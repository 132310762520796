import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import {IndicatorProps} from './index'
import {CounselorEmailIndicatorType} from '../../Types'

const CounselorEmailIndicator = ({indicator, answer, onChange, disabled}: IndicatorProps<CounselorEmailIndicatorType, string>) => {
    const {fresh, value, valid} = answer

    return (
        <FormGroup hasError={!disabled && !fresh && !valid && !indicator.optional} hasSuccess={!fresh && valid} size={'lg'}>
            <label>{indicator['title']}</label>
            {indicator.rows <= 1 && (
                <input
                    disabled={disabled}
                    name={indicator['@id']}
                    onBlur={e => onChange(e.target.value, true)}
                    onChange={e => onChange(e.target.value)}
                    value={value ? value : ''}
                    className='form-control'
                    type='text'
                />
            )}
            {indicator.rows > 1 && (
                <textarea
                    disabled={disabled}
                    name={indicator['@id']}
                    onBlur={e => onChange(e.target.value, true)}
                    value={value ? value : ''}
                    onChange={e => onChange(e.target.value)}
                    style={{resize: 'vertical'}}
                    className='form-control'
                />
            )}
            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            <span className='help-block'>
                <p>Dette feltet er valgfritt.</p>
            </span>
            <span className='text-warning'>
                Alle svar blir sent til veileder(e), eventuelt kurs-administrator eller kontakt@skilnet.no. Svarene blir slettet etter at de
                er sendt.
            </span>
        </FormGroup>
    )
}
export default CounselorEmailIndicator
