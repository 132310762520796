import React, {useState} from 'react'
import {Box} from '../Components/Panel'
import LoadingComponent from '../Components/LoadingComponent'
import {Page} from '../Components/Page'
import {useQueryInvalidate, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import {GridCellParams, GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../Components/DataGrid/columnDefs/dateColumnDef'
import {DataGridWrapper} from '../Components/DataGrid/Wrappers'
import DataGrid, {InitModel} from '../Components/DataGrid/DataGrid'
import SpecialActions from '../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import CreateNefleDiscountCodeDialog from './Components/CreateNefleDiscountCodeDialog'
import {Radio} from '@mui/material'
import EditNefleDiscountCodeDialog from './Components/EditNefleDiscountCodeDialog'

const columnVisibilityModel = {
    createdAt: false,
    createdBy: false,
    updatedAt: false,
    updatedBy: false,
}

export default function NefleDiscountCodes() {
    const invalidate = useQueryInvalidate()
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel})
    const [selectedDiscount, setSelectedDiscount] = React.useState('')
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [editingDiscount, setEditingDiscount] = React.useState(false)
    const [isDeleting, setIsDeleting] = React.useState(false)
    const updateDiscountCodeMutation = useSkilMutation<'patchNefleDiscountCodeItem'>(
        'patch',
        `/api/nefle_discount_codes/${selectedDiscount}`,
        ['/api/nefle_discount_codes/codes/all']
    )
    const deleteDiscountCodeMutation = useSkilMutation<'deleteNefleDiscountCodeItem'>('delete', `/api/nefle_discount_codes/${null}`, [
        '/api/nefle_discount_codes/codes/all',
    ])

    const generateDiscountCode = useSkilMutation<'postNefleDiscountCodeItem'>('post', `/api/nefle_discount_codes`)
    const {
        data: discountCodesRaw,
        isFetching,
        isSuccess,
        refetch: refetchDiscountCodes,
    } = useSkilQuery<'getNefleDiscountCodeCollection'>(`/api/nefle_discount_codes/codes/all`)
    const discountCodes = discountCodesRaw?.['hydra:member'] ?? []
    const {
        data: discountCode,
        isFetching: isFetchingDiscountCode,
        isSuccess: isSuccessDiscountCode,
        refetch: refetchDiscountCode,
    } = useSkilQuery<'getNefleDiscountCodeItem'>(
        `/api/nefle_discount_codes/${selectedDiscount}`,
        {},
        {enabled: !!selectedDiscount && editingDiscount}
    )

    const handleSelect = value => {
        setSelectedDiscount(value)
    }

    const handleCloseEditDialog = () => {
        setSelectedDiscount('')
        setEditingDiscount(false)
    }

    const handleEditDiscount = async () => {
        invalidate(['/api/nefle_discount_codes/' + selectedDiscount]).then(() => {
            setEditingDiscount(true)
        })
    }

    const handleDeleteDiscount = async () => {
        await onDelete()
    }

    const columns: GridColDef[] = [
        // Add a custom column for radio buttons
        {
            field: 'radio',
            headerName: '',
            width: 50,
            renderCell: params => {
                // Custom cell renderer for the radio button
                return <Radio checked={selectedDiscount === params.id.toString()} onChange={() => handleSelect(params.id.toString())} />
            },
        },
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'code', headerName: 'Rabattkode', type: 'string'}),
        columnDef({field: 'discount', headerName: 'Rabatt på etableringskostnad (kroner)', type: 'number'}),
        dateColumnDef({field: 'validFrom', headerName: 'Gyldig fra', type: 'date'}),
        dateColumnDef({field: 'validTo', headerName: 'Gyldig til', type: 'date'}),
        dateColumnDef({field: 'createdAt', headerName: 'Opprettet dato', type: 'date'}),
        columnDef({field: 'createdBy', headerName: 'Opprettet av', type: 'string'}),
        dateColumnDef({field: 'updatedAt', headerName: 'Oppdatert dato', type: 'date'}),
        columnDef({field: 'updatedBy', headerName: 'Oppdatert av', type: 'string'}),
        columnDef({field: 'usage', headerName: 'Antall brukt', type: 'number'}),
    ]

    const validateForm = async e => {
        if (!e.code) return toast('Du må fylle inn en rabattkode', {type: 'error'})
        if (isNaN(e.discount)) return toast('Du må fylle inn rabattbeløpet', {type: 'error'})
        if (!e.validFrom) return toast('Du må fylle inn gyldig fra dato', {type: 'error'})
        if (!e.validTo) return toast('Du må fylle inn gyldig til dato', {type: 'error'})
        if (new Date(e.validTo) < new Date(e.validFrom)) return toast('Gyldig til dato kan ikke være før gyldig fra dato', {type: 'error'})
    }

    const onSave = async e => {
        await validateForm(e)

        try {
            const newDiscountCode = await generateDiscountCode.mutateAsync({
                code: e.code,
                discount: e.discount,
                validFrom: e.validFrom,
                validTo: e.validTo,
            })
            await refetchDiscountCodes()
            toast('Rabattkoden opprettet', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onUpdate = async e => {
        await validateForm(e)
        try {
            const updatedDiscountCode = await updateDiscountCodeMutation.mutateAsync({
                // @ts-expect-error
                nefleDiscountCode: discountCode['@id'],
                code: e.code,
                discount: e.discount,
                validFrom: e.validFrom,
                validTo: e.validTo,
            })

            toast('Rabattkoden ble oppdatert', {type: 'success'})
            invalidate(['/api/nefle_discount_codes/codes/all', '/api/nefle_discount_codes/' + selectedDiscount]).then(() => {})
            refetchDiscountCodes()
                .then(() => {})
                .catch(() => {})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onDelete = async () => {
        const usage = discountCodes?.find(e => e.id === parseInt(selectedDiscount))?.usage
        if (selectedDiscount && discountCodes.length > 0 && usage !== undefined && usage > 0) {
            toast(`Rabattkoden kan ikke slettes fordi den er i bruk av ${usage} kunder`, {type: 'warning'})
        } else {
            try {
                await deleteDiscountCodeMutation.mutateAsync({
                    '@overridePath': '/api/nefle_discount_codes/' + selectedDiscount,
                })
                toast('Rabattkoden ble slettet', {type: 'success'})
                invalidate(['/api/nefle_discount_codes/codes/all', '/api/nefle_discount_codes/' + selectedDiscount]).then(() => {})
                refetchDiscountCodes()
            } catch (e) {
                handleErrorWithToast(e)
            }
        }
    }

    const breadcrumbs = [
        {
            title: 'SKIL',
            to: '/dashboard/SKIL',
        },
        {
            title: 'Verktøy',
            to: '/dashboard/SKIL/tools',
        },
        {
            title: 'Nefle rabattkoder',
        },
    ]

    const actions: SpecialActionProps[] = [
        {
            label: 'Legg til rabattkode',
            action: () => setShowCreateDialog(true),
            disabled: false,
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: editingDiscount && !isSuccessDiscountCode && isFetchingDiscountCode ? 'henter data...' : 'Rediger rabattkode',
            action: () => handleEditDiscount(),
            disabled: !selectedDiscount,
            icon: {
                position: 'start',
                icon:
                    editingDiscount && !isSuccessDiscountCode && isFetchingDiscountCode ? (
                        <i className={'fa fa-spin fa-spinner'} />
                    ) : (
                        <EditIcon />
                    ),
            },
        },
        {
            label: isDeleting ? 'sletter...' : 'Slett rabattkode',
            action: () => handleDeleteDiscount(),
            disabled: !selectedDiscount,
            icon: {
                position: 'start',
                icon: isDeleting ? <i className={'fa fa-spin fa-spinner'} /> : <RemoveIcon />,
            },
        },
    ]

    if (!isSuccess) return <LoadingComponent size={'h4'}>Laster inn..</LoadingComponent>

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <Box>
                <DataGridWrapper rows={discountCodes.length} addHeight={12} sx={{height: '100vh'}}>
                    <DataGrid
                        id={'courses'}
                        sx={{height: 'auto', '& .MuiDataGrid-main': {height: '50vh'}}}
                        initModel={initModel}
                        loading={isFetching}
                        rows={discountCodes}
                        columns={columns}
                        disableSelectionOnClick
                        toolbarButtons={<SpecialActions actions={actions} />}
                    />
                </DataGridWrapper>
                <CreateNefleDiscountCodeDialog
                    isOpen={showCreateDialog}
                    onSave={e => onSave(e)}
                    onClose={() => setShowCreateDialog(false)}
                />
                {discountCode && isSuccessDiscountCode && !isFetchingDiscountCode && (
                    <EditNefleDiscountCodeDialog
                        discountCode={discountCode}
                        isOpen={editingDiscount && isSuccessDiscountCode && !isFetchingDiscountCode}
                        onSave={e => onUpdate(e)}
                        onClose={() => handleCloseEditDialog()}
                    />
                )}
            </Box>
        </Page>
    )
}
