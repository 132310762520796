import * as React from 'react'
import {Link, Outlet, useParams} from 'react-router-dom'
import {Page} from '../../Components/Page'
import {useSkilQuery} from '../../Utilities/QueryClient'
import {Box, Button, MobileStepper, Step, StepLabel, Stepper} from '@mui/material'
import {useEffect, useState} from 'react'
import OfficeSkeleton from './Skeletons/OfficeSkeleton'
import {useNavigate} from 'react-router'
import {styled} from '@mui/material/styles'

/*
    These should match subpages and siteConfig.progress.*
    Status has no progress
*/
const TABS = [
    {label: 'Legekontoret', to: 'legekontoret'},
    {label: 'Timebestilling', to: 'timebestilling'},
    {label: 'Ansatte', to: 'ansatte'},
    {label: 'Åpningstider', to: 'opningstider'},
    {label: 'Tilgjengelighet', to: 'tilgjengelighet'},
    {label: 'Status', to: 'status'},
]

export default function Overview() {
    const {['*']: slug, officeId} = useParams()
    const [activeStep, setActiveStep] = useState(0)
    const [completedSteps, setCompletedSteps] = useState(0)
    const [progress, setProgress] = useState({})
    const navigate = useNavigate()

    const [nefle, setNefle] = useState<any>()

    const [queryEnabled, setQueryEnabled] = useState(false)
    /*
        Initial data for all subpages, should only be needed once.
     */
    const {data: office, isLoading, isError} = useSkilQuery<'getOfficeItem'>(`/api/offices/${officeId}`)

    const {
        data: nefleData,
        isLoading: isLoadingNefle,
        isError: isErrorNefle,
    } = useSkilQuery<'getOfficeNefleItem'>(
        office?.nefle ?? '',
        {},
        {
            enabled: !!office?.nefle && queryEnabled,
            onSuccess: () => {
                setQueryEnabled(false)
            },
            refetchInterval: 1,
        }
    )

    const capitalizedSlug = slug ? slug.charAt(0).toUpperCase() + slug.slice(1) : ''

    const breadcrumbs = [
        {title: office?.name ?? '', href: `/dashboard/offices/${officeId}`},
        {title: 'Nefle Oppsett', href: `/dashboard/offices/${officeId}/nefle/setup`},
        {title: capitalizedSlug ?? '', href: ''},
    ]

    useEffect(() => {
        setQueryEnabled(true)
    }, [])

    useEffect(() => {
        if (!nefleData) return

        let step = 0

        nefleData?.siteConfig?.progress &&
            Object.keys(nefleData?.siteConfig?.progress).map(obj => {
                if (nefleData?.siteConfig?.progress[obj] === true) {
                    step++
                }
            })
        /*
            Initial setup for progress navigation.
            Using both states and initial data for faster response.
       */
        setProgress(nefleData?.siteConfig?.progress)
        setCompletedSteps(step)
        setActiveStep(step)
        setNefle(nefleData)
        navigate(TABS[step].to)
    }, [nefleData])

    /*
        Keep track of progress, mainly for navigation
     */
    const updateSteps = progress => {
        let step = 0

        if (!progress) {
            setCompletedSteps(step)
            return
        }
        Object.keys(progress).map(obj => {
            if (progress[obj] === true) {
                step++
            }
        })

        setProgress(progress)
        setCompletedSteps(step)
    }

    const handleMobileBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1)
            navigate(TABS[activeStep - 1].to)
        }
    }

    const handleMobileNext = () => {
        if (activeStep + 1 < TABS.length) {
            setActiveStep(activeStep + 1)
            navigate(TABS[activeStep + 1].to)
        }
    }

    if (isLoading || isLoadingNefle || !nefle || queryEnabled) return <OfficeSkeleton />

    if (!nefleData) navigate('/signup/nefle')

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs}>
            <Box sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '.1em', sm: '2em'}}}>
                <StyledMainBox sx={{width: '100%'}}>
                    <StyledMobileStepper
                        backButton={
                            <Button variant={'outlined'} disabled={activeStep <= 0} onClick={() => handleMobileBack()}>
                                Tilbake
                            </Button>
                        }
                        nextButton={
                            <Button
                                variant={'contained'}
                                disabled={activeStep >= TABS.length - 1 || !progress || !progress['/' + slug]}
                                onClick={() => handleMobileNext()}
                            >
                                Neste
                            </Button>
                        }
                        steps={TABS.length}
                        activeStep={activeStep}
                        position={'static'}
                        sx={{
                            display: {md: 'none'},
                        }}
                    ></StyledMobileStepper>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        sx={{
                            display: {xs: 'none', sm: 'none', md: 'flex'},
                        }}
                    >
                        {TABS.map((obj, key) => (
                            <Step key={obj.label + key} completed={progress && progress['/' + obj.to]}>
                                <StyledStepLabel tabIndex={-1}>
                                    {completedSteps >= key && obj.to != slug ? (
                                        <Link
                                            to={obj.to}
                                            onClick={() => {
                                                setActiveStep(key)
                                            }}
                                            tabIndex={0}
                                        >
                                            {obj.label}
                                        </Link>
                                    ) : (
                                        <span>
                                            <strong>{obj.label}</strong>
                                        </span>
                                    )}
                                </StyledStepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {/* Subpages through Outlet
                        @nefle - initial json data
                        @setProgress - mainly for mobile navigation
                        @isGenerated - lockdown everything
                        @skip to next TAB page. (No save logic here)
                     */}
                    <Outlet
                        context={{
                            nefle,
                            setProgress: val => {
                                updateSteps(val)
                            },
                            isGenerated: !!nefle?.generatedAt,
                            next: () => {
                                handleMobileNext()
                            },
                            setQueryEnabled: val => {
                                setQueryEnabled(val)
                            },
                        }}
                    />
                </StyledMainBox>
            </Box>
        </Page>
    )
}

const StyledStepLabel = styled(StepLabel)`
    &:focus-within a {
        border-bottom: 2px solid black;
    }
`
const StyledMobileStepper = styled(MobileStepper)`
    background-color: white;
`

const StyledMainBox = styled(Box)`
    ${props => props.theme.breakpoints.down('md')} {
        button {
            min-height: 38px;
            font-size: 1rem;
        }
    }
`
