import * as React from 'react'
import Button, {ButtonProps} from '@mui/material/Button'

export interface SpecialActionProps extends ButtonProps {
    label: string
    icon?: {
        position: 'start' | 'end'
        icon: JSX.Element
    }
    action: (props) => void
}

const SpecialAction = ({action, label, icon = undefined, ...props}: SpecialActionProps) => {
    if (icon && icon.position === 'start') {
        return (
            <Button {...props} startIcon={icon.icon} onClick={action}>
                {label}
            </Button>
        )
    }
    if (icon && icon.position === 'end') {
        return (
            <Button {...props} endIcon={icon.icon} onClick={action}>
                {label}
            </Button>
        )
    }

    return (
        <Button {...props} onClick={action}>
            {label}
        </Button>
    )
}

export default SpecialAction
