import React, {useState} from 'react'
import Modal from './Modal'
import TextField from './Fields/TextField'
import DateField from './Fields/DateField'
import SimpleSelectField from './Fields/SimpleSelectField'
import DateTimeField from './Fields/DateTimeField'

export default function NewMeetingModal({meetingOptions, onSubmit, onClose}) {
    const [isSaving, setIsSaving] = useState(false)
    const [meetingData, setMeetingData] = useState({when: null, task: null, location: null})

    const onChangeDatetime = datetime => {
        setMeetingData({...meetingData, when: datetime})
    }
    const onChangeMeeting = meeting => {
        setMeetingData({...meetingData, task: meeting})
    }
    const onChangeLocation = location => {
        setMeetingData({...meetingData, location: location})
    }

    const onSave = () => {
        setIsSaving(true)
        onSubmit(meetingData)
    }
    return (
        <Modal
            title={`Opprett nytt møte`}
            onClose={onClose}
            spinnerWhileSaving={isSaving}
            disableWhileSaving={isSaving}
            onSave={meetingData.when && meetingData.task && meetingData.location && onSave ? onSave : undefined}
        >
            <DateTimeField
                id={'datetimeField'}
                label={'Tid'}
                showTimeSelect={true}
                value={meetingData.when}
                required
                onChange={onChangeDatetime}
            />
            <SimpleSelectField
                id={'meetingField'}
                label={'Tema'}
                value={meetingData.task}
                onChange={onChangeMeeting}
                size={'lg'}
                options={meetingOptions}
            />
            <TextField
                id={'locationField'}
                label={'Sted'}
                validateFn={location => location.length > 2}
                value={meetingData.location}
                onChange={onChangeLocation}
            />
        </Modal>
    )
}
