import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import {Link} from 'react-router-dom'
import {useTheme} from '@mui/system'
import LaunchIcon from '@mui/icons-material/Launch'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'

export interface BigLinkProps {
    label: string
    isExternal?: boolean
    isEmail?: boolean
    disabled?: boolean
    to?: string
    href?: string
}

export const BigListLink = ({label, to, href, isExternal = false, isEmail = false, disabled = false}: BigLinkProps) => {
    const theme = useTheme()
    const LinkComponent = isExternal || isEmail ? 'a' : Link

    return (
        <ListItem disablePadding key={label}>
            <ListItemButton
                sx={{
                    padding: theme.spacing(1, 1, 0, 1),
                    marginTop: theme.spacing(1),
                    borderBottom: `1px solid ${theme.palette.grey[500]}`,
                }}
                component={LinkComponent}
                to={to}
                disabled={disabled}
                href={href}
                target={isExternal || isEmail ? '_blank' : undefined}
            >
                <ListItemText sx={{mb: 0}} primary={label} />
                {isExternal && <LaunchIcon />}
                {isEmail && <AlternateEmailOutlinedIcon />}
            </ListItemButton>
        </ListItem>
    )
}
