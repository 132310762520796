import {useSkilQuery} from '../Utilities/QueryClient'
import * as React from 'react'

export const CountiesExport = hasLoaded => {
    const countiesQueryResult = useSkilQuery<'getCountyCollection'>(`/api/counties`, {pagination: false}, {enabled: !hasLoaded})

    const counties = React.useMemo(() => {
        const countiesRaw = countiesQueryResult.data?.['hydra:member'] ?? []
        return Object.values(countiesRaw)
            .map(county => {
                // we know that the @id is always a string when a county is returned
                let iri = county['@id'] as string
                return {label: county.name, id: iri, region: county.region, regionId: county.regionId, districts: county.districts}
            })
            .sort(function (a, b) {
                if (a.region < b.region) {
                    return -1
                }
                if (a.region > b.region) {
                    return 1
                }
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
    }, [countiesQueryResult])
    return counties
}
