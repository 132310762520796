import React, {useEffect, useState} from 'react'
import FormGroup from '../../../Components/FormGroup'
import styled from 'styled-components'
import EditText from '../../Components/EditText'
import useEntity from '../../../Hooks/useEntity'
import LoadingComponent from '../../../Components/LoadingComponent'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {useQuery} from 'react-query'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`

const PreviousAnswerIndicator = ({indicator, answer, onChange, disabled = false, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer
    const {data: form} = useSkilQuery<'getFormItem'>(indicator.form, {}, {enabled: !!indicator.form})
    const {data: course} = useSkilQuery<'getCourseItem'>(form?.course ?? '', {}, {enabled: !!form?.course})
    const {data: globalFormsCollection} = useSkilQuery<'getFormCollection'>('/api/forms', {
        'exists[course]': false,
        enabled: form && !form?.course,
    })
    const globalForms = globalFormsCollection?.['hydra:member']
    const workSheetForNewIndicator = form?.['@id'] ? form?.['@id'] : null
    const previousAnswerIndicator = indicator.subType === 'PreviousAnswerIndicator' ? indicator : null
    const previousAnswerIndicatorForm =
        previousAnswerIndicator && previousAnswerIndicator?.['previousAnswerIndicator']?.['form']
            ? previousAnswerIndicator?.['previousAnswerIndicator']?.['form']
            : null
    const [workSheetIri, changeWorkSheetIri] = useState(previousAnswerIndicatorForm ? previousAnswerIndicatorForm : null)
    const workSheet = useEntity<'getFormItem'>(workSheetIri ?? workSheetForNewIndicator?.['@id'])

    if ((!workSheet['@loaded'] || !indicator['@loaded'] || !form?.['@id']) && previousAnswerIndicator && workSheetIri)
        return <LoadingComponent size={'h4'}>Laster inn...</LoadingComponent>

    if ((!indicator['@loaded'] || !form?.['@id']) && !previousAnswerIndicator)
        return <LoadingComponent size={'h4'}>Laster inn...</LoadingComponent>

    const changeIndicator = value => {
        indicator.changeField('previousAnswerIndicator', value)
        indicator.save()
    }

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    return (
        <FormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>

            <label>Velg arbeidsark</label>
            <select
                name='previous_answer_form'
                title='Velg arbeidsark'
                className='form-control'
                value={workSheet['id']}
                onChange={e => changeWorkSheetIri('/api/forms/' + e.target.value)}>
                {course?.forms && Object.values(course?.forms).map(course => <Form key={course['@id']} iri={course['@id']} />)}
                {!course?.forms &&
                    globalForms &&
                    Object.values(globalForms).map(course => <Form key={course['@id']} iri={course['@id']} />)}
            </select>
            <label style={{marginTop: '0.5em'}}>Velg indikator</label>
            <select
                name='previous_answer_indicator'
                title='Velg indikator'
                className='form-control'
                value={previousAnswerIndicator?.['previousAnswerIndicator']?.['id'] ?? 0}
                onChange={e => {
                    if (e.target.value !== '-1') {
                        changeIndicator('/api/indicators/' + e.target.value)
                    }
                }}>
                <option key={'null_indicator'} value={-1}>
                    Velg indikator
                </option>
                {workSheet &&
                    workSheet.indicatorGroups &&
                    Object.entries(workSheet.indicatorGroups).map(([key, iri]) => <IndicatorGroup key={key} iri={iri} />)}
            </select>

            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </FormGroup>
    )
}
export default PreviousAnswerIndicator

const Form = ({iri}) => {
    const form = useEntity<'getFormItem'>(iri)

    return <option key={form.id} label={form.name} value={form.id} />
}

const IndicatorGroup = ({iri}) => {
    const indicatorGroup = useEntity<'getIndicatorGroupItem'>(iri)
    return (
        <optgroup key={indicatorGroup.id} label={indicatorGroup.title}>
            {indicatorGroup.indicators && Object.entries(indicatorGroup.indicators).map(([key, iri]) => <Indicator key={key} iri={iri} />)}
        </optgroup>
    )
}

const Indicator = ({iri}) => {
    const indicator = useEntity<'getIndicatorItem'>(iri)

    return (
        <option key={indicator.id} value={indicator.id}>
            #{indicator.id} - {indicator.title}
        </option>
    )
}
