import React from 'react'
import {messaging} from '../Utilities/Firebase'
import UAParser from 'ua-parser-js'
import useEntities from '../Hooks/useEntities'
import {useDispatch} from 'react-redux'
import {showToast} from '../State/actions/ui'
import {isDevelopment} from '../State/utils/errorHandler'
import useUser from '../Utilities/useUser'

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then()
    } catch (e) {
        return false
    }

    return true
}

const postUserToken = (token, description, bindingType) => {
    return fetch('/api/push_notification_targets', {
        method: 'POST',
        body: JSON.stringify({token, description, bindingType}),
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/ld+json'},
    })
}

export const usePushNotifications = () => {
    // @ts-expect-error
    const isSafari = 'safari' in window && 'pushNotification' in window.safari
    const isSupported = ('Notification' in window && messaging) || isSafari
    const dispatch = useDispatch()
    const currentToken = window.localStorage.getItem('last_notification_token')
    const user = useUser()
    const targets = useEntities<'getUserPushNotificationTargets'>(user.authenticated ? user.iri + '/push_notification_targets' : undefined, 60000)
    const isRegistered = currentToken && isSupported && targets.find(t => t.token === currentToken)

    const handlePermission = permission => {
        if (permission !== 'granted') {
            dispatch(
                showToast(
                    'Tillatelse til notifikasjoner er blokkert. Vennligst trykk på hengelåsen i addressebaren for å endre tilatelsene.',
                    'error'
                )
            )
            return
        }

        const parser = new UAParser()
        const os = parser.getOS()
        const browser = parser.getBrowser()
        const description = `${browser.name} ${browser.version} på ${os.name} ${os.version || ''}`.trim()

        messaging?.getToken().then(token => {
            if (!token) {
                console.info('No device token for FCM!')
                return
            }

            window.localStorage.setItem('last_notification_token', token)
            postUserToken(token, description, 'fcm').then(() => targets.refresh())
        })
    }

    const requestPermission = () => {
        if (isSafari) {
            const checkSafariRemotePermission = permissionData => {
                if (permissionData.permission === 'default') {
                    // @ts-expect-error
                    window.safari.pushNotification.requestPermission(
                        isDevelopment('https://localhost:8000/api/apple', 'https://eportal.skilnet.no/api/apple'),
                        'web.no.skilnet.eportal',
                        {userId: user.iri},
                        checkSafariRemotePermission
                    )
                } else if (permissionData.permission === 'denied') {
                    dispatch(
                        showToast(
                            'Tillatelse til notifikasjoner er blokkert. Vennligst trykk på hengelåsen i addressebaren for å endre tilatelsene.',
                            'error'
                        )
                    )
                } else if (permissionData.permission === 'granted') {
                    window.localStorage.setItem('last_notification_token', permissionData.deviceToken)
                    postUserToken(permissionData.deviceToken, 'Safari på Mac OSX', 'apn').then(() => targets.refresh())
                }
            }

            // Ensure that the user can receive Safari Push Notifications.
            // @ts-expect-error
            const permissionData = window.safari.pushNotification.permission('web.no.skilnet.eportal')
            checkSafariRemotePermission(permissionData)
        } else {
            if (checkNotificationPromise()) {
                window.Notification.requestPermission().then(p => handlePermission(p))
            } else {
                window.Notification.requestPermission(p => handlePermission(p)).then()
            }
        }
    }

    const deleteTarget = iri => {
        const entity = targets.find(e => e['@id'] === iri)
        if (entity?.token === currentToken) {
            window.localStorage.removeItem('last_notification_token')
        }

        targets.remove(iri)
    }

    const deleteDevice = () => {
        const entity = targets.find(e => e.token === currentToken)
        window.localStorage.removeItem('last_notification_token')

        if (!entity) return

        targets.remove(entity['@id']!)
    }

    return {
        requestPermission,
        targets,
        deleteTarget,
        isRegistered,
        isSupported,
        deleteDevice,
        currentToken,
        isLoading: !targets['@loaded'],
    }
}
export default usePushNotifications
