import * as React from 'react'
import {Link} from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import {computeDisabled, useClickHandler, useConfirmClickHandler} from './utils'
import LoadingButton, {LoadingButtonProps} from '@mui/lab/LoadingButton'
import {SxProps, useTheme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export type OnClickFunction = (event: any) => Promise<void | string | Error> | void | string | Error

type AnchorProps = {
    href: string
    target?: string
}

export interface ButtonProps extends LoadingButtonProps {
    to?: string
    confirm?: React.ReactNode
    onClick?: OnClickFunction
    target?: string
    type?: 'button' | 'submit' | 'reset'
}

export const Button = ({
    to,
    confirm = undefined,
    disabled = false,
    type = 'button',
    children,
    onClick,
    variant = 'contained',
    color = 'primary',
    ...props
}: ButtonProps) => {
    const theme = useTheme()
    const clickHandler = useClickHandler(onClick)
    const confirmClick = useConfirmClickHandler(clickHandler.handleClick, children, confirm)
    const isDisabled = computeDisabled(onClick, props.href, to, disabled, clickHandler.working, type)

    // TODO: revisit whether this is the right solution. Is body2 too restrictive?
    const elasticWidthProps: SxProps = {
        marginRight: clickHandler.working ? theme.spacing(2.5) : null,
        fontSize: props.size === 'large' ? '1.25em' : '1em',
    }

    if (to) {
        return (
            // @ts-expect-error somehow this LoadingButton doesn't accept {...props}
            <LoadingButton
                type={type}
                variant={variant}
                component={Link}
                onClick={confirmClick.handleClick}
                disabled={isDisabled}
                to={to}
                color={color}
                {...props}
            >
                <Typography variant='body2' sx={elasticWidthProps}>
                    {confirmClick.children}
                </Typography>
                {clickHandler.working ? <CircularProgress size='small' /> : ''}
            </LoadingButton>
        )
    }
    if (props.href) {
        return (
            <LoadingButton
                type={type}
                variant={variant}
                loading={clickHandler.working}
                disabled={isDisabled}
                onClick={confirmClick.handleClick}
                color={color}
                {...props}
            >
                <Typography variant='body2' sx={elasticWidthProps}>
                    {confirmClick.children}
                </Typography>
            </LoadingButton>
        )
    }

    return (
        <LoadingButton
            type={type}
            variant={variant}
            loading={clickHandler.working}
            onClick={confirmClick.handleClick}
            disabled={isDisabled}
            color={color}
            {...props}
        >
            <Typography variant='body2' sx={elasticWidthProps}>
                {confirmClick.children}
            </Typography>
        </LoadingButton>
    )
}
