import ButtonGroup from '@mui/material/ButtonGroup'
import * as React from 'react'

interface ActionButtonGroupProps {
    children: React.ReactNode
    align?: 'left' | 'right'
}

export const ActionButtonGroup = ({children, align = 'left'}: ActionButtonGroupProps) => {
    return (
        <ButtonGroup
            sx={{
                display: 'flex',
                marginBottom: 2,
                gap: 1,
                justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
            }}
            disableElevation
            variant='contained'>
            {children}
        </ButtonGroup>
    )
}
