import React, {useState} from 'react'
import Button from '../../Components/Button/Button'
import styled from 'styled-components'

const StyledDowndown = styled.div`
    .dropdown-menu {
        position: relative;
    }
`

type Props = {
    value: unknown
    onChange: (val: string) => void
    options: {value: string | number; key?: string; label: string}[]
    optionsRaw: unknown[]
    labelKey: string
    valueKey: string
    alternative: any
}
const InlineDropdown = React.memo(({value, onChange, options, optionsRaw, labelKey = 'label', valueKey = 'value', alternative}: Props) => {
    const [show, setShow] = useState(false)
    const onLocalClick = val => {
        setShow(false)
        onChange(val)
    }

    let symbol: string = ''

    if (!alternative) {
        switch (value) {
            case 0:
                symbol = '='
                break
            case 10:
                symbol = '!='
                break
            case 1:
                symbol = '>'
                break
            case 2:
                symbol = '>='
                break
            case -1:
                symbol = '<'
                break
            case -2:
                symbol = '<='
                break
            case 11:
                symbol = '='
                break
            case 12:
                symbol = '!='
                break
            case 13:
                symbol = 'inneholder'
                break
            case 14:
                symbol = 'inneholder ikke'
                break
        }
    }

    return (
        <StyledDowndown className='dropdown'>
            <Button
                onClick={() => setShow(!show)}
                variant={'link'}
                className='dropdown-toggle'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded={show}
            >
                {!alternative && <span className='caret'>{symbol}</span>}
                {alternative && (
                    <span className='caret'>
                        {options && options.find(o => o.value === value)
                            ? options.find(o => o.value === value)?.[labelKey]
                            : options && options.find(o => o.key === value)
                            ? options.find(o => o.key === value)?.[labelKey]
                            : // @ts-expect-error
                            optionsRaw && optionsRaw[value]
                            ? // @ts-expect-error
                              optionsRaw[value]
                            : null}
                    </span>
                )}
            </Button>
            <ul hidden={!show} className='dropdown-menu'>
                {options.map(item => (
                    <li key={item[valueKey]}>
                        <Button
                            block
                            // @ts-expect-error wtf
                            variant={item[valueKey] === value ? 'primary' : 'secondary' + item[labelKey]}
                            onClick={() => onLocalClick(item[valueKey])}
                        >
                            {item[labelKey]}
                        </Button>
                    </li>
                ))}
            </ul>
        </StyledDowndown>
    )
})
export default InlineDropdown
