import * as React from 'react'
import {GridEnrichedColDef, GridValueGetterParams, GridValueFormatterParams} from '@mui/x-data-grid-premium'
import {resolve} from './columnDef'
import {CountyOptionsTypeForColumnDef} from '../../CountiesExportToAreaColumnDef'

const regionValueGetter = field => (params: GridValueGetterParams) => {
    const value = resolve(field, params.row)
    if (!value || typeof value !== 'string') return null

    const id = Number(value.substring('/api/counties/'.length))
    return Math.floor(id / 100)
}

const countyValueGetter = field => (params: GridValueGetterParams) => {
    const value = resolve(field, params.row)
    if (!value || typeof value !== 'string') return null

    return Number(value.substring('/api/counties/'.length))
}

const districtValueGetter = field => (params: GridValueGetterParams) => {
    const value = resolve(field, params.row)
    if (!value || typeof value !== 'string') return null

    return Number(value.substring('/api/districts/'.length))
}

type Column = GridEnrichedColDef & {
    type: 'county' | 'region' | 'district'
    counties: CountyOptionsTypeForColumnDef[]
}

const sortComparator = options => (v1: unknown, v2: unknown) => {
    const a1 = options.find(o => o.value === v1)
    const a2 = options.find(o => o.value === v2)

    if (!a1 && !a2) return 0
    if (!a1) return -1
    if (!a2) return 1

    return a1.label.localeCompare(a2.label)
}

export default function areaColumnDef(col: Column): GridEnrichedColDef {
    const valueOptions = col.counties
    const valueGetter =
        col.type === 'region'
            ? regionValueGetter(col.field)
            : col.type === 'district'
            ? districtValueGetter(col.field)
            : countyValueGetter(col.field)

    let valueFormatter = ({value, field, api}: GridValueFormatterParams) => {
        const colDef = api.getColumn(field)
        const option = colDef.valueOptions.find(({value: optionValue}) => value === optionValue)

        return option?.label ?? ''
    }

    return {
        flex: 1,
        headerAlign: 'left',
        sortComparator: sortComparator(valueOptions),
        headerName: col.type === 'region' ? 'Fylke' : col.type === 'district' ? 'Bydel' : 'Kommune',
        ...col,
        type: 'singleSelect',
        valueOptions,
        field: col.field + '.' + col.type,
        valueGetter,
        valueFormatter,
    }
}
