import * as React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'
import {DEFAULT_ICON_PROPS} from './constants'
import sample from 'lodash/sample'

export const DoctorGeneric = () => (
    <g transform='translate(0.000000,1050.000000) scale(0.200000,-0.200000)' fill='#000000' stroke='none'>
        <path
            d='M1333 3330 c-344 -90 -560 -454 -473 -794 38 -148 119 -276 230 -362
            35 -27 63 -59 69 -77 14 -38 14 -115 1 -154 l-11 -30 -137 -6 c-152 -6 -262
            -32 -375 -88 -237 -119 -408 -341 -462 -601 -12 -55 -15 -166 -15 -538 l0
            -469 22 -15 c20 -14 162 -16 1319 -16 l1296 0 27 21 27 22 -3 486 -4 486 -27
            88 c-47 153 -117 268 -228 378 -164 162 -382 249 -626 249 -115 0 -118 2 -129
            82 -11 78 9 120 81 178 68 54 142 144 180 220 93 184 93 416 0 600 -85 167
            -256 302 -436 345 -88 20 -237 18 -326 -5z m278 -110 c96 -18 208 -81 281
            -159 104 -110 148 -220 148 -371 0 -156 -46 -267 -154 -376 -119 -120 -249
            -171 -411 -161 -203 12 -366 120 -456 302 -76 155 -76 315 0 470 110 223 345
            341 592 295z m-106 -1190 c50 0 116 6 148 14 l57 13 0 -41 c0 -22 10 -73 21
            -113 l22 -72 -113 -311 c-109 -300 -114 -311 -138 -308 -23 3 -33 26 -138 315
            l-113 311 19 49 c10 27 22 77 25 110 l7 60 57 -13 c31 -8 97 -14 146 -14z
            m-362 -252 c2 -7 55 -152 117 -323 l113 -310 -54 -415 -54 -415 -348 -3 -347
            -2 0 108 c0 127 -11 152 -65 152 -48 0 -55 -20 -55 -149 l0 -111 -86 0 -85 0
            3 438 c4 427 4 439 27 507 50 149 129 270 236 358 57 47 170 117 189 117 3 0
            6 -87 6 -194 l0 -194 -34 -22 c-58 -35 -133 -125 -156 -187 -28 -76 -43 -204
            -38 -333 5 -116 16 -148 58 -173 38 -23 147 -23 172 0 43 39 6 103 -61 103
            l-39 0 -8 68 c-9 88 9 236 37 301 23 55 99 131 130 131 34 0 103 -64 134 -125
            29 -57 30 -64 33 -217 l4 -158 -39 0 c-49 0 -83 -23 -83 -57 0 -47 25 -63 101
            -63 58 0 72 4 98 26 45 38 54 86 48 249 -4 77 -11 160 -17 185 -25 100 -86
            188 -177 251 l-43 30 0 204 c0 152 3 206 13 212 33 21 262 30 270 11z m991 -8
            l26 -10 0 -298 0 -299 -47 -18 c-181 -69 -231 -317 -93 -455 58 -58 103 -75
            200 -75 75 0 91 3 131 27 197 116 185 409 -21 497 l-50 22 0 279 c0 217 3 280
            13 280 6 0 46 -22 87 -49 162 -104 271 -251 327 -440 14 -47 17 -122 20 -488
            l4 -433 -85 0 -86 0 0 112 c0 123 -10 148 -58 148 -53 0 -62 -22 -62 -147 l0
            -113 -348 0 -348 0 -55 417 -55 418 108 300 c59 165 113 311 119 324 10 24 10
            24 128 18 66 -4 131 -11 145 -17z m-614 -689 c0 -14 90 -700 96 -738 l6 -33
            -116 0 c-98 0 -116 2 -116 16 0 16 86 678 95 732 4 19 12 32 20 32 8 0 15 -4
            15 -9z m766 -57 c74 -35 106 -140 66 -214 -24 -45 -85 -80 -138 -80 -77 0
            -154 77 -154 153 0 114 122 190 226 141z'
        />
    </g>
)

export const DoctorWoman = () => (
    <g>
        <path
            d='M229.4,887.1v270h45v-270c0-11.7,2.7-23.3,8-33.8L242,833.9C233.7,850.5,229.4,868.7,229.4,887.1L229.4,887.1z
		'
        />
        <path
            d='M895.2,768.6l-80.5-15.7v-12.7c0-23.3-9.5-45.6-26.4-62.1c-16.9-16.5-39.8-25.7-63.6-25.8v-7.4
		c27.7-15.5,51.6-36.5,70.2-61.7c18.6-25.2,31.5-54.1,37.8-84.5h4.5c26.6,0,51.9-11.5,69-31.4c17.1-19.9,24.3-46.1,19.7-71.7
		c-4.6-25.6-20.6-47.8-43.6-60.8v-41.3c37.5-12.9,67.3-41.3,81.4-77.7s11-76.9-8.4-110.8c-19.4-33.9-53.1-57.8-92.2-65.1
		c-39.1-7.4-79.5,2.4-110.5,26.8c-36.2-19.2-76.7-29.2-117.9-29.2h-45C537,37.4,485.8,53.7,443.5,84l26.6,35.4
		c34.6-24.8,76.5-38.1,119.5-38h45c53.7,0.1,105.2,20.9,143.2,57.9s59.3,87.3,59.4,139.7V323h-8.6l-83.8-163.5
		c-3-5.9-8.7-10.2-15.3-11.6c-6.6-1.4-13.6,0.2-18.9,4.3c-2.1,1.6-196,152-323.6,169.3V279c0-49,18.6-96.3,52.4-132.6L405.9,117
		c-41.2,44.4-64.1,102.2-64,162.1v55.7c-23,13-39,35.2-43.6,60.8c-4.6,25.6,2.6,51.8,19.7,71.7c17.1,19.9,42.4,31.4,69,31.4h4.5
		c6.3,30.5,19.2,59.3,37.8,84.5c18.6,25.2,42.6,46.3,70.3,61.7v7.4c-23.9,0-46.8,9.3-63.6,25.8c-16.9,16.5-26.4,38.8-26.4,62.1v12.7
		l-80.6,15.7c-15.9,3.1-31,9.2-44.5,18l25,36.5h0c8.6-5.6,18.2-9.5,28.3-11.4l71.7-14v164.9c-28.5,7.2-51.7,27.5-61.9,54.5
		c-10.2,27-6.3,57.1,10.5,80.7c16.8,23.6,44.4,37.7,73.9,37.7c29.5,0,57.1-14.1,73.9-37.7c16.8-23.6,20.8-53.7,10.5-80.7
		c-10.3-27-33.4-47.3-61.9-54.5V788.9l8.2-1.6l60.8,158.2l66.1,171.9v39.6h45v-39.6l66.1-171.9l60.8-158.2l8.2,1.6v129.1
		c-25.4,5.1-48.2,18.6-64.6,38.2c-16.4,19.6-25.4,44.1-25.4,69.4v109.8c0,5.8,2.4,11.4,6.6,15.5c4.2,4.1,9.9,6.4,15.9,6.4h45v-43.9
		h-22.5v-87.8c0-23.5,12.9-45.3,33.8-57.1c20.9-11.8,46.6-11.8,67.5,0c20.9,11.8,33.8,33.5,33.8,57.1v87.8h-22.5v43.9h45
		c6,0,11.7-2.3,15.9-6.4c4.2-4.1,6.6-9.7,6.6-15.5v-109.8c0-25.3-9-49.8-25.4-69.4c-16.4-19.6-39.2-33.1-64.6-38.2V797.7l71.7,14
		c17.8,3.5,33.9,12.9,45.4,26.7c11.5,13.7,17.9,30.9,17.9,48.7v236.5h45V887.1c-0.1-27.9-10-54.9-28.1-76.5
		C948.4,789,923.2,774.2,895.2,768.6L895.2,768.6z M792.3,93c27.9-15.6,62.1-15.5,89.9,0.2c27.8,15.7,44.9,44.7,44.9,76
		c0,15.8-4.4,31.4-12.8,45c-8.3,13.6-20.2,24.8-34.5,32.3C871.5,186.5,840.2,131.7,792.3,93L792.3,93z M477,1047.6
		c0,11.6-4.7,22.8-13.2,31.1c-8.4,8.2-19.9,12.9-31.8,12.9s-23.4-4.6-31.8-12.9c-8.4-8.2-13.2-19.4-13.2-31.1s4.7-22.8,13.2-31.1
		c8.4-8.2,19.9-12.9,31.8-12.9c11.9,0,23.4,4.6,31.8,12.9S477,1036,477,1047.6z M679.6,711.6L612,810.4l-67.5-98.8v-47.3
		c44,13.4,91.1,13.4,135.1,0V711.6z M518.5,752.7l66,96.5l-33.3,43.3l-48-124.9L518.5,752.7z M569.9,941.4l42.1-54.8l42.1,54.8
		L612,1051L569.9,941.4z M672.9,892.6l-33.3-43.3l66-96.5l15.4,15L672.9,892.6z M837.1,454.7v-87.8c16.1,0,30.9,8.4,39,22
		c8,13.6,8,30.3,0,43.9C868.1,446.4,853.2,454.7,837.1,454.7z M341.9,410.8c0-11.6,4.8-22.8,13.2-31s19.9-12.9,31.8-12.9v87.8
		c-11.9,0-23.4-4.6-31.8-12.9C346.7,433.6,341.9,422.5,341.9,410.8z M432,454.7v-98.2c108.4-30,234.5-116.8,284.7-153.6l75.4,147.2
		v104.6c0,62.8-34.3,120.8-90,152.1c-55.7,31.4-124.4,31.4-180.1,0C466.3,575.5,432,517.5,432,454.7L432,454.7z M454.5,740.2
		c0-11.6,4.8-22.8,13.2-31c8.4-8.2,19.9-12.9,31.8-12.9v12.9l-33.6,32.8l-11.4,2.2L454.5,740.2z M758.2,741.9l-33.6-32.8v-12.9
		c11.9,0,23.4,4.6,31.8,12.9c8.4,8.2,13.2,19.4,13.2,31v4L758.2,741.9z'
        />
        <path
            d='M544.5,377.9c0-8.7-3.6-17.1-9.9-23.3c-6.3-6.2-14.9-9.6-23.9-9.6c-9,0-17.5,3.5-23.9,9.6
		c-6.3,6.2-9.9,14.6-9.9,23.3s3.6,17.1,9.9,23.3c6.3,6.2,14.9,9.6,23.9,9.6c9,0,17.5-3.5,23.9-9.7
		C540.9,395,544.5,386.6,544.5,377.9L544.5,377.9z'
        />
        <path
            d='M713.3,410.8c9,0,17.5-3.5,23.9-9.6c6.3-6.2,9.9-14.6,9.9-23.3c0-8.7-3.6-17.1-9.9-23.3
		c-6.3-6.2-14.9-9.6-23.9-9.6s-17.5,3.5-23.9,9.6c-6.3,6.2-9.9,14.6-9.9,23.3c0,8.7,3.6,17.1,9.9,23.3
		C695.8,407.3,704.4,410.8,713.3,410.8L713.3,410.8z'
        />
        <path
            d='M612,542.6c17.9,0,35.1-7,47.7-19.3c12.7-12.4,19.8-29.1,19.8-46.6h-45c0,7.8-4.3,15.1-11.3,19
		c-7,3.9-15.5,3.9-22.5,0c-7-3.9-11.3-11.2-11.3-19h-45c0,17.5,7.1,34.2,19.8,46.6C577,535.6,594.1,542.5,612,542.6z'
        />
        <path d='M499.5,1113.5h45v43.9h-45V1113.5z' />
    </g>
)

export const DoctorMan = () => (
    <g>
        <path d='M157.8,916v237.8h42.1V916c0.1-26.1,12.2-50.8,32.9-66.8l-25.7-33.4C176.1,839.9,157.9,876.8,157.8,916z' />
        <path
            d='M952.2,795l-120.5-36.2v-5.4c0-22.3-8.9-43.7-24.7-59.5c-15.8-15.8-37.2-24.7-59.5-24.7v-64.7
		c24.8-27.3,41.9-60.7,49.6-96.8c3.2-15.8,5.7-32,7.6-49.1h6c30.1,0,57.9-16.1,72.9-42.1c15-26.1,15-58.2,0-84.2
		c-15-26.1-42.9-42.1-72.9-42.1V166.4c0-25.3-10.8-49.3-29.6-66.2c-18.8-16.9-43.9-24.9-69-22.1c-30-26.3-68.5-40.8-108.3-40.6
		h-87.8c-33.5,0-65.6,13.4-89.3,37s-37,55.8-37,89.3c0,5.6,2.2,10.9,6.2,14.9c3.9,3.9,9.3,6.2,14.9,6.2h53.7
		c-9.5,21.2-20.4,41.7-32.7,61.4c0,0,0.2-28.6,0.7-33.2l-41.9-4.4c-0.6,6-1,81.5-1,81.5c-30.1,0-57.9,16.1-72.9,42.1
		c-15,26.1-15,58.2,0,84.2c15,26.1,42.9,42.1,72.9,42.1h6c2,17.3,4.5,33.5,7.6,49.1c7.2,36.3,24.4,69.9,49.5,97v64.4
		c-22.3,0-43.7,8.9-59.5,24.7c-15.8,15.8-24.7,37.2-24.7,59.5v5.4L259,791.7l12.1,40.3l97.3-29.2v163.9
		c-26.7,6.9-48.3,26.4-57.9,52.3c-9.6,25.8-5.9,54.8,9.8,77.4c15.7,22.6,41.6,36.1,69.1,36.1s53.4-13.5,69.1-36.1
		c15.7-22.6,19.4-51.5,9.8-77.4c-9.6-25.9-31.2-45.4-57.9-52.3v-177l31.4-10.5l137,274v100.6h42.1v-100.6l137-274l31.4,10.5v134v0
		c-29.4,5-56.1,20.3-75.4,43.1c-19.3,22.8-29.9,51.7-29.9,81.5v84.2c0,5.6,2.2,10.9,6.2,14.9c3.9,3.9,9.3,6.2,14.9,6.2h42.1v-42.1
		h-21.1v-63.2c0-30.1,16.1-57.9,42.1-72.9c26.1-15,58.2-15,84.2,0c26.1,15,42.1,42.9,42.1,72.9v63.2h-21.1v42.1h42.1
		c5.6,0,10.9-2.2,14.9-6.2c3.9-3.9,6.2-9.3,6.2-14.9v-84.2c0-29.8-10.6-58.7-29.9-81.5c-19.3-22.8-46-38-75.4-43.1V802.8l108.4,32.5
		c17.4,5.1,32.6,15.8,43.5,30.3c10.8,14.5,16.6,32.2,16.5,50.4v91h42.1v-91c0.1-27.2-8.6-53.7-24.8-75.5
		C1001.1,818.7,978.3,802.7,952.2,795L952.2,795z M810.6,335.3c0-1,0-2,0-3v0c15,0,28.9,8,36.5,21.1c7.5,13,7.5,29.1,0,42.1
		c-7.5,13-21.4,21.1-36.5,21.1C806.6,416.5,810.5,343.5,810.6,335.3L810.6,335.3z M389.4,416.5c-15,0-28.9-8-36.5-21.1
		c-7.5-13-7.5-29.1,0-42.1s21.4-21.1,36.5-21.1c0,1,2.2,83.1,2.3,84.2H389.4z M431.5,1048.3c0,11.2-4.4,21.9-12.3,29.8
		c-7.9,7.9-18.6,12.3-29.8,12.3c-11.2,0-21.9-4.4-29.8-12.3c-7.9-7.9-12.3-18.6-12.3-29.8c0-11.2,4.4-21.9,12.3-29.8
		c7.9-7.9,18.6-12.3,29.8-12.3c11.2,0,21.9,4.5,29.8,12.3C427.1,1026.4,431.5,1037.1,431.5,1048.3z M436,736.8l-24.6,8.2
		c1.7-8.1,5.7-15.5,11.5-21.3c5.8-5.8,13.2-9.8,21.3-11.5L436,736.8z M726.4,119.9c11.5,1.2,22.2,6.6,30,15.2
		c7.8,8.6,12.1,19.8,12.1,31.4v76.7c-15.6-27.1-29.7-55-42.1-83.6V119.9z M603.6,79.6c29.6-0.1,58.3,10.6,80.6,30.2v33h-32
		l-50.5-63.2H603.6z M515.8,79.6h32l50.5,63.2h-52.8l-58.2-58.2C496.5,81.3,506.1,79.6,515.8,79.6L515.8,79.6z M434.2,142.8
		c3.3-12.5,9.4-24.2,17.8-34l34,34H434.2z M435.4,435.7c-3.4-38.4-3.9-77.3-3.9-117c32.5-40.6,59-85.7,78.5-133.9h181.5
		c11.9,26.8,41.5,88.9,77,133.8c0,39-0.4,78.1-3.9,117c-1.8,21.4-4.8,42.7-8.9,63.8c-7.7,35.8-27.1,68-55,91.6
		c-30.3,24.9-68.8,37.6-108,35.8c-39.2-1.8-76.3-18.1-104.2-45.6C449.6,542.9,440.1,487.9,435.4,435.7L435.4,435.7z M705.3,639.4
		v42.2L600,786.9L494.7,681.6v-42.1c31.7,19.5,68.1,29.8,105.3,29.8C637.2,669.2,673.6,658.9,705.3,639.4L705.3,639.4z M479.1,759.4
		l-3.8-7.5l7.5-22.6l90.6,90.6l-35,58.3L479.1,759.4z M600,1001.2l-39.2-78.3l39.2-65.3l39.2,65.3L600,1001.2z M720.9,759.4
		l-59.4,118.8l-35-58.3l90.6-90.6l7.5,22.6L720.9,759.4z M764.1,736.8l-8.2-24.6l0,0c8.1,1.7,15.5,5.7,21.3,11.5
		c5.8,5.8,9.8,13.2,11.5,21.3L764.1,736.8z'
        />
        <path d='M1000.1,1042.1h42.1v111.7h-42.1V1042.1z' />
        <path
            d='M536.8,336.5c0,17.4-14.1,31.6-31.6,31.6c-17.4,0-31.6-14.1-31.6-31.6c0-17.4,14.1-31.6,31.6-31.6
		C522.7,304.9,536.8,319,536.8,336.5'
        />
        <path
            d='M726.3,336.5c0,17.4-14.1,31.6-31.6,31.6s-31.6-14.1-31.6-31.6c0-17.4,14.1-31.6,31.6-31.6
		S726.3,319,726.3,336.5'
        />
        <path
            d='M694.8,476.8l-21.2-36.4c-19.8,11.6-47.7,18.2-76.5,18.2h-39.2v42.1h39.2C633.7,500.8,668.4,492.3,694.8,476.8
		L694.8,476.8z'
        />
        <path d='M494.7,1090.4h42.1v42.1h-42.1V1090.4z' />
    </g>
)

/*
 * For the sake of ✨ diversity ✨, we pick a random doctor icon to display.
 */
export default function DoctorIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...DEFAULT_ICON_PROPS} {...props}>
            {sample([true, false]) ? <DoctorMan /> : <DoctorWoman />}
        </SvgIcon>
    )
}
