import * as React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import Button from '../../Components/Button/Button'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {SNOWBOX_EXTRACTION_OPTIONS, SNOWBOX_SKY_OPTIONS, SNOWBOX_TEST_OPTIONS} from './Offices'

const renderCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return (
        <Button variant='text' to={`/dashboard/praksisnett/offices/${params.row.office.id}`}>
            {params.value}
        </Button>
    )
}

const columnVisibilityModel = {
    'office.id': false,
    createdAt: false,
    'office.orgNr': false,
    'office.address': false,
    'office.county.name': false,
    'office.county.id': false,
    'office.signedContract': false,
    'office.createdAt': false,
    'office.snowbox.online': false,
    'office.snowbox.status': false,
    'office.snowbox.journal': false,
    'office.snowbox.deployedAt': false,
    'office.snowbox.drcInstalled': false,
    'office.snowbox.extraction': false,
    'office.snowbox.test': false,
    'office.snowbox.sky': false,
    'office.snowbox.cancelledAt': false,
    'office.snowbox.cancelledReason': false,
    'office.snowbox.location': false,
    'office.snowbox.invitationAvailable': false,
    'office.snowbox.notes': false,
}

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'System-ID', type: 'number'}),
    columnDef({field: 'office.id', headerName: 'Legekontor ID', type: 'number'}),
    columnDef({field: 'office.name', headerName: 'Navn', type: 'string', renderCell}),
    columnDef({field: 'year', headerName: 'År', type: 'number'}),
    dateColumnDef({field: 'invoiceReceived', headerName: 'Faktura mottatt', type: 'date'}),
    dateColumnDef({field: 'invoicePaid', headerName: 'Fakuta betalt', type: 'date'}),
    columnDef({field: 'paid', headerName: 'Betalt', type: 'number'}),
    dateColumnDef({field: 'createdAt', headerName: 'Opprettet', type: 'date'}),
    columnDef({field: 'office.region', headerName: 'Region', type: 'string'}),
    columnDef({field: 'office.orgNr', headerName: 'Orgnr.', type: 'number'}),
    columnDef({field: 'office.address', headerName: 'Adresse', type: 'string'}),
    columnDef({field: 'office.county.name', headerName: 'Kommune', type: 'string'}),
    columnDef({field: 'office.county.id', headerName: 'Kommunenr', type: 'number'}),
    columnDef({field: 'office.signedContract', headerName: 'På kontrakt', type: 'boolean'}),
    dateColumnDef({field: 'office.createdAt', headerName: 'Opprettet', type: 'dateTime'}),

    columnDef({field: 'office.snowbox.online', headerName: 'Snowbox Online', type: 'boolean'}),
    columnDef({field: 'office.snowbox.status', headerName: 'Snowbox Status', type: 'string'}),
    columnDef({field: 'office.snowbox.journal', headerName: 'Snowbox Journal', type: 'string'}),
    dateColumnDef({field: 'office.snowbox.deployedAt', headerName: 'Snowbox Utplassert', type: 'dateTime'}),
    columnDef({field: 'office.snowbox.drcInstalled', headerName: 'Snowbox DRC Innstallert', type: 'boolean'}),
    selectColumnDef({
        field: 'office.snowbox.extraction',
        headerName: 'Snowbox Uttrekk',
        valueOptions: SNOWBOX_EXTRACTION_OPTIONS,
    }),
    selectColumnDef({field: 'office.snowbox.test', headerName: 'Snowbox Test', valueOptions: SNOWBOX_TEST_OPTIONS}),
    selectColumnDef({field: 'office.snowbox.sky', headerName: 'Snowbox Sky', valueOptions: SNOWBOX_SKY_OPTIONS}),
    dateColumnDef({field: 'office.snowbox.cancelledAt', headerName: 'Snowbox Avlyst', type: 'dateTime'}),
    columnDef({field: 'office.snowbox.cancelledReason', headerName: 'Snowbox Avlyst grunn', type: 'string'}),
    columnDef({field: 'office.snowbox.location', headerName: 'Snowbox Plassering', type: 'string'}),
    columnDef({field: 'office.snowbox.invitationAvailable', headerName: 'Snowbox Tilgjengelig Invitasjon', type: 'boolean'}),
    columnDef({field: 'office.snowbox.notes', headerName: 'Snowbox Notater', type: 'string'}),
]
export const Compensations = () => {
    const officesResponse = useSkilQuery<'getAllPraksisNettOfficeCompensationsCollection'>('/api/praksisnett/office/compensations', {
        pagination: false,
    })
    console.log('officesResponse', officesResponse)

    let compensations = officesResponse.data?.['hydra:member'] ?? []

    return (
        <DataGridWrapper rows={compensations.length}>
            <DataGrid
                id={'compensations'}
                loading={officesResponse.isLoading || officesResponse.isFetching}
                rows={compensations}
                columns={columns}
                initModel={{columnVisibilityModel}}
            />
        </DataGridWrapper>
    )
}
