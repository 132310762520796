import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import Button from '../../../Components/Button/Button'
import {IndicatorProps} from './index'
import {InformationIndicatorType} from '../../Types'
import {toast} from 'react-toastify'

const StyledContainer = styled.div`
    img {
        margin-bottom: 1em;
    }
`
let onlySelection = false

function strip(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
}

function fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        let successful = document.execCommand('copy')
        let msg = successful ? 'successful' : 'unsuccessful'
    } catch (err) {
        console.error('Teksten kunne ikke kopieres til utklippstavlen: ', err)
    }

    document.body.removeChild(textArea)
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(
        function () {
            console.log('Teksten ble kopiert til utklippstavlen')
        },
        function (err) {
            toast('Teksten kunne ikke kopieres til utklippstavlen: ', err)
        }
    )
}

function getContentToCopy(indicatorContent) {
    let onlyTextContent = strip(indicatorContent)
    // @ts-expect-error Fix
    let contentToCopy: string = onlyTextContent?.match(`\\[\\[COPY-AREA\\]\\]((.|\\n)*)\\[\\[\\/COPY-AREA\\]\\]`)
    if (contentToCopy && contentToCopy[1]) {
        contentToCopy = contentToCopy[1].trim()
        onlySelection = true
    } else {
        onlySelection = false
    }
    return contentToCopy
}

function getContent(indicatorContent) {
    let styleToBeInserted =
        '<span id="textToCopy" style="background-color:lightgrey;" title="Kopier denne teksten ved å klikke på knappen Kopier innhold">'
    let endOfStyleToBeInserted = '</span>'
    let firstInsertPosition = 0
    let lastInsertPosition = 0
    let contentAfterFirstInsert = ''
    let contentAfterLastInsert = indicatorContent

    let contentToCopy = getContentToCopy(indicatorContent)
    if (contentToCopy) {
        firstInsertPosition = indicatorContent.indexOf(contentToCopy)
        contentAfterFirstInsert = [
            indicatorContent.slice(0, firstInsertPosition),
            styleToBeInserted,
            indicatorContent.slice(firstInsertPosition),
        ].join('')
        lastInsertPosition = contentAfterFirstInsert.indexOf(contentToCopy) + contentToCopy.length
        contentAfterLastInsert = [
            contentAfterFirstInsert.slice(0, lastInsertPosition),
            endOfStyleToBeInserted,
            contentAfterFirstInsert.slice(lastInsertPosition),
        ].join('')
    }

    contentAfterLastInsert = contentAfterLastInsert.replace('[[COPY-AREA]]', '') // remove start tag
    contentAfterLastInsert = contentAfterLastInsert.replace('[[/COPY-AREA]]', '') // remove end tag

    return contentAfterLastInsert
}

const InformationIndicator = ({indicator}: IndicatorProps<InformationIndicatorType>) => {
    const divRef = useRef<HTMLDivElement>()
    let content = getContent(indicator.content)
    const onCopy = () => {
        let contentToCopy = getContentToCopy(indicator.content)
        if (contentToCopy) {
            copyTextToClipboard(contentToCopy)
            const element = document.getElementById('textToCopy')
            if (element) {
                element.style.backgroundColor = 'lightskyblue'
            }
        } else {
            copyTextToClipboard(strip(indicator.content).trim())
        }
    }

    useEffect(() => {
        divRef.current?.querySelectorAll('a').forEach(el => {
            el.setAttribute('target', '_blank')
        })
    })

    let buttonText = 'Kopiert alt innhold'
    if (onlySelection) {
        buttonText = 'Kopier utvalg i grått'
    }

    return (
        <div>
            {/* @ts-expect-error */}
            <StyledContainer ref={divRef} dangerouslySetInnerHTML={{__html: content}} />
            {indicator.copyFunction && (
                <Button className={'btn btn-info'} style={{color: 'white'}} onClick={onCopy}>
                    {buttonText}
                </Button>
            )}
        </div>
    )
}
export default InformationIndicator
