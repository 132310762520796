import React, {useEffect, useState} from 'react'
import Panel from '../../../Components/Panel'
import {useOutletContext} from 'react-router-dom'
import TextEditor from '../../../Components/TextEditor/TextEditor'
import {Box} from '@mui/material'
import Image from '../../Setup/Components/Image'
import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import _ from 'lodash'
import SubmitBar from '../Components/SubmitBar'
import OfficeSkeleton from '../Skeletons/OfficeSkeleton'
import {styled} from '@mui/material/styles'
import Button from '@mui/material/Button'
import {ArrowDownward, ArrowUpward, Delete, ModeEdit} from '@mui/icons-material'

const validationSchema = z.object({
    employees: z.array(
        z.object({
            content: z.string(),
            link: z.string().optional(),
            icon: z.string().optional(),
            isImage: z.boolean().optional(),
        })
    ),
})

type ValidationSchema = z.infer<typeof validationSchema>

const Employees = () => {
    const {nefle, setProgress, isGenerated, next}: any = useOutletContext()
    const nefleSetupMutation = useSkilMutation<'patchOfficeNefleItem'>('PATCH', `/api/office_nefles/${nefle.id}`)

    const {
        register,
        resetField,
        reset,
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: {errors, isDirty, isSubmitting},
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
    })

    /*
         Populate form with data from Context
      */
    useEffect(() => {
        reset({
            ...nefle.siteConfig,
        })
    }, [nefle])

    /*
        Submit everything, will only run if validated by zod, check errors variable if not.
        We only have data for this page in the form, so merging data from context is very important.
    */
    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        let siteConfig = nefle.siteConfig
        siteConfig.progress['/ansatte'] = true

        const merged = _.merge(siteConfig, data)

        merged.employees = data.employees

        try {
            await nefleSetupMutation.mutateAsync({
                siteConfig: {...merged},
            })
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            /*
                Reset form to update isDirty validation
                progress functions from context
            */
            reset({...merged})
            setProgress(merged.progress)
        }
    }

    const employees = getValues('employees')

    if (!employees) return <OfficeSkeleton />

    return (
        <Panel variant={'flat'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <EmployeesMap
                    officeId={nefle.id}
                    disabled={isGenerated}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    resetField={resetField}
                    register={register}
                    isDirty={isDirty}
                />
                {/*
               One for every page, ties to context
            */}
                <SubmitBar
                    shouldSpin={isSubmitting}
                    isDirty={isDirty}
                    onDiscard={() => reset()}
                    isCompleted={nefle?.siteConfig?.progress && nefle.siteConfig?.progress['/ansatte']}
                    next={() => {
                        next()
                    }}
                    hasError={Object.keys(errors).length > 0}
                />
            </form>
        </Panel>
    )
}

export default Employees

const EmployeesMap = ({officeId, control, setValue, isDirty, watch, disabled, resetField, register}) => {
    const {fields, append, remove, swap, prepend, update} = useFieldArray({
        name: 'employees',
        control,
        rules: {minLength: 1},
    })

    const watchFieldArray = watch('employees')
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        }
    })

    const [defaultValue, setDefaultValue] = useState<any>()

    useEffect(() => {
        setDefaultValue({...controlledFields})
    }, [controlledFields[0]?.id])

    return (
        <Box>
            {controlledFields.map((employee, index) => {
                return (
                    <Box key={employee.id}>
                        <Employee
                            officeId={officeId}
                            disabled={disabled}
                            data={employee}
                            onChange={data => {
                                update(index, data)
                            }}
                            isDirty={isDirty}
                            onReset={() => {
                                let valueKey: string | null =
                                    Object.keys(defaultValue).find(key => {
                                        return defaultValue[key].id == employee.id
                                    }) ?? null

                                defaultValue && update(index, valueKey ? defaultValue[valueKey] : defaultEmployee)
                            }}
                            moveUp={() => {
                                if (index === 0) {
                                    remove(index)
                                    append(employee)
                                } else {
                                    swap(index, index - 1)
                                }
                            }}
                            moveDown={() => {
                                if (index === fields.length - 1) {
                                    remove(index)
                                    prepend(employee)
                                } else {
                                    swap(index, index + 1)
                                }
                            }}
                            remove={() => remove(index)}
                        />
                    </Box>
                )
            })}
            <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
                <Button
                    disabled={disabled}
                    variant={'contained'}
                    onClick={() => {
                        append(defaultEmployee)
                        setDefaultValue({...defaultValue, defaultEmployee})
                    }}
                >
                    Legg til ansatt
                </Button>
            </Box>
        </Box>
    )
}

const defaultEmployeeContent = '<h1>Ole Olsen</h1><p>Litt informasjon om meg</p>'
const defaultImage = '/defaultEmployee.svg'

const defaultEmployee = {
    icon: 'user-md',
    isImage: false,
    content: defaultEmployeeContent,
    link: '',
}
const Employee = ({officeId, data, remove, isDirty, onChange, moveUp, moveDown, disabled, onReset}) => {
    const [editing, setEditing] = useState<any>()
    const [tmpImage, setTmpImage] = useState()

    const [employeeData, setEmployeeData] = useState<any>()

    useEffect(() => {
        setEmployeeData(data)
    }, [data.id])

    if (!employeeData) return <div></div>

    const getUrl = () => {
        if (tmpImage) return tmpImage
        if (employeeData.isImage && employeeData.link) return employeeData.link

        return defaultImage
    }

    const handleOnchange = obj => {
        setEmployeeData(prev => {
            onChange({...prev, ...obj})
            return {...prev, ...obj}
        })
    }

    return (
        <Panel variant={'flat'}>
            <Box sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '.1em', sm: '2em'}}}>
                <div className={'row'}>
                    <div className={'col-sm-4'}>
                        <Image
                            officeId={officeId}
                            alt={'Employee image'}
                            editing={editing}
                            onChange={url => {
                                handleOnchange({link: url, isImage: true})
                            }}
                            link={getUrl()}
                        />
                    </div>
                    <div className={'col-sm-8'}>
                        {editing ? (
                            <TextEditor
                                key={employeeData.id}
                                id={'employee-form ' + data.id}
                                style={{padding: 0}}
                                value={employeeData.content}
                                onChange={val => {
                                    handleOnchange({content: val})
                                }}
                            />
                        ) : (
                            <div dangerouslySetInnerHTML={{__html: employeeData.content}} />
                        )}
                    </div>
                </div>
            </Box>
            <EmployeeButtonBox
                sx={{
                    display: 'flex',
                    justifyContent: {xs: 'center', md: 'flex-end'},
                    marginBottom: {xs: '1em', sm: '2em'},
                }}
            >
                {editing ? (
                    <>
                        <Button
                            variant={'contained'}
                            disabled={employeeData.isImage === false}
                            onClick={() => {
                                onChange({...employeeData, link: defaultImage, isImage: true})
                                setEmployeeData({...employeeData, link: defaultImage, isImage: true})
                                setTmpImage(undefined)
                            }}
                        >
                            Fjern bilde
                        </Button>

                        <Button
                            variant={'contained'}
                            onClick={() => {
                                onReset()
                                setEditing(false)
                            }}
                        >
                            Avbryt
                        </Button>

                        <Button
                            variant={'contained'}
                            disabled={!isDirty}
                            onClick={() => {
                                setEditing(false)
                            }}
                        >
                            Oppdater
                        </Button>
                    </>
                ) : (
                    <>
                        {
                            <Button startIcon={<ArrowDownward />} variant={'contained'} disabled={disabled} onClick={() => moveDown()}>
                                Ned
                            </Button>
                        }
                        {
                            <Button startIcon={<ArrowUpward />} variant={'contained'} disabled={disabled} onClick={() => moveUp()}>
                                Opp
                            </Button>
                        }

                        <Button startIcon={<Delete />} variant={'contained'} disabled={disabled} onClick={() => remove()}>
                            Fjern
                        </Button>

                        <Button startIcon={<ModeEdit />} variant={'contained'} disabled={disabled} onClick={() => setEditing(!editing)}>
                            Rediger
                        </Button>
                    </>
                )}
            </EmployeeButtonBox>
        </Panel>
    )
}

const EmployeeButtonBox = styled(Box)`
    ${props => props.theme.breakpoints.down('md')} {
        button {
            min-height: 38px;
            font-size: 1rem;
        }
    }
    button:nth-child(n + 2) {
        margin-left: 4px;
    }
`
