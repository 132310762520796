import * as React from 'react'
import Button from '../../Components/Button/Button'
import {GridCellParams, GridColDef, GridRenderCellParams} from '@mui/x-data-grid-premium'
import {sortComparator, resolve, customValueGetter} from '../../Components/DataGrid/columnDefs/columnDef'

const renderCell = (params: GridRenderCellParams) => {
    if (!params.value) return <span>ingen navn</span>

    const office = resolve(params.field, params.row)
    if (typeof office !== 'object') {
        return params.value
    }

    return (
        <Button variant='text' href={`/dashboard/praksisnett/offices/${office.id}`}>
            {office.name}
        </Button>
    )
}

const getApplyOfficeQuickFilterFn = (value: string) => {
    if (!value) {
        return null
    }
    return (params: GridCellParams): boolean => (typeof params.value === 'string' ? params.value.includes(value) : false)
}

type Column = GridColDef & {
    type?: 'praksisnett_office_type'
}
export default function officeColumnDef(col: Column): GridColDef {
    let valueGetter = customValueGetter(col.valueGetter, 'name')

    return {
        flex: 2,
        sortComparator,
        renderCell,
        type: 'string',
        headerName: 'Legekontor',
        ...col,
        valueGetter,
        getApplyQuickFilterFn: getApplyOfficeQuickFilterFn,
    }
}
