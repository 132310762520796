import React, {useState} from 'react'
import Modal from './Modal'
import TextField from './Fields/TextField'
import useEntities from '../Hooks/useEntities'
import SelectField from './Fields/SelectField'
import LoadingComponent from './LoadingComponent'
import {CountyAutocomplete} from './CountyAutocomplete'
import {CourseType} from './CourseAutocomplete'
import {toast} from 'react-toastify'
export default function CreateNewGroupModal({onSave, onClose, description = undefined}) {
    const [spinnerWhileSaving, setSpinnerWhileSaving] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [county, setCounty] = useState<any>(null)
    const [district, setDistrict] = React.useState<string | undefined>(undefined)
    const [countiesRaw, setCountiesRaw] = React.useState<any>(undefined)
    const [activeCourse, setActiveCourse] = useState<null | CourseType>(null)
    const courses = useEntities<'getCourseCollection'>(`/api/courses?type=1`)

    const countyDistricts = county
        ? countiesRaw
              ?.find(c => c.id === county)
              ?.districts?.map(district => {
                  let iri = district['@id'] as string
                  return {label: district.name, id: iri}
              })
              .sort(function (a, b) {
                  if (a.label < b.label) {
                      return -1
                  }
                  if (a.label > b.label) {
                      return 1
                  }
                  return 0
              })
        : []

    const onSubmit = () => {
        // if the county has districts, we need to make sure one is selected
        if (countyDistricts?.length > 0 && !district) {
            toast('Du må velge en bydel', {type: 'warning'})
            return
        }
        onSave({
            name: groupName,
            county: county.id.substring('/api/counties/'.length),
            course: activeCourse?.shortTitle ?? null,
            district: district?.substring('/api/districts/'.length),
        })
        setSpinnerWhileSaving(true)
    }
    let saveText = 'Opprett'
    let allContentLoaded = courses['@loaded']
    const onSaveEnabled = groupName?.length > 0 && county
    return (
        <Modal
            title={`Opprett en ny gruppe`}
            onSave={onSaveEnabled ? onSubmit : undefined}
            onClose={onClose}
            saveText={saveText}
            spinnerWhileSaving={spinnerWhileSaving}>
            {!courses['@loaded'] && <LoadingComponent />}
            {description}

            {allContentLoaded && (
                <TextField
                    id={'groupname'}
                    label={'Gruppenavn'}
                    placeholder={'Lag et gruppenavn'}
                    value={groupName}
                    onChange={setGroupName}
                />
            )}
            {allContentLoaded && (
                <CountyAutocomplete
                    sx={{mb: 2}}
                    value={county}
                    required
                    onChange={(e, val, iri, district, countiesRaw) => {
                        setCounty(val)
                        setDistrict(district?.id)
                        setCountiesRaw(countiesRaw)
                    }}
                />
            )}
            {allContentLoaded && (
                <SelectField<CourseType>
                    id={'activeCourse'}
                    label={'Kurs'}
                    value={activeCourse}
                    entities={courses}
                    onChange={setActiveCourse}
                />
            )}
        </Modal>
    )
}
