import {Grid, Paper, TextField} from '@mui/material'
import Box from '@mui/system/Box'
import {Button} from '../../Components/Button/MuiButton'
import React, {useRef} from 'react'

type Props = {
    redirectUrl?: string | null
    error?: string | null
}

export default function EmailAuthCard({redirectUrl, error}: Props) {
    const formRef = useRef<HTMLFormElement | null>(null)
    const href = redirectUrl ? `/login_check?redirect=${encodeURI(redirectUrl)}` : '/login_check'
    const idPortenHref = redirectUrl ? `/?redirect=${encodeURI(redirectUrl)}` : '/'

    return (
        <form ref={formRef} action={href} method='post'>
            <Paper elevation={0}>
                <Box
                    minHeight={350}
                    overflow={'hidden'}
                    padding={4}
                    display={'flex'}
                    justifyContent={'space-evenly'}
                    flexDirection={'column'}
                >
                    <TextField
                        type={'email'}
                        name={'username'}
                        required
                        autoComplete={'email'}
                        label={'E-post'}
                        placeholder={'e-postaddressen din'}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                formRef.current?.submit()
                            }
                        }}
                    />
                    <TextField
                        type={'password'}
                        name={'password'}
                        required
                        autoComplete={'password'}
                        label={'Passord'}
                        placeholder={'Ditt passord'}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                formRef.current?.submit()
                            }
                        }}
                    />
                    {error && (
                        <Box sx={{mt: 2}}>
                            <p style={{color: 'red'}}>{error}</p>
                        </Box>
                    )}
                    <Box flexGrow={1} />
                    <Button onClick={() => formRef.current?.submit()} style={{marginTop: 15}} size={'large'} fullWidth>
                        Logg inn
                    </Button>
                    <Grid mt={2} container justifyContent={'space-between'}>
                        <Grid item>
                            <Button variant={'text'} to={idPortenHref}>
                                Logg inn via ID-Porten
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'text'} to='/forgot/password/view/'>
                                Glemt passord
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </form>
    )
}
