import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import useField from '../../../Hooks/useField'
import EditText from '../../Components/EditText'
import styled from 'styled-components'
import Button from '../../../Components/Button/Button'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledRows = styled.span`
    margin: 0 0 2.5em 0;
`
const StyledEditText = styled(EditText)`
    text-align: left;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`
const StyledFormGroup = styled(FormGroup)`
    .form-control {
        cursor: not-allowed;
    }
`

const TextIndicator = ({indicator, answer, onChange, disabled = true, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer
    const rows = indicator.rows

    const onRowsSave = value => {
        indicator.changeField('rows', parseInt(value, 10))
        indicator.save()
    }

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    return (
        <>
            <StyledLabel>
                <EditText placeholder={'Legg til tittel'} text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>

            <StyledFormGroup className={'form-group'}>
                {rows <= 1 && (
                    <input
                        disabled={true}
                        name={indicator['@id']}
                        onBlur={e => onChange(e.target.value, true)}
                        onChange={e => onChange(e.target.value)}
                        value={value ? value : ''}
                        className='form-control'
                        type='text'
                    />
                )}
                {rows > 1 && (
                    <textarea
                        disabled={true}
                        rows={rows}
                        name={indicator['@id']}
                        onBlur={e => onChange(e.target.value, true)}
                        value={value ? value : ''}
                        onChange={e => onChange(e.target.value)}
                        style={{resize: 'vertical'}}
                        className='form-control'
                    />
                )}

                <StyledRows className='help-block'>
                    Antall rader:{' '}
                    <StyledEditText
                        type='number'
                        width={'4em'}
                        inline
                        placeholder='Antall rader'
                        text={indicator.rows}
                        onSave={onRowsSave}
                    />
                </StyledRows>
                <div className='help-block'>
                    <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
                </div>
                <label className='help-block'>
                    <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                    feltet er valgfritt
                </label>
            </StyledFormGroup>
        </>
    )
}
export default TextIndicator
