import React from 'react'
import useEntities from '../Hooks/useEntities'
import Modal from '../Components/Modal'
import useEntity from '../Hooks/useEntity'
import {format} from 'date-fns'

const EventLogModal = ({iri, onClose}) => {
    const consultation = useEntity<'getConsultationItem'>(iri)
    const events = useEntities<'getConsultationEventCollection'>(iri + '/events')
    const reversedEvents = events.slice().reverse()

    return (
        <Modal
            cancelText={'Skjul'}
            onClose={onClose}
            title={`${consultation.id}: Hendelser ${consultation.name ? 'for ' + consultation.name : ''}`}
        >
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>Når</th>
                        <th>Hendelse</th>
                        <th>Hvem</th>
                    </tr>
                </thead>
                <tbody>
                    {reversedEvents.map(e => (
                        <EventRow key={e['@id']} iri={e['@id']} />
                    ))}
                    {events.length === 0 && (
                        <tr>
                            <td colSpan={1} className={'text-center text-muted'}>
                                Ingen hendelser
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Modal>
    )
}
export default EventLogModal

const EventRow = ({iri}) => {
    const event = useEntity<'getConsultationEventItem'>(iri)
    if (!event['@loaded']) return <tr />

    const timestamp = new Date(event.createdAt)

    // @ts-expect-error
    const content = event.data.content
    return (
        <>
            <tr>
                <td>{format(timestamp, 'dd.MM.yy HH:mm:ss')}</td>
                <td>{event.eventString}</td>
                <td>{event.roleString}</td>
            </tr>
            {event.event === 1 && content && (
                <tr>
                    <td className={'text-muted'} colSpan={3}>
                        {content!}
                    </td>
                </tr>
            )}
        </>
    )
}
