import React, {useEffect, useRef, useState} from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import {Checkbox, ClickAwayListener, FormControlLabel, FormGroup, IconButton, styled, TextField} from '@mui/material'
import {useOutletContext} from 'react-router-dom'
import SubmitBar from '../Components/SubmitBar'
import {SubmitHandler, useForm} from 'react-hook-form'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {z} from 'zod'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {zodResolver} from '@hookform/resolvers/zod'
import OfficeSkeleton from '../Skeletons/OfficeSkeleton'
import _ from 'lodash'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

const validationSchema = z.object({
    siteTags: z.object({
        title: z.string().min(1),
    }),
    booking: z
        .object({
            booking: z.string().optional(),
            sms: z.boolean(),
            smsnumber: z.string().trim().optional(),
            smscode: z.boolean(),
            smscodeword: z.string().trim().optional(),
            video: z.boolean(),
            videoLink: z.string().trim().optional(),
            helseBokaid: z.string().optional(),
            cgmClientId: z.string().optional(),
        })
        .superRefine(({video, videoLink, smscode, smscodeword, sms, smsnumber}, refinementContext) => {
            if (video && (!videoLink || videoLink?.length <= 0)) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Kan ikke være blank',
                    path: ['videoLink'],
                })
            }
            if (smscode && (!smscodeword || smscodeword?.length <= 0)) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Kan ikke være blank',
                    path: ['smscodeword'],
                })
            }
            if (sms && (!smsnumber || smsnumber?.length <= 0)) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Kan ikke være blank',
                    path: ['smsnumber'],
                })
            }

            return refinementContext
        }),
})

type ValidationSchema = z.infer<typeof validationSchema>

const Booking = () => {
    const {nefle, setProgress, isGenerated, next}: any = useOutletContext()
    const nefleSetupMutation = useSkilMutation<'patchOfficeNefleItem'>('PATCH', `/api/office_nefles/${nefle.id}`)
    const formRef = useRef(null)
    const [renderKey, setRenderKey] = useState(Math.random().toString(36))
    const [openTooltip, setOpenTooltipTooltip] = useState(false)

    const handleTooltipToggle = () => {
        setOpenTooltipTooltip(!openTooltip)
    }

    const handleTooltipClose = () => {
        setOpenTooltipTooltip(false)
    }

    const {
        setValue,
        getValues,
        reset,
        register,
        handleSubmit,
        watch,
        formState: {errors, isDirty, isSubmitting},
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
    })

    useEffect(() => {
        reset({
            ...nefle.siteConfig,
        })
    }, [nefle])

    /*
    Submit everything, will only run if validated by zod, check errors variable if not.
    We only have data for this page in the form, so merging data from context is very important.
 */
    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        let siteConfig = nefle.siteConfig
        siteConfig.progress['/timebestilling'] = true

        const merged = _.merge(siteConfig, data)

        try {
            await nefleSetupMutation.mutateAsync({
                // @ts-expect-error
                siteConfig: {...merged},
            })
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            /*
                Reset form to update isDirty validation
                progress functions from context
            */
            reset({...merged})
            setProgress(merged.progress)
        }
    }

    const values = getValues()

    const booking = values.booking ?? {}

    if (!values || Object.keys(values).length <= 0) return <OfficeSkeleton />

    return (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Box key={renderKey} sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '1em', sm: '2em'}}}>
                <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: {xs: '1em', sm: '2em'}}}>
                    <Box
                        padding={2}
                        display={'flex'}
                        justifyContent={'space-around'}
                        alignItems={'stretch'}
                        flexDirection={'column'}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel id='booking-label'>Digital timebestilling</InputLabel>
                            <Select
                                disabled={isGenerated}
                                labelId={'booking-label'}
                                defaultValue={booking?.booking ?? ''}
                                label='Digital timebestilling'
                                {...register('booking.booking')}
                            >
                                <MenuItem value='HelseNorge'>HelseNorge</MenuItem>
                                <MenuItem value='PasientSky'>PasientSky</MenuItem>
                                <MenuItem value='CGM'>CGM ePortal / Besøklegen.no</MenuItem>
                                <MenuItem value='HelseBoka'>HelseBoka</MenuItem>
                                <MenuItem value=''>Ingen</MenuItem>
                            </Select>
                        </FormControl>
                        <FormGroup>
                            <FormControlLabel
                                disabled={isGenerated}
                                tabIndex={-1}
                                control={
                                    <StyledCheckbox
                                        tabIndex={0}
                                        defaultChecked={nefle?.siteConfig?.booking?.sms}
                                        {...register('booking.sms')}
                                    />
                                }
                                label={
                                    <>
                                        Kan pasienten sende SMS for å bestille time?
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipClose}
                                                open={openTooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={`Velg dersom legekontoret benytter en SMS timebestillingsløsning slik som f.eks. HelseRespons. Kan velges uavhengig av annen digital timebestillingsløsning.`}
                                            >
                                                <IconButton onClick={handleTooltipToggle}>
                                                    <InfoIcon style={{fill: '#11ade0'}} />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </>
                                }
                            />
                        </FormGroup>
                        <TextField
                            type={'title'}
                            autoComplete={'office'}
                            placeholder='Telefonnummer for timebestilling via SMS'
                            label={'Telefonnummer for timebestilling via SMS'}
                            disabled={!watch('booking.sms') || isGenerated}
                            error={!!errors?.booking?.smsnumber}
                            helperText={errors?.booking?.smsnumber?.message ?? ''}
                            {...register('booking.smsnumber')}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        disabled={isGenerated}
                                        defaultChecked={nefle?.siteConfig?.booking?.smscode}
                                        {...register('booking.smscode')}
                                        sx={{
                                            '&:focus': {
                                                outline: '2px solid black!important',
                                                borderRadius: '2px',
                                            },
                                        }}
                                    />
                                }
                                tabIndex={-1}
                                label='Må pasienten bruke kodeord ved SMS-bestilling?'
                            />
                        </FormGroup>
                        <TextField
                            type={'title'}
                            placeholder='Kodeord ved SMS-bestilling'
                            label={'Kodeord ved SMS-bestilling'}
                            error={!!errors?.booking?.smscodeword}
                            helperText={errors?.booking?.smscodeword?.message ?? ''}
                            disabled={!watch('booking.smscode') || isGenerated}
                            {...register('booking.smscodeword')}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        disabled={isGenerated}
                                        defaultChecked={nefle?.siteConfig?.booking?.video}
                                        {...register('booking.video')}
                                        sx={{
                                            '&:focus': {
                                                outline: '2px solid black!important',
                                                borderRadius: '2px',
                                            },
                                        }}
                                    />
                                }
                                tabIndex={-1}
                                label='Har legekontoret videokonsultasjon?'
                            />
                        </FormGroup>
                        <TextField
                            type={'title'}
                            placeholder='Lenke til videokonsultasjon'
                            label={'Lenke til videokonsultasjon'}
                            disabled={!watch('booking.video') || isGenerated}
                            error={!!errors?.booking?.videoLink}
                            helperText={errors?.booking?.videoLink?.message ?? ''}
                            {...register('booking.videoLink')}
                        />
                    </Box>
                </Box>
            </Box>
            {/*
               One for every page, ties to context
            */}
            <SubmitBar
                shouldSpin={isSubmitting}
                isDirty={isDirty}
                onDiscard={() => reset()}
                isCompleted={nefle?.siteConfig?.progress && nefle.siteConfig?.progress['/timebestilling']}
                next={() => {
                    next()
                }}
                hasError={Object.keys(errors).length > 0}
            />
        </form>
    )
}

export default Booking

const StyledCheckbox = styled(Checkbox)`
    border: 2px solid transparent;
    &:focus-within {
        border: 2px solid black;
    }
`
