import React from 'react'
import DatePicker from 'react-datepicker'
import FormGroup from '../../../Components/FormGroup'
import {IndicatorProps} from './index'
import {DateTimeIndicatorType} from '../../Types'

const DateTimeIndicator = ({indicator, answer, onChange, disabled = false}: IndicatorProps<DateTimeIndicatorType, string>) => {
    const {fresh, value, valid} = answer
    const selected = value && valid ? new Date(value) : null

    return (
        <FormGroup hasError={!disabled && !fresh && !valid && !indicator.optional} hasSuccess={!fresh && valid} size={'lg'}>
            <label>{indicator['title']}</label>
            <DatePicker
                disabled={disabled}
                name={indicator['@id']}
                onChange={date => onChange(date.toISOString(), true)}
                className={`form-control`}
                selected={selected}
                showTimeSelect={indicator.time}
                showTimeSelectOnly={!indicator.date}
                timeFormat='HH:mm'
                timeIntervals={15}
                timeCaption='Tid'
                dateFormat='dd.MM.yyyy HH:mm'
                isClearable
                inline
            />
            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
        </FormGroup>
    )
}
export default DateTimeIndicator
