import * as React from 'react'
import {Box} from '../Components/Panel'
import {Page} from '../Components/Page'
import {DataGridWrapper} from '../Components/DataGrid/Wrappers'
import DataGrid from '../Components/DataGrid/DataGrid'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../Components/DataGrid/columnDefs/columnDef'
import {useSkilQuery} from '../Utilities/QueryClient'
import dateColumnDef from '../Components/DataGrid/columnDefs/dateColumnDef'

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'ID', hide: true, type: 'number'}),
    columnDef({field: 'name', headerName: 'Kontor'}),
    columnDef({field: 'api.domain', headerName: 'Domene'}),
    columnDef({field: 'ordered', headerName: 'Bestilt'}),
    columnDef({field: 'orderedBy', headerName: 'Bestilt av'}),
    columnDef({field: 'api.generated', headerName: 'Generert', type: 'boolean'}),
    columnDef({field: 'api.nefle', headerName: 'Tilgjengelig', type: 'boolean'}),
    dateColumnDef({field: 'cancelledAt', headerName: 'Avbestilt på'}),
    columnDef({field: 'cancelledReason', headerName: 'Avbestilt årsak'}),
    columnDef({field: 'orgNr', headerName: 'Org.nr.'}),
    columnDef({field: 'address', headerName: 'Addresse'}),
    columnDef({field: 'invoiceEmail', headerName: 'Faktura epost'}),
]
const breadcrumbs = [
    {title: 'SKIL', to: '/dashboard/SKIL'},
    {
        title: 'Verktøy',
        to: '/dashboard/SKIL/tools',
    },
    {title: 'Nefle rapport'},
]
export default function NefleUsage() {
    const {data, isFetching} = useSkilQuery<'getNefleUsage'>('/api/skil/nefle-usage')

    const offices = data?.['hydra:member'] ?? []

    return (
        <Page breadcrumbs={breadcrumbs} variant={'default'}>
            <Box>
                <DataGridWrapper rows={offices.length}>
                    <DataGrid id={'nefle-usage'} loading={isFetching} rows={offices} columns={columns} disableSelectionOnClick />
                </DataGridWrapper>
            </Box>
        </Page>
    )
}
