import * as React from 'react'
import {useState} from 'react'
import {Button} from '../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextEditorField from '../../Components/Fields/TextEditorField'
import CheckboxField from '../../Components/Fields/CheckboxField'
import {styled} from '@mui/material/styles'
import {useSkilMutation} from '../../Utilities/QueryClient'
import Box from '@mui/material/Box'
import {DialogTitle} from '@mui/material'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import MenuItem from '@mui/material/MenuItem'
import RadioGroupField from '../../Components/Fields/RadioGroupField'

type EmailProp = {isEmail: true; isText?: undefined}
type TextProp = {isText: true; isEmail?: undefined}
type BaseProps = {
    onClose: Function
    isOpen: boolean
    children?: React.ReactNode
    userIris: string[]
    submitLabel?: string
}
type DialogProps = BaseProps & (EmailProp | TextProp)

// TODO: Create a new component for this?
//  It is a custom fancy separator with label for the rich text field to match the label from Mui
const LabelDivider = styled(Divider)`
    ::before {
        width: 10px;
    }
    ::before,
    ::after {
        border-top-color: #c4c4c4;
    }
    ::after {
        width: 100%;
    }

    span {
        color: #021012;
        font-size: 11px;
        padding-left: 4px;
        padding-right: 4px;
    }
`

export const SendMessageDialog = ({isOpen, isEmail, isText, onClose, userIris, children, submitLabel}: DialogProps) => {
    const sendMessageMutation = useSkilMutation<'sendMessage'>('post', '/api/messages')
    const [from, setFrom] = useState<string>('SKIL')
    const [content, setContent] = useState<string>('')
    const [subject, setSubject] = useState<string>('')
    const [dontWantGeneralInfo, setDontWantGeneralInfo] = useState(true)
    const [outreachType, setOutreachType] = useState<string>('generalInfo')

    const handleDialogClose = () => {
        setFrom('SKIL')
        setContent('')
        setDontWantGeneralInfo(true)
        return onClose()
    }

    const handleSubmit = async () => {
        try {
            if (isText) {
                // @ts-expect-error
                await sendMessageMutation.mutateAsync({content, users: userIris, from, type: 'sms', dontWantGeneralInfo, outreachType})
                toast('Forsøker å sende sms til ' + userIris.length + ' brukere')
            } else if (isEmail) {
                // @ts-expect-error
                await sendMessageMutation.mutateAsync({content, users: userIris, subject, type: 'email', dontWantGeneralInfo, outreachType})
                toast('Forsøker å sende epost til ' + userIris.length + ' brukere')
            }

            handleDialogClose()
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    return (
        <Dialog disableEnforceFocus maxWidth='md' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogTitle>Send SMS</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                    {isEmail && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    id='content'
                                    label='Emne'
                                    fullWidth
                                    value={subject}
                                    onChange={event => {
                                        setSubject(event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroupField
                                    id='generalInfoOrNewsletter'
                                    label='Generell info eller nyhetsbrev?'
                                    value={outreachType}
                                    onChange={event => {
                                        setOutreachType(event)
                                    }}
                                    dontWantGeneralInfo={dontWantGeneralInfo}
                                />
                            </Grid>
                            {outreachType === 'generalInfo' && (
                                <Grid item xs={12}>
                                    <CheckboxField
                                        id='dontWantGeneralInfo'
                                        label='Hopp over de som ikke ønsker generell info'
                                        value={dontWantGeneralInfo}
                                        defaultChecked={true}
                                        onChange={event => {
                                            setDontWantGeneralInfo(event)
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LabelDivider textAlign='left'>Innhold</LabelDivider>
                                <TextEditorField rows={10} id='content' value={content} onChange={setContent} />
                            </Grid>
                        </>
                    )}
                    {isText && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    id='from'
                                    label='Fra'
                                    select
                                    fullWidth
                                    value={from}
                                    onChange={event => {
                                        setFrom(event.target.value)
                                    }}
                                >
                                    <MenuItem value={'SKIL'}>SKIL</MenuItem>
                                    <MenuItem value={'PraksisNett'}>PraksisNett</MenuItem>
                                    <MenuItem value={'Fastlegen'}>Fastlegen</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroupField
                                    id='generalInfoOrNewsletter'
                                    label='Generell info eller nyhetsbrev?'
                                    value={outreachType}
                                    onChange={event => {
                                        setOutreachType(event)
                                    }}
                                    dontWantGeneralInfo={dontWantGeneralInfo}
                                    isText={isText}
                                />
                            </Grid>
                            {outreachType === 'generalInfo' && (
                                <Grid item xs={12}>
                                    <CheckboxField
                                        id='dontWantGeneralInfo'
                                        label='Hopp over de som ikke ønsker generell info'
                                        value={dontWantGeneralInfo}
                                        defaultChecked={true}
                                        onChange={event => {
                                            setDontWantGeneralInfo(event)
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    minRows={6}
                                    id='content'
                                    label='Melding'
                                    multiline
                                    fullWidth
                                    value={content}
                                    onChange={event => {
                                        setContent(event.target.value)
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                <DialogActions sx={{padding: 0}}>
                    <Button variant='outlined' onClick={handleDialogClose}>
                        Avbryt
                    </Button>
                    <Button disabled={userIris.length === 0} variant='contained' onClick={handleSubmit}>
                        {submitLabel ?? `Send melding til ${userIris.length} bruker(e)`}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
