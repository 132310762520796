import React from 'react'
import Slider from 'rc-slider'
import range from 'lodash/range'
import styled from 'styled-components'
import FormGroup from '../../../Components/FormGroup'
import {IndicatorProps} from './index'
import {NumberIndicatorType} from '../../Types'

const StyledDiv = styled.div`
    margin-left: 2em;
    margin-right: 2em;
    .rc-slider-track {
        background: ${props => (props.valid ? '#10596e' : '#a94442')};
    }
    .rc-slider-rail {
        background: ${props => (props.valid ? '#e9e9e9' : '#a94442')};
    }

    .rc-slider-dot {
        width: 24px;
        height: 24px;
        bottom: -10px;
        margin-left: -12px;

        border-color: ${props => (props.valid ? '#10596e' : '#a94442')};
        border-width: ${props => (props.valid ? '2px' : '1px')};
    }

    .rc-slider-handle {
        border-color: ${props => (props.valid ? '#10596e' : '#a94442')};
        background: ${props => (props.valid ? '#10596e' : '#a94442')};

        &:hover {
            border-color: ${props => (props.valid ? '#10596e' : '#873635')};
            border-width: 3px;
        }
    }
`

const NumberIndicator = ({indicator, answer, onChange, disabled = false}: IndicatorProps<NumberIndicatorType, number | null>) => {
    const {fresh, value, valid} = answer
    const isSlider = !!indicator['slider']
    const maximum = indicator['maximum']
    const minimum = indicator['minimum']

    const marks = range(minimum - 1, maximum + 1).reduce((carry, i) => {
        carry[i] = i
        return carry
    }, {})

    const isValid = disabled || fresh || valid || indicator.optional

    return (
        <FormGroup hasError={!isValid} hasSuccess={!fresh && valid} size={'lg'}>
            <label>{indicator['title']}</label>
            {!isSlider && (
                <input
                    disabled={disabled}
                    name={indicator['@id']}
                    onBlur={e => onChange(Number(e.target.value), true)}
                    onChange={e => onChange(Number(e.target.value))}
                    value={value ?? ''}
                    style={{width: '10em'}}
                    max={maximum}
                    min={minimum}
                    className='form-control'
                    type='number'
                />
            )}
            {isSlider && (
                <StyledDiv valid={isValid}>
                    <Slider
                        disabled={disabled}
                        min={minimum}
                        max={maximum}
                        /* @ts-expect-error NULL meas not selected yet */
                        value={value ? value : null}
                        marks={marks}
                        onChange={value => onChange(value, true)}
                    />
                    <br />
                    <br />
                </StyledDiv>
            )}
            {(maximum || minimum) && (
                <span className='help-block'>
                    {'Minimum ' + minimum} : {'Maksimum ' + maximum}
                </span>
            )}
            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
        </FormGroup>
    )
}
export default NumberIndicator
