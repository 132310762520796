import React, {useState} from 'react'
import GroupSlides from './GroupSlides'
import StatusPage from './StatusPage'
import {AppAnswerSetType, FormTaskType, IndicatorType} from '../Types'
import {LoadedUseEntityType} from '../../Hooks/useEntity'

type Props = {
    task?: LoadedUseEntityType<FormTaskType>
    answerSet: AppAnswerSetType
    questions
    unansweredQuestions
    answerSetSubmitting: boolean
    fresh: boolean
    onChange: (indicator: IndicatorType, answer: any, save: boolean) => Promise<void>
    onSubmit: () => void
    isPreview: boolean
}
const Form = ({
    task,
    answerSet,
    questions,
    unansweredQuestions,
    answerSetSubmitting,
    fresh = true,
    onChange,
    onSubmit,
    isPreview,
}: Props) => {
    const [isSubmitted, setSubmitted] = useState(answerSet ? answerSet['approved'] : false)
    const onLocalSubmit = () => {
        setSubmitted(true)
        onSubmit()
    }
    return (
        <>
            {isSubmitted && (
                <StatusPage
                    onShowForm={() => setSubmitted(false)}
                    task={task}
                    answerSet={answerSet}
                    unansweredQuestions={unansweredQuestions}
                    answerSetSubmitting={answerSetSubmitting}
                    isPreview={isPreview}
                />
            )}
            {!isSubmitted && (
                <GroupSlides questions={questions} answerSet={answerSet} onChange={onChange} onSubmit={onLocalSubmit} fresh={fresh} />
            )}
        </>
    )
}
export default Form
