import React, {useState} from 'react'
import Modal from '../Components/Modal'
import useEntity from '../Hooks/useEntity'
import {format} from 'date-fns'
import TextModal from './TextModal'
import SelectField from '../Components/Fields/SelectField'
import useEntities from '../Hooks/useEntities'
import DateTimeField from '../Components/Fields/DateTimeField'
import TextField from '../Components/Fields/TextField'
import PhoneField from '../Components/Fields/PhoneField'
import {SkilQueryResponseType} from '../Utilities/QueryClient'

type EmployeeType = SkilQueryResponseType<'getOfficeEmployees'>['hydra:member'][number]

const EditConsultationForm = ({iri, onClose}) => {
    const consultation = useEntity<'getConsultationItem'>(iri)
    const [{defaultContent, showSendText}, setShowSendTextModal] = useState({defaultContent: '', showSendText: false})
    const employees = useEntities<'getOfficeEmployees'>(consultation.office + '/employees')

    const onHide = () => {
        consultation.reset()
        onClose()
    }
    const onSave = async () => {
        const result = await consultation.save()
        if (!result) return

        const meetingAt = new Date(result.meetingAt)
        const date = format(meetingAt, 'dd.MM')
        const time = format(meetingAt, 'HH:mm')
        const content = `Din videokonsultasjon er endret til ${date} kl ${time}.\n\nGå til https://v.nefle.no/c/${result.entryCode} litt før med PC/mac/telefon og oppdatert nettleser`

        setShowSendTextModal({defaultContent: content, showSendText: true})
        onClose()
    }
    const onCloseTextModal = () => {
        setShowSendTextModal({showSendText: false, defaultContent: ''})
        onClose()
    }

    return (
        <Modal title={'Rediger konsultasjon #' + consultation.id} onClose={onHide} onSave={consultation['@changes'] ? onSave : undefined}>
            {showSendText && <TextModal defaultContent={defaultContent} iri={iri} onClose={onCloseTextModal} />}
            <form>
                <DateTimeField
                    id='meeting'
                    label='Tidspunkt'
                    value={consultation.meetingAt}
                    onChange={consultation.changeField('meetingAt')}
                />
                <TextField
                    id='name'
                    type='text'
                    label='Pasientens navn'
                    placeholder='Ola Jensen'
                    required={false}
                    help={'Valgfritt'}
                    value={consultation.name}
                    onChange={consultation.changeField('name')}
                />
                <PhoneField
                    id='phonenumber'
                    label='Pasientens mobilnummber'
                    placeholder='123 45 123'
                    required
                    value={consultation.phonenumber}
                    onChange={consultation.changeField('phonenumber')}
                />
                <TextField
                    id='message'
                    type='text'
                    label='Melding til pasient'
                    value={consultation.message}
                    onChange={consultation.changeField('message')}
                />
                <SelectField<EmployeeType>
                    id='doctor'
                    label='Lege'
                    entities={employees}
                    value={consultation.doctor}
                    /* @ts-expect-error */
                    valueFn={e => e.user?.['@id']}
                    /* @ts-expect-error */
                    labelFn={e => e.user.name}
                    required={false}
                    help={'Valgfritt'}
                    onChange={doctor => consultation.changeField('doctor', doctor?.user?.['@id'] ?? null)}
                />
            </form>
        </Modal>
    )
}

export default EditConsultationForm
