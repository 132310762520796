import React, {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router'
import PurePage from '../../../Components/PurePage'
import {BackdropComponent} from '../../../Components/BackdropComponent'
import {Divider, Grid, Paper, Skeleton, Stack, useMediaQuery} from '@mui/material'
import Box from '@mui/system/Box'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {useTheme} from '@mui/material/styles'
import {Typography} from '@mui/material/'
import HelseIdAuthCard from '../../Components/HelseIdAuthCard'
import {Button} from '../../../Components/Button/MuiButton'
import useUser from '../../../Utilities/useUser'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import styled from 'styled-components'
import {SkeletonTitleContainer} from './GroupDetailsPage'
import LoadingComponent from '../../../Components/LoadingComponent'

const StyledGrid = styled(Grid)`
    .MuiCard-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

export default function LoginOrRegisterPage() {
    const {shortTitle, redirect} = useParams<{shortTitle: string; redirect: string}>()
    const {data: course} = useSkilQuery<'getCoursePublicInfoByShortTitle'>(
        shortTitle !== 'nefle' ? `/api/courses/${shortTitle}/publicInfo/byShortTitle` : ''
    )

    const isNefleSignup = window.location.pathname.includes('/signup/nefle')
    const isNefleSignupConfirm = window.location.pathname.includes('/signup/nefle/confirm')
    const isNefleSignupAuthorize = window.location.pathname.includes('/signup/nefle/authorize')
    const theme = useTheme()
    const user = useUser()
    const navigate = useNavigate()
    const [canSignUpToCourse, setCanSignUpToCourse] = React.useState<boolean>(false)
    const redirectUri =
        isNefleSignup && !isNefleSignupConfirm
            ? encodeURI('signup/nefle') + '/authorize'
            : canSignUpToCourse && redirect
            ? redirect
            : canSignUpToCourse && course?.usesGroups
            ? encodeURI(window.location.pathname) + '/details'
            : canSignUpToCourse && course && !course?.usesGroups
            ? encodeURI(window.location.pathname) + '/acknowledge'
            : null
    const [readyToShow, setReadyToShow] = React.useState<boolean>(false)
    const orientation = useMediaQuery(theme.breakpoints.down('md')) ? 'horizontal' : 'vertical'

    useEffect(() => {
        if (!user.authenticated) {
            user.refresh()
        }
    }, [])

    function delayRedirect(url: string) {
        const timer = setTimeout(() => {
            window.location.replace(url)
        }, 1500)

        return () => clearTimeout(timer)
    }
    // Redirect to nefle confirm page if the user is already authenticated
    // Redirect to the group details page if the user is already authenticated, and we have a redirect url
    useEffect(() => {
        if (isNefleSignup && user?.authenticated) {
            if (isNefleSignupAuthorize) {
                window.location.replace(window.location.pathname)
            } else {
                window.location.replace(`/signup/nefle/authorize`)
            }
        } else {
            if (user?.authenticated && redirect) {
                setReadyToShow(false)
                navigate(redirect)
            }
            // go to group signup
            if (user?.authenticated && !redirect && course?.usesGroups) {
                setReadyToShow(false)
                navigate(window.location.pathname + '/details')
            }
            // go to individual signup
            if (user?.authenticated && !redirect && course && !course?.usesGroups) {
                setReadyToShow(false)
                navigate(window.location.pathname + '/acknowledge')
            }
        }
    }, [user, course])

    useEffect(() => {
        if (isNefleSignup) {
            setReadyToShow(true)
        }
        if (!course) return
        if (course?.['@id']) {
            setReadyToShow(true)
        }
        const courseNotFound = course?.['hydra:description'] === 'Not Found' || course?.['@type'] === 'hydra:Error'
        // if it is not related to Nefle then it is related to course signup
        if (!isNefleSignup && !isNefleSignupAuthorize && !isNefleSignupConfirm) {
            if (!course?.signupAllowed || courseNotFound) {
                setCanSignUpToCourse(false)
                // if the course is archived or not published then redirect to the frontpage
                if (!course?.signupAllowed && !courseNotFound) {
                    handleErrorWithToast(new Error('Kurset er ikke åpent for påmelding'))
                }
                // if the course is not found then redirect to the frontpage
                if (courseNotFound && shortTitle !== 'nefle') {
                    handleErrorWithToast(new Error('Dette kurset finnes ikke'))
                }
                // if the course is not found then redirect to the frontpage
                if (course?.['@type'] === 'hydra:Error' && !courseNotFound) {
                    handleErrorWithToast(new Error('En feil har oppstått'))
                }
                // allow time to show error message before redirecting
                delayRedirect(`${window.location.protocol}//${window.location.hostname}`)
            } else {
                setCanSignUpToCourse(true)
            }
        }
    }, [course])

    // we hide the page until we have redirected
    if (redirect == '/signup/nefle/confirm') {
        return <LoadingComponent />
    }

    return (
        <PurePage variant={'md'}>
            {readyToShow && (
                <>
                    <Typography textAlign={'center'} mb={4} mt={2} variant={'h2'}>
                        {canSignUpToCourse && 'Meld deg på ' + (course?.title ?? 'kurs')}
                    </Typography>
                    <Stack
                        direction={{sm: 'column', md: 'row'}}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                        spacing={2}
                        divider={
                            <Divider orientation={orientation} flexItem={true}>
                                eller
                            </Divider>
                        }
                    >
                        <HelseIdAuthCard redirectUrl={redirectUri} />

                        <Paper elevation={0} sx={{width: '100%', height: '100%'}}>
                            <Box
                                minHeight={'350px'}
                                overflow={'hidden'}
                                padding={4}
                                display={'flex'}
                                justifyContent={'space-around'}
                                alignItems={'center'}
                                flexDirection={'column'}
                                flexGrow={1}
                            >
                                <Typography variant={'h2'}>Ny bruker</Typography>
                                <small>
                                    Registrer en ny bruker for å få tilgang til kvalitetspakker, e-læringskurs, kvalitetsseminar, SKIL
                                    Forum, Nefle hjemmeside, Nefle video og mye mer!
                                </small>
                                <Box flexGrow={1} />
                                <Button size={'large'} fullWidth to={window.location.pathname + '/register'} sx={{marginBottom: '2.8rem'}}>
                                    Registrer ny bruker
                                </Button>
                            </Box>
                        </Paper>
                    </Stack>
                </>
            )}
            {user.authenticated && readyToShow ? (
                <>
                    {canSignUpToCourse && (
                        <Button
                            size={'large'}
                            variant={'text'}
                            sx={{marginTop: 3}}
                            fullWidth
                            to={
                                redirect
                                    ? redirect
                                    : course?.usesGroups
                                    ? window.location.pathname + '/details'
                                    : window.location.pathname + '/acknowledge'
                            }
                        >
                            Du er allerede innlogget som <em>{user.name}</em>, fortsett til kurs-påmeldingen her
                        </Button>
                    )}
                    <Button size={'large'} href={'/logout'} variant={'text'} sx={{marginTop: 3}} fullWidth>
                        {canSignUpToCourse ? 'Eller logg ut her' : 'Logg ut her'}
                    </Button>
                </>
            ) : null}
            {!readyToShow && (
                <>
                    <SkeletonTitleContainer>
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '50%', mt: 1}} />
                    </SkeletonTitleContainer>
                    <Grid width={'100%'} sx={{mt: 1}}>
                        <StyledGrid
                            display={'flex'}
                            width={'100%'}
                            xs={12}
                            sm={12}
                            md={12}
                            item
                            sx={{justifyContent: {xs: 'center', sm: 'initial'}}}
                        >
                            <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%', mr: 2}} />
                            <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%'}} />
                        </StyledGrid>
                    </Grid>
                    <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1}} />
                </>
            )}
            <BackdropComponent
                isOpen={(course && !canSignUpToCourse && !isNefleSignup) || (!course && !isNefleSignup)}
                status={'Vent litt...'}
            />
        </PurePage>
    )
}
