import React, {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Button, IconButton, styled} from '@mui/material'

import {useFieldArray} from 'react-hook-form'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import {Add} from '@mui/icons-material'

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'lunch']
const weekdaysNo = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag', 'Lunsj']
const translateWeekday = day => weekdaysNo[weekdays.findIndex(d => d === day)]

const Location = ({errors, control, locationKey, register, disabled, hidden}) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <Accordion
            expanded={expanded}
            disabled={hidden}
            sx={{
                paddingTop: '10px',
                borderTop: '1px solid rgba(0, 0, 0, .125)',
            }}
        >
            <AccordionSummary
                onClick={() => setExpanded(!expanded)}
                expandIcon={
                    <ExpandMoreIcon
                        sx={{
                            height: '1.5em',
                            width: '1.5em',
                        }}
                    />
                }
                sx={{
                    //Mui focus-within fix
                    background: 'transparent!important',
                }}
            >
                <StyledCategoryTextField
                    disabled={disabled || hidden}
                    type={'text'}
                    autoComplete={'Category'}
                    placeholder='Kategori'
                    label={'Kategori'}
                    error={!!errors?.openHours?.[locationKey].name}
                    helperText={errors?.openHours?.[locationKey].name?.message ?? ''}
                    {...register('siteTags.openHours.' + locationKey + '.name')}
                    onClick={event => {
                        //Prevent accordion open/close
                        event.stopPropagation()
                    }}
                />
            </AccordionSummary>
            <AccordionDetails>
                {weekdays.map(key => {
                    return (
                        <LocationDay
                            disabled={disabled || hidden}
                            key={key}
                            name={key}
                            locationKey={locationKey}
                            control={control}
                            register={register}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

export default Location

const LocationDay = ({name, control, locationKey, disabled, register}) => {
    const {fields, append, remove} = useFieldArray({
        name: 'siteTags.openHours.' + locationKey + '.days.' + name,
        control,
    })

    return (
        <Grid
            key={name + locationKey}
            spacing={1}
            container
            sx={{
                marginBottom: '30px',
                width: '100%',
            }}
        >
            <Grid
                sx={{
                    marginLeft: '10px',
                }}
            >
                <h4>{translateWeekday(name)}</h4>
            </Grid>
            <Grid
                item
                container
                sx={{
                    flexDirection: {xs: 'column', sm: 'column', md: 'row'},
                }}
            >
                {fields &&
                    fields.map((hours, hoursKey) => {
                        return (
                            <Grid
                                key={hoursKey}
                                item
                                sx={{
                                    marginBottom: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    div: {
                                        marginBottom: 0,
                                    },
                                }}
                            >
                                <TextField
                                    disabled={disabled}
                                    type={'time'}
                                    autoComplete={'open'}
                                    placeholder='Fra'
                                    label={'Fra'}
                                    defaultValue='08:00'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        marginRight: '10px',
                                    }}
                                    {...register('siteTags.openHours.' + locationKey + '.days.' + name + '[' + hoursKey + '].open')}
                                />
                                <TextField
                                    disabled={disabled}
                                    type={'time'}
                                    autoComplete={'close'}
                                    placeholder='Til'
                                    label={'Til'}
                                    defaultValue='16:00'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        width: 110,
                                        marginBottom: '0',
                                    }}
                                    {...register('siteTags.openHours.' + locationKey + '.days.' + name + '[' + hoursKey + '].close')}
                                />
                                {fields.length > 0 && !disabled && (
                                    <IconButton
                                        sx={{
                                            height: 'fit-content',
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                        }}
                                        onClick={() => remove(hoursKey)}
                                    >
                                        <DeleteIcon sx={{}} />
                                    </IconButton>
                                )}
                            </Grid>
                        )
                    })}
                <Grid
                    item
                    sx={{
                        height: '60px',
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'column', md: 'row'},
                        alignItems: {xs: 'flex-start', sm: 'flex-start', md: 'center'},
                    }}
                >
                    {fields.length < 1 && (
                        <Grid
                            item
                            sx={{
                                marginRight: '20px',
                                marginBottom: '10px',
                            }}
                        >
                            <em>Stengt</em>
                        </Grid>
                    )}
                    {!disabled && (
                        <Grid
                            item
                            sx={{
                                padding: '0em',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button startIcon={<Add />} variant={'contained'} onClick={() => append({})}>
                                Legg til
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StyledCategoryTextField = styled(TextField)``
