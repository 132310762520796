import * as React from 'react'
import {Card, CardContent, SvgIconProps, Typography} from '@mui/material'
import {CardActionArea} from '@mui/material'
import {Link} from 'react-router-dom'
import {SxProps, Theme} from '@mui/material/styles'

interface CardButtonWithIconProps {
    icon: React.ComponentType<SvgIconProps>
    sx?: SxProps<Theme>
    label: string | React.ReactNode
    to?: string
    href?: string
    disabled?: boolean
    isExternal?: boolean
}

const CardButtonWithIcon = ({icon, label, to, href, disabled = false, sx, isExternal = false}: CardButtonWithIconProps) => {
    const LinkComponent = to ? Link : 'a'
    const Icon = icon

    /**
     * Default icon props are used to set the size and spacing of the icon.
     * In cases where the optical centre is not the geometric centre,
     * use the sx prop to set the position of the icon. (You can do other things
     * with it, obviously, but you SHOULDN'T 😉)
     *
     */
    const iconSx: SxProps<Theme> = [{fontSize: 120, mb: 1, position: 'relative'}, ...(Array.isArray(sx) ? sx : [sx])]

    return (
        <Card sx={{mb: 3, width: '100%'}}>
            <CardActionArea
                component={LinkComponent}
                to={to}
                href={href}
                disabled={disabled || !(href || to)}
                target={isExternal ? '_blank' : undefined}
            >
                <CardContent sx={{p: 5, textAlign: 'center', position: 'relative'}}>
                    <Icon sx={iconSx} />
                    <Typography fontWeight={500} variant='body1'>
                        {label}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default CardButtonWithIcon
