import React, {ReactNode} from 'react'
import {Button, OnClickFunction} from './Button/MuiButton'
import {Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'

type Props = {
    children: ReactNode
    onSave?: OnClickFunction
    onClose: OnClickFunction
    title?: ReactNode
    size?: false | Breakpoint | undefined
    footerComponents?: ReactNode
    showCancel?: boolean
    cancelText?: string
    saveText?: string
    spinnerWhileSaving?: boolean
    disableWhileSaving?: boolean
    confirm?: React.ReactNode
}

export default function Modal({
    children,
    onSave,
    onClose,
    title,
    size = 'lg',
    footerComponents = null,
    showCancel = true,
    cancelText = 'Avbryt',
    saveText = 'Lagre',
    spinnerWhileSaving = false,
    disableWhileSaving = false,
    confirm = undefined,
}: Props) {
    return (
        <Dialog maxWidth={size} open={true} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {footerComponents}
                {showCancel && (
                    <Button color={!onSave ? 'primary' : 'inherit'} onClick={onClose} disabled={disableWhileSaving}>
                        {cancelText}
                    </Button>
                )}
                {onSave && (
                    <Button color={'primary'} onClick={onSave} disabled={disableWhileSaving} confirm={confirm}>
                        {spinnerWhileSaving && <i className='fa fa-spinner fa-spin' />} {saveText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
