import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import EditableRadioInput from '../../Components/EditableRadioInput'
import styled from 'styled-components'
import EditText from '../../Components/EditText'
import Button from '../../../Components/Button/Button'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`

const ChoiceIndicator = ({indicator, answer, onChange, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer
    const id = indicator['@id']
    const alternatives = indicator.alternatives
    alternatives.sort((a, b) => a.order - b.order)

    const onRemoveAlternative = value => () => {
        let newAlternatives = [...indicator.alternatives]
        newAlternatives = newAlternatives.filter(obj => obj.key !== value)
        delete newAlternatives[value]
        let reorderedAlternatives = newAlternatives.map((obj, index) => {
            return {order: index, key: obj.key, text: obj.text}
        })
        indicator.changeField('alternatives', reorderedAlternatives)
        indicator.save()
    }

    const onAddAlternative = () => {
        let values: any[] = Object.values(alternatives)
        // @ts-expect-error
        let indexes: number[] = Object.keys(alternatives)
        let keysToNumbers = values.map(obj => obj.key.charCodeAt(0))
        let highestNumber = Math.max(...keysToNumbers)
        let highestIndex = Math.max(...indexes)
        const newCharacterKey = String.fromCharCode(highestNumber + 1)
        let newAlternatives = [...alternatives, {order: highestIndex + 1, text: 'Nytt valg', key: newCharacterKey}]
        indicator.changeField('alternatives', newAlternatives)
        indicator.save()
    }

    const onSaveAlternative = value => newText => {
        let props = alternatives.find(a => a.key === value)
        const filteredAlternatives = alternatives.filter(a => a.key !== value)
        filteredAlternatives.push({key: value, text: newText, order: props.order})
        indicator.changeField('alternatives', filteredAlternatives)
        indicator.save()
    }

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    const onMoveUpOrDown = (index, direction) => {
        let copy = [...alternatives]
        let maxOrder = copy.length - 1
        let newOrder = copy[index].order + direction

        if (newOrder > maxOrder) newOrder = maxOrder
        if (newOrder < 0) newOrder = 0

        copy[index + direction].order = copy[index].order
        copy[index].order = newOrder
        copy.sort((a, b) => a.order - b.order)
        indicator.changeField('alternatives', [...copy])
        indicator.save()
    }

    return (
        <FormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>
            {Object.entries(alternatives).map((obj, index) => {
                let lastElement = Object.entries(alternatives).length - 1
                // @ts-expect-error random type!
                let alternative: {key: string; text: string} = obj[1]
                return (
                    <div key={alternative.key + index} style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <EditableRadioInput
                            key={alternative.key + index + 'radio'}
                            value={alternative.key}
                            label={alternative.text}
                            disabled={true}
                            checked={value === alternative.key}
                            name={id}
                            onChange={e => onChange(e.target.value, true)}
                            onSave={onSaveAlternative(alternative.key)}
                            onRemove={onRemoveAlternative(alternative.key)}
                            toolboxStyling={true}
                        />
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <button
                                disabled={index === 0}
                                style={{padding: '1rem'}}
                                className={'btn btn-link'}
                                onClick={() => onMoveUpOrDown(index, -1)}
                            >
                                <i className={'fa fa-arrow-up fa-fw'} />
                            </button>
                            <button
                                disabled={lastElement === index}
                                style={{padding: '1rem 0'}}
                                className={'btn btn-link'}
                                onClick={() => onMoveUpOrDown(index, +1)}
                            >
                                <i className={'fa fa-arrow-down fa-fw'} />
                            </button>
                        </div>
                    </div>
                )
            })}
            <Button onClick={onAddAlternative} variant={'link'} size={'sm'}>
                <i className={'fa fa-plus'} /> Legg til element
            </Button>
            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </FormGroup>
    )
}
export default ChoiceIndicator
