import React from 'react'
import DatePicker from 'react-datepicker'
import FormGroup from '../../../Components/FormGroup'
import EditText from '../../Components/EditText'
import styled from 'styled-components'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`

const DateTimeIndicator = ({indicator, answer, onChange, disabled = false, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer
    const selected = value && valid ? new Date(value) : null
    const time = indicator.time
    const date = indicator.date

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    const onTimeChange = e => {
        indicator.changeField('time', e)
        indicator.save()
    }

    const onDateChange = e => {
        indicator.changeField('date', e)
        indicator.save()
    }

    return (
        <FormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>

            <div>
                <label>
                    <input onChange={e => onDateChange(e.target.checked)} checked={date} type='checkbox' /> Dato
                </label>
                &nbsp;&nbsp;
                <label>
                    <input onChange={e => onTimeChange(e.target.checked)} checked={time} type='checkbox' /> Tid
                </label>
            </div>

            <DatePicker
                disabled={disabled}
                onChange={date => onChange(date.toISOString(), true)}
                className={`form-control`}
                selected={selected}
                showTimeSelect={time}
                showTimeSelectOnly={!date}
                timeFormat='HH:mm'
                timeIntervals={15}
                timeCaption='Tid'
                dateFormat='dd.MM.yyyy HH:mm'
                isClearable
                inline
            />
            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </FormGroup>
    )
}
export default DateTimeIndicator
