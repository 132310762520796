import React from 'react'
import {patchEntity} from '../actions/api'
import {clearChanges, SAVE_FIELDS} from '../actions/changes'

export const changes =
    ({dispatch, getState}) =>
    next =>
    async action => {
        next(action)
        if (action.type !== SAVE_FIELDS) return

        const state = getState()
        const {changes, id} = action.payload

        const newChanges = {...state.changes[id], ...changes}
        const children = newChanges['@children'] || {}
        delete newChanges['@children']

        await Promise.allSettled(Object.entries(children).map(([id, changes]) => dispatch(patchEntity(id, changes))))

        const newEntity = await dispatch(patchEntity(id, newChanges))
        await dispatch(clearChanges(id))

        return newEntity
    }
