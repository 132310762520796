import {useSkilQuery} from '../Utilities/QueryClient'
import * as React from 'react'

export const DistrictsExport = hasLoaded => {
    const districtsQueryResult = useSkilQuery<'getDistrictCollection'>(`/api/districts`, {pagination: false}, {enabled: hasLoaded})

    return React.useMemo(() => {
        const districtsRaw = districtsQueryResult.data?.['hydra:member'] ?? []
        return Object.values(districtsRaw)
            .map(district => {
                let countyId = district?.county?.id
                return {label: district.name, id: district.id, '@id': district['@id'], county: countyId}
            })
            .sort(function (a, b) {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
    }, [districtsQueryResult])
}
