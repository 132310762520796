import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'
import LoadingComponent from '../../Components/LoadingComponent'

const NewUserCoursesGoal23 = () => {
    const {data: newUserCourses} = useSkilQuery<'getNewUserCoursesPerYear'>(`/api/user_courses/newUserCoursesPerYear`, {year: 2023})
    if (!newUserCourses) {
        return <LoadingComponent />
    }

    return (
        <GoalCard
            title='Kursdrift'
            subheader='Viser tall for kliniske emnekurs og e-læringskurs.'
            // @ts-expect-error
            description={`${newUserCourses?.signups} av 750 påmeldte i 2023`}
            // @ts-expect-error
            percentage={Math.ceil((newUserCourses?.signups * 100) / 750)}
            // @ts-expect-error
            secondDescription={`${newUserCourses?.signupsQualityPackage} kliniske emnekurs`}
            // @ts-expect-error
            thirdDescription={`${newUserCourses?.signupsFreeCourses} e-læringskurs`}
            // @ts-expect-error
            forthDescription={`${newUserCourses?.payableSignups} fakturerbare påmeldinger`}
            // @ts-expect-error
            fifthDescription={`${newUserCourses?.invited} inviterte`}
        />
    )
}

export default NewUserCoursesGoal23
