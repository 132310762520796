import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {Snackbar} from '@mui/material'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import Moment from 'moment'
import {useQuery} from 'react-query'
import {jsonFetch} from '../jsonFetch'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

type Hashes = {
    apiHash: string
    frontendHash: string
}

export default function ReloadFrontend() {
    const apiHash = process.env.API_HASH
    const frontendHash = process.env.FRONTEND_HASH

    let countDownInterval
    const [openInfoDialog, setOpenInfoDialog] = React.useState(false)
    const [openInfoSnackbar, setOpenInfoSnackbar] = React.useState(false)
    const [openWarningDialog, setOpenWarningDialog] = React.useState(false)
    const [openWarningSnackbar, setOpenWarningSnackbar] = React.useState(false)
    const [refreshing, setRefreshing] = React.useState<boolean>(false)
    const [targetTime, setTargetTime] = React.useState<Date>()
    const [countDown, setCountDown] = React.useState<number>(10)
    const [serverHashes, setServerHashes] = React.useState<Hashes>({apiHash: '', frontendHash: ''})
    useQuery<Hashes>(`/public/frontend_versions`, {
        refetchInterval: 60_000, // refetch every minute
        // only run if none of the dialogs or snackbars are open
        enabled: !(openInfoDialog || openInfoSnackbar || openWarningDialog || openWarningSnackbar),
        onSuccess: hashes => {
            const isOpen = openInfoSnackbar || openInfoSnackbar || openWarningDialog || openWarningSnackbar
            // only run if none of the dialogs or snackbars are open (and environment is production since in dev the frontend hash will always mismatch)
            if (isOpen) {
                return
            }

            if (hashes.frontendHash === 'dev' || frontendHash === 'dev') {
                return
            }

            const hasChanges = hashes.apiHash !== apiHash || hashes.frontendHash !== frontendHash
            if (!hasChanges) {
                return
            }
            setServerHashes(hashes)
            //console.log('hasChanges', {isOpen, hashes, apiHash, frontendHash})
            setOpenInfoDialog(true)

            // start timer to show warning if one of the hashes are new
            setTimeout(() => {
                setOpenInfoDialog(false)
                setOpenInfoSnackbar(false)
                setOpenWarningDialog(true)
                startInterval()
                // 10 minute timer to show warning dialog
            }, 60000 * 10)
        },
    })

    function startInterval() {
        let timeNow = new Date()
        setTargetTime(Moment(timeNow).add(10, 'm').toDate())

        // set inital targetTime (target) before the state is set,
        // cannot set default state for targetTime since it should only start 10 minutes
        // after openInfoDialog has been opened
        let target = targetTime ?? Moment(new Date()).add(10, 'm').toDate()
        countDownInterval = setInterval(() => {
            setCountDown(target.getMinutes() - new Date().getMinutes())
        }, 1000)
    }

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false)
        setOpenInfoSnackbar(true)
    }

    const handleCloseWarningDialog = () => {
        setOpenWarningDialog(false)
        setOpenWarningSnackbar(true)
    }

    const sendErrorReport = async report => {
        return jsonFetch(`/public/report_frontend_versions_error`, {
            method: 'POST',
            json: report,
        })
            .then(() => {})
            .catch(e => {
                return 'Det skjedde en feil: ' + e
            })
    }

    const refreshPage = async () => {
        setRefreshing(true)
        const refreshTime = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        const report = JSON.stringify({
            refreshTime,
            serverHashes,
            browserHashes: {apiHash, frontendHash},
        })
        await sendErrorReport(report)
        window.location.reload()
    }

    if (countDown <= 0) {
        clearInterval(countDownInterval)
        refreshPage()
    }

    return (
        <div>
            {/* Info dialog */}
            <Dialog open={openInfoDialog} keepMounted onClose={handleCloseInfoDialog} aria-describedby='alert-dialog-slide-description'>
                <DialogTitle>
                    <strong>{'Siden er blitt oppdatert'}</strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                        Oppdater siden på nytt for at den skal fungere optimalt. Om du fortsetter å jobbe uten å laste inn siden på nytt kan
                        vi ikke garantere at endringer vil lagres eller at siden vil fungere som forventet.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfoDialog} disabled={refreshing}>
                        Utsett
                    </Button>
                    <Button variant={'contained'} onClick={() => refreshPage()} disabled={refreshing}>
                        {refreshing ? <i className={'fa fa-spin fa-spinner'} /> : 'Oppdater siden'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openInfoSnackbar} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert severity='info' sx={{width: '100%'}}>
                    Siden er utdatert. Oppdater siden på nytt for at den skal fungere optimalt!
                    <Button onClick={() => refreshPage()} disabled={refreshing}>
                        {refreshing ? <i className={'fa fa-spin fa-spinner'} /> : 'Oppdater siden'}
                    </Button>
                </Alert>
            </Snackbar>

            {/* Warning dialog */}
            <Dialog
                open={openWarningDialog}
                keepMounted
                onClose={handleCloseWarningDialog}
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle>{'Siden er blitt oppdatert'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                        Oppdater siden på nytt for at den skal fungere optimalt. Om du fortsetter å jobbe uten å laste inn siden på nytt kan
                        vi ikke garantere at endringer vil lagres eller at siden vil fungere som forventet.
                    </DialogContentText>
                    <div style={{marginTop: '1rem'}}>
                        Tid gjenstående til automatisk oppdatering: {countDown} {countDown > 1 ? 'minutter' : 'minutt'}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWarningDialog}>Utsett</Button>
                    <Button variant={'contained'} onClick={() => refreshPage()}>
                        {refreshing ? <i className={'fa fa-spin fa-spinner'} /> : 'Oppdater siden'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openWarningSnackbar} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert severity='warning' sx={{width: '100%'}}>
                    Siden er utdatert. Oppdater siden på nytt for at den skal fungere optimalt!
                    <p>
                        Tid gjenstående til automatisk oppdatering: {countDown} {countDown > 1 ? 'minutter' : 'minutt'}
                    </p>
                    <Button onClick={() => refreshPage()}>
                        {refreshing ? <i className={'fa fa-spin fa-spinner'} /> : 'Oppdater siden'}
                    </Button>
                </Alert>
            </Snackbar>
        </div>
    )
}
