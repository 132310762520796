import * as React from 'react'
import {Page} from '../Components/Page'
import Panel from '../Components/Panel'
import {Outlet, Link, useLocation} from 'react-router-dom'
import Tab from '../Components/Tab'
import Tabs from '../Components/Tabs'
import {BreadcrumbsList} from '../Components/Breadcrumbs'
import {ROUTES} from './constants/routes'

const breadcrumbs: BreadcrumbsList = [
    {title: 'Kurs', href: '/dashboard'},
    {title: 'SKIL', to: ROUTES.HOME},
]

const TABS = [
    {label: 'Kursutvikling', to: ROUTES.HOME},
    {label: 'Verktøy', to: ROUTES.TOOLS},
    {label: 'Mål 2023', to: ROUTES.GOALS23},
    {label: 'Mål 2024', to: ROUTES.GOALS24},
    {label: 'Kurs', to: ROUTES.COURSES},
    {label: 'Deltakelse', to: ROUTES.PARTICIPANTS},
    {label: 'Grupper', to: ROUTES.GROUPS},
    {label: 'Møter', to: ROUTES.MEETINGS},
    {label: 'Legekontor', to: ROUTES.OFFICES},
    {label: 'Brukere', to: ROUTES.USERS},
    {label: 'Veiledere', to: ROUTES.COUNSELORS},
    {label: 'Samarbeid', to: ROUTES.PARTNERSHIPS},
    {label: 'Samarbeid - påmeldinger', to: ROUTES.PARTNERSHIPSIGNUPS},
    {label: 'Samarbeid - statistikk', to: ROUTES.PARTNERSHIPS_STATISTICS},
    {label: 'Fullførelsesgrad', to: ROUTES.STATISTICS_COURSE_COMPLETIONS},
    {label: 'Automatiske eposter', to: ROUTES.JOBS},
]

export default function Overview() {
    const location = useLocation()

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs}>
            <Panel variant='flat' style={{border: 'none'}}>
                <Tabs aria-label='nav tabs' value={location.pathname} variant='scrollable'>
                    {TABS.map(({label, to}) => (
                        <Tab key={to} attached={false} disableRipple label={label} to={to} value={to} component={Link} />
                    ))}
                </Tabs>
                <Outlet />
            </Panel>
        </Page>
    )
}
