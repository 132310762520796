import * as React from 'react'
import Modal from './Modal'
import LoadingComponent from './LoadingComponent'
import TextField from './Fields/TextField'
import SelectField from './Fields/SelectField'
import useEntities from '../Hooks/useEntities'
import {useQueryInvalidate, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {components} from '../Generated/eportal'
import Autocomplete from '@mui/material/Autocomplete'
import {CountyAutocomplete} from './CountyAutocomplete'

/*************
 *
 * NOTE: Has a replica in PraksisNett domain
 *
 ***************/

const validateEmail = email => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)

type NewUserType = {
    type: 'new'
    name: string
    email: string
    role: string
    county?: string
    district?: string
}
type HelfoUserType = {
    type: 'helfo'
    email: string
    id: number
    name: string
    district?: string
}
type SkilUserType = {
    type: 'skil'
    id: number
    name: string
    district?: string
}
type NewOfficeType = {
    officeType: 'new'
    officeName: string
    county: string
    district?: string
}

type ExternalOfficeType = {
    officeType: 'external'
    officeName?: string
    externalOffice: string
    district?: string
}
type SkilOfficeType = {
    officeType: 'skil'
    officeName?: string
    office: string
    district?: string
}
type UserType = NewUserType | HelfoUserType | SkilUserType
type OfficeType = NewOfficeType | ExternalOfficeType | SkilOfficeType

export type FindUserModalDTO = components['schemas']['FindUserModalDTO.jsonld'] & UserType & {office?: OfficeType}

type Props = {
    onSave?: (user: FindUserModalDTO) => Promise<any> | any
    onClose: () => void
    disableHelfoDoctor?: boolean
    disableNewUser?: boolean
    disableSkilUser?: boolean
    saveLabel?: string
    description?: string
    initSearch?: string
    children?: React.ReactNode
    signupAllowed?: boolean
    shortTitle?: string
    isCourseAdmin?: boolean
    isSkilUser?: boolean
    size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

export default function FindUserModal({
    onSave,
    onClose,
    disableHelfoDoctor = false,
    disableNewUser = false,
    disableSkilUser = false,
    saveLabel = '',
    description = undefined,
    initSearch = '',
    children = undefined,
    signupAllowed = true,
    shortTitle = '',
    isCourseAdmin = false,
    isSkilUser = false,
    size = 'lg',
}: Props) {
    const [search, setSearch] = React.useState(initSearch)
    const [selected, setSelected] = React.useState<UserType>()
    const [selectedOffice, setSelectedOffice] = React.useState<OfficeType>()
    const invalidate = useQueryInvalidate()
    const skilQuery = /\S+@\S+\.\S+/.test(search) ? `email=${search}` : `name=${search}`
    const skilUsersResponse = useSkilQuery<'getUserCollection'>(`/api/users?${skilQuery}&itemsPerPage=5`, null, {
        enabled: !disableSkilUser && search.length > 3,
    })
    const skilUsers = skilUsersResponse?.data?.['hydra:member'] ?? []
    const helfoUsersResponse = useSkilQuery<'getHpnDoctorCollection'>(`/api/helfo_doctors?name=${search}&itemsPerPage=5`, null, {
        enabled: !disableHelfoDoctor && search.length > 3,
    })
    const roles = useSkilQuery<'getUserRoleCollection'>(`/api/user_roles`)
    const userRoles = roles?.data?.['hydra:member'] ?? []
    const createOfficeMutation = useSkilMutation<'addOffice'>('POST', '/api/offices')
    const helfoUsers = helfoUsersResponse?.data?.['hydra:member'] ?? []
    const onSubmit = async () => {
        if (!selected || !onSave) {
            return
        }

        let office: undefined | OfficeType = selectedOffice

        if (selectedOffice?.officeType === 'new') {
            let newOffice = await createOfficeMutation.mutateAsync(selectedOffice)
            office = {
                officeType: 'skil',
                office: newOffice['@id']!,
                officeName: newOffice.name,
            }
            await invalidate([newOffice.county + '/offices'])
        }

        return onSave({...selected, office})
    }

    let saveText = 'Opprett bruker'
    if (selected?.type === 'helfo' && selected?.id) {
        saveText = 'Legg til Helfo-lege'
    }
    if (selected?.type === 'skil' && selected?.id) {
        saveText = 'Legg til SKIL bruker'
    }
    if (saveLabel) {
        saveText = saveLabel
    }

    const onSelect = (user: UserType, office: OfficeType) => {
        if (office?.district) {
            user.district = office.district['@id'] ?? null
        }
        setSelected(user)
        setSelectedOffice(office)
    }

    const userValid =
        (selected?.type === 'skil' && selected?.id) ||
        (selected?.type === 'helfo' && selected?.id && validateEmail(selected?.email)) ||
        (selected?.type === 'new' && selected?.name?.length > 2 && validateEmail(selected?.email) && selected.role)

    const officeValid =
        (selectedOffice?.officeType === 'skil' && selectedOffice?.office?.startsWith('/api/offices')) ||
        (selectedOffice?.officeType === 'external' && selectedOffice?.externalOffice?.startsWith('/api/')) ||
        (selectedOffice?.officeType === 'new' &&
            selectedOffice?.officeName?.length > 2 &&
            selectedOffice?.county?.startsWith('/api/counties'))

    const onSaveEnabled = userValid && officeValid

    const missingHelfoUsers = helfoUsers.filter(h => !skilUsers.find(u => u['@hpnDoctor'] === h['@id']))
    return (
        <Modal
            title={`Legg til ${!disableNewUser ? 'eller lag ny' : ''} bruker`}
            size={size}
            onSave={onSaveEnabled && onSave ? onSubmit : undefined}
            onClose={onClose}
            saveText={saveText}
        >
            {!signupAllowed && (isCourseAdmin || isSkilUser) && (
                <div style={{marginBottom: '3em'}}>
                    <div>
                        <div>
                            <i className='fa fa-exclamation-triangle fa-2x' style={{color: 'red'}}></i>
                        </div>
                        <div>
                            <strong style={{color: 'red'}}>Påmelding er ikke mulig for dette kurset.</strong>
                            <br />
                            <strong style={{color: 'red'}}>Se kursinnstillingene for hva som mangler.</strong>
                            <br />
                            <br />
                            <small style={{color: 'red'}}>Du kan registrere ny bruker og sende invitasjon til kurset,</small>
                            <br />
                            <small style={{color: 'red'}}>
                                men brukeren kan ikke godkjenne invitasjonen og invitasjonen vil automatisk bli slettet.
                            </small>
                        </div>
                    </div>
                    {shortTitle && (
                        <div>
                            <a href={`/dashboard/course/${shortTitle}/settings`} className='btn btn-primary btn-flat pull-right'>
                                Kursinnstillinger
                            </a>
                        </div>
                    )}
                </div>
            )}

            <div style={{marginBottom: '1em'}}>{description}</div>
            <TextField id={'search'} label={'Søk'} placeholder={'Søk etter navn eller epost'} value={search} onChange={setSearch} />
            <div className={'list-group'}>
                {!disableSkilUser &&
                    skilUsers.map(u => (
                        <SkilItem
                            key={'skil' + u.id}
                            user={u}
                            selectedUser={selected}
                            selectedOffice={selectedOffice}
                            onSelect={onSelect}
                        />
                    ))}
                {!disableHelfoDoctor &&
                    missingHelfoUsers.map(u => (
                        <HelfoItem
                            key={'helfo' + u.id}
                            user={u}
                            selectedUser={selected}
                            selectedOffice={selectedOffice}
                            onSelect={onSelect}
                        />
                    ))}
                {(helfoUsersResponse.isLoading || skilUsersResponse.isLoading) && <LoadingComponent />}
                {!disableNewUser && (
                    <NewItem selectedOffice={selectedOffice} selectedUser={selected} onSelect={onSelect} userRoles={userRoles} />
                )}
            </div>
            {children}
        </Modal>
    )
}

/*******************
 * SkilUser Item!
 *******************/

type SkilItemProps = {
    user: components['schemas']['User.jsonld-User.user']
    selectedUser?: UserType
    selectedOffice?: OfficeType
    onSelect: (SkilUserType, SkilOfficeType) => void
}

const SkilItem = ({user, selectedUser, selectedOffice, onSelect}: SkilItemProps) => {
    const [county, setCounty] = React.useState<string | undefined>(undefined)
    const [district, setDistrict] = React.useState<string | undefined>(undefined)
    const isSelected = selectedUser?.type === 'skil' && selectedUser?.id === user.id

    if (!isSelected) {
        return (
            <button
                onClick={() =>
                    onSelect(
                        {...selectedUser, type: 'skil', id: user.id, name: user.name},
                        user.office
                            ? {
                                  officeType: 'skil',
                                  office: user.office,
                                  officeName: user.officeName,
                              }
                            : undefined
                    )
                }
                className={'list-group-item'}
            >
                {user.name} {user.officeName ? user.officeName : null}
                <em className='label label-primary pull-right'>SKIL</em>
                {user.hpnDoctor && <em className='label label-info pull-right'>HELFO</em>}
            </button>
        )
    }

    return (
        // User is selected
        <div className={'list-group-item'}>
            <h5>
                <i className={'fa fa-circle'} /> {user.name} {user.officeName ? `(${user.officeName})` : null}
                <em className='label label-primary pull-right'>SKIL</em>
                {user.hpnDoctor && <em className='label label-info pull-right'>HELFO</em>}
            </h5>
            {!user.office && (
                <>
                    <h5>Legekontor</h5>
                    <CountyAutocomplete
                        sx={{mb: 2}}
                        required={true}
                        value={county ?? null}
                        valueDistrict={selectedOffice?.district ?? null}
                        onChange={(e, c, iri, district) => {
                            onSelect({...selectedUser}, {...selectedOffice, county: iri, district: district?.id})
                            setCounty(iri ?? undefined)
                            setDistrict(district?.id)
                        }}
                    />
                    <OfficeItem
                        office={selectedOffice}
                        onChange={office => {
                            const officeWithModifiedDistrict = {...office, district: office.district?.['@id'] ?? null}
                            onSelect(selectedUser, officeWithModifiedDistrict)
                            setDistrict(office.district?.id ?? null)
                        }}
                        county={county}
                        district={district}
                    />
                </>
            )}
        </div>
    )
}

/*******************
 * New User Item!
 *******************/
type NewItemProps = {
    selectedUser?: UserType
    selectedOffice?: OfficeType
    onSelect: (NewUserType, NewOfficeType) => void
    userRoles: any[]
}
const NewItem = ({selectedUser, selectedOffice, onSelect, userRoles}: NewItemProps) => {
    const [county, setCounty] = React.useState<string | undefined>(undefined)
    const [district, setDistrict] = React.useState<string | undefined>(undefined)
    const isSelected = selectedUser?.type === 'new'
    if (!isSelected) {
        return (
            <button
                style={{background: '#052a30', color: 'white'}}
                onClick={() =>
                    onSelect({...selectedUser, name: '', email: '', role: '', type: 'new'}, {...selectedOffice, officeType: 'skil'})
                }
                className={'list-group-item'}
            >
                Opprett ny bruker
            </button>
        )
    }

    return (
        <div className={'list-group-item'}>
            <h5>
                <i className={'fa fa-circle'} /> Opprett ny bruker
            </h5>
            <TextField
                id={'name'}
                label={'Navn *'}
                value={selectedUser?.type === 'new' ? selectedUser?.name : ''}
                onChange={name => onSelect({...selectedUser, name}, selectedOffice)}
            />
            <TextField
                id={'email'}
                label={'Epost *'}
                value={selectedUser?.type === 'new' ? selectedUser?.email : ''}
                validateFn={validateEmail}
                onChange={email => onSelect({...selectedUser, email}, selectedOffice)}
            />
            <SelectField
                id={'role'}
                label={'Rolle'}
                value={selectedUser.role}
                entities={userRoles}
                onChange={userRole => {
                    let role = userRole?.['@id'] ?? null
                    onSelect({...selectedUser, role}, selectedOffice)
                }}
            />
            <CountyAutocomplete
                sx={{mb: 2}}
                required={true}
                requiredDistrict={false}
                value={county ?? null}
                valueDistrict={selectedOffice?.district ?? null}
                onChange={(e, c, iri, district) => {
                    if (!district) {
                        onSelect(
                            {...selectedUser, county: iri, district: undefined},
                            {...selectedOffice, county: iri, district: undefined, office: undefined}
                        )
                    }
                    if (district) {
                        onSelect(
                            {...selectedUser, county: iri, district: district.id},
                            {...selectedOffice, county: iri, district: district.id}
                        )
                    }
                    setCounty(iri ?? undefined)
                    setDistrict(district?.id)
                }}
            />
            <OfficeItem
                county={county}
                office={selectedOffice}
                onChange={office => {
                    const officeWithModifiedDistrict = {...office, district: office.district?.['@id'] ?? null}
                    onSelect(selectedUser, officeWithModifiedDistrict)
                    setDistrict(office.district?.id ?? null)
                }}
                district={district}
            />
        </div>
    )
}

/*******************
 * Helfo Item!
 *******************/
type HelfoItemProps = {
    user: components['schemas']['helfo_doctor.jsonld-Doctor.user']
    selectedUser?: UserType
    selectedOffice?: OfficeType
    onSelect: (HelfoUserType, OfficeType) => void
}
const HelfoItem = ({user, selectedUser, selectedOffice, onSelect}: HelfoItemProps) => {
    const firstOffice = user.offices?.[0] ?? null
    // @ts-expect-error Why is "name" missing on this relation?
    const fistOfficeName = firstOffice.name

    if (!selectedUser || selectedUser.type !== 'helfo' || selectedUser.id !== user.id)
        return (
            <button
                className={'list-group-item'}
                onClick={() =>
                    onSelect(
                        {...selectedUser, type: 'helfo', id: user.id, name: user.name},
                        {
                            officeType: 'external',
                            externalOffice: firstOffice?.['@id'],
                            officeName: fistOfficeName,
                        }
                    )
                }
            >
                {user.name} {fistOfficeName ? `(${fistOfficeName})` : null}
                <em className='label label-info pull-right'>HELFO</em>
            </button>
        )

    return (
        <div className={'list-group-item'}>
            <h5>
                <i className={'fa fa-circle'} />
                {user.name} {fistOfficeName ? `(${fistOfficeName})` : null}
                <em className='label label-info pull-right'>HELFO</em>
            </h5>

            <TextField
                id={'email'}
                label={'Epost'}
                type={'text'}
                value={selectedUser.email ?? ''}
                validateFn={validateEmail}
                onChange={email => onSelect({...selectedUser, email}, selectedOffice)}
            />
            <div className={'list-group'}>
                {user.offices?.map(o => {
                    const isSelectedOffice = selectedOffice?.officeType === 'external' && o['@id'] === selectedOffice?.externalOffice

                    // @ts-expect-error
                    const officeName = o.name
                    return (
                        <button
                            onClick={() =>
                                onSelect(selectedUser, {officeType: 'external', externalOffice: o['@id'], officeName: o.hpnOffice?.name})
                            }
                            className={'list-group-item'}
                            key={'office' + o['@id']}
                        >
                            {isSelectedOffice && <i className={'fa fa-circle'} />} {officeName}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

/*******************
 * Office Item!
 *******************/

type OfficeItemProps = {
    county?: string
    office?: OfficeType
    onChange: (office: OfficeType & {county: string}) => void
    district?: string
}

const countiesWithDistricts = ['/api/counties/301', '/api/counties/4601', '/api/counties/5001', '/api/counties/1103']

export function generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000)
}

const OfficeItem = ({county, office, onChange, district}: OfficeItemProps) => {
    const isCountyWithDistrict = county ? countiesWithDistricts.includes(county) : false
    const skilOfficesResponse = useSkilQuery<'getCountyOfficeCollection'>(
        `${county}/offices`,
        {itemsPerPage: 5000},
        {
            enabled: !!county,
        }
    )
    const externalOfficesResponse = useSkilQuery<'getCountyExternalOfficeCollection'>(
        `${county}/external_offices`,
        {itemsPerPage: 5000},
        {
            enabled: !!county,
        }
    )
    const helfoOffices = externalOfficesResponse.data?.['hydra:member'] ?? []
    const skilOffices = skilOfficesResponse.data?.['hydra:member'] ?? []

    const loading = skilOfficesResponse.isLoading || externalOfficesResponse.isLoading

    const options: {value: string | null; label: string; type: string}[] = skilOffices
        .sort(function (a, b) {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
        .map(o => {
            return {
                label: o.name,
                value: o['@id']!,
                type: o.externalOffice ? 'both' : 'skil',
            }
        })

    helfoOffices?.forEach(h => {
        const include = !skilOffices.find(s => s['@id'] === h.office)
        if (!include) return

        options.push({
            label: h.name,
            value: h['@id']!,
            type: 'helfo',
        })
    })

    options.push({
        label: 'Lag nytt legekontor',
        value: null,
        type: 'new',
    })

    const onLocalChange = (val: string | null, label: string | undefined) => {
        if (val === null) {
            onChange({officeType: 'new', officeName: '', county: county!, district: office?.district ?? undefined})
        } else if (val.startsWith('/api/offices')) {
            const hasDistrict = skilOffices.find(o => o['@id'] === val)?.district
            onChange({officeType: 'skil', office: val, county: county!, district: hasDistrict ?? district ?? undefined, officeName: label})
        } else {
            onChange({officeType: 'external', externalOffice: val, county: county!, officeName: label})
        }
    }

    const selectedOffice =
        office?.officeType === 'skil'
            ? office.office
            : office?.officeType === 'external'
            ? office.externalOffice
            : office?.officeType === 'new'
            ? null
            : undefined

    return (
        <>
            <Autocomplete
                sx={{mb: 2}}
                id={'choose-office' + generateRandomSixDigitNumber()}
                fullWidth
                // @ts-expect-error
                value={selectedOffice}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disabled={!county}
                options={options}
                // @ts-expect-error
                onChange={(event: any, newValue: {label: string; value: string}) => {
                    onLocalChange(newValue.value, newValue.label)
                }}
                placeholder={'Velg...'}
                disableClearable={true}
                getOptionLabel={option => option.label ?? ''}
                renderOption={(props, option) => {
                    const type = option.type
                    return (
                        <li {...props} key={props.id}>
                            <div style={{width: '100%'}}>
                                <span className='pull-left'>{option.label}</span>
                                <span className='pull-right'>
                                    {(type === 'both' || type === 'skil') && <em className='label label-info pull-right'> SKIL</em>}
                                    {(type === 'both' || type === 'helfo') && <em className='label label-info pull-right'> HELFO</em>}
                                    {type === 'new' && <strong>{option.label}</strong>}
                                </span>
                            </div>
                            <br />
                        </li>
                    )
                }}
                renderInput={params => (
                    <TextField
                        required={true}
                        {...params}
                        label={'Velg arbeidssted'}
                        // @ts-expect-error
                        helperText={county ? '' : 'Du må velge kommune først'}
                    />
                )}
            />
            {loading && county && <LoadingComponent msg={'Laster inn...'} />}
            {office?.officeType === 'new' && (
                <TextField
                    id={'office-name'}
                    label={'Legekontor navn'}
                    value={office.officeName}
                    onChange={officeName => onChange({...office, officeName})}
                />
            )}
        </>
    )
}
