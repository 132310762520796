import * as React from 'react'
import {styled} from '@mui/material/styles'
import {Tabs as MuiTabs} from '@mui/material'
import {TabsProps} from '@mui/material/Tabs'

const Tabs = styled((props: TabsProps) => (
    <MuiTabs {...props} TabIndicatorProps={{children: <span className='MuiTabs-indicatorSpan' />}} />
))(({theme}) => ({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        bottom: 'initial',
        top: 0,
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
    },
}))

export default Tabs
