import * as React from 'react'
import Button from '../../Button/Button'
import {GridColDef} from '@mui/x-data-grid-premium'
import {sortComparator, customValueGetter, resolve} from './columnDef'

const renderCell = params => {
    if (!params.value) return ''

    const user = resolve(params.field, params.row) as {id: number; name: string}

    return (
        <Button variant='text' href={`/dashboard/users/${user.id}`}>
            {user.name}
        </Button>
    )
}

type Column = GridColDef & {
    type?: 'user_type'
}
export default function userColumnDef(col: Column): GridColDef {
    let valueGetter = customValueGetter(col.valueGetter, 'name')

    return {
        flex: 1,
        headerName: 'Navn',
        type: 'string',
        sortComparator,
        renderCell,
        ...col,
        valueGetter,
    }
}
