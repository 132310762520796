import PurePage from '../../../Components/PurePage'
import {BackdropComponent} from '../../../Components/BackdropComponent'
import React, {useEffect, useState} from 'react'
import useUser from '../../../Utilities/useUser'
import {Box, Checkbox, Divider, FormControlLabel, Grid, Skeleton, Stack, useMediaQuery} from '@mui/material'
import 'dayjs/locale/nb'
import {Button} from '../../../Components/Button/MuiButton'
import {useQueryInvalidate, useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import styled from 'styled-components'
import {z} from 'zod'
import {useParams, useNavigate} from 'react-router'
import {zodResolver} from '@hookform/resolvers/zod'
import TermsOfServiceDialog from './TermsOfServiceDialog'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import InvoiceDetails from './InvoiceDetails'
import LearningGoalsDialog from './LearningGoalsDialog'
import {useTheme} from '@mui/material/styles'
import {Title, SmallSection, LearningGoalContainer, Section, TextHeading, StyledLink, SkeletonTitleContainer} from './GroupDetailsPage'
import LoadingComponent from '../../../Components/LoadingComponent'

const StyledGrid = styled(Grid)`
    .MuiCard-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`
const TosFormControlLabel = styled(FormControlLabel)`
    span:nth-of-type(2) {
        margin-bottom: 0;
    }
`

const validationSchema = z.object({
    tosAccepted: z
        .boolean({
            required_error: 'Du må godkjenne vilkår for å fortsette',
        })
        .refine(tosAccepted => tosAccepted, {
            message: 'Du må godkjenne vilkår for å fortsette',
        }),

    invoice: z.optional(
        z.object({
            private: z.optional(
                z.object({
                    name: z.string().nonempty({message: 'Navn er påkrevd'}),
                    address: z.string().nonempty({message: 'Adresse er påkrevd'}),
                    postalcode: z
                        .string({
                            required_error: 'Postnummer er påkrevd',
                            invalid_type_error: 'Postnummeret er ugyldig',
                        })
                        .refine(
                            code => {
                                return /^\d{4}$/.test(code)
                            },
                            {
                                message: 'Postnummer må være 4 siffer',
                            }
                        ),
                    postalarea: z.string().nonempty({message: 'Poststed er påkrevd'}),
                })
            ),
            company: z.optional(
                z
                    .object({
                        orgnr: z.string().refine(
                            orgnr => {
                                return /^\d{9}$/.test(orgnr)
                            },
                            {message: 'Organisasjonsnummeret må være 9 siffer'}
                        ),
                        orgForm: z.string().nonempty({message: 'Organisasjonsform er påkrevd'}),
                        name: z.string().nonempty({message: 'Organisasjonsnavn er påkrevd'}),
                        email: z
                            .string({
                                required_error: 'E-post er påkrevd',
                                invalid_type_error: 'E-post er ugyldig',
                            })
                            .email({message: 'Ugyldig e-post'}),
                        reference: z.string().optional(),
                        resource: z.string(),
                    })
                    .refine(
                        ({orgForm, resource}) => {
                            if (orgForm === 'KOMM') {
                                return resource !== '' && resource !== undefined
                            } else {
                                return true
                            }
                        },
                        {
                            path: ['resource'],
                            message: 'Påkrevd når kommune faktureres',
                        }
                    )
            ),
            sole_proprietorship: z.optional(
                z.object({
                    orgnr: z.string().refine(orgnr => /^\d{9}$/.test(orgnr), {message: 'Organisasjonsnummeret må være 9 siffer'}),
                    orgForm: z.string().nonempty({message: 'Organisasjonsform er påkrevd'}),
                    name: z.string().nonempty({message: 'Organisasjonsnavn er påkrevd'}),
                    address: z.string().nonempty({message: 'Addresse er påkrevd'}),
                    postalcode: z
                        .string({
                            required_error: 'Postnummer er påkrevd',
                            invalid_type_error: 'Postnummeret er ugyldig',
                        })
                        .refine(
                            code => {
                                return /^\d{4}$/.test(code)
                            },
                            {
                                message: 'Postnummer må være 4 siffer',
                            }
                        ),
                    postalarea: z.string().nonempty({message: 'Poststed er påkrevd'}),
                })
            ),
        })
    ),
})

type ValidationSchema = z.infer<typeof validationSchema>

export default function AcknowledgePage() {
    const {
        register,
        control,
        handleSubmit,
        formState: {isValid, errors, isDirty},
        unregister,
        trigger,
        setValue,
        clearErrors,
        setError,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
        mode: 'all',
        defaultValues: {
            tosAccepted: false,
            invoice: {
                private: {
                    name: '',
                    address: '',
                    postalcode: '',
                    postalarea: '',
                },
                company: {
                    orgnr: '',
                    orgForm: '',
                    email: '',
                    reference: '',
                    resource: '',
                },
                sole_proprietorship: {
                    orgnr: '',
                    orgForm: '',
                    name: '',
                    address: '',
                    postalcode: '',
                    postalarea: '',
                },
            },
        },
    })

    const user = useUser()
    const {shortTitle, redirect} = useParams<{shortTitle: string; redirect: string}>()
    const theme = useTheme()
    const navigate = useNavigate()
    const invalidate = useQueryInvalidate()
    const [hasRefetchedUser, setHasRefetchedUser] = React.useState(false)
    const [isRefetchingUser, setIsRefetchingUser] = React.useState<boolean>(false)
    const declineCourseInvitation = useSkilMutation<'declineCourseInvitation'>('POST', `/api/user_courses/declineCourseInvitation`)
    const approveCourseInvitation = useSkilMutation<'approveCourseInvitation'>('POST', `/api/user_courses/approveCourseInvitation`)
    const [tosAccepted, setTosAccepted] = React.useState<boolean>(false)
    const [termsOfServiceDialogOpen, setTermsOfServiceDialogOpen] = React.useState(false)
    const [learningGoalsDialogOpen, setLearningGoalsDialogOpen] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [isDeclining, setIsDeclining] = React.useState(false)
    const [isReadyToRefetchUserCourse, setIsReadyToRefetchUserCourse] = React.useState<boolean>(false)
    const [hasRefetchedUserCourse, setHasRefetchedUserCourse] = React.useState(false)
    const [baseRedirectUrl, setBaseRedirectUrl] = React.useState<string>(redirect ?? '')
    const formRef = React.useRef<HTMLFormElement>(null)
    const {data: course} = useSkilQuery<'getCoursePublicInfoByShortTitle'>(
        `/api/courses/${shortTitle}/publicInfo/byShortTitle`,
        {},
        {enabled: !!shortTitle && hasRefetchedUser}
    )

    const {
        data: userCourseResponse,
        refetch: refetchUserCourse,
        isLoading: isLoadingUserCourse,
        isFetching: isFetchingUserCourse,
        isError: isErrorUserCourse,
        isLoadingError: isLoadingErrorUserCourse,
        isRefetching: isRefetchingUserCourse,
    } = useSkilQuery<'userCourseByUserAndCourse'>(
        `/api/user_courses/forGroupSignup`,
        {
            courseId: course?.id,
        },
        {cacheTime: 0, refetchOnMount: true, enabled: isReadyToRefetchUserCourse}
    )

    const userCourse = userCourseResponse?.['hydra:member']?.[0]
    const readyToShow = course && user?.authenticated && hasRefetchedUserCourse
    const [hasAlreadyAcknowledged, setHasAlreadyAcknowledged] = React.useState<boolean>(false)
    const [isSearchingOrgNr, setIsSearchingOrgNr] = useState(false)
    const [canSignUpToCourse, setCanSignUpToCourse] = React.useState<boolean>(false)
    const courseDescription = course?.description ? {__html: course.description} : undefined
    const orientation = useMediaQuery(theme.breakpoints.down('md')) ? 'horizontal' : 'vertical'
    const courseNotFound = course?.['hydra:description'] === 'Not Found' || course?.['@type'] === 'hydra:Error'
    const [recheckAcknowledgeCourse, setRecheckAcknowledgeCourse] = React.useState<boolean>(false)

    useEffect(() => {
        if (redirect) {
            // Decode the URL-encoded string
            const checkDecodedRedirect = decodeURIComponent(redirect)

            // Extract the base URL and parameters
            const [path, queryParams] = checkDecodedRedirect.split('?')
            if (path) {
                setBaseRedirectUrl(path)
            }
            // Parse the query parameters into an object
            const searchParams = new URLSearchParams(queryParams)
            const paramsObject = {}
            searchParams.forEach((value, key) => {
                if (key === 'recheck' && value === 'true') {
                    setRecheckAcknowledgeCourse(true)
                }
                paramsObject[key] = value
            })
        }
    }, [redirect])

    // if the course uses groups, determine if the user needs to be in a group to sign up or if the user can sign up individually
    const evaluateUseOfGroups = React.useMemo(() => {
        if (course?.usesGroups) {
            return (
                userCourseResponse?.['hydra:description'] !== 'Not Found' &&
                userCourseResponse?.['@type'] !== 'hydra:Error' &&
                userCourse?.id
            )
        } else {
            // if the course does not use groups (individual signup), then we can sign up to the course without checking if the userCourse exists
            return true
        }
    }, [course, userCourseResponse])

    const acknowledgePossible = React.useMemo(
        () =>
            course?.signupAllowed && !courseNotFound && course?.['@id'] && evaluateUseOfGroups && userCourse?.isCourseAcknowledged !== true,
        [userCourse, course]
    )

    function calculateIfUserShouldPay() {
        if (!course?.price || course?.price === 0 || (!userCourse?.price && course?.usesGroups) || userCourse?.price === 0) {
            return false
        }
        return true
    }

    const userShouldPay = React.useMemo(() => calculateIfUserShouldPay(), [userCourse, course])

    useEffect(() => {
        // allow time to actually be logged out in the backend
        const timer = setTimeout(() => {
            invalidate(['/api/login/connect/user']).then(() => {
                user.refresh()
                setIsRefetchingUser(true)
            })
        }, 1000)

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (
            !hasRefetchedUser &&
            isRefetchingUser &&
            !user.isLoading &&
            !user.isRefetching &&
            (user.isSuccess || user.isError || user.isLoadingError)
        ) {
            // when we have finished refetching the user, we can set the hasRefreshedUser to true, either if we have an error or success
            setIsRefetchingUser(false)
            setHasRefetchedUser(true)
        }
    }, [user])

    // refetch userCourse when the course is found because we want to make sure that the userCourse is up to date
    useEffect(() => {
        if (course?.['@id'] && hasRefetchedUser) {
            setIsReadyToRefetchUserCourse(true)
            refetchUserCourse().then(() => {
                setHasRefetchedUserCourse(true)
            })
        }
    }, [course, hasRefetchedUser])

    useEffect(() => {
        // if user is not authenticated then navigate to login page with redirect to this page
        if (!user.authenticated) {
            navigate(`/signup/${shortTitle}/${encodeURIComponent(window.location.pathname)}`)
            //navigate(`/signup/${shortTitle}?redirect=${window.location.pathname}`)
        }
    }, [user])

    // if the user does not pay, then we do not collect the invoice details
    // so we need to remove the fields so that they are not validated
    useEffect(() => {
        if (!userShouldPay) {
            unregister([
                'invoice.private.name',
                'invoice.private.address',
                'invoice.private.postalcode',
                'invoice.private.postalarea',
                'invoice.company.orgnr',
                'invoice.company.orgForm',
                'invoice.company.name',
                'invoice.company.email',
                'invoice.company.reference',
                'invoice.company.resource',
                'invoice.sole_proprietorship.orgnr',
                'invoice.sole_proprietorship.orgForm',
                'invoice.sole_proprietorship.name',
                'invoice.sole_proprietorship.address',
                'invoice.sole_proprietorship.postalcode',
                'invoice.sole_proprietorship.postalarea',
            ])
        }
    }, [course, userCourse])

    function delayRedirect(url: string) {
        const timer = setTimeout(() => {
            window.location.replace(url)
        }, 1500)
    }

    // if the user has already acknowledged the course then redirect to the course page
    useEffect(() => {
        if (userCourseResponse && course && userCourse?.isCourseAcknowledged && hasRefetchedUserCourse) {
            setHasAlreadyAcknowledged(true)
            toast(`Du er allerede påmeldt kurset ${course.title}`, {
                type: 'success',
            })
            // if there are more than one userCourse to acknowledge, then redirect to the acknowledge the next one
            if (recheckAcknowledgeCourse) {
                const decodedRedirect = decodeURIComponent(`/dashboard/course/${course.shortTitle}/tasks`)
                delayRedirect(`${window.location.protocol}//${window.location.hostname}/login_recheck_acknowledgeCourse?${decodedRedirect}`)
            } else {
                delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard/course/${course.shortTitle}/tasks`)
            }
        }
    }, [userCourse, hasRefetchedUserCourse, hasRefetchedUser, user.isLoading])

    // make sure that the course and userCourse exists, is not archived and is published
    useEffect(() => {
        if (!course) return
        if (!hasRefetchedUser) return
        if (hasRefetchedUser && user.isLoading) return
        if (!acknowledgePossible && userCourseResponse !== undefined) {
            setCanSignUpToCourse(false)
            // if the course is not found then redirect to the dashboard
            if (courseNotFound) {
                handleErrorWithToast(new Error('Dette kurset finnes ikke'))
                delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard`)
            }
            // if the course is found but is not signupAllowed then redirect to the dashboard
            if (!course?.signupAllowed && !courseNotFound) {
                denyInvitation().then(r => {
                    handleErrorWithToast(new Error('Kurset er ikke åpent for påmelding'))
                    delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard`)
                })
            }
            // if the course is found and the userCourse is not found, then redirect to the dashboard
            if (!userCourse && !courseNotFound && !isLoadingUserCourse && !isFetchingUserCourse && !isRefetchingUserCourse) {
                handleErrorWithToast(new Error('Kursinvitasjonen finnes ikke'))
                delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard`)
            }
            // if the course is not found then redirect to the dashboard
            if (course?.['@type'] === 'hydra:Error' && !courseNotFound) {
                handleErrorWithToast(new Error('En feil har oppstått, IT avdelingen er blitt varslet, forsøk igjen senere'))
                delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard`)
            }
        } else {
            setCanSignUpToCourse(true)
        }
    }, [course, userCourse, hasRefetchedUserCourse, hasRefetchedUser, user.isLoading, isErrorUserCourse, isLoadingErrorUserCourse])

    const denyInvitation = async () => {
        if (!user || !course) {
            handleErrorWithToast(
                ' Det skjedde en feil under avmelding. Bruker eller kurs er ugyldig. Last inn siden på nytt og prøv igjen.'
            )
            return
        }
        setIsDeclining(true)

        try {
            await declineCourseInvitation
                .mutateAsync({
                    user: user.iri,
                    course: `/api/courses/${course.id}`,
                })
                .then(response => {
                    if (response) {
                        if (
                            response &&
                            response['status'] &&
                            // @ts-expect-error
                            (response['status'] === 200 ||
                                // @ts-expect-error
                                response['status'] === 204 ||
                                // @ts-expect-error
                                response['status'] === 205 ||
                                // @ts-expect-error
                                response['status'] === 226 ||
                                // @ts-expect-error
                                response['status'] === 401)
                        ) {
                            type TypeOptions = 'success' | 'warning' | 'error' | 'info'
                            let toastType: TypeOptions = 'success'
                            switch (response['status']) {
                                case 200:
                                    toastType = 'success'
                                    break
                                case 204:
                                    toastType = 'warning'
                                    break
                                case 205:
                                    toastType = 'warning'
                                    break
                                case 226:
                                    toastType = 'warning'
                                    break
                                case 401:
                                    toastType = 'error'
                                    break
                                default:
                                    toastType = 'success'
                            }

                            toast(response['message'], {type: toastType})
                            if (redirect) {
                                // if there are more than one userCourse to acknowledge, then redirect to acknowledge the next one
                                if (recheckAcknowledgeCourse) {
                                    if (redirect.includes('https')) {
                                        const decodedRedirect = decodeURIComponent('/login_recheck_acknowledgeCourse?' + baseRedirectUrl)
                                        delayRedirect(`${decodedRedirect}`)
                                    } else {
                                        delayRedirect(
                                            `${window.location.protocol}//${window.location.hostname}${
                                                '/login_recheck_acknowledgeCourse?' + baseRedirectUrl
                                            }`
                                        )
                                    }
                                } else {
                                    if (redirect.includes('https')) {
                                        const decodedRedirect = decodeURIComponent(redirect)
                                        delayRedirect(`${decodedRedirect}`)
                                    } else {
                                        delayRedirect(`${window.location.protocol}//${window.location.hostname}${redirect}`)
                                    }
                                }
                            } else {
                                // successfully removed invitation or could not find invitation
                                if (response['status'] === 200 || response['status'] === 204 || response['status'] === 205) {
                                    delayRedirect(`${window.location.protocol}//${window.location.hostname}/dashboard`)
                                }
                                // already signed up, cannot remove invitation, so redirect to course page
                                if (response['status'] === 226) {
                                    delayRedirect(
                                        `${window.location.protocol}//${window.location.hostname}/dashboard/course/${course.shortTitle}/tasks`
                                    )
                                }
                                // not logged in or trying to decline invitation for another user
                                if (response['status'] === 401) {
                                    delayRedirect(`${window.location.protocol}//${window.location.hostname}`)
                                }
                            }
                        }
                    }
                })
        } catch (e) {
            handleErrorWithToast('Det skjedde en feil under avmelding. IT avdelingen er blitt varslet. Prøv igjen senere.')
            setIsDeclining(false)
        }
    }

    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        if (isSubmitting || !course || !shortTitle) {
            return
        }
        setIsSubmitting(true)
        try {
            await approveCourseInvitation
                .mutateAsync({
                    user: user.iri,
                    course: `/api/courses/${course.id}`,
                    tosAccepted: tosAccepted,
                    // @ts-expect-error
                    invoice: data.invoice,
                })
                .then(userCourse => {
                    toast(`Du er nå påmeldt kurset ${course.title}`, {type: 'success'})
                    if (userCourse.id && redirect) {
                        // if there are more than one userCourse to acknowledge, then redirect to the acknowledge the next one
                        if (recheckAcknowledgeCourse) {
                            if (redirect.includes('https')) {
                                const decodedRedirect = decodeURIComponent('/login_recheck_acknowledgeCourse?' + baseRedirectUrl)
                                delayRedirect(`${decodedRedirect}`)
                            } else {
                                delayRedirect(
                                    `${window.location.protocol}//${window.location.hostname}${
                                        '/login_recheck_acknowledgeCourse?' + baseRedirectUrl
                                    }`
                                )
                            }
                        } else {
                            if (redirect.includes('https')) {
                                const decodedRedirect = decodeURIComponent(redirect)
                                delayRedirect(`${decodedRedirect}`)
                            } else {
                                delayRedirect(`${window.location.protocol}//${window.location.hostname}${redirect}`)
                            }
                        }
                    }
                    if (userCourse.id && !redirect) {
                        delayRedirect(
                            `${window.location.protocol}//${window.location.hostname}/dashboard/course/${course.shortTitle}/tasks`
                        )
                    }
                })
        } catch (e) {
            handleErrorWithToast(e + ' Det skjedde en feil under påmeldingen. Prøv igjen.')
            setIsSubmitting(false)
        }
    }

    const isSubmittable = isDirty && isValid
    const isReadyToShowInvoiceDetails = tosAccepted && userShouldPay

    if (!hasRefetchedUser || (hasRefetchedUser && user.isLoading)) {
        return <LoadingComponent />
    }

    return (
        <PurePage variant={'md'}>
            <form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
                {readyToShow && (
                    <>
                        <Box sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '1em', sm: '2em'}}}>
                            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: {xs: '1em', sm: '2em'}}}>
                                {/* @ts-expect-error */}
                                <img width={'20%'} id='course-logo' src={course?.logo} alt={course?.shortTitle} />
                            </Box>
                            <Title>{course ? course.title : ''}</Title>
                            {courseDescription && (
                                <Section>
                                    <div dangerouslySetInnerHTML={courseDescription} />
                                </Section>
                            )}
                            <SmallSection>
                                {course?.learningGoal && (
                                    <>
                                        <LearningGoalContainer>
                                            <a href={'#'} onClick={() => setLearningGoalsDialogOpen(true)}>
                                                {course.type === 3
                                                    ? 'Se læringsmålene for SKIL Forum'
                                                    : course.type === 2
                                                    ? 'Se læringsmålene for seminaret'
                                                    : 'Se læringsmålene for kurset'}
                                            </a>
                                        </LearningGoalContainer>
                                    </>
                                )}
                                {!!course?.approvedPoints && (course?.type === 0 || course?.type === 1) && (
                                    <TextHeading>
                                        {course?.type === 0
                                            ? course.approvedPoints + ' valgfrie poeng'
                                            : course?.type === 1
                                            ? course.approvedPoints + ' klinisk emnekurs poeng'
                                            : ''}
                                    </TextHeading>
                                )}
                                {course?.isApprovedForRefund && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: {xs: 'space-between', sm: 'start'},
                                            alignItems: 'baseline',
                                        }}
                                    >
                                        <TextHeading>Kurset er godkjent for refusjon</TextHeading>
                                        <StyledLink>
                                            <a href={'https://www.skilnet.no/refusjon/'} target={'_blank'}>
                                                {' '}
                                                Les mer
                                            </a>
                                        </StyledLink>
                                    </Box>
                                )}
                                <SmallSection>
                                    <TextHeading>
                                        <strong>Bindende påmelding</strong>
                                    </TextHeading>
                                    <TextHeading>
                                        Pris per lege: {course?.price === 0 || !course?.price ? 'GRATIS' : course?.price + ' kr'}
                                    </TextHeading>
                                    {/* @ts-expect-error */}
                                    {userCourse && course && course?.price > userCourse?.price && userShouldPay && (
                                        <TextHeading>Din pris: {userCourse?.price} kr</TextHeading>
                                    )}
                                    {course?.price !== userCourse?.price && course?.price !== 0 && !userShouldPay && (
                                        <TextHeading>Din pris: GRATIS</TextHeading>
                                    )}
                                    <Stack
                                        sx={{alignItems: 'center', justifyContent: 'space-between'}}
                                        direction={{sm: 'column', md: 'row'}}
                                        justifyContent={'center'}
                                        alignItems={'flex-start'}
                                        spacing={2}
                                        divider={
                                            <Divider sx={{padding: {xs: '1em', sm: '0em'}}} orientation={orientation} flexItem={true}>
                                                eller
                                            </Divider>
                                        }
                                    >
                                        <div>
                                            <Controller
                                                name={'tosAccepted'}
                                                control={control}
                                                render={({field: {onChange}, fieldState: {error}}) => (
                                                    <TosFormControlLabel
                                                        sx={{
                                                            mb: '0em',
                                                            ml: '0em',
                                                            mr: '0em',
                                                            border: errors?.tosAccepted ? 'red solid 1px' : 'none',
                                                            borderRadius: '0.25em',
                                                            padding: '0.5em 1em 0.5em 0',
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                required
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                                {...register('tosAccepted')}
                                                                onChange={(e, checked) => {
                                                                    onChange(checked)
                                                                    setTosAccepted(checked)
                                                                    if (!checked) {
                                                                        setError('tosAccepted', {
                                                                            type: 'boolean',
                                                                            message: 'Vilkårene må godtas for å melde seg på kurset',
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        defaultChecked={false}
                                                        label={
                                                            <span>
                                                                Lest og godtatt{' '}
                                                                <a href={'#'} onClick={() => setTermsOfServiceDialogOpen(true)}>
                                                                    vilkår
                                                                </a>{' '}
                                                            </span>
                                                        }
                                                    />
                                                )}
                                            />
                                            {errors?.tosAccepted && (
                                                <FormHelperText style={{color: '#ab3e36'}}>
                                                    {errors.tosAccepted.message ?? ''}
                                                </FormHelperText>
                                            )}
                                        </div>
                                        <Button
                                            sx={{marginTop: {xs: '1em', sm: '0em'}}}
                                            type={'button'}
                                            size={'large'}
                                            color={'error'}
                                            onClick={() => denyInvitation()}
                                            confirm={'Bekreft'}
                                        >
                                            Avslå invitasjonen&nbsp;&nbsp;
                                            {isDeclining && <CircularProgress color='inherit' size={'1.5em'} />}
                                        </Button>
                                    </Stack>
                                </SmallSection>
                                {isReadyToShowInvoiceDetails && (
                                    <InvoiceDetails
                                        register={register}
                                        errors={errors}
                                        control={control}
                                        unregister={unregister}
                                        trigger={trigger}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        setError={setError}
                                        tosAccepted={tosAccepted}
                                        invoice={null}
                                        isSearchingOrgNr={isSearchingOrgNr}
                                        setIsSearchingOrgNr={setIsSearchingOrgNr}
                                        user={user}
                                        isNefleSignup={false}
                                    />
                                )}
                            </SmallSection>
                        </Box>
                    </>
                )}
                {!readyToShow && (
                    <>
                        <SkeletonTitleContainer>
                            <Skeleton variant='text' animation='wave' sx={{width: '50%'}} />
                        </SkeletonTitleContainer>
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '50%', mt: 1}} />
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1}} />
                        <Grid width={'100%'} sx={{mt: 1}}>
                            <StyledGrid
                                display={'flex'}
                                width={'100%'}
                                xs={12}
                                sm={12}
                                md={12}
                                item
                                sx={{justifyContent: {xs: 'center', sm: 'initial'}}}
                            >
                                <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%', mr: 2}} />
                                <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%'}} />
                            </StyledGrid>
                        </Grid>
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '25%', mt: 1}} />
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1}} />
                    </>
                )}
                {termsOfServiceDialogOpen && (
                    <TermsOfServiceDialog isOpen={termsOfServiceDialogOpen} onClose={() => setTermsOfServiceDialogOpen(false)} />
                )}
                {learningGoalsDialogOpen && course?.learningGoal && (
                    <LearningGoalsDialog
                        isOpen={learningGoalsDialogOpen}
                        onClose={() => setLearningGoalsDialogOpen(false)}
                        learningGoal={course.learningGoal}
                    />
                )}

                {readyToShow && (
                    <>
                        <Button type={'submit'} size={'large'} fullWidth disabled={!isSubmittable || isSubmitting}>
                            Meld meg på kurset&nbsp;&nbsp;
                            {isSubmitting && <CircularProgress color='inherit' size={'1.5em'} />}
                        </Button>
                        <Box sx={{marginTop: 4}}>
                            <p>
                                NB! For gjennomføring av kurset anbefaler vi en oppdatert versjon av nettleserne Google Chrome eller Mozilla
                                Firefox.
                            </p>
                        </Box>
                    </>
                )}
                {(isSubmitting || isDeclining || (!canSignUpToCourse && !!course) || hasAlreadyAcknowledged) && (
                    <BackdropComponent
                        isOpen={isSubmitting || isDeclining || (!canSignUpToCourse && !!course) || hasAlreadyAcknowledged}
                        status={'Behandler...'}
                    />
                )}
            </form>
        </PurePage>
    )
}
