import Dialog from '@mui/material/Dialog'
import {DialogTitle, MenuItem} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import * as React from 'react'
import {components} from '../../Generated/eportal'
import {COURSE_TRIGGER_OPTIONS, TASK_TRIGGER_OPTIONS, TRIGGER_OPTIONS} from '../constants/jobTriggers'
import {CourseAutocomplete, CourseType} from '../../Components/CourseAutocomplete'
import {TaskAutocomplete, TaskType} from '../../Components/TaskAutocomplete'

type NewJobType = components['schemas']['Job.jsonld-Job.construct_Job.write']

type Props = {
    isOpen: boolean
    handleClose: () => any
    handleSubmit: (newJob: NewJobType) => any
}

export const CreateJobDialog = ({isOpen, handleClose, handleSubmit}: Props) => {
    const [trigger, setTrigger] = React.useState<string>('')
    const [course, setCourse] = React.useState<CourseType | null>(null)
    const [task, setTask] = React.useState<TaskType | null>(null)

    const handleDialogClose = () => {
        setTrigger('')
        setCourse(null)
        setTask(null)
        return handleClose()
    }

    const handleDialogSubmit = async () => {
        await handleSubmit({
            trigger: trigger,
            course: isCourseTrigger ? course!['@id']! : null,
            task: isTaskTrigger ? task!['@id']! : null,
            content: '',
            days: 0,
            event: 0,
            ifCourse: false,
            ifState: 0,
            title: '',
            userType: 0,
            when: 0,
        })
        handleDialogClose()
    }

    const isTaskTrigger = TASK_TRIGGER_OPTIONS.some(o => o.value === trigger)
    const isCourseTrigger = isTaskTrigger || COURSE_TRIGGER_OPTIONS.some(o => o.value === trigger)
    const isGlobalTrigger = !isCourseTrigger

    return (
        <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogTitle>Opprett ny jobb</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            id='trigger'
                            label='Hendelse'
                            select
                            fullWidth
                            required
                            value={trigger}
                            onChange={event => {
                                setTrigger(event.target.value)
                            }}
                        >
                            {TRIGGER_OPTIONS.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <CourseAutocomplete
                            disabled={!isCourseTrigger}
                            required={isCourseTrigger}
                            value={course?.['@id'] ?? null}
                            onChange={(e, course) => setCourse(course)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TaskAutocomplete
                            required={isTaskTrigger}
                            disabled={!isTaskTrigger || !course?.shortTitle}
                            value={task?.['@id'] ?? null}
                            onChange={(event, task) => setTask(task)}
                            courseShortTitle={course?.shortTitle}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                <DialogActions sx={{padding: 0}}>
                    <Button variant='outlined' onClick={handleDialogClose}>
                        Avbryt
                    </Button>
                    <Button
                        disabled={!trigger || (isTaskTrigger && !task) || (isCourseTrigger && !course)}
                        variant='contained'
                        onClick={handleDialogSubmit}
                    >
                        Opprett job
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
