import React from 'react'
import Modal from './Modal'
import Panel from './Panel'
import {CustomUserType} from '../Groups/Meeting'
import styled from 'styled-components'
import {toast} from 'react-toastify'

type Props = {
    onSave?: () => void
    onClose: () => void
    users: CustomUserType[]
    missingUsers: CustomUserType[]
    name: string
}

const Table = styled.table`
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    @media (max-width: 650px) {
        th {
            display: none;
        }
        td {
            display: grid;
            gap: 0.5rem;
            grid-template-columns: 1fr 1fr;
            padding: 0.5rem 1rem;
        }
        td:first-child {
            padding-top: 2rem;
        }
        td:last-child {
            padding-bottom: 2rem;
        }
        td::before {
            font-weight: 700;
        }
        td: nth-of-type(1)::before {
            content: 'Status';
        }
        td: nth-of-type(2)::before {
            content: 'Navn';
        }
        td: nth-of-type(3)::before {
            content: 'Kontor';
        }
        td: nth-of-type(4)::before {
            content: 'Kommune';
        }
        td: nth-of-type(5)::before {
            content: 'Rolle';
        }
    }
`

const Caption = styled.caption`
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: inherit;
    color: inherit;
`

export default function ConfirmMeetingParticipation({onSave, onClose, users, missingUsers, name}: Props) {
    const [isSaving, setIsSaving] = React.useState(false)
    const [showConfirmModal, setShowConfirmModal] = React.useState(false)

    const onSubmit = () => {
        return onSave ? onSave() : toast('Ingen funksjon for lagring', {type: 'error'})
    }

    return (
        <Modal
            title={<strong>Bekreftelse av deltakerlisten</strong>}
            onSave={() => {
                if (users.length === 0) {
                    setShowConfirmModal(true)
                }
                if (users.length > 0) {
                    setIsSaving(true)
                    onSubmit()
                }
            }}
            onClose={onClose}
            saveText={`Bekreft`}
            size={'lg'}
            spinnerWhileSaving={isSaving}
            disableWhileSaving={isSaving}
        >
            <div>
                <p>
                    Du er innlogget som {name}
                    <br />
                    og bekrefter at følgende <strong>{users.length} deltakere</strong> var tilstede på møtet:
                </p>
                <div style={{marginTop: '2em'}}>
                    <Table className={'table'}>
                        <Caption>Deltakere</Caption>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Navn</th>
                                <th>Kontor</th>
                                <th>Kommune</th>
                                <th>Rolle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length < 1 && (
                                <tr>
                                    <td>Det er ingen deltakere tilstede</td>
                                </tr>
                            )}
                            {users.map(u => {
                                return (
                                    <tr key={u.id}>
                                        <td>{u.status}</td>
                                        <td>{u.name}</td>
                                        <td>{u.officeName}</td>
                                        <td>{u.county}</td>
                                        <td>
                                            {u.roles
                                                .filter(r => r !== 'Deltaker')
                                                .map(r => (
                                                    <em key={r} className='label label-primary pull-right'>
                                                        {r}
                                                    </em>
                                                ))}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <thead>
                            <tr>
                                <th>Fraværende</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {missingUsers.length < 1 && (
                                <tr>
                                    <td>Det er ingen fraværende deltakere</td>
                                </tr>
                            )}
                            {missingUsers.map(u => {
                                {
                                    return (
                                        <tr key={u.id} style={{background: 'lightgrey'}}>
                                            <td>{u.status}</td>
                                            <td>{u.name}</td>
                                            <td>{u.officeName}</td>
                                            <td>{u.county}</td>
                                            <td>
                                                {u.roles
                                                    .filter(r => r !== 'Deltaker')
                                                    .map(r => (
                                                        <em key={r} className='label label-primary pull-right'>
                                                            {r}
                                                        </em>
                                                    ))}
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </Table>
                </div>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <strong>
                        {users.length} av {users.length + missingUsers.length} får deltakelse godkjent
                    </strong>
                </div>
            </div>
            {showConfirmModal && (
                <Modal
                    onClose={() => setShowConfirmModal(false)}
                    onSave={() => {
                        setIsSaving(true)
                        onSubmit()
                    }}
                    spinnerWhileSaving={isSaving}
                    disableWhileSaving={isSaving}
                    saveText={`Bekreft`}
                    title={
                        <>
                            <i className='fa fa-exclamation-triangle fa-2x' style={{color: 'red', marginRight: '0.5em'}}></i>
                            <strong>Ingen deltakere er lagt til i møtet</strong>
                        </>
                    }
                >
                    <p>
                        Er du sikker på at du vil godkjenne deltakerlisten uten deltakere? <br /> Det betyr at ingen får godkjent
                        møtedeltakelse for dette møtet.
                    </p>
                </Modal>
            )}
        </Modal>
    )
}
