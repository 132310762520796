import React from 'react'
import isPast from 'date-fns/isPast'
import SeminarButton from './Components/SeminarButton'
import useEntities from '../Hooks/useEntities'
import LoadingComponent from '../Components/LoadingComponent'
import useEntity from '../Hooks/useEntity'
import {Page} from '../Components/Page'
import useUser from '../Utilities/useUser'
import {useNavigate, useParams} from 'react-router'
import {Box, FormControl, InputLabel, List, MenuItem, Select, Typography, Grid} from '@mui/material'
import {BigListLink} from '../Components/Button/BigListLink'
import CardButtonWithIcon from './Components/CardButtonWithIcon'
import EmailOpenIcon from '../Components/Icons/EmailOpenIcon'
import CalendarIcon from '../Components/Icons/CalendarIcon'
import ReportIcon from '../Components/Icons/ReportIcon'
import DoctorIcon from '../Components/Icons/DoctorIcon'

export default function KommuneDashboard() {
    const user = useUser()
    const {partnershipId} = useParams()
    // NOTE: if we want to move to useSkilQuery, we should do that before we release the new dashboard.
    const partnerships = useEntities<'getUserPartnerships'>(user.authenticated ? user.iri + '/partnerships' : undefined)
    const partnership = useEntity<'getPartnershipItem'>(`/api/partnerships/${partnershipId}`)
    const qualityReports = partnership.attachments?.filter(a => a?.file?.type === 13)
    const seminars = useEntities<'getPartnershipSeminarCollection'>(partnership ? partnership['@id'] + `/seminars` : undefined)
    const navigate = useNavigate()

    if (!partnership['@loaded'] || !partnerships['@loaded'] || !seminars['@loaded']) return <LoadingComponent msg={'Laster inn...'} />

    partnerships.sort(function (a, b) {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    })

    /*********************************************/
    /*  Dashboard Header with Kommune Selection  */
    /*********************************************/
    const KommuneDashboardHeader = () => {
        return (
            <>
                <Typography variant='h1'>{partnership.name} Kommune</Typography>
                <Typography variant='subtitle1'>SKIL Kommunedashboard</Typography>
                {partnerships['@loaded'] && partnerships.length > 1 && (
                    <FormControl sx={{mt: 1, minWidth: 130}} size='small'>
                        <InputLabel id='kommune-select-label'>Velg kommune/bydel</InputLabel>
                        <Select
                            label='Velg kommune/bydel'
                            labelId='kommune-select-label'
                            autoWidth
                            size='small'
                            value={partnershipId}
                            onChange={e => navigate(`/dashboard/kommuner/${e.target.value}`)}>
                            {partnerships.map(p => {
                                const id = p.partnership?.substring('/api/partnerships/'.length)
                                return (
                                    <MenuItem value={id} key={p.id}>
                                        {p.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                )}
            </>
        )
    }

    /*****************/
    /*  Main Buttons */
    /*****************/
    const MainButtons = props => {
        return (
            <Grid container spacing={1} mb={3} mt={5}>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <CardButtonWithIcon
                        icon={CalendarIcon}
                        sx={{top: 7, left: 7}}
                        label='Planlegg og inviter til kvalitetsseminar'
                        to='create_seminar'
                    />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} sx={{display: 'none'}} item>
                    <CardButtonWithIcon icon={EmailOpenIcon} label='Send e-post til deltakerne' to={`communication`} />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} sx={{display: 'none'}} item>
                    <CardButtonWithIcon icon={DoctorIcon} label='Oversikt over leger i kommunen' to={`doctors`} />
                </Grid>
                {qualityReports?.map(report => {
                    return (
                        <Grid key={report['@id']} display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                            <CardButtonWithIcon
                                icon={ReportIcon}
                                sx={{left: 5}}
                                label={
                                    <span style={{display: 'inline-grid'}}>
                                        <span>Kvalitetsrapport</span>
                                        {report.seminar?.title && <span style={{marginTop: '1em'}}>{report.seminar?.title}</span>}
                                    </span>
                                }
                                href={report.file?.['@id'] + '/download'}
                                isExternal={true}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    /********************/
    /*  Seminar Lists  */
    /********************/
    const previousSeminars = seminars
        .filter(seminar => isPast(new Date(seminar.meetingAt).setHours(new Date(seminar.meetingAt).getHours() + 6)))
        .sort((a, b) => new Date(b.meetingAt).getTime() - new Date(a.meetingAt).getTime())

    const upcomingSeminars = seminars
        .filter(seminar => !isPast(new Date(seminar.meetingAt).setHours(new Date(seminar.meetingAt).getHours() + 6)))
        .sort((a, b) => new Date(a.meetingAt).getTime() - new Date(b.meetingAt).getTime())

    const UpcomingSeminarsList = () => {
        return (
            <>
                <Typography variant='h2'>Kommende kvalitetsseminar</Typography>
                <Box mt={4} mb={6}>
                    {upcomingSeminars.length === 0 && (
                        <Typography variant='body1'>
                            Du har ingen planlagte seminarer. Klikk på knappen over for å planlegge et seminar.
                        </Typography>
                    )}
                    {upcomingSeminars.map((s, index) => (
                        <SeminarButton key={index} partnershipId={partnershipId} seminar={s} isPast={false} />
                    ))}
                </Box>
            </>
        )
    }

    const PreviousSeminarsList = () => (
        <>
            <Typography variant='h2'>Tidligere seminarer</Typography>
            <Box mt={4} mb={6}>
                {previousSeminars.map((s, index) => (
                    <SeminarButton key={index} partnershipId={partnershipId} seminar={s} isPast={true} />
                ))}
            </Box>
        </>
    )

    /*******************/
    /*  Footer Links   */
    /*******************/
    const FooterLinks = () => {
        return (
            <>
                <Typography variant='h2'>Data, verktøy og hjelp</Typography>
                <nav aria-label={`data-verktoy-hjelp`}>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <List sx={{py: 0}}>
                                <BigListLink
                                    key={1}
                                    href='https://www.skilnet.no/kommunesamarbeid/veileder/'
                                    label='Veileder for kommunesamarbeid'
                                    isExternal
                                />
                                <BigListLink key={2} href='mailto:kontakt@skil.no' label='Kontakt SKIL' isEmail />
                                <BigListLink key={3} to='' label='Pasientundersøkelse (kommer)' disabled />
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <List sx={{py: 0}}>
                                <BigListLink
                                    key={1}
                                    href='https://www.helsedirektoratet.no/statistikk/kvalitetsindikatorer/allmennlegetjenesten'
                                    label='Nasjonale kvalitetsindikatorer (Hdir)'
                                    isExternal
                                />
                                <BigListLink
                                    key={2}
                                    href='https://www.helsedirektoratet.no/statistikk/statistikk-om-allmennlegetjenester'
                                    label='Allmennlegedata (Hdir)'
                                    isExternal
                                />
                            </List>
                        </Grid>
                    </Grid>
                </nav>
            </>
        )
    }

    return (
        <Page variant={'default'}>
            <KommuneDashboardHeader />
            <MainButtons partnershipId={partnershipId} />
            <UpcomingSeminarsList />
            {previousSeminars.length > 0 && <PreviousSeminarsList />}
            <FooterLinks />
        </Page>
    )
}
