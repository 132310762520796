import * as React from 'react'
import Grid from '@mui/material/Grid'
import {Button} from '../../Components/Button/MuiButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from 'styled-components'
import {useEffect, useRef} from 'react'

const StyledContainer = styled.div`
    img {
        margin-bottom: 1em;
    }
`

interface Props {
    title: string
    message: string
    onClose: Function
    onRemove?: Function
    isOpen: boolean
}

export default function CustomNewsPopupDialog({title, message, onClose, onRemove, isOpen = false}: Props) {
    const divRef = useRef<HTMLDivElement>()
    const bigScreen = useMediaQuery('(min-width:600px)')

    useEffect(() => {
        divRef.current?.querySelectorAll('a').forEach(el => {
            el.setAttribute('target', '_blank')
        })
    })

    const onLocalRemove = () => {
        if (onRemove) onRemove(false)
    }

    const onLocalClose = () => {
        onClose(false)
    }

    return (
        <div
            style={{
                border: '1px solid black',
                borderRadius: '0.25em',
                backgroundColor: 'white',
                padding: '1em',
                marginBottom: '1em',
                width: bigScreen ? '25em' : '20em',
            }}
        >
            <div>
                <h3>{title}</h3>
                <Grid container spacing={2} mt={2} sx={{marginLeft: '0', width: '100%'}}>
                    <Grid
                        container
                        style={{
                            backgroundColor: '#fff',
                        }}
                    >
                        <Grid item xs={12}>
                            <StyledContainer
                                id={'news_popups_message'}
                                name={'news_popups_message'}
                                // @ts-expect-error
                                ref={divRef}
                                dangerouslySetInnerHTML={{__html: message}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div style={{padding: 3, display: 'flex', justifyContent: 'space-between', marginTop: '2em'}}>
                <Button variant='outlined' onClick={() => onLocalClose()}>
                    <i className='fa fa-1x fa-eye-slash'></i> Vis senere
                </Button>
                <Button variant='contained' onClick={() => onLocalRemove()}>
                    <i className='fa fa-1x fa-trash'></i> Fjern
                </Button>
            </div>
        </div>
    )
}
