import {components} from '../Generated/eportal'
import {SkilQueryResponseType, useSkilQuery} from '../Utilities/QueryClient'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export type TaskType = SkilQueryResponseType<'getTaskItem'>
type Props = {
    value: string | null
    onChange: (event, course: TaskType, iri: string) => any
    required?: boolean
    disabled?: boolean
    courseShortTitle?: string
}
export const TaskAutocomplete = ({value, onChange, courseShortTitle, required = false, disabled = false}: Props) => {
    const searchParameters = {pagination: false}
    if (courseShortTitle) {
        searchParameters['course.shortTitle'] = courseShortTitle
    }
    const {data, isFetching} = useSkilQuery<'getTaskCollection'>('/api/tasks', searchParameters)
    const tasks = data?.['hydra:member'] ?? []
    const localValue = tasks.find(task => task['@id'] === value) ?? null

    const onLocalChange = (event, newValue) => {
        return onChange(event, newValue, newValue?.['@id'] ?? null)
    }

    return (
        <Autocomplete
            id={'task'}
            value={localValue}
            disabled={disabled}
            onChange={onLocalChange}
            disableClearable={required}
            options={tasks}
            getOptionLabel={task => task.title}
            renderInput={params => (
                <TextField
                    required={required}
                    {...params}
                    label='Oppgave'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
