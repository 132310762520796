import * as React from 'react'
import {SetState} from '../../../Utilities/TypeHelpers'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {ChangelogDialog} from './ChangelogDialog'
import {StudyType} from '../../DetailsViews/Study/Study'

interface StudyChangelogDialogProps {
    setOpen: SetState<boolean>
    isOpen: boolean
    study: StudyType
}

export const StudyChangelogDialog = ({setOpen, study, isOpen = false}: StudyChangelogDialogProps) => {
    const {data, isLoading} = useSkilQuery<'getPraksisNettStudy-ChangelogCollection'>(`/api/praksisnett/studies/${study.id}/changelogs`, {
        pagination: false,
    })
    return <ChangelogDialog setOpen={setOpen} isOpen={isOpen} data={data} isLoading={isLoading} />
}
