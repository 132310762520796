import React, {useEffect, useState} from 'react'
import {Box, ButtonGroup, Button, FormGroup, InputAdornment, IconButton, FormControlLabel} from '@mui/material'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import {Controller} from 'react-hook-form'
import {useQuery} from 'react-query'
import styled from 'styled-components'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {useQueryInvalidate} from '../../../Utilities/QueryClient'
import useMediaQuery from '@mui/material/useMediaQuery'
import {INVOICE_RECEIVER_PRIVATE} from './GroupDetailsPage'
import {INVOICE_RECEIVER_SOLE_PROPRIETORSHIP} from './GroupDetailsPage'
import {INVOICE_RECEIVER_COMPANY} from './GroupDetailsPage'

const CustomTextField = styled(TextField)`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`

type SearchBrregResult = {
    organisasjonsnummer: number
    navn: string
    organisasjonsform: {kode: string}
    forretningsadresse?: {adresse: string[]; postnummer: number; poststed: string}
    postadresse?: {adresse: string[]; postnummer: number; poststed: string}
    institusjonellSektorkode: {kode: string}
}

export default function InvoiceDetails({
    control,
    register,
    errors,
    unregister,
    trigger,
    setValue,
    clearErrors,
    setError,
    tosAccepted,
    invoice,
    isSearchingOrgNr,
    setIsSearchingOrgNr,
    user,
    isNefleSignup,
}) {
    const invalidate = useQueryInvalidate()
    const bigScreen = useMediaQuery('(min-width:600px)')
    const [invoiceType, setInvoiceType] = useState(isNefleSignup ? 'company' : 'sole_proprietorship')
    const [sole_proprietorship_orgnr, setSole_proprietorship_orgnr] = useState<number | undefined>(undefined)
    const [company_orgnr, setCompany_orgnr] = useState<number | undefined>(undefined)
    const [zipcode, setZipcode] = useState<number | undefined>(undefined)
    const [orgNrError, setOrgNrError] = useState<string | undefined>(undefined)
    const [isKommune, setIsKommune] = useState(false)
    const orgNrToSearch =
        invoiceType === 'sole_proprietorship' ? sole_proprietorship_orgnr : invoiceType === 'company' ? company_orgnr : null
    const isReadyForSearch = isSearchingOrgNr && orgNrToSearch?.toString()?.length === 9

    const {
        data: dataResult,
        isSuccess,
        isLoading: isLoadingSearchResult,
        // @ts-expect-error
    } = useQuery(`/api/users/searchBrreg/${orgNrToSearch}`, {
        enabled: isReadyForSearch,
        select: (data: any, err) => {
            if (err) {
                handleErrorWithToast('Det skjedde en feil ved søk i Brønnoysundregistrene. Prøv igjen senere. ' + err)
                return
            }
            return data ?? {}
        },
        onSuccess: async () => {
            if (invoiceType === 'company') {
                if (searchResult?.organisasjonsform?.kode !== 'KOMM') {
                    clearErrors(['invoice.company.resource'])
                }
            }
            await invalidate([`/api/users/searchBrreg/${orgNrToSearch}`]).then(() => {})
            await trigger()
        },
        onError: () => {
            handleErrorWithToast('Det skjedde en feil ved søk i Brønnoysundregistrene. Prøv igjen senere.')
        },
    })

    const searchResult = dataResult as SearchBrregResult

    // when searching for an org nr we want to clear the error message
    useEffect(() => {
        if (isReadyForSearch) {
            setOrgNrError(undefined)
        }
    }, [isReadyForSearch])

    // if we have fetched existing invoice details, we want to fill the form with the data when the user accepts the terms of service
    useEffect(() => {
        if (tosAccepted && invoice) {
            if (invoice?.invoiceReceiver === INVOICE_RECEIVER_COMPANY) {
                setInvoiceType('company')
                setCompany_orgnr(invoice?.companyOrgNr)
                setSole_proprietorship_orgnr(undefined)
                setIsSearchingOrgNr(true)
                setValue('invoice.company.email', invoice.emailForInvoiceReceipt, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setValue('invoice.company.reference', invoice.invoiceReference, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setValue('invoice.company.resource', invoice.resourceNumber, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                })
                clearErrors(['invoice.company.resource'])
            } else if (invoice?.invoiceReceiver === INVOICE_RECEIVER_SOLE_PROPRIETORSHIP) {
                setInvoiceType('sole_proprietorship')
                setSole_proprietorship_orgnr(parseInt(invoice?.privateOrgNr))
                setCompany_orgnr(undefined)
                setIsSearchingOrgNr(true)
            } else if (invoice?.invoiceReceiver === INVOICE_RECEIVER_PRIVATE) {
                onActivatePrivateFields()
                setValue('invoice.private.address', invoice?.mailingAddress, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setValue('invoice.private.postalcode', String(invoice?.zipCode), {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setValue('invoice.private.postalarea', invoice?.postal, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                })
            }
            trigger()
        }
    }, [tosAccepted])

    const onActivateSoloProprietorshipFields = async () => {
        setInvoiceType('sole_proprietorship')
        setCompany_orgnr(undefined)
        setZipcode(undefined)
        setIsSearchingOrgNr(false)
        unregister([
            'invoice.private.name',
            'invoice.private.address',
            'invoice.private.postalcode',
            'invoice.private.postalarea',
            'invoice.company.orgnr',
            'invoice.company.orgForm',
            'invoice.company.name',
            'invoice.company.email',
            'invoice.company.reference',
            'invoice.company.resource',
        ])
        clearErrors(['invoice.private', 'invoice.company'])
    }
    const onActivateCompanyFields = async () => {
        setInvoiceType('company')
        setSole_proprietorship_orgnr(undefined)
        setZipcode(undefined)
        setIsSearchingOrgNr(false)
        unregister([
            'invoice.sole_proprietorship.orgnr',
            'invoice.sole_proprietorship.orgForm',
            'invoice.sole_proprietorship.name',
            'invoice.sole_proprietorship.address',
            'invoice.sole_proprietorship.postalcode',
            'invoice.sole_proprietorship.postalarea',
            'invoice.private.name',
            'invoice.private.address',
            'invoice.private.postalcode',
            'invoice.private.postalarea',
        ])
        clearErrors(['invoice.private', 'invoice.sole_proprietorship'])
    }

    const onActivatePrivateFields = () => {
        setCompany_orgnr(undefined)
        setSole_proprietorship_orgnr(undefined)
        setInvoiceType('private')
        setIsSearchingOrgNr(false)
        setValue('invoice.private.name', user.name, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
        })
        unregister([
            'invoice.sole_proprietorship.orgnr',
            'invoice.sole_proprietorship.orgForm',
            'invoice.sole_proprietorship.name',
            'invoice.sole_proprietorship.address',
            'invoice.sole_proprietorship.postalcode',
            'invoice.sole_proprietorship.postalarea',
            'invoice.company.orgnr',
            'invoice.company.orgForm',
            'invoice.company.name',
            'invoice.company.email',
            'invoice.company.reference',
            'invoice.company.resource',
        ])
        clearErrors(['invoice.company', 'invoice.sole_proprietorship'])
    }

    useEffect(() => {
        if (searchResult && Object.keys(searchResult).length === 0) {
            unregister(['invoice.company.orgForm', 'invoice.sole_proprietorship.orgForm'])
        }
    }, [!isReadyForSearch])

    useEffect(() => {
        if (isSuccess && (searchResult === undefined || Object.keys(searchResult).length === 0)) {
            if (isReadyForSearch) {
                setOrgNrError('Organisasjonsnummeret er ikke gyldig')
                setIsSearchingOrgNr(false)
            }
        }
    }, [isSuccess])

    useEffect(() => {
        if (searchResult?.organisasjonsnummer) {
            if (isSuccess) {
                setIsSearchingOrgNr(false)
            }
            if (searchResult) {
                const kode = searchResult?.organisasjonsform?.kode
                const sektorKode = searchResult?.institusjonellSektorkode?.kode
                if (kode && kode === 'ENK') {
                    onActivateSoloProprietorshipFields().then(() => {
                        setSole_proprietorship_orgnr(searchResult?.organisasjonsnummer)
                        setValue('invoice.sole_proprietorship.orgnr', searchResult?.organisasjonsnummer, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        setValue('invoice.sole_proprietorship.orgForm', kode, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        setValue('invoice.sole_proprietorship.name', searchResult?.navn, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        setValue(
                            'invoice.sole_proprietorship.address',
                            searchResult?.postadresse
                                ? searchResult?.postadresse?.adresse[0]
                                : searchResult?.forretningsadresse
                                ? searchResult?.forretningsadresse?.adresse[0]
                                : undefined,
                            {
                                shouldValidate: true,
                                shouldTouch: true,
                                shouldDirty: true,
                            }
                        )
                        setValue(
                            'invoice.sole_proprietorship.postalcode',
                            searchResult?.postadresse
                                ? searchResult?.postadresse?.postnummer
                                : searchResult?.forretningsadresse
                                ? searchResult?.forretningsadresse?.postnummer
                                : undefined,
                            {
                                shouldValidate: true,
                                shouldTouch: true,
                                shouldDirty: true,
                            }
                        )
                        setValue(
                            'invoice.sole_proprietorship.postalarea',
                            searchResult?.postadresse
                                ? searchResult?.postadresse?.poststed
                                : searchResult?.forretningsadresse
                                ? searchResult?.forretningsadresse?.poststed
                                : undefined,
                            {
                                shouldValidate: true,
                                shouldTouch: true,
                                shouldDirty: true,
                            }
                        )
                    })
                }

                if (kode && kode !== 'ENK') {
                    onActivateCompanyFields().then(() => {
                        setCompany_orgnr(searchResult?.organisasjonsnummer)
                        setValue('invoice.company.orgnr', searchResult?.organisasjonsnummer, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        setValue('invoice.company.orgForm', kode, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        setValue('invoice.company.name', searchResult?.navn, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        setValue('invoice.company.email', '', {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true,
                        })
                        clearErrors(['invoice.company.resource'])
                    })
                }

                // sektorKode 6500 is for kommuner / bydeler
                if (kode !== 'KOMM' && sektorKode !== '6500') {
                    setIsKommune(false)
                }
                // sektorKode 6500 is for kommuner / bydeler
                if (kode === 'KOMM' || sektorKode === '6500') {
                    setIsKommune(true)
                }
            }
            trigger()
        }
    }, [searchResult?.organisasjonsnummer])

    return (
        <Box sx={{mt: isNefleSignup ? 5 : 10}} id={'invoice.main.box'}>
            <Box sx={{m: 'auto', width: isNefleSignup && bigScreen ? '50%' : '100%'}}>
                <h5 className='text-uppercase'>
                    <strong>Faktura opplysninger</strong>
                </h5>
            </Box>
            {isNefleSignup && (
                <Box sx={{m: 'auto', width: bigScreen ? '50%' : '100%'}}>
                    <p className='text-left'>
                        Fyll ut organisasjonsnummer og bekreft kjøp for å komme i gang. Du vil deretter få tilgang til oppsett av din nye
                        nettside. Faktura og avtale sendes på e-post når kjøpet er bekreftet.
                    </p>
                </Box>
            )}
            {!isNefleSignup && (
                <p>
                    Du må registrere dine faktura opplysninger for din kurspåmelding. Velg hvordan du ønsker å motta faktura, enten det er
                    som enkeltpersonforetak, bedrift eller privatperson.
                </p>
            )}
            <Box sx={{display: 'flex', justifyContent: 'center', mt: 4}}>
                <ButtonGroup
                    aria-label='outlined primary button group'
                    orientation={`${isNefleSignup ? `horizontal` : bigScreen ? `horizontal` : `vertical`}`}
                    size='large'
                >
                    <Button
                        key={'sole_proprietorship_button'}
                        variant={invoiceType === 'sole_proprietorship' ? 'contained' : 'outlined'}
                        style={{backgroundColor: invoiceType === 'sole_proprietorship' ? '#3e8a82' : 'initial'}}
                        onClick={() => {
                            onActivateSoloProprietorshipFields()
                        }}
                    >
                        Enkeltpersonforetak
                    </Button>
                    <Button
                        key={'company_button'}
                        variant={invoiceType === 'company' ? 'contained' : 'outlined'}
                        style={{backgroundColor: invoiceType === 'company' ? '#3e8a82' : 'initial'}}
                        onClick={() => {
                            onActivateCompanyFields()
                        }}
                    >
                        Bedrift
                    </Button>
                    {!isNefleSignup && (
                        <Button
                            id={'private_button'}
                            key={'private_button'}
                            variant={invoiceType === 'private' ? 'contained' : 'outlined'}
                            style={{backgroundColor: invoiceType === 'private' ? '#3e8a82' : 'initial'}}
                            onClick={() => {
                                onActivatePrivateFields()
                            }}
                        >
                            Privatperson
                        </Button>
                    )}
                </ButtonGroup>
            </Box>
            {invoiceType === 'sole_proprietorship' && (
                <Box sx={{mt: 4}}>
                    <FormGroup sx={{display: 'flex', alignItems: 'center'}}>
                        <Controller
                            name={`invoice.sole_proprietorship.orgnr`}
                            control={control}
                            render={({field: {onChange}, fieldState: {}}) => (
                                <>
                                    <FormControlLabel
                                        sx={{
                                            width: {xs: '100%', sm: '100%'},
                                            mr: {xs: 0, sm: 0},
                                            ml: {xs: 0, sm: 0},
                                            mb: {xs: 0, sm: 0},
                                            borderRadius: '0.25em',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                        control={
                                            <CustomTextField
                                                id={`invoice.sole_proprietorship.orgnr`}
                                                name={`invoice.sole_proprietorship.orgnr`}
                                                type={'number'}
                                                value={sole_proprietorship_orgnr}
                                                placeholder={'Organisasjonsnummer'}
                                                sx={{
                                                    pointerEvents: 'auto',
                                                    width: {xs: '100%', sm: '50%'},
                                                    mr: {xs: 0, sm: 0},
                                                    ml: {xs: 0, sm: 0},
                                                    mt: {xs: 0, sm: 1},
                                                    mb: {xs: 0, sm: 1},
                                                }}
                                                disabled={false}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            {isLoadingSearchResult && (
                                                                <InputAdornment position='end'>
                                                                    <span style={{fontSize: '0.7em'}}>Søker</span>{' '}
                                                                    <IconButton sx={{p: 0, ml: 1}} aria-label='marker'>
                                                                        <CircularProgress color='inherit' size={'1em'} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )}
                                                        </>
                                                    ),
                                                }}
                                                {...register('invoice.sole_proprietorship.orgnr')}
                                                error={!!errors?.invoice?.sole_proprietorship?.orgnr}
                                                helperText={errors?.invoice?.sole_proprietorship?.orgnr?.message ?? ''}
                                                onChange={e => {
                                                    onChange(e)
                                                    if (e.target) {
                                                        setSole_proprietorship_orgnr(parseInt(e.target.value))
                                                        setIsSearchingOrgNr(true)
                                                        if (!/^\d{9}$/.test(String(e.target.value.length))) {
                                                            setOrgNrError(undefined)
                                                            setError('invoice.sole_proprietorship.orgnr', {
                                                                type: 'string',
                                                                message: 'Organisasjonsnummeret må være 9 siffer',
                                                            })
                                                        }
                                                        if (e.target.value.length === 9) {
                                                            clearErrors('invoice.sole_proprietorship.orgnr')
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                        value={sole_proprietorship_orgnr}
                                        label={''}
                                    />
                                    {orgNrError && (
                                        <FormGroup sx={{display: 'flex', alignItems: 'center', width: {xs: '100%', sm: '50%'}}}>
                                            <span
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    fontSize: '0.75rem',
                                                    color: '#ab3e36',
                                                    marginLeft: '1rem',
                                                }}
                                            >
                                                {orgNrError}
                                            </span>
                                        </FormGroup>
                                    )}
                                </>
                            )}
                        />

                        {!isSearchingOrgNr && isSuccess && Object.keys(searchResult).length > 0 && (
                            <>
                                <TextField
                                    id={`invoice.sole_proprietorship.orgForm`}
                                    name={`invoice.sole_proprietorship.orgForm`}
                                    type={'text'}
                                    value={searchResult?.organisasjonsform?.kode ?? undefined}
                                    placeholder={'Organisasjonsform'}
                                    disabled={false}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                        display: 'none',
                                    }}
                                    {...register('invoice.sole_proprietorship.orgForm')}
                                />
                                <TextField
                                    id={`invoice.sole_proprietorship.name`}
                                    name={`invoice.sole_proprietorship.name`}
                                    type={'text'}
                                    value={searchResult?.navn ?? undefined}
                                    placeholder={'Organisasjonsnavn'}
                                    disabled={true}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.sole_proprietorship.name')}
                                />
                                <TextField
                                    id={`invoice.sole_proprietorship.address`}
                                    name={`invoice.sole_proprietorship.address`}
                                    type={'text'}
                                    value={
                                        searchResult?.postadresse
                                            ? searchResult?.postadresse?.adresse[0]
                                            : searchResult?.forretningsadresse
                                            ? searchResult?.forretningsadresse?.adresse[0]
                                            : undefined
                                    }
                                    placeholder={'Postadresse'}
                                    disabled={true}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.sole_proprietorship.address')}
                                />
                                <TextField
                                    id={`invoice.sole_proprietorship.postalcode`}
                                    name={`invoice.sole_proprietorship.postalcode`}
                                    type={'number'}
                                    value={
                                        searchResult?.postadresse
                                            ? searchResult?.postadresse?.postnummer
                                            : searchResult?.forretningsadresse
                                            ? searchResult?.forretningsadresse?.postnummer
                                            : undefined
                                    }
                                    placeholder={'Postnummer'}
                                    disabled={true}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.sole_proprietorship.postalcode')}
                                />
                                <TextField
                                    id={`invoice.sole_proprietorship.postalarea`}
                                    name={`invoice.sole_proprietorship.postalarea`}
                                    type={'text'}
                                    value={
                                        searchResult?.postadresse
                                            ? searchResult?.postadresse?.poststed
                                            : searchResult?.forretningsadresse
                                            ? searchResult?.forretningsadresse?.poststed
                                            : undefined
                                    }
                                    placeholder={'Poststed'}
                                    disabled={true}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.sole_proprietorship.postalarea')}
                                />
                            </>
                        )}
                    </FormGroup>
                </Box>
            )}
            {invoiceType === 'company' && (
                <Box sx={{mt: 4}}>
                    <FormGroup sx={{display: 'flex', alignItems: 'center'}}>
                        <Controller
                            name={`invoice.company.orgnr`}
                            control={control}
                            render={({field: {onChange}, fieldState: {}}) => (
                                <>
                                    <FormControlLabel
                                        sx={{
                                            width: {xs: '100%', sm: '100%'},
                                            mr: {xs: 0, sm: 0},
                                            ml: {xs: 0, sm: 0},
                                            mb: {xs: 0, sm: 0},
                                            borderRadius: '0.25em',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                        control={
                                            <CustomTextField
                                                id={`invoice.company.orgnr`}
                                                name={`invoice.company.orgnr`}
                                                type={'number'}
                                                value={company_orgnr}
                                                placeholder={'Organisasjonsnummer'}
                                                sx={{
                                                    pointerEvents: 'auto',
                                                    width: {xs: '100%', sm: '50%'},
                                                    mr: {xs: 0, sm: 0},
                                                    ml: {xs: 0, sm: 0},
                                                    mt: {xs: 0, sm: 1},
                                                    mb: {xs: 0, sm: 1},
                                                }}
                                                disabled={false}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            {isLoadingSearchResult && (
                                                                <InputAdornment position='end'>
                                                                    <span style={{fontSize: '0.7em'}}>Søker</span>{' '}
                                                                    <IconButton sx={{p: 0, ml: 1}} aria-label='marker'>
                                                                        <CircularProgress color='inherit' size={'1em'} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )}
                                                        </>
                                                    ),
                                                }}
                                                {...register('invoice.company.orgnr')}
                                                error={!!errors?.invoice?.company?.orgnr}
                                                helperText={errors?.invoice?.company?.orgnr?.message ?? ''}
                                                onChange={e => {
                                                    onChange(e)
                                                    if (e.target.value) {
                                                        setCompany_orgnr(parseInt(e.target.value))
                                                        if (company_orgnr !== parseInt(e.target.value)) {
                                                            setIsSearchingOrgNr(true)
                                                        }
                                                        if (!/^\d{9}$/.test(String(e.target.value.length))) {
                                                            setOrgNrError(undefined)
                                                            setError('invoice.company.orgnr', {
                                                                type: 'string',
                                                                message: 'Organisasjonsnummeret må være 9 siffer',
                                                            })
                                                        }
                                                        if (e.target.value.length === 9) {
                                                            clearErrors('invoice.company.orgnr')
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                        value={company_orgnr}
                                        label={''}
                                    />
                                    {orgNrError && (
                                        <FormGroup sx={{display: 'flex', alignItems: 'center', width: {xs: '100%', sm: '50%'}}}>
                                            <span
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    fontSize: '0.75rem',
                                                    color: '#ab3e36',
                                                    marginLeft: '1rem',
                                                }}
                                            >
                                                {orgNrError}
                                            </span>
                                        </FormGroup>
                                    )}
                                </>
                            )}
                        />
                        {!isSearchingOrgNr && isSuccess && Object.keys(searchResult).length > 0 && (
                            <>
                                <TextField
                                    id={`invoice.company.orgForm`}
                                    name={`invoice.company.orgForm`}
                                    type={'text'}
                                    value={searchResult?.organisasjonsform?.kode ?? undefined}
                                    placeholder={'Organisasjonsnavn'}
                                    disabled={false}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                        display: 'none',
                                    }}
                                    {...register('invoice.company.orgForm')}
                                />
                                <TextField
                                    id={`invoice.company.name`}
                                    name={`invoice.company.name`}
                                    type={'text'}
                                    value={searchResult?.navn ?? ''}
                                    placeholder={'Organisasjonsnavn'}
                                    disabled={true}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.company.name')}
                                />
                                <TextField
                                    id={`invoice.company.email`}
                                    name={`invoice.company.email`}
                                    type={'email'}
                                    placeholder={'E-post til felles fakturamottak'}
                                    disabled={false}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.company.email')}
                                    error={!!errors?.invoice?.company?.email}
                                    helperText={errors?.invoice?.company?.email?.message ?? ''}
                                />
                                <TextField
                                    id={`invoice.company.reference`}
                                    name={`invoice.company.reference`}
                                    type={'text'}
                                    placeholder={'Fakturareferanse'}
                                    disabled={false}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                    }}
                                    {...register('invoice.company.reference')}
                                    error={!!errors?.invoice?.company?.reference}
                                    helperText={errors?.invoice?.company?.reference?.message ?? ''}
                                />
                                <TextField
                                    id={`invoice.company.resource`}
                                    name={`invoice.company.resource`}
                                    type={'text'}
                                    placeholder={'Ressursnummer / PO nr'}
                                    disabled={false}
                                    sx={{
                                        pointerEvents: 'auto',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mt: {xs: 0, sm: 1},
                                        mb: {xs: 0, sm: 1},
                                        display: isKommune ? 'inherit' : 'none',
                                    }}
                                    {...register('invoice.company.resource', {
                                        required: isKommune ? 'Ressursnummer / PO nr er påkrevd' : false,
                                    })}
                                    error={!!errors?.invoice?.company?.resource}
                                    helperText={errors?.invoice?.company?.resource?.message ?? ''}
                                />
                            </>
                        )}
                    </FormGroup>
                </Box>
            )}
            {invoiceType === 'private' && (
                <Box sx={{mt: 4}}>
                    <FormGroup sx={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            id={`invoice.private.name`}
                            type={'text'}
                            placeholder={'Navn'}
                            disabled={true}
                            sx={{
                                pointerEvents: 'auto',
                                width: {xs: '100%', sm: '50%'},
                                mr: {xs: 0, sm: 0},
                                ml: {xs: 0, sm: 0},
                                mt: {xs: 0, sm: 1},
                                mb: {xs: 0, sm: 1},
                            }}
                            {...register('invoice.private.name')}
                            error={!!errors?.invoice?.private?.name}
                            helperText={errors?.invoice?.private?.name?.message ?? ''}
                        />
                        <TextField
                            id={`invoice.private.address`}
                            type={'text'}
                            placeholder={'Postadresse'}
                            disabled={false}
                            sx={{
                                pointerEvents: 'auto',
                                width: {xs: '100%', sm: '50%'},
                                mr: {xs: 0, sm: 0},
                                ml: {xs: 0, sm: 0},
                                mt: {xs: 0, sm: 1},
                                mb: {xs: 0, sm: 1},
                            }}
                            {...register('invoice.private.address')}
                            error={!!errors?.invoice?.private?.address}
                            helperText={errors?.invoice?.private?.address?.message ?? ''}
                        />

                        <Controller
                            name={`invoice.private.postalcode`}
                            control={control}
                            render={({field: {onChange, value}, fieldState: {}}) => (
                                <FormControlLabel
                                    sx={{
                                        pointerEvents: 'none',
                                        width: {xs: '100%', sm: '50%'},
                                        mr: {xs: 0, sm: 0},
                                        ml: {xs: 0, sm: 0},
                                        mb: {xs: 0, sm: 0},
                                        borderRadius: '0.25em',
                                    }}
                                    control={
                                        <TextField
                                            id={`private_postal_code`}
                                            type={'number'}
                                            placeholder={'Postnummer'}
                                            disabled={false}
                                            sx={{
                                                pointerEvents: 'auto',
                                                width: {xs: '50%', sm: '50%'},
                                                mr: {xs: 0, sm: 0},
                                                ml: {xs: 0, sm: 0},
                                                mt: {xs: 0, sm: 1},
                                                mb: {xs: 0, sm: 1},
                                            }}
                                            {...register('invoice.private.postalcode')}
                                            onChange={e => {
                                                onChange(e)
                                                if (e.target.value) {
                                                    setZipcode(parseInt(e.target.value))

                                                    if (!/^\d{4}$/.test(String(e.target.value.length))) {
                                                        setError('invoice.private.postalcode', {
                                                            type: 'string',
                                                            message: 'Postnummer må være 4 siffer langt',
                                                        })
                                                    }
                                                    if (e.target.value.length === 4) {
                                                        clearErrors('invoice.private.postalcode')
                                                    }
                                                }
                                            }}
                                            error={!!errors?.invoice?.private?.postalcode}
                                            helperText={errors?.invoice?.private?.postalcode?.message ?? ''}
                                        />
                                    }
                                    value={zipcode}
                                    label={''}
                                />
                            )}
                        />
                        <TextField
                            id={`invoice.private.postalarea`}
                            type={'text'}
                            placeholder={'Poststed'}
                            disabled={false}
                            sx={{
                                pointerEvents: 'auto',
                                width: {xs: '100%', sm: '50%'},
                                mr: {xs: 0, sm: 0},
                                ml: {xs: 0, sm: 0},
                                mt: {xs: 0, sm: 1},
                                mb: {xs: 0, sm: 1},
                            }}
                            {...register('invoice.private.postalarea')}
                            error={!!errors?.invoice?.private?.postalarea}
                            helperText={errors?.invoice?.private?.postalarea?.message ?? ''}
                        />
                    </FormGroup>
                </Box>
            )}
        </Box>
    )
}
