import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import {SxProps} from '@mui/material/styles'
import {useEffect} from 'react'
import {useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import LoadingComponent from './LoadingComponent'
import {CountyType, DistrictType} from '../User/ConfirmUserDetails'

type NewOfficeType = {
    officeType: 'new'
    officeName: string
    office?: string
    county: string | undefined
    district?: DistrictType
}

type ExternalOfficeType = {
    officeType: 'external'
    externalOffice: string
    office?: string | undefined
}
type SkilOfficeType = {
    officeType: 'skil'
    office: string
    county: string | undefined
    district?: DistrictType
}

export type OfficeType = NewOfficeType | ExternalOfficeType | SkilOfficeType

type OfficeItemProps = {
    county?: string | null | undefined
    office?: OfficeType
    onChange: (office: OfficeType & {county: string}) => void
    district?: DistrictType | null
    conserveSpace?: boolean
}

const countiesWithDistricts = ['/api/counties/301', '/api/counties/4601', '/api/counties/5001', '/api/counties/1103']

function generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000)
}

export const OfficeAutocomplete = ({county, office, onChange, district, conserveSpace}: OfficeItemProps) => {
    const isCountyWithDistrict = county ? countiesWithDistricts.includes(county) : false
    const createOfficeMutation = useSkilMutation<'addOffice'>('POST', '/api/offices')
    const skilOfficesResponse = useSkilQuery<'getCountyOfficeCollection'>(
        `${county}/offices`,
        {itemsPerPage: 5000},
        {
            enabled: !!county,
        }
    )

    const externalOfficesResponse = useSkilQuery<'getCountyExternalOfficeCollection'>(
        `${county}/external_offices`,
        {itemsPerPage: 5000},
        {
            enabled: !!county,
        }
    )
    const helfoOffices = externalOfficesResponse.data?.['hydra:member'] ?? []
    const skilOffices = skilOfficesResponse.data?.['hydra:member'] ?? []

    const loading = skilOfficesResponse.isLoading || externalOfficesResponse.isLoading

    const options: {id: string | null; label: string; type: string}[] = skilOffices
        .sort(function (a, b) {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
        .map(o => {
            return {
                label: o.name,
                id: o['@id']!,
                type: o.externalOffice ? 'both' : 'skil',
            }
        })

    helfoOffices?.forEach(h => {
        const include = !skilOffices.find(s => s['@id'] === h.office)
        if (!include) return

        options.push({
            label: h.name,
            id: h['@id']!,
            type: 'helfo',
        })
    })

    options.push({
        label: 'Lag nytt legekontor',
        id: null,
        type: 'new',
    })

    const onLocalChange = (val: string | null) => {
        if (val === null) {
            onChange({
                officeType: 'new',
                officeName: '',
                county: county!,
                // @ts-expect-error
                district: office?.district ? {name: office?.district.name, id: office?.district?.['@id']} : undefined,
            })
        } else if (val.startsWith('/api/offices')) {
            const hasDistrict = skilOffices.find(o => o['@id'] === val)?.district
            onChange({
                officeType: 'skil',
                office: val,
                county: county!,
                // @ts-expect-error
                district: hasDistrict?.['@id']
                    ? {name: hasDistrict.name, id: hasDistrict['@id']}
                    : {name: district?.name, id: district?.id} ?? undefined,
            })
        } else {
            onChange({officeType: 'external', externalOffice: val, county: county!})
        }
    }

    const selectedOffice =
        office?.officeType === 'skil'
            ? office.office
            : office?.officeType === 'external'
            ? office.externalOffice
            : office?.officeType === 'new'
            ? null
            : undefined
    let officeFromOptions: {id: string | null; label: string; type: string} = {label: '', id: '', type: ''}
    if (selectedOffice) {
        officeFromOptions = options.find(option => option.id === selectedOffice) ?? officeFromOptions
    }

    if (!options) {
        return (
            <LoadingComponent size='h4' absolute={true} backdrop={true} color='#fff'>
                Laster inn...
            </LoadingComponent>
        )
    }

    return (
        <>
            <Autocomplete
                sx={{mb: conserveSpace ? 0 : 2}}
                id={'choose-office' + generateRandomSixDigitNumber()}
                fullWidth
                value={officeFromOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={!county}
                options={options}
                // @ts-expect-error
                onChange={(event: any, newValue: {label: string; id: string}) => {
                    onLocalChange(newValue.id)
                }}
                placeholder={'Velg...'}
                disableClearable={true}
                getOptionLabel={option => option.label}
                renderOption={(props, option) => {
                    const type = option.type
                    // Use option.id directly for key or a fallback that remains stable across renders
                    const key = option.id || `generated-${option.label}`
                    return (
                        <li {...props} key={key}>
                            <div style={{width: '100%'}}>
                                <span className='pull-left'>{option.label}</span>
                                <span className='pull-right'>
                                    {(type === 'both' || type === 'skil') && <em className='label label-info pull-right'> SKIL</em>}
                                    {(type === 'both' || type === 'helfo') && <em className='label label-info pull-right'> HELFO</em>}
                                    {type === 'new' && <strong>{option.label}</strong>}
                                </span>
                            </div>
                            <br />
                        </li>
                    )
                }}
                renderInput={params => (
                    <TextField
                        required={true}
                        sx={{
                            mt: conserveSpace ? 0 : 2,
                            backgroundColor: office?.officeType === 'new' ? 'rgba(211, 211, 211, 0.3)' : 'initial',
                            '& .MuiInputBase-root': {
                                marginBottom: 0, // Targets the div inside the TextField
                            },
                        }}
                        {...params}
                        label={'Velg arbeidssted'}
                        helperText={county ? '' : 'Du må velge kommune først'}
                    />
                )}
            />
            {loading && county && (
                <LoadingComponent size='h4' absolute={true} backdrop={true} color='#fff'>
                    Laster inn...
                </LoadingComponent>
            )}
            {office?.officeType === 'new' && (
                <TextField
                    id={'office-name'}
                    sx={{mt: conserveSpace ? 1 : 2}}
                    label={'Legekontor navn'}
                    value={office.officeName}
                    // @ts-expect-error
                    onChange={e => onChange({...office, officeName: e.target.value})}
                />
            )}
        </>
    )
}
