import React, {ReactNode} from 'react'
import styled from 'styled-components'

import TextIndicator from './TextIndicator'
import InformationIndicator from './InformationIndicator'
import UnknownIndicator from './UnknownIndicator'
import NumberIndicator from './NumberIndicator'
import CheckboxIndicator from './CheckboxIndicator'
import ChoiceIndicator from './ChoiceIndicator'
import MultichoiceIndicator from './MultichoiceIndicator'
import LocationIndicator from './LocationIndicator'
import PreviousAnswerIndicator from './PreviousAnswerIndicator'
import DateTimeIndicator from './DateTimeIndicator'
import CounselorEmailIndicator from './CounselorEmailIndicator'
import SeminarThemeIndicator from './SeminarThemeIndicator'
import {AppAnswerSetType, UnknownIndicatorType} from '../../Types'

const StyledContainer = styled.div`
    width: 100%;
    clear: both;
`

export type IndicatorProps<TIndicator, TValue = number | string | boolean | null> = {
    indicator: TIndicator
    onChange: (value: TValue, save?: boolean) => void
    answer: {valid: boolean; fresh: boolean; value: TValue}
    disabled: boolean
    answerSet: AppAnswerSetType
}

export default function Indicator({indicator, ...props}: IndicatorProps<UnknownIndicatorType>) {
    const CurrentIndicator = PickIndicator(indicator)

    return (
        <StyledContainer>
            {/* @ts-expect-error */}
            <CurrentIndicator indicator={indicator} {...props} />
        </StyledContainer>
    )
}

function PickIndicator(indicator: UnknownIndicatorType) {
    switch (indicator['subType']) {
        case 'InformationIndicator':
            return InformationIndicator
        case 'TextIndicator':
            return TextIndicator
        case 'NumberIndicator':
            return NumberIndicator
        case 'CheckboxIndicator':
            return CheckboxIndicator
        case 'ChoiceIndicator':
            return ChoiceIndicator
        case 'MultichoiceIndicator':
            return MultichoiceIndicator
        case 'CounselorEmailIndicator':
            return CounselorEmailIndicator
        case 'DateTimeIndicator':
            return DateTimeIndicator
        case 'LocationIndicator':
            return LocationIndicator
        case 'PreviousAnswerIndicator':
            return PreviousAnswerIndicator
        case 'SeminarThemeIndicator':
            return SeminarThemeIndicator
        default:
            return UnknownIndicator
    }
}
