import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    nbNO,
    useGridApiContext,
} from '@mui/x-data-grid-premium'
import {SxProps, Theme, useTheme} from '@mui/material/styles'
import {Box} from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

export const DataGridToolbar = ({specialActions, showQuickFilter = true, ...props}) => {
    const bigScreen = useMediaQuery('(min-width:600px)')
    const apiRef = useGridApiContext()
    const theme = useTheme()

    const defaultActionButtonStyleProps: SxProps<Theme> = {
        borderRadius: 0,
        marginLeft: 0,
        marginBottom: 1,
        padding: theme.spacing(0.5, 1.5),
        lineHeight: 'initial',
        textTransform: 'initial',
        color: `inherit`,
        backgroundColor: theme.palette.grey[300],
        minHeight: theme.spacing(3.75),
        border: `none`,
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
            border: 'none',
        },
    }

    const downloadExcel = () => apiRef.current.exportDataAsExcel()
    const downloadCsv = () => apiRef.current.exportDataAsCsv()

    return (
        // Grid Toolbar Container is a flexbox with the QuickFilter off to the left and the button groups off to the right.
        // The QuickFilter and the block of button groups stack on smaller screens, with QuickFilter going full-width.
        <GridToolbarContainer
            sx={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 1,
                gap: 1,
                padding: 0,
                alignItems: 'flex-end',
                justifyContent: 'space-between',
            }}
            {...props}
        >
            {showQuickFilter && <GridToolbarQuickFilter sx={{flexGrow: 1}} />}
            <Box display='flex' flexDirection='column' flexGrow='1'>
                <ButtonGroup
                    sx={{gap: 1, justifyContent: 'flex-end', flexFlow: bigScreen ? 'inline-flex' : 'column'}}
                    aria-label='default datagrid action buttons'
                >
                    {/* @ts-expect-error */}
                    <GridToolbarColumnsButton sx={{...defaultActionButtonStyleProps}} />
                    {/* @ts-expect-error */}
                    <GridToolbarFilterButton sx={{...defaultActionButtonStyleProps}} />
                    <Button sx={{...defaultActionButtonStyleProps}} size='small' startIcon={<FileDownloadIcon />} onClick={downloadExcel}>
                        {nbNO.components.MuiDataGrid.defaultProps.localeText.toolbarExportExcel}
                    </Button>
                    <Button sx={{...defaultActionButtonStyleProps}} size='small' startIcon={<FileDownloadIcon />} onClick={downloadCsv}>
                        {nbNO.components.MuiDataGrid.defaultProps.localeText.toolbarExportCSV}
                    </Button>
                </ButtonGroup>
                {specialActions}
            </Box>
        </GridToolbarContainer>
    )
}
