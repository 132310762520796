import React, {useRef} from 'react'
import AddItem from './AddItem'
import IndicatorItem from './IndicatorItem'
import Toolbar from './Toolbar'
import useEntity from '../../Hooks/useEntity'
import useEntities from '../../Hooks/useEntities'
import LoadingComponent from '../../Components/LoadingComponent'
import styled from 'styled-components'
import {Reorder} from 'framer-motion'
import {changeIndicatorField, changeListOrder, commitListOrder, resetListOrder} from '../../State/actions/changes'
import {jsonFetch} from '../../Components/jsonFetch'
import {useDispatch} from 'react-redux'
import {debounce} from 'lodash'

const StyledPanelDefault = styled.div`
    background: transparent;
    border-width: 0;
`

const debouncedJsonFetch = debounce(jsonFetch, 1000)

const FormGroup = ({iri, form, indicatorsWithMissingDependencies, updatingIndicatorGroups, setUpdatingIndicatorGroups}) => {
    const group = useEntity<'getIndicatorGroupItem'>(iri)
    const indicators = useEntities<'getIndicatorGroupIndicatorCollection'>(iri + '/indicators?disabled=false', false, true)
    const sortedIndicators = indicators.sort((a, b) => a.order - b.order)
    const dispatch = useDispatch()

    if (!group['@loaded'] || !indicators['@loaded']) return <LoadingComponent />

    const onRemoveIndicator = iri => {
        return indicators.remove(iri)
    }

    const onReorder = async items => {
        let indicators = items.map(i => i['@id'])
        dispatch(commitListOrder(indicators))

        try {
            await debouncedJsonFetch(iri + '/update_group_order', {
                json: {indicators},
            })
        } catch (e) {
            dispatch(resetListOrder(indicators))
        }
    }

    return (
        <>
            <StyledPanelDefault className={'panel panel-default'}>
                <AddItem toolbar={<Toolbar indicators={indicators} order={-5} group={iri} form={form['@id']} />} />
                <Reorder.Group className={'list-group'} axis={'y'} values={sortedIndicators} onReorder={onReorder}>
                    {sortedIndicators.map((item, i) => {
                        let hasMissingDependency = false
                        if (indicatorsWithMissingDependencies) {
                            hasMissingDependency = item.id in indicatorsWithMissingDependencies
                        }
                        return (
                            <IndicatorItem
                                key={item['@id']}
                                iri={item['@id']}
                                reorderValue={item}
                                onRemove={() => onRemoveIndicator(item['@id'])}
                                addItem={
                                    <AddItem
                                        toolbar={<Toolbar indicators={indicators} order={item.order - 5} group={iri} form={form['@id']} />}
                                    />
                                }
                                showAddItem={i !== 0}
                                indicators={indicators}
                                group={group}
                                hasMissingDependency={hasMissingDependency}
                                updatingIndicatorGroups={updatingIndicatorGroups}
                                setUpdatingIndicatorGroups={setUpdatingIndicatorGroups}
                            />
                        )
                    })}
                </Reorder.Group>
                <div className={'panel-footer'}>
                    <Toolbar indicators={indicators} order={100000} group={iri} form={form['@id']} />
                </div>
            </StyledPanelDefault>
        </>
    )
}
export default FormGroup
