import React, {ReactNode} from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import {RadioGroup, Radio, FormControl, FormLabel} from '@mui/material'

type Props = {
    id: string
    label?: ReactNode
    help?: ReactNode
    value?: string
    onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => any
    dontWantGeneralInfo?: boolean
    isText?: boolean
    feedback?: ReactNode
    className?: string
    required?: boolean
    props?: {[key: string]: any}
    defaultValue?: string
}

/** @deprecated*/
const RadioGroupField = ({
    id,
    label,
    help,
    value,
    onChange,
    feedback,
    className,
    defaultValue = 'generalInfo',
    dontWantGeneralInfo = true,
    isText = false,
    ...props
}: Props) => {
    return (
        <div className='form-group'>
            <FormControl>
                <FormLabel id='radio-buttons-group-label'>{label}</FormLabel>
                <RadioGroup
                    row
                    name='radio-buttons-group'
                    aria-labelledby='radio-buttons-group-label'
                    {...label}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={event => onChange(event.target.value, event)}
                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                >
                    <FormControlLabel value='generalInfo' control={<Radio />} label='Generell info' />
                    <FormControlLabel value='newsletter' control={<Radio />} label='Nyhetsbrev' />
                </RadioGroup>
            </FormControl>
            {feedback}
            {help && <p>{help}</p>}
            {value === 'newsletter' && (
                <p>{isText ? 'Meldingen' : 'E-posten'} blir kun sendt til de som har akseptert å motta nyhetsbrev</p>
            )}
            {value === 'generalInfo' && dontWantGeneralInfo && (
                <p>{isText ? 'Meldingen' : 'E-posten'} blir sendt til alle brukerne i ePortalen, med unntak av de som har reservert seg</p>
            )}
            {value === 'generalInfo' && !dontWantGeneralInfo && (
                <p>{isText ? 'Meldingen' : 'E-posten'} blir sendt til alle brukerne i ePortalen</p>
            )}
        </div>
    )
}
export default RadioGroupField
