import React, {ReactNode} from 'react'
import TextEditor from '../TextEditor/TextEditor'

type Props = {
    id: string
    label?: ReactNode
    help?: ReactNode
    value?: string
    onChange: (text: string) => any
    feedback?: ReactNode
    placeholder?: string
    required?: boolean
    rows?: number
}

/** @deprecated*/
const TextEditorField = ({id, label, help, value, required = false, placeholder, onChange, feedback = undefined, ...props}: Props) => {
    return (
        <>
            <div style={{marginBottom: '5px'}}>{label && <b>{label}</b>}</div>
            <TextEditor
                id={id}
                aria-required={required}
                placeholder={placeholder}
                value={value}
                componentClass='textarea'
                onChange={e => onChange(e)}
                {...props}
            />
        </>
    )
}
export default TextEditorField
