import React, {ReactNode} from 'react'
import Alert from '../../Components/Alert'
import {AppAnswerSetType, IndicatorGroupType} from '../Types'

type Props = {
    group: IndicatorGroupType
    children: ReactNode
    answerSet: AppAnswerSetType
}
const IndicatorGroup = React.forwardRef<HTMLDivElement, Props>(({group, children, answerSet}, ref) => {
    return (
        <div ref={ref}>
            <h3>{group['title']}</h3>
            {answerSet && answerSet['approved'] && <Alert>Dette skjemaet er allerede sendt inn og kan ikke redigeres.</Alert>}
            {children}
        </div>
    )
})
export default IndicatorGroup
