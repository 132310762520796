import * as React from 'react'
import {ThemeProvider as MuiThemeProvider, Theme} from '@mui/material/styles'
import skilTheme from '../skilTheme'
import CssBaseline from '@mui/material/CssBaseline'

interface ThemeProviderProps {
    theme?: Theme
    children: JSX.Element
}

const ThemeProvider = ({theme = skilTheme, children}: ThemeProviderProps) => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    )
}

export default ThemeProvider
