import * as React from 'react'
import {
    GridEnrichedColDef,
    GridValueGetterParams,
    GridCallbackDetails,
    GridCellEditCommitParams,
    GridRenderCellParams,
} from '@mui/x-data-grid-premium'
import {EditableCell} from '../editableCell'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

interface keyable {
    [key: string]: any
}

// Resolves a variable deep in a object by a path string
export const resolve = (path: string, obj: Object): keyable | string | number | boolean => {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
    }, obj)
}

export const sortComparator = (v1: unknown, v2: unknown) => {
    if ((v1 === undefined && v2 === undefined) || (v1 === null && v2 === null)) return 0
    if (v1 === undefined || v1 === null) return -1
    if (v2 === undefined || v2 === null) return 1

    if (typeof v1 === 'string') return v1.localeCompare(String(v2))
    if (typeof v1 === 'number') return v1 - Number(v2)
    if (typeof v1 === 'boolean') return Number(v1) - Number(v2)

    return 0
}

type Props = (
    callback: Function | undefined | null,
    editable: boolean,
    noValueMessage: string,
    align: 'center' | 'left' | 'right'
) => (params: GridRenderCellParams) => any
const renderCell: Props =
    (callback, editable = false, noValueMessage = '', align) =>
    params => {
        if (callback) return callback(params)

        let rendered = params.value ?? noValueMessage

        if (params.colDef.type === 'boolean') {
            rendered = params.value ? (
                <CheckIcon aria-label={params.formattedValue} />
            ) : (
                <CloseIcon color={'disabled'} aria-label={params.formattedValue} />
            )
        }

        if (editable) {
            rendered = EditableCell(rendered, noValueMessage, align)
        }

        return rendered
    }

type CallbackType = (params: GridValueGetterParams) => any

export const customValueGetter = (callback?: CallbackType, fieldName?: string) => (params: GridValueGetterParams) => {
    let value = callback ? callback(params) : resolve(params.field, params.row)

    if (fieldName && value?.[fieldName] !== undefined) return value[fieldName]

    return value
}

type Column = GridEnrichedColDef & {
    type?: 'string' | 'number' | 'boolean' | 'actions'
}

// Types for committing edited fields
type OnEditCommitCallback = (params: GridCellEditCommitParams, event: any, details: GridCallbackDetails) => any

type EditDef = {
    onCellEditCommit: OnEditCommitCallback
}

export type EditableGridColDef = GridEnrichedColDef & {onCellEditCommit?: OnEditCommitCallback}

export default function columnDef(col: Column, edit?: EditDef, noValueMessage = ''): EditableGridColDef {
    let valueGetter = customValueGetter(col.valueGetter)

    const align = col.type === 'number' ? 'right' : col.type === 'boolean' ? 'center' : 'left'
    return {
        headerAlign: 'left',
        align,
        type: 'string',
        editable: !!edit?.onCellEditCommit,
        onCellEditCommit: edit?.onCellEditCommit,
        flex: col.field.endsWith('id') ? 0.5 : 1,
        sortComparator,
        ...col,
        renderCell: renderCell(col.renderCell, Boolean(edit), noValueMessage, align),
        valueGetter,
    }
}
