import React from 'react'
import {Route, Routes} from 'react-router'
import ForgotPasswordPage from './Pages/ForgotPasswordPage'
import HelseIdLoginPage from './Pages/HelseIdLoginPage'
import {PageNotFound} from '../PageNotFound'
import EmailLoginPage from './Pages/EmailLoginPage'
import LoginOrRegisterPage from './Pages/Signup/LoginOrRegisterPage'
import RegisterPage from './Pages/Signup/RegisterPage'
import GroupDetailsPage from './Pages/Signup/GroupDetailsPage'
import AcknowledgePage from './Pages/Signup/AcknowledgePage'
import NefleSignupConfirm from './Pages/Signup/NefleSignupConfirm'

export const SignupApp = () => {
    return (
        <Routes>
            <Route path='' element={<HelseIdLoginPage />} />
            <Route path='/login/email' element={<EmailLoginPage />} />
            <Route path='/forgot/password/view/:email' element={<ForgotPasswordPage />} />
            <Route path='/forgot/password/view' element={<ForgotPasswordPage />} />
            <Route path='/signup/nefle' element={<LoginOrRegisterPage />} />
            <Route path='/signup/nefle/confirm/' element={<NefleSignupConfirm />} />
            <Route path='/signup/:shortTitle' element={<LoginOrRegisterPage />} />
            <Route path='/signup/:shortTitle/register' element={<RegisterPage />} />
            <Route path='/signup/:shortTitle/:redirect/register' element={<RegisterPage />} />
            <Route path='/signup/:shortTitle/register/group' element={<GroupDetailsPage />} />
            <Route path='/signup/:shortTitle/details' element={<GroupDetailsPage />} />
            <Route path='/signup/:shortTitle/acknowledge' element={<AcknowledgePage />} />
            <Route path='/signup/:shortTitle/acknowledge/acknowledge' element={<AcknowledgePage />} />
            <Route path='/signup/:shortTitle/acknowledge/deny' element={<AcknowledgePage />} />
            <Route path='/signup/:shortTitle/:redirect' element={<LoginOrRegisterPage />} />
            <Route path='/signup/:shortTitle/acknowledge/:redirect' element={<AcknowledgePage />} />
            <Route path={'*'} element={<PageNotFound />} />
        </Routes>
    )
}
