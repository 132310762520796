import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import Panel from '../Components/Panel'
import Button from '../Components/Button/Button'
import SelectField from '../Components/Fields/SelectField'
import Modal from '../Components/Modal'
import {Page} from '../Components/Page'
import SimpleSelectField from '../Components/Fields/SimpleSelectField'
import TextField from '../Components/Fields/TextField'
import RichTextField from '../Components/Fields/RichTextField'
import {toast} from 'react-toastify'
import {SkilQueryResponseType, useQueryInvalidate, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {useQuery} from 'react-query'
import {components} from '../Generated/eportal'
import useFieldChange from '../PraksisNett/utils/useFieldChange'
import LoadingComponent from '../Components/LoadingComponent'
import {handleErrorWithToast} from '../Utilities/errorHandlers'

const ifTaskStates = [
    {value: 0, label: 'Ikke påbegynt'},
    {value: 1, label: 'Påbegynt'},
    {value: 2, label: 'Fullført'},
    {value: 3, label: 'Ikke fullført'},
]

const GLOBAL_TRIGGER_OPTIONS = [
    {value: 'App\\Messages\\Events\\ePortal\\UserRegistered', label: 'Bruker registrert i SKIL ePortal'},
    {value: 'App\\Messages\\Events\\ePortal\\UserOrderedNefle', label: 'Bruker bestilt Nefle'},
    {value: 'App\\Messages\\Events\\ePortal\\UserAddedToNefle', label: 'Bruker lagt til Nefle'},
    {value: 'App\\Messages\\Events\\ePortal\\CounselorAddedToGroup', label: 'Veileder tildelt gruppe'},
    {value: 'App\\Messages\\Events\\ePortal\\UserAcknowledgeReminder', label: 'Første påminnelse kursinvitasjon (14 dager igjen)'},
    {value: 'App\\Messages\\Events\\ePortal\\UserAcknowledgeSecondReminder', label: 'Andre påminnelse kursinvitasjon (4 dager igjen)'},
    {
        value: 'App\\Messages\\Events\\ePortal\\UserAcknowledgeSpecialReminder',
        label: 'Spesiell påminnelse kursinvitasjon (14 dager igjen) (engangsbruk)',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\CourseAdminApproveQualitySeminarReminder',
        label: 'Påminnelse til kursadmin om å godkjenne deltakerlisten for kvalitetsseminar',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekUntilCourseUnapprovedReminder',
        label: 'Påminnelse 1 uke før kursgodkjenning går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\ThreeMonthsUntilCourseUnapprovedReminder',
        label: 'Påminnelse 3 måneder før kursgodkjenning går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\FifteenMonthsAfterCourseSignupReminder',
        label: 'Påminnelse 3 måneder før frist for å fullføre kurset går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekBeforeCourseSignupExpiresReminder',
        label: 'Påminnelse 1 uke før frist for å fullføre kurset går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekBeforeFinalCourseSignupDeadlineExpiresReminder',
        label: 'Påminnelse 1 uke før siste frist for å fullføre kurset går ut (utvidelse innvilget)',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\TwoDaysBeforeConferenceSignupExpiresReminder',
        label: 'Påminnelse 2 dager før frist for å fullføre konferansen går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekBeforeSeminarSignupExpiresReminder',
        label: 'Påminnelse 1 uke før frist for å fullføre seminaret går ut',
    },
]

const getPreview = async (job, content, title, trigger) => {
    if (!job || !content || !title || !trigger) {
        return {}
    }

    const response = await fetch(job['@id'] + '/preview', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Accept: 'application/json'},
        body: JSON.stringify({content, title, trigger}),
    })

    return await response.json()
}

function getTriggerOptions(availableEvents, job) {
    if (!job) return []

    if (!job.course) return GLOBAL_TRIGGER_OPTIONS

    return availableEvents.map(({id, description}) => ({value: id, label: description}))
}

type TaskType = SkilQueryResponseType<'getTaskItem'>
type TriggerConfiguration = {
    id: string
    description: string
    help: string
    availableProperties: {[key: string]: string | boolean}
    sourceType: string
}

type JobWriteType = Partial<components['schemas']['Job-Job.write']>
type JobType = SkilQueryResponseType<'getJobItem'>

function createBreadcrumbs(jobTriggerEvent, job?: JobType) {
    const courseBreadcrumbs = [
        {title: 'Kurs', href: '/dashboard/'},
        {title: job?.course?.shortTitle ?? '', href: '/dashboard/course/' + job?.course?.shortTitle ?? ''},
        {title: 'Kurs innstillinger', href: `/dashboard/course/${job?.course?.shortTitle}/settings`},
        {title: jobTriggerEvent?.[0], to: ''},
    ]
    const globalBreadcrumbs = [
        {title: 'SKIL', to: '/dashboard/SKIL/'},
        {title: 'Automatiske jobber', to: `/dashboard/SKIL/jobs`},
        {title: jobTriggerEvent?.[0], to: ''},
    ]

    return job?.course ? courseBreadcrumbs : globalBreadcrumbs
}

const Job = () => {
    const navigate = useNavigate()
    const invalidate = useQueryInvalidate()
    const {shortTitle, jobId} = useParams()
    const [rendered, setRendered] = useState<{content: string; title: string; error?: string}>({content: '', title: '', error: ''})
    const [preview, setPreview] = useState(false)
    const [fields, , setUpdateFields] = useFieldChange<JobWriteType>({})

    const {data: job} = useSkilQuery<'getJobItem'>(
        `/api/jobs/${jobId}`,
        {},
        {onSuccess: job => onPreview(job.content, job.title, job.trigger)}
    )
    const {data: course} = useSkilQuery<'getCourseItem'>(job?.course?.['@id'] ?? '', {}, {enabled: !!job?.course})
    const {data: tasks} = useSkilQuery<'getCourseTaskCollection'>(job?.course?.['@id'] + `/tasks`, {}, {enabled: !!job?.course})
    const {data: selectedTask} = useSkilQuery<'getTaskItem'>(job?.task?.['@id'] ?? '', {}, {enabled: !!job?.task})
    const {data: eventDescription, refetch: refetchEventDescription} = useQuery<TriggerConfiguration>(
        `/api/jobs/${jobId}/trigger_configuration?trigger=${fields.trigger ?? job?.trigger}`,
        {enabled: !!job}
    )

    const saveJobMutation = useSkilMutation<'patchJobItem'>('patch', `/api/jobs/${jobId}`)
    const deleteJobMutation = useSkilMutation<'deleteJobItem'>('delete', `/api/jobs/${jobId}`)

    const availableEvents = course?.availableEvents ?? []
    const triggerOptions = getTriggerOptions(availableEvents, job)
    const isCourseEvent = (eventDescription?.sourceType ?? 'Task') === 'Course'
    const isMeetingEvent = (eventDescription?.sourceType ?? 'Task') === 'Meeting'
    const jobTriggerEvent = triggerOptions.filter(e => e.value === job?.trigger).map(e => e.label)

    const onDelete = async () => {
        await deleteJobMutation.mutateAsync({})
        toast('Automatisk epost fjernet')

        if (job?.course) navigate('/dashboard/SKIL/jobs')
        else navigate(`/dashboard/course/${shortTitle}/settings`)
    }

    const onChangeTrigger = async trigger => {
        if (!job) {
            return
        }
        setUpdateFields(fields => ({...fields, trigger}))
        await refetchEventDescription()
        await onPreview(fields.content ?? job?.content, fields.title ?? job?.title, trigger)
    }

    const onShowPreview = async () => {
        await onPreview(fields.content ?? job?.content, fields.title ?? job?.title, fields.trigger ?? job?.trigger)
        setPreview(true)
    }

    const onChangeContent = async content => {
        if (!job) {
            return
        }

        setUpdateFields(fields => ({...fields, content}))
        await onPreview(content, fields.title ?? job?.title, fields.trigger ?? job?.trigger)
    }

    const onPreview = async (content, title, trigger) => {
        try {
            const results = await getPreview(job, content, title, trigger)
            setRendered(results)
        } catch (e) {
            handleErrorWithToast(e)
        }
    }
    const breadcrumbs = createBreadcrumbs(jobTriggerEvent, job)

    // Filter available tasks depending on Trigger
    const availableTasks = (tasks?.['hydra:member'] ?? []).filter(t => (isMeetingEvent ? t['@type'] === 'MeetingTask' : true))

    const onSave = async () => {
        if (!job) {
            return
        }

        await saveJobMutation.mutateAsync(fields)
        setUpdateFields({})

        await invalidate([
            `/api/jobs/${jobId}`,
            course ? course['@id']! : null,
            course ? course['@id']! + `/tasks` : null,
            job?.task ? job.task['@id']! : null,
            job ? `/api/jobs/${jobId}/trigger_configuration?trigger=${job?.trigger}` : null,
        ])
    }

    if (!job || (job.course && !tasks)) {
        return <LoadingComponent />
    }

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <Panel title={'Automatisk Oppgave:'}>
                <Panel.Body>
                    <div className='row'>
                        <div className='col-md-5'>
                            <SimpleSelectField
                                id={'job_event'}
                                onChange={trigger => onChangeTrigger(trigger)}
                                value={fields.trigger ?? job.trigger}
                                options={triggerOptions}
                                label={'Hendelsen som skal utløse jobben:'}
                            />

                            {job?.course &&
                                (isCourseEvent ? (
                                    <TextField
                                        value={fields.title ?? course?.title ?? ''}
                                        label='Kurs:'
                                        disabled
                                        readonly
                                        id={'title'}
                                        onChange={() => {}}
                                    />
                                ) : (
                                    <>
                                        <SelectField<TaskType>
                                            id={'job_task'}
                                            onChange={e => setUpdateFields(fields => ({...fields, task: e['@id']!}))}
                                            value={fields.task ?? job.task?.['@id'] ?? null}
                                            labelFn={e => e.title}
                                            // @ts-expect-error
                                            entities={availableTasks}
                                            label={'Oppgave: '}
                                        />
                                        {isMeetingEvent && selectedTask?.['@type'] !== 'MeetingTask' && 'Du må velge en møte-oppgave!'}
                                    </>
                                ))}

                            {job.course && !isCourseEvent && (
                                <>
                                    <hr />
                                    <SelectField<TaskType>
                                        id={'job_if_task'}
                                        onChange={e => setUpdateFields(fields => ({...fields, ifTask: e?.['@id'] ?? null}))}
                                        value={fields.ifTask === undefined ? job.ifTask?.['@id'] : fields.ifTask}
                                        labelFn={e => e.title}
                                        // @ts-expect-error
                                        entities={tasks?.['hydra:member'] ?? []}
                                        required={false}
                                        label={'Hvis oppgave: '}
                                    />
                                    <SimpleSelectField
                                        id={'ifState'}
                                        disabled={!(fields.ifTask === undefined ? job.ifTask : fields.ifTask)}
                                        onChange={ifState => setUpdateFields(fields => ({...fields, ifState}))}
                                        value={fields.ifState ?? job.ifState}
                                        options={ifTaskStates}
                                        label={'Er:'}
                                    />
                                    <span className='help-block'>Gjennomfør kun jobben hvis en oppgave har spesifik status</span>
                                </>
                            )}
                        </div>

                        <div className='col-md-7'>
                            <TextField
                                value={fields.title ?? job.title}
                                label={'Emne'}
                                onChange={title => setUpdateFields(fields => ({...fields, title}))}
                                id={'title'}
                            />
                            <RichTextField
                                rows={20}
                                value={job.content}
                                onChange={content => onChangeContent(content)}
                                label={'Innhold'}
                                id={'content'}
                            />

                            {rendered.error && (
                                <span className='help-block text-red'>
                                    Feil: <strong>{rendered.error}</strong>
                                </span>
                            )}
                        </div>
                    </div>
                </Panel.Body>
                <Panel.Footer>
                    <Button href={`/dashboard/course/${shortTitle}/settings`} variant={'link'}>
                        Tilbake
                    </Button>
                    <Button
                        disabled={!!rendered.error || Object.values(fields).length === 0}
                        className={'pull-right'}
                        onClick={onSave}
                        variant={'primary'}
                    >
                        Lagre
                    </Button>
                    <Button
                        className={'pull-right '}
                        disabled={Object.values(fields).length === 0}
                        onClick={() => setUpdateFields({})}
                        variant={'default'}
                    >
                        Tilbakestill
                    </Button>
                    <Button className={'pull-right'} onClick={onShowPreview} variant={'default'}>
                        Forhåndsvis
                    </Button>
                    {course && (
                        <Button
                            href={`/dashboard/courses/${course.shortTitle}/communication/history/jobs/${job.id}`}
                            className={'pull-right'}
                            variant={'default'}
                        >
                            Sente eposter
                        </Button>
                    )}
                    <Button confirm={'Er du sikker?'} onClick={onDelete} className={'pull-right'} variant={'warning'}>
                        Slett
                    </Button>
                </Panel.Footer>
                <Panel.Body>
                    {eventDescription && (
                        <>
                            {eventDescription?.help && <span className={'help-block'}>{eventDescription.help}</span>}
                            <h4>Tilgjengelige variabler for '{eventDescription.description}':</h4>
                            <ul>
                                {Object.entries(eventDescription?.availableProperties ?? {}).map(([prop, example]) => (
                                    <li key={prop}>
                                        <strong>{prop}: </strong>
                                        {typeof example === 'boolean' ? 'false' : example}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </Panel.Body>
            </Panel>
            {preview && (
                <Modal size={'lg'} onClose={() => setPreview(false)} title={'Emne: ' + rendered.title} cancelText={'Skjul'}>
                    <div dangerouslySetInnerHTML={{__html: rendered.content}} />
                </Modal>
            )}
        </Page>
    )
}
export default Job
