import React from 'react'
import styled from 'styled-components'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import {FormControl} from '@mui/material'

const StyledItem = styled.div`
    background: #eee;
    padding: 5px;
    margin: 5px;
    width: fit-content;
    display: inline-block;
`

type Props = {
    id?: string
    label?: string
    value?: string[]
    onChange: Function
    entities: Array<{name: string; '@id': string}>
    required?: boolean
}

/** @deprecated*/
const MultiSelectField = ({id, label, value, onChange, entities = [], required = true}: Props) => {
    const handleChange = event => {
        onChange(event.target.value)
    }

    return (
        <FormControl sx={{mb: 2}} fullWidth>
            <InputLabel shrink variant='outlined' htmlFor={id}>
                {label}
            </InputLabel>
            <Select
                fullWidth
                multiple
                id={id}
                value={value}
                onChange={handleChange}
                required={required}
                label={label}
                renderValue={selected => (
                    <div style={{whiteSpace: 'break-spaces'}}>
                        {selected.map(val => (
                            <StyledItem key={val}>{entities.filter(o => o['@id'] === val).map(o => o.name)}</StyledItem>
                        ))}
                    </div>
                )}
            >
                {entities.map(e => {
                    return (
                        <MenuItem key={e['@id']} value={e['@id']}>
                            <Checkbox checked={value?.includes(e['@id'])} />
                            <ListItemText primary={e.name} />
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
export default MultiSelectField
