import React, {useState} from 'react'
import Button from '../Components/Button/Button'
import Panel from '../Components/Panel'
import {Page} from '../Components/Page'
import TextareaField from '../Components/Fields/TextareaField'
import TextField from '../Components/Fields/TextField'
import {useParams} from 'react-router'

function formatMessage(negative, positive, feedback) {
    return `
Ris:
${negative}

Ros:
${positive}

Tilbakemelding:
${feedback}
`
}

const breadcrumbs = id => [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + id}, {title: 'Tilbakemelding'}]

export default function Feedback() {
    const {partnershipId} = useParams()
    const [feedback, setFeedback] = useState('')
    const [positive, setPositive] = useState('')
    const [negative, setNegative] = useState('')

    const onSubmit = form => {
        return fetch('/api/users/feedback', {
            method: 'POST',
            body: JSON.stringify({...form, uri: window.location.href}),
            headers: {'Content-type': 'application/json'},
        }).then(() => 'Meldingen er sendt!')
    }

    const onLocalSave = () => {
        return onSubmit({message: formatMessage(negative, positive, feedback)})
    }

    return (
        <Page breadcrumbs={breadcrumbs(partnershipId)} variant={'full'}>
            <div className={'col-sm-6'}>
                <Panel variant={'flat'}>
                    <Panel.Body>
                        <TextareaField value={negative} onChange={setNegative} id={'ris'} label={'Ris'} />
                        <TextareaField value={positive} onChange={setPositive} id={'ros'} label={'Ros'} />
                        <TextareaField value={feedback} onChange={setFeedback} id={'feedback'} label={'Tilbakemelding'} />
                    </Panel.Body>
                    <Panel.Footer className={'text-right'}>
                        <Button variant='primary' onClick={onLocalSave}>
                            Send tilbakemelding
                        </Button>
                    </Panel.Footer>
                </Panel>
            </div>
            <div className={'col-sm-6'}>
                <Panel variant={'flat'}>
                    <Panel.Body>
                        <TextField
                            id={'phonenumber'}
                            value={'94207070'}
                            readonly
                            onChange={() => {}}
                            type={'text'}
                            label={'Support telefon'}
                        />
                        <TextField
                            id={'email'}
                            value={'kontakt@skilnet.no'}
                            readonly
                            onChange={() => {}}
                            type={'text'}
                            label={'Support e-post'}
                        />
                        <div className={'form-group'} hidden>
                            <label>Evalueringsskjema</label>
                            <br />
                            <a href='https://eportal.skilnet.no/public/form/145'>Gå til evalueringsskjemaet</a>
                        </div>
                    </Panel.Body>
                </Panel>
            </div>
        </Page>
    )
}
