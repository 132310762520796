import * as React from 'react'
import {components} from '../../../Generated/eportal'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {Box} from '../../../Components/Panel'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../Components/Button/MuiButton'
import useFieldChange from '../../utils/useFieldChange'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {DoctorType} from './Doctor'
import {toast} from 'react-toastify'

interface DoctorProfilePanelProps {
    doctor: DoctorType
    refetch: Function
}
type ProfileUpdateType = components['schemas']['Praksisnett.Doctor.UpdateProfileDto']
type DoctorUpdateType = components['schemas']['Praksisnett.Doctor.UpdateDoctorDto']

export const DoctorProfilePanel = ({doctor, refetch}: DoctorProfilePanelProps) => {
    const updateProfile = useSkilMutation<'updatePraksisnettDoctorProfile'>(
        'post',
        `/api/praksisnett/doctors/${doctor.id}/update_profile`,
        [`/api/praksisnett/doctors/${doctor.id}`, `/api/praksisnett/doctors`]
    )
    const updateDoctor = useSkilMutation<`updatePraksisnettDoctor`>('post', `/api/praksisnett/doctors/${doctor?.id}/update_doctor`, [
        `/api/praksisnett/doctors/${doctor.id}`,
        `/api/praksisnett/doctors`,
    ])

    const [profileFieldsToUpdate, onProfileFieldChange] = useFieldChange<ProfileUpdateType>(doctor)
    const [doctorFieldsToUpdate, onDoctorFieldChange] = useFieldChange<DoctorUpdateType>(doctor)

    const saveDoctorAndProfileChanges = async () => {
        await Promise.all([
            updateProfile.mutateAsync({...doctor, ...profileFieldsToUpdate}),
            updateDoctor.mutateAsync({...doctor, ...doctorFieldsToUpdate}),
        ])
        // refetch: no need to catch, handled by useSkilQuery 😄
        refetch()
            .then(() => {})
            .catch(() => {})
    }

    return (
        <Box title='Personaldetaljer'>
            <Grid container spacing={2} mb={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <TextField id='name' fullWidth label='Navn' defaultValue={doctor?.name} onChange={onProfileFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='email' fullWidth label='E-post' defaultValue={doctor?.email} onChange={onProfileFieldChange} />
                </Grid>
                <Grid item xs={6}>
                    <TextField id='mobile' fullWidth label='Mobilnummer' defaultValue={doctor?.mobile} onChange={onProfileFieldChange} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id='hpr'
                        fullWidth
                        label='HPR Nummer'
                        defaultValue={doctor?.hpr ?? doctor?.helfoDoctor?.hpr ?? null}
                        type={'number'}
                        onChange={onProfileFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id='region-label'>Region</InputLabel>
                        <Select
                            labelId='region-label'
                            label='Region'
                            name='region'
                            value={doctorFieldsToUpdate.region ?? doctor?.region}
                            onChange={e => onDoctorFieldChange(e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)}>
                            <MenuItem value='Midt'>Midt</MenuItem>
                            <MenuItem value='Nord'>Nord</MenuItem>
                            <MenuItem value='Vest'>Vest</MenuItem>
                            <MenuItem value='Øst'>Øst</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id='accountNr'
                        fullWidth
                        label='Kontonummer'
                        defaultValue={doctor?.accountNr}
                        onChange={onDoctorFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        label='Sluttet/i permisjon'
                        control={
                            <Checkbox
                                id='external'
                                checked={doctorFieldsToUpdate.external ?? doctor?.external}
                                onChange={onDoctorFieldChange}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        label='Spesialist i allmennmedisin'
                        control={
                            <Checkbox
                                id='signedDigitally'
                                checked={doctorFieldsToUpdate.signedDigitally ?? doctor?.signedDigitally}
                                onChange={onDoctorFieldChange}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='comment'
                        fullWidth
                        multiline
                        label='Merknad'
                        defaultValue={doctor?.comment}
                        onChange={onDoctorFieldChange}
                    />
                </Grid>
            </Grid>
            {/* Todo: convert these divs into button footer component (we are reusing them, bigtime :D) */}
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={saveDoctorAndProfileChanges}>Lagre</Button>
            </div>
        </Box>
    )
}
