import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import {useQuery} from 'react-query'
import LoadingComponent from '../../../Components/LoadingComponent'
import Slider from 'rc-slider'
import range from 'lodash/range'
import DatePicker from 'react-datepicker'
import {useLayoutEffect} from 'react'
import {IndicatorProps} from './index'
import {PreviousAnswerIndicatorType} from '../../Types'
import {useSkilQuery} from '../../../Utilities/QueryClient'

const PreviousAnswerIndicator = ({indicator}: IndicatorProps<PreviousAnswerIndicatorType, null>) => {
    const previousAnswerIndicator = indicator['previousAnswerIndicator']

    const {isLoading, data: previousAnswer} = useQuery(['abstractindicator', previousAnswerIndicator?.['id'], 'previous_answer'], () =>
        fetch('/api/abstractindicator/' + previousAnswerIndicator?.['id'] + '/previous_answer').then(res => res.json())
    )

    useLayoutEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [previousAnswer])

    if (isLoading)
        return (
            <LoadingComponent size={'h4'} absolute={false}>
                Laster inn...
            </LoadingComponent>
        )

    return (
        <div>
            <div>
                <div>
                    <FormGroup size={'lg'} hasError={false} hasSuccess={true}>
                        {previousAnswer && previousAnswer['hydra:member'] === undefined && (
                            <>
                                <label style={{marginBottom: '0.5em'}}>{'Ditt tidligere innsendte svar:'}</label>
                                <div>Svar ikke registrert</div>
                            </>
                        )}
                        {previousAnswer && previousAnswer['hydra:member'] && !previousAnswer['hydra:member'].length && (
                            <>
                                <label style={{marginBottom: '0.5em'}}>{'Ditt tidligere innsendte svar:'}</label>
                                <div>Svar ikke registrert</div>
                            </>
                        )}
                        {previousAnswer &&
                            previousAnswer['hydra:member'] &&
                            previousAnswer['hydra:member'].map(a => (
                                <div key={a.indicator.id}>
                                    <label style={{marginBottom: '0.5em'}}>{'Ditt tidligere svar innsendt ' + a.reportedAt + ':'}</label>
                                    <div>{a.indicator.title}</div>
                                    {indicator['description'] && previousAnswer && previousAnswer['hydra:member'] && (
                                        <span className='help-block'>
                                            <small>{indicator['description']}</small>
                                        </span>
                                    )}
                                    {indicator['optional'] && previousAnswer && previousAnswer['hydra:member'] && (
                                        <span className='help-block'>
                                            <small>{indicator['optional']}</small>
                                        </span>
                                    )}
                                    <PreviousAnswer previousAnswer={a} />
                                </div>
                            ))}
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}

const PreviousAnswer = ({previousAnswer}) => {
    const value = previousAnswer.value
    const key = previousAnswer.key
    const type = previousAnswer.type
    const minimum = previousAnswer.minimum
    const maximum = previousAnswer.maximum
    const isSlider = !!previousAnswer.slider
    const marks = range(minimum - 1, maximum + 1).reduce((carry, i) => {
        carry[i] = i
        return carry
    }, {})
    let indicator = previousAnswer['indicator']
    let alternatives = indicator['alternatives'] ? Object.entries(indicator['alternatives']) : null

    const selected = new Date(previousAnswer.time) ?? null
    switch (type) {
        case 'TextIndicator':
            return <textarea className='form-control' value={value} disabled={true} />
        case 'NumberIndicator':
            return (
                <>
                    {' '}
                    {!isSlider && (
                        <input
                            value={value ? value : ''}
                            style={{width: '10em'}}
                            max={maximum}
                            min={minimum}
                            className='form-control'
                            type='number'
                            disabled={true}
                        />
                    )}
                    {isSlider && (
                        <Slider
                            min={minimum}
                            max={maximum}
                            style={{width: '98%', marginLeft: '0.5em'}}
                            value={value ? value : null}
                            marks={marks}
                            disabled={true}
                        />
                    )}
                    <br />
                    {(maximum || minimum) && (
                        <span className='help-block'>
                            {`Minimum ${minimum} : `}
                            {'Maksimum ' + maximum}
                        </span>
                    )}
                </>
            )
        case 'CheckboxIndicator':
            return (
                <>
                    <div>
                        <label>
                            <input defaultChecked={value === 'yes'} type='radio' disabled={true} /> Ja
                        </label>
                    </div>
                    <div>
                        <label>
                            <input defaultChecked={value === 'no'} type='radio' disabled={true} /> Nei
                        </label>
                    </div>
                </>
            )
        case 'ChoiceIndicator':
            return (
                <>
                    {' '}
                    {Object.values(alternatives ?? []).map(([index, option], i) => {
                        const alternative = option as {order: number; text: string; key: string}
                        // we check if the key (not the value) is equal to the key of the alternative, since the value can change
                        return (
                            <div key={alternative.key + i}>
                                <label>
                                    <input type='radio' value={alternative.key} defaultChecked={key === alternative.key} disabled={true} />
                                    &nbsp;{alternative.text}
                                </label>
                            </div>
                        )
                    })}
                </>
            )
        case 'MultichoiceIndicator':
            return (
                <>
                    {' '}
                    {Object.values(alternatives ?? []).map(([index, option], i) => {
                        const alternative = option as {order: number; text: string; key: string}
                        return (
                            <div key={alternative.key + i}>
                                <label>
                                    <input
                                        type='checkbox'
                                        value={alternative.key}
                                        defaultChecked={value.includes(alternative.key)}
                                        disabled={true}
                                    />
                                    &nbsp;{alternative.text}
                                </label>
                            </div>
                        )
                    })}
                </>
            )
        case 'DateTimeIndicator':
            return (
                <DatePicker
                    className={`form-control`}
                    selected={selected}
                    disabled={true}
                    showTimeSelect={previousAnswer.indicator.time}
                    showTimeSelectOnly={!previousAnswer.indicator.date}
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    timeCaption='Tid'
                    dateFormat='dd.MM.yyyy HH:mm'
                    isClearable
                    inline
                />
            )
        default:
            return <div>Tidligere svar ikke funnet...</div>
    }
}

export default PreviousAnswerIndicator
