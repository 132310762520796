import * as React from 'react'
import {styled, Theme} from '@mui/material/styles'
import {Box as MuiBox} from '@mui/material/'
import {Typography} from '@mui/material/'
import {BoxProps as MuiBoxProps} from '@mui/material/Box'
import styledComponents from 'styled-components'
import {ReactNode} from 'react'

/* ******** NOTES ON PANEL USE ⬜️💕 ********

If your panel has a title, that's awesome! Then you should use the __primary__ variant.
If your panel doesn't need a title, that's cool too -- then you should use the __flat__ variant.
In ✨ the future ✨ we will only use our MUI-based Box component, and that one handles everything for you. 😄

@Maja, I messed up everything and added a 'Panel.TitleWithATitleWithActionsctions' component, that takes a title prop, and a children prop for random header actions :O

*/

// ORIGINAL PANEL

const Title = ({children}: {children: ReactNode}) => (
    <div className='panel-heading'>
        <h3 className='panel-title'>{children}</h3>
    </div>
)
type Props = {
    title?: React.ReactNode
    children: React.ReactNode
    variant?: 'primary' | 'flat' | 'default'
    className?: string
    [key: string]: any
}
const Panel = ({title = undefined, children, variant = 'primary', ...props}: Props) => {
    return (
        <>
            <div {...props} className={`panel panel-${variant} ${props.className ?? ''}`}>
                {title && <Title>{title}</Title>}
                {children}
            </div>
        </>
    )
}

const StyledTitle = styledComponents.div`
    h3 {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
`

Panel.TitleWithActions = ({title, children}) => (
    <StyledTitle className='panel-heading'>
        <h3 className='panel-title'>
            {title}
            <div className={'actions'}>{children}</div>
        </h3>
    </StyledTitle>
)

Panel.Body = ({children, className = '', style = {}}) => (
    <div style={style} className={className + ' panel-body'}>
        {children}
    </div>
)
Panel.Footer = ({children, className = '', style = {}}: {children: ReactNode; className?: string; style?: React.CSSProperties}) => (
    <div style={style} className={className + ' panel-footer'}>
        {children}
    </div>
)
Panel.Title = Title

export default Panel

/*  *** NEW MUI BOX PANEL GUY! ⬜️✨ ***
    Box is displayed with "primary" style whenever it has a title; "flat" style otherwise.
    Padding on the main content can be turned off for special cases.
    TODO: add footer support.
 */

type BoxProps = MuiBoxProps & {title?: string | null; padContents?: boolean}

type StyleProps = {
    theme: Theme
}

const BoxTitle = styled(Typography)(({theme}: StyleProps) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 2),
}))

export const Box = styled(({title = undefined, children, ...props}: BoxProps) => {
    return (
        <MuiBox {...props}>
            {title && <BoxTitle variant='h3'>{title}</BoxTitle>}
            <MuiBox>{children}</MuiBox>
        </MuiBox>
    )
})(({theme, title, padContents = true}: StyleProps & BoxProps) => ({
    backgroundColor: theme.palette.background.paper,
    border: title ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.background.paper}`,
    '& > .MuiBox-root': {
        padding: padContents ? theme.spacing(2) : 0,
    },
}))
