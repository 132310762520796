import React, {useEffect} from 'react'
import {useNavigate} from 'react-router'
import useUser from '../../Utilities/useUser'
import {Button} from '../../Components/Button/MuiButton'
import PurePage from '../../Components/PurePage'
import {Grid, Skeleton, useMediaQuery} from '@mui/material'
import GdprList from '../Components/GdprList'
import {useSearchParams} from 'react-router-dom'
import HelseIdAuthCard from '../Components/HelseIdAuthCard'
import {SkeletonTitleContainer} from './Signup/GroupDetailsPage'
import {useQueryInvalidate} from '../../Utilities/QueryClient'
import LoadingComponent from '../../Components/LoadingComponent'

export default function HelseIdLoginPage() {
    const navigate = useNavigate()
    const user = useUser()
    const invalidate = useQueryInvalidate()
    const bigScreen = useMediaQuery('(min-width:600px)')
    const [searchParams] = useSearchParams()
    const redirect = searchParams.get('redirect')
    const [hasRefetchedUser, setHasRefetchedUser] = React.useState<boolean>(false)
    const [isRefetchingUser, setIsRefetchingUser] = React.useState<boolean>(false)
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false)

    useEffect(() => {
        // allow time to actually be logged out backend
        const timer = setTimeout(() => {
            invalidate(['/api/login/connect/user']).then(() => {
                user.refresh()
                setIsRefetchingUser(true)
            })
        }, 1000)

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (
            !hasRefetchedUser &&
            isRefetchingUser &&
            !user.isLoading &&
            !user.isRefetching &&
            (user.isSuccess || user.isError || user.isLoadingError)
        ) {
            // when we have finished refetching the user, we can set the hasRefreshedUser to true, either if we have an error or success
            setIsRefetchingUser(false)
            setHasRefetchedUser(true)
        }
    }, [user])

    useEffect(() => {
        if (user.authenticated && hasRefetchedUser) {
            if (redirect) {
                navigate(redirect)
                return
            }

            if (user.voters.isSkilUser() && !redirect) {
                setShowSpinner(true)
                window.location.replace(`/dashboard/SKIL`)
            }

            if (!user.voters.isSkilUser() && !redirect) {
                setShowSpinner(true)
                window.location.replace(`/dashboard`)
            }
        }
    }, [user, redirect, hasRefetchedUser])

    if (showSpinner) {
        return <LoadingComponent />
    }

    return (
        <>
            {hasRefetchedUser && (
                <PurePage variant={'xs'} breadcrumbs={[{title: 'Logg inn'}]}>
                    <Grid container minHeight={'80vh'} columns={1} direction={'column'} justifyContent={'space-between'}>
                        <Grid item>
                            <HelseIdAuthCard redirectUrl={redirect} />

                            <Grid container mt={2} justifyContent={'center'}>
                                <Grid item>
                                    <Button variant={'text'} href='https://www.skilnet.no/pakker/'>
                                        Meld på kurs
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item alignSelf={'end'}>
                            <GdprList />
                        </Grid>
                    </Grid>
                </PurePage>
            )}
            {!hasRefetchedUser && (
                <>
                    <div style={{justifyContent: 'center', height: '60vh', display: 'flex', alignItems: 'center'}}>
                        <div style={{width: '100%'}}>
                            <SkeletonTitleContainer>
                                <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: bigScreen ? '30%' : '75%'}} />
                            </SkeletonTitleContainer>
                            <SkeletonTitleContainer>
                                <Skeleton variant='text' animation='wave' sx={{width: '10%'}} />
                            </SkeletonTitleContainer>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
