import {
    CANCEL_CHANGE_FIELD,
    CANCEL_EDIT_INDICATOR,
    CHANGE_FIELD,
    CHANGE_LIST_ORDER,
    CLEAR_CHANGES,
    COMMIT_LIST_ORDER,
    RESET_LIST_ORDER,
} from '../actions/changes'

import {set} from 'lodash'

const reducer = (state = {}, action) => {
    const {id, field, content, ownerId} = action.payload || {}
    const mainId = ownerId || id

    if (!state[mainId]) state[mainId] = {}

    if (ownerId) {
        if (!state[mainId]['@children']) state[mainId]['@children'] = {}

        if (!state[mainId]['@children']) state[mainId]['@children'] = {}

        if (!state[mainId]['@children'][id]) state[mainId]['@children'][id] = {}
    }

    switch (action.type) {
        case CANCEL_CHANGE_FIELD:
            if (ownerId) {
                state[mainId]['@children'][id] = {...state[mainId]['@children'][id]}
                delete state[ownerId]['@children'][id][field]

                if (Object.values(state[ownerId]['@children'][id]).length === 0) {
                    state[ownerId]['@children'] = {...state[ownerId]['@children']}
                    delete state[ownerId]['@children'][id]
                }
            } else {
                state[id] = {...state[id]}
                delete state[id][field]
            }

            if (Object.values(state[mainId]).length === 0) {
                state = {...state}
                delete state[mainId]
            }

            return {...state}
        case CHANGE_LIST_ORDER:
            action.payload.items.forEach((iri, index) => {
                state[iri] = {
                    ...state[iri],
                    order: index * 10,
                }
            })

            return {...state}
        case COMMIT_LIST_ORDER: // Does the same as Reset list order, but entity reducer updates the actual state
        case RESET_LIST_ORDER:
            action.payload.items.forEach((iri, index) => {
                if (state[iri]?.order === index * 10) {
                    delete state[iri].order
                    if (Object.values(state[iri]).length === 0) {
                        delete state[iri]
                    }
                }
            })
            return {...state}
        case CHANGE_FIELD:
            if (ownerId) {
                state[ownerId]['@children'][id] = {
                    ...state[ownerId]['@children'][id],
                    [field]: content,
                }
            } else {
                state[id] = {
                    ...state[id],
                    [field]: content,
                }
            }

            return {...state}
        case CANCEL_EDIT_INDICATOR: /* Falltrough because they do the same thing */
        case CLEAR_CHANGES:
            state = {...state}
            delete state[mainId]
            return {...state}
        default:
            return {...state}
    }
}
export default reducer
