import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router'
import useUser from '../../Utilities/useUser'
import {Button} from '../../Components/Button/MuiButton'
import PurePage from '../../Components/PurePage'
import {Grid} from '@mui/material'
import GdprList from '../Components/GdprList'
import {useSearchParams} from 'react-router-dom'
import EmailAuthCard from '../Components/EmailAuthCard'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'

export default function EmailLoginPage() {
    const navigate = useNavigate()
    const user = useUser()
    const [searchParams] = useSearchParams()
    const redirect = searchParams.get('redirect')
    const authFailed = searchParams.get('auth_failed')
    const [error, setError] = useState<string | null>(null)
    const errorMsg = 'E-post eller passord er feil'
    useEffect(() => {
        if (!user.authenticated && redirect) {
            // If the user is not authenticated, and there is a redirect URL, and the redirect URL contains the auth_failed parameter

            // Create a URL object from the redirect URL
            const redirectUrlObj = new URL(redirect, window.location.href)
            // Extract the auth_failed parameter from the redirect URL
            const authFailedInRedirect = redirectUrlObj.searchParams.get('auth_failed')
            if (authFailedInRedirect) {
                setError(errorMsg)
                handleErrorWithToast(new Error(errorMsg))
            }
        }
        if (!user.authenticated) {
            return
        }

        if (redirect) {
            navigate(redirect)
            return
        }

        if (user.voters.isSkilUser()) {
            window.location.replace(`/dashboard/SKIL`)
        }
        window.location.href = '/dashboard/'
    }, [user.authenticated, redirect])

    useEffect(() => {
        if (authFailed) {
            setError(errorMsg)
            handleErrorWithToast(new Error(errorMsg))
        }
    }, [authFailed])

    return (
        <PurePage variant={'xs'} breadcrumbs={[{title: 'Logg inn', to: '/'}, {title: 'E-post'}]}>
            <Grid container minHeight={'80vh'} direction={'column'} justifyContent={'space-between'}>
                <Grid item>
                    <EmailAuthCard redirectUrl={redirect} error={error} />

                    <Grid container mt={2} justifyContent={'center'}>
                        <Grid item>
                            <Button variant={'text'} href='https://www.skilnet.no/pakker/'>
                                Meld på kurs
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item alignSelf={'end'}>
                    <GdprList />
                </Grid>
            </Grid>
        </PurePage>
    )
}
