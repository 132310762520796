import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import LoadingComponent from '../Components/LoadingComponent'
import {Bar, HorizontalBar, Doughnut} from 'react-chartjs-2'
import ErrorComponent from '../Components/ErrorComponent'
import {Page} from '../Components/Page'
import {useParams} from 'react-router'

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;

    h4,
    h1 {
        grid-column-start: 1;
        grid-column-end: -1;
    }

    div.chart {
        padding: 10px;
        background: white;
        min-width: 0;
        overflow: hidden;
        min-height: 300px;
        max-height: 700px;
        max-width: 100%;

        .chartContainer {
            height: 265px;
        }

        &.spanChart {
            @media screen and (min-width: 750px) {
                grid-column: span 2 / auto;
            }
        }
    }

    div.empty {
        padding: 10px;
        background: white;
        height: fit-content;
        min-width: 0;
        max-width: 100%;
        order: 9;
        grid-column-start: 1;
        grid-column-end: -1;
    }
`
const Footer = styled.div`
    text-align: center;
    font-family: sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: gray;

    line-height: 15px;
    height: 40px;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;

    p {
        margin: 0;
    }
`
const StyledTable = styled.div`
    padding: 10px;
    background: white;
    min-width: 0;
    overflow: hidden;

    max-width: 100%;

    .chartContainer {
        height: 265px;
    }

    &.spanChart {
        @media screen and (min-width: 750px) {
            grid-column: span 2 / auto;
        }
    }
`
const HeaderWarning = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: small;
    color: dimgrey;
    padding: 20px;
    height: 100px;
`
const WarningIcon = styled.div`
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const NotEnoughData = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
`
const TextIndicator = styled.div`
    resize: none;
    height: 87%;
    width: inherit;
    border: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow-y: scroll;
`
const StyledTd = styled.td`
    text-align: center;
`

const breadcrumbs = partnershipId => [
    {title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId},
    {title: 'Kvalitetsrapport', to: ''},
]

export default function Report() {
    const {partnershipId} = useParams()
    return (
        <Page breadcrumbs={breadcrumbs(partnershipId)} variant={'default'}>
            <div className='col-xs-12'>
                <Grid>
                    <Chart id={'getQualityPackagesCompleted'} />
                    <Chart id={'getQualityPackagesCompletedComparison'} />
                    <Chart id={'getAllOtherCoursesCompletedComparison'} />
                    <Chart id={'qualityOverview'} />
                    <Chart id={'getHelfoGenderStatistics'} />
                    <Chart id={'getAvailableLMGCourses'} />
                    <Chart id={'getAvailableRAKCourses'} />
                    <Chart id={'getAvailablePSGCourses'} />
                    <Chart id={'getAvailableKLOKCourses'} />
                    <Chart id={'getAvailableSFLCourses'} />
                </Grid>
            </div>
        </Page>
    )
}

const Chart = ({id}) => {
    const [data, setData] = useState<any>(null)

    useEffect(() => {
        if (!id) return

        let mounted = true

        fetch(`/api/partnerships/chart/${id}`)
            .then(res => res.json())
            .then(data => {
                if (mounted) setData(data)
            })

        return () => {
            mounted = false
        }
    }, [id])

    let Component = null
    const chartType = data?.chartType ?? null
    const groupTitle = data?.groupTitle ?? null
    const summary = data?.summary
    const span = summary?.span ?? 1
    const rowSpan = summary?.rowSpan ?? 1

    if (chartType === 'Table') {
        return (
            <StyledTable rowSpan={rowSpan} className={summary?.span ? 'spanChart' : ''}>
                <h4>{data?.title}</h4>
                <div className={'table-responsive'}>
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>{data?.data['groupTitle']}</th>
                                {Object.entries(data?.data['years']).map(([key, year]) => (
                                    <th key={key}>{year as string}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(data?.data).map(([title, data]) => (
                                <tr key={'tr' + title}>
                                    {title && title !== 'groupTitle' && title !== 'years' ? <td key={title}>{title}</td> : null}
                                    {/* ts-expect-error */}
                                    {Object.entries(data as {[year: string]: string}).map(([year, value]) =>
                                        title && title !== 'groupTitle' && title !== 'years' ? (
                                            <StyledTd key={'td' + title + year}>{value}</StyledTd>
                                        ) : null
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </StyledTable>
        )
    }

    if (chartType === 'List') {
        return (
            <>
                <h4>{groupTitle}</h4>
                {data.charts.map((id, index) => (
                    <Chart key={id + index} id={id} />
                ))}
            </>
        )
    }

    if (chartType === 'GroupList') {
        return (
            <>
                {data.charts.map((id, index) => (
                    <Chart key={id + index} id={id} />
                ))}
            </>
        )
    }

    if (!data)
        return (
            <div data-id={id} className={'empty'}>
                <h4>{data?.title}</h4>
                <span>Laster inn...</span>
            </div>
        )

    if (chartType === 'Empty' || !chartType)
        return (
            <div data-id={id} className={'empty'}>
                <h4>{data?.title}</h4>
                <span>Denne indikatoren finnes ikke!</span>
            </div>
        )

    if (chartType === 'None') return null

    if (chartType === 'Text') {
        return (
            <div className={'chart ' + (summary?.span ? 'spanChart' : '')}>
                {summary && summary.count >= 10 ? (
                    <TextIndicator>
                        <ul>
                            {data.data.map((value, index) => {
                                return <li key={index}>{value}</li>
                            })}
                        </ul>
                    </TextIndicator>
                ) : (
                    <>
                        <HeaderWarning>{data.title}</HeaderWarning>
                        <WarningIcon>
                            <i className='fa fa-analytics fa-4x' />
                        </WarningIcon>
                        <NotEnoughData>Inviter til kvalitetspakke for å samle inn mer data</NotEnoughData>
                    </>
                )}
                <Footer>{summary ? 'Antall lokale svar: ' + summary.count : ''}</Footer>
            </div>
        )
    }

    switch (chartType) {
        case 'Bar':
            // @ts-expect-error
            Component = Bar
            break
        case 'HorizontalBar':
            // @ts-expect-error
            Component = HorizontalBar
            break
        case 'Doughnut':
            // @ts-expect-error
            Component = Doughnut
            break
        default:
            // @ts-expect-error
            Component = data ? ErrorComponent : LoadingComponent
            break
    }

    const datasetKeyProvider = (...args) => {
        console.log(args)
        return args
    }

    return (
        <div data-id={id} className={'chart ' + (summary?.span && summary.nationalCount >= 10 ? 'spanChart' : '')}>
            {summary && summary.nationalCount >= 10 ? (
                <div className={'chartContainer'}>
                    {/* @ts-expect-error */}
                    <Component datasetKeyProvider={datasetKeyProvider} data={data?.data} options={data?.options} />
                </div>
            ) : (
                <>
                    <HeaderWarning>{data.title}</HeaderWarning>
                    <WarningIcon>
                        <i className='fa fa-analytics fa-4x' />
                    </WarningIcon>
                    <NotEnoughData>Inviter til kvalitetspakke for å samle mer data</NotEnoughData>
                </>
            )}
            {summary && summary.localCount < 10 && summary.nationalCount >= 10 && (
                <Footer>
                    {chartType && (chartType === 'Doughnut' || chartType === 'Pie') && <p>Sirkelen viser nasjonale data</p>}
                    <p>Utvalget er for lite til å kunne vise lokale data. ({summary.localCount} av 10)</p>
                </Footer>
            )}
            {chartType &&
                (chartType === 'Doughnut' || chartType === 'Pie') &&
                summary &&
                summary.localCount >= 10 &&
                summary.nationalCount >= 10 && (
                    <Footer>
                        <p>Indre sirkel: Nasjonale data</p>
                        <p>Ytre sirkel: Lokale data</p>
                    </Footer>
                )}
        </div>
    )
}
