import React, {useEffect, useRef, useState} from 'react'
import Panel from '../../../Components/Panel'
import {useOutletContext, useParams} from 'react-router-dom'
import Location from '../Components/Location'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'

import {SubmitHandler, useForm, Controller} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import _ from 'lodash'
import {Box, Button, Grid, TextField} from '@mui/material'
import Modal from '../../../Components/Modal'
import SubmitBar from '../Components/SubmitBar'

const daySchema = z.array(
    z.object({
        open: z.string(),
        close: z.string(),
    })
)

const openingHoursLocationSchema = z.object({
    content: z.string(),
    hidden: z.boolean(),
    href: z.string(),
    icon: z.string(),
    name: z.string(),
    order: z.number(),
    type: z.string(),
    days: z.record(z.string(), daySchema),
})
const validationSchema = z.object({
    siteTags: z.object({
        openHours: z.record(z.string(), openingHoursLocationSchema),
    }),
})

type ValidationSchema = z.infer<typeof validationSchema>

const mandatoryCategories = [`lab`, 'office', 'phone']

const defaultCatObject = {
    content: '',
    hidden: false,
    href: '',
    icon: 'info-circle',
    name: '',
    order: 4,
    type: 'other',
    days: {
        monday: [
            {
                open: '08:00',
                close: '16:00',
            },
        ],
        tuesday: [
            {
                open: '08:00',
                close: '16:00',
            },
        ],
        wednesday: [
            {
                open: '08:00',
                close: '16:00',
            },
        ],
        thursday: [
            {
                open: '08:00',
                close: '16:00',
            },
        ],
        friday: [
            {
                open: '08:00',
                close: '16:00',
            },
        ],
        lunch: [
            {
                open: '11:30',
                close: '12:00',
            },
        ],
    },
}
const getDefaultCategoryObject = key => {
    switch (key) {
        case 'lab':
            return {
                ...defaultCatObject,
                content: 'Hvis du trenger blodprøve eller vaksiner etc',
                hidden: false,
                href: '',
                icon: 'vials',
                name: 'Laboratorium',
                order: 3,
                type: 'lab',
            }
        case 'office':
            return {
                ...defaultCatObject,
                content: '',
                hidden: false,
                href: '',
                icon: 'map-marker-alt',
                name: 'Kontor',
                order: 2,
                type: 'address',
            }
        case 'phone':
            return {...defaultCatObject, content: '', hidden: false, href: '', icon: 'phone', name: 'Telefon', order: 1, type: 'phone'}
        default:
            return defaultCatObject
    }
}

const Openinghours = () => {
    const {nefle, setProgress, isGenerated, next}: any = useOutletContext()
    const nefleSetupMutation = useSkilMutation<'patchOfficeNefleItem'>('PATCH', `/api/office_nefles/${nefle.id}`)
    //For targeted rerender to keep zod data persistent
    const [renderKey, setRenderKey] = useState('')
    const [modalOpen, setModalOpen] = useState(false)

    const formRef = useRef(null)

    const {
        reset,
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        formState: {errors, isDirty, isSubmitting},
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
    })

    useEffect(() => {
        //Some categories are required for Nefle frontend.
        mandatoryCategories.map(key => {
            if (nefle.siteConfig.siteTags.openHours[key] == undefined) {
                nefle.siteConfig.siteTags.openHours[key] = getDefaultCategoryObject(key)
            }
        })
        reset({
            ...nefle.siteConfig,
        })
    }, [nefle])

    /*
    Submit everything, will only run if validated by zod, check errors variable if not.
    We only have data for this page in the form, so merging data from context is very important.
 */
    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        let siteConfig = nefle.siteConfig
        siteConfig.progress['/opningstider'] = true

        const merged = _.merge(nefle.siteConfig, data)

        //Make sure removed items are not reapplied
        merged.siteTags.openHours = data.siteTags.openHours

        try {
            await nefleSetupMutation.mutateAsync({
                siteConfig: {...merged},
            })
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            reset({...merged})
            setProgress(merged.progress)
        }
    }

    const locations = getValues('siteTags.openHours')

    const handleDelete = key => {
        const tmpLocations = locations
        delete tmpLocations[key]
        setValue('siteTags.openHours', tmpLocations, {shouldDirty: true})
        setRenderKey(Math.random().toString(36))
    }
    if (!locations) return <div></div>

    return (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} key={renderKey}>
            <Box sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '1em', sm: '2em'}}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginBottom: {xs: '1em', sm: 'em'},
                    }}
                >
                    {Object.keys(locations)
                        .sort((a, b) => locations[a].order - locations[b].order)
                        .map(key => {
                            return (
                                <Box
                                    key={key}
                                    sx={{
                                        marginBottom: {xs: '1em', sm: '2em'},
                                    }}
                                >
                                    <Location
                                        errors={errors}
                                        disabled={isGenerated}
                                        hidden={getValues(`siteTags.openHours.${key}.hidden`)}
                                        control={control}
                                        key={key}
                                        locationKey={key}
                                        register={register}
                                    />
                                    <Button
                                        variant={'contained'}
                                        disabled={mandatoryCategories.includes(key) || isGenerated}
                                        onClick={() => handleDelete(key)}
                                    >
                                        Slett
                                    </Button>
                                    <Controller
                                        name={`siteTags.openHours.${key}.hidden`}
                                        control={control}
                                        render={({field}) => {
                                            return (
                                                <Button
                                                    disabled={isGenerated}
                                                    variant={field.value ? 'contained' : 'outlined'}
                                                    onClick={() => {
                                                        setValue(`siteTags.openHours.${key}.hidden`, !field.value, {shouldDirty: true})
                                                        setRenderKey(Math.random().toString(36))
                                                    }}
                                                >
                                                    {field.value ? 'Skjult' : 'Synlig'}
                                                </Button>
                                            )
                                        }}
                                    />
                                </Box>
                            )
                        })}
                </Box>
            </Box>
            <Panel.Body>
                <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
                    <Button disabled={isGenerated} variant={'contained'} onClick={() => setModalOpen(true)}>
                        Legg til kategori
                    </Button>
                </Box>
            </Panel.Body>

            {modalOpen && (
                <CategoryModal
                    onClose={() => setModalOpen(false)}
                    onSave={key => {
                        setValue(`siteTags.openHours.${key}`, {...defaultCatObject, name: key}, {shouldDirty: true})
                        setModalOpen(false)
                    }}
                />
            )}
            {/*
               One for every page, ties to context
        */}

            <SubmitBar
                shouldSpin={isSubmitting}
                isDirty={isDirty}
                onDiscard={() => {
                    reset()
                    setRenderKey(Math.random().toString(36))
                }}
                isCompleted={nefle?.siteConfig?.progress && nefle.siteConfig?.progress['/opningstider']}
                next={() => {
                    next()
                }}
                hasError={Object.keys(errors).length > 0}
            />
        </form>
    )
}

export default Openinghours

const CategoryModal = ({onSave, onClose}) => {
    const [categoryName, setCategoryName] = useState('')
    return (
        <Modal
            size={'lg'}
            title={'Legg til kategori'}
            cancelText={'Avbryt'}
            onSave={() => {
                if (categoryName != '') {
                    onSave(categoryName)
                }
            }}
            onClose={onClose}
        >
            <TextField
                autoFocus
                type={'text'}
                required
                autoComplete={'office'}
                placeholder='Kategori'
                label={'Kategori'}
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
                onKeyPress={e => {
                    if (e.key == 'Enter' && categoryName != '') {
                        onSave(categoryName)
                    }
                }}
            />
        </Modal>
    )
}
