import * as React from 'react'
import {Box} from '../../../Components/Panel'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {useClickHandler} from '../../../Components/Button/utils'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import {DatePicker} from '@mui/x-date-pickers-pro'
import OutlinedInput from '@mui/material/OutlinedInput'
import {OfficeProps} from './Office'
import useFieldChange from '../../utils/useFieldChange'
import {components} from '../../../Generated/eportal'
import MenuItem from '@mui/material/MenuItem'

type UpdateSnowboxType = components['schemas']['Praksisnett.Office.UpdateSnowboxDTO.jsonld']
export const SnowboxDetailsPanel = ({office, refetch}: OfficeProps) => {
    const [updateFields, onChange, , onChangeDate] = useFieldChange<UpdateSnowboxType>({})
    const snowbox = office?.snowbox
    const updateSnowbox = useSkilMutation<'updatePraksisNettOfficeSnowbox'>(
        'post',
        `/api/praksisnett/offices/${office?.id}/update_snowbox`,
        [`/api/praksisnett/offices/${office?.id}`, `/api/praksisnett/offices`]
    )

    const saveSnowboxDetails = async () => {
        await updateSnowbox.mutateAsync({...snowbox, ...updateFields})
        refetch()
    }
    const {handleClick: handleSaveSnowboxDetailsClick, working: savingSnowboxDetails} = useClickHandler(saveSnowboxDetails)

    let cancelledAt = updateFields.cancelledAt ?? snowbox?.cancelledAt ? updateFields.cancelledAt ?? snowbox?.cancelledAt : null
    let deployedAt = updateFields.deployedAt ?? snowbox?.deployedAt ? updateFields.deployedAt ?? snowbox?.deployedAt : null

    return (
        <Box title='Tekniske Detaljer'>
            <Grid container spacing={2} mb={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        label='Operativ i analyseklient'
                        control={<Checkbox id='online' checked={updateFields.online ?? snowbox?.online ?? false} onChange={onChange} />}
                    />
                    <FormControlLabel
                        label='Passivt kontor'
                        control={
                            <Checkbox
                                id='drcInstalled'
                                checked={updateFields.drcInstalled ?? snowbox?.drcInstalled ?? false}
                                onChange={onChange}
                            />
                        }
                    />
                    <FormControlLabel
                        label='Operativ i portal'
                        control={
                            <Checkbox
                                id='invitationAvailable'
                                checked={updateFields.invitationAvailable ?? snowbox?.invitationAvailable ?? false}
                                onChange={onChange}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='status' fullWidth multiline label='Status' defaultValue={snowbox?.status} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id='journal' fullWidth label='Journalsystem' defaultValue={snowbox?.journal} onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label='Kontrakt inngått'
                        inputFormat='dd.MM.yyyy'
                        mask={'__.__.____'}
                        value={deployedAt}
                        renderInput={params => <TextField fullWidth id={'deployedAt'} {...params} />}
                        onChange={onChangeDate('deployedAt')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={'IT-ansvarlig'}
                        id={'extraction'}
                        name={'extraction'}
                        select
                        variant='outlined'
                        fullWidth
                        value={updateFields.extraction ?? snowbox?.extraction ?? 0}
                        onChange={onChange}
                    >
                        <MenuItem value={1}>Ja</MenuItem>
                        <MenuItem value={0}>Nei</MenuItem>
                        <MenuItem value={-1}>Feilet</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={'Test (rekr. -liste)'}
                        id={'test'}
                        name={'test'}
                        select
                        variant='outlined'
                        fullWidth
                        value={updateFields.test ?? snowbox?.test ?? 0}
                        onChange={onChange}
                    >
                        <MenuItem value={0}>Ok</MenuItem>
                        <MenuItem value={1}>Invitert</MenuItem>
                        <MenuItem value={2}>Signert</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={'Legesekretær har hatt opplæring'}
                        id={'sky'}
                        name={'sky'}
                        select
                        variant='outlined'
                        fullWidth
                        value={updateFields.sky ?? snowbox?.sky ?? 0}
                        onChange={onChange}
                    >
                        <MenuItem value={0}>Nei</MenuItem>
                        <MenuItem value={2}>Ja</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id='status' fullWidth label='Status' defaultValue={snowbox?.status} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='location' fullWidth label='Lokasjon boks' defaultValue={snowbox?.location} onChange={onChange} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label='Avsluttet kontrakt'
                        mask={'__.__.____'}
                        inputFormat='dd.MM.yyyy'
                        value={cancelledAt}
                        renderInput={params => <TextField fullWidth id={'cancelledAt'} {...params} />}
                        onChange={onChangeDate('cancelledAt')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id='cancelledReason'
                        fullWidth
                        label='Avsluttet årsak'
                        defaultValue={snowbox?.cancelledReason}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='notes' fullWidth multiline label='Merknad' defaultValue={snowbox?.notes} onChange={onChange} />
                </Grid>
            </Grid>
            <LoadingButton
                sx={{marginLeft: 'auto', display: 'block'}}
                loading={savingSnowboxDetails}
                variant='contained'
                onClick={handleSaveSnowboxDetailsClick}
            >
                Lagre
            </LoadingButton>
        </Box>
    )
}
