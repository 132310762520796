import React, {useEffect, useLayoutEffect, useState} from 'react'
import Panel from '../../../Components/Panel'
import {useOutletContext, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import _ from 'lodash'
import SubmitBar from '../Components/SubmitBar'
import {Box} from '@mui/system'

const accessibilitySchema = z.object({
    description: z.string(),
    title: z.string(),
    items: z.array(
        z.object({
            checked: z.boolean(),
            description: z.string(),
        })
    ),
})

const validationSchema = z.object({
    siteTags: z.object({
        accessibility: z.record(z.string(), accessibilitySchema),
    }),
})

type ValidationSchema = z.infer<typeof validationSchema>

const Accessibility = () => {
    const {nefle, setProgress, isGenerated, next}: any = useOutletContext()
    const nefleSetupMutation = useSkilMutation<'patchOfficeNefleItem'>('PATCH', `/api/office_nefles/${nefle.id}`)

    const {
        reset,
        handleSubmit,
        getValues,
        setValue,
        formState: {errors, isDirty, isSubmitting},
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
    })

    /*
        Populate form with data from Context
     */
    useLayoutEffect(() => {
        reset({
            ...nefle.siteConfig,
        })
    }, [nefle])

    /*
        Submit everything, will only run if validated by zod, check errors variable if not.
        We only have data for this page in the form, so merging data from context is very important.
     */
    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        let siteConfig = nefle.siteConfig
        siteConfig.progress['/tilgjengelighet'] = true

        const merged = _.merge(nefle.siteConfig, data)

        try {
            await nefleSetupMutation.mutateAsync({
                siteConfig: {...merged},
            })
        } catch (e) {
            /* 🍻 */
            handleErrorWithToast(e)
        } finally {
            /*
               Reset form to update isDirty validation
               progress functions from context
             */
            reset({...merged})
            setProgress(merged.progress)
        }
    }

    const accessibility = getValues('siteTags.accessibility')

    if (!accessibility) return <div></div>

    return (
        <Panel variant={'flat'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    {!isGenerated && (
                        <Box
                            sx={{
                                display: 'flex',
                                padding: '10px',
                            }}
                        >
                            <i className={'fa fa-question-circle'} />
                            &nbsp;
                            <p>Klikk på og eliminer egenskapene som ikke gjelder for legekontoret</p>
                        </Box>
                    )}
                    <Box>
                        {Object.keys(accessibility).map(k => (
                            <Group
                                group={accessibility[k]}
                                key={k}
                                onChange={(i, value) => {
                                    setValue(`siteTags.accessibility.${k}.items.${i}.checked`, !value, {shouldDirty: true})
                                }}
                                isGenerated={isGenerated}
                            />
                        ))}
                    </Box>
                </Box>
                {/*
               One for every page, ties to context
            */}
                <SubmitBar
                    shouldSpin={isSubmitting}
                    isDirty={isDirty}
                    onDiscard={() => reset()}
                    isCompleted={nefle?.siteConfig?.progress && nefle.siteConfig?.progress['/tilgjengelighet']}
                    next={() => {
                        next()
                    }}
                    hasError={Object.keys(errors).length > 0}
                />
            </form>
        </Panel>
    )
}

export default Accessibility

const Group = ({group, onChange, isGenerated}) => {
    const [renderKey, setRenderKey] = useState(Math.random().toString(36))

    return (
        <StyledGroup key={renderKey}>
            <div className={'content'}>
                <h3>{group?.title}</h3>
                <ul>
                    {group.items.map((item, index) => {
                        return (
                            <Item
                                isGenerated={isGenerated}
                                key={item.description + item.id + index.checked}
                                item={item}
                                onChange={() => {
                                    //Rerender group on value change
                                    setRenderKey(Math.random().toString(36))
                                    onChange(index, item.checked)
                                }}
                            />
                        )
                    })}
                </ul>
            </div>
        </StyledGroup>
    )
}

const Description = styled.span`
    text-decoration: ${props => (props.checked ? 'inherit' : 'line-through')};
`

const Item = ({onChange, isGenerated, item}) => {
    return (
        <StyledLi
            tabIndex={0}
            style={{pointerEvents: isGenerated ? 'none' : 'auto'}}
            onKeyDown={e => {
                if (e.key == 'Enter') onChange()
            }}
        >
            <div onClick={() => onChange()}>
                <i className={item.checked ? 'fa fa-eye' : 'fa fa-eye-slash'} />
                &nbsp;
                <Description checked={item.checked}>{item.description}</Description>
            </div>
        </StyledLi>
    )
}

const StyledGroup = styled.div`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border-radius: ${p => p.theme.radius};
    margin-bottom: 1.5rem;
    display: flex;
    font-size: 20px;
    width: 100%;
    border: 0;

    .content {
        background-color: #ffffff;
        border-radius: 0 10px 10px 0;
        padding: 1rem;
        flex-grow: 1;
        text-align: left;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn-link {
            text-align: start;
        }

        h3 {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }

        p {
            margin-bottom: 0;
            font-family: Open Sans, sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0;
            text-align: left;
        }

        @media (max-width: 1040px) {
            word-wrap: break-word;
            width: 60%;
        }
    }

    @media (max-width: 600px) {
        padding: 1rem;

        .content {
            padding-left: 1rem;
            font-size: 16px;
        }
    }
`
const StyledLi = styled.li`
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
        background-color: #dddddd;
    }
    &:focus-visible {
        outline: black auto 2px;
    }
`
