import * as React from 'react'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {Box} from '../../../Components/Panel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {Button} from '../../../Components/Button/MuiButton'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {DoctorType} from './Doctor'
import {CountyAutocomplete} from '../../../Components/CountyAutocomplete'
import {SetState} from '../../../Utilities/TypeHelpers'
import {Chip} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import {useState} from 'react'
import LoadingComponent from '../../../Components/LoadingComponent'

interface DoctorOfficePanelProps {
    doctor: DoctorType
    refetch: Function
}

export const DoctorOfficePanel = ({doctor, refetch}: DoctorOfficePanelProps) => {
    const [newOfficeName, setNewOfficeName] = useState('')
    const [county, setCounty] = useState(() => {
        if (doctor) {
            return doctor?.office?.county?.['@id']
        }
        return null
    })
    const [district, setDistrict] = useState(() => {
        if (doctor) {
            return doctor?.office?.district?.['@id']
        }
        return null
    })

    const officesResponse = useSkilQuery<'getPraksisNettOffices'>(
        `/api/praksisnett/offices`,
        {county, pagination: false},
        {enabled: !!county}
    )
    const externalOfficesResponse = useSkilQuery<'getCountyExternalOfficeCollection'>(
        `${county}/external_offices`,
        {pagination: false},
        {
            enabled: !!county,
        }
    )
    const helfoOffices = externalOfficesResponse.data?.['hydra:member'] ?? []
    const praksisNettOffices = officesResponse.data?.['hydra:member'] ?? []
    const loading = officesResponse.isLoading || externalOfficesResponse.isLoading

    const options: {value: string | null; label: string; type: string}[] = praksisNettOffices.map(o => {
        return {
            label: o.name,
            value: o['@id']!,
            type: o.helfoOffice ? 'both' : 'praksisNett',
        }
    })

    const [localOffice, setLocalOffice] = React.useState(() => {
        return options.find(office => office?.value === doctor.office?.['@id']) ?? undefined
    })

    helfoOffices.forEach(h => {
        const include = !praksisNettOffices.find(s => s.helfoOffice === h['@id'])
        if (!include) return

        options.push({
            label: h.name,
            value: h['@id']!,
            type: 'helfo',
        })
    })

    options.unshift({
        label: 'Lag nytt legekontor',
        value: 'new',
        type: 'new',
    })

    const changeOffice = useSkilMutation<`changePraksisnettOffice`>('post', `/api/praksisnett/doctors/${doctor?.id}/change_office`, [
        `/api/praksisnett/doctors/${doctor?.id}`,
        `/api/praksisnett/doctors`,
    ])

    const saveOfficeChange = async () => {
        if (!localOffice?.value) return
        try {
            if (localOffice.type === 'helfo') {
                await Promise.all([changeOffice.mutateAsync({hpnOffice: localOffice.value})])
            }
            if (localOffice.type === 'new' && county) {
                await Promise.all([changeOffice.mutateAsync({newOffice: {county, name: newOfficeName, district}})])
            }
            if (localOffice.type === 'both') {
                await Promise.all([changeOffice.mutateAsync({office: localOffice.value, district})])
            }
            if (localOffice.type === 'praksisNett') {
                await Promise.all([changeOffice.mutateAsync({office: localOffice.value, district})])
            }

            // refetch: no need to catch, handled by useSkilQuery 😄
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onCountyChange = (event, county, iri, district) => {
        setCounty(iri)
        setDistrict(district.id)
        if (!district) {
            setLocalOffice(undefined)
        }
    }

    const onOfficeChange = (event, office) => {
        setLocalOffice(office)
    }

    const onNewOfficeName = (name: string) => {
        setNewOfficeName(name)
    }

    const officeDistrict = doctor?.office?.district
    const prevDistrict = officeDistrict?.['@id'] && officeDistrict?.name ? {id: officeDistrict['@id'], label: officeDistrict.name} : null
    return (
        <Box title='Legekontordetaljer'>
            <Grid container spacing={2} mb={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <CountyAutocomplete
                        value={doctor?.office?.county?.['@id'] ?? null}
                        valueDistrict={prevDistrict}
                        onChange={onCountyChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        sx={{mb: 2}}
                        id={'choose-office'}
                        fullWidth
                        value={localOffice}
                        disabled={!county}
                        options={options}
                        onChange={onOfficeChange}
                        placeholder={'Velg...'}
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderOption={(props, option) => {
                            const type = option.type

                            return (
                                <li {...props}>
                                    <div style={{width: '100%'}}>
                                        <span className='pull-left'>{option.label}</span>
                                        <span className='pull-right'>
                                            {(type === 'both' || type === 'praksisNett') && (
                                                <Chip size='small' label='PraksisNett' color='primary' className='pull-right' />
                                            )}
                                            {(type === 'both' || type === 'helfo') && (
                                                <Chip size='small' className='pull-right' label={'HELFO'} />
                                            )}
                                            {type === 'new' && <strong>{option.label}</strong>}
                                        </span>
                                    </div>
                                    <br />
                                </li>
                            )
                        }}
                        renderInput={params => (
                            <TextField
                                required={true}
                                {...params}
                                label={'Legekontor'}
                                helperText={county ? '' : 'Du må velge kommune først'}
                            />
                        )}
                    />
                    {loading && county && <LoadingComponent />}
                    {localOffice?.value === 'new' && (
                        <TextField
                            id={'office-name'}
                            label={'Legekontor navn'}
                            value={newOfficeName ?? ''}
                            onChange={e => onNewOfficeName(e.target.value)}
                        />
                    )}
                </Grid>
            </Grid>
            {/* Todo: convert these divs into button footer component (we are reusing them, bigtime :D) */}
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                <Button onClick={saveOfficeChange}>Lagre</Button>
            </div>
        </Box>
    )
}
