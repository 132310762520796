import has from 'lodash/has'
import {entitiesUpdated, entityNotFound} from '../actions/entity'
import {REQUEST} from '../actions/api'
import {showToast} from '../actions/ui'
import React from 'react'
import reportError from '../utils/errorHandler'

export const MIME_TYPE = 'application/ld+json'
export const PATCH_MIME_TYPE = 'application/merge-patch+json'

export const api =
    ({dispatch, getState}) =>
    next =>
    async action => {
        next(action)
        if (action.type !== REQUEST) return

        try {
            let {url, method, body, type, ttl = 60} = action.payload || {}
            const {forceReload} = action.meta || {}
            const {entity} = getState()

            if (body) {
                body = JSON.stringify(body)
            }

            const contentType = method === 'PATCH' ? PATCH_MIME_TYPE : MIME_TYPE

            if (method === 'GET' && typeof entity[url] !== 'undefined' && !forceReload) {
                dispatch({type, payload: entity[url], meta: {_from: 'cache'}})
                return Promise.resolve(entity[url])
            }

            const response = await fetch(url, {
                method,
                headers: {'Content-Type': contentType, Accept: 'application/ld+json, application/json'},
                body,
                credentials: 'include',
            })

            if (response.status === 401) {
                const redirectTarget = response.headers.get('WWW-Authenticate')
                const redirectAction = redirectTarget ? () => (window.location.href = redirectTarget) : undefined
                dispatch(
                    showToast('Du er ikke innlogget eller mangler nødvendige rettigheter for denne handlingen.', 'warning', {
                        autoClose: true,
                        onClose: redirectAction,
                    })
                )
                return Promise.reject('Unauthorized')
            }

            if (response.status === 403) {
                console.error(response)

                const redirectTarget = response.headers.get('Location')
                const redirectAction = redirectTarget ? () => (window.location.href = redirectTarget) : undefined
                dispatch(showToast('Du har ikke tilatelse til å gjøre dette.', 'warning', {autoClose: false, onClose: redirectAction}))
                return Promise.reject('Access denied')
            }

            if (response.status === 404 && method === 'GET') {
                dispatch(entityNotFound(url))
                dispatch({type, payload: {id: url, '@status': 'NOT FOUND'}, meta: {_from: 'api'}})
                return Promise.reject(response.statusText)
            }

            if (response.status === 204 && method === 'DELETE') {
                const newVar = {type, payload: {id: url, '@status': 'DELETED'}, meta: {_from: 'api'}}
                dispatch(newVar)
                return Promise.resolve()
            }

            if (response.status === 409) {
                console.error({response, action})
                const partialUrl = '/api/location_indicators'
                let statusText = response.statusText
                if (response.url.includes(partialUrl)) {
                    statusText = 'Skjemaet har allerede en lokalisasjonsindikator. Kun èn lokalisasjonsindikator er tillat per skjema.'
                }

                reportError(`${response.status}: ${statusText} (${method}: ${url}`)
                dispatch(
                    showToast(
                        <>
                            En feil oppstod: {statusText}
                            <br />
                            <br />
                        </>,
                        'error',
                        {autoClose: false, onClose: () => document.location.reload()}
                    )
                )
                return Promise.reject(statusText)
            }

            if (response.status >= 400 && response.status < 500) {
                console.error({response, action})

                reportError(`${response.status}: ${response.statusText} (${method}: ${url}`)
                dispatch(
                    showToast(
                        <>
                            En feil oppstod: {response.statusText}
                            <br />
                            <br />
                            Du bør oppdatere siden.
                        </>,
                        'error',
                        {autoClose: false, onClose: () => document.location.reload()}
                    )
                )
                return Promise.reject(response.statusText)
            }
            if (response.status >= 500) {
                console.error({response, action})

                dispatch(
                    showToast(
                        <>
                            En feil oppstod: {response.statusText}
                            <br />
                            <br />
                            Du kan prøve igjen eller oppdatere siden. Teknikere er blitt varslet om feilen.
                        </>,
                        'error'
                    )
                )
                return Promise.reject(response.statusText)
            }

            let receivedContentType = response.headers.get('Content-type')
            if (receivedContentType?.includes(';')) receivedContentType = receivedContentType.substr(0, receivedContentType.indexOf(';'))

            if (['application/json', 'application/ld+json'].includes(receivedContentType ?? '')) {
                let json = await response.json()
                json = normalize(json) //TODO: Only normalize and cache the response if its jsonld?

                const ttlMs = ttl !== false ? ttl * 1000 : false
                // @ts-expect-error
                const expiration = ttl ? Date.now() + ttlMs : false
                const entities = normalizeAnything(json, expiration, ttl)
                dispatch(entitiesUpdated(entities))
                json['@status'] = 'FOUND'
                json['@expiresAt'] = expiration
                json['@fetchedAt'] = Date.now()
                json['@ttl'] = ttl
                dispatch({type, payload: json, meta: {_from: 'api', url}})
                return Promise.resolve(json)
            } else {
                return Promise.resolve({})
            }
        } catch (e: any) {
            reportError(e)
            dispatch(
                showToast(
                    <>
                        En feil oppstod
                        <br />
                        <br />
                        Du bør oppdatere siden.
                    </>,
                    'error',
                    {autoClose: false, onClose: () => document.location.reload()}
                )
            )
            return Promise.reject(new Error(e.message))
        }
    }

function normalize(data) {
    if (has(data, 'hydra:member')) {
        // Normalize items in collections
        data['hydra:member'] = data['hydra:member'].map(item => normalize(item))

        return data
    }

    // Flatten nested documents
    return data /* mapValues(data, value =>
        Array.isArray(value)
            ? value.map(v => get(v, '@id', v))
            : get(value, '@id', value)
    ); */
}

export const normalizeAnything = (anything: any, expiration?: number, ttl?: number) => {
    let entities = {}

    if (
        anything['@id'] &&
        anything['@type'] &&
        anything['@type'] !== 'hydra:Collection' &&
        anything['@type'] !== 'hydra:PartialCollectionView'
    ) {
        anything['@status'] = 'FOUND'
        anything['@expiresAt'] = expiration
        anything['@fetchedAt'] = Date.now()
        anything['@ttl'] = ttl
        entities[anything['@id']] = anything
    }

    for (const [key, value] of Object.entries(anything)) {
        if (value && typeof value === 'object') {
            const normalized = normalizeAnything(anything[key], expiration, ttl)
            entities = {...entities, ...normalized}
        }
    }

    return entities
}
