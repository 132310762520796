import React, {ReactNode} from 'react'
import {isValid} from 'date-fns'
import TextField from '@mui/material/TextField'
import {DateTimePicker} from '@mui/x-date-pickers-pro'

type Props = {
    id: string
    label?: ReactNode
    /** @format date-string */
    value?: Date | string | null
    onChange: (e: any, event: any) => any
    placeholder?: string
    required?: boolean
    disabled?: boolean
    showTimeSelect?: boolean
    autocomplete?: 'off'
    fullWidth?: boolean
    hours?: boolean
}
/** @deprecated*/
export default function DateTimeField({
    id,
    label,
    value,
    onChange,
    required = false,
    disabled = false,
    fullWidth = true,
    hours = true,
    ...props
}: Props) {
    const [hasError, setHasError] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const localValue = new Date(value ? value : NaN)
    const selected = isValid(localValue) ? localValue : null

    const onLocalChange = e => {
        return onChange(e, e)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div
            onBlur={() => {
                setHasError(!selected)
            }}>
            <DateTimePicker
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                views={hours ? ['year', 'month', 'day', 'hours', 'minutes'] : ['year', 'month', 'day']}
                label={label}
                value={selected}
                inputFormat={hours ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
                minutesStep={5}
                ampm={false}
                onChange={onLocalChange}
                disabled={disabled}
                renderInput={params => (
                    <TextField sx={{mb: hasError ? 0 : 2}} onClick={handleOpen} fullWidth={fullWidth} required={required} {...params} />
                )}
                {...props}
            />
            {hasError && (
                <p style={{marginBottom: '2em', fontSize: '85%', color: 'red'}}>
                    Dato og tid er i feil format. Bruk dato velgeren i stedet.
                </p>
            )}
        </div>
    )
}
