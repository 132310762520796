import * as React from 'react'
import Button from '../../Components/Button/Button'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-premium'
import {useSkilQuery} from '../../Utilities/QueryClient'
import {Box} from '../../Components/Panel'
import DataGrid, {InitModel} from '../../Components/DataGrid/DataGrid'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import groupColumnDef from '../../Components/DataGrid/columnDefs/groupColumnDef'
import taskColumnDef from '../../Components/DataGrid/columnDefs/taskColumnDef'
import courseColumnDef from '../../Components/DataGrid/columnDefs/courseColumnDef'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import {FilterList as FilterListIcon} from '@mui/icons-material'
import {useState} from 'react'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import format from 'date-fns/format'

let locationRenderCell = (params: GridRenderCellParams) => (
    <Button variant='text' to={`/dashboard/meetings/${params.id}`}>
        {params.value || <em>mangler sted</em>}
    </Button>
)
const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'ID', type: 'number'}),
    courseColumnDef({field: 'course.shortTitle'}),
    groupColumnDef({field: 'group'}),
    taskColumnDef({field: 'task'}),
    columnDef({field: 'location', headerName: 'Sted', renderCell: locationRenderCell}),
    dateColumnDef({field: 'time', headerName: 'Tid', type: 'dateTime'}),
    dateColumnDef({field: 'createdAt', headerName: 'Opprettet', type: 'dateTime'}),
]
const columnVisibilityModel = {id: false, createdAt: false}

const Meetings = () => {
    const apiParams = {pagination: false}

    const {data, isFetching} = useSkilQuery<'getMeetingCollection'>('/api/meetings', apiParams)
    const meetings = data?.['hydra:member'] ?? []
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel})

    const actions: SpecialActionProps[] = [
        {
            label: 'Tidligere møter',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel,
                    sort: [{field: 'time', sort: 'desc'}],
                    filter: [{columnField: 'time', operatorValue: 'onOrBefore', value: format(new Date(), 'yyyy-MM-dd')}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'Fremtidige møter',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel,
                    sort: [{field: 'time', sort: 'asc'}],
                    filter: [{columnField: 'time', operatorValue: 'onOrAfter', value: format(new Date(), 'yyyy-MM-dd')}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
    ]

    return (
        <Box>
            <DataGridWrapper rows={meetings.length}>
                <DataGrid
                    id={'meetings'}
                    initModel={initModel}
                    loading={isFetching}
                    rows={meetings}
                    columns={columns}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={actions} />}
                />
            </DataGridWrapper>
        </Box>
    )
}

export default Meetings
