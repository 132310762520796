import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import LoadingComponent from '../../../Components/LoadingComponent'
import {useLayoutEffect} from 'react'
import {IndicatorProps} from './index'
import {SeminarThemeIndicatorType} from '../../Types'
import {SkilQueryResponseType, useSkilQuery} from '../../../Utilities/QueryClient'
import SelectField from '../../../Components/Fields/SelectField'

type SeminarType = SkilQueryResponseType<'getCourseItem'>

const SeminarThemeIndicator = ({indicator, answer, onChange, disabled = false}: IndicatorProps<SeminarThemeIndicatorType, null>) => {
    const {fresh, value, valid} = answer

    const {data: templates} = useSkilQuery<'getCourseItem'>(`/api/seminar/templates`)
    const seminarTemplates = templates?.['hydra:member'] ?? []

    useLayoutEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [seminarTemplates])

    if (!seminarTemplates) return <LoadingComponent size={'h4'}>Laster inn...</LoadingComponent>

    return (
        <FormGroup hasError={!disabled && !fresh && !valid && !indicator.optional} hasSuccess={!fresh && valid} size={'lg'}>
            <label style={{marginTop: '0.5em'}}>{indicator.title}</label>
            <SelectField
                id={'seminar_theme_indicator'}
                value={value}
                entities={seminarTemplates}
                onChange={(seminar: SeminarType) => {
                    // @ts-expect-error
                    onChange(seminar['@id'])
                }}
            />
            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
        </FormGroup>
    )
}

export default SeminarThemeIndicator
