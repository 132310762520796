import {
    GridCallbackDetails,
    GridCellEditCommitParams,
    GridCellParams,
    GridColDef,
    GridComparatorFn,
    GridValueGetterParams,
} from '@mui/x-data-grid-premium'
import format from 'date-fns/format'
import {DATE_FORMAT} from '../../../State/utils/constants'
import {resolve} from './columnDef'
import isValid from 'date-fns/isValid'
import React from 'react'

const dateRenderCell =
    (callback, formatStr, editable = false, noDateMessage = '', sortDirection) =>
    params => {
        if (callback) return callback(params)
        const dates = [...params.value]
        if (dates.length > 0) {
            // sort formattedDates by date
            dates?.sort(function (a, b) {
                if (sortDirection === 'asc') {
                    if (a < b) {
                        return -1
                    }
                    if (a > b) {
                        return 1
                    }
                } else {
                    if (a < b) {
                        return 1
                    }
                    if (a > b) {
                        return -1
                    }
                }

                return 0
            })
        }

        let todayAtMidnight = new Date()
        todayAtMidnight.setHours(0, 0, 0, 0)
        const inOneWeek = new Date(todayAtMidnight.getFullYear(), todayAtMidnight.getMonth(), todayAtMidnight.getDate() + 7)
        const inOneDay = new Date(todayAtMidnight.getFullYear(), todayAtMidnight.getMonth(), todayAtMidnight.getDate() + 1)
        let formattedDates: {dateFormatted: string; backgroundColor: string; color: string}[] = []
        if (dates.length > 0) {
            for (let i = 0; i < dates.length; i++) {
                let backgroundColor = ''
                let color = ''
                if (dates[i] && isValid(new Date(dates[i]))) {
                    const dateFormatted = format(new Date(dates[i]), formatStr)
                    const dateObj = new Date(dates[i])

                    // if today or less than one day left until meeting, show strong warning
                    if (dateObj && dateObj <= inOneDay && dateObj >= todayAtMidnight) {
                        backgroundColor = '#a92513'
                        color = '#fff'
                    }
                    // if less than one week left until meeting, show warning
                    if (dateObj && dateObj < inOneWeek && dateObj > inOneDay) {
                        backgroundColor = '#e1c00e'
                        color = '#fff'
                    }

                    formattedDates.push({dateFormatted, backgroundColor, color})
                }
                if (!isValid(new Date(dates[i]))) {
                    formattedDates.push({dateFormatted: 'Ugyldig datoformat, kontakt support', backgroundColor, color})
                }
            }
        }

        return (
            <ul style={{width: '100%', padding: '0', textAlign: 'center'}}>
                {formattedDates.length > 0
                    ? formattedDates.map((date, index) => {
                          return (
                              <li
                                  key={date.dateFormatted + index}
                                  style={{
                                      listStyle: 'none',
                                      backgroundColor: `${date.backgroundColor}`,
                                      color: `${date.color}`,
                                      width: '100%',
                                  }}
                              >
                                  {date.dateFormatted}
                              </li>
                          )
                      })
                    : noDateMessage}
            </ul>
        )
    }

const compareDates = (date1, date2, sortDirection) => {
    if (sortDirection === 'asc') {
        if (!date1) return -1
        if (!date2) return 1
    } else {
        if (!date1) return 1
        if (!date2) return -1
    }
    const d1 = new Date(date1)
    const d2 = new Date(date2)

    // @ts-expect-error
    return d1 - d2 // This will give a number less than, equal to, or greater than zero
}

const dateComparator = (callback, sortDirection) => (v1, v2, cellParams1, cellParams2) => {
    // Check for null or empty arrays first
    if ((!v1 || v1.length === 0) && (!v2 || v2.length === 0)) {
        return 0 // both are empty/null, treat as equal
    }
    if (!v1 || v1.length === 0) {
        if (sortDirection === 'asc') {
            return -1
        } else {
            return 1
        }
    }
    if (!v2 || v2.length === 0) {
        if (sortDirection === 'asc') {
            return 1
        } else {
            return -1
        }
    }

    // Sort the arrays of dates
    v1.sort(compareDates)
    v2.sort(compareDates)

    // Use the first element for comparison as arrays are already sorted
    const firstDate1 = v1[0]
    const firstDate2 = v2[0]

    // Apply the custom callback if provided
    if (callback) {
        return callback(firstDate1, firstDate2)
    }

    // Compare the first elements based on sort direction
    return compareDates(firstDate1, firstDate2, sortDirection)
}

const dateValueGetter = getter => (params: GridValueGetterParams) => {
    return getter ? getter(params) : resolve(params.field, params.row)
}

type Column = GridColDef & {
    type?: 'string'
    sortDirection?: 'asc' | 'desc' | 'none'
}

// Types for committing edited fields
type OnEditCommitCallback = (params: GridCellEditCommitParams, event: any, details: GridCallbackDetails) => any

type EditDef = {
    onCellEditCommit: OnEditCommitCallback
}

export type EditableGridColDef = GridColDef & {onCellEditCommit?: OnEditCommitCallback}

export default function arrayOfDatesColumnDef(col: Column, edit?: EditDef, noDateMessage?: string): EditableGridColDef {
    const sortDirection = col.sortDirection
    const formatStr = DATE_FORMAT
    const valueGetter = dateValueGetter(col.valueGetter)
    const sortComparator = dateComparator(null, sortDirection)
    const renderCell = dateRenderCell(col.renderCell, formatStr, Boolean(edit), noDateMessage, sortDirection)

    const getApplyDateQuickFilterFn = (value: string) => {
        if (!value || value.length > 10 || !/\d+/.test(value) || /.*[a-zA-Z].*/.test(value)) {
            // The filter value cannot be a date
            return null
        }
        return (params: GridCellParams): boolean => {
            return params.value ? format(params.value, formatStr).includes(value) : false
        }
    }

    return {
        editable: !!edit?.onCellEditCommit,
        onCellEditCommit: edit?.onCellEditCommit,
        flex: 1,
        headerName: 'Dato',
        ...col,
        valueGetter,
        sortComparator,
        renderCell,
        getApplyQuickFilterFn: getApplyDateQuickFilterFn,
        // This affects filtering, keep "dateTime" if we want to filter on a specific time, and not only a date
        type: 'string',
    }
}
