import React, {useState} from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'

const StyledShade = styled(motion.div)`
    position: ${props => (props.toolboxStyling ? 'relative' : 'absolute')};
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    &:hover {
        cursor: pointer;
    }

    i.fa {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
    }
`

const EditShade = ({onEdit, toolboxStyling, ...rest}) => {
    return (
        <StyledShade
            whileHover='hover'
            initial='rest'
            {...rest}
            onClick={onEdit}
            toolboxStyling={toolboxStyling}
            variants={{
                rest: {background: 'rgba(0,0,0,0)', transition: {duration: 0.25}},
                hover: {
                    background: 'rgba(0,0,0,0.05)',
                    transition: {duration: 0.25},
                },
            }}
        >
            <motion.i
                variants={{
                    rest: {opacity: 0},
                    hover: {opacity: 1},
                }}
                className={'fa fa-pen'}
            />
        </StyledShade>
    )
}
export default EditShade
