import React from 'react'
import styled from 'styled-components'

import TextIndicator from './TextIndicator'
import InformationIndicator from './InformationIndicator'
import UnknownIndicator from './UnknownIndicator'
import NumberIndicator from './NumberIndicator'
import CheckboxIndicator from './CheckboxIndicator'
import ChoiceIndicator from './ChoiceIndicator'
import MultichoiceIndicator from './MultichoiceIndicator'
import DateTimeIndicator from './DateTimeIndicator'
import CounselorEmailIndicator from './CounselorEmailIndicator'
import LocationIndicator from './LocationIndicator'
import PreviousAnswerIndicator from './PreviousAnswerIndicator'
import {LoadedUseEntityType} from '../../../Hooks/useEntity'
import {SkilQueryResponseType} from '../../../Utilities/QueryClient'
import SeminarThemeIndicator from './SeminarThemeIndicator'

const StyledContainer = styled.div`
    width: 100%;
    position: relative;
    top: 1.5em;
`

type IndicatorType = SkilQueryResponseType<'getIndicatorItem'>

type Props = {
    indicator: LoadedUseEntityType<IndicatorType>
    onChange: (e: any) => void
    answer: any
    showSaveAndResetButtons: (e: any) => void
}

const Indicator = ({indicator, onChange, answer, showSaveAndResetButtons, ...rest}: Props) => {
    const CurrentIndicator = PickIndicator(indicator)

    return (
        <StyledContainer {...rest}>
            <CurrentIndicator
                disabled={false}
                onChange={onChange}
                indicator={indicator}
                answer={answer}
                showSaveAndResetButtons={showSaveAndResetButtons}
            />
        </StyledContainer>
    )
}
export default Indicator

function PickIndicator(indicator) {
    switch (indicator['subType']) {
        case 'InformationIndicator':
            return InformationIndicator
        case 'TextIndicator':
            return TextIndicator
        case 'NumberIndicator':
            return NumberIndicator
        case 'CheckboxIndicator':
            return CheckboxIndicator
        case 'ChoiceIndicator':
            return ChoiceIndicator
        case 'MultichoiceIndicator':
            return MultichoiceIndicator
        case 'CounselorEmailIndicator':
            return CounselorEmailIndicator
        case 'DateTimeIndicator':
            return DateTimeIndicator
        case 'LocationIndicator':
            return LocationIndicator
        case 'PreviousAnswerIndicator':
            return PreviousAnswerIndicator
        case 'SeminarThemeIndicator':
            return SeminarThemeIndicator
        default:
            return UnknownIndicator
    }
}
