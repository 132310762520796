import * as React from 'react'
import useFieldChange from '../../utils/useFieldChange'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {Box} from '../../../Components/Panel'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ClassIcon from '@mui/icons-material/Class'
import LinkIcon from '@mui/icons-material/Link'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {components} from '../../../Generated/eportal'
import {Button} from '../../../Components/Button/MuiButton'
import {DeleteIconButton} from '../../../Components/Button/DeleteIconButton'

type StudyType = components['schemas']['Praksisnett.Study.jsonld-PraksisNettStudy.details_PraksisNettStudyAttachment.details_File.view']
type AddPublicationType = components['schemas']['Praksisnett.Study.Publication.jsonld']
interface StudyProps {
    study: StudyType
    refetch: Function
}

export const StudyPublicationsPanel = ({study, refetch}: StudyProps) => {
    const [open, setOpen] = React.useState(false)
    const [updateFields, onFieldChange, setUpdateFields] = useFieldChange<AddPublicationType>({})

    const addMutation = useSkilMutation<'postPraksisnettStudyPublicationCollection'>('POST', `/api/praksisnett/study/publications`, [
        `/api/praksisnett/studies/${study.id}`,
        `/api/praksisnett/studies`,
    ])
    const deleteMutation = useSkilMutation<'deletePraksisnettStudyPublicationItem'>('DELETE', `/api/praksisnett/study/publications`, [
        `/api/praksisnett/studies/${study.id}`,
        `/api/praksisnett/studies`,
    ])

    const onAddPublication = async () => {
        await addMutation.mutateAsync({...updateFields, study: study['@id']})
        // @ts-expect-error
        setUpdateFields({})
        setOpen(false)
        refetch()
    }
    const onDeletePublication = async id => {
        await deleteMutation.mutateAsync({
            '@overridePath': id,
        })
        refetch()
    }

    return (
        <Box title='Publikasjoner' padContents={false}>
            <List>
                {/* existing publications */}
                {study.publications?.map(p => {
                    const isUrl = String(p.reference).toLowerCase().startsWith('http')
                    return (
                        <ListItem
                            sx={{overflowWrap: 'anywhere'}}
                            key={p.id}
                            secondaryAction={
                                <DeleteIconButton
                                    deleteAction={() => onDeletePublication(p['@id'])}
                                    menuId={p.id?.toString()}
                                    deleteMenuItemLabel='Slett publikasjon'
                                />
                            }
                        >
                            <ListItemIcon sx={{minWidth: 32}}>
                                {isUrl ? <LinkIcon fontSize='small' /> : <ClassIcon fontSize='small' />}
                            </ListItemIcon>
                            <ListItemText
                                primary={p.title}
                                secondary={
                                    isUrl ? (
                                        <a target={'_blank'} href={p.reference}>
                                            {p.reference}
                                        </a>
                                    ) : (
                                        p.reference
                                    )
                                }
                            />
                        </ListItem>
                    )
                })}
                {/* add publication */}
                <ListItem>
                    <ListItemButton onClick={() => setOpen(open => !open)}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Legg til publikasjon'} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Grid container spacing={2} mt={0.25}>
                            <Grid item xs={12}>
                                <TextField id='title' fullWidth value={updateFields.title ?? ''} onChange={onFieldChange} label='Tittel' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='reference'
                                    fullWidth
                                    value={updateFields.reference ?? ''}
                                    onChange={onFieldChange}
                                    label='Referanse'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={onAddPublication} sx={{marginLeft: 'auto', display: 'block'}} variant='contained'>
                                    Lagre
                                </Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                </ListItem>
            </List>
        </Box>
    )
}
