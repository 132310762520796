import Panel from '../../Components/Panel'
import format from 'date-fns/format'
import * as React from 'react'
import {jsonFetch} from '../../Components/jsonFetch'
import Button from '../../Components/Button/Button'
import {useQueryInvalidate, useSkilQuery} from '../../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import NewMeetingModal from '../../Components/NewMeetingModal'
import useUser from '../../Utilities/useUser'
import {GroupType} from '../Group'
import {ValueOptions} from '@mui/x-data-grid-premium'

type Props = {
    group: GroupType
    baseLinkUrl: string
    isAdminOrCounselor: boolean
    isKommuneDashboard: boolean
    isSkilUser: boolean
}

export default function Meetings({group, baseLinkUrl, isAdminOrCounselor, isKommuneDashboard, isSkilUser}: Props) {
    const user = useUser()
    const [showNewMeetingModal, setShowNewMeetingModal] = React.useState(false)
    const meetingsQuery = useSkilQuery<'getGroupMeetings'>(`${group['@id']}/meetings`)
    const meetings = meetingsQuery.data?.['hydra:member'] ?? []
    let coursesQuery = useSkilQuery<'getCourseCollection'>(
        isSkilUser ? `/api/courses?usesGroups=true&type[]=0&type[]=1&published=true` : `${user.iri}/courses`
    )
    const meetingOptions = (coursesQuery.data?.['hydra:member'] ?? []).reduce<ValueOptions[]>((carry, course) => {
        return carry.concat(
            course.tasks
                .filter(task => task['@type'] === 'MeetingTask')
                .map(task => ({
                    value: task['@id'],
                    label: `${course.shortTitle}: ${task.title}`,
                }))
        )
    }, [])

    const invalidate = useQueryInvalidate()

    const removeMeeting = async iri => {
        try {
            await jsonFetch(`${iri}`, {
                method: 'delete',
                json: {meeting: iri},
            })

            await invalidate([`${group['@id']}/meetings`])
            toast('Møtet ble fjernet', {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    const onCreateNewMeeting = async meetingData => {
        try {
            await jsonFetch(`/api/groups/${group?.id}/add_meeting`, {
                json: {
                    group: group?.['@id'],
                    when: meetingData.when,
                    task: meetingData.task,
                    location: meetingData.location,
                },
                method: 'POST',
            })
            await invalidate([`${group['@id']}/meetings`])
            toast('Møtet opprettet', {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        } finally {
            setShowNewMeetingModal(false)
        }
    }

    return (
        <>
            <Panel variant={'flat'}>
                <Panel.TitleWithActions title={'Møter'}>
                    {meetingOptions && (
                        <Button
                            size={'sm'}
                            disabled={!(meetingOptions?.length > 0)}
                            onClick={() => setShowNewMeetingModal(!showNewMeetingModal)}
                        >
                            {meetingOptions?.length > 0 && (
                                <div>
                                    <i className='far fa-calendar-plus' /> Nytt møte
                                </div>
                            )}
                            {!(meetingOptions?.length > 0) && (
                                <div>
                                    <i className='fa fa-spinner fa-spin' /> Laster møtevalg...
                                </div>
                            )}
                        </Button>
                    )}
                </Panel.TitleWithActions>
                <Panel.Body>
                    <table className={'table table-striped'} hidden={!meetings}>
                        <thead>
                            <tr className={'subheader'}>
                                <th colSpan={2}>
                                    <span>Tidspunkt</span>
                                </th>
                                <th colSpan={2}>
                                    <span>Tema</span>
                                </th>
                                <th colSpan={3}>
                                    <span>Sted</span>
                                </th>
                                <th colSpan={1}>
                                    <span>Tilstede #</span>
                                </th>
                                <th />
                            </tr>
                        </thead>

                        <tbody>
                            {meetings?.map(m => {
                                const canDeleteMeeting =
                                    m.group &&
                                    (user.voters.isAdminOrCounselor(m.course!['@id']) ||
                                        isSkilUser ||
                                        user.voters.isGroupMember(m.group) ||
                                        isKommuneDashboard)
                                return (
                                    <tr key={m.id}>
                                        <td colSpan={2}>
                                            <span>
                                                {format(new Date(m.time), 'dd.MM.yyyy') + ' kl. ' + format(new Date(m.time), 'HH:mm')}
                                            </span>
                                        </td>
                                        <td colSpan={2} className={'clickable'}>
                                            {m.shortTitle}: {m.name}
                                        </td>
                                        <td colSpan={3}>
                                            <span>{m.location ? m.location : 'Ikke satt'}</span>
                                        </td>
                                        <td colSpan={1}>
                                            <span>
                                                {m.pastMeeting ? m.attendedUsersCount : m.availableUsersCount} / {m.availableUsersCount}
                                            </span>
                                        </td>
                                        <td colSpan={1} style={{display: 'flex', justifyContent: 'end'}}>
                                            {canDeleteMeeting && (
                                                <Button
                                                    inline
                                                    variant={'link'}
                                                    confirm='Bekreft fjerning'
                                                    title={m?.confirmed ? `Møtet kan ikke slettes, deltakerlisten er godkjent` : ``}
                                                    disabled={m?.confirmed}
                                                    onClick={() => removeMeeting(m['@id'])}
                                                >
                                                    Slett
                                                </Button>
                                            )}
                                            {(isAdminOrCounselor ||
                                                isKommuneDashboard ||
                                                isSkilUser ||
                                                user.voters.hasCourse(m.course?.['@id'])) && (
                                                <Button
                                                    inline
                                                    variant={'link'}
                                                    to={`${baseLinkUrl}/${m.id}`}
                                                    style={{textDecoration: 'underline'}}
                                                >
                                                    Detaljer
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Panel.Body>
            </Panel>
            {showNewMeetingModal && (
                <NewMeetingModal
                    meetingOptions={meetingOptions}
                    onSubmit={meetingData => onCreateNewMeeting(meetingData)}
                    onClose={() => setShowNewMeetingModal(false)}
                />
            )}
        </>
    )
}
