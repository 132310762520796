import React, {useEffect, useState} from 'react'
import Modal from '../Components/Modal'
import useEntities from '../Hooks/useEntities'
import SimpleSelectField from '../Components/Fields/SimpleSelectField'
import SelectField from '../Components/Fields/SelectField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {Button} from '../Components/Button/MuiButton'
import {RegionsExport} from '../Components/RegionsExport'
import LoadingComponent from '../Components/LoadingComponent'

export default function NewLocationForm({onSubmit, onClose, regions}) {
    const [region, setRegion] = useState<any>(null)
    const [county, setCounty] = useState<any>(null)
    const [office, setOffice] = useState<any>(null)
    const [district, setDistrict] = useState<any>(null)
    const [officesFilteredByDistrict, setOfficesFilteredByDistrict] = useState<any>([])
    const counties = useEntities<'getCountyCollection'>(region ? `/api/counties?regionId=${region}` : undefined)
    const offices = useEntities<'getCountyOfficeCollection'>(county ? `${county['@id']}/offices?itemsPerPage=5000` : undefined)
    const districts = county?.districts

    useEffect(() => {
        if (district) {
            // filter offices by district
            setOfficesFilteredByDistrict(offices.filter(o => o.district === district['@id']))
        } else {
            setOfficesFilteredByDistrict([])
        }
    }, [district])

    const onSave = () => onSubmit({region: Number(region), county: county?.['@id'], district: district?.['@id'], office: office?.['@id']})

    const onHide = () => {
        setRegion(null)
        setCounty(null)
        setOffice(null)
        onClose()
    }

    if (!regions || regions.length === 0) {
        return <LoadingComponent msg={'Laster inn områder...'} />
    }

    return (
        <Dialog maxWidth='sm' open={true} onClose={onHide}>
            <DialogContent>
                <Typography>
                    <strong>Nytt område</strong>
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <SimpleSelectField
                            id={'region'}
                            size={'lg'}
                            placeholder={'Alle'}
                            required={false}
                            onChange={setRegion}
                            value={region}
                            options={regions}
                            label={'Fylke'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectField
                            id={'county'}
                            // @ts-expect-error
                            entities={counties}
                            value={county}
                            onChange={setCounty}
                            label={'Kommune'}
                            placeholder={'Alle'}
                            required={false}
                        />
                    </Grid>
                    {districts && (
                        <Grid item xs={12}>
                            <SelectField
                                id={'district'}
                                entities={districts}
                                value={district}
                                onChange={setDistrict}
                                label={'Bydel'}
                                placeholder={'Alle'}
                                required={false}
                            />
                        </Grid>
                    )}
                    {officesFilteredByDistrict.length > 0 && (
                        <Grid item xs={12}>
                            <SelectField
                                id={'office'}
                                entities={officesFilteredByDistrict}
                                value={office}
                                onChange={setOffice}
                                label={'Legekontor'}
                                placeholder={'Alle'}
                                required={false}
                            />
                        </Grid>
                    )}
                    {officesFilteredByDistrict.length === 0 && (
                        <Grid item xs={12}>
                            <SelectField
                                id={'office'}
                                // @ts-expect-error
                                entities={offices}
                                value={office}
                                onChange={setOffice}
                                label={'Legekontor'}
                                placeholder={'Alle'}
                                required={false}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={onHide}>
                    Avbryt
                </Button>
                <Button disabled={!region} variant='contained' onClick={onSave}>
                    Lagre
                </Button>
            </DialogActions>
        </Dialog>
    )
}
