import Panel from '../../Components/Panel'
import format from 'date-fns/format'
import * as React from 'react'
import styled from 'styled-components'
import Button from '../../Components/Button/Button'
import FindUserModal, {FindUserModalDTO} from '../../Components/FindUserModal'
import FindOfficeDialog from '../../Components/FindOfficeDialog'
import SendEmailDialog from '../../Components/SendEmailDialog'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {jsonFetch} from '../../Components/jsonFetch'
import {useEffect} from 'react'
import useUser from '../../Utilities/useUser'
import {GroupType, MembersType, MemberType} from '../Group'
import {components} from '../../Generated/eportal'
import {useQueryInvalidate, useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'

const StyledLabelSpan = styled.span`
    margin-left: 0.5em;
    background-color: #d2d6de;
    color: #444;
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
`

const StyledLinkButton = styled(Button)`
    font-size: 90%;
`
type UserType = NonNullable<MembersType[number]['user']>
type OfficeType =
    components['schemas']['Office.jsonld-UserGroup.basic_UserGroup.group_Group.course_UserGroup.isAdmin_UserGroup.user_User.basic_UserGroup.userCourse_UserGroup.shortTitle_User.office_Office.basic_User.userCourses_User.email-validation_UserCourse.basic_UserCourse.course_UserCourse.price_Employee.office_UserCourse.acceptedAt']

type Props = {
    group: GroupType
    userGroupsActive: MembersType
    isKommuneDashboard: any
    isAdminOrCounselor: any
    isGroupCounselor: any
    isSkilUser: any
    userGroupsDeactivated: MembersType
}

export default function GroupMembers({
    group,
    userGroupsActive,
    isKommuneDashboard,
    isAdminOrCounselor,
    isGroupCounselor,
    isSkilUser,
    userGroupsDeactivated,
}: Props) {
    const {voters, iri: userIri} = useUser()
    const {data} = useSkilQuery<'getGroupMeetings'>(`${group['@id']}/meetings`)
    const updateCounselorMutation = useSkilMutation<'changeGroupCounselor'>('POST', `${group['@id']}/changeCounselor`)
    const meetings = data?.['hydra:member'] ?? []
    const [showFindUserModal, setShowFindUserModal] = React.useState(false)
    const [showFindOfficeModal, setShowFindOfficeModal] = React.useState(false)
    const [removingIri, setRemovingIri] = React.useState<string | null>()
    const [recentlyAddedIri, setRecentlyAddedIri] = React.useState<{iri: string}[]>([])
    const futureMeetings = meetings.filter(m => !m.pastMeeting)
    const userHasActiveCourse = voters.hasCourse(group?.course?.['@id'])
    const invalidate = useQueryInvalidate()
    const isCourseAdmin = voters.isCourseAdmin(group.course?.['@id'])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setRecentlyAddedIri([])
        }, 15000)

        return () => clearTimeout(timeout)
    })

    const changeCounselor = async (newCounselor: UserType) => {
        try {
            await updateCounselorMutation.mutateAsync({
                group: group!['@id']!,
                user: newCounselor['@id']!,
            })

            await invalidate([
                group['@id']!,
                `${group['@id']}/filterToOnePerUser/price`,
                `${group['@id']}/filterToOnePerUser`,
                `${group['@id']}/meetings`,
            ])
            toast(`${newCounselor.name} ble satt som veileder`, {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    let invalidateMeetingIris: string[] = []
    meetings.map(meeting => {
        const iri = meeting?.['@id']
        const availableIri = `${iri}/available_users`
        const attendedIri = `${iri}/attended_users`
        invalidateMeetingIris.push(availableIri)
        invalidateMeetingIris.push(attendedIri)
    })

    const removeUserGroup = async (member: MemberType) => {
        try {
            setRemovingIri(member['@id'])
            await jsonFetch(`${member['@id']}/remove`, {
                method: 'POST',
                json: {usergroup: member['@id']},
            })

            invalidate([
                group['@id']!,
                `${group['@id']}/filterToOnePerUser/price`,
                `${group['@id']}/filterToOnePerUser`,
                `${group['@id']}/meetings`,
                ...invalidateMeetingIris,
            ])
                .then(() => {})
                .catch(() => {})

            if (!isSkilUser && !isAdminOrCounselor && member.user!['@id'] === userIri) {
                // If a user or counselor removes themselves,
                // they don't have access to group any longer, lets redirect to dashboard :)
                window.location.href = '/dashboard/'
                return
            }
            toast(`${member.user!.name} ble fjernet fra gruppen`, {type: 'success'})
            setRemovingIri(null)
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    const onDeleteUserGroup = async (member: MemberType) => {
        try {
            await jsonFetch(`${member['@id']}`, {method: 'DELETE'})
            await invalidate([`${group['@id']}/filterToOnePerUser/price`, `${group['@id']}/filterToOnePerUser`, `${group['@id']}/meetings`])
            toast(`${member.user!.name} ble slettet fra gruppen`, {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onCreateNewUserOrAddExisting = async (user: FindUserModalDTO) => {
        try {
            const res = await jsonFetch(`/api/user_groups`, {
                json: {group: group?.['@id'], user: user},
                method: 'POST',
            })
            await invalidate([`${group['@id']}/filterToOnePerUser/price`, `${group['@id']}/filterToOnePerUser`, `${group['@id']}/meetings`])
            // @ts-expect-error api endpoint is not defined
            setRecentlyAddedIri(prev => [...prev, {iri: res['@id']}])
            toast(`${user.name} er blitt lagt til i gruppen og invitert til kurset`, {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        } finally {
            setShowFindUserModal(false)
        }
    }

    const onCreateAddUsersToGroup = async ({multipleEmployees, group}) => {
        try {
            const res = await jsonFetch(`/api/groups/${group.id}/add_multiple_members`, {
                json: {
                    group: group['@id'],
                    users: {...multipleEmployees},
                },
                method: 'POST',
            })
            // @ts-expect-error api endpoint is not defined
            let iris = res.iri.map(i => {
                return {iri: i}
            })
            await invalidate([`${group['@id']}/filterToOnePerUser/price`, `${group['@id']}/filterToOnePerUser`, `${group['@id']}/meetings`])
            // keep this custom feedback from the backend
            // @ts-expect-error api endpoint is not defined
            toast(res.message, {type: 'success'})
            setRecentlyAddedIri(prev => [...prev, ...iris])
        } catch (err) {
            handleErrorWithToast(err)
        } finally {
            setShowFindOfficeModal(false)
        }
    }
    const onSendEmailToUsersInGroup = async ({data}) => {
        try {
            const res = await jsonFetch(`/dashboard/massmailing/send_message_to_group`, {
                method: 'POST',
                json: {content: data.content, title: data.subject, recipients: data.multipleMembers, group: data.group},
            })
            // @ts-expect-error keep this custom feedback from the backend
            toast(res.message, {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    const onInviteUserToCourse = async (course, user) => {
        try {
            await jsonFetch(`${course['@id']}/addMissingCourseToMember`, {
                json: {
                    group: group?.['@id'],
                    course: course['@id'],
                    user: user['@id'],
                },
            })
            await invalidate([`${group['@id']}/filterToOnePerUser/price`, `${group['@id']}/filterToOnePerUser`, `${group['@id']}/meetings`])
            // keep this custom feedback from the backend
            toast(`${user.name} er lagt til kurset`, {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    // make new endpoint based upon the code for path #[Route(path: '/add_member', name: 'group_add_member')]
    const addUserToGroup = async (user: UserType) => {
        try {
            const json: components['schemas']['user_groups.AddMemberDTO.jsonld'] = {
                group: group!['@id']!,
                user: {id: user.id, type: 'skil'},
            }
            await jsonFetch(`/api/user_groups`, {
                json: json,
            })
            await invalidate([`${group['@id']}/filterToOnePerUser/price`, `${group['@id']}/filterToOnePerUser`, `${group['@id']}/meetings`])
            // keep this custom feedback from the backend
            toast(`${user.name} ble lagt til i gruppen og invitert til kurset`, {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    const showPrice = isCourseAdmin || isSkilUser

    return (
        <>
            <Panel variant={'flat'}>
                <Panel.TitleWithActions title={'Gruppemedlemmer'}>
                    <SendEmailDialog group={group} userGroups={userGroupsActive} onSave={data => onSendEmailToUsersInGroup({data})} />
                    <Button size={'sm'} onClick={() => setShowFindOfficeModal(!showFindOfficeModal)}>
                        <i className='far fa-plus' title={'Legg til kontor'} /> Legg til kontor
                    </Button>
                    <Button size={'sm'} onClick={() => setShowFindUserModal(!showFindUserModal)}>
                        <i className='far fa-user-plus' title={'Legg til bruker'} /> Legg til bruker
                    </Button>
                </Panel.TitleWithActions>
                <Panel.Body>
                    <table className={'table table-striped'}>
                        <thead>
                            <tr>
                                <th>
                                    <span>Navn</span>
                                </th>
                                <th>
                                    <span>Kontor</span>
                                </th>
                                {showPrice && (
                                    <th>
                                        <span>Pris</span>
                                    </th>
                                )}
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {userGroupsActive?.map(m => {
                                let color = recentlyAddedIri.find(ra => ra.iri === m['@id']) !== undefined ? 'lightgrey' : undefined
                                const user = m.user!
                                const office = user.employee?.office
                                const userCourses = Object.values(user.userCourses!)

                                // @ts-expect-error userCourse.course is always a string
                                const courseIris: Array<string> = userCourses.map(userCourse => userCourse.course)

                                const missingFutureMeetings = futureMeetings?.filter(
                                    meeting =>
                                        !courseIris.some(course => course === meeting.course!['@id'] || course === group.course?.['@id']) &&
                                        meeting?.course?.['@id'] !== group.course?.['@id']
                                )

                                const userCourse = userCourses.find(userCourse => userCourse.course === group.course?.['@id'])

                                const missingActiveCourse = !userCourses.find(uc => uc?.course === group.course?.['@id'])

                                // @ts-expect-error uc.course is always a string
                                const isAdminForUser = userCourses.some(uc => voters.isCourseAdmin(uc.course) && uc.acceptedAt)
                                const showUserLink = isAdminForUser || isSkilUser
                                return (
                                    <tr key={m.id} style={{background: color}}>
                                        <td>
                                            {showUserLink && (
                                                <a href={`/dashboard/users/${user.id}`}>
                                                    <span style={{textDecoration: 'underline'}}>{user.name}</span>
                                                </a>
                                            )}
                                            {!showUserLink && <span>{user.name}</span>}
                                            {missingActiveCourse && (
                                                <StyledLabelSpan
                                                    title={
                                                        'Brukeren mangler det aktive kurset til gruppen og vil ikke få informasjon om møtene!'
                                                    }
                                                >
                                                    Mangler {group.course!.shortTitle}
                                                    {(userHasActiveCourse ||
                                                        isSkilUser ||
                                                        isAdminOrCounselor ||
                                                        isGroupCounselor ||
                                                        isKommuneDashboard) && (
                                                        <StyledLinkButton
                                                            variant={'link'}
                                                            inline
                                                            onClick={() => onInviteUserToCourse(group.course, user)}
                                                        >
                                                            Inviter til kurs
                                                        </StyledLinkButton>
                                                    )}
                                                </StyledLabelSpan>
                                            )}
                                            {missingFutureMeetings.map(meeting => (
                                                <StyledLabelSpan
                                                    key={meeting.id}
                                                    title={
                                                        'Brukeren mangler ' +
                                                        meeting!.course!.shortTitle +
                                                        ' og vil ikke få informasjon om fremtidige møter for dette kurset!'
                                                    }
                                                >
                                                    Mangler {meeting.course!.shortTitle}
                                                    {(voters.hasCourse(meeting!.course!['@id']) ||
                                                        isSkilUser ||
                                                        isCourseAdmin ||
                                                        isGroupCounselor ||
                                                        isKommuneDashboard) && (
                                                        <StyledLinkButton
                                                            variant={'link'}
                                                            inline
                                                            onClick={() => onInviteUserToCourse(meeting.course!, user)}
                                                        >
                                                            Inviter til kurs
                                                        </StyledLinkButton>
                                                    )}
                                                </StyledLabelSpan>
                                            ))}
                                            {user.invalidEmail && (
                                                <StyledLabelSpan style={{color: 'red'}}>
                                                    {' '}
                                                    UGYLDIG EPOSTADRESSE <i className='fa fa-exclamation-triangle' aria-hidden='true' />
                                                </StyledLabelSpan>
                                            )}
                                            {m.admin && <span className='badge pull-right'>Admin</span>}
                                            {m.counselor && <span className='badge pull-right'>Veileder</span>}
                                            {m.leader && <span className='badge pull-right'>Leder</span>}
                                            {m.contact && <span className='badge pull-right'>Kontakt</span>}
                                        </td>
                                        <td>
                                            {office && showUserLink && (
                                                <Button inline variant={'link'} href={`/dashboard/offices/${office?.id}`}>
                                                    <span style={{textDecoration: 'underline'}}>{office?.name}</span>
                                                </Button>
                                            )}
                                            {office && !showUserLink && <span>{office?.name}</span>}
                                            {!office?.id && <span>Ingen legekontor</span>}
                                        </td>
                                        {showPrice && (
                                            <td>
                                                <span>{userCourse?.price != null ? userCourse.price : 'Ikke satt'}</span>
                                            </td>
                                        )}
                                        <td style={{display: 'flex', justifyContent: 'end'}}>
                                            {removingIri && removingIri === m['@id'] && <i className='fa fa-spinner fa-spin' />}
                                            {removingIri !== m['@id'] && (
                                                <Button
                                                    inline
                                                    variant={'link'}
                                                    confirm='Bekreft fjerning'
                                                    onClick={() => removeUserGroup(m)}
                                                >
                                                    Fjern
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                            {userGroupsDeactivated?.length > 0 && (
                                <tr>
                                    <th>Tidligere medlemmer:</th>
                                    <td />
                                    <td />
                                    {showPrice && <td />}
                                </tr>
                            )}
                            {userGroupsDeactivated?.map(m => {
                                const isCounselor = m.counselor
                                // @ts-expect-error
                                const isAdminForUser = m?.user?.userCourses?.some(uc => voters.isCourseAdmin(uc.course))
                                const showUserLink = isAdminForUser || isSkilUser

                                // @ts-expect-error
                                const office: OfficeType | undefined = m.user?.office
                                return (
                                    <tr key={m.id}>
                                        <td>
                                            {showUserLink && (
                                                <a href={`/dashboard/users/${m.user!.id}`}>
                                                    <span style={{textDecoration: 'underline'}}>{m.user!.name}</span>
                                                </a>
                                            )}
                                            {!showUserLink && <span>{m.user!.name}</span>}
                                            {m?.removedAt && m?.createdAt && (
                                                <span className='small'>
                                                    {' '}
                                                    (
                                                    {format(new Date(m.createdAt), 'dd.MM.yyyy') +
                                                        ' - ' +
                                                        format(new Date(m.removedAt), 'dd.MM.yyyy')}
                                                    )
                                                </span>
                                            )}
                                            {m.counselor && <span className='badge pull-right'>Veileder</span>}
                                            {m.leader && <span className='badge pull-right'>Leder</span>}
                                            {m.contact && <span className='badge pull-right'>Kontakt</span>}
                                        </td>
                                        <td>
                                            {office && showUserLink && (
                                                <a href={`/dashboard/offices/${office?.id}`}>
                                                    <span style={{textDecoration: 'underline'}}>
                                                        {office?.name ? office?.name : 'Ikke satt'}
                                                    </span>
                                                </a>
                                            )}
                                            {office && !showUserLink && <span>{office?.name ? office?.name : 'Ikke satt'}</span>}
                                            {!office && 'Ingen legekontor'}
                                        </td>
                                        {showPrice && <td />}
                                        <td style={{display: 'flex', justifyContent: 'end'}}>
                                            {isCounselor && (isAdminOrCounselor || isSkilUser) && (
                                                <Button
                                                    inline
                                                    variant={'link'}
                                                    confirm='Bekreft veileder'
                                                    onClick={() => changeCounselor(m.user!)}
                                                >
                                                    Sett veileder
                                                </Button>
                                            )}
                                            <Button
                                                inline
                                                variant={'link'}
                                                confirm='Bekreft legg til'
                                                onClick={() => addUserToGroup(m.user!)}
                                            >
                                                Legg til i gruppen
                                            </Button>
                                            <Button
                                                hidden={!isSkilUser}
                                                inline
                                                variant={'link'}
                                                confirm='Bekreft slett'
                                                onClick={() => onDeleteUserGroup(m)}
                                            >
                                                Slett
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Panel.Body>
            </Panel>
            {showFindUserModal && (
                <FindUserModal onSave={user => onCreateNewUserOrAddExisting(user)} onClose={() => setShowFindUserModal(false)} />
            )}
            <FindOfficeDialog
                isOpen={showFindOfficeModal}
                onSave={multipleEmployees => onCreateAddUsersToGroup({multipleEmployees, group})}
                onClose={() => setShowFindOfficeModal(false)}
            />
        </>
    )
}
