/* IMPORTANT NOTE ON BREADCRUMBS and h1!
The h1 element should be used for the title only.
if we want to use the h1 styles for the breadcrumbs,
we should use e.g. a .h1 class to achieve this.
I'll use the h1 for now, though, since we do not have
a separate title on pages with breadcrumbs (as far as I can tell). */

import * as React from 'react'
import {Link} from 'react-router-dom'
import {AtMostOneOf} from '../Utilities/TypeHelpers'

export type BreadcrumbsItem = {title: string; image?: React.ReactNode} & AtMostOneOf<{href: string; to: string}>

export type BreadcrumbsList = BreadcrumbsItem[]

interface BreadcrumbsProps {
    pages: BreadcrumbsList
}

const Breadcrumbs = ({pages}: BreadcrumbsProps) => {
    const breadcrumbItems = pages.map((page, index) => {
        const className = index === 0 ? 'home' : index === pages.length - 1 ? 'current' : ''

        if (!page) return null

        if (page.href) {
            return (
                <li key={index}>
                    <a href={page.href} className={className}>
                        {page.image ?? page.title}
                    </a>
                </li>
            )
        }

        if (page.to) {
            return (
                <li key={index}>
                    <Link to={page.to} className={className}>
                        {page.image ?? page.title}
                    </Link>
                </li>
            )
        }

        return (
            <li key={index}>
                <span className={className}>{page.image ?? page.title}</span>
            </li>
        )
    })

    return (
        <section className='content-header pl-0'>
            <h1>
                <ol className='breadcrumb pl-0 bkg-inherit'>{breadcrumbItems}</ol>
            </h1>
        </section>
    )
}

export default Breadcrumbs
