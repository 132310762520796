import {useDispatch, useSelector} from 'react-redux'
import {useCallback, useEffect} from 'react'
import {fetchEntity} from '../State/actions/entity'
import {cancelIndicatorChanges, cancelIndicatorFieldChange, changeIndicatorField, saveChanges} from '../State/actions/changes'
import {deleteEntity} from '../State/actions/api'
import {Dispatch} from 'redux'
import {SkilQueryResponseType, SkilQueryResponseTypes} from '../Utilities/QueryClient'

type SuccessReturn<T> = T & {'@loaded': true}
type LoadingReturn<T> = {[key in keyof T]: undefined} & {'@loaded': false}

type UseEntityReturnProps<T> = {
    refresh: () => Promise<ReturnType<Dispatch>>
    save: () => Promise<ReturnType<Dispatch>>
    reset: () => Promise<ReturnType<Dispatch>>
    remove: () => Promise<ReturnType<Dispatch>>
    changeField: (field: keyof T, newContent?: any) => (newContent: any) => void
    '@changes': boolean
    '@original': T
    '@deleted': boolean
    '@notFound': boolean
}
export type LoadedUseEntityType<T> = SuccessReturn<T> & UseEntityReturnProps<T>
export type LoadingUseEntityType<T> = LoadingReturn<T> & UseEntityReturnProps<T>
export type UseEntityReturnType<T> = LoadedUseEntityType<T> | LoadingUseEntityType<T>

/**
 * @deprecated use useSkilQuery instead!
 */
export default function useEntity<Top extends keyof SkilQueryResponseTypes>(
    iri?: string | null,
    ownerId?: string,
    ttl?: number | boolean
): UseEntityReturnType<SkilQueryResponseType<Top>> {
    const dispatch = useDispatch()
    // @ts-expect-error
    let entity = useSelector(state => state.entity[iri])
    const changes = useSelector(state => {
        if (ownerId) {
            // @ts-expect-error
            if (state.changes[ownerId] && state.changes[ownerId]['@children']) return state.changes[ownerId]['@children'][iri]

            return {}
        }

        // @ts-expect-error
        return state.changes[iri] || {}
    })

    useEffect(() => {
        if (!entity || !entity['@expiresAt']) return

        const duration = entity['@expiresAt'] - Date.now()
        const timeout = setTimeout(() => {
            dispatch(fetchEntity(iri, true, entity['@ttl']))
        }, duration)

        return () => {
            clearTimeout(timeout)
        }
    }, [entity, iri])

    useEffect(() => {
        if (!entity && iri) dispatch(fetchEntity(iri, false, ttl))
    }, [iri, entity, ttl])

    const onChange = useCallback(
        (...args /*, field, newContent */) => {
            const onChangeField = field => newContent => {
                if (entity && JSON.stringify(newContent) === JSON.stringify(entity[field]))
                    // @ts-expect-error
                    dispatch(cancelIndicatorFieldChange(iri, field, ownerId))
                // @ts-expect-error
                else dispatch(changeIndicatorField(iri, field, newContent, ownerId))
            }

            if (args.length === 1) return onChangeField(args[0])
            else return onChangeField(args[0])(args[1])
        },
        [iri, ownerId, entity]
    )

    return {
        ...entity,
        ...changes,
        refresh: () => dispatch(fetchEntity(iri, true)),
        save: () => dispatch(saveChanges(entity['@id'], changes)),
        reset: () => dispatch(cancelIndicatorChanges(iri)),
        remove: () => dispatch(deleteEntity(iri)),
        changeField: onChange,
        '@changes': Object.values(changes).length > 0 ? changes : null, //TODO: has changes if @children has changes
        '@loaded': entity && entity['@status'] === 'FOUND',
        '@original': entity,
        '@deleted': entity && entity['@status'] === 'DELETED',
        '@notFound': entity && entity['@status'] === 'NOT FOUND',
    }
}
