import * as React from 'react'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import {useTheme} from '@mui/material/styles'

export type DropdownMenuItem = {
    label: string
    action: any //action: (props) => void
}

interface IconButtonWithDropdownMenuProps {
    menuItems: DropdownMenuItem[]
    menuId: string
    buttonIcon?: JSX.Element
    tooltipText?: string
}

export const IconButtonWithDropdownMenu = ({
    buttonIcon = <MoreHoriz />,
    tooltipText = 'Handlinger',
    menuId,
    menuItems,
}: IconButtonWithDropdownMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const theme = useTheme()
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const hasMenuItems = menuItems.length > 0

    return (
        <div>
            <Tooltip title={tooltipText}>
                <IconButton
                    onClick={handleClick}
                    disabled={!hasMenuItems}
                    size='small'
                    aria-controls={open ? menuId : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}>
                    {buttonIcon}
                </IconButton>
            </Tooltip>
            {hasMenuItems && (
                <Menu
                    anchorEl={anchorEl}
                    id={menuId}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        //TODO: can we remove/cleanup the paperProps or move it to theme?
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                background: theme.palette.background.paper,
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                    {menuItems.map(menuItem => (
                        <MenuItem key={menuItem.label} onClick={menuItem.action}>
                            {menuItem.label}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    )
}
