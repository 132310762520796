import * as React from 'react'
import {set} from 'lodash'
import isValid from 'date-fns/isValid'
import {SetState} from '../../Utilities/TypeHelpers'

type DefaultType = Map<string, any>

const CopyAndSet = (obj, path, value) => {
    set(obj, path, value)
    return {...obj}
}

type Response<T> = [
    T,
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    SetState<T>,
    (field: keyof T | string) => (date?: Date | null, text?: string) => void
]

export default function useFieldChange<T = DefaultType>(initialData?: T | (() => T) | {}): Response<T> {
    const [updateFields, setUpdateFields] = React.useState<T>(() =>
        typeof initialData === 'function' ? (initialData as Function)() : initialData
    )

    const onFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let target = event.target as HTMLInputElement
        let value: any = target.value
        const field = target.id ?? target.name

        if (!field) {
            console.error('Could not get field name, tried `event.target.id` and `event.target.name`!', {target: target})
        }

        if (value === undefined) {
            console.warn('Value is `undefined`', {target: target})
        }

        if (target.type === 'checkbox') {
            setUpdateFields(current => CopyAndSet(current, field, Boolean(target.checked)))
        } else if (event.target.type === 'number') {
            setUpdateFields(current => CopyAndSet(current, field, Number(value)))
        } else {
            setUpdateFields(current => CopyAndSet(current, field, value))
        }
    }

    const onChangeDate = (field: keyof T | string) => (date?: Date | null, text?: string) => {
        let value: Date | string | null | undefined = text

        if (date instanceof Date && isValid(date)) {
            value = date
        }

        // Undefined will be lost when sent over the network, enforce NULL instead
        if (value === undefined) {
            value = null
        }

        setUpdateFields(oldFields => CopyAndSet(oldFields, field, value))
    }

    return [updateFields, onFieldChange, setUpdateFields, onChangeDate]
}
