import * as React from 'react'
import {useClickHandler} from '../../../Components/Button/utils'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {Box} from '../../../Components/Panel'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AddIcon from '@mui/icons-material/Add'
import LoadingButton from '@mui/lab/LoadingButton'
import {components} from '../../../Generated/eportal'
import FileField from '../../../Components/Fields/FileField'
import styled from 'styled-components'
import {DeleteIconButton} from '../../../Components/Button/DeleteIconButton'

type StudyType = components['schemas']['Praksisnett.Study.jsonld-PraksisNettStudy.details_PraksisNettStudyAttachment.details_File.view']

interface StudyProps {
    study: StudyType
    refetch: Function
}

const StyledFileField = styled(FileField)`
    margin-bottom: 0;
`

export const StudyAttachmentsPanel = ({study, refetch}: StudyProps) => {
    const [open, setOpen] = React.useState(false)
    const [file, setFile] = React.useState()

    const deleteAttachmentMutation = useSkilMutation<'deletePraksisnettStudyAttachment'>('DELETE', `/api/praksisnett/study/publications`, [
        `/api/praksisnett/studies/${study.id}`,
        `/api/praksisnett/studies`,
    ])

    const onAddAttachment = async () => {
        await FileField.UploadFile(file, 11, `${study['@id']}/upload_attachment`)
        setFile(undefined)
        setOpen(false)
        refetch()
    }
    const onDeleteAttachment = async iri => {
        await deleteAttachmentMutation.mutateAsync({
            '@overridePath': iri,
        })
        refetch()
    }
    const clickHandler = useClickHandler(onAddAttachment)

    return (
        <Box title='Vedlegg' padContents={false}>
            <List>
                {/* uploaded attachments */}
                {study.attachments?.map(attachment => {
                    return (
                        <ListItem
                            sx={{overflowWrap: 'anywhere'}}
                            key={attachment.id}
                            secondaryAction={
                                <DeleteIconButton
                                    deleteAction={() => onDeleteAttachment(attachment['@id'])}
                                    menuId={attachment.id?.toString()}
                                    deleteMenuItemLabel='Slett vedlegg'
                                />
                            }
                        >
                            <ListItemIcon sx={{minWidth: 32}}>
                                <AttachFileIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <a target={'_blank'} href={attachment.file?.['@id'] + '/download'}>
                                        {attachment.file?.filename}
                                    </a>
                                }
                            />
                        </ListItem>
                    )
                })}
                {/* add attachment button */}
                <ListItem>
                    <ListItemButton onClick={() => setOpen(open => !open)}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Legg til vedlegg'} />
                    </ListItemButton>
                </ListItem>
                {/* upload accordion */}
                <ListItem>
                    <Collapse sx={{width: '100%', position: 'relative'}} orientation='vertical' in={open} timeout='auto' unmountOnExit>
                        <StyledFileField label={'Last opp'} value={file} onChange={file => setFile(file)} />
                        <LoadingButton
                            disabled={!file}
                            loading={clickHandler.working}
                            onClick={clickHandler.handleClick}
                            sx={{position: 'absolute', right: 0, bottom: 0}}
                            variant='contained'
                        >
                            Lagre
                        </LoadingButton>
                    </Collapse>
                </ListItem>
            </List>
        </Box>
    )
}
