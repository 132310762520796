import React, {useState, HTMLAttributes} from 'react'
import styled from 'styled-components'
import JoditEditor from './JoditEditor'

const StyledDiv = styled.div`
    width: 100%;
    color: #161616;
    background-clip: padding-box;
    border: 1px solid transparent;
    transition: all 0.15s ease-in-out;
`

type Props = {
    value?: string
    onChange: (value: boolean, event: React.ChangeEvent<HTMLInputElement>) => any
    className?: string
    componentClass?: any
} & HTMLAttributes<'div'>

const TextEditor = ({id, value, onChange, className, ...props}: Props) => {
    const [isFocused, updateFocus] = useState('visible')
    const handleFocus = () => {
        updateFocus('visible')
    }
    const handleBlur = () => {
        updateFocus('hidden')
    }
    if (value === null || typeof value === 'undefined') return null
    return (
        <StyledDiv id={id} {...props} className={className} onFocus={handleFocus} onBlur={handleBlur} isFocused={isFocused}>
            <JoditEditor onChange={onChange} value={value} />
        </StyledDiv>
    )
}
export default TextEditor
