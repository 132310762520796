import * as React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import SpecialAction, {SpecialActionProps} from './SpecialAction'
import {Theme, SxProps} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from 'styled-components'

interface SpecialActionsProps {
    actions: SpecialActionProps[]
    explainerText?: string
}

/* All Special Action styling props are here so they are easy to survey */
const GroupSxProps: SxProps<Theme> = {
    marginTop: 1,
    marginBottom: 2,
    justifyContent: 'flex-end',
    '& > .MuiButtonGroup-grouped:not(:last-of-type)': {
        marginRight: 1,
    },
}
const ButtonSxProps: SxProps<Theme> = {
    width: '100%',
    marginBottom: '0.5em',
}

const ButtonGroupContainer = styled.div`
    display: flex;
    justify-content: end;
    div {
        display: inline-flex;
        flex-flow: ${({matchesMedia}: {matchesMedia: boolean}) => (matchesMedia ? 'inline-flex' : 'column')};
        gap: ${({matchesMedia}: {matchesMedia: boolean}) => (matchesMedia ? '0' : '0.5em')};
        width: ${({matchesMedia}: {matchesMedia: boolean}) => (matchesMedia ? 'fit-content' : '100%')};
    }
`

const SpecialActions = ({actions, explainerText}: SpecialActionsProps) => {
    const bigScreen = useMediaQuery('(min-width:600px)')
    return (
        <ButtonGroupContainer matchesMedia={bigScreen}>
            <ButtonGroup fullWidth sx={GroupSxProps} disableElevation variant='contained' aria-label='special actions button group'>
                {explainerText ? (
                    <div>
                        <div style={{marginRight: '1em'}}>
                            <strong style={{backgroundColor: 'yellow'}}>{explainerText}</strong>
                        </div>
                        {actions.map((actionProps, index) => (
                            <SpecialAction key={index} {...actionProps} sx={ButtonSxProps} />
                        ))}
                    </div>
                ) : (
                    <>
                        {actions.map((actionProps, index) => (
                            <SpecialAction key={index} {...actionProps} sx={ButtonSxProps} />
                        ))}
                    </>
                )}
            </ButtonGroup>
        </ButtonGroupContainer>
    )
}

export default SpecialActions
