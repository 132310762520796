import * as React from 'react'
import {styled} from '@mui/material/styles'
import {Tab as MuiTab} from '@mui/material/'
import {TabProps as MuiTabProps} from '@mui/material/Tab'

interface TabProps extends MuiTabProps {
    // 'attached' is true when the tabs are continuous with the region they control,
    // like physical tabs at the top of a page.
    attached?: boolean
}

const Tab = styled(({attached, ...props}: TabProps) => <MuiTab disableRipple {...props} />)(({theme, attached = true}) => ({
    borderRight: attached ? `1px solid ${theme.palette.grey[300]}` : `none`,
    '&.Mui-selected': {
        borderLeft: attached ? `none` : `1px solid ${theme.palette.grey[300]}`,
    },
}))

export default Tab
