import {Grid, Skeleton} from '@mui/material'
import React from 'react'
import {styled} from '@mui/material/styles'

const OfficeSkeleton = () => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1, mr: 2}} />
            </div>
            <Grid width={'100%'} sx={{mt: 1, mr: 2}}>
                <StyledGrid
                    display={'flex'}
                    width={'100%'}
                    xs={12}
                    sm={12}
                    md={12}
                    item
                    sx={{
                        justifyContent: {xs: 'center', sm: 'center'},
                    }}
                >
                    <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '100%', mr: 2}} />
                </StyledGrid>
            </Grid>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1, mr: 2}} />
            </div>
        </>
    )
}

export default OfficeSkeleton

const StyledGrid = styled(Grid)`
    .MuiCard-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`
