import React from 'react'
import Form from './Form'
import {LoadedUseEntityType} from '../../Hooks/useEntity'
import {UseEntitiesType} from '../../Hooks/useEntities'
import {jsonFetch} from '../../Components/jsonFetch'
import useLocalStorage from '../../Components/useLocalStorage'
import moment from 'moment'
import useUser from '../../Utilities/useUser'
import {SkilQueryResponseType} from '../../Utilities/QueryClient'
import NumberIndicator from './Indicator/NumberIndicator'
import {
    AnswerValueType,
    ApiAnswerSetType,
    AppAnswerSetType,
    AppAnswerType,
    DependencyType,
    FormTaskType,
    IndicatorGroupType,
    IndicatorType,
    NewLocationType,
    QuestionsType,
    UnansweredQuestionsType,
    UnknownIndicatorType,
} from '../Types'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {toast} from 'react-toastify'

export const LocationContext = React.createContext<any>(undefined)

export type FormType = SkilQueryResponseType<'getFormItem'>

export async function saveChanges<T>(iri, indicators, location): Promise<T> {
    return await jsonFetch<T>(iri, {
        method: 'PUT',
        json: {location, indicators},
    })
}

const useLocalAnswers = (indicators, answerSet, formId, taskId = 0) => {
    const user = useUser()

    const localStorageKey = `answerset&form=${formId}&task=${taskId}&user=${user.id}&v=1`

    const [localStorage, setLocalStorage, clearLocalStorage] = useLocalStorage(localStorageKey, {newLocation: undefined, answers: {}})

    function getNewestLocalStorage() {
        const newestLocalStorage = window.localStorage.getItem(localStorageKey)
        if (!newestLocalStorage) {
            return null
        }
        return JSON.parse(newestLocalStorage)
    }

    // fresh means that the answerSet is empty and the localStorage is empty
    const [isFresh, setFresh] = React.useState(answerSet.answers.length === 0 && Object.values(localStorage.answers).length === 0)
    // for the initial render, we want to use the values from the answerSet and from localStorage, because the localStorage might be newer
    const [answersInState, setAnswersInState] = React.useState<AppAnswerSetType>(() => {
        let answerSetNewLocation = answerSet.newLocation!
        let answerSetCounty = answerSetNewLocation.county ? parseInt(answerSetNewLocation.county?.substring(14)) : null // /api/counties/301

        const newLocation: NewLocationType = localStorage?.newLocation?.region
            ? localStorage.newLocation
            : {
                  region: answerSetNewLocation.region,
                  county: answerSetCounty,
                  externalOffice: answerSetNewLocation.externalOffice,
                  office: answerSetNewLocation.office ?? undefined,
                  district: answerSetNewLocation.district ?? undefined,
              }
        const localStorageAnswers = Object.entries(localStorage?.answers ?? {}).map(([indicatorIri, value]) => {
            // @ts-expect-error Enrich answers require a proper indicator object to validate the answer!
            return enrichAnswer({value}, {'@id': indicatorIri}, true)
        })
        // prioritize values from localStorage, if indicator in answerSet is not in localStorage then import from answerSet
        // this is because values in localStorage will be equal to or newer than values in answerSet
        let answersNotInLocalStorage = answerSet.answers
            .filter(a => !localStorageAnswers.some(s => s.indicator === a.indicator))
            .map(answer => {
                const indicator: UnknownIndicatorType = indicators.find(i => i['@id'] === answer.indicator)
                return enrichAnswer({value: answer.value}, indicator, isFresh)
            })
        const combinedAnswers = [...localStorageAnswers, ...answersNotInLocalStorage]
        return {
            answers: combinedAnswers,
            newLocation: newLocation,
            approved: answerSet.approved,
            clearLocalStorage,
        }
    })

    return {
        answers: localStorage.answers,
        newLocation: localStorage.newLocation,
        isFresh,
        setFresh,
        setAnswersInState,
        answersInState,
        localStorage,
        setLocalStorage,
        clearLocalStorage,
        getNewestLocalStorage,
    }
}

type AppProps = {
    dependencies: DependencyType[]
    onChangeLocation?: Function
    answerSet: LoadedUseEntityType<ApiAnswerSetType> // Require a loaded answerSet!
    indicators: UseEntitiesType<UnknownIndicatorType>
    groups: UseEntitiesType<IndicatorGroupType>
    isPreview: boolean
    form: FormType
    title: string
    task?: LoadedUseEntityType<FormTaskType>

    onSubmit: (answers: {[indicatorIri: string]: AnswerValueType}, newLocation?: NewLocationType) => Promise<AppAnswerSetType>
    /**
     * Return Indicator IRIs for values stored on server
     */
    onChange?: (answers: {[indicatorIri: string]: AnswerValueType}, save: boolean, newLocation: NewLocationType) => Promise<Array<string>>
}
export const App = ({
    dependencies,
    onChangeLocation,
    answerSet,
    indicators,
    groups,
    isPreview = false,
    form,
    task,
    onSubmit,
    onChange,
    title,
}: AppProps) => {
    const {isFresh, setFresh, localStorage, setLocalStorage, answersInState, setAnswersInState, clearLocalStorage, getNewestLocalStorage} =
        useLocalAnswers(indicators, answerSet, form.id, task?.id ?? 0)
    const [answerSetSubmitting, setAnswerSetSubmitting] = React.useState(false)
    const setLocalLocation = newLocation => {
        setLocalStorage(prev => ({...prev, newLocation}))
        setAnswersInState(prev => ({...prev, newLocation}))

        if (onChangeLocation) {
            return onChangeLocation(newLocation)
        }
    }

    const onLocalSubmit = async () => {
        try {
            setAnswerSetSubmitting(true)

            const indicators = Object.values(answersInState.answers).reduce((carry, answer: AppAnswerType) => {
                if (!answer.indicator) {
                    return carry
                }
                let indicator = answer['indicator']
                if (typeof indicator !== 'string') indicator = indicator?.['@id']
                carry[indicator] = answer['value']
                return carry
            }, {})
            const results = await onSubmit(indicators, answersInState.newLocation)
            if (results.approved) {
                clearLocalStorage()
                setFresh(false)
                setAnswersInState(results)
            }
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            setAnswerSetSubmitting(false)
        }
    }
    const onLocalChange = async (indicator: IndicatorType, answer: any, save: boolean) => {
        // make sure to save every change in the state
        setAnswersInState(prevAnswerSet => {
            let answers = answersInState.answers.filter(item => item.indicator !== indicator['@id'])
            const newAnswer = enrichAnswer({value: answer}, indicator, false)
            answers.push(newAnswer)

            return {...prevAnswerSet, answers}
        })
        if (!save) {
            return
        }

        // make sure to save the change in the localStorage only if save is true
        // save will be false when the user is typing in the input field
        if (save) {
            setLocalStorage(prev => ({...prev, answers: {...localStorage.answers, [indicator['@id']!]: answer}}))
        }
        if (!onChange) {
            return
        }

        // Indicator answers to save:
        let indicatorAnswers = {...localStorage.answers, [indicator['@id']!]: answer}
        await onChange(indicatorAnswers, save, answersInState.newLocation).then(res => {
            if (res.length === 0) {
                return
            }
            setLocalStorage(prev => {
                // need to get the newest localStorage again because it might have changed since onChange was called
                const newestLocalStorage = getNewestLocalStorage()
                const filteredAnswers = Object.fromEntries(
                    Object.entries(newestLocalStorage.answers).filter(([key]) => {
                        return !res.some(iri => key === iri)
                    })
                )
                return {...prev, answers: filteredAnswers}
            })
        })
    }
    const answers = Object.values(answersInState.answers ?? {}).reduce((carry, answer) => {
        // TODO: What happens if we dont find a matching indicator?
        let indicator: IndicatorType | undefined = indicators.find(i => i['@id'] === answer.indicator)
        if (indicator) {
            carry[answer.indicator!] = enrichAnswer({value: answer.value!}, indicator, isFresh)
        }
        return carry
    }, {})
    const questions = CalculateQuestions({answers, indicators, groups, dependencies})
    const newLocation = answersInState.newLocation as NewLocationType
    const unansweredQuestions = CalculateUnansweredQuestions({questions, answers, newLocation})
    return (
        <LocationContext.Provider value={{setLocation: setLocalLocation, answersInState, localStorage}}>
            <div className='col-xs-12 col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1'>
                <div className='box box-primary'>
                    <div className='box-header with-border'>
                        <h3 className='box-title'>{title}:</h3>
                    </div>
                    <div className='box-body'>
                        <Form
                            task={task}
                            questions={questions}
                            answerSet={answersInState}
                            unansweredQuestions={unansweredQuestions}
                            answerSetSubmitting={answerSetSubmitting}
                            fresh={isFresh}
                            onSubmit={onLocalSubmit}
                            onChange={(indicator, value, save) => onLocalChange(indicator, value, save)}
                            isPreview={isPreview}
                        />
                    </div>
                </div>
            </div>
        </LocationContext.Provider>
    )
}

export const enrichAnswer = (answer: {value: any}, indicator: IndicatorType, fresh: boolean): AppAnswerType => {
    // if the indicator does not have a subType then the answer comes from the localStorage and we presume that the answer is valid
    const valid = isAnswerValid(indicator, answer['value']) || indicator?.['optional'] || !indicator?.['subType']
    const localFresh = fresh && (typeof answer.value === 'undefined' || answer.value === 'null')

    return {
        id: 0,
        reportedAt: '',
        ...answer,
        indicator: indicator?.['@id'],
        fresh: localFresh,
        valid,
    }
}

const isAnswerValid = (indicator: IndicatorType, value: any): boolean => {
    switch (indicator?.['subType']) {
        case 'CheckboxIndicator':
            return true // true or false, can't be invalid
        case 'ChoiceIndicator':
            return !!String(value)
        case 'MultichoiceIndicator':
            return !!String(value)
        case 'LocationIndicator':
            return !!Array(value)
        case 'CounselorEmailIndicator':
            return !!String(value)
        case 'DateTimeIndicator':
            return moment(value).isValid()
        case 'NumberIndicator':
            const i = Number(value)
            return i >= indicator['minimum'] && i <= indicator['maximum']
        case 'TextIndicator':
            return !!String(value)
        case 'InformationIndicator':
            return true
        case 'PreviousAnswerIndicator':
            return true
        case 'SeminarThemeIndicator':
            return !!String(value)
    }

    console.warn("We don't know the indicator type?!", indicator, value)
    // @ts-expect-error
    return undefined //TODO: Is this correct? Should it be false when we dont know the answer type?
}
const DEP_UNKNOWN = 0
const DEP_MET = 1
const DEP_NOT_MET = -1
const MAX_DEPTH = 1000

type CalculateQuestionsProps = {
    answers: {[groupIri: string]: AppAnswerType}
    indicators: UseEntitiesType<IndicatorType>
    groups: IndicatorGroupType[]
    dependencies: DependencyType[]
}
const CalculateQuestions = ({answers, indicators, groups, dependencies}: CalculateQuestionsProps): QuestionsType => {
    if (!answers || !indicators || !dependencies || !groups) return {}
    return Object.entries(indicators).reduce((carry, [key, indicator]) => {
        // indicator might be @loaded/create,remove ir refresh function from useEntities
        if (typeof indicator !== 'object') return carry

        const depsMet = isDependenciesMet(indicator, answers, indicators, dependencies)
        if (depsMet === DEP_MET || depsMet !== DEP_NOT_MET) {
            const groupId: string | null | undefined = indicator.group
            if (groupId && typeof carry[groupId] === 'undefined') {
                const group = groups.find(g => g['@id'] === groupId)
                carry[groupId] = {group, indicators: []}
            }

            if (groupId) {
                carry[groupId].indicators.push(indicator)
            }
        }

        return carry
    }, {})
}

type UnansweredQuestionsProps = {
    answers: {[iri: string]: AppAnswerType}
    questions: QuestionsType
    newLocation: NewLocationType | undefined
}
const CalculateUnansweredQuestions = ({answers, questions, newLocation}: UnansweredQuestionsProps): UnansweredQuestionsType =>
    Object.values(questions)
        .map(({group, indicators}) => ({
            group,
            indicators: indicators.filter(i => {
                if (i['subType'] === 'InformationIndicator') return false

                if (i['optional']) return false

                const answer = answers?.[i['@id']!] ?? {}
                let validAnswer: boolean
                // if the indicator is a locationIndicator then we need to check if the answer is valid by checking the answer according to the locationLevel
                // TODO: in the future we may want to add a check for the district level
                if (i['subType'] === 'LocationIndicator') {
                    // get the newLocation from the answerSet of check if the answer is valid
                    if (i['locationLevel']) {
                        // locationLevel country
                        if (i['locationLevel'] === '0') {
                            validAnswer = !!(newLocation?.country && newLocation?.country > 0)
                            // locationLevel region
                        } else if (i['locationLevel'] === '1') {
                            validAnswer = !!(newLocation?.region && newLocation?.region > 0)
                            // locationLevel county
                        } else if (i['locationLevel'] === '2') {
                            validAnswer = !!(newLocation?.county && parseInt(newLocation?.county) > 0)
                            // locationLevel office
                        } else if (i['locationLevel'] === '3') {
                            validAnswer = !!(newLocation?.office && parseInt(newLocation?.office) > 0)
                        } else {
                            validAnswer = false
                        }
                    } else {
                        validAnswer = false
                    }
                } else if (i['subType'] === 'PreviousAnswerIndicator') {
                    validAnswer = true
                } else {
                    validAnswer = !!(answer && answer.valid)
                }

                return !validAnswer
            }),
        }))
        .filter(({indicators}) => indicators.length > 0)

export const isDependenciesMet = (
    indicator: IndicatorType,
    answers: {[iri: string]: AppAnswerType},
    indicators: IndicatorType[],
    dependencies: DependencyType[],
    depth: number = 0
) => {
    //TODO: Fix me!!!

    if (depth > MAX_DEPTH) {
        // MAX_DEPTH is a constant that you define
        toast('En feil skjedde. Ta kontrakt med teknisk@skilnet.no og oppgi feilkode: Arbeidsark_Recursive_Dependency_1001', {
            type: 'error',
        })
        return DEP_UNKNOWN
    }

    let ret = DEP_UNKNOWN
    for (const id of Object.values(indicator.dependencies ?? {})) {
        const dep = dependencies.find(d => d['@id'] === id)
        // @ts-expect-error
        const depInd = indicators.find(i => i['@id'] === dep.dependent)
        // @ts-expect-error
        const {method, value: targetValue} = dep
        // @ts-expect-error
        const answer = answers[depInd['@id']]
        const hasAnswer = !!answer
        const {value, valid} = answer || {}
        // @ts-expect-error
        const isDisabled = depInd['disabled']
        // @ts-expect-error
        const depIndType = depInd['subType']
        // @ts-expect-error
        const dependenciesMet = isDependenciesMet(depInd, answers, indicators, dependencies, true, depth + 1)
        if (dependenciesMet === DEP_NOT_MET) {
            return DEP_NOT_MET
        }

        if (isDisabled) {
            ret = DEP_UNKNOWN
            continue
        }

        if (!hasAnswer) {
            ret = DEP_NOT_MET
            continue
        }

        if (!valid) return DEP_NOT_MET

        const dependencyValid = validateDependency(value, method, targetValue, depIndType)
        if (!dependencyValid) return DEP_NOT_MET
    }
    return ret
}

const validateDependency = (value, method, targetValue, type) => {
    const DEPENDS_EQUAL = 0
    const DEPENDS_NOT_EQUAL = 10
    const DEPENDS_GREATER_THAN = 1
    const DEPENDS_GREATER_THAN_OR_EQUAL = 2
    const DEPENDS_LESS_THAN = -1
    const DEPENDS_LESS_THAN_OR_EQUAL = -2
    const DEPENDS_EQUAL_CHOICE = 11
    const DEPENDS_NOT_EQUAL_CHOICE = 12
    const DEPENDS_INCLUDES = 13
    const DEPENDS_INCLUDES_NOT = 14

    if (type === 'DateTimeIndicator') {
        value = moment(value).unix()
        targetValue = moment(targetValue, 'DD.MM.YYYY HH:mm').unix()
    } else if (type === 'CheckboxIndicator') {
        targetValue = targetValue === 'yes'
    } else if (type === 'NumberIndicator') {
        targetValue = parseFloat(targetValue)
        value = parseFloat(value)
    }

    switch (Number(method)) {
        case DEPENDS_EQUAL:
            return value === targetValue
        case DEPENDS_NOT_EQUAL:
            return value !== targetValue
        case DEPENDS_GREATER_THAN:
            return value > targetValue
        case DEPENDS_GREATER_THAN_OR_EQUAL:
            return value >= targetValue
        case DEPENDS_LESS_THAN:
            return value < targetValue
        case DEPENDS_LESS_THAN_OR_EQUAL:
            return value <= targetValue
        case DEPENDS_EQUAL_CHOICE:
            return value === targetValue
        case DEPENDS_NOT_EQUAL_CHOICE:
            return value !== targetValue
        case DEPENDS_INCLUDES:
            return value.includes(targetValue)
        case DEPENDS_INCLUDES_NOT:
            return !value.includes(targetValue)
        default:
            console.warn('Unknown method!', method)
    }
}
