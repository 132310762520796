import React, {ReactNode} from 'react'
import {motion, AnimatePresence} from 'framer-motion'

type Props = {
    expanded: boolean
    as?: string
    className?: string
    children: ReactNode
}

export default function Collapse({expanded, as = 'section', className = undefined, ...props}: Props) {
    const Component = motion[as]

    if (!Component) console.error(`Collapse must have a valid motion element. "${as}" is not allowed!`, Object.keys(motion))

    return (
        <AnimatePresence initial={false}>
            {expanded && (
                <Component
                    key='content'
                    initial='collapsed'
                    animate='open'
                    exit='collapsed'
                    className={className}
                    variants={{
                        open: {opacity: 1, height: 'auto'},
                        collapsed: {opacity: 0, height: 0},
                    }}
                    transition={{duration: 0.2}}
                >
                    {props.children}
                </Component>
            )}
        </AnimatePresence>
    )
}
