import React, {useRef, useState} from 'react'

import Dropzone, {DropzoneRef} from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import styled from 'styled-components'

import Modal from './Modal'
import Button from './Button/Button'

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, image/ico, image/x-icon, image/vnd.microsoft.icon'
const acceptedFileTypesArray = acceptedFileTypes.split(',').map(item => {
    return item.trim()
})

const StyledDropZone = styled.div`
    width: 100%;
    height: 15em;
    border: dotted;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
`

type ConfigProp = {
    imgSrc: string | null | ArrayBuffer
    crop: {}
    type: string
    filename: string
}

export default function ImageDropZoneModal({minAspectRatio, maxAspectRatio, onSave, onCancel, uploadUrl = '/api/files'}) {
    const [{imgSrc, crop, type, filename}, setConfig] = useState<ConfigProp>({
        imgSrc: null,
        crop: {},
        type: 'image/png',
        filename: 'image.png',
    })
    const imageRef = useRef()
    const dropzoneRef = useRef<DropzoneRef>()

    const onLocalChange = async () => {
        const url = await uploadFile()
        await onSave(url)
    }
    const onLocalCancel = () => {
        onCancel()
    }

    const uploadFile = async () => {
        if (!imgSrc) throw new Error('Invalid file to upload!')

        let image = await getCroppedImg(imageRef, crop, type)

        const fd = new FormData()
        fd.append('upload', image, Date.now() / 1000 + '_' + filename)

        const {id, ...rest} = await fetch(uploadUrl, {
            method: 'POST',
            body: fd,
            headers: {Accept: 'application/json'},
        }).then(res => res.json())

        if (!id) throw new Error('Error, f')

        let url = `${window.location.protocol}//${window.location.host}/api/files/${id}/download`
        console.log('Uploaded image', url, {id, ...rest})
        return url
    }

    const handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            if (verifyFile(files)) {
                const currentFile = files[0]
                const reader = new FileReader()
                reader.addEventListener('load', () =>
                    setConfig({
                        crop: {},
                        imgSrc: reader.result,
                        type: currentFile.type,
                        filename: currentFile.name,
                    })
                )
                reader.readAsDataURL(currentFile)
            }
        }
    }

    const handleOnCropChange = oldCrop => {
        const crop = {...oldCrop}
        const currentAr = oldCrop.width / oldCrop.height
        if (currentAr > maxAspectRatio) {
            crop.height = oldCrop.width / maxAspectRatio

            // Stop the crop for going higher than the image
            // @ts-expect-error no idea what imageRef should be!
            if (crop.y + crop.height > imageRef.current.height) {
                return
            }
        } else if (currentAr < minAspectRatio) {
            crop.height = oldCrop.width / minAspectRatio

            // @ts-expect-error no idea what imageRef should be!
            if (crop.y + crop.height > imageRef.current.height) {
                return
            }
        }

        setConfig(old => ({...old, crop}))
    }

    const handleOnClick = () => {
        dropzoneRef.current?.open()
    }

    return (
        <Modal
            onClose={onLocalCancel}
            onSave={onLocalChange}
            title='Velg bilde'
            footerComponents={<Button onClick={handleOnClick}>Velg bilde</Button>}
        >
            <div>
                <i className={'fa fa-info-circle fa-2x pull-right'} title='Klikk og dra for å beskjære bildet.' />
                Klikk og dra for å beskjære bildet.
                <div hidden={!imgSrc}>
                    <ReactCrop
                        onImageLoaded={img => (imageRef.current = img)}
                        src={imgSrc}
                        crop={crop}
                        onComplete={handleOnCropChange}
                        onChange={handleOnCropChange}
                    />
                    <br />
                </div>
                {/* @ts-expect-error dropzoneRef is wrong type? */}
                <Dropzone ref={dropzoneRef} onDrop={handleOnDrop} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize}>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div hidden={!!imgSrc} {...getRootProps()} className={'dropzone' + (isDragActive ? ' dropzone--isActive' : '')}>
                                <input id={'dropzone'} {...getInputProps()} />
                                <StyledDropZone>
                                    Slipp en bildefil her, eller klikk for å velge bildefilen som skal lastes opp.
                                </StyledDropZone>
                            </div>
                        )
                    }}
                </Dropzone>
            </div>
        </Modal>
    )
}

const verifyFile = files => {
    if (files && files.length > 0) {
        const currentFile = files[0]
        const currentFileType = currentFile.type
        const currentFileSize = currentFile.size
        if (currentFileSize > imageMaxSize) {
            alert('Denne filen er ' + currentFileSize + ' bytes. Maksimal filstørrelse tillatt er ' + imageMaxSize + ' bytes!')
            return false
        }
        if (!acceptedFileTypesArray.includes(currentFileType)) {
            alert('Kun bilde tillatt!')
            return false
        }
        return true
    }
}

async function getCroppedImg(imgRef, crop, type): Promise<Blob> {
    const image = imgRef.current

    const canvas = document.createElement('canvas')

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    if (!ctx) {
        return Promise.reject(new Error('Invalid canvas!'))
    }

    if (!crop.width || !crop.height) {
        console.log('NO CROP', crop, {width: image.width, height: image.height})
        crop.width = image.width
        crop.height = image.height
    } else {
        console.log('cropped', crop, {width: image.width, height: image.height})
    }

    canvas.width = crop.width * scaleX
    canvas.height = crop.height * scaleY

    ctx.setTransform(scaleX, 0, 0, scaleY, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

    // As Base64 string
    //return canvas.toDataURL('image/jpeg');

    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
            blob => {
                if (blob === null) reject(new Error('No blob returned!'))

                // @ts-expect-error Why is `blob` Blob|null here?
                resolve(blob)
            },
            type,
            1
        )
    })
}
