import * as React from 'react'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {GridColDef, GridSelectionModel} from '@mui/x-data-grid-premium'
import AddIcon from '@mui/icons-material/Add'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import officeColumnDef from '../components/officeColumnDef'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import Button from '../../Components/Button/Button'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from '../constants/routes'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import CircularProgress from '@mui/material/CircularProgress'
import {SNOWBOX_EXTRACTION_OPTIONS, SNOWBOX_SKY_OPTIONS, SNOWBOX_TEST_OPTIONS} from './Offices'
import {SendMessageDialog} from '../components/SendMessageDialog'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FindDoctorModal, {FindDoctorModalDto} from '../components/FindDoctorModal'
import {DoctorsAddToStudyDialog} from '../DetailsViews/Doctor/DoctorsAddToStudyDialog'

const renderCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return (
        <Button variant='text' to={`/dashboard/praksisnett/doctors/${params.row.id}`}>
            {params.value}
        </Button>
    )
}
const columnVisibilityModel = {
    id: false,
    accountNr: false,
    acknowledgeOfficeMismatch: false,
    comment: true,
    verifiedEmail: false,
    'office.signedContract': false,
    'office.id': false,
    'office.region': false,
    'office.orgNr': false,
    'office.address': false,
    'office.county.name': false,
    'office.county.id': false,
    'office.snowbox.journal': false,
    'office.snowbox.deployedAt': false,
    'office.snowbox.online': false,
    'office.snowbox.drcInstalled': false,
    'snowbox.extraction': false,
    'snowbox.test': false,
    'snowbox.sky': false,
    'office.snowbox.cancelledAt': false,
    'office.snowbox.cancelledReason': false,
    'office.snowbox.location': false,
    'office.snowbox.status': false,
    'office.snowbox.invitationAvailable': false,
    'office.snowbox.notes': false,
    'helfoDoctor.id': false,
    'helfoDoctor.authorizedAt': false,
    'helfoDoctor.age': false,
    'helfoDoctor.male': false,
    'helfoDoctor.listSize': false,
    'helfoDoctor.listFree': false,
}

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'System-ID', type: 'number', renderCell}),
    columnDef({field: 'name', headerName: 'Navn', type: 'string', renderCell}),
    columnDef({field: 'email', headerName: 'Epost', type: 'string', flex: 2}),
    columnDef({field: 'mobile', headerName: 'Telefon', type: 'string', flex: 0.75}),
    columnDef({field: 'hpr', headerName: 'HPR', type: 'string', flex: 0.75}),
    columnDef({field: 'external', headerName: 'Sluttet', type: 'boolean', flex: 0.5}),
    columnDef({field: 'region', headerName: 'Region', type: 'string', flex: 0.5}),
    columnDef({field: 'signedDigitally', headerName: 'Spesialist i allmennmedisin', type: 'boolean'}),
    columnDef({field: 'accountNr', headerName: 'Kontonr.', type: 'string'}),
    columnDef({field: 'acknowledgeOfficeMismatch', headerName: 'Feil kontor godkjent', type: 'boolean'}),
    columnDef({field: 'comment', headerName: 'Kommentar', type: 'string'}),
    columnDef({field: 'verifiedEmail', headerName: 'Godkjent epost', type: 'boolean'}),
    officeColumnDef({field: 'office'}),
    columnDef({field: 'office.signedContract', headerName: 'Legekontor Kontrakt signert', type: 'boolean'}),
    columnDef({field: 'office.id', headerName: 'Legekontor ID', type: 'string'}),
    columnDef({field: 'office.region', headerName: 'Legekontor Region', type: 'string'}),
    columnDef({field: 'office.orgNr', headerName: 'Legekontor Orgnr.', type: 'string'}),
    columnDef({field: 'office.address', headerName: 'Legekontor Adresse', type: 'string'}),
    columnDef({field: 'office.county.name', headerName: 'Legekontor Kommune', type: 'string'}),
    columnDef({field: 'office.county.id', headerName: 'Legekontor Kommunenr', type: 'number'}),

    columnDef({field: 'office.snowbox.journal', headerName: 'Snowbox Journal', type: 'string'}),
    dateColumnDef({field: 'office.snowbox.deployedAt', headerName: 'Snowbox Utplassert', type: 'dateTime'}),
    columnDef({field: 'office.snowbox.online', headerName: 'Snowbox Online', type: 'boolean'}),
    columnDef({field: 'office.snowbox.drcInstalled', headerName: 'Snowbox DRC Innstallert', type: 'boolean'}),
    selectColumnDef({field: 'snowbox.extraction', headerName: 'Snowbox Uttrekk', valueOptions: SNOWBOX_EXTRACTION_OPTIONS}),
    selectColumnDef({field: 'snowbox.test', headerName: 'Snowbox Test', valueOptions: SNOWBOX_TEST_OPTIONS}),
    selectColumnDef({field: 'snowbox.sky', headerName: 'Snowbox Sky', valueOptions: SNOWBOX_SKY_OPTIONS}),
    dateColumnDef({field: 'office.snowbox.cancelledAt', headerName: 'Snowbox Avlyst', type: 'dateTime'}),
    columnDef({field: 'office.snowbox.cancelledReason', headerName: 'Snowbox Avlyst grunn', type: 'string'}),
    columnDef({field: 'office.snowbox.location', headerName: 'Snowbox Plassering', type: 'string'}),
    columnDef({field: 'office.snowbox.status', headerName: 'Snowbox Status', type: 'string'}),
    columnDef({field: 'office.snowbox.invitationAvailable', headerName: 'Snowbox Tilgjengelig Invitasjon', type: 'boolean'}),
    columnDef({field: 'office.snowbox.notes', headerName: 'Snowbox Notater', type: 'string'}),

    columnDef({field: 'helfoDoctor.id', headerName: 'Helfo ID', type: 'number'}),
    columnDef({field: 'helfoDoctor.authorizedAt', headerName: 'Helfo Autorisert', type: 'string'}),
    columnDef({field: 'helfoDoctor.age', headerName: 'Helfo Alder', type: 'number'}),
    columnDef({field: 'helfoDoctor.male', headerName: 'Helfo Mann', type: 'boolean'}),
    columnDef({field: 'helfoDoctor.listSize', headerName: 'Helfo Listestørrelse', type: 'number'}),
    columnDef({field: 'helfoDoctor.listFree', headerName: 'Helfo Ledig plass', type: 'number'}),
]

const formattedColumns: GridColDef[] = columns.map(column => ({headerAlign: 'left', ...column}))

export const Doctors = () => {
    const [showAddUser, setShowAddUser] = React.useState(false)
    const [showSendText, setShowText] = React.useState(false)
    const [showSendEmail, setShowEmail] = React.useState(false)
    const [showAddToStudy, setShowAddToStudy] = React.useState(false)
    const [selectedDoctors, setSelectedDoctors] = React.useState<GridSelectionModel>([])
    const doctorsResponse = useSkilQuery<'getPraksisnettDoctorCollection'>('/api/praksisnett/doctors', {
        pagination: false,
    })
    const navigate = useNavigate()
    const addDoctorMutation = useSkilMutation<'createPraksisnettDoctor'>('POST', `/api/praksisnett/doctors`, [`/api/praksisnett/doctors`])
    const doctors = doctorsResponse.data?.['hydra:member'] ?? []

    const onAddDoctor = async (user: FindDoctorModalDto) => {
        const response = await addDoctorMutation.mutateAsync(user)
        navigate(`${ROUTES.DOCTORS}/${response.id}`)
    }

    const doctorActions: SpecialActionProps[] = [
        {
            label: 'Legg til lege',
            action: () => setShowAddUser(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: 'Send SMS',
            action: () => setShowText(true),
            disabled: selectedDoctors.length === 0,
            icon: {
                position: 'start',
                icon: <PhoneIphoneOutlinedIcon />,
            },
        },
        {
            label: 'Send e-post',
            action: () => setShowEmail(true),
            disabled: selectedDoctors.length === 0,
            icon: {
                position: 'start',
                icon: <EmailOutlinedIcon />,
            },
        },
        {
            label: 'Legg til i studie',
            action: () => setShowAddToStudy(true),
            disabled: selectedDoctors.length === 0,
            icon: {
                position: 'start',
                icon: <AssessmentOutlinedIcon />,
            },
        },
    ]

    const onChangeSelectedDoctors = (event, value) => {
        setSelectedDoctors(value.map(v => v.id))
    }
    const currentSelection = doctors.filter(i => selectedDoctors.includes(Number(i.id)))
    const doctorIris = doctors.filter(i => selectedDoctors.includes(Number(i.id))).map(d => d['@id'] as string)

    return (
        <>
            {doctorsResponse.isLoading && !doctors ? (
                <CircularProgress />
            ) : (
                <DataGridWrapper rows={doctors.length} addHeight={8.5}>
                    <DataGrid
                        id={'doctors'}
                        checkboxSelection
                        disableSelectionOnClick
                        loading={doctorsResponse.isFetching}
                        rows={doctors}
                        columns={formattedColumns}
                        initModel={{columnVisibilityModel}}
                        onSelectionModelChange={newSelectedDoctors => {
                            setSelectedDoctors(newSelectedDoctors)
                        }}
                        selectionModel={selectedDoctors}
                        toolbarButtons={<SpecialActions actions={doctorActions} />}
                    />
                </DataGridWrapper>
            )}
            {showAddUser && <FindDoctorModal onSave={onAddDoctor} onClose={() => setShowAddUser(false)} />}

            <SendMessageDialog isOpen={showSendEmail} onClose={() => setShowEmail(false)} doctorIris={doctorIris} isEmail>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={doctors}
                    value={currentSelection}
                    limitTags={5}
                    onChange={onChangeSelectedDoctors}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>

            <SendMessageDialog isOpen={showSendText} onClose={() => setShowText(false)} doctorIris={doctorIris} isText>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={doctors}
                    value={currentSelection}
                    limitTags={5}
                    onChange={onChangeSelectedDoctors}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>
            <DoctorsAddToStudyDialog
                isOpen={showAddToStudy}
                onClose={() => setShowAddToStudy(false)}
                doctorIris={doctorIris}
                setSelected={setSelectedDoctors}
            />
        </>
    )
}
