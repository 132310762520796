import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {Button} from '../../../Components/Button/MuiButton'
import * as React from 'react'
import {SetState} from '../../../Utilities/TypeHelpers'
import DataGrid from '../../../Components/DataGrid/DataGrid'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {GridColumns} from '@mui/x-data-grid-premium'
import columnDef from '../../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../../Components/DataGrid/columnDefs/dateColumnDef'
import {components} from '../../../Generated/eportal'
import {ViewHeightWrapper} from '../../../Components/DataGrid/Wrappers'
import MessageIcon from '@mui/icons-material/Message'
import EmailIcon from '@mui/icons-material/Email'
import selectColumnDef from '../../../Components/DataGrid/columnDefs/selectColumnDef'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

type DoctorType = components['schemas']['Praksisnett.Doctor.jsonld-PraksisNettDoctor.details_PraksisNettOffice.details_Office.basic']

interface DoctorMessagesDialogProps {
    setOpen: SetState<boolean>
    isOpen: boolean
    doctor: DoctorType
}

const MESSAGE_TYPES = [
    {label: 'SMS', value: 'sms'},
    {label: 'Epost', value: 'email'},
]

export const DoctorMessagesDialog = ({setOpen, doctor, isOpen = false}: DoctorMessagesDialogProps) => {
    const [showMessage, setShowMessage] = React.useState<{[key: string]: any}>()
    const {data: messages} = useSkilQuery<'listPraksisnettDoctorMessages'>(`/api/praksisnett/doctors/${doctor.id}/list_messages`, {
        pagination: false,
    })

    const handleDialogClose = () => {
        setOpen(false)
    }

    const columns: GridColumns = [
        selectColumnDef({
            field: 'type',
            headerName: 'Type',
            valueOptions: MESSAGE_TYPES,
            renderCell: params => (params.value === 'sms' ? <MessageIcon /> : <EmailIcon />),
            maxWidth: 75,
        }),
        dateColumnDef({field: 'createdAt', headerName: 'Opprettet', type: 'dateTime', maxWidth: 150}),
        dateColumnDef({field: 'deliveredAt', headerName: 'Sendt', type: 'dateTime', maxWidth: 150}),
        dateColumnDef({field: 'readAt', headerName: 'Lest', type: 'dateTime', maxWidth: 150}),
        columnDef({
            field: 'content',
            headerName: 'Melding',
            valueGetter: params => (params.row['type'] === 'sms' ? params.row['content'] : params.row['title']),
        }),
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Handlinger',

            getActions: params => {
                return [
                    <Tooltip key={`view${params.id}`} title={'Vis melding'}>
                        <IconButton onClick={() => setShowMessage(params.row)} size='small'>
                            <ExpandMoreIcon />
                        </IconButton>
                    </Tooltip>,
                ]
            },
        },
    ]

    return (
        <>
            <Dialog maxWidth='md' fullWidth open={isOpen} onClose={handleDialogClose}>
                <DialogTitle>Meldinger: {doctor.name}</DialogTitle>
                <DialogContent sx={{pb: 0}}>
                    <ViewHeightWrapper viewHeight='md'>
                        <DataGrid id={'messages'} rows={messages?.['hydra:member'] ?? []} columns={columns} />
                    </ViewHeightWrapper>
                </DialogContent>
                <DialogActions sx={{px: 3, pb: 2}}>
                    <Button variant='contained' onClick={() => setOpen(false)}>
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
            <MessageDialog row={showMessage} doctor={doctor} onClose={() => setShowMessage(undefined)} />
        </>
    )
}

const MessageDialog = ({row, onClose, doctor}) => {
    const isEmail = row?.type === 'email'

    return (
        <Dialog maxWidth='sm' fullWidth open={!!row} onClose={onClose}>
            <DialogTitle>
                {isEmail ? 'Epost' : 'Sms'}: {doctor.name}
            </DialogTitle>
            <DialogContent sx={{pb: 0}}>
                {isEmail ? (
                    <>
                        <Typography variant={'subtitle1'}>Emne: {row?.title}</Typography>
                        <div dangerouslySetInnerHTML={{__html: row?.content}} />
                    </>
                ) : (
                    <>
                        <Typography variant={'subtitle1'}>Innhold</Typography>
                        <Typography>{row?.content}</Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{px: 3, pb: 2}}>
                <Button variant='contained' onClick={onClose}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    )
}
