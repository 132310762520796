import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import EditText from '../../Components/EditText'
import styled from 'styled-components'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`
const StyledFormGroup = styled.div`
    .checkbox-not-allowed {
        cursor: not-allowed;
    }
`

const CheckboxIndicator = ({indicator, answer, onChange, disabled = false, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    return (
        <StyledFormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>
            <div>
                <label>
                    <input
                        onChange={e => onChange(e.target.value === 'yes', true)}
                        checked={!fresh && value}
                        name={indicator['@id']}
                        disabled={true}
                        type='radio'
                        value='yes'
                        className={'checkbox-not-allowed'}
                    />{' '}
                    Ja
                </label>
            </div>
            <div>
                <label>
                    <input
                        onChange={e => onChange(e.target.value === 'no', true)}
                        checked={!fresh && !value}
                        name={indicator['@id']}
                        disabled={true}
                        type='radio'
                        value='no'
                        className={'checkbox-not-allowed'}
                    />{' '}
                    Nei
                </label>
            </div>
            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </StyledFormGroup>
    )
}
export default CheckboxIndicator
