import * as React from 'react'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import Grid from '@mui/material/Grid'
import {DatePicker} from '@mui/x-date-pickers-pro'
import TextField from '@mui/material/TextField'
import FindDoctorModal, {FindDoctorModalDto} from '../../components/FindDoctorModal'

type ModalProps = {
    onClose: () => void
    refetch: Function | (() => Promise<any>)
    studyId: number
}

export function StudyAddDoctorModal({onClose, refetch, studyId}: ModalProps) {
    const [acceptedAt, setAcceptedAt] = React.useState<string | null>(null)
    const [invoicePaidAt, setInvoicePaidAt] = React.useState<string | null>(null)
    const [invoiceReceivedAt, setInvoiceReceivedAt] = React.useState<string | null>(null)
    const [amountPaid, setAmountPaid] = React.useState<string>('')

    const createInvitation = useSkilMutation<'getPraksisNettInvitationCollection'>('POST', `/api/praksisnett/invitations`, [
        `/api/praksisnett/studies/${studyId}/invitations`,
    ])
    const addDoctorMutation = useSkilMutation<'createPraksisnettDoctor'>('POST', `/api/praksisnett/doctors`, [`/api/praksisnett/doctors`])

    const onAddUser = async (newDoctor: FindDoctorModalDto) => {
        const doctor = await addDoctorMutation.mutateAsync(newDoctor)
        await createInvitation.mutateAsync({
            // @ts-expect-error
            doctor: doctor['@id'],
            study: `/api/praksisnett/studies/${studyId}`,
            acceptedAt,
            compensation: {invoiceReceivedAt, amountPaid: Number(amountPaid), invoicePaidAt},
            changelogs: [],
        })

        setAcceptedAt(null)
        setInvoicePaidAt(null)
        setInvoiceReceivedAt(null)
        setAmountPaid('')
        refetch()
        onClose()

        return 'Lege lagt til'
    }

    return (
        <FindDoctorModal onClose={onClose} onSave={onAddUser}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <DatePicker
                        label='Godtatt på'
                        value={acceptedAt}
                        onChange={value => setAcceptedAt(value)}
                        renderInput={params => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Faktura mottatt på'
                        value={invoiceReceivedAt}
                        onChange={value => setInvoiceReceivedAt(value)}
                        renderInput={params => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Faktura betalt på'
                        value={invoicePaidAt}
                        onChange={value => setInvoicePaidAt(value)}
                        renderInput={params => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='amountPaid'
                        label='Betalt'
                        value={amountPaid}
                        fullWidth
                        defaultValue={amountPaid}
                        onChange={event => {
                            setAmountPaid(event.target.value)
                        }}
                    />
                </Grid>
            </Grid>
        </FindDoctorModal>
    )
}
