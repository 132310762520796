import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import {IndicatorProps} from './index'
import {MultichoiceIndicatorType} from '../../Types'

const MultichoiceIndicator = ({indicator, answer, onChange, disabled = false}: IndicatorProps<MultichoiceIndicatorType, string>) => {
    let alternativesWithOrder = Object.values(indicator.alternatives) as {key: string; text: string; order: number}[]

    alternativesWithOrder.sort((a, b) => a.order - b.order)
    const {fresh, value, valid} = answer
    let valueArray = value ? value.split(',') : []

    const onSave = (val, checked) => {
        if (checked) onChange([...new Set([...valueArray, val])].join(','), true)
        else onChange(valueArray.filter(v => v !== val).join(','), true)
    }

    return (
        <FormGroup hasError={!disabled && !fresh && !valid && !indicator.optional} hasSuccess={!fresh && valid} size={'lg'}>
            <label>{indicator['title']}</label>

            {alternativesWithOrder.map(({key, text, order}) => (
                <div key={key}>
                    <label>
                        <input
                            disabled={disabled}
                            onChange={e => onSave(key, e.target.checked)}
                            checked={!!valueArray.find(o => o === key[0])}
                            name={indicator['@id']}
                            type='checkbox'
                            value={key}
                        />
                        &nbsp;{text}
                    </label>
                </div>
            ))}

            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
        </FormGroup>
    )
}
export default MultichoiceIndicator
