import React, {ReactNode} from 'react'
import ReactSelect from 'react-select'

export type OptionType = {value: any; label: ReactNode}
type Props = {
    value?: any
    onChange?: (value: OptionType) => any
    options: OptionType[]
    disabled?: boolean
    required?: boolean
    placeholder?: string
    [key: string]: any
}
const Select = ({options, value, onChange, disabled = false, required = true, ...props}: Props) => {
    const onLocalChange = option => {
        if (onChange) onChange(option)
    }

    props.classname += ' custom-select'
    props.isSearchable = true
    props.isDisabled = disabled
    if (!required) props.isClearable = true

    return <ReactSelect value={value} onChange={onLocalChange} options={options} {...props} />
}

export default Select
