import React, {useState} from 'react'

type Props = {
    variant?: 'success' | 'danger' | 'info'
    onDismiss?: Function
    children: React.ReactNode
    className?: string
}

const Alert = ({variant = 'success', onDismiss = undefined, children, className}: Props) => {
    const [hide, setHide] = useState(false)

    const onDismissClick = () => {
        setHide(true)
        if (typeof onDismiss === 'function') onDismiss()
    }

    return (
        <div hidden={hide} className={`alert alert-${variant} ${className}`} role='alert'>
            {onDismiss && (
                <button onClick={onDismissClick} type='button' className='close' data-dismiss='alert' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                </button>
            )}
            {children}
        </div>
    )
}
export default Alert
