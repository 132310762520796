import * as React from 'react'
import {Box} from '../../../Components/Panel'
import Button from '../../../Components/Button/Button'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import CircularProgress from '@mui/material/CircularProgress'
import columnDef, {EditableGridColDef} from '../../../Components/DataGrid/columnDefs/columnDef'
import {DataGridWrapper} from '../../../Components/DataGrid/Wrappers'
import DataGrid from '../../../Components/DataGrid/DataGrid'
import {GridSelectionModel} from '@mui/x-data-grid-premium'
import dateColumnDef from '../../../Components/DataGrid/columnDefs/dateColumnDef'
import {OfficeProps} from './Office'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SpecialActions from '../../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../../Components/DataGrid/SpecialAction'
import {SendMessageDialog} from '../../components/SendMessageDialog'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {DoctorsAddToStudyDialog} from '../Doctor/DoctorsAddToStudyDialog'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'

const renderCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return (
        <Button variant='text' to={`/dashboard/praksisnett/doctors/${params.row.id}`}>
            {params.value}
        </Button>
    )
}
const columnVisibilityModel = {
    id: false,
    hpr: false,
    signedDigitally: false,
    accountNr: false,
    comment: false,
    'helfoDoctor.positionSize': false,
    'helfoDoctor.authorizedAt': false,
    'helfoDoctor.age': false,
    'helfoDoctor.male': false,
    'helfoDoctor.listSize': false,
    'helfoDoctor.listFree': false,
    verifiedEmail: false,
    mismatchedOffice: false,
    acknowledgeOfficeMismatch: false,
}

const doctorColumns: EditableGridColDef[] = [
    columnDef({field: 'id', headerName: 'System-ID', type: 'string'}),
    columnDef({field: 'name', headerName: 'Navn', type: 'string', renderCell}),
    columnDef({field: 'external', headerName: 'Sluttet', type: 'boolean'}),
    columnDef({field: 'mobile', headerName: 'Telefon', type: 'string'}),
    columnDef({field: 'email', headerName: 'Epost', type: 'string'}),
    columnDef({field: 'region', headerName: 'Region', type: 'string'}),
    columnDef({field: 'hpr', headerName: 'HPR', type: 'string'}),
    columnDef({field: 'signedDigitally', headerName: 'Spesialist i allmennmedisin', type: 'boolean'}),
    columnDef({field: 'accountNr', headerName: 'Kontonr', type: 'number'}),
    columnDef({field: 'comment', headerName: 'Notat', type: 'string'}),
    columnDef({field: 'helfoDoctor.positionSize', headerName: 'Helfo Posisjonsstørrelse', type: 'number'}),
    columnDef({field: 'helfoDoctor.authorizedAt', headerName: 'Helfo Autorisert', type: 'string'}),
    columnDef({field: 'helfoDoctor.age', headerName: 'Helfo Alder', type: 'number'}),
    columnDef({field: 'helfoDoctor.male', headerName: 'Helfo Kjønn', type: 'boolean'}),
    columnDef({field: 'helfoDoctor.listSize', headerName: 'Helfo Listestørrelse', type: 'number'}),
    columnDef({field: 'helfoDoctor.listFree', headerName: 'Helfo Ledige plasser', type: 'number'}),
    columnDef({field: 'verifiedEmail', headerName: 'Godkjent epost', type: 'boolean'}),
    columnDef({field: 'mismatchedOffice', headerName: 'Feil legekontor', type: 'boolean'}),
    columnDef({field: 'acknowledgeOfficeMismatch', headerName: 'Godkjent feil legekontor', type: 'boolean'}),
]
export const OfficeEmployeesPanel = ({office}: OfficeProps) => {
    const [showSendText, setShowText] = React.useState(false)
    const [showSendEmail, setShowEmail] = React.useState(false)
    const [showAddToStudy, setShowAddToStudy] = React.useState(false)
    const [selectedDoctors, setSelectedDoctors] = React.useState<GridSelectionModel>([])
    const {data, isFetching, isLoading} = useSkilQuery<'getPraksisnettOfficeDoctors'>(`${office['@id']}/doctors`)

    const doctors = data ? data['hydra:member'] : []

    const actions: SpecialActionProps[] = [
        {
            label: 'Send SMS',
            action: () => setShowText(true),
            disabled: selectedDoctors.length === 0,
            icon: {
                position: 'start',
                icon: <PhoneIphoneOutlinedIcon />,
            },
        },
        {
            label: 'Send e-post',
            disabled: selectedDoctors.length === 0,
            action: () => setShowEmail(true),
            icon: {
                position: 'start',
                icon: <EmailOutlinedIcon />,
            },
        },
        {
            label: 'Legg til i studie',
            action: () => setShowAddToStudy(true),
            disabled: selectedDoctors.length === 0,
            icon: {
                position: 'start',
                icon: <AssessmentOutlinedIcon />,
            },
        },
    ]

    const onChangeSelectedDoctors = (event, value) => {
        setSelectedDoctors(value.map(v => v.id))
    }
    const currentSelection = doctors.filter(i => selectedDoctors.includes(Number(i.id)))
    const doctorIris = doctors.filter(i => selectedDoctors.includes(Number(i.id))).map(d => d['@id'] as string)

    return (
        <Box title='Ansatte'>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <DataGridWrapper rows={doctors.length} addHeight={8.5}>
                    <DataGrid
                        id={'employees'}
                        loading={isFetching}
                        checkboxSelection
                        rows={doctors}
                        initModel={{columnVisibilityModel}}
                        columns={doctorColumns}
                        onSelectionModelChange={newSelectedDoctors => {
                            setSelectedDoctors(newSelectedDoctors)
                        }}
                        selectionModel={selectedDoctors}
                        toolbarButtons={<SpecialActions actions={actions} />}
                    />
                </DataGridWrapper>
            )}

            <SendMessageDialog isOpen={showSendEmail} onClose={() => setShowEmail(false)} doctorIris={doctorIris} isEmail>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={doctors}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelectedDoctors}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>

            <SendMessageDialog isOpen={showSendText} onClose={() => setShowText(false)} doctorIris={doctorIris} isText>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={doctors}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelectedDoctors}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>
            <DoctorsAddToStudyDialog
                isOpen={showAddToStudy}
                onClose={() => setShowAddToStudy(false)}
                doctorIris={doctorIris}
                setSelected={setSelectedDoctors}
            />
        </Box>
    )
}
