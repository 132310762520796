import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Button from '../../../Components/Button/Button'
import EditText from '../../Components/EditText'
import FormGroup from '../../../Components/FormGroup'
import TextEditor from '../../../Components/TextEditor/TextEditor'
import CheckboxField from '../../../Components/Fields/CheckboxField'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`

const StyledFormGroup = styled(FormGroup)`
    .btn-group {
        display: flex;
        justify-content: flex-end;
    }
`

const StyledCheckbox = styled.input`
    cursor: pointer;
`

const InformationIndicator = ({indicator, answer, onChange, disabled = false}) => {
    const {fresh, value, valid} = answer
    const [editing, setEditing] = useState(false)
    const [text, setText] = useState(indicator.content)

    const setValue = value => {
        setText(value)
    }

    useEffect(() => {
        if (!editing) setText(indicator.content)
    }, [indicator.content])

    const onEdit = () => {
        setEditing(true)
    }

    const onCancel = () => {
        setEditing(false)
    }

    const onSave = () => {
        setEditing(false)
        indicator.changeField('content', text)
        indicator.save()
    }

    const onTitleSave = e => {
        indicator.changeField('title', e)
        indicator.save()
    }

    const onCopyFunctionChange = e => {
        indicator.changeField('copyFunction', e)
        indicator.save()
    }

    return (
        <StyledFormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>
            <div onClick={onEdit}>
                <TextEditor id={indicator.id} value={text} onChange={setValue} />
            </div>

            {editing && (
                <div>
                    <div className={'btn-group-left'}>
                        <label className='help-block'>
                            <StyledCheckbox
                                type={'checkbox'}
                                checked={!!indicator.copyFunction}
                                onChange={e => onCopyFunctionChange(e.target.checked)}
                            />{' '}
                            Vis knappen 'Kopier innhold'
                        </label>
                    </div>
                    <div className={'btn-group'}>
                        <Button onClick={onCancel}>Avbryt</Button>
                        <Button variant={'primary'} onClick={onSave}>
                            Lagre
                        </Button>
                    </div>
                </div>
            )}
        </StyledFormGroup>
    )
}
export default InformationIndicator
