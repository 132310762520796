import React from 'react'
import Button from './Button/Button'
import reportError from '../State/utils/errorHandler'

class ErrorBoundary extends React.Component<any, {hasError: boolean; error?: Error; errorInfo?: any}> {
    constructor(props) {
        super(props)
        this.state = {hasError: false, error: undefined, errorInfo: undefined}
    }

    componentDidCatch(error, errorInfo) {
        reportError(error.message, JSON.stringify(errorInfo))
        this.setState({hasError: true, error, errorInfo})
    }

    render() {
        if (this.state.hasError) {
            const isDev = process.env.APP_ENV === 'dev'
            const {error, errorInfo} = this.state
            const stack = (errorInfo?.componentStack ?? '').split('\n')
            // You can render any custom fallback UI
            return (
                <>
                    <br />
                    <br />
                    <h1 style={{textAlign: 'center'}}>Noe gikk galt</h1>
                    <h1 style={{textAlign: 'center'}}>
                        <Button variant='primary' onClick={() => this.setState({hasError: false})}>
                            Prøv på ny
                        </Button>
                    </h1>
                    {isDev && (
                        <div className={'well'}>
                            <p>{this.state.error?.message}</p>
                            <ul>
                                {stack
                                    .filter(l => l)
                                    .map((l, i) => (
                                        <li key={i}>{l}</li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </>
            )
        }

        return this.props.children
    }
}
export default ErrorBoundary
