import * as React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import useFieldChange from '../../utils/useFieldChange'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import {useClickHandler} from '../../../Components/Button/utils'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {components} from '../../../Generated/eportal'
import {OfficeProps} from './Office'
import Grid from '@mui/material/Grid'
import {Box} from '../../../Components/Panel'
import {CountyAutocomplete} from '../../../Components/CountyAutocomplete'

type UpdateProfileType = components['schemas']['Praksisnett.Office.UpdateProfileDTO.jsonld']

export const OfficeProfilePanel = ({office, refetch}: OfficeProps) => {
    const [updateFields, onFieldChange, setUpdateFields] = useFieldChange<UpdateProfileType>({})
    const updateProfile = useSkilMutation<'updatePraksisNettOfficeProfile'>(
        'post',
        `/api/praksisnett/offices/${office?.id}/update_profile`,
        [`/api/praksisnett/offices/${office?.id}`, `/api/praksisnett/offices`]
    )
    const employeesRequest = useSkilQuery<'getPraksisnettOfficeDoctors'>(`${office['@id']}/doctors`)
    /*************************
     * Managing profile data *
     *************************/

    const onCountyChange = (event, county, iri, district) => {
        setUpdateFields({...updateFields, county: iri, district: district.id})
    }
    const oContactChange = (event, employee) => {
        setUpdateFields({...updateFields, contact: employee['@id']})
    }
    const saveProfile = async () => {
        await updateProfile.mutateAsync({
            name: updateFields.name ?? office?.name,
            address: updateFields.address ?? office?.address,
            orgNr: updateFields.orgNr ?? office?.orgNr,
            region: updateFields.region ?? office?.region,
            county: updateFields.county ?? office?.county?.['@id'],
            district: updateFields.district ?? office?.district?.id,
            signedContract: updateFields.signedContract ?? office?.signedContract,
            contact: updateFields.contact ?? office?.contact?.['@id'] ?? null,
            invoiceNote: updateFields.invoiceNote ?? office?.invoiceNote,
        })
        refetch()
    }
    const {handleClick: handleSaveProfileClick, working: savingProfile} = useClickHandler(saveProfile)
    const employees = employeesRequest.data?.['hydra:member'] ?? []
    const selectedContact = employees.find(e => e['@id'] === (updateFields.contact ?? office?.contact?.['@id'] ?? null))

    return (
        <Box title='Legekontordetaljer'>
            <Grid container spacing={2} mb={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        label='På kontrakt'
                        control={
                            <Checkbox
                                id='signedContract'
                                checked={updateFields.signedContract ?? office?.signedContract ?? false}
                                onChange={onFieldChange}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='name' fullWidth label='Navn' defaultValue={office?.name} onChange={onFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='address' fullWidth label='Adresse' defaultValue={office?.address} onChange={onFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <CountyAutocomplete value={updateFields.county ?? office?.county?.['@id'] ?? null} onChange={onCountyChange} required />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='orgNr' fullWidth label='Org. nr.' defaultValue={office?.orgNr} onChange={onFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id='region' fullWidth label='Region' defaultValue={office?.region} onChange={onFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        autoHighlight
                        id={'contact'}
                        value={selectedContact}
                        getOptionLabel={option => String(option.name)}
                        onChange={oContactChange}
                        disableClearable
                        renderInput={params => <TextField {...params} label='Kontakt' />}
                        options={employees}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='invoiceNote'
                        fullWidth
                        multiline
                        label='Faktura Merknad'
                        defaultValue={office?.invoiceNote}
                        onChange={onFieldChange}
                    />
                </Grid>
            </Grid>
            <LoadingButton
                sx={{marginLeft: 'auto', display: 'block'}}
                loading={savingProfile}
                variant='contained'
                onClick={handleSaveProfileClick}
            >
                Lagre
            </LoadingButton>
        </Box>
    )
}
