import {useState, useLayoutEffect, useRef} from 'react'

const useGetSize = () => {
    const ref = useRef<HTMLDivElement>()
    const [height, setHeight] = useState<number>(0)

    const resize = () => {
        if (ref.current) {
            setHeight(ref.current.clientHeight)
        }
    }

    const setRef = element => {
        ref.current = element
        resize()
    }

    useLayoutEffect(() => {
        let images: NodeListOf<HTMLImageElement>
        window.addEventListener('resize', resize)
        if (ref.current) {
            images = ref.current.querySelectorAll('img')
            images.forEach(i => {
                i.addEventListener('load', resize)
            })
        }

        return () => {
            window.removeEventListener('resize', resize)

            images.forEach(i => {
                i.removeEventListener('load', resize)
            })
        }
    })

    useLayoutEffect(() => {
        let previousAnswer: NodeListOf<Element>
        window.addEventListener('resize', resize)
        if (ref.current) {
            previousAnswer = ref.current.querySelectorAll('.previousAnswer')
            previousAnswer.forEach(i => {
                i.addEventListener('load', resize)
            })
        }

        return () => {
            window.removeEventListener('resize', resize)

            previousAnswer.forEach(i => {
                i.removeEventListener('load', resize)
            })
        }
    })

    return {setRef, height}
}
export default useGetSize
