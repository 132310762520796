import React from 'react'
import {useQuery} from 'react-query'
import {jsonFetch} from '../Components/jsonFetch'

interface UpstreamClaimsType {
    is_secure: boolean
    user_id: number
    name: string
    email: string
    email_verified: boolean
    phone_number: string
    phone_number_verified: boolean
    family_name: string
    nickname: string
    preferred_username: string
    offices: {
        [officeIri: string]: {
            role: number
            approved_by: string
            nefle: {
                domain: string
                generated: boolean
                cancelled: boolean
            }
        }
    }
    meetings: {[meetingIri: string]: {role: number}}
    courses: {
        [courseIri: string]: {
            status: 'accepted' | 'invited'
            role: number
        }
    }
    groups: {
        [groupIri: string]: {
            leader: boolean
            contact: boolean
            counselor: boolean
        }
    }
    partnerships: {[partnershipIri: string]: {role: string}} // Used!
    roles: string[]
    roles_secure: string[]
    role: string
    office: number
}

type ClaimsType = Omit<UpstreamClaimsType, 'phone_number_verified' | 'family_name' | 'nickname' | 'preferred_username'>

type UseUserReturnType = {
    iri: string
    id: number
    name: string
    voters: ReturnType<typeof voters>
    refresh: () => void
    isLoading?: boolean
    isError?: boolean
    error?: any
    isRefetching: boolean
    isFetched: boolean
    isLoadingError: boolean
    isSuccess: boolean
} & (AnonymousUseUserReturnType | AuthenticatedUseUserReturnType)

type AnonymousUseUserReturnType = {
    authenticated: false
    claims?: ClaimsType
}
type AuthenticatedUseUserReturnType = {
    authenticated: true
    claims: ClaimsType
}

const voters = (roles: string[], claims?: ClaimsType) => ({
    viewGroup: (groupIri?: string) => {
        if (!groupIri) return false

        return !!claims?.groups[groupIri]
    },
    isAdminOrCounselor: (courseIri?: string) => {
        if (!courseIri) return false

        let role = claims?.courses[courseIri]?.role

        return role === 1 || role === 2
    },
    isGroupCounselor: (groupIri?: string) => {
        if (!groupIri) return false

        return claims?.groups[groupIri]?.counselor
    },
    isCourseAdmin: (courseIri?: string) => {
        if (!courseIri) return false

        return claims?.courses[courseIri]?.role === 2
    },
    isSkilUser: () => {
        return roles.some(role => role === 'ROLE_SKIL')
    },
    hasCourse: (courseIri?: string, requireApproved: boolean = false) => {
        if (!courseIri) return false

        const course = claims?.courses[courseIri]

        return course && (!requireApproved || course.status === 'accepted')
    },
    isGroupMember(groupIri) {
        if (!groupIri) return false

        return !!claims?.groups[groupIri]
    },
})

export default function useUser(): UseUserReturnType {
    return React.useContext(UserContext)
}

const UserContext = React.createContext<UseUserReturnType>({} as any)
export const UserProvider = ({children}) => {
    // TODO: Trigger a refresh when the user session cookie expires
    const {
        data: userInfo,
        isLoading,
        isError,
        error,
        refetch,
        isRefetching,
        isFetched,
        isLoadingError,
        isSuccess,
    } = useQuery({
        queryFn: () => jsonFetch<UpstreamClaimsType>('/login/connect/userinfo', {ignoreAuthErrors: true}),
        queryKey: 'userinfo',
        cacheTime: Infinity,
        staleTime: 0,
        onSuccess: data => {
            // @ts-expect-error errorHandler is defined in errorHandler.tsx, and must be imported before userProvider!
            window.errorHandler?.setUser(data.user_id)
        },
    })

    let user: UseUserReturnType = {
        authenticated: false,
        name: 'Gjest',
        iri: '',
        id: 0,
        voters: voters(['ROLE_GUEST']),
        refresh: refetch,
        isLoading: isLoading,
        isError: isError,
        error: error,
        isRefetching,
        isFetched,
        isLoadingError,
        isSuccess,
    }

    if (userInfo) {
        let roles = userInfo.is_secure ? userInfo.roles_secure : userInfo.roles
        user = {
            authenticated: true,
            name: userInfo.name,
            iri: '/api/users/' + userInfo.user_id,
            claims: userInfo,
            id: userInfo.user_id,
            voters: voters(roles, userInfo),
            refresh: refetch,
            isLoading: isLoading,
            isError: isError,
            error: error,
            isRefetching,
            isFetched,
            isLoadingError,
            isSuccess,
        }
    }

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
