import * as React from 'react'
import {Box} from '../../../Components/Panel'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import Grid from '@mui/material/Grid'
import {components} from '../../../Generated/eportal'
import {useClickHandler} from '../../../Components/Button/utils'
import {Button} from '../../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import useFieldChange from '../../utils/useFieldChange'
import {DatePicker} from '@mui/x-date-pickers-pro'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {useTheme} from '@mui/material/styles'
import {StudyProps} from './Study'

type PatchStudyType = components['schemas']['Praksisnett.Study-PraksisNettStudy.update']

export const StudyDetailsPanel = ({study, refetch}: StudyProps) => {
    const theme = useTheme()
    const [updateFields, onFieldChange, setUpdateFields, onChangeDate] = useFieldChange<PatchStudyType>({})
    const updateProfile = useSkilMutation<'patchPraksisnettStudy'>('patch', `/api/praksisnett/studies/${study?.id}`, [
        `/api/praksisnett/studies/${study.id}`,
        `/api/praksisnett/studies`,
    ])

    const onSelectChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUpdateFields({...updateFields, [event.target.name]: event.target.value})
    }

    const saveProfile = async () => {
        await updateProfile.mutateAsync(updateFields)
        refetch()
    }

    const {handleClick: handleSaveProfileClick} = useClickHandler(saveProfile)

    return (
        <Box title='Detaljer'>
            <Grid container spacing={2} mb={5} rowSpacing={2}>
                <Grid item xs={12}>
                    <TextField id='name' fullWidth label='Navn' defaultValue={study.name} onChange={onFieldChange} />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id='region-label'>Ansvarlig region</InputLabel>
                        <Select
                            labelId='region-label'
                            label='Ansvarlig region'
                            name='region'
                            value={updateFields.region ?? study.region}
                            onChange={e => onSelectChange(e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)}
                        >
                            {['Midt', 'Nord', 'Vest', 'Øst'].map(region => (
                                <MenuItem key={region} value={region}>
                                    {region}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id={'responsibleInstitution'}
                        fullWidth
                        label='Ansvarlig institusjon'
                        defaultValue={study.responsibleInstitution}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id={'fundingSource'}
                        fullWidth
                        label='Finansieringskilde'
                        defaultValue={study.fundingSource}
                        onChange={onFieldChange}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mb={5} rowSpacing={2}>
                <Grid item xs={6}>
                    <TextField
                        id={'researcher.title'}
                        fullWidth
                        label='Stillingstittel'
                        defaultValue={study.researcher?.title}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id={'researcher.name'}
                        fullWidth
                        label='Prosjektleder'
                        defaultValue={study.researcher?.name}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id={'researcher.email'}
                        fullWidth
                        label='Prosjektleder epost'
                        defaultValue={study.researcher?.email}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id={'researcher.phoneNumber'}
                        fullWidth
                        label='Prosjektleder telefon'
                        defaultValue={study.researcher?.phoneNumber}
                        onChange={onFieldChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={5} rowSpacing={2}>
                <Grid item sm={4} xs={12}>
                    <DatePicker
                        label='Siste betaling mottatt på'
                        inputFormat='dd.MM.yyyy'
                        mask={'__.__.____'}
                        value={updateFields?.payment?.lastReceivedOn ?? study.payment?.lastReceivedOn ?? null}
                        renderInput={params => <TextField fullWidth id={'payment.lastReceivedOn'} {...params} />}
                        onChange={onChangeDate('payment.lastReceivedOn')}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'payment.total'}
                        type={'number'}
                        fullWidth
                        label='Betaling totalt'
                        defaultValue={study.payment?.total}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'payment.paid'}
                        type={'number'}
                        fullWidth
                        label='Betaling betalt'
                        defaultValue={study.payment?.paid}
                        onChange={onFieldChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={5} rowSpacing={2}>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'norce.projectNumber'}
                        fullWidth
                        label='NORCE Prosjektnr'
                        defaultValue={study.norce?.projectNumber}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'norce.clientNumber'}
                        fullWidth
                        label='NORCE Klientnr'
                        defaultValue={study.norce?.clientNumber}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'norce.invoiceNumber'}
                        fullWidth
                        label='NORCE Fakturanr'
                        defaultValue={study.norce?.invoiceNumber}
                        onChange={onFieldChange}
                    />
                </Grid>
                <Grid mt={5} item xs={12}>
                    <TextField multiline id={'notes'} fullWidth label='Merknad' defaultValue={study.notes} onChange={onFieldChange} />
                </Grid>
            </Grid>

            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                <Button variant='contained' sx={{marginLeft: theme.spacing(1)}} onClick={handleSaveProfileClick}>
                    Lagre
                </Button>
            </div>
        </Box>
    )
}
