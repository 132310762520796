import React, {useState} from 'react'
import Button from '../Components/Button/Button'
import Panel from '../Components/Panel'
import {jsonFetch} from '../Components/jsonFetch'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import {Page} from '../Components/Page'
import {getTitleFromContent} from './News'
import useEntities from '../Hooks/useEntities'
import useEntity from '../Hooks/useEntity'
import LoadingComponent from '../Components/LoadingComponent'
import TextEditorField from '../Components/Fields/TextEditorField'
import DateField from '../Components/Fields/DateField'
import CheckboxField from '../Components/Fields/CheckboxField'
import TextField from '../Components/Fields/TextField'

export default function NewsNew() {
    const {partnershipId} = useParams()
    const partnership = useEntity<'getPartnershipItem'>(partnershipId ? `/api/partnerships/${partnershipId}` : null)
    const navigate = useNavigate()
    const [article, setArticle] = useState('')
    const [validFrom, setValidFrom] = useState('')
    const [validUntil, setValidUntil] = useState('')
    const [important, setImportant] = useState(false)
    const [urlSlug, setUrlSlug] = useState('')
    const news = useEntities<'getPartnershipNewsCollection'>(`/api/partnerships/${partnershipId}/news`)

    const onSaveNews = async newNews => {
        await jsonFetch(`/api/partnership_news`, {
            method: 'POST',
            json: {partnership: `/api/partnerships/${partnershipId}`, ...newNews},
        }).then(() => news.refresh())
    }

    const onCreateNew = async () => {
        let extractedTitle = getTitleFromContent(article)
        await onSaveNews({
            title: extractedTitle,
            article,
            validFrom: validFrom ? validFrom : null,
            validUntil: validUntil ? validUntil : null,
            important: important ? important : false,
            urlSlug,
        })
        navigate(`/dashboard/kommuner/${partnershipId}/nyheter`, {replace: true})
    }

    const breadcrumbs = [
        {title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId},
        {title: 'Nyheter', to: '/dashboard/kommuner/' + partnershipId + '/nyheter'},
        {title: 'Ny artikkel', to: ''},
    ]

    if (!partnership['@loaded']) return <LoadingComponent />

    return (
        <Page title={'Nefle nyhetssaker'} breadcrumbs={breadcrumbs} variant={'full'}>
            <Panel variant={'flat'}>
                <Panel.Body>
                    <div style={{marginBottom: '1rem'}}>
                        <TextEditorField
                            label='Artikkel'
                            placeholder='Nyhetsartikkelen'
                            required
                            value={article}
                            onChange={e => setArticle(e)}
                            id={'article-content'}
                        />
                    </div>
                    <div style={{marginBottom: '1rem'}}>
                        <DateField
                            autocomplete={'off'}
                            label='Aktiv fra'
                            help='La stå blankt for å være deaktivert'
                            value={validFrom}
                            onChange={e => setValidFrom(e)}
                            id={'validFrom'}
                        />
                    </div>
                    <div style={{marginBottom: '1rem'}}>
                        <DateField
                            id={'validUntil'}
                            autocomplete={'off'}
                            label='Aktiv til'
                            help='La stå blankt for å ikke ha tidsbegrensning'
                            value={validUntil}
                            onChange={e => setValidUntil(e)}
                        />
                    </div>
                    <div style={{marginBottom: '1rem'}}>
                        <CheckboxField id='important' label='Angi viktig nyhet' value={important} onChange={val => setImportant(val)} />
                    </div>
                    <div style={{marginBottom: '1rem'}}>
                        <TextField
                            id='urlSlug'
                            type='text'
                            label='URL slug'
                            placeholder=''
                            required
                            value={urlSlug}
                            onChange={val => setUrlSlug(val)}
                            help='Angir slutten på URL lenken'
                        />
                    </div>
                </Panel.Body>
                <Panel.Footer>
                    <div className={'row'}>
                        <div className={'col-xs-4'}>
                            <Button variant={'link'} to={`/dashboard/kommuner/${partnershipId}/nyheter`} aria-label={'Tilbake'}>
                                Tilbake
                            </Button>
                        </div>
                        <div className={'col-xs-4'} />
                        <div className={'col-xs-4 text-right'}>
                            <Button onClick={onCreateNew} aria-label={'Lagre'}>
                                Lagre
                            </Button>
                        </div>
                    </div>
                </Panel.Footer>
            </Panel>
        </Page>
    )
}
