import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridCellEditCommitParams, GridCellParams, GridColDef, GridSortItem, GridValueGetterParams} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import arrayOfDatesColumnDef from '../../Components/DataGrid/columnDefs/arrayOfDatesColumnDef'
import Button from '../../Components/Button/Button'
import useUser from '../../Utilities/useUser'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {CreatePartnershipDialog} from '../Components/CreatePartnershipDialog'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import {useQueryInvalidate} from '../../Utilities/QueryClient'
import {useNavigate} from 'react-router'

const renderCell = params => {
    if (!params.value) return ''

    return (
        <Button variant='text' to={`/dashboard/SKIL/samarbeid/${params.row.id}`}>
            {params.value}
        </Button>
    )
}
const renderJiraCell = params => {
    if (!params.value) return ''

    return (
        <Button variant='text' target={'_blank'} href={`https://skilnet.atlassian.net/issues/?jql=labels = ${params.value}`}>
            {params.value}
        </Button>
    )
}

const addMeValueGetter = user => (params: GridValueGetterParams) => {
    const members = params.value ?? []

    // If we are editing, params.value will actually be a boolean with current state
    if (typeof members === 'boolean') return members

    return members.some(member => member.user === user['iri'])
}

const columnVisibilityModel = {
    id: false,
    createdAt: false,
    description: false,
    price: false,
    plannedSeminarAt: false,
    informationMeetingAt: false,
    educationMeetingAt: false,
    debriefMeetingAt: false,
}

const Partnerships = () => {
    const invalidate = useQueryInvalidate()
    const navigate = useNavigate()
    const [sortModel, setSortModel] = React.useState<GridSortItem[]>([])
    const {data, isFetching, refetch} = useSkilQuery<'getPartnershipCollection'>('/api/partnerships')
    const [showCreatePartnership, setShowCreatePartnership] = React.useState<boolean>(false)
    const createPartnershipMutation = useSkilMutation<'createPartnershipItem'>('POST', '/api/partnerships/create', [
        '/api/partnerships/create',
    ])
    const deleteMemberMutation = useSkilMutation<'deletePartnershipMemberItem'>('DELETE', '/api/partnership_members/*id*', [
        '/api/partnerships',
    ])
    const addMemberMutation = useSkilMutation<'addPartnershipMember'>('POST', `/api/partnerships/*id*/add_member`, ['/api/partnerships'])
    const partnerships = data?.['hydra:member'] ?? []
    const user = useUser()

    const onCreatePartnership = async newPartnership => {
        try {
            await createPartnershipMutation
                .mutateAsync({
                    ...newPartnership,
                })
                .then(res => {
                    toast('Samarbeidet ble opprettet', {type: 'success'})
                    navigate(`/dashboard/SKIL/samarbeid/${res.id}`)
                    invalidate([
                        '/api/partnerships',
                        '/api/partnerships/statistics',
                        '/dashboard/api/search?term=&filter=users,groups,offices,courses',
                    ]).then(() => {})
                    refetch()
                        .then(() => {})
                        .catch(() => {})
                })
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onToggleMember = async (params: GridCellEditCommitParams) => {
        try {
            if (params.value) {
                await addMemberMutation.mutateAsync({
                    // Row is undefined on params, but does exist :/
                    // @ts-expect-error
                    '@overridePath': `/api/partnerships/${params.row.id}/add_member`,
                    type: 'skil',
                    id: user.id,
                })
                return true
            } else {
                // Row is undefined on params, but does exist :/
                // @ts-expect-error
                const members = params.row.members ?? []
                const member = members.find(member => member.user === user.iri)
                if (member) {
                    await deleteMemberMutation.mutateAsync({'@overridePath': member['@id']})
                }
                return false
            }
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            refetch()
                .then(() => {})
                .catch(() => {})
        }
    }

    const actions: SpecialActionProps[] = [
        {
            label: 'Opprett samarbeid',
            action: () => setShowCreatePartnership(true),
            icon: {
                position: 'start',
                icon: <AccountBalanceIcon />,
            },
        },
    ]

    const handleSortModelChange = model => {
        setSortModel(model)
    }

    const columns: GridColDef[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'name', headerName: 'Navn', renderCell}),
        columnDef(
            {field: 'members', headerName: 'Brukertilgang', type: 'boolean', valueGetter: addMeValueGetter(user)},
            {onCellEditCommit: onToggleMember}
        ),
        arrayOfDatesColumnDef({
            field: 'plannedSeminars',
            headerName: 'Planlagte seminar',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'plannedSeminars')?.sort ?? 'none',
        }),
        arrayOfDatesColumnDef({
            field: 'informationMeetings',
            headerName: 'Informasjonsmøter',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'informationMeetings')?.sort ?? 'none',
        }),
        arrayOfDatesColumnDef({
            field: 'educationMeetings',
            headerName: 'Opplæringsmøter',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'educationMeetings')?.sort ?? 'none',
        }),
        arrayOfDatesColumnDef({
            field: 'debriefMeetings',
            headerName: 'Oppsummeringsmøter',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'debriefMeetings')?.sort ?? 'none',
        }),
        dateColumnDef({field: 'plannedSeminarAt', headerName: 'Planlagt seminar'}),
        dateColumnDef({field: 'informationMeetingAt', headerName: 'Informasjonsmøte'}),
        dateColumnDef({field: 'educationMeetingAt', headerName: 'Opplæringsmøte'}),
        dateColumnDef({field: 'debriefMeetingAt', headerName: 'Oppsummeringsmøte'}),
        dateColumnDef({field: 'previousSeminar', headerName: 'Forrige seminar', type: 'date'}),
        dateColumnDef({field: 'nextSeminar', headerName: 'Neste seminar', type: 'date'}),
        dateColumnDef({field: 'createdAt', headerName: 'Opprettet'}),
        columnDef({field: 'description', headerName: 'Beskrivelse'}),
    ]

    return (
        <Box>
            <DataGridWrapper rows={partnerships.length}>
                <DataGrid
                    id={'participants'}
                    loading={isFetching}
                    rows={partnerships}
                    initModel={{columnVisibilityModel}}
                    columns={columns}
                    disableSelectionOnClick
                    onSortModelChange={handleSortModelChange}
                    toolbarButtons={<SpecialActions actions={actions} />}
                />
            </DataGridWrapper>
            <CreatePartnershipDialog
                isOpen={showCreatePartnership}
                handleSubmit={onCreatePartnership}
                handleClose={() => setShowCreatePartnership(false)}
            />
        </Box>
    )
}

export default Partnerships
