import React from 'react'
import Slider from 'rc-slider'
import range from 'lodash/range'
import styled from 'styled-components'
import FormGroup from '../../../Components/FormGroup'
import useField from '../../../Hooks/useField'
import EditText from '../../Components/EditText'
import useEntity from '../../../Hooks/useEntity'

const StyledDiv = styled.div`
    margin-left: 2em;
    margin-right: 2em;
    .rc-slider-track {
        background: ${props => (props.valid ? '#10596e' : '#a94442')};
    }
    .rc-slider-rail {
        background: ${props => (props.valid ? '#e9e9e9' : '#a94442')};
    }

    .rc-slider-dot {
        width: 24px;
        height: 24px;
        bottom: -10px;
        margin-left: -12px;

        border-color: ${props => (props.valid ? '#10596e' : '#a94442')};
    }

    .rc-slider-handle {
        border-color: ${props => (props.valid ? '#10596e' : '#a94442')};

        &:hover {
            border-color: ${props => (props.valid ? '#10596e' : '#873635')};
            border-width: 3px;
        }
    }
`
const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`

const StyledMinMax = styled.span`
    margin: 0 0 2.5em 0;
`
const StyledEditText = styled(EditText)`
    text-align: left;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`

const NumberIndicator = ({indicator, answer, onChange, disabled = false, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer
    let minimum = indicator.minimum.toString()
    let maximum = indicator.maximum.toString()
    let marks = range(parseInt(minimum) - 1, parseInt(maximum) + 1).reduce((carry, i) => {
        carry[i] = Number(i)
        return carry
    }, {})

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onSliderChange = value => {
        indicator.changeField('slider', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    const onMinimumSave = value => {
        indicator.changeField('minimum', parseInt(value, 10))
        indicator.save()
    }

    const onMaximumSave = value => {
        indicator.changeField('maximum', parseInt(value, 10))
        indicator.save()
    }

    return (
        <FormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>

            <div>
                <label>
                    <input onChange={e => onSliderChange(!e.target.checked)} checked={!indicator.slider} type='radio' /> Nummer input
                </label>
                &nbsp;&nbsp;
                <label>
                    <input onChange={e => onSliderChange(e.target.checked)} checked={indicator.slider} type='radio' /> Slider
                </label>
            </div>
            <br />

            {!indicator.slider && (
                <input
                    disabled={disabled}
                    name={indicator['@id']}
                    onBlur={e => onChange(e.target.value, true)}
                    onChange={e => onChange(e.target.value)}
                    value={value ? value : ''}
                    style={{width: '10em'}}
                    max={maximum}
                    min={minimum}
                    className='form-control'
                    type='number'
                />
            )}
            {indicator.slider && (
                <StyledDiv valid={fresh || valid}>
                    <Slider
                        disabled={disabled}
                        min={minimum}
                        max={maximum}
                        value={value ? value : null}
                        marks={marks}
                        onChange={value => onChange(value, true)}
                    />
                    <br />
                    <br />
                </StyledDiv>
            )}
            <StyledMinMax className='help-block'>
                Minimum <StyledEditText type='number' width={'4em'} inline placeholder='Legg til' text={minimum} onSave={onMinimumSave} />
                Maksimum <StyledEditText type='number' width={'4em'} inline placeholder='Legg til' text={maximum} onSave={onMaximumSave} />
            </StyledMinMax>
            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </FormGroup>
    )
}
export default NumberIndicator
