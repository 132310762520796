import {Paper, Typography} from '@mui/material'
import Box from '@mui/system/Box'
import HelseIdImage from '../helseid.png'
import {Button} from '../../Components/Button/MuiButton'
import React from 'react'

type Props = {
    redirectUrl?: string | null
}
export default function HelseIdAuthCard({redirectUrl}: Props) {
    const href = redirectUrl ? `/login/helseid?redirect=${encodeURI(redirectUrl)}` : '/login/helseid'
    const emailHref = redirectUrl ? `/login/email?redirect=${encodeURI(redirectUrl)}` : '/login/email'
    return (
        <Paper elevation={0} sx={{width: '100%', height: '100%'}}>
            <Box
                minHeight={350}
                overflow={'hidden'}
                padding={4}
                display={'flex'}
                justifyContent={'space-around'}
                alignItems={'center'}
                flexDirection={'column'}
                flexGrow={1}
            >
                <Typography variant={'h2'}>Eksisterende bruker</Typography>
                <small>
                    Du kan velge mellom følgende alternativer for innlogging:
                    <br />
                    <strong>MinID, BankID, Buypass ID og Commfides</strong>
                </small>
                <img alt={'MinID, BankID, BankID (på mobil)'} style={{objectFit: 'none'}} src={HelseIdImage} />
                <Box flexGrow={1} />
                <Button size={'large'} fullWidth href={href}>
                    Logg inn via ID-Porten
                </Button>
                <Button sx={{marginTop: 1}} fullWidth variant={'text'} to={emailHref}>
                    Logg inn med e-post og passord
                </Button>
            </Box>
        </Paper>
    )
}
