import * as React from 'react'
import {
    DataGridPremium,
    DataGridPremiumProps as MuiDataGridProProps,
    GridCellEditCommitParams,
    GridColumnVisibilityModel,
    GridInitialState,
    useGridApiRef,
    GridApi,
} from '@mui/x-data-grid-premium'
import {EditableGridColDef} from './columnDefs/columnDef'
import LinearProgress from '@mui/material/LinearProgress'
import {useSearchParams} from 'react-router-dom'
import {useEffect, useMemo} from 'react'
import lzString from 'lz-string'
import {throttle} from 'lodash'
import {DataGridToolbar} from './DataGridToolbar'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
    highlightedRow: {
        backgroundColor: '#10a110bf !important',
        color: 'white',
    },
})

// TODO: make sure the operatorValues is as complete as possible
export type InitModel = {
    columnVisibilityModel?: GridColumnVisibilityModel
    filter?: {
        columnField: string
        operatorValue: 'is' | 'startsWith' | 'onOrBefore' | 'onOrAfter' | 'isNotEmpty' | 'isEmpty' | '>' | '<' | '!=' | '='
        value?: number | string | boolean
        id?: number
    }[]
    sort?: {field: string; sort: 'asc' | 'desc'}[]
}

export interface DataGridProProps extends MuiDataGridProProps {
    id: string
    columns: EditableGridColDef[]
    toolbarButtons?: JSX.Element
    showQuickFilter?: boolean
    initModel?: InitModel
}

function createInitDataModel(initModel: InitModel): GridInitialState {
    return {
        filter: {filterModel: {items: initModel.filter ?? []}},
        sorting: {sortModel: initModel.sort ?? []},
        columns: {
            columnVisibilityModel: initModel.columnVisibilityModel ?? {},
        },
    }
}

function useInitDatagrid(initModel: InitModel | undefined, apiRef: React.MutableRefObject<GridApi>) {
    /*
    restore/reset datagrid state with the new initModel whenever it changes

    - To avoid complexity with all users of the datagrid
    - At the same time avoid us controlling the state directly
    - Also since MUI Datagrid is using its own cache outside of React requires some special cases

     */
    useEffect(
        () => {
            if (initModel && apiRef.current) {
                apiRef.current.restoreState(createInitDataModel(initModel))
            }
        },
        [JSON.stringify(initModel)] // Make sure we compare the actual columns and filters, not the specific object reference!
    )
}

export default function DataGrid({
    rows,
    columns,
    loading,
    sx,
    id,
    showQuickFilter,
    toolbarButtons = undefined,
    initModel = undefined,
    ...props
}: DataGridProProps) {
    const apiRef = useGridApiRef()
    const dataGridName = id + '-' + window.location.pathname.replaceAll('/', '-')
    const [searchParams, setSearchParams] = useSearchParams()
    useInitDatagrid(initModel, apiRef)
    const classes = useStyles()
    const initState = useMemo<GridInitialState>(() => {
        let sp = searchParams.get(id)
        try {
            if (sp) {
                return JSON.parse(lzString.decompressFromEncodedURIComponent(sp))
            }

            if (initModel) {
                return createInitDataModel(initModel)
            }
            return {}
        } catch (e) {
            console.warn('Could not decode existing datagrid state', id, e)
            return {}
        }
    }, [dataGridName])

    const onCellEditCommit = (params: GridCellEditCommitParams, event, details) => {
        const editedCell = columns.find(column => column.field === params.field && typeof column.onCellEditCommit === 'function')
        if (editedCell) {
            // @ts-expect-error
            editedCell.onCellEditCommit(params, event, details)
        }
    }

    const onStateChange = () => {
        const {sorting, pagination, filter, columns, ...rest} = apiRef.current?.exportState() ?? {}
        const {columnVisibilityModel} = columns ?? {}
        if (!sorting && !pagination && !filter && !columnVisibilityModel) return

        const state = lzString.compressToEncodedURIComponent(
            JSON.stringify({sorting, pagination, filter, columns: {columnVisibilityModel}})
        )

        setSearchParams({[id]: state}, {replace: true})
    }
    const delayedOnStateChange = useMemo(() => throttle(onStateChange, 300), [])
    const currentDateTime = new Date()
    const getRowClassName = params => {
        const validFrom = new Date(params.row.validFrom)
        const validTo = new Date(params.row.validTo)

        return validFrom <= currentDateTime && currentDateTime <= validTo ? classes.highlightedRow : ''
    }

    return (
        <DataGridPremium
            key={dataGridName}
            apiRef={apiRef}
            loading={loading}
            sx={sx}
            pagination
            pageSize={20}
            onStateChange={delayedOnStateChange}
            initialState={initState}
            components={{Toolbar: DataGridToolbar, LoadingOverlay: LinearProgress}}
            rowsPerPageOptions={[20]}
            componentsProps={{toolbar: {specialActions: toolbarButtons}}}
            onCellEditCommit={onCellEditCommit}
            density='compact'
            rows={rows}
            columns={columns}
            getRowHeight={() => 'auto'}
            getRowClassName={getRowClassName}
            {...props}
        />
    )
}
