import * as React from 'react'
import {Page} from '../Components/Page'
import Panel from '../Components/Panel'
import {Outlet, Link, useLocation} from 'react-router-dom'
import Tab from '../Components/Tab'
import Tabs from '../Components/Tabs'
import {BreadcrumbsItem} from '../Components/Breadcrumbs'
import {breadcrumbsMap} from './utils/breadcrumbsMap'
import {ROUTES} from './constants/routes'

/*
 * Tabs have "Unstyled" versions, which might be easier to style! :)
 * https://mui.com/components/tabs/#unstyled
 *
 * Check out templates/Settings/Admin/groups.html.twig for an example of bootstrap tabs instead of MUI
 * Its also possible (and might be easier) to move the routes in here
 * - Then we would be able to code-split the PraksisNett code on demand in the future
 * - But for now I prefer to keep all routes in assets/App.js :)
 *
 */

export default function Overview() {
    const location = useLocation()

    const breadcrumbs: () => BreadcrumbsItem[] = () => {
        switch (location.pathname) {
            case ROUTES.STUDIES:
                return [breadcrumbsMap.home, breadcrumbsMap.studies]
            case ROUTES.OFFICES:
                return [breadcrumbsMap.home, breadcrumbsMap.offices]
            case ROUTES.DOCTORS:
                return [breadcrumbsMap.home, breadcrumbsMap.doctors]
            case ROUTES.COMPENSATIONS:
                return [breadcrumbsMap.home, breadcrumbsMap.compensations]
            case ROUTES.INVITATIONS:
                return [breadcrumbsMap.home, breadcrumbsMap.invitations]
            case ROUTES.PRAKSISNETT_USERS:
                return [breadcrumbsMap.home, breadcrumbsMap.praksisnett_users]
            default:
                return [breadcrumbsMap.home]
        }
    }

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs()}>
            <Panel variant='flat' style={{border: 'none'}}>
                <Tabs aria-label='nav tabs' value={location.pathname}>
                    <Tab attached={false} disableRipple value={ROUTES.STUDIES} label='Studier' to={'studies'} component={Link} />
                    <Tab attached={false} disableRipple value={ROUTES.DOCTORS} label='Leger' to={'doctors'} component={Link} />
                    <Tab attached={false} disableRipple value={ROUTES.OFFICES} label='Legekontor' to={'offices'} component={Link} />
                    <Tab
                        attached={false}
                        disableRipple
                        value={ROUTES.COMPENSATIONS}
                        label='Kompensasjon'
                        to={'compensations'}
                        component={Link}
                    />
                    <Tab
                        attached={false}
                        disableRipple
                        value={ROUTES.INVITATIONS}
                        label='Studieinvitasjoner'
                        to={'invitations'}
                        component={Link}
                    />
                    <Tab
                        attached={false}
                        disableRipple
                        value={ROUTES.PRAKSISNETT_USERS}
                        label='PraksisNett brukere'
                        to={'praksisnett_users'}
                        component={Link}
                    />
                </Tabs>
            </Panel>
            {/* TODO: how to handle /praksisnett/ case? */}
            <Outlet />
        </Page>
    )
}
