/*
Make sure bugfixes and enhancements here is replicated in ePortal as well
 */

import {handleErrorWithToast} from '../Utilities/errorHandlers'

async function parseContent(res) {
    let type = res.headers.get('Content-Type') || ''
    if (type.includes(';')) type = type.substr(0, type.indexOf(';'))

    const content = await res.text()

    if (res.status === 202 || res.status === 204) {
        return content
    }

    if (['application/json', 'application/ld+json'].includes(type)) {
        return content.length > 0 ? JSON.parse(content) : null
    }

    return content
}

type OptionType = RequestInit & {
    json?: {[key: string]: any}
    throwOn404?: boolean
    ignoreAuthErrors?: boolean
}

export async function jsonFetch<T>(uri: string, {json, ignoreAuthErrors, ...options}: OptionType = {}): Promise<T> {
    try {
        if (json) options.body = JSON.stringify(json)
        options.method = (options.method ?? (options.body ? 'POST' : 'GET')).toUpperCase()

        if (options.method === 'GET' && options.body) {
            console.warn('Sending GET request with Body!', {body: options.body})
        }

        const contentType = options.method === 'PATCH' ? 'application/merge-patch+json' : 'application/ld+json'

        const res = await fetch(uri, {
            headers: {Accept: 'application/ld+json, application/json', 'Content-Type': contentType},
            ...options,
        })

        let content = await parseContent(res)

        if (res.status >= 500 || res.status === 400) {
            if (content && content['@type'] === 'hydra:Error') {
                console.error(content)
                return Promise.reject(new Error(content['hydra:title'] + ': ' + content['hydra:description']))
            } else {
                return Promise.reject(new Error(res.statusText))
            }
        }

        if (!ignoreAuthErrors && res.status === 401) {
            const redirectTarget = res.headers.get('WWW-Authenticate')
            if (redirectTarget) {
                console.log('res', res)
                console.log('res.headers', res.headers)
                console.log('jsonFetch Redirecting to', redirectTarget)
                setTimeout(() => (window.location.href = redirectTarget), 5000)
            }

            let error = new Error('Du er ikke innlogget eller mangler nødvendige rettigheter for denne handlingen.')
            // @ts-expect-error // We check and use this type later on...
            error.toastType = 'error'
            // this error shows first in the toast stack, maybe it is unnecessary to having it double up as another error toast is also shown
            handleErrorWithToast(error)
            return Promise.reject(error)
        }

        if (options.throwOn404 && res.status === 404) {
            return Promise.reject(new Error('Ressursen mangler (404)'))
        }

        if (res.status === 409 && content) {
            return Promise.reject(new Error(content.error))
        }

        return content
    } catch (e: unknown) {
        // @ts-expect-error please fix...
        if (e?.name === 'AbortError' || e.code === 20) return Promise.reject(e)
        // @ts-expect-error please fix...
        if (e?.name === 'NetworkError') return Promise.reject(e)

        console.error(e)
        return Promise.reject(e)
    }
}
