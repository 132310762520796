import * as React from 'react'
import Grid from '@mui/material/Grid'
import {Button} from '../../Components/Button/MuiButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from 'styled-components'
import {useEffect, useRef} from 'react'
import {Dialog, DialogActions, DialogContent, Typography} from '@mui/material'

const StyledContainer = styled.div`
    img {
        margin-bottom: 1em;
    }
`

interface Props {
    title: string
    message: string
    onClose: Function
    onRemove?: Function
    isOpen: boolean
}

export default function PreviewNewsPopupDialog({title, message, onClose, onRemove, isOpen = false}: Props) {
    const divRef = useRef<HTMLDivElement>()
    const bigScreen = useMediaQuery('(min-width:600px)')

    useEffect(() => {
        divRef.current?.querySelectorAll('a').forEach(el => {
            el.setAttribute('target', '_blank')
        })
    })

    const onLocalClose = () => {
        onClose(false)
    }

    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
            onClose={onLocalClose}
            sx={{
                div: {
                    borderRadius: '5px',
                },
            }}
        >
            <DialogContent sx={{paddingX: 2, maxWidth: bigScreen ? '25em' : '20em', minWidth: bigScreen ? '25em' : '20em'}}>
                <Typography>
                    <h3>{title}</h3>
                </Typography>
                <Grid container spacing={2} mt={2} sx={{marginLeft: '0', width: '100%'}}>
                    <Grid
                        container
                        style={{
                            backgroundColor: '#fff',
                        }}
                    >
                        <Grid item xs={12}>
                            <StyledContainer
                                id={'news_popups_message'}
                                name={'news_popups_message'}
                                // @ts-expect-error
                                ref={divRef}
                                dangerouslySetInnerHTML={{__html: message}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 2, paddingBottom: 2, display: 'flex', justifyContent: 'space-between'}}>
                <Button variant='outlined' onClick={() => onLocalClose()}>
                    <i className='fa fa-1x fa-eye-slash'></i> Vis senere
                </Button>
                <Button variant='contained' onClick={() => onLocalClose()}>
                    <i className='fa fa-1x fa-trash'></i> Fjern
                </Button>
            </DialogActions>
        </Dialog>
    )
}
