import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {Button} from '../../../Components/Button/MuiButton'
import * as React from 'react'
import {SetState} from '../../../Utilities/TypeHelpers'
import DataGrid from '../../../Components/DataGrid/DataGrid'
import {DataGridPremiumProps, GridColumns, GRID_DETAIL_PANEL_TOGGLE_COL_DEF} from '@mui/x-data-grid-premium'
import columnDef from '../../../Components/DataGrid/columnDefs/columnDef'
import {ViewHeightWrapper} from '../../../Components/DataGrid/Wrappers'
import {Box} from '../../../Components/Panel'
import dateColumnDef from '../../../Components/DataGrid/columnDefs/dateColumnDef'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {stringify} from 'yaml'

interface ChangelogDialogProps {
    setOpen: SetState<boolean>
    isOpen: boolean
    data: any
    isLoading?: boolean
}

export const ChangelogDialog = ({setOpen, data, isLoading = false, isOpen = false}: ChangelogDialogProps) => {
    const changelogItems = data ? data['hydra:member'] : []

    const handleDialogClose = () => {
        setOpen(false)
    }

    const columns: GridColumns = [
        dateColumnDef({field: 'createdAt', headerName: 'Dato', type: 'dateTime', maxWidth: 150}),
        columnDef({field: 'operation', headerName: 'Hendelse'}),
        columnDef({field: 'createdBy.name', headerName: 'Utført av'}),
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            headerName: 'Vis detaljer',
            width: 100,
            align: 'center',
        },
    ]

    const DetailPanelContent = ({row}) => {
        return (
            <Box>
                {row.payload ? (
                    <div>
                        <pre>{stringify(row.payload, null, 2)}</pre>
                    </div>
                ) : (
                    'no payload'
                )}
            </Box>
        )
    }

    const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = React.useCallback(
        ({row}) => <DetailPanelContent row={row} />,
        []
    )

    const getDetailPanelHeight = React.useCallback(params => {
        const payload = params.row.payload ?? ''
        const payloadLines = stringify(payload, null, 3).split(/\r\n|\r|\n/).length
        return payloadLines * 18.6 + 45
    }, [])

    return (
        <div>
            <Dialog maxWidth='lg' fullWidth open={isOpen} onClose={handleDialogClose}>
                <DialogTitle>Endringslogg</DialogTitle>
                <DialogContent sx={{pb: 0}}>
                    <ViewHeightWrapper viewHeight='md' sx={{pt: 1}}>
                        <DataGrid
                            loading={isLoading}
                            id={`changelog-${name}`}
                            rows={changelogItems}
                            columns={columns}
                            getDetailPanelContent={getDetailPanelContent}
                            getDetailPanelHeight={getDetailPanelHeight}
                            components={{DetailPanelExpandIcon: ExpandMoreIcon, DetailPanelCollapseIcon: ExpandLessIcon}}
                        />
                    </ViewHeightWrapper>
                </DialogContent>
                <DialogActions sx={{px: 3, pb: 2}}>
                    <Button variant='contained' onClick={() => setOpen(false)}>
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
