import {
    GridColDef,
    GridComparatorFn,
    GridValueGetterParams,
    GridCellEditCommitParams,
    GridCallbackDetails,
    GridCellParams,
} from '@mui/x-data-grid-premium'
import format from 'date-fns/format'
import {DATE_TIME_FORMAT, DATE_FORMAT} from '../../../State/utils/constants'
import {resolve} from './columnDef'
import {EditableCell} from '../editableCell'
import compareAsc from 'date-fns/compareAsc'
import isValid from 'date-fns/isValid'

const dateRenderCell =
    (callback, formatStr, editable = false, noDateMessage = '') =>
    params => {
        if (callback) return callback(params)

        let dateValue: any = params.value && isValid(params.value) ? format(params.value, formatStr) : noDateMessage

        if (editable) {
            dateValue = EditableCell(dateValue, '', 'left')
        }

        return dateValue
    }

const dateComparator =
    (callback): GridComparatorFn =>
    (v1, v2, cellParams1, cellParams2) => {
        if (callback) return callback(v1, v2)

        const val1 = v1 instanceof Date ? v1 : 0
        const val2 = v2 instanceof Date ? v2 : 0

        return compareAsc(val1, val2)
    }
const dateValueGetter = getter => (params: GridValueGetterParams) => {
    const value = getter ? getter(params) : resolve(params.field, params.row)
    const date = value ? new Date(value) : null

    if (date && !isValid(date)) {
        console.error('DataGrid field has invalid date!', {field: params.field, value, row: params.row})
        return null
    }
    return date
}

type Column = GridColDef & {
    type?: 'date' | 'dateTime'
}

// Types for committing edited fields
type OnEditCommitCallback = (params: GridCellEditCommitParams, event: any, details: GridCallbackDetails) => any

type EditDef = {
    onCellEditCommit: OnEditCommitCallback
}

export type EditableGridColDef = GridColDef & {onCellEditCommit?: OnEditCommitCallback}

export default function dateColumnDef(col: Column, edit?: EditDef, noDateMessage?: string): EditableGridColDef {
    const showTime = col.type === 'dateTime'
    const formatStr = showTime ? DATE_TIME_FORMAT : DATE_FORMAT
    const valueGetter = dateValueGetter(col.valueGetter)
    const sortComparator = dateComparator(null)
    const renderCell = dateRenderCell(col.renderCell, formatStr, Boolean(edit), noDateMessage)

    const getApplyDateQuickFilterFn = (value: string) => {
        if (!value || value.length > 10 || !/\d+/.test(value) || /.*[a-zA-Z].*/.test(value)) {
            // The filter value cannot be a date
            return null
        }
        return (params: GridCellParams): boolean => {
            return params.value ? format(params.value, formatStr).includes(value) : false
        }
    }

    return {
        editable: !!edit?.onCellEditCommit,
        onCellEditCommit: edit?.onCellEditCommit,
        flex: showTime ? 2 : 1,
        headerName: 'Dato',
        ...col,
        valueGetter,
        sortComparator,
        renderCell,
        getApplyQuickFilterFn: getApplyDateQuickFilterFn,
        // This affects filtering, keep "dateTime" if we want to filter on a specific time, and not only a date
        type: 'date',
    }
}
