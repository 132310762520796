import {useSkilQuery} from '../Utilities/QueryClient'
import * as React from 'react'

export type CountyOptionsTypeForColumnDef = {
    label: string
    value: number
}

export const CountiesExportToAreaColumnDef = hasLoaded => {
    const countiesQueryResult = useSkilQuery<'getCountyCollection'>(`/api/counties`, {pagination: false}, {enabled: !hasLoaded})

    const counties = React.useMemo(() => {
        const countiesRaw = countiesQueryResult.data?.['hydra:member'] ?? []
        return Object.values(countiesRaw)
            .map(county => {
                return {label: county.name, id: county.id, region: county.region, regionId: county.regionId}
            })
            .sort(function (a, b) {
                if (a.region < b.region) {
                    return -1
                }
                if (a.region > b.region) {
                    return 1
                }
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
    }, [countiesQueryResult])

    return counties
}
