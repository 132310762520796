import {createTheme, ThemeOptions, PaletteOptions, SimplePaletteColorOptions} from '@mui/material/styles'
import {TypographyOptions} from '@mui/material/styles/createTypography'
import {nbNO} from '@mui/material/locale'
import {nbNO as dataGridNO} from '@mui/x-data-grid-premium'
import {Color} from '@mui/material'
import '@mui/x-data-grid-premium/themeAugmentation'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        quiet: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        quiet?: React.CSSProperties
    }

    interface Theme {
        size: {
            viewHeight: {
                xs: string
                sm: string
                md: string
                lg: string
                xl: string
            }
        }
    }
    interface ThemeOptions {
        size: {
            viewHeight: {
                xs: string
                sm: string
                md: string
                lg: string
                xl: string
            }
        }
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        quiet: true
    }
}

interface DefaultPaletteOptions extends PaletteOptions {
    primary: SimplePaletteColorOptions
    secondary: SimplePaletteColorOptions
    warning: SimplePaletteColorOptions
    info: SimplePaletteColorOptions
    success: SimplePaletteColorOptions
    grey: Partial<Color>
}

const {palette: defaultPalette, typography: defaultTypography} = createTheme()

const skilPaletteConfig: DefaultPaletteOptions = {
    ...defaultPalette,
    primary: {
        main: '#052a30',
        light: '#97acb0',
    },
    secondary: {
        main: '#b07952',
    },
    text: {
        primary: '#333333',
        secondary: '#021012',
    },
    background: {
        default: '#f7f7f7',
        paper: '#fff',
    },
    error: {
        main: '#ab3e36',
    },
    warning: {
        main: '#eb9565',
    },
    info: {
        main: 'hsl(202, 71%, 52%)',
    },
    success: {
        main: '#27AE60',
    },
}

const spacingUnit = 8

const skilTypographyConfig: TypographyOptions = {
    ...defaultTypography,
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
        fontSize: 4.5 * spacingUnit,
        fontWeight: 700,
        marginBottom: 2 * spacingUnit,
    },
    h2: {
        fontSize: 3.25 * spacingUnit,
        fontWeight: 700,
        marginBottom: 1.5 * spacingUnit,
    },
    h3: {
        fontSize: 2.75 * spacingUnit,
        fontWeight: 500,
        marginBottom: 1.25 * spacingUnit,
    },
    subtitle1: {
        fontSize: 2.75 * spacingUnit,
        fontWeight: 400,
        lineHeight: '1em',
        marginTop: -spacingUnit,
        marginBottom: 2.5 * spacingUnit,
    },
    subtitle2: {
        fontSize: 2.25 * spacingUnit,
        fontWeight: 400,
        marginTop: -spacingUnit,
        marginBottom: 1.75 * spacingUnit,
    },
    body1: {
        fontWeight: 400,
        lineHeight: 1.4,
        fontSize: 2 * spacingUnit,
        marginBottom: 1 * spacingUnit,
    },
    body2: {
        fontSize: 1.75 * spacingUnit,
    },
    quiet: {
        fontWeight: 200,
        fontStyle: 'italic',
    },
}

export const skilThemeConfig: ThemeOptions = {
    size: {
        viewHeight: {
            xs: `30vh`,
            sm: `50vh`,
            md: `70vh`,
            lg: `90vh`,
            xl: '100vh',
        },
    },
    palette: skilPaletteConfig,
    typography: skilTypographyConfig,
    components: {
        MuiPaper: {
            defaultProps: {
                sx: {
                    borderRadius: "5px",
                }
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                    lineHeight: 'initial',
                    // hover state added here to override Bootstrap a:hover color
                    '&.MuiButton-containedPrimary:hover': {
                        color: `${skilPaletteConfig?.background?.paper}`,
                    },
                    '&:focus, &.MuiButtonGroup-grouped:focus': {
                        // using secondary colour here for now because it works equally well on light and dark buttons.
                        outline: `3px solid ${skilPaletteConfig.secondary.main}`,
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiCardActionArea: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: skilPaletteConfig.grey[50],
                    },
                    '&.Mui-disabled': {
                        // replace with a gray from MUI when we drop the Bootstrap color palette
                        backgroundColor: '#f7f7f7',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    fontSize: '1rem',
                    '& .MuiDataGrid-main': {
                        background: '#fff',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 700,
                    },
                    '& .MuiDataGrid-row:nth-child(2n - 1)': {
                        backgroundColor: skilPaletteConfig.grey[100],
                    },
                    '& > .MuiDataGrid-main .MuiDataGrid-row:hover, & > .MuiDataGrid-main .MuiDataGrid-row.Mui-hovered': {
                        backgroundColor: skilPaletteConfig.grey[50],
                    },
                    '& p.MuiTablePagination-displayedRows': {
                        marginBottom: 0,
                    },
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    square: true,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: '15px !important',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'normal',
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiInput-underline:after': {
                        borderBottomColor: skilPaletteConfig.primary,
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    '& .Mui-focused': {
                        color: skilPaletteConfig.primary,
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: skilPaletteConfig.primary.light, // TODO: figure out how to handle shades of Skil Green
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: true,
            },
        },
        MuiTab: {
            defaultProps: {},
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: skilTypographyConfig.fontWeightMedium,
                    borderRight: 'none',
                    fontSize: '1rem', // TODO: revisit when looking at typography/fonts

                    '&:hover': {
                        opacity: 1,
                        textDecoration: 'none',
                    },
                    '&.Mui-selected': {
                        fontWeight: skilTypographyConfig.fontWeightBold,
                        borderRight: `1px solid ${skilPaletteConfig.grey[300]}`,
                    },
                    '&.Mui-focusVisible': {
                        backgroundColor: '#d1eaff', // TODO: use themed colours in the future
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'p',
                    subtitle2: 'p',
                    body1: 'p',
                    body2: 'p',
                },
            },
        },
    },
}

const skilTheme = createTheme(skilThemeConfig, nbNO, dataGridNO)

export default skilTheme
