import {components} from '../Generated/eportal'
import {SkilQueryResponseType, useSkilQuery} from '../Utilities/QueryClient'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export type CourseType = SkilQueryResponseType<'getCourseCollection'>['hydra:member'][number]
type CourseAutocompleteProps = {
    value: string | null
    onChange: (event, course: CourseType, iri: string) => any
    disabled?: boolean
    required?: boolean
}
export const CourseAutocomplete = ({value, onChange, disabled = false, required = false}: CourseAutocompleteProps) => {
    const {data, isFetching} = useSkilQuery<'getCourseCollection'>('/api/courses', {pagination: false})
    const courses =
        data?.['hydra:member'].sort(function (a, b) {
            if (a.shortTitle < b.shortTitle) {
                return -1
            }
            if (a.shortTitle > b.shortTitle) {
                return 1
            }
            return 0
        }) ?? []
    const localValue = courses.find(course => course['@id'] === value) ?? null

    const onLocalChange = (event, newValue) => {
        return onChange(event, newValue, newValue?.['@id'] ?? null)
    }

    return (
        <Autocomplete
            id={'course'}
            value={localValue}
            disabled={disabled}
            onChange={onLocalChange}
            disableClearable={required}
            options={courses}
            getOptionLabel={course => course.shortTitle + ': ' + course.name}
            renderInput={params => (
                <TextField
                    required={required}
                    {...params}
                    label='Aktivt kurs'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
