import * as React from 'react'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'

export const computeDisabled = (onClick?: any, href?: string, to?: string, disabled?: boolean, working?: boolean, type?: string) => {
    return (!onClick && !href && !to && type !== "submit") || disabled || working
}

export const useClickHandler = onClick => {
    const [working, setWorking] = React.useState(false)

    const handleClick = async event => {
        try {
            setWorking(true)
            const message = await onClick(event)

            if (typeof message === 'string') {
                toast(message, {type: 'info'})
            } else if (message instanceof Error) {
                handleErrorWithToast(message)
            }
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            setWorking(false)
        }
    }

    return {
        handleClick: onClick ? handleClick : undefined,
        working,
    }
}

export const useConfirmClickHandler = (onClick, children: React.ReactNode, confirmMessage?: React.ReactNode) => {
    const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>()
    const [askConfirmation, setAskConfirmation] = React.useState(false)

    React.useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                setAskConfirmation(false)
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    // First click
    const onDefaultClick = event => {
        if (!confirmMessage) {
            return onClick(event)
        }

        setAskConfirmation(true)
        timeoutRef.current = setTimeout(() => setAskConfirmation(false), 5000)

        event.preventDefault()
        event.stopPropagation()
        return false
    }

    // Second click
    const onConfirmClick = event => {
        onClick(event)
        if (timeoutRef.current) {
            setAskConfirmation(false)
            clearTimeout(timeoutRef.current)
            timeoutRef.current = undefined
        }
    }

    const isConfirmState = confirmMessage && askConfirmation
    const handleClick = isConfirmState ? onConfirmClick : onDefaultClick

    return {
        handleClick: onClick ? handleClick : undefined,
        children: isConfirmState ? confirmMessage : children,
    }
}

export const asyncSleep = timeout => new Promise(resolve => setTimeout(resolve, timeout))
