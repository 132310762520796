import React, {useEffect, useRef, useState} from 'react'
import {Navigation} from './Navigation'
import FormGroup from './FormGroup'
import useEntity from '../../Hooks/useEntity'
import useEntities from '../../Hooks/useEntities'
import LoadingComponent from '../../Components/LoadingComponent'
import {useParams, useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'
import {Page} from '../../Components/Page'
import {Reorder} from 'framer-motion'
import {commitListOrder, resetListOrder} from '../../State/actions/changes'
import {useDispatch} from 'react-redux'
import {jsonFetch} from '../../Components/jsonFetch'
import {debounce} from 'lodash'

export const EditLockContext = React.createContext<any>(undefined)

function createBreadcrumbs(location, shortTitle, form) {
    let isSKILRoute = location.pathname?.includes('/SKIL')

    let courseDetailsOrSKILBreadcrumb = isSKILRoute
        ? {
              title: 'SKIL dashboard',
              href: '/dashboard/SKIL',
          }
        : {
              title: 'Kurs detaljer',
              href: '/dashboard/course/' + shortTitle,
          }
    let courseSettingsOrFormBreadcrumb = isSKILRoute
        ? {
              title: form.name,
              href: '',
          }
        : {
              title: 'Kurs innstillinger',
              href: `/dashboard/course/${shortTitle}/settings`,
          }

    const breadcrumbs = [courseDetailsOrSKILBreadcrumb, courseSettingsOrFormBreadcrumb]

    if (!isSKILRoute) {
        breadcrumbs.push({title: form.name, href: ''})
    }
    return breadcrumbs
}

const debouncedJsonFetch = debounce(jsonFetch, 1000)

type IndicatorType = {
    number: string | {number: string}
}

const Form = () => {
    const {formId, groupId, shortTitle} = useParams()
    const lock = React.useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const form = useEntity<'getFormItem'>(`/api/forms/${formId}`)
    const groups = useEntities<'getFormIndicatorGroupCollection'>(`/api/forms/${formId}/indicator_groups?disabled=false`, false, true)
    const selectedGroup = groups.find(g => g.id === Number(groupId))
    const sortedGroups = groups.sort((a, b) => a.order - b.order)
    const constraintsRef = useRef(null)
    const dispatch = useDispatch()
    const [indicatorsWithMissingDependencies, setIndicatorsWithMissingDependencies] = useState<IndicatorType[] | null>(() => {
        const localStorageMissingDependencies = window.localStorage.getItem('missingDependencies-' + formId)
        if (localStorageMissingDependencies) {
            return JSON.parse(localStorageMissingDependencies)
        }
        return null
    })
    const [updatingIndicatorGroups, setUpdatingIndicatorGroups] = useState(false)
    const getGroupUrl = targetGroupId => {
        let path = location.pathname
        if (groupId) path = path.substr(0, path.lastIndexOf('/'))

        path.replace(new RegExp('/+$'), '')

        if (!targetGroupId) return path

        return `${path}/${targetGroupId}`
    }

    const onRemoveIndicatorGroup = iri => {
        groups.remove(iri).then(() => {
            setUpdatingIndicatorGroups(true)
            navigate(getGroupUrl(''), {replace: true})
        })
    }

    useEffect(() => {
        if (!groups['@loaded']) return

        if (!groupId && groups.length > 0) {
            navigate(getGroupUrl(groups[0].id), {replace: true})
        }
    }, [groupId, groups])

    const breadcrumbs = createBreadcrumbs(location, shortTitle, form)

    const onReorder = items => {
        let groups = items.map(i => i['@id'])
        dispatch(commitListOrder(groups))

        try {
            debouncedJsonFetch(form['@id'] + '/update_group_order', {
                json: {groups},
            })
        } catch (e) {
            dispatch(resetListOrder(groups))
        }
    }

    if (!form['@loaded']) return <LoadingComponent />

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <EditLockContext.Provider value={lock}>
                <div className={'col-md-4 pull-right'}>
                    <Navigation
                        groups={groups}
                        form={form}
                        shortTitle={shortTitle}
                        selectedGroup={selectedGroup}
                        indicatorsWithMissingDependencies={indicatorsWithMissingDependencies}
                        setIndicatorsWithMissingDependencies={setIndicatorsWithMissingDependencies}
                        setUpdatingIndicatorGroups={setUpdatingIndicatorGroups}
                    >
                        <Reorder.Group className={'list-group'} axis={'y'} values={sortedGroups} onReorder={onReorder}>
                            {sortedGroups.map(item => (
                                <Navigation.Item
                                    key={item['@id']}
                                    value={item}
                                    onRemove={() => onRemoveIndicatorGroup(item['@id'])}
                                    iri={item['@id']}
                                    to={getGroupUrl(item.id)}
                                />
                            ))}
                        </Reorder.Group>
                    </Navigation>
                </div>
                <div className={'col-md-8 pull-left'}>
                    {selectedGroup && (
                        <FormGroup
                            iri={selectedGroup['@id']}
                            form={form}
                            indicatorsWithMissingDependencies={indicatorsWithMissingDependencies}
                            updatingIndicatorGroups={updatingIndicatorGroups}
                            setUpdatingIndicatorGroups={setUpdatingIndicatorGroups}
                        />
                    )}
                </div>
            </EditLockContext.Provider>
        </Page>
    )
}
export default Form
