import {useSkilQuery} from '../Utilities/QueryClient'
import * as React from 'react'

// lists all professions that uses HPR number
// TODO:: create a table for this list

export const HprProfessionsExport = () => {
    const professions = [
        'ambulansearbeider',
        'apotektekniker',
        'audiograf',
        'bioingeniør',
        'ergoterapeut',
        'fotterapeut',
        'fysioterapeut',
        'helsefagarbeider',
        'helsesekretær',
        'hjelpepleier',
        'jordmor',
        'kiropraktor',
        'klinisk ernæringsfysiolog',
        'lege',
        'manuellterapeut',
        'naprapat',
        'omsorgsarbeider',
        'optiker',
        'ortopediingeniør',
        'ortoptist',
        'osteopat',
        'paramedisiner',
        'perfusjonist',
        'provisorfarmasøyt',
        'psykolog',
        'radiograf',
        'reseptarfarmasøyt',
        'sykepleier',
        'tannhelsesekretær',
        'tannlege',
        'tannpleier',
        'tanntekniker',
        'vernepleier',
        'fastlege',
        'sykehuslege',
        'kommuneoverlege',
        'lis1 (turnuslege)',
        'turnuslege',
        'sykehjemslege',
        'fastlegevikar',
    ]

    return professions
}
