import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchEntity} from '../State/actions/entity'
import {createEntity, deleteEntity} from '../State/actions/api'
import {SkilQueryResponseType, SkilQueryResponseTypes} from '../Utilities/QueryClient'
import {Dispatch} from 'redux'

// @ts-expect-error (it cant now hydra:member always exists)
type CollectionType<Top> = SkilQueryResponseType<Top>['hydra:member'][number]

export type UseEntitiesType<T> = Array<T> & {
    '@loaded': boolean
    create: (data, customIri?: string) => Promise<any>
    remove: (itemIri: string) => Promise<any>
    refresh: (itemIri?: string) => Promise<any>
}

/** @deprecated use useSkilQuery instead! */
function useEntities<Top extends keyof SkilQueryResponseTypes>(
    iri?: string | null,
    ttl: number | false = false,
    useLocalChanges: boolean = false
): UseEntitiesType<CollectionType<Top>> {
    const dispatch = useDispatch()
    // @ts-expect-error
    const entities = useSelector(state => state.entity[iri])

    useEffect(() => {
        if (!entities && iri) {
            dispatch(fetchEntity(iri, false, ttl))
        }
    }, [iri, entities])

    useEffect(() => {
        if (!entities || !entities['@expiresAt']) return

        const duration = entities['@expiresAt'] - Date.now()
        const timeout = setTimeout(() => {
            dispatch(fetchEntity(iri, true, entities['@ttl']))
        }, duration)

        return () => clearTimeout(timeout)
    }, [entities, iri, ttl])

    const Create = async (data, customIri) => {
        // The core of createEntity is handled in middlewares/api.js and actually returns a promise
        // noinspection ES6RedundantAwait
        const results = await dispatch(createEntity(customIri ? customIri : iri, data))
        dispatch(fetchEntity(iri, true))
        return results
    }
    const Remove = async itemIri => {
        // The core of deleteEntity is handled in middlewares/api.js and actually returns a promise
        // noinspection ES6RedundantAwait
        await dispatch(deleteEntity(itemIri))
        return dispatch(fetchEntity(iri, true))
    }

    const Refresh = async (customIri = undefined) => {
        return dispatch(fetchEntity(customIri ? customIri : iri, true))
    }

    const hasList = entities && entities['@type'] === 'hydra:Collection' && entities['hydra:member']
    let list = hasList ? entities['hydra:member'] : []
    list = useSelector(state =>
        list.map(item => {
            if (!useLocalChanges) return item

            // @ts-expect-error
            const currentItem = state.entity[item['@id']]

            // @ts-expect-error
            const currentChanges = state.changes[item['@id']]

            if (!currentChanges) return currentItem

            //console.log("Changes to " + iri,currentChanges, item)
            return {
                ...currentItem,
                ...currentChanges,
            }
        })
    )
    list['@loaded'] = !!entities
    list.create = Create
    list.remove = Remove
    list.refresh = Refresh
    return list
}
export default useEntities
