import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridCellEditCommitParams, GridColDef, GridActionsColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import userColumnDef from '../../Components/DataGrid/columnDefs/userColumnDef'
import areaColumnDef from '../../Components/DataGrid/columnDefs/areaColumnDef'
import FindUserModal from '../../Components/FindUserModal'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {DeleteIconButton} from '../../Components/Button/DeleteIconButton'
import {CountiesExportToAreaColumnDef} from '../../Components/CountiesExportToAreaColumnDef'
import {useEffect} from 'react'

const Counselors = () => {
    const {data, isFetching, refetch} = useSkilQuery<'getCounselorCollection'>('/api/counselors', {pagination: false})
    const counselors = data?.['hydra:member'] ?? []
    const [showAddCounselor, setShowAddCounselor] = React.useState(false)
    const addCounselorMutation = useSkilMutation<'addCounselor'>('POST', `/api/counselors`, [`/api/counselors`])
    const deleteCounselorMutation = useSkilMutation<'deleteCounselor'>('DELETE', `/api/counselors/`, [`/api/counselors`])
    const updateCounselorMutation = useSkilMutation<'updateCounselor'>('PATCH', `/api/counselors/{id}`, [`/api/counselors`])
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const counties = CountiesExportToAreaColumnDef(hasLoaded)

    const handleDeleteCounselor = async id => {
        try {
            await deleteCounselorMutation.mutateAsync({
                '@overridePath': `/api/counselors/${id}`,
            })
            toast('Veileder slettet', {
                type: 'success',
            })
            refetch()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const onCellEditCommit = async (params: GridCellEditCommitParams) => {
        try {
            await updateCounselorMutation.mutateAsync({
                '@overridePath': `/api/counselors/${params.id}`,
                [params.field]: params.value,
            })
            refetch()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const onAddCounselor = async addCounselor => {
        try {
            await addCounselorMutation.mutateAsync(addCounselor)
            setShowAddCounselor(false)
            refetch()
                .then(() => {})
                .catch(() => {})
            toast('Veilederen ble opprettet', {type: 'success'})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const columns: (GridColDef | GridActionsColDef)[] = [
        columnDef({field: 'id', headerName: 'ID', hide: true, type: 'number'}),
        userColumnDef({field: 'user', headerName: 'Bruker'}),
        areaColumnDef({
            field: 'user.location',
            type: 'county',
            counties: counties.map(county => ({label: county.label, value: county.id})),
        }),
        areaColumnDef({
            field: 'user.location',
            type: 'region',
            counties: counties.map(county => ({label: county.region, value: county.regionId})),
        }),
        columnDef({field: 'active', headerName: 'Aktiv', type: 'boolean'}, {onCellEditCommit}),
        columnDef({field: 'KUL', headerName: 'KUL', type: 'boolean'}, {onCellEditCommit}),
        columnDef({field: 'description', headerName: 'Beskrivelse'}, {onCellEditCommit}),
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Handlinger',

            getActions: ({id}) => {
                return [
                    <DeleteIconButton
                        key={`del${id}`}
                        deleteMenuItemLabel='Slett veileder'
                        tooltipText='Slett'
                        menuId={`del${id}`}
                        deleteAction={() => handleDeleteCounselor(id)}
                    />,
                ]
            },
        },
    ]

    const counselorActions: SpecialActionProps[] = [
        {
            label: 'Legg til veileder',
            action: () => setShowAddCounselor(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
    ]

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && counties?.length > 0) {
            setHasLoaded(true)
        }
    }, [counties])

    return (
        <Box>
            <DataGridWrapper rows={counselors.length}>
                <DataGrid
                    id={'counselors'}
                    loading={isFetching}
                    rows={counselors}
                    columns={columns}
                    initModel={{columnVisibilityModel: {id: false}}}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={counselorActions} />}
                />
            </DataGridWrapper>
            {showAddCounselor && (
                <FindUserModal
                    onSave={onAddCounselor}
                    onClose={() => setShowAddCounselor(false)}
                    disableNewUser
                    saveLabel={'Legg til veileder'}
                />
            )}
        </Box>
    )
}

export default Counselors
