import {useParams} from 'react-router'
import useEntity from '../Hooks/useEntity'
import useEntities from '../Hooks/useEntities'
import LoadingComponent from '../Components/LoadingComponent'
import {jsonFetch} from '../Components/jsonFetch'
import {AnswerValueType, AppAnswerSetType, NewLocationType, ResponseAnswerType} from './Types'
import {toast} from 'react-toastify'
import React, {useEffect} from 'react'
import {App, saveChanges} from './Form/App'
import {SkilQueryResponseType, useSkilQuery} from '../Utilities/QueryClient'

export const FormTaskApp = props => {
    const task = useEntity<'getFormTaskItem'>(`/api/form_tasks/${props.taskId}`)
    const form = useEntity<'getFormItem'>(task?.form)
    const [answerSetId, setAnswerSetId] = React.useState<number>(0)
    const dependencies = useEntities<'getFormIndicatorDependencyCollection'>(task.form ? `${task.form}/indicator_dependencies` : null)
    const indicators = useEntities<'getFormIndicatorCollection'>(task.form ? `${task.form}/indicators?disabled=0` : null)
    const groups = useEntities<'getFormIndicatorGroupCollection'>(task.form ? `${task.form}/indicator_groups?disabled=0` : null)
    // when the answerSetId changes, we need to fetch the answerSet
    // if the answerSetId is 0, the backend will create a new answerSet
    // if the answerSetId is not 0, the backend will return the answerSet with that id
    // the answerSetId is set to 0 when the form renders for the first time
    const answerSet = useEntity<'findAnswerSet'>(
        form.id && props.taskId ? `/api/answer_sets/find?form=${form.id}&task=${props.taskId}&answerSetId=${answerSetId}` : null
    )
    const [isWorking, setIsWorking] = React.useState(false)

    useEffect(() => {
        if (answerSet?.id) {
            setAnswerSetId(answerSet?.id)
        }
    }, [answerSet?.id])

    const onSubmit = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        newLocation?: NewLocationType
    ): Promise<AppAnswerSetType> => {
        const isApproved = answerSet && answerSet['approved']
        if (isApproved) {
            return {
                answers: answerSet.answers ?? [],
                newLocation: newLocation ?? undefined,
                approved: true,
            }
        }

        const response = await jsonFetch<SkilQueryResponseType<'submitAnswerSet', 201>>(
            `${answerSet['@id']}?form=${form.id}&task=${task?.id}`,
            {
                json: {
                    indicators: answers,
                    location: newLocation,
                },
            }
        )

        return {
            newLocation: response.newLocation,
            approved: response.approved,
            answers: response.answers,
        }
    }

    const onChangeLocation = async location => {
        if (isWorking || !answerSet['@id'] || !answerSet['id'] || answerSet['approved']) {
            return
        }
        try {
            setIsWorking(true)
            await jsonFetch(answerSet['@id'], {
                method: 'PUT',
                json: {location, indicators: {}},
            })
        } catch (e) {
            toast((e as Error).message, {type: 'error'})
        } finally {
            setIsWorking(false)
        }
    }

    const onChange = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        save: boolean,
        newLocation?: NewLocationType
    ): Promise<Array<string>> => {
        // Only save if we have id, and the answerSet is not approwed
        // ID means it's not anonymous and approved means it have been submitted before and completed
        if (answerSet['approved'] || !answerSet['@id'] || !answerSet['id']) {
            return []
        }
        if (isWorking) {
            return []
        }

        // if the task is anonymous, we don't want to save anything onChange because we do not know the answerSet, only when submitting
        if (task['anonymous'] === true) {
            return []
        }

        setIsWorking(true)
        // Save all unsaved answers from local storage
        // Only submit one save request at a time / de-throttle put requests

        try {
            type PatchAnswerSet = SkilQueryResponseType<'updateAnswerSet'>
            const results = await saveChanges<PatchAnswerSet>(answerSet['@id'], answers, newLocation)
            // return the indicators that have been saved
            if (typeof results.answers === 'object') {
                // find the indicators that have been saved
                // @ts-expect-error
                const responseAnswers: ResponseAnswerType[] = Object.values(results?.answers)
                return responseAnswers
                    ?.filter(answer => Object.entries(answers).some(([key, value]) => key === answer.indicator && value === answer.value))
                    .map(answer => answer.indicator!)
            }
            if (Array.isArray(results?.answers)) {
                const returnedAnswers = results?.answers as Array<any>
                return returnedAnswers
                    .filter(answer => Object.entries(answers).some(([key, value]) => key === answer.indicator && value === answer.value))
                    .map(answer => answer.indicator!)
            } else {
                return []
            }
        } catch (e) {
            toast((e as Error).message, {type: 'error'})
            return []
        } finally {
            setIsWorking(false)
        }
    }

    if (
        !task['@loaded'] ||
        !answerSet['@loaded'] ||
        !form['@loaded'] ||
        !dependencies['@loaded'] ||
        !indicators['@loaded'] ||
        !groups['@loaded']
    ) {
        return <LoadingComponent msg={'Laster inn spørsmål...'} />
    }

    if (groups.length === 0) {
        return <h1>Oppgaven er ikke klar enda</h1>
    }

    return (
        <App
            onSubmit={onSubmit}
            onChange={onChange}
            onChangeLocation={onChangeLocation}
            answerSet={answerSet}
            dependencies={dependencies}
            // @ts-expect-error
            indicators={indicators}
            groups={groups}
            task={task}
            form={form}
            title={task.title}
        />
    )
}
