import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {SkilQueryResponseType} from '../../Utilities/QueryClient'
import {jsonFetch} from '../../Components/jsonFetch'
import {GridColDef, GridSortItem} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import Button from '../../Components/Button/Button'
import {toast} from 'react-toastify'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {useQuery} from 'react-query'
import arrayOfDatesColumnDef from '../../Components/DataGrid/columnDefs/arrayOfDatesColumnDef'
import {useEffect} from 'react'

const renderCell = params => {
    if (!params.value) return ''

    return (
        <Button variant='text' href={`/dashboard/SKIL/samarbeid/${params.row.id}`}>
            {params.value}
        </Button>
    )
}

const columnVisibilityModel = {
    id: false,
    'partnership.createdAt': false,
    'partnership.description': false,
}

type Stats = SkilQueryResponseType<'getPartnershipStatsItem'>
type StatsCollection = SkilQueryResponseType<'getPartnershipStatsCollection'>
const toastId = 'loading-partnership-stats-toast'

// Hack, the server only allows request to use up to 30sec of processing time, but loading all data takes 3-4mins currently (50 partnerships)
// Since only Nicolas will be using this feature, rarely it's not worth looking closer at optimizing it, for now I'll let it be (super) slow
async function loadStatsQueryFn(context): Promise<Stats[]> {
    const t = toast('Laster inn samarbeid...', {type: 'info', isLoading: true, toastId})
    let data = []
    let page = 1
    const itemsPerPage = 3
    const response: StatsCollection = await jsonFetch(
        `/api/partnerships/statistics?pagination=true&itemsPerPage=${itemsPerPage}&page=${page}`
    )
    let items = response['hydra:member'] ?? []
    while (items.length >= itemsPerPage) {
        // @ts-expect-error
        data = data.concat(items)
        toast.update(t, {render: 'Lastet inn ' + data.length + ' samarbeid...', isLoading: true, toastId})

        page++
        const response: StatsCollection = await jsonFetch(
            `/api/partnerships/statistics?pagination=true&itemsPerPage=${itemsPerPage}&page=${page}`
        )
        items = response['hydra:member'] ?? []
    }
    toast.update(t, {render: 'Lastet inn ' + data.length + ' samarbeid!', type: 'success', isLoading: false, toastId})
    return data
}

const PartnershipStatistics = () => {
    const [enableSearch, setEnableSearch] = React.useState(false)
    const {data, isFetching} = useQuery<Stats[]>('/api/partnerships/statistics', {
        enabled: enableSearch,
        queryFn: loadStatsQueryFn,
        onSuccess: () => {
            setEnableSearch(false)
        },
    })
    const [sortModel, setSortModel] = React.useState<GridSortItem[]>([])
    const partnerships = data ?? []

    useEffect(() => {
        setEnableSearch(true)
    }, [])

    const handleSortModelChange = model => {
        setSortModel(model)
    }

    const columns: GridColDef[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'partnership.name', headerName: 'Navn', renderCell}),
        columnDef({field: 'doctors', headerName: 'Helfo Fastlegehjemler'}),
        columnDef({field: 'doctorsFinishedAtleastOneQualityCourse', headerName: 'Leger fullført minst 1 kvalitetskurs'}),
        columnDef({field: 'doctorsFinishedAtleastOneSeminars', headerName: 'Leger fullført minst 1 seminar'}),
        columnDef({field: 'finishedQualityCourses', headerName: 'Antall fullførte kvalitetskurs'}),
        arrayOfDatesColumnDef({
            field: 'partnership.plannedSeminars',
            headerName: 'Planlagte seminar',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'partnership.plannedSeminars')?.sort ?? 'none',
        }),
        arrayOfDatesColumnDef({
            field: 'partnership.informationMeetings',
            headerName: 'Informasjonsmøter',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'partnership.informationMeetings')?.sort ?? 'none',
        }),
        arrayOfDatesColumnDef({
            field: 'partnership.educationMeetings',
            headerName: 'Opplæringsmøter',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'partnership.educationMeetings')?.sort ?? 'none',
        }),
        arrayOfDatesColumnDef({
            field: 'partnership.debriefMeetings',
            headerName: 'Oppsummeringsmøter',
            type: 'string',
            sortDirection: sortModel.find(model => model.field === 'partnership.debriefMeetings')?.sort ?? 'none',
        }),
        dateColumnDef({field: 'partnership.plannedSeminarAt', headerName: 'Planlagt seminar'}),
        dateColumnDef({field: 'partnership.informationMeetingAt', headerName: 'Informasjonsmøte'}),
        dateColumnDef({field: 'partnership.educationMeetingAt', headerName: 'Opplæringsmøte'}),
        dateColumnDef({field: 'partnership.debriefMeetingAt', headerName: 'Oppsummeringsmøte'}),
        dateColumnDef({field: 'partnership.createdAt', headerName: 'Opprettet'}),
        columnDef({field: 'partnership.description', headerName: 'Beskrivelse'}),
        dateColumnDef({field: 'nextSeminar', headerName: 'Neste seminar'}),
    ]

    return (
        <Box>
            <DataGridWrapper rows={partnerships.length}>
                <DataGrid
                    id={'partnership_stats'}
                    loading={isFetching}
                    rows={partnerships}
                    initModel={{columnVisibilityModel}}
                    columns={columns}
                    disableSelectionOnClick
                    onSortModelChange={handleSortModelChange}
                />
            </DataGridWrapper>
        </Box>
    )
}

export default PartnershipStatistics
