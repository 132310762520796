import * as React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import Dialog from '@mui/material/Dialog'
import {components} from '../../Generated/eportal'
import DateTimeField from '../../Components/Fields/DateTimeField'
import Item from '@mui/material/Unstable_Grid2'
import useMediaQuery from '@mui/material/useMediaQuery'
import TextEditor from '../../Components/TextEditor/TextEditor'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import PreviewNewsPopupDialog from './PreviewNewsPopupDialog'
import {BackdropComponent} from '../../Components/BackdropComponent'

type PopupNewsGeneratorDTO = components['schemas']['NewsPopup.NewsPopupGeneratorDTO.jsonld']

interface Props {
    onSave: (newCourse: PopupNewsGeneratorDTO) => any
    onClose: Function
    isOpen: boolean
}

const StyledTextEditor = styled(TextEditor)`
    .jodit-workplace div {
        min-height: 15em !important;
    }
`

const StyledBackdropComponent = styled(BackdropComponent)`
    z-index: 9999;
    max-height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
    }
`

export default function CreateNewsPopupDialog({onSave, onClose, isOpen = false}: Props) {
    const [preview, setPreview] = React.useState<boolean>(false)
    const [title, setTitle] = React.useState<string>('')
    const [message, setMessage] = React.useState<string>('')
    const [validFrom, setValidFrom] = React.useState<string>('')
    const [validTo, setValidTo] = React.useState<string>('')
    const bigScreen = useMediaQuery('(min-width:600px)')

    const onSubmit = async () => {
        await onSave({title, message, validFrom, validTo})
        onLocalClose()
    }

    const onLocalClose = () => {
        setTitle('')
        setMessage('')
        setValidFrom('')
        setValidTo('')
        onClose()
    }

    return (
        <>
            <Dialog maxWidth='sm' open={isOpen} onClose={onLocalClose}>
                <DialogContent sx={{backgroundColor: '#f4f4f4'}}>
                    <Typography>
                        <strong>Legg til</strong>
                    </Typography>
                    <Grid container spacing={2} mt={2} sx={{marginLeft: '0', width: '100%'}}>
                        <Grid
                            container
                            style={{
                                outline: '1px solid #c4c4c4',
                                padding: '1em',
                                paddingBottom: '1.5em',
                                borderRadius: '0.25em',
                                backgroundColor: '#fff',
                            }}
                        >
                            <Grid item xs={bigScreen ? 6 : 12} sx={{paddingRight: bigScreen ? '0.5em' : 0}}>
                                <DateTimeField
                                    id={'validFrom'}
                                    label={'Synlig fra og med'}
                                    value={validFrom}
                                    onChange={time => {
                                        setValidFrom(time.toISOString())
                                    }}
                                    required
                                    hours={true}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={bigScreen ? 6 : 12} sx={{paddingLeft: bigScreen ? '0.5em' : 0}}>
                                <DateTimeField
                                    id={'validTo'}
                                    label={'Synlig til og med'}
                                    value={validTo}
                                    onChange={time => {
                                        setValidTo(time.toISOString())
                                    }}
                                    required
                                    hours={true}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id='title'
                                    label='Tittel'
                                    placeholder='Tittel...'
                                    value={title}
                                    required
                                    fullWidth
                                    onChange={event => {
                                        setTitle(event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StyledTextEditor
                                    id={'news_popups_message'}
                                    placeholder={'Nyheten...'}
                                    value={message}
                                    onChange={e => setMessage(e)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{marginTop: '0.5em', display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
                            <Grid item xs={bigScreen ? 6 : 12}>
                                <Item sx={{display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
                                    <small>* Obligatorisk</small>
                                </Item>
                                <Item sx={{display: 'flex', marginTop: '2em', alignItems: 'end', justifyContent: 'end'}}>
                                    <Button onClick={() => setPreview(true)}>
                                        <i className='fa fa-eye' /> Forhåndsvis popup
                                    </Button>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{paddingX: 3, paddingBottom: 2, backgroundColor: '#f4f4f4'}}>
                    <Button variant='outlined' onClick={onLocalClose}>
                        Avbryt
                    </Button>
                    <Button disabled={!title || !message || !validFrom || !validTo} variant='contained' onClick={onSubmit}>
                        Opprett popup nyhet
                    </Button>
                </DialogActions>
            </Dialog>
            {preview && (
                <StyledBackdropComponent isOpen={preview} status={'Nyheter'} showSpinner={false}>
                    <PreviewNewsPopupDialog title={title} message={message} onClose={setPreview} isOpen={preview} />
                </StyledBackdropComponent>
            )}
        </>
    )
}
