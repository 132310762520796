import PurePage from '../../../Components/PurePage'
import React from 'react'
import useUser from '../../../Utilities/useUser'
import {
    Grid,
    Paper,
    Box,
    Typography,
    Divider,
    Stack,
    Chip,
    Card,
    CardContent,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    InputAdornment,
    DialogTitle,
} from '@mui/material'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import 'dayjs/locale/nb'
import DateTimeField from '../../../Components/Fields/DateTimeField'
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker'
import DialogActions from '@mui/material/DialogActions'
import Item from '@mui/material/Unstable_Grid2'
import {Button} from '../../../Components/Button/MuiButton'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import styled from 'styled-components'
import {array, z} from 'zod'
import {useLocation} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import TextField from '@mui/material/TextField'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

type Props = {
    isOpen: boolean
    onClose: () => any
    isNefle?: boolean
}

export default function TermsOfServiceDialog({isOpen, onClose, isNefle = false}: Props) {
    return (
        <PurePage variant={'md'}>
            <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={() => onClose} aria-describedby='alert-dialog-slide-description'>
                <DialogTitle>Vilkår</DialogTitle>
                <DialogContent>
                    {isNefle && (
                        <>
                            <p>
                                Senter for Kvalitet i legetjenester tilbyr leveranse og drift av nettsideløsningen «Nefle.» (Nettside for
                                legekontor). Ved bestilling av Nefle, gjøre nødvendig førstegangsoppsett, logge inn, administrere
                                brukerrettigheter og tilganger, må det opprettes en brukerprofil i SKIL ePortal. SKIL ePortal vil i denne
                                forbindelse lagre nødvendige data om brukeren. ePortalen har i tillegg mulighet til å utføre benchmarking
                                (sammenliknede data mtp. evaluering og forbedring) fra spørreundersøkelser levert via Nefle, samt presentere
                                dette for brukerne tilknyttet legekontoret. Brukerne vil også få tilgang til et kvalitetsstyringsprosjekt,
                                samt sammenlikning av innhentet data tilknyttet prosjektet.
                            </p>
                            <p>
                                SKIL ePortal vil i denne forbindelse lagre nødvendige data om brukeren. ePortalen har i tillegg mulighet til
                                å utføre benchmarking (sammenliknede data mtp. evaluering og forbedring) fra spørreundersøkelser levert via
                                Nefle, samt presentere dette for brukerne tilknyttet legekontoret.
                            </p>
                            <p>
                                Brukerne vil også få tilgang til et kvalitetsstyringsprosjekt, samt sammenlikning av innhentet data
                                tilknyttet prosjektet.
                            </p>
                        </>
                    )}
                    {!isNefle && (
                        <p>
                            Senter for Kvalitet i legetjenester tilbyr verktøy og aktiviteter for å stimulere til kvalitetsarbeid på norske
                            legekontor. Aktivitetene innebærer kurs bestående blant annet av kartleggingsskjema, e-læringskurs og
                            kollegagruppemøter. SKIL samler inn data gjennom SKIL ePortal: Oversikt over deltakere, grupper, møter,
                            fremdrift til den enkelte deltaker, innleveringer av kartleggingsskjema, oppgaver og evalueringer via
                            «Arbeidsark». Dataene vil kunne brukes til benchmarking (slik at den enkelte deltakeren skal kunne sammenligne
                            seg med gjennomsnittet), evaluering av SKIL og vitenskatelige publikasjoner. Ved publikasjon på noen måte vil
                            data alltid aggregeres på minst 10 leger, om ikke annet avtales direkte med deltakerne.
                        </p>
                    )}
                    <p>
                        Se personvern, brukerbetingelser og (salgs)vilkår her:{' '}
                        <a href={'https://www.skilnet.no/hjem/vilkar/'} target='_blank'>
                            https://www.skilnet.no/hjem/vilkar/
                        </a>
                    </p>
                    {!isNefle && (
                        <p style={{marginTop: '2rem', marginBottom: '0rem'}}>
                            <strong>Påmelding er bindende</strong>
                        </p>
                    )}
                </DialogContent>
                <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                    <DialogActions sx={{padding: 0}}>
                        <Button variant='outlined' onClick={() => onClose()}>
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </PurePage>
    )
}
