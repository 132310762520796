import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'
import LoadingComponent from '../../Components/LoadingComponent'

const QualitySeminarsGoal24 = () => {
    const {data: qualitySeminarStats} = useSkilQuery<'getQualitySeminarStatsPerYear'>(`/api/user_courses/qualitySeminarStatsPerYear`, {
        year: 2024,
    })

    const {data: qualitySeminarMeetings} = useSkilQuery<'getQualitySeminarMeetingsPerYear'>(`/api/courses/qualitySeminarMeetings`, {
        year: 2024,
    })

    if (!qualitySeminarStats || !qualitySeminarMeetings) {
        return <LoadingComponent />
    }

    return (
        <GoalCard
            title='Kvalitetsseminar'
            subheader='Inkluderer data fra både tidligere og fremtidige seminar'
            // @ts-expect-error
            description={`${qualitySeminarStats?.signups} påmeldte seminardeltakere i 2024`}
            percentage={null}
            // @ts-expect-error
            secondDescription={`${qualitySeminarStats?.payable_signups} fakturerbare påmeldinger`}
            // @ts-expect-error
            thirdDescription={`${qualitySeminarStats?.invited} inviterte`}
            // @ts-expect-error
            fourthDescription={`${qualitySeminarMeetings?.seminarMeetings} seminar`}
            // @ts-expect-error
            fifthDescription={`${qualitySeminarMeetings?.informationMeetings} informasjonsmøter`}
        />
    )
}

export default QualitySeminarsGoal24
