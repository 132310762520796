import * as React from 'react'
import TextField from '@mui/material/TextField'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import Dialog from '@mui/material/Dialog'
import {components} from '../../Generated/eportal'
import DateTimeField from '../../Components/Fields/DateTimeField'
import Item from '@mui/material/Unstable_Grid2'
import useMediaQuery from '@mui/material/useMediaQuery'
import {toast} from 'react-toastify'

type NefleDiscountCodeGeneratorDTO = components['schemas']['NefleDiscountCode.NefleDiscountCodeGeneratorDTO.jsonld']

interface Props {
    discountCode: components['schemas']['NefleDiscountCode.jsonld-NefleDiscountCode.basic']
    onSave: (newCourse: NefleDiscountCodeGeneratorDTO) => any
    onClose: Function
    isOpen: boolean
}

export default function EditNefleDiscountCodeDialog({discountCode, onSave, onClose, isOpen = false}: Props) {
    const [code, setCode] = React.useState<string>(discountCode.code)
    const [discount, setDiscount] = React.useState<number>(discountCode.discount)
    const [validFrom, setValidFrom] = React.useState<string>(discountCode.validFrom)
    const [validTo, setValidTo] = React.useState<string>(discountCode.validTo)
    const bigScreen = useMediaQuery('(min-width:600px)')

    const onSubmit = async () => {
        if (discount > 7000) {
            toast('Maks rabatt på etableringskostnad er 7000 kr', {type: 'warning'})
            return
        }
        await onSave({code, discount, validFrom, validTo})
        onLocalClose()
    }

    const onLocalClose = () => {
        setCode('')
        setDiscount(0)
        setValidFrom('')
        setValidTo('')
        onClose(false)
    }

    return (
        <Dialog maxWidth='sm' open={isOpen} onClose={onLocalClose}>
            <DialogContent sx={{backgroundColor: '#f4f4f4'}}>
                <Typography>
                    <strong>Rediger Nefle rabattkoden</strong>
                </Typography>
                <Grid container spacing={2} mt={2} sx={{marginLeft: '0', width: '100%'}}>
                    <Grid
                        container
                        style={{
                            outline: '1px solid #c4c4c4',
                            padding: '1em',
                            paddingBottom: '1.5em',
                            borderRadius: '0.25em',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Grid item xs={bigScreen ? 6 : 12} sx={{paddingRight: bigScreen ? '0.5em' : 0}}>
                            <DateTimeField
                                id={'validFrom'}
                                label={'Gyldig fra og med'}
                                value={validFrom}
                                onChange={time => {
                                    setValidFrom(time.toDateString())
                                }}
                                required
                                hours={false}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={bigScreen ? 6 : 12} sx={{paddingLeft: bigScreen ? '0.5em' : 0}}>
                            <DateTimeField
                                id={'validTo'}
                                label={'Gyldig til og med'}
                                value={validTo}
                                onChange={time => {
                                    setValidTo(time.toDateString())
                                }}
                                required
                                hours={false}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={bigScreen ? 6 : 12} sx={{paddingRight: bigScreen ? '0.5em' : 0}}>
                            <TextField
                                id={'discount_code'}
                                label={'Ny rabattkode'}
                                placeholder={'Rabattkode'}
                                value={code}
                                disabled={true}
                                required
                                sx={{width: '100%'}}
                                onChange={e => setCode(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={bigScreen ? 6 : 12} sx={{paddingLeft: bigScreen ? '0.5em' : 0}}>
                            <TextField
                                id={'discount_amount'}
                                label={'Rabatt på etableringskostnad (kroner)'}
                                type={'number'}
                                placeholder={'0'}
                                value={discount}
                                disabled={true}
                                required
                                sx={{width: '100%'}}
                                onChange={e => setDiscount(parseInt(e.target.value))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{marginTop: '0.5em', display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
                        <Grid item xs={bigScreen ? 6 : 12}>
                            <Item sx={{display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
                                <small>* Obligatorisk</small>
                            </Item>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2, backgroundColor: '#f4f4f4'}}>
                <Button variant='outlined' onClick={onLocalClose}>
                    Avbryt
                </Button>
                <Button disabled={!code || isNaN(discount) || !validFrom || !validTo} variant='contained' onClick={onSubmit}>
                    Lagre endringer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
