import * as React from 'react'
import Button from '../Components/Button/Button'
import Panel from '../Components/Panel'
import LoadingComponent from '../Components/LoadingComponent'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import {Page} from '../Components/Page'
import Collapse from '../Components/Collapse'
import CreateNewGroupModal from '../Components/CreateNewGroupModal'
import {jsonFetch} from '../Components/jsonFetch'
import format from 'date-fns/format'
import useEntities from '../Hooks/useEntities'
import useUser from '../Utilities/useUser'
import {useSkilQuery} from '../Utilities/QueryClient'

export default function Groups() {
    const user = useUser()
    const {partnershipId} = useParams()
    const navigate = useNavigate()
    const [showCreateNewGroupModal, setShowCreateNewGroupModal] = React.useState(false)
    const partnershipMembers = useEntities<'getUserPartnerships'>(user.authenticated ? user.iri + '/partnerships' : undefined)

    const {
        data,
        refetch: refreshGroups,
        isLoading,
    } = useSkilQuery<'listPartnershipGroups'>(`/api/partnerships/${partnershipId}/list_groups`, {enabled: !!partnershipId})

    const groups = []

    const onPartnershipChange = value => {
        const newId = value.value.substring(value.value.lastIndexOf('/') + 1)
        navigate(`/dashboard/kommuner/groups/${newId}`, {replace: true})
    }

    const onCreateNewGroup = async group => {
        await jsonFetch(`/dashboard/course/${group.course}/groups/create`, {
            json: group,
            method: 'POST',
        })
        await refreshGroups()
        setShowCreateNewGroupModal(false)
    }

    const breadcrumbs = [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId}, {title: 'Grupper'}]

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs}>
            {isLoading && <LoadingComponent />}
            <Panel variant={'flat'} hidden={isLoading} title={undefined}>
                <Panel.TitleWithActions title={'Grupper'}>
                    <Button size={'sm'} onClick={() => setShowCreateNewGroupModal(true)} aria-label={'Opprett ny gruppe'}>
                        <i className='fa fa-plus' /> Opprett ny gruppe
                    </Button>
                </Panel.TitleWithActions>
                <Collapse expanded={(groups?.length ?? 0) > 0}>
                    <table className={'table table-striped'}>
                        <thead>
                            <tr>
                                <th>Navn</th>
                                <th>Gruppeleder</th>
                                <th>Veileder</th>
                                <th>Kommune</th>
                                <th>Fylke</th>
                                <th>Deltakere #</th>
                                <th>Neste møte</th>
                                <th>Siste kurs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups?.map(g => (
                                <tr key={g.id}>
                                    <td>
                                        <Button to={`/dashboard/kommuner/${partnershipId}/groups/${g.id}`} variant={'text'}>
                                            {g.name ? g.name : 'N/A'}
                                        </Button>
                                    </td>
                                    <td>{g.leaderName ? g.leaderName : 'N/A'}</td>
                                    <td>{g.counselorName ? g.counselorName : 'N/A'}</td>
                                    <td>{g.county ? g.county : 'N/A'}</td>
                                    <td>{g.region ? g.region : 'N/A'}</td>
                                    <td>{g.members ? g.members : 'N/A'}</td>
                                    <td>{g.nextMeetingTime ? format(new Date(g.nextMeetingTime), 'dd.MM.yyyy') : 'N/A'}</td>
                                    <td>{g.shortTitle ? g.shortTitle : 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Collapse>
                <div style={{width: 'max-content', padding: '1em'}}>
                    Oversikt over grupper som er i samme geografiske område som kommunesamarbeidet dekker
                </div>
            </Panel>
            {showCreateNewGroupModal && (
                <CreateNewGroupModal onSave={data => onCreateNewGroup(data)} onClose={() => setShowCreateNewGroupModal(false)} />
            )}
        </Page>
    )
}
