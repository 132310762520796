import * as React from 'react'
import Button from './Button/Button'
import ErrorBoundary from './ErrorBoundary'
import Breadcrumbs, {BreadcrumbsItem} from './Breadcrumbs'

const VARIANTS = {
    default: 'col-md-10 col-md-offset-1',
    big: 'col-xs-12',
    full: 'col-xs-12',
    narrow: 'col-md-8 col-md-offset-2',
}

interface PageProps {
    backLink?: string
    breadcrumbs?: BreadcrumbsItem[]
    children?: React.ReactNode
    className?: string
    title?: string
    variant?: keyof typeof VARIANTS
    bigScreen?: boolean
}

export const Page: React.FC<PageProps> = ({
    children,
    backLink,
    title,
    variant = 'default',
    className = '',
    breadcrumbs,
    bigScreen = false,
    ...props
}) => {
    let columns = VARIANTS[variant] ?? VARIANTS['default']
    const containerClass = variant === 'full' ? 'container-fluid' : 'container'

    return (
        <div style={{maxWidth: '100%'}} className={`${containerClass} ${className}`} {...props}>
            <div className={'row'}>
                <div className={columns}>
                    {breadcrumbs ? (
                        <Breadcrumbs pages={breadcrumbs} />
                    ) : (
                        <h1>
                            {title}
                            {backLink && (
                                <Button variant={'link'} to={backLink}>
                                    Tilbake
                                </Button>
                            )}
                        </h1>
                    )}
                </div>
            </div>
            <div className={'row'}>
                <div className={columns} style={{padding: bigScreen ? 'initial' : 0}}>
                    <ErrorBoundary>{children}</ErrorBoundary>
                </div>
            </div>
        </div>
    )
}
