import React, {ReactNode} from 'react'
import {isValid} from 'date-fns'
import TextField from '@mui/material/TextField'
import {DatePicker} from '@mui/x-date-pickers-pro'

type Props = {
    id: string
    label?: ReactNode
    help?: ReactNode
    /** @format date-string */
    value?: Date | string | null
    onChange: (e: any, event: any) => any
    required?: boolean
    autocomplete?: 'off'
}

/** @deprecated*/
export default function DateField({id, label, help, value, onChange, required = false, autocomplete = undefined}: Props) {
    const localValue = new Date(value ? value : NaN)
    const validValue = isValid(localValue)
    const selected = validValue ? localValue : null

    const onLocalChange = e => {
        return onChange(e, e)
    }

    return (
        <DatePicker
            label={label}
            value={selected}
            inputFormat='dd.MM.yyyy'
            mask={'__.__.____'}
            onChange={onLocalChange}
            renderInput={params => (
                <TextField sx={{mb: 2}} id={id} helperText={help} autoComplete={autocomplete} required={required} fullWidth {...params} />
            )}
        />
    )
}
