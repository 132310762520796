import React from 'react'

const FormGroup = ({hasError = false, hasSuccess = false, children, size = 'md', className = '', ...props}) => (
    <div
        className={`${className} form-group form-group-${size} ${hasError ? 'has-error' : ''} ${hasSuccess ? 'has-success' : ''}`}
        {...props}
    >
        {children}
    </div>
)
export default FormGroup
