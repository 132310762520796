import React, {useEffect} from 'react'
import useEntities from '../../../Hooks/useEntities'
import {LocationContext} from '../App'
import SelectField from '../../../Components/Fields/SelectField'
import SimpleSelectField from '../../../Components/Fields/SimpleSelectField'
import {IndicatorProps} from './index'
import {LocationIndicatorType} from '../../Types'
import {RegionsExport} from '../../../Components/RegionsExport'
import LoadingComponent from '../../../Components/LoadingComponent'

const SHOW_COUNTRY = '0'
const SHOW_REGION = '1'
const SHOW_COUNTY = '2'
const SHOW_EXTERNAL_OFFICE = '3'
// TODO: In the future we may want to add district location level

const LocationIndicator = ({indicator, disabled}: IndicatorProps<LocationIndicatorType>) => {
    const [hasLoaded, setHasLoaded] = React.useState(false)
    // @ts-expect-error
    let regions: {label: string; value: number}[] = RegionsExport(hasLoaded)
    const {setLocation, answersInState, localStorage} = React.useContext(LocationContext)
    const location = answersInState.newLocation
    const searchParams = new URLSearchParams(window.location.search)
    const counties = useEntities<'getCountyCollection'>(location?.region !== 0 ? `/api/counties?regionId=${location?.region}` : undefined)
    const districts = location?.county
        ? counties
              ?.find(county => {
                  if (typeof location.county === 'number') {
                      return county.id === location?.county
                  } else {
                      return county['@id'] === location?.county
                  }
              })
              ?.districts?.map(district => {
                  return {name: district.name, id: district.id, '@id': district['@id']}
              })
              .sort(function (a, b) {
                  if (a.name < b.name) {
                      return -1
                  }
                  if (a.name > b.name) {
                      return 1
                  }
                  return 0
              })
        : []

    const externalOffices = useEntities<'getCountyExternalOfficeCollection'>(
        location?.county !== 0 && Number.isInteger(location?.county)
            ? `/api/counties/${location?.county}/external_offices`
            : typeof location.county === 'string'
            ? `${location?.county}/external_offices`
            : undefined
    )

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && regions?.length > 0) {
            setHasLoaded(true)
        }
    }, [regions])

    useEffect(() => {
        const urlParams = searchParams
        let region = parseInt(urlParams.get('region') ?? '0')
        const county = parseInt(urlParams.get('county') ?? '0')
        const office = parseInt(searchParams.get('office') ?? '0')
        const externalOffice = parseInt(urlParams.get('external_office') ?? '0')
        if (county && !region) region = Math.floor(county / 100)

        //if localStorage is empty , url paramenters exists, do not care about location in answerSet
        if (!localStorage.newLocation?.region && region !== 0) {
            setLocation({region, county, externalOffice, office})
        }
    }, [searchParams.toString()])

    const showExternalOffice = indicator['locationLevel'] === SHOW_EXTERNAL_OFFICE
    const showCounty = showExternalOffice || indicator['locationLevel'] === SHOW_COUNTY

    return (
        <>
            <label>{indicator['title']}</label>
            {indicator['description'] && <span className='help-block'>{indicator['description']}</span>}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
            <div>
                <SimpleSelectField
                    id={'region'}
                    label='Fylke'
                    placeholder='Fylke'
                    disabled={disabled}
                    required
                    onChange={region => setLocation({region, county: 0, district: 0, externalOffice: 0})}
                    value={location.region}
                    options={regions}
                />
                {showCounty && !!location.region && !counties['@loaded'] && <LoadingComponent msg={'Laster inn kommuner...'} />}
                {showCounty && !!location.region && counties['@loaded'] && (
                    <SelectField
                        id={'county'}
                        label='Kommune'
                        placeholder='Kommune'
                        onChange={e => setLocation({...location, county: e.id, district: 0, externalOffice: 0})}
                        value={typeof location?.county === 'number' ? `/api/counties/${location?.county}` : location?.county}
                        // @ts-expect-error TODO: This should be fixed ASAP!!! (or test that it works as expected)
                        entities={counties}
                        // TODO: Should be replaced by disabledFn={() => disabled} */
                        disabled={disabled}
                    />
                )}
                {showCounty && districts && districts?.length > 0 && (
                    <SelectField
                        id={'district'}
                        label='Bydel'
                        placeholder='Bydel'
                        onChange={e => setLocation({...location, district: e.id})}
                        value={location?.district}
                        // @ts-expect-error
                        entities={districts}
                        // TODO: Should be replaced by disabledFn={() => disabled} */
                        disabled={disabled}
                    />
                )}
                {showExternalOffice && !!location.region && !!location.county && !externalOffices['@loaded'] && (
                    <LoadingComponent msg={'Laster inn legekontor...'} />
                )}
                {showExternalOffice && !!location.region && !!location.county && externalOffices['@loaded'] && (
                    <SelectField
                        id={'office'}
                        label='Legekontor'
                        placeholder='Legekontor'
                        onChange={e => setLocation({...location, externalOffice: e.id})}
                        value={location?.externalOffice}
                        // @ts-expect-error TODO: This should be fixed ASAP!!! (or test that it works as expected)
                        entities={externalOffices}
                        // TODO: Should be replaced by disabledFn={() => disabled}
                        disabled={disabled}
                    />
                )}
            </div>
        </>
    )
}
export default LocationIndicator
