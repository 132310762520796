import React, {useContext} from 'react'
import Button from '../../Components/Button/Button'
import {EditLockContext} from './Form'

const PlaceholderToolbar = ({indicators, order, group, form}) => {
    const [lock] = useContext(EditLockContext)

    const onAddChoice = () => {
        indicators.create(
            {remoteId: '', optional: false, title: '[Tittel] Ny flervalg', description: '', order: order, group: group, form: form},
            `/api/choice_indicators`
        )
        indicators.refresh()
    }
    const onAddMultichoice = () => {
        indicators.create(
            {remoteId: '', optional: false, title: '[Tittel] Ny flersvar', description: '', order: order, group: group, form: form},
            `/api/multichoice_indicators`
        )
        indicators.refresh()
    }
    const onAddCheckbox = () => {
        indicators.create(
            {remoteId: '', optional: false, title: '[Tittel] Ny checkbox', description: '', order: order, group: group, form: form},
            `/api/checkbox_indicators`
        )
        indicators.refresh()
    }
    const onAddSeminarTheme = () => {
        indicators.create(
            {
                remoteId: '',
                optional: false,
                title: '[Tittel] Velg seminar tema',
                description: '',
                order: order,
                group: group,
                form: form,
            },
            `/api/seminar_theme_indicators`
        )
        indicators.refresh()
    }
    const onAddCounselorEmail = () => {
        indicators.create(
            {
                remoteId: '',
                optional: false,
                title: '[Tittel] Ny Epost til veileder',
                description: '',
                order: order,
                group: group,
                form: form,
            },
            `/api/counselor_email_indicators`
        )
        indicators.refresh()
    }
    const onAddDateTime = () => {
        indicators.create(
            {
                remoteId: '',
                optional: false,
                title: '[Tittel] Ny Dato/Tid',
                description: '',
                order: order,
                group: group,
                form: form,
                date: true,
                time: true,
            },
            `/api/date_time_indicators`
        )
        indicators.refresh()
    }
    const onAddLocation = () => {
        indicators
            .create(
                {
                    remoteId: '',
                    optional: false,
                    title: '[Tittel] Ny lokalisasjons indikator',
                    description: '',
                    order: order,
                    group: group,
                    form: form,
                    locationLevel: 1,
                },
                `/api/location_indicators`
            )
            .then(() => {
                indicators.refresh()
            })
    }
    const onAddInformation = () => {
        indicators.create(
            {
                remoteId: '',
                optional: false,
                title: '[Tittel] Ny informasjons indikator',
                description: '',
                content: 'Legg til innholder her',
                order: order,
                group: group,
                form: form,
                copyFunction: false,
            },
            `/api/information_indicators`
        )
        indicators.refresh()
    }
    const onAddNumber = () => {
        indicators.create(
            {remoteId: '', optional: false, title: '[Tittel] Ny nummer indikator', description: '', order: order, group: group, form: form},
            `/api/number_indicators`
        )
        indicators.refresh()
    }
    const onAddPreviousAnswer = () => {
        indicators.create(
            {
                remoteId: '',
                optional: false,
                title: '[Tittel] Ny tidligere svar indikator',
                description: '',
                order: order,
                group: group,
                form: form,
                previousAnswerIndicator: null,
            },
            `/api/previous_answer_indicators`
        )
        indicators.refresh()
    }
    const onAddText = () => {
        indicators.create(
            {remoteId: '', optional: false, title: '[Tittel] Ny tekst indikator', description: '', order: order, group: group, form: form},
            `/api/text_indicators`
        )
        indicators.refresh()
    }

    return (
        // @ts-expect-error
        <div className='btn-group' role='group' style={{visible: lock ? 'hidden' : 'visible'}}>
            <Button onClick={onAddInformation} size='md'>
                <i className='fa fa-info-square' /> Informasjon
            </Button>
            <Button onClick={onAddCheckbox} size='md'>
                <i className='fa fa-align-justify' /> Ja/Nei
            </Button>
            <Button onClick={onAddChoice} size='md'>
                <i className='fa fa-ballot-check' /> Flervalg
            </Button>
            <Button onClick={onAddMultichoice} size='md'>
                <i className='fa fa-ballot-check' /> Flersvar
            </Button>
            <Button onClick={onAddDateTime} size='md'>
                <i className='fa fa-calendar-alt' /> Dato/Tid
            </Button>
            <Button onClick={onAddLocation} size='md'>
                <i className='fa fa-calendar-alt' /> Lokalisasjon
            </Button>
            <Button onClick={onAddPreviousAnswer} size='md'>
                <i className='fa fa-calendar-alt' /> Tidligere svar
            </Button>
            <Button onClick={onAddNumber} size='md'>
                <i className='fa fa-sort-numeric-up-alt' /> Nummer/Slider
            </Button>
            <Button onClick={onAddText} size='md'>
                <i className='fa fa-align-justify' /> Tekst
            </Button>
            <Button onClick={onAddCounselorEmail} size='md'>
                <i className='fa fa-align-justify' /> Epost til veileder
            </Button>
            <Button onClick={onAddSeminarTheme} size='md'>
                <i className='fa fa-list-ul' /> Seminar tema
            </Button>
        </div>
    )
}

export default PlaceholderToolbar
