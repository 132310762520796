import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import styled from 'styled-components'
import EditText from '../../Components/EditText'
import LoadingComponent from '../../../Components/LoadingComponent'
import {useSkilQuery} from '../../../Utilities/QueryClient'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`

const SeminarThemeIndicator = ({indicator}) => {
    const seminarThemeIndicator = indicator['seminarThemeIndicator'] ?? null
    const {data: templates} = useSkilQuery<'getCourseItem'>(`/api/seminar/templates`)

    if (!templates?.['@loaded'] && seminarThemeIndicator) return <LoadingComponent size={'h4'}>Laster inn..</LoadingComponent>

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        indicator.save()
    }

    return (
        <FormGroup hasError={false} hasSuccess={true}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>
            <select name='seminar_theme_indicator' className='form-control' value={''} onChange={() => {}} />
            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </FormGroup>
    )
}
export default SeminarThemeIndicator
