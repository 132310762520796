import React, {useState} from 'react'
import Modal from '../../Components/Modal'
import useEntities from '../../Hooks/useEntities'
import FormGroup from '../../Components/FormGroup'
import LoadingComponent from '../../Components/LoadingComponent'

export default function PreviousIndicatorGroupsModal({
    onClose,
    selectedCourse,
    onSelectCourse,
    forms,
    form,
    selectedForm,
    indicatorGroups,
    addPreviousIndicatorGroup,
}) {
    const courses = useEntities<'getCourseCollection'>(`/api/courses`)
    const [localSelectedForm, setLocalSelectedForm] = useState(0)
    const [localSelectedIndicatorGroup, setLocalSelectedIndicatorGroup] = useState(0)

    const changeCourse = e => {
        onSelectCourse(e)
    }
    const onSelectForm = e => {
        const form = JSON.parse(e)
        selectedForm(form.iri)
        // @ts-expect-error
        setLocalSelectedForm(JSON.stringify(form))
    }
    const onSelectIndicatorGroup = e => {
        const indicatorGroup = JSON.parse(e)
        // @ts-expect-error
        setLocalSelectedIndicatorGroup(JSON.stringify(indicatorGroup))
    }

    const addIndicatorGroup = () => {
        // @ts-expect-error
        addPreviousIndicatorGroup({form: JSON.parse(form['id']), indicatorGroup: JSON.parse(localSelectedIndicatorGroup).id})
        onClose()
    }

    const onHide = () => {
        onClose()
    }

    return (
        <>
            <Modal title={'Legg til tidligere indikatorgruppe'} onClose={onHide} onSave={addIndicatorGroup}>
                {!courses['@loaded'] && <LoadingComponent />}
                {courses['@loaded'] && (
                    <div>
                        <FormGroup hidden={false} hasSuccess={true} size={'lg'}>
                            <label>Kurs og globale skjema</label>
                            <select
                                title='Kurs og globale skjema'
                                className='form-control'
                                onChange={e => changeCourse(e.target.value)}
                                value={selectedCourse}
                                disabled={false}
                            >
                                <option value='0'>Ikke valgt</option>
                                <option key={0} value={'global_forms'}>
                                    Globale skjema
                                </option>
                                {courses.map(course => (
                                    <option key={course['@id']} value={course['@id']}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                        <FormGroup hidden={false} hasSuccess={true} size={'lg'}>
                            <label>Arbeidsark</label>
                            <select
                                title='Arbeidsark'
                                className='form-control'
                                onChange={e => onSelectForm(e.target.value)}
                                value={localSelectedForm}
                                disabled={false}
                            >
                                <option value='0'>Ikke valgt</option>
                                {forms.map(form => (
                                    <option key={form['@id']} value={JSON.stringify({iri: form['@id'], id: form['id']})}>
                                        {form.name}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                        <FormGroup hidden={false} hasSuccess={true} size={'lg'}>
                            <label>Indikatorgruppe</label>
                            <select
                                title='Indikatorgruppe'
                                className='form-control'
                                onChange={e => onSelectIndicatorGroup(e.target.value)}
                                value={localSelectedIndicatorGroup}
                                disabled={false}
                            >
                                <option value='0'>Ikke valgt</option>
                                {indicatorGroups.map(indicatorGroup => (
                                    <option
                                        key={indicatorGroup['@id']}
                                        value={JSON.stringify({iri: indicatorGroup['@id'], id: indicatorGroup['id']})}
                                    >
                                        {indicatorGroup.title}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                    </div>
                )}
            </Modal>
        </>
    )
}
