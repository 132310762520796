import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import {SxProps} from '@mui/material/styles'
import {useEffect} from 'react'
import {CountiesExport} from './CountiesExport'
import Grid from '@mui/material/Grid'

type CountyOptionsType = {
    label: string
    id: string
    region: string
    regionId: number
}

type DistrictOptionsType = {
    name: string
    id: string
}

type Props = {
    value: string | undefined
    valueDistrict?: DistrictOptionsType | undefined
    onChange: (
        event,
        county: CountyOptionsType | null,
        iri: string | null,
        district: DistrictOptionsType | undefined,
        countiesRaw: CountyOptionsType
    ) => any
    required?: boolean
    requiredDistrict?: boolean
    sx?: SxProps
    error?: boolean
    helperText?: string
    errorDistrict?: boolean
    helperTextDistrict?: string
    xs?: number
    conserveSpace?: boolean
}

export const CountyAutocomplete = ({
    value,
    valueDistrict = undefined,
    onChange,
    helperText = '',
    error = false,
    errorDistrict = false,
    helperTextDistrict = '',
    required = false,
    requiredDistrict = false,
    sx = undefined,
    xs = 12,
    conserveSpace = false,
}: Props) => {
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const countiesRaw = CountiesExport(hasLoaded)

    const counties = countiesRaw
        .map(county => {
            return {label: county.label, id: county.id, region: county.region, regionId: county.regionId}
        })
        .sort(function (a, b) {
            if (a.region < b.region) {
                return -1
            }
            if (a.region > b.region) {
                return 1
            }
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })
    const [localCountyValue, setLocalCountyValue] = React.useState<CountyOptionsType | null>(() => {
        return (
            counties.find(county => {
                return county.id === value
            }) ?? null
        )
    })

    React.useEffect(() => {
        setLocalCountyValue(
            counties.find(county => {
                return county.id === value
            }) ?? null
        )
    }, [value])

    const [localDistrictValue, setLocalDistrictValue] = React.useState<DistrictOptionsType | null>(valueDistrict ?? null)

    const countyDistricts = localCountyValue
        ? countiesRaw
              ?.find(county => county.id === localCountyValue.id)
              ?.districts?.map(district => {
                  let iri = district['@id'] as string
                  return {name: district.name, id: iri}
              })
              .sort(function (a, b) {
                  if (a.name < b.name) {
                      return -1
                  }
                  if (a.name > b.name) {
                      return 1
                  }
                  return 0
              })
        : []

    useEffect(() => {
        if (valueDistrict?.id !== localDistrictValue?.id && valueDistrict !== null && countyDistricts && countyDistricts?.length > 0) {
            const valueDistrictObj = countyDistricts?.find(district => district.id === valueDistrict?.id)
            if (valueDistrictObj) {
                setLocalDistrictValue(valueDistrictObj)
            }
        }
    }, [valueDistrict, countyDistricts])

    const onLocalChangeCounty = (event, newValue: CountyOptionsType | null) => {
        setLocalCountyValue(newValue)
        setLocalDistrictValue(null)
        // @ts-expect-error
        return onChange(event, newValue, newValue?.id ?? null, null, countiesRaw)
    }

    const onLocalChangeDistrict = (event, newValue: DistrictOptionsType | null) => {
        setLocalDistrictValue(newValue)
        // @ts-expect-error
        return onChange(event, localCountyValue, localCountyValue?.id ?? null, newValue ?? null, countiesRaw)
    }

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && counties?.length > 0) {
            setHasLoaded(true)
        }
    }, [counties])

    return (
        <Grid container spacing={1} mt={0}>
            <Grid item xs={xs}>
                <Autocomplete<CountyOptionsType>
                    sx={sx}
                    id={'county'}
                    fullWidth
                    value={localCountyValue}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.label}
                    onChange={onLocalChangeCounty}
                    groupBy={option => option.region}
                    // @ts-expect-error Wrong type definition from mui?
                    disableClearable={!!required}
                    options={counties}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.label}
                        </li>
                    )}
                    renderInput={params => (
                        <TextField
                            error={error}
                            sx={{mt: conserveSpace ? 0 : 2}}
                            helperText={helperText}
                            required={required}
                            {...params}
                            label='Kommune'
                        />
                    )}
                />
            </Grid>
            {countyDistricts && countyDistricts?.length > 0 && (
                <Grid item xs={xs}>
                    <Autocomplete<DistrictOptionsType>
                        sx={sx}
                        id={'district'}
                        fullWidth
                        value={localDistrictValue}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={option => option.name}
                        onChange={onLocalChangeDistrict}
                        // @ts-expect-error Wrong type definition from mui?
                        disableClearable={!!requiredDistrict}
                        options={countyDistricts}
                        renderInput={params => (
                            <TextField
                                error={errorDistrict}
                                helperText={helperTextDistrict}
                                required={requiredDistrict || countyDistricts?.length > 0}
                                {...params}
                                sx={{mt: conserveSpace ? 0 : 2}}
                                label='Bydel'
                            />
                        )}
                    />
                </Grid>
            )}
        </Grid>
    )
}
