import {toast} from 'react-toastify'
import reportError from '../State/utils/errorHandler'

export const handleErrorWithToast = error => {
    if (error instanceof Error) {
        //@ts-expect-error
        const type = error.toastType ?? 'error'

        const toastId: string | undefined = error.message

        toast(error.message, {type, toastId})
        reportError(error.message)
    } else if (typeof error === 'string') {
        toast(error, {type: 'error', toastId: error})
        reportError(error)
    } else {
        console.error(error)
    }
}
