import React from 'react'
import {styled} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'

interface StyledAbsoluteDiv {
    backdrop: boolean
    Theme?: any
}

const AbsoluteDiv = styled('div')<StyledAbsoluteDiv>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: ${props => (props.backdrop ? '#fff' : props.Theme)};
`

type Props = {
    msg?: string
    children?: React.ReactNode
    size?: string
    color?: string
    absolute?: boolean
    backdrop?: boolean
}

const LoadingComponent = ({
    msg = undefined,
    children = undefined,
    size = 'h4',
    color = 'default',
    absolute = true,
    backdrop = false,
}: Props) => {
    const themeColor = theme => theme.palette.primary.main as any
    return React.createElement(
        size,
        {className: 'text-center loading-component', title: msg},
        <>
            {absolute && backdrop && (
                <Backdrop sx={{color: color, backgroundColor: 'rgba(0,0,0,0.85)', zIndex: theme => theme.zIndex.drawer + 2}} open={true}>
                    <AbsoluteDiv backdrop={backdrop} Theme={themeColor}>
                        <i className='fa fa-spinner fa-spin fa' style={{color: color, marginRight: '0.3em'}} />
                        {children && <>{children}</>}
                        <br />
                        <small style={{color: color}}>{msg}</small>
                    </AbsoluteDiv>
                </Backdrop>
            )}
            {absolute && !backdrop && (
                <AbsoluteDiv backdrop={false}>
                    <i className='fa fa-spinner fa-spin fa' style={{color: color, marginRight: '0.3em'}} />
                    {children && <>{children}</>}
                    <br />
                    <small style={{color: color}}>{msg}</small>
                </AbsoluteDiv>
            )}
            {!absolute && (
                <>
                    <i className='fa fa-spinner fa-spin fa-2x' style={{color: color, marginRight: '0.3em'}} />
                    {children && (
                        <>
                            <br />
                            <br />
                            {children}
                        </>
                    )}
                    <br />
                    <small>{msg}</small>
                </>
            )}
        </>
    )
}

export default LoadingComponent
