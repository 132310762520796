import * as React from 'react'
import {SetState} from '../../../Utilities/TypeHelpers'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {ChangelogDialog} from './ChangelogDialog'
import {DoctorType} from '../../DetailsViews/Doctor/Doctor'

interface DoctorChangelogDialogProps {
    setOpen: SetState<boolean>
    isOpen: boolean
    doctorId: string
}

export const DoctorChangelogDialog = ({setOpen, doctorId, isOpen = false}: DoctorChangelogDialogProps) => {
    const {data, isLoading} = useSkilQuery<'getPraksisNettDoctor-ChangelogCollection'>(`/api/praksisnett/doctors/${doctorId}/changelogs`, {
        pagination: false,
    })
    return <ChangelogDialog setOpen={setOpen} isOpen={isOpen} data={data} isLoading={isLoading} />
}
