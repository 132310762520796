import * as React from 'react'
import {Box} from '@mui/material'
import QualitySeminarGoal23 from './QualitySeminarGoal23'
import NewParticipantsGoal23 from './NewParticipantsGoal23'
import NewUserCoursesGoal23 from './NewUserCoursesGoal23'
import QualitySeminarsGoal23 from './QualitySeminarsGoal23'

export const Goals23 = () => {
    return (
        <Box sx={{display: 'flex', p: 6, gap: 4, justifyContent: 'center'}}>
            <QualitySeminarGoal23 />
            <NewParticipantsGoal23 />
            <NewUserCoursesGoal23 />
            <QualitySeminarsGoal23 />
            {/* other guys forthcoming! */}
            {/* <KvalitetspakkerGoal /> */}
            {/* <KursbevisGoal /> */}
        </Box>
    )
}
