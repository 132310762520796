import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import {IndicatorProps} from './index'
import {CheckboxIndicatorType} from '../../Types'

const CheckboxIndicator = ({indicator, answer, onChange, disabled = false}: IndicatorProps<CheckboxIndicatorType, boolean>) => {
    const {fresh, value, valid} = answer
    const hasAnswer = value !== undefined && value !== null
    const hasError = !disabled && !fresh && !valid && !indicator.optional

    return (
        <FormGroup hasError={hasError} hasSuccess={!fresh && valid} size={'lg'}>
            <label>{indicator['title']}</label>
            <div>
                <label>
                    <input
                        onChange={() => onChange(true, true)}
                        checked={hasAnswer && value}
                        name={indicator['@id']}
                        disabled={disabled}
                        type='radio'
                        value='yes'
                    />{' '}
                    Ja
                </label>
            </div>
            <div>
                <label>
                    <input
                        onChange={() => onChange(false, true)}
                        checked={hasAnswer && !value}
                        name={indicator['@id']}
                        disabled={disabled}
                        type='radio'
                        value='no'
                    />{' '}
                    Nei
                </label>
            </div>
            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
        </FormGroup>
    )
}
export default CheckboxIndicator
