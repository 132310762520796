import React, {ReactNode} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

type DefaultType = {
    name: string
    '@id': string
}

type EntitiesType = {
    '@expiresAt'?: boolean
    '@fetchedAt'?: number
    '@id': string
    '@status'?: string
    '@ttl'?: boolean
    '@type': string
    id: number
    name?: string
    title?: string
    region?: string
    regionId?: number
}

type Props<Type> = {
    id: string
    label?: ReactNode
    help?: ReactNode
    value?: Type | string | null | number
    onChange: (value: Type, e: React.ChangeEvent<HTMLInputElement>) => any
    labelFn?: (value: Type) => ReactNode
    valueFn?: (value: Type | string | null | undefined) => any
    disabledFn?: (value: Type) => boolean
    entities: EntitiesType[]
    required?: boolean
    placeholder?: string
    disabled?: boolean
    marginBottom?: number
    conserveSpace?: boolean
    sx?: any
    error?: boolean
}
/** @deprecated*/
function SelectField<Type = DefaultType>({
    id,
    label,
    help,
    value,
    onChange,
    entities = [],
    labelFn = undefined,
    valueFn = undefined,
    required = true,
    placeholder = 'Velg...',
    disabledFn = () => false,
    disabled = false,
    marginBottom = 2,
    conserveSpace = false,
    sx,
    error,
}: Props<Type>) {
    const onLocalChange = (e, val) => {
        onChange(val?.['@original'] ?? val, val)
    }

    const options = entities.sort(function (a, b) {
        if (a?.name < b?.name) {
            return -1
        }
        if (a?.name > b?.name) {
            return 1
        }
        return 0
    })

    // Find local value
    // - Check if valueFn exist
    // - test regular value
    // - fallback, check if value equals to entity Id
    const localValue =
        entities.find(e => (valueFn ? valueFn(e) : e) === value) ||
        entities.find(e => e?.['@id'] === value || (e as any)?.id === value) ||
        null

    return (
        <Autocomplete
            sx={{mb: marginBottom, ...sx}}
            id={id}
            options={options}
            value={localValue}
            // @ts-expect-error e.name must be set if labelFn is undefiend, but how to type it?!
            getOptionLabel={option => (labelFn ? labelFn(option) : option?.name ?? '')}
            fullWidth
            onChange={onLocalChange}
            disableClearable={required}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    placeholder={placeholder}
                    sx={{
                        mt: conserveSpace ? 0 : 2,
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: error ? '#ab3e36' : 'initial',
                        },
                    }}
                    required={required}
                    {...params}
                    label={label}
                    helperText={help}
                />
            )}
        />
    )
}
export default SelectField
