import React, {CSSProperties, ReactNode} from 'react'

type Props = {
    msg?: string
    children?: ReactNode
    style?: CSSProperties
}

const ErrorComponent = React.forwardRef<any, Props>(({msg, children}, ref) => {
    return (
        <h4 ref={ref} className={'text-center'} title={msg}>
            {children && (
                <>
                    {children}
                    <br />
                </>
            )}
            <i className='fa fa-exclamation-triangle fa-2x' />
            <br />
            <small>{msg}</small>
        </h4>
    )
})

export default ErrorComponent
