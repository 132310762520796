import * as React from 'react'
import TextField from '@mui/material/TextField'
import {useState} from 'react'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import Dialog from '@mui/material/Dialog'
import {SetState} from '../../Utilities/TypeHelpers'
import {components} from '../../Generated/eportal'
import {MenuItem} from '@mui/material'

interface Props {
    onSave: (newCourse: components['schemas']['Course.AddCourseDTO.jsonld']) => any
    onClose: Function
    isOpen: boolean
}

export default function CreateCourseDialog({onSave, onClose, isOpen = false}: Props) {
    const [title, setTitle] = React.useState<string>('')
    const [shortTitle, setShortTitle] = React.useState<string>('')
    const [type, setType] = React.useState<number>(0)
    const [description, setDescription] = React.useState<string>('')
    const [learningGoal, setLearningGoal] = React.useState<string>('')

    const onSubmit = async () => {
        await onSave({title, description, shortTitle, type, learningGoal})
        onLocalClose()
    }

    const onLocalClose = () => {
        setTitle('')
        setShortTitle('')
        setType(0)
        setDescription('')
        setLearningGoal('')
        onClose(false)
    }

    return (
        <Dialog maxWidth='sm' open={isOpen} onClose={onLocalClose}>
            <DialogContent>
                <Typography>Legg til kurs</Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            id={'title'}
                            label={'Tittel'}
                            type={'text'}
                            placeholder={'Kurstittel'}
                            fullWidth
                            value={title}
                            required
                            onChange={e => setTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id={'shortTitle'}
                            label={'Forkortelse'}
                            type={'text'}
                            placeholder={'LMG'}
                            fullWidth
                            value={shortTitle}
                            required
                            onChange={e => setShortTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            id={'type'}
                            disabled={false}
                            label={'Type'}
                            fullWidth
                            value={type}
                            onChange={e => setType(Number(e.target.value))}
                            required
                        >
                            <MenuItem value={0}>E-Læringskurs</MenuItem>
                            <MenuItem value={1}>Klinisk emnekurs</MenuItem>
                            <MenuItem value={2}>Seminar</MenuItem>
                            <MenuItem value={3}>Konferanse</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            rows={3}
                            id={'description'}
                            label={'Beskrivelse'}
                            placeholder={'Kort beskrivelse av kurset'}
                            value={description}
                            fullWidth
                            multiline
                            required
                            onChange={e => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            rows={10}
                            id={'learningGoal'}
                            label={'Læringsmål'}
                            placeholder={'Oppgi læringsmålene til kurset'}
                            value={learningGoal}
                            fullWidth
                            multiline
                            required
                            onChange={e => setLearningGoal(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={onLocalClose}>
                    Avbryt
                </Button>
                <Button disabled={false} variant='contained' onClick={onSubmit}>
                    Opprett kurs
                </Button>
            </DialogActions>
        </Dialog>
    )
}
