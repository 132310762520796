import React, {useState} from 'react'
import TextField from './Fields/TextField'
import MultiSelectField from './Fields/MultiSelectField'
import TextEditor from './TextEditor/TextEditor'
import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import {Button} from './Button/MuiButton'
import BoostrapButton from './Button/Button'

export default function SendEmailDialog({group, userGroups, onSave}) {
    const [isOpen, setIsOpen] = React.useState(false)
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const users = userGroups.map(ug => ({
        '@id': ug['@id'],
        name: ug.user.name,
    }))
    let recipients = userGroups.map(member => (member && member['@id'] ? member['@id'] : null))
    const [multipleMembers, setMultipleMembers] = useState(recipients.length > 0 ? recipients : [])
    const onChangeSubject = string => {
        setSubject(string)
    }
    const setValue = value => {
        setContent(value)
    }
    const onSubmit = async () => {
        await onSave({multipleMembers, subject, content, group: group.id})
        setIsOpen(false)
    }

    const onLocalClose = () => {
        setIsOpen(false)
    }

    const onShow = () => {
        setContent('')
        setSubject('')
        setMultipleMembers(recipients)
        setIsOpen(true)
    }

    const onSaveEnabled = subject?.length > 0 && content?.length > 0 && multipleMembers?.length > 0
    return (
        <>
            {/* Hack so we have access to OnShow event to update selection of recipients */}
            <BoostrapButton size={'sm'} onClick={onShow}>
                <i className='far fa-envelope' title={'Ny e-post'} /> Ny e-post
            </BoostrapButton>

            <Dialog open={isOpen} onClose={onLocalClose}>
                <DialogTitle>Send e-post til gruppens medlemmer</DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    {userGroups.length > 0 && (
                        <MultiSelectField
                            id={'members'}
                            label={'Gruppemedlemmer'}
                            value={multipleMembers}
                            entities={users}
                            onChange={setMultipleMembers}
                        />
                    )}
                    <TextField
                        id={'subject'}
                        label={'Emne'}
                        placeholder={'Skriv emnet til e-posten'}
                        value={subject}
                        onChange={onChangeSubject}
                    />
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <TextEditor id={'content'} placeholder={'Innhold'} value={content} onChange={setValue} />
                        </div>
                    </div>
                </DialogContent>

                <DialogActions sx={{px: 3, pb: 2}}>
                    <Button variant='outlined' onClick={onLocalClose}>
                        Lukk
                    </Button>
                    <Button disabled={!onSaveEnabled} variant='contained' onClick={onSubmit}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
