import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid, {InitModel} from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridCellParams, GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import courseColumnDef from '../../Components/DataGrid/columnDefs/courseColumnDef'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {useState} from 'react'
import CreateCourseDialog from '../Components/CreateCourseDialog'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {FilterList as FilterListIcon} from '@mui/icons-material'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'

export const COURSE_TYPES = [
    {value: 0, label: 'E-læringskurs'},
    {value: 1, label: 'Kvalitetspakke'},
    {value: 2, label: 'Seminar'},
    {value: 3, label: 'Konferanse'},
]

export const COURSE_STATUS = [
    {value: 0, label: 'Under utvikling'},
    {value: 1, label: 'Aktivt'},
    {value: 2, label: 'Arkivert'},
]

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'ID', type: 'number'}),
    courseColumnDef({field: 'course.shortTitle'}),
    selectColumnDef({field: 'course.type', headerName: 'Type', valueOptions: COURSE_TYPES}),
    selectColumnDef({field: 'courseStatus', headerName: 'Status', valueOptions: COURSE_STATUS}),
    columnDef({field: 'course.title', headerName: 'Tittel'}),
    dateColumnDef({field: 'nextApproval', headerName: 'Neste godkjenning', type: 'date'}),
    columnDef({field: 'invited', headerName: 'Invitert (i år)', type: 'number'}),
    columnDef({field: 'accepted', headerName: 'Påmeldt (i år)', type: 'number'}),
    columnDef({field: 'acceptedAllYears', headerName: 'Påmeldt (alle år)', type: 'number'}),
    columnDef({field: 'completed', headerName: 'Fullført (i år)', type: 'number'}),
    columnDef({field: 'total', headerName: 'Totalt (invitert+påmeldt i år)', type: 'number'}),
    columnDef({field: 'meetings', headerName: 'Antall møter i år', type: 'number'}),
    columnDef({field: 'minimumOneTaskCompleted', headerName: 'Fullført minst èn oppgave som ikke er møte (i år)', type: 'number'}),
    columnDef({field: 'courseCertificatesThisYear', headerName: 'Kursbevis generert (i år)', type: 'number'}),
    columnDef({field: 'totalCourseCertificates', headerName: 'Totalt kursbevis', type: 'number'}),
]

const columnVisibilityModel = {
    invited: false,
    completed: false,
    meetings: false,
}

const Courses = () => {
    const {data, isFetching, refetch} = useSkilQuery<'getCourseStatistics'>('/api/courses/statistics/overview')
    const courses = data?.['hydra:member'] ?? []
    const [showCourseDialog, setShowCourseDialog] = useState(false)
    const createCourseMutation = useSkilMutation<'addCourse'>('POST', '/api/courses', ['/api/courses'])
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel})

    const actions: SpecialActionProps[] = [
        {
            label: 'Legg til kurs',
            action: () => setShowCourseDialog(true),
            disabled: false,
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: 'Kvalitetspakker',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false},
                    sort: [{field: 'course', sort: 'asc'}],
                    filter: [{columnField: 'course.type', operatorValue: 'is', value: 1}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'E-Læringskurs',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false},
                    sort: [{field: 'course', sort: 'asc'}],
                    filter: [{columnField: 'course.type', operatorValue: 'is', value: 0}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'Kvalitetsseminar',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false},
                    sort: [{field: 'course', sort: 'asc'}],
                    filter: [{columnField: 'course.type', operatorValue: 'is', value: 2}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'Konferanser',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false},
                    sort: [{field: 'course', sort: 'asc'}],
                    filter: [{columnField: 'course.type', operatorValue: 'is', value: 3}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
    ]

    const onCreateCourse = async newCourse => {
        try {
            await createCourseMutation.mutateAsync(newCourse)
            toast('Kurset ble opprettet', {type: 'success'})
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    return (
        <Box>
            <DataGridWrapper rows={courses.length}>
                <DataGrid
                    id={'courses'}
                    initModel={initModel}
                    loading={isFetching}
                    rows={courses}
                    columns={columns}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={actions} />}
                    getCellClassName={(params: GridCellParams<any, any, number>) => {
                        const now = new Date()
                        const inThreeMonths = new Date(now.getFullYear(), now.getMonth() + 3, now.getDate())
                        // if next approval is in the past, show alert
                        if (params.field === 'nextApproval' && params.value && params.value < now) {
                            return 'MuiDataGrid-cell-alert'
                        }
                        // if less than three months left until next approval, show warning
                        if (params.field === 'nextApproval' && params.value && params.value < inThreeMonths && params.value > now) {
                            return 'MuiDataGrid-cell-warning'
                        }
                        return ''
                    }}
                />
            </DataGridWrapper>
            <CreateCourseDialog isOpen={showCourseDialog} onSave={onCreateCourse} onClose={() => setShowCourseDialog(false)} />
        </Box>
    )
}

export default Courses
