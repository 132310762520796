import * as React from 'react'

type Simplify<T> = T extends infer S ? {[K in keyof S]: S[K]} : never
export type NoneOf<T> = Simplify<{[K in keyof T]?: never}>
export type AtMostOneOf<T> = NoneOf<T> | {[K in keyof T]: Simplify<Pick<T, K> & NoneOf<Omit<T, K>>>}[keyof T]
export type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false

    // Javascript compiler hack to make sure we *actually* dont return empty values
    // See https://stackoverflow.com/a/46700791/2103434
    // noinspection JSUnusedLocalSymbols
    const testDummy: TValue = value
    return true
}

export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + x)
}

export type Unboxed<T> =
    T extends (infer U)[]
        ? U
        : T;
