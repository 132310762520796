import * as React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'
import {DEFAULT_ICON_PROPS} from './constants'

export default function CalendarIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...DEFAULT_ICON_PROPS} {...props}>
            <g>
                <path d='m1055.6 733.12v-494.06c0-43.125-34.688-77.812-77.812-77.812h-129.38v-61.875c0-13.125-10.312-23.438-23.438-23.438h-71.25c-13.125 0-23.438 10.312-23.438 23.438v61.875h-395.62v-61.875c0-13.125-10.312-23.438-23.438-23.438h-71.25c-13.125 0-23.438 10.312-23.438 23.438v61.875h-128.44c-43.125 0-77.812 34.688-77.812 77.812v687.19c0 43.125 34.688 77.812 77.812 77.812h716.25c30.938 71.25 102.19 120.94 183.75 120.94 110.62 0 201.56-90 201.56-201.56 0-88.125-56.25-162.19-134.06-190.31zm-277.5-610.31h24.375v125.62h-24.375zm-514.69 0h24.375v125.62h-24.375zm-175.31 85.312h128.44v63.75c0 13.125 10.312 23.438 23.438 23.438h71.25c13.125 0 23.438-10.312 23.438-23.438v-64.688h396.56v64.688c0 13.125 10.312 23.438 23.438 23.438h70.312c13.125 0 23.438-10.312 23.438-23.438v-64.688h128.44c16.875 0 30.938 14.062 30.938 30.938v120.94h-950.62v-120c0-17.812 14.062-30.938 30.938-30.938zm0 748.12c-16.875 0-30.938-14.062-30.938-30.938v-518.44h951.56v315.94c-6.5625-0.9375-13.125-0.9375-19.688-0.9375-111.56 0-201.56 90-201.56 200.62 0 11.25 0.9375 22.5 2.8125 33.75zm900 120.94c-85.312 0-154.69-69.375-154.69-154.69s70.312-153.75 154.69-153.75 154.69 69.375 154.69 154.69-69.375 153.75-154.69 153.75z' />
                <path d='m1078.1 898.12h-66.562v-66.562c0-13.125-10.312-23.438-23.438-23.438s-23.438 10.312-23.438 23.438v66.562h-66.562c-13.125 0-23.438 10.312-23.438 23.438s10.312 23.438 23.438 23.438h66.562v66.562c0 13.125 10.312 23.438 23.438 23.438s23.438-10.312 23.438-23.438v-66.562h66.562c13.125 0 23.438-10.312 23.438-23.438s-10.312-23.438-23.438-23.438z' />
            </g>
        </SvgIcon>
    )
}
