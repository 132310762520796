import React, {ReactNode} from 'react'
import TextEditor from '../TextEditor/TextEditor'
import styled from 'styled-components'

const StyledTextEditor = styled(TextEditor)`
    min-height: ${({rows}) => (rows * 22).toString() + 'px'};
`
type Props = {
    id: string
    label?: ReactNode
    help?: ReactNode
    value?: string
    onChange: (value: string, e) => any
    rows?: number
}
/** @deprecated*/
const RichTextField = ({id, label, help, value, onChange, rows = 4, ...props}: Props) => {
    return (
        <>
            <div style={{marginBottom: '5px'}}>{label && <b>{label}</b>}</div>
            <StyledTextEditor id={id} rows={rows} value={value} editing onChange={onChange} {...props} />
            {help && <p>{help}</p>}
        </>
    )
}
export default RichTextField
