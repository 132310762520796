import React, {useState} from 'react'
import Button from '../../Components/Button/Button'
import LoadingComponent from '../../Components/LoadingComponent'
import useEntity, {LoadedUseEntityType} from '../../Hooks/useEntity'
import {LocationContext} from './App'
import {AppAnswerSetType, FormTaskType, UnansweredQuestionsType} from '../Types'
import {useLocation} from 'react-router-dom'

type Props = {
    onShowForm: () => void
    answerSet: AppAnswerSetType
    task?: LoadedUseEntityType<FormTaskType>
    unansweredQuestions: UnansweredQuestionsType
    answerSetSubmitting: boolean
    isPreview: boolean
}
const StatusPage = ({onShowForm, answerSet, task, unansweredQuestions, answerSetSubmitting = false, isPreview}: Props) => {
    const location = useLocation()
    const {answersInState} = React.useContext(LocationContext) || {}
    let url: string | boolean = false
    const isApproved = !!(answerSet && answerSet['approved']) || isPreview || !!(answersInState && answersInState['approved'])
    const courseIri = task && !task['anonymous'] ? task.course : null
    const course = useEntity<'getCourseItem'>(courseIri)
    const isRelatedTask = location.pathname?.includes('related_task')

    if (course['shortTitle']) {
        url = `/dashboard/course/${course['shortTitle']}/tasks/`
    }

    if (answerSetSubmitting) {
        return <LoadingComponent absolute={false}>Vi sjekker svarene, vennligst vent</LoadingComponent>
    }

    if (!isApproved) {
        return (
            <>
                <h3>Oppgaven mangler svar:</h3>
                <ul>
                    {Object.values(unansweredQuestions).map(({group, indicators}) => (
                        <li key={group['@id']}>
                            {group['title']}
                            <ul>
                                {indicators.map(i => (
                                    <li key={i['@id']}>{i['title']}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
                <Button variant='primary' onClick={onShowForm}>
                    Gå tilbake
                </Button>
            </>
        )
    }

    return (
        <>
            <h3>Takk for innsendt skjema</h3>
            {isRelatedTask && <p>Du må velge ny pasient for hver pasient du evaluerer.</p>}
            <Button variant={url ? 'default' : 'primary'} onClick={onShowForm}>
                Vis skjema
            </Button>
            {url && (
                <>
                    <Button variant={'primary'} href={url}>
                        Tilbake til kurset
                    </Button>
                </>
            )}
        </>
    )
}
export default StatusPage
