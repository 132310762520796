import useEntity from '../Hooks/useEntity'
import useEntities from '../Hooks/useEntities'
import {jsonFetch} from '../Components/jsonFetch'
import {AnswerValueType, AppAnswerSetType, NewLocationType, ResponseAnswerType} from './Types'
import {toast} from 'react-toastify'
import LoadingComponent from '../Components/LoadingComponent'
import React, {useState, useEffect} from 'react'
import {App, saveChanges} from './Form/App'
import {Toolbar} from './Toolbar'
import {SkilQueryResponseType} from '../Utilities/QueryClient'
import {useNavigate} from 'react-router-dom'

export const RelatedTasksApp = ({taskId, answerSetId}) => {
    const navigate = useNavigate()
    const task = useEntity<'getFormRelatedTaskItem'>(`/api/form_related_tasks/${taskId}`)
    const form = useEntity<'getFormItem'>(task?.form)
    const dependencies = useEntities<'getFormIndicatorDependencyCollection'>(task.form ? `${task.form}/indicator_dependencies` : null)
    const indicators = useEntities<'getFormIndicatorCollection'>(task.form ? `${task.form}/indicators?disabled=0` : null)
    const groups = useEntities<'getFormIndicatorGroupCollection'>(task.form ? `${task.form}/indicator_groups?disabled=0` : null)
    const answerSet = useEntity<'findAnswerSet'>(answerSetId ? `/api/answer_sets/${answerSetId}` : null)
    const [gettingNewAnswerSet, setGettingNewAnswerSet] = useState(false)
    useEffect(() => {
        if (answerSetId) {
            return
        }
        const id = window.localStorage.getItem('current-answerSetId')
        if (!id) {
            return
        }
        navigate(`/dashboard/related_task/${taskId}/${id}`)
    }, [answerSetId])

    async function createAnswerset() {
        setGettingNewAnswerSet(true)
        try {
            const newAnswerSet = await jsonFetch<SkilQueryResponseType<'createAnswerSet'>>(
                `/api/answer_sets/newFormRelatedTask?task=${task?.id}`
            )
            window.localStorage.setItem('current-answerSetId', String(newAnswerSet.id))
            navigate(`/dashboard/related_task/${taskId}/${newAnswerSet.id}`)
        } finally {
            setGettingNewAnswerSet(false)
        }
    }

    const [isWorking, setIsWorking] = React.useState(false)

    const onSubmit = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        newLocation?: NewLocationType
    ): Promise<AppAnswerSetType> => {
        const isApproved = answerSet && answerSet['approved']
        if (isApproved) {
            return {
                answers: answerSet.answers ?? [],
                newLocation: newLocation ?? undefined,
                approved: true,
            }
        }

        const submittedAnswerSet = await jsonFetch<SkilQueryResponseType<'masterRelatedSubmitAnswerSet', '201'>>(
            `/api/master_related_answer_sets?task=${task?.id}`,
            {
                json: {
                    indicators: answers,
                    location: newLocation,
                },
            }
        )

        return {
            newLocation: submittedAnswerSet.newLocation,
            approved: submittedAnswerSet.approved,
            answers: submittedAnswerSet.answers,
        }
    }

    const onChangeLocation = async location => {
        if (isWorking || !answerSet['@id'] || answerSet['approved']) {
            return
        }

        try {
            setIsWorking(true)
            await jsonFetch(answerSet['@id'], {
                method: 'PUT',
                json: {location, indicators: {}},
            })
        } catch (e) {
            toast((e as Error).message, {type: 'error'})
        } finally {
            setIsWorking(false)
        }
    }

    const onChange = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        save: boolean,
        newLocation?: NewLocationType
    ): Promise<Array<string>> => {
        if (answerSet['approved'] || !save) {
            return []
        }

        if (isWorking) {
            return []
        }
        setIsWorking(true)
        try {
            type PatchAnswerSet = SkilQueryResponseType<'updateAnswerSet'>
            const results = await saveChanges<PatchAnswerSet>(answerSet['@id'], answers, newLocation)
            // return the indicators that have been saved
            if (typeof results.answers === 'object') {
                // find the indicators that have been saved
                // @ts-expect-error
                const responseAnswers: ResponseAnswerType[] = Object.values(results?.answers)
                return responseAnswers
                    ?.filter(answer => Object.entries(answers).some(([key, value]) => key === answer.indicator && value === answer.value))
                    .map(answer => answer.indicator!)
            }
            if (Array.isArray(results?.answers)) {
                const returnedAnswers = results?.answers as Array<any>
                return returnedAnswers
                    .filter(answer => Object.entries(answers).some(([key, value]) => key === answer.indicator && value === answer.value))
                    .map(answer => answer.indicator!)
            } else {
                return []
            }
        } catch (e) {
            toast((e as Error).message, {type: 'error'})
            return []
        } finally {
            setIsWorking(false)
        }
    }

    if (!task['@loaded']) {
        return <LoadingComponent msg={'Laster inn oppgave...'} />
    }

    if (!answerSetId) {
        return (
            <>
                <Toolbar
                    task={task}
                    createAnswerset={createAnswerset}
                    /* @ts-expect-error */
                    relatedTaskUniqueId={answerSet.metadata?.masterRelatedTask}
                    gettingNewAnswerSet={gettingNewAnswerSet}
                    approved={answerSet.approved}
                />
            </>
        )
    }

    if (!answerSet['@loaded'] || !form['@loaded'] || !dependencies['@loaded'] || !indicators['@loaded'] || !groups['@loaded']) {
        return <LoadingComponent msg={'Laster inn spørsmål...'} />
    }

    if (groups.length === 0) {
        return <h1>Oppgaven er ikke klar enda</h1>
    }

    return (
        <>
            <Toolbar
                task={task}
                createAnswerset={createAnswerset}
                /* @ts-expect-error */
                relatedTaskUniqueId={answerSet.metadata?.masterRelatedTask}
                gettingNewAnswerSet={gettingNewAnswerSet}
                approved={answerSet.approved}
            />
            <App
                onSubmit={onSubmit}
                onChange={onChange}
                answerSet={answerSet}
                dependencies={dependencies}
                /* @ts-expect-error */
                indicators={indicators}
                groups={groups}
                task={task}
                form={form}
                onChangeLocation={onChangeLocation}
                isPreview={false}
                title={form.name}
            />
        </>
    )
}
