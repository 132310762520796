import React from 'react'
import styled from 'styled-components'
import EditText from './EditText'

const StyledDiv = styled.div`
    display: inline-block;
    padding: 0 4px;
    right: 0;
    left: 2em;
    width: calc(100% - 20px);
`

const StyledLabel = styled.label`
    position: relative;
    display: block;
`

const EditableRadioInput = ({disabled, onChange, value, onSave, label, name, checked, onRemove, toolboxStyling}) => {
    return (
        <StyledLabel>
            <input disabled={disabled} onChange={onChange} checked={checked} name={name} type='radio' value={value} />
            <StyledDiv>
                <EditText onSave={onSave} onRemove={onRemove} text={label} toolboxStyling={toolboxStyling} />
            </StyledDiv>
        </StyledLabel>
    )
}
export default EditableRadioInput
