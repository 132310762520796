import * as React from 'react'
import {useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {BackdropComponent} from '../Components/BackdropComponent'
import styled from 'styled-components'
import useUser from '../Utilities/useUser'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {Button} from '../Components/Button/MuiButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import LoadingComponent from '../Components/LoadingComponent'
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material'
import {CountyAutocomplete} from '../Components/CountyAutocomplete'
import {OfficeAutocomplete, OfficeType} from '../Components/OfficeAutocomplete'
import SelectField from '../Components/Fields/SelectField'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {HprProfessionsExport} from '../Components/HprProfessionsExport'
import {useParams} from 'react-router'

const StyledBackdropComponent = styled(BackdropComponent)`
    max-height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
    }
`

type RoleOptionsType = {
    name: string
    id: string
}

export type CountyType = {
    id: string | undefined
    label: string | undefined
    region: string | undefined
    regionId: number | undefined
}

export type DistrictType = {
    id: string
    name: string
}

function isNumber(value) {
    return !isNaN(value) && !isNaN(parseFloat(value))
}

const countiesWithDistricts = ['/api/counties/5001', '/api/counties/301', '/api/counties/4601', '/api/counties/1103']

export const ConfirmUserDetails = () => {
    const {iri: userIri, id: userId} = useUser()
    const {redirect} = useParams()
    const bigScreen = useMediaQuery('(min-width:600px)')
    const [enableGetUserDetails, setEnableGetUserDetails] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>('')
    const [email, setEmail] = React.useState<string>('')
    const [mobile, setMobile] = React.useState<string>('')
    const [newsletter, setNewsletter] = React.useState<boolean>(false)
    const [county, setCounty] = React.useState<CountyType | null>(null)
    const [district, setDistrict] = React.useState<DistrictType | undefined>(undefined)
    const [office, setOffice] = React.useState<OfficeType | undefined>(undefined)
    const [marketing, setMarketing] = React.useState<boolean>(false)
    const [role, setRole] = React.useState<string>('')
    const [currentRoleName, setCurrentRoleName] = React.useState<string>('')
    const [hpr, setHpr] = React.useState<string | null | undefined>('')
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [isSuccessfullySaved, setSuccessfullySaved] = React.useState(false)
    const [isConfirmedValidEmail, setConfirmedValidEmail] = React.useState(false)
    const updateUserProfile = useSkilMutation<'updateUserProfile'>('post', `${userIri}/update_profile`)
    const professions = HprProfessionsExport()
    const requireHpr = professions.includes(currentRoleName.toLowerCase())

    const {
        data: userDetails,
        isFetching: isFetchingUserDetails,
        isSuccess: isSuccessUserDetails,
    } = useSkilQuery<'getUserDetailsForConfirmation'>(`/api/users/${userId}/details`, {}, {enabled: enableGetUserDetails})

    const {
        data: rolesRaw,
        isFetching: isFetchingRoles,
        isSuccess: isSuccessRoles,
    } = useSkilQuery<'getUserRoleCollection'>(`/api/user_roles`, {}, {enabled: true})

    const roles = rolesRaw?.['hydra:member']

    const handleChangeNewsletter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewsletter((event.target as HTMLInputElement).value === 'true')
    }

    const handleChangeMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarketing((event.target as HTMLInputElement).value === 'true')
    }

    // enable fetch user details
    React.useEffect(() => {
        setEnableGetUserDetails(true)
    }, [])

    // disable fetch user details
    React.useEffect(() => {
        if (userDetails && isSuccessUserDetails) {
            setEnableGetUserDetails(false)
            setName(userDetails.name)
            setEmail(userDetails.email)
            setMobile(userDetails.mobile ?? '')
            setMarketing(userDetails.acceptMarketing)
            setCounty({
                id: userDetails.location?.['@id'],
                label: userDetails.location?.name,
                region: userDetails.location?.region,
                regionId: userDetails.location?.regionId,
            })
            if (userDetails.district?.['@id'] && userDetails.district?.name) {
                setDistrict({
                    name: userDetails.district?.name ?? '',
                    id: userDetails.district?.['@id'] ?? '',
                })
            }

            setOffice({
                officeType: 'skil',
                office: userDetails.office?.['@id'] ?? '',
                county: userDetails?.location?.['@id'] ?? undefined,
                district: userDetails.district?.['@id'] ? {name: userDetails.district?.name, id: userDetails.district?.['@id']} : undefined,
            })

            setRole(userDetails.role?.['@id'] ?? '')
            setCurrentRoleName(userDetails.role?.name ?? '')
            setHpr(userDetails.hpn?.toString())

            // if newsletter is accepted or added to email campaign
            if (userDetails.newsletter === 2 || userDetails.newsletter === 3) {
                setNewsletter(true)
            } else {
                setNewsletter(false)
            }
        }
    }, [isSuccessUserDetails])

    const saveProfile = async () => {
        // TODO:: kreve hpr nr kun hvis yrket tilsier at vi skal samle det inn
        if (!hpr && requireHpr) {
            handleErrorWithToast(new Error('HPR nummer må fylles inn'))
            return
        }
        if (hpr && requireHpr && (hpr?.length < 7 || hpr?.length > 8)) {
            handleErrorWithToast(new Error('HPR nummer skal være 7 eller 8 siffer langt'))
            return
        }
        if (!isNumber(hpr) && requireHpr) {
            handleErrorWithToast(new Error('HPR nummer må fylles inn'))
            return
        }

        const tempHpr = Number(hpr)

        if (
            !office ||
            (office?.officeType === 'skil' && !office?.office) ||
            (office?.officeType === 'external' && !office?.externalOffice)
        ) {
            handleErrorWithToast(new Error('Du må velge et arbeidssted'))
            return
        }

        if (!county?.id) {
            handleErrorWithToast(new Error('Du må velge en kommune'))
            return
        }

        if (countiesWithDistricts.includes(county?.id) && !district?.id) {
            handleErrorWithToast(new Error('Du må velge en bydel'))
            return
        }
        if (!role) {
            handleErrorWithToast(new Error('Du må velge et yrke'))
            return
        }

        try {
            setIsSubmitting(true)
            await updateUserProfile.mutateAsync({
                '@overridePath': `${userIri}/update_profile`,
                name,
                email,
                mobile,
                newsletter,
                marketing,
                role,
                office: {
                    county: county.id,
                    district: district?.id ?? null,
                    officeType: office.officeType,
                    office: office?.office,
                    // @ts-expect-error Only available in NewOfficeType
                    officeName: office?.officeName ?? null,
                    // @ts-expect-error
                    externalOffice: office?.externalOffice,
                },
                county: county.id,
                district: district?.id ?? null,
                hpr: tempHpr,
                confirmedValidEmail: isConfirmedValidEmail,
            })
            setIsSubmitting(false)
            setSuccessfullySaved(true)
            toast('Bruker profilen ble lagret', {type: 'success'})
            if (redirect && redirect.includes('course_')) {
                const shortTitle = redirect.replace('course_', '')
                window.location.replace(`/dashboard/course/${shortTitle}/tasks`)
            } else {
                window.location.replace(`/dashboard`)
            }
        } catch (e) {
            handleErrorWithToast(e)
            setIsSubmitting(false)
            setSuccessfullySaved(false)
        }
    }

    if (!isSuccessUserDetails || !roles || isSuccessfullySaved) {
        return (
            <LoadingComponent size='h4' absolute={true} backdrop={true} color='#fff'>
                Laster inn...
            </LoadingComponent>
        )
    }

    return (
        <>
            <StyledBackdropComponent
                isOpen={true}
                showSpinner={false}
                componentWidth={bigScreen ? '80%' : '100%'}
                showScrollers={false}
                showFooter={false}
            >
                {(() => {
                    return (
                        <>
                            <Grid container spacing={0} mt={0} sx={{marginLeft: '0', width: '100%'}}>
                                <Grid
                                    container
                                    style={{
                                        outline: '1px solid #c4c4c4',
                                        padding: '1em',
                                        paddingBottom: '1.5em',
                                        borderRadius: '0.25em',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <h4 style={{fontWeight: 'bold'}}>Bekreft bruker profil</h4>
                                    <Grid item xs={12}>
                                        <TextField
                                            id='name'
                                            label='Navn'
                                            placeholder='Ola Normann'
                                            value={name}
                                            required
                                            fullWidth
                                            onChange={event => {
                                                setName(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid container spacing={1} mt={0}>
                                        <Grid item xs={bigScreen ? 8 : 12}>
                                            <TextField
                                                id='email'
                                                label='E-post'
                                                placeholder='ola@normann.no'
                                                value={email}
                                                sx={{mt: 0}}
                                                required
                                                fullWidth
                                                onChange={event => {
                                                    setEmail(event.target.value)
                                                }}
                                                error={userDetails.invalidEmail && !isConfirmedValidEmail && userDetails.email === email}
                                                helperText={
                                                    userDetails.invalidEmail &&
                                                    !isConfirmedValidEmail &&
                                                    userDetails.email === email && (
                                                        <>
                                                            Vi har registrert at e-post adressen muligens er feil og at e-post ikke blir
                                                            levert til denne e-posten. Vennligst oppdater e-post adressen din eller bekreft
                                                            at den er riktig.{' '}
                                                            <button onClick={() => setConfirmedValidEmail(true)}>Bekreft</button>
                                                        </>
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={bigScreen ? 4 : 6}>
                                            <TextField
                                                id='mobile'
                                                label='Mobilnummer'
                                                placeholder='40000123'
                                                value={mobile}
                                                sx={{mt: 0}}
                                                required
                                                fullWidth
                                                onChange={event => {
                                                    setMobile(event.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <span style={{color: 'black', fontStyle: 'italic'}}>Hvilken type informasjon ønsker du å motta?</span>
                                    <Grid container spacing={1} mt={0}>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <FormLabel id='radio-buttons-group-newsletter'>Nyhetsbrev</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby='radio-buttons-group-newsletter'
                                                    value={newsletter}
                                                    onChange={handleChangeNewsletter}
                                                    name='radio-buttons-group-newsletter'
                                                >
                                                    <FormControlLabel
                                                        style={{color: 'black'}}
                                                        value={true}
                                                        control={<Radio />}
                                                        label='Ja'
                                                    />
                                                    <FormControlLabel
                                                        style={{color: 'black'}}
                                                        value={false}
                                                        control={<Radio />}
                                                        label='Nei'
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <FormLabel id='radio-buttons-group-marketing'>Generell informasjon</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby='radio-buttons-group-marketing'
                                                    value={marketing}
                                                    onChange={handleChangeMarketing}
                                                    name='radio-buttons-group-marketing'
                                                >
                                                    <FormControlLabel
                                                        style={{color: 'black'}}
                                                        value={true}
                                                        control={<Radio />}
                                                        label='Ja'
                                                    />
                                                    <FormControlLabel
                                                        style={{color: 'black'}}
                                                        value={false}
                                                        control={<Radio />}
                                                        label='Nei'
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} mt={0} mb={0}>
                                        <Grid item xs={6} pt={0} mt={0}>
                                            <SelectField<RoleOptionsType>
                                                id={'role'}
                                                type='select'
                                                disabled={false}
                                                marginBottom={0}
                                                value={role}
                                                // @ts-expect-error
                                                entities={roles}
                                                label={'Yrke'}
                                                onChange={e => {
                                                    setRole(e['@id'])
                                                    setCurrentRoleName(e.name)
                                                }}
                                                conserveSpace={true}
                                            />
                                        </Grid>
                                        {requireHpr && (
                                            <Grid item xs={6} pt={0}>
                                                <TextField
                                                    id='hpr'
                                                    label='HPR nummer'
                                                    placeholder='9999999'
                                                    value={hpr}
                                                    required
                                                    fullWidth
                                                    onChange={event => {
                                                        setHpr(event.target.value)
                                                    }}
                                                    sx={{mt: 0}}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CountyAutocomplete
                                            sx={{mb: 0}}
                                            xs={6}
                                            required={true}
                                            value={county?.id ?? undefined}
                                            valueDistrict={district ?? undefined}
                                            onChange={(e, c, iri, district) => {
                                                setCounty(c)
                                                setDistrict(district)
                                                setOffice({
                                                    county: c?.id,
                                                    district: district,
                                                    office: '',
                                                    officeType: 'skil',
                                                })
                                            }}
                                            conserveSpace={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <OfficeAutocomplete
                                            office={office}
                                            onChange={office => {
                                                setOffice(office)
                                                if (office.officeType !== 'external' && office?.district?.id) {
                                                    setDistrict({name: office.district?.name, id: office.district?.id})
                                                }
                                            }}
                                            county={county?.id}
                                            district={district}
                                            conserveSpace={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>
                                        <Button variant='contained' onClick={saveProfile} disabled={isSubmitting || isSuccessfullySaved}>
                                            Bekreft
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )
                })()}
            </StyledBackdropComponent>
        </>
    )
}
