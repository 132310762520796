import useEntity from '../Hooks/useEntity'
import useEntities from '../Hooks/useEntities'
import LoadingComponent from '../Components/LoadingComponent'
import {jsonFetch} from '../Components/jsonFetch'
import {AnswerValueType, AppAnswerSetType, NewLocationType, ResponseAnswerType} from './Types'
import {toast} from 'react-toastify'
import React from 'react'
import {App, saveChanges} from './Form/App'
import {SkilQueryResponseType} from '../Utilities/QueryClient'

export const FormApp = ({isPreview, formId}) => {
    let iri = `/api/forms/${formId}`
    const form = useEntity<'getFormItem'>(iri)
    const dependencies = useEntities<'getFormIndicatorDependencyCollection'>(iri ? `${iri}/indicator_dependencies` : null)
    const indicators = useEntities<'getFormIndicatorCollection'>(iri ? `${iri}/indicators?disabled=0` : null)
    const groups = useEntities<'getFormIndicatorGroupCollection'>(iri ? `${iri}/indicator_groups?disabled=0` : null)
    const answerSet = useEntity<'findAnswerSet'>(
        formId ? `/api/answer_sets/find?form=${formId}&preview=${isPreview ? 'true' : 'false'}` : null
    )
    const [isWorking, setIsWorking] = React.useState(false)

    if (!form['@loaded'] || !answerSet['@loaded'] || !dependencies['@loaded'] || !indicators['@loaded'] || !groups['@loaded']) {
        return <LoadingComponent msg={'Laster inn spørsmål...'} />
    }

    const onSubmit = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        newLocation?: NewLocationType
    ): Promise<AppAnswerSetType> => {
        const isApproved = answerSet && answerSet['approved']
        if (isApproved || isPreview) {
            return {
                answers: answerSet.answers ?? [],
                newLocation: newLocation ?? undefined,
                approved: !isPreview,
            }
        }

        const response = await jsonFetch<SkilQueryResponseType<'submitAnswerSet', 201>>(`${answerSet['@id']}?form=${form.id}&task=0`, {
            json: {
                indicators: answers,
                location: newLocation,
            },
        })

        return {
            newLocation: response.newLocation,
            approved: response.approved,
            answers: response.answers,
        }
    }

    const onChangeLocation = async location => {
        if (isWorking || !answerSet['@id'] || !answerSet['id'] || answerSet['approved'] || isPreview) {
            return
        }

        try {
            setIsWorking(true)
            await jsonFetch(answerSet['@id'], {
                method: 'PUT',
                json: {location, indicators: {}},
            })
        } catch (e) {
            toast((e as Error).message, {type: 'error'})
        } finally {
            setIsWorking(false)
        }
    }

    const onChange = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        save: boolean,
        newLocation?: NewLocationType
    ): Promise<Array<string>> => {
        // Only save if we have id, and the answerSet is not approwed
        // ID means it's not anonymous and approved means it have been submitted before and completed
        if (answerSet['approved'] || !answerSet['@id'] || !answerSet['id']) {
            return []
        }
        if (isWorking || isPreview) {
            return []
        }
        setIsWorking(true)
        // Save all unsaved answers from local storage
        // Only submit one save request at a time / de-throttle put requests

        try {
            type PatchAnswerSet = SkilQueryResponseType<'updateAnswerSet'>
            const results = await saveChanges<PatchAnswerSet>(answerSet['@id'], answers, newLocation)
            // return the indicators that have been saved
            if (typeof results.answers === 'object') {
                // find the indicators that have been saved
                // @ts-expect-error
                const responseAnswers: ResponseAnswerType[] = Object.values(results?.answers)
                return responseAnswers
                    ?.filter(answer => Object.entries(answers).some(([key, value]) => key === answer.indicator && value === answer.value))
                    .map(answer => answer.indicator!)
            }
            if (Array.isArray(results?.answers)) {
                const returnedAnswers = results?.answers as Array<any>
                return returnedAnswers
                    .filter(answer => Object.entries(answers).some(([key, value]) => key === answer.indicator && value === answer.value))
                    .map(answer => answer.indicator!)
            } else {
                return []
            }
        } catch (e) {
            toast((e as Error).message, {type: 'error'})
            return []
        } finally {
            setIsWorking(false)
        }
    }
    if (!answerSet['@loaded'] || !form['@loaded'] || !dependencies['@loaded'] || !indicators['@loaded'] || !groups['@loaded']) {
        return <LoadingComponent msg={'Laster inn spørsmål...'} />
    }

    if (groups.length === 0) {
        return <h1>Oppgaven er ikke klar enda</h1>
    }

    return (
        <App
            onSubmit={onSubmit}
            onChange={onChange}
            onChangeLocation={onChangeLocation}
            answerSet={answerSet}
            dependencies={dependencies}
            // @ts-expect-error
            indicators={indicators}
            groups={groups}
            form={form}
            isPreview={isPreview}
            title={form.name}
        />
    )
}
