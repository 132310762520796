import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import {Button} from '../../../Components/Button/MuiButton'
import * as React from 'react'
import {SetState} from '../../../Utilities/TypeHelpers'

interface DeleteDoctorDialogProps {
    setOpen: SetState<boolean>
    isOpen: boolean
    deleteHandler: () => Promise<void>
    doctorName?: string
}

export const DeleteDoctorDialog = ({setOpen, deleteHandler, doctorName, isOpen = false}: DeleteDoctorDialogProps) => {
    const handleDialogClose = () => {
        setOpen(false)
    }

    return (
        <Dialog maxWidth='xs' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogContent>
                <Typography>Er du sikker at du vil fjerne denne legen fra PraksisNett CRM?</Typography>
                {doctorName && (
                    <Typography fontWeight={600} component='span'>
                        {doctorName}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={handleDialogClose}>
                    Avbryt
                </Button>
                <Button variant='contained' onClick={deleteHandler}>
                    Ja, fjern lege
                </Button>
            </DialogActions>
        </Dialog>
    )
}
