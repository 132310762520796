import React, {useState, useContext} from 'react'
import {motion, useDragControls, Reorder} from 'framer-motion'
import Indicator from './Indicator'
import Button from '../../Components/Button/Button'
import styled from 'styled-components'
import Dependencies from './Dependencies'
import useEntity from '../../Hooks/useEntity'
import {EditLockContext} from './Form'
import {jsonFetch} from '../../Components/jsonFetch'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'

const StyledMotionDiv = styled(Reorder.Item)`
    position: relative;
`

const Panel = styled(motion.div)`
    background: #fff;
    position: relative;
    border-radius: 5px;

    .toolbox {
        background: #fff;
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
`
const Footer = styled(motion.div)`
    border: 1px solid #ddd;

    .dependencies {
        z-index: 1;
    }

    div:nth-child(3) {
        z-index: 1;
        position: relative;
    }
`

const StyledMoveButton = styled.button`
    background: white;
    border: none;
    outline: none;
    outline-color: transparent;
`

const StyledGroupedHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const StyledSmall = styled.div`
    align-self: center;
`

const MissingDependency = styled.div`
    border: ${props => (props.hasMissingDependency ? 'solid 2px red' : '')};
`

const PanelVariant = {
    initial: {
        opacity: 0,
        y: -50,
    },
    hover: {opacity: 1},
    animate: {
        opacity: 1,
        y: 0,
    },
}

// Spring configs
const onTop = {zIndex: 10, opacity: 1}
const flat = {
    zIndex: 0,
    transition: {delay: 0.3},
}

const Toolbox = ({onShowDependencies, onDeleteIndicator, onCopyIndicator, dependenciesCount, onPointerDown, onPointerUp}) => {
    const [lock] = useContext(EditLockContext)

    if (lock) return null

    return (
        <div className={'toolbox'}>
            <Button onClick={onShowDependencies} variant={'link'}>
                <span className='fa fa-layers fa-fw'>
                    <i className='fa fa-link' />
                    {dependenciesCount > 0 && <span className='fa fa-layers-counter'>{dependenciesCount}</span>}
                </span>
            </Button>
            <Button onClick={onCopyIndicator} variant={'link'}>
                <i className={'fa fa-copy fa-fw'} />
            </Button>
            <Button
                onClick={onDeleteIndicator}
                confirm={
                    <>
                        <i className={'fa fa-check fa-fw'} /> Er du sikker?
                    </>
                }
                variant={'link'}
            >
                <i className={'fa fa-trash fa-fw'} />
            </Button>

            <StyledMoveButton onPointerDown={e => onPointerDown(e)} onPointerUp={e => onPointerUp(e)} variant={'link'}>
                <i className={'fa fa-arrows-alt fa-fw'} />
            </StyledMoveButton>
        </div>
    )
}

const IndicatorItem = ({
    iri,
    addItem,
    showAddItem,
    onRemove,
    indicators,
    group,
    reorderValue,
    hasMissingDependency,
    updatingIndicatorGroups,
    setUpdatingIndicatorGroups,
}) => {
    const [value, setValue] = useState('')
    const [showDependencies, setShowDependencies] = useState(false)
    const indicator = useEntity<'getIndicatorItem'>(iri)
    const [isShowing, setIsShowing] = useState(false)
    const controls = useDragControls()

    if (!indicator['@loaded'] || indicator['@deleted']) return null

    const onSave = () => {
        setIsShowing(false)
        indicator.save().then(() => indicators.refresh())
    }

    const onCancel = () => {
        setIsShowing(false)
        return indicator.reset()
    }

    const showSaveAndResetButtons = e => {
        setIsShowing(e)
    }

    const copyIndicator = () => {
        // @ts-expect-error
        copyIndicatorToGroup({indicator: JSON.parse(indicator.id), indicatorGroup: JSON.parse(group.id)})
    }

    const copyIndicatorToGroup = e => {
        jsonFetch(`/api/abstractindicator/${indicator.id}/copy_indicator_to_group`, {json: e})
            .then(() => indicators.refresh())
            .catch(e => handleErrorWithToast(e))
    }

    return (
        <>
            {showAddItem && addItem}
            <StyledMotionDiv value={reorderValue} dragListener={false} dragControls={controls} layout>
                <MissingDependency hasMissingDependency={hasMissingDependency}>
                    <Panel className={'panel-body'} initial={'initial'} whileHover='mainhover' animate={'animate'} variants={PanelVariant}>
                        <StyledGroupedHeader>
                            <StyledSmall>Indikator # {indicator.id}</StyledSmall>
                            <Toolbox
                                onShowDependencies={() => setShowDependencies(show => !show)}
                                onDeleteIndicator={onRemove}
                                onCopyIndicator={copyIndicator}
                                dependenciesCount={(indicator?.dependencies ?? []).length}
                                onPointerDown={e => controls.start(e)}
                                onPointerUp={e => {}}
                            />
                        </StyledGroupedHeader>
                        <Indicator
                            indicator={indicator}
                            onChange={indicator.reset}
                            answer={{fresh: !indicator['@changes'], value, valid: true}}
                            showSaveAndResetButtons={showSaveAndResetButtons}
                        />
                    </Panel>
                    {showDependencies && (
                        <Footer className={'panel-footer'} variants={PanelVariant}>
                            <Dependencies
                                iri={indicator['@id']}
                                updatingIndicatorGroups={updatingIndicatorGroups}
                                setUpdatingIndicatorGroups={setUpdatingIndicatorGroups}
                            />
                        </Footer>
                    )}
                    {indicator['@changes'] && isShowing && (
                        <Footer className={'panel-footer text-right'} variants={PanelVariant}>
                            <Button onClick={onCancel}>Tilbakestill</Button>
                            <Button onClick={onSave} variant={'primary'}>
                                Lagre
                            </Button>
                        </Footer>
                    )}
                </MissingDependency>
            </StyledMotionDiv>
        </>
    )
}
export default IndicatorItem
