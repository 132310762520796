import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import {IndicatorProps} from './index'
import {ChoiceIndicatorType} from '../../Types'

const ChoiceIndicator = ({indicator, answer, onChange, disabled = false}: IndicatorProps<ChoiceIndicatorType, string>) => {
    let alternativesWithOrder = Object.values(indicator.alternatives)
    alternativesWithOrder.sort((a, b) => a.order - b.order)
    const {fresh, value, valid} = answer
    return (
        <FormGroup hasError={!disabled && !fresh && !valid && !indicator.optional} hasSuccess={!fresh && valid} size={'lg'}>
            <label>{indicator['title']}</label>

            {alternativesWithOrder.map(({key, text}) => (
                <div key={key}>
                    <label>
                        <input
                            disabled={disabled}
                            onChange={e => onChange(e.target.value, true)}
                            checked={value === key}
                            name={indicator['@id']}
                            type='radio'
                            value={key}
                        />
                        &nbsp;{text}
                    </label>
                </div>
            ))}

            {indicator['description'] && (
                <span className='help-block' placeholder={'Legg til beskrivelse her'}>
                    {indicator['description']}
                </span>
            )}
            {indicator['optional'] && <span className='help-block'>Dette feltet er valgfritt.</span>}
        </FormGroup>
    )
}
export default ChoiceIndicator
