import * as React from 'react'
import {styled, Theme} from '@mui/material/styles'
import Box, {BoxProps} from '@mui/system/Box'

/************************************************************************
 * These height wrapper components help size their contents vertically
 * in predictable ways, based on spacing and size values in the theme.
 ************************************************************************/

/* view-height relative (vh units) */
interface ViewHeightWrapperProps extends BoxProps {
    viewHeight?: keyof Theme['size']['viewHeight']
}

type Props = {
    theme: Theme
    viewHeight: keyof Theme['size']['viewHeight']
}

const ViewHeightStyledWrapperComponent = styled(({viewHeight, ...props}: ViewHeightWrapperProps) => <Box {...props} />)(
    ({theme, viewHeight = 'md'}: Props) => ({
        height: theme.size.viewHeight[viewHeight],
    })
)
export const ViewHeightWrapper = ({viewHeight, ...props}: ViewHeightWrapperProps) => (
    <ViewHeightStyledWrapperComponent viewHeight={viewHeight} {...props} />
)

/* Wrappers relative to (DataGrid) rows */
interface DataGridWrapperProps extends BoxProps {
    rows: number
    maxRowsOnPage?: number
    addHeight?: number
}

/* Row-relative height for use with DataGrids (height value is in pixels):
    - Rows are theme.spacing(4.5) in height.
    - theme.spacing(18) is added to make vertical space for the DataGrid (default) action buttons.
    - Additional space is added via addHeight (for e.g. special action buttons on the DataGrid). */
const rowHeight = 4.5
const defaultActionButtonsHeight = 18

const heightByRows = (rows: number, maxRowsOnPage: number, addHeight: number, theme: Theme) =>
    rows < maxRowsOnPage
        ? theme.spacing(defaultActionButtonsHeight + addHeight + rows * rowHeight)
        : theme.spacing(defaultActionButtonsHeight + addHeight + maxRowsOnPage * rowHeight)

const DataGridStyledWrapperComponent = styled(({rows, maxRowsOnPage, addHeight, ...props}: DataGridWrapperProps) => <Box {...props} />)(
    ({theme, rows, maxRowsOnPage, addHeight}) => ({
        height: heightByRows(rows, maxRowsOnPage, addHeight, theme),
    })
)

export const DataGridWrapper = ({rows = 3, maxRowsOnPage = 20, addHeight = 0, ...props}: DataGridWrapperProps) => (
    <DataGridStyledWrapperComponent rows={rows > 3 ? rows : 3} maxRowsOnPage={maxRowsOnPage} addHeight={addHeight} {...props} />
)
