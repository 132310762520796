import React, {ReactNode, useState} from 'react'
import MuiTextField from '@mui/material/TextField'
import {FormControl} from '@mui/material'

type TextProps = {
    type?: 'text'
    value?: string | null
    onChange: (value: string, e) => any
    validateFn?: (value: string) => boolean
}
type NumberProps = {
    type?: 'number'
    min?: number
    value?: number | null
    onChange: (value: number, e) => any
    validateFn?: (value: number) => boolean
}

type Props = {
    id: string
    label?: ReactNode
    placeholder?: string
    type?: 'number' | 'text'
    help?: string
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    className?: string
    style?: React.CSSProperties
} & (TextProps | NumberProps)

/** @deprecated*/
function TextField({
    id,
    label,
    placeholder,
    type = 'text',
    value,
    help,
    onChange,
    required,
    validateFn = undefined,
    disabled = false,
    ...props
}: Props) {
    const [isInvalid, setInvalid] = useState<boolean>(false)
    //TODO: Why is validateFn(value: **never**):bool
    const onLocalChange = e => {
        let tmp = e.target.value
        if (type === 'number') {
            tmp = Number(tmp)
            if (validateFn) {
                // @ts-expect-error
                setInvalid(!validateFn(tmp))
            }
            // @ts-expect-error
            return onChange(tmp, e)
        } else {
            if (validateFn) {
                // @ts-expect-error
                setInvalid(!validateFn(tmp))
            }
            // @ts-expect-error
            return onChange(tmp, e)
        }
    }

    const localValue = typeof value === 'undefined' || value === null ? '' : value

    return (
        <MuiTextField
            sx={{mb: 2}}
            id={id}
            label={label}
            onChange={onLocalChange}
            variant='outlined'
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            value={localValue}
            fullWidth
            error={isInvalid}
            helperText={help}
            {...props}
        />
    )
}
export default TextField
