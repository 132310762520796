// @ts-expect-error no idea why typescript complains here...
import StackdriverErrorReporter from 'stackdriver-errors-js/stackdriver-errors.js'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'

export function isDevelopment(yes?: any, no?: any): any {
    if (typeof yes === 'undefined') yes = true
    if (typeof no === 'undefined') no = false

    const isDevelopment =
        process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    return isDevelopment ? yes : no
}

const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
    key: 'AIzaSyAB8c5iCaGfmYhxNVxhozKLDZCMlNfUmOk',
    projectId: 'skil-196709',
    service: 'eportal', // (optional)
    version: process.env.NODE_ENV, // (optional)
})
// @ts-expect-error
errorHandler.setUser(window.STATE_CONTEXT?.user?.['@id'] ?? null)

const reportError = (exception: string, cause?: string) => {
    try {
        if (isDevelopment()) {
            return
        }

        // @ts-expect-error
        window.errorHandler.report(exception, cause)
    } catch (e) {
        handleErrorWithToast(e)
    }
}
window.reportError = reportError
// @ts-expect-error Set it here so UserProvider can use it
window.errorHandler = errorHandler
export default reportError
