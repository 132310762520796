import * as React from 'react'
import TextField from '../../Components/Fields/TextField'
import {useState} from 'react'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import Dialog from '@mui/material/Dialog'
import {CountyAutocomplete} from '../../Components/CountyAutocomplete'
import {components} from '../../Generated/eportal'
import {toast} from 'react-toastify'

type AddOfficeDTO = components['schemas']['Office.AddOfficeDTO.jsonld']

type Props = {
    isOpen: boolean
    handleClose: () => any
    handleSubmit: (newOffice: AddOfficeDTO) => any
}

export default function CreateOfficeDialog({handleSubmit, handleClose, isOpen = false}: Props) {
    const [officeName, setOfficeName] = useState('')
    const [county, setCounty] = useState<string | null>('')
    const [district, setDistrict] = React.useState<string | undefined>(undefined)
    const [countiesRaw, setCountiesRaw] = React.useState<any>(undefined)

    const countyDistricts = county
        ? countiesRaw
              ?.find(c => c.id === county)
              ?.districts?.map(district => {
                  let iri = district['@id'] as string
                  return {label: district.name, id: iri}
              })
              .sort(function (a, b) {
                  if (a.label < b.label) {
                      return -1
                  }
                  if (a.label > b.label) {
                      return 1
                  }
                  return 0
              })
        : []

    const onSubmit = async () => {
        // if the county has districts, we need to make sure one is selected
        if (countyDistricts?.length > 0 && !district) {
            toast('Du må velge en bydel', {type: 'warning'})
            return
        }
        await handleSubmit({officeName, county, district, officeType: 'new'})
        onClose()
    }

    const onClose = () => {
        setOfficeName('')
        setCounty('')
        handleClose()
    }

    return (
        <Dialog maxWidth='sm' open={isOpen} onClose={onClose}>
            <DialogContent>
                <Typography>Legg til legekontor</Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            id={'name'}
                            label={'Navn'}
                            type={'text'}
                            placeholder={'Navn på legekontor'}
                            value={officeName}
                            required
                            onChange={name => setOfficeName(name)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CountyAutocomplete
                            value={county}
                            required={true}
                            onChange={(event, county, iri, district, countiesRaw) => {
                                setCounty(iri)
                                setDistrict(district?.id)
                                setCountiesRaw(countiesRaw)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={onClose}>
                    Avbryt
                </Button>
                <Button
                    disabled={!officeName || !county || (countyDistricts?.length > 0 && !district)}
                    variant='contained'
                    onClick={onSubmit}>
                    Opprett legekontor
                </Button>
            </DialogActions>
        </Dialog>
    )
}
