import * as React from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'
import {DEFAULT_ICON_PROPS} from './constants'

export default function ReportIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...DEFAULT_ICON_PROPS} {...props}>
            <g>
                <path d='m1016.5 177.06c-11.301-15.32-28.27-25.477-47.113-28.199l-498.3-73.094c-18.824-2.7383-37.969 2.1133-53.219 13.492-15.246 11.375-25.352 28.344-28.09 47.172l-14.953 101.77h-130.45c-19.883 0.019531-38.949 7.9297-53.008 21.988-14.062 14.062-21.973 33.125-21.992 53.012v736.8c0.019531 19.883 7.9297 38.949 21.992 53.008 14.059 14.062 33.125 21.973 53.008 21.992h497.07c19.887-0.019531 38.953-7.9297 53.012-21.988 14.062-14.062 21.969-33.125 21.988-53.012v-27.273l24.262 3.5625c18.812 2.6719 37.922-2.1836 53.172-13.516 15.254-11.328 25.422-28.219 28.293-47.004l107.72-735.59v0.003906c2.8047-18.785-2.0156-37.91-13.387-53.121zm-237.56 872.94c-0.011719 9.9414-3.9648 19.473-10.996 26.504-7.0312 7.0273-16.562 10.984-26.504 10.996h-497.07c-9.9414-0.011719-19.473-3.9648-26.504-10.996s-10.984-16.562-10.996-26.504v-736.8c0.011719-9.9453 3.9648-19.477 10.996-26.504 7.0312-7.0312 16.562-10.984 26.504-10.996h497.07c9.9414 0.011719 19.473 3.9648 26.504 10.996s10.984 16.562 10.996 26.504zm213.85-825.26-107.72 735.61c-1.3867 8.9648-6.2539 17.016-13.539 22.418-7.2891 5.3984-16.41 7.7031-25.391 6.4219l-29.699-4.3633v-671.62c-0.019532-19.887-7.9258-38.953-21.988-53.012-14.062-14.062-33.125-21.969-53.012-21.988h-328.71l14.152-96.332h-0.003907c1.3438-8.9648 6.168-17.039 13.43-22.469 7.2578-5.4297 16.363-7.7773 25.344-6.5352l498.29 73.094c8.9453 1.3711 16.988 6.2109 22.387 13.469 5.4023 7.2617 7.7266 16.355 6.4727 25.312z' />
                <path d='m299.86 587.97c4.9727 0.003906 9.7422-1.9727 13.258-5.4883 3.5195-3.5156 5.4922-8.2891 5.4922-13.262v-72.254c0-6.6953-3.5742-12.887-9.375-16.234-5.8008-3.3516-12.949-3.3516-18.75 0-5.8008 3.3477-9.375 9.5391-9.375 16.234v72.254c0 4.9727 1.9727 9.7461 5.4883 13.262 3.5195 3.5156 8.2891 5.4922 13.262 5.4883z' />
                <path d='m428.55 587.97c4.9727 0.003906 9.7422-1.9727 13.258-5.4883 3.5195-3.5156 5.4922-8.2891 5.4922-13.262v-154.59c0-6.6992-3.5742-12.891-9.375-16.238-5.8008-3.3516-12.949-3.3516-18.75 0-5.8008 3.3477-9.375 9.5391-9.375 16.238v154.59c-0.003906 4.9727 1.9727 9.7461 5.4883 13.262 3.5195 3.5156 8.2891 5.4922 13.262 5.4883z' />
                <path d='m557.25 587.97c4.9727 0.003906 9.7422-1.9727 13.258-5.4883s5.4922-8.2891 5.4922-13.262v-134.43c0-6.6992-3.5742-12.891-9.375-16.238-5.8008-3.3516-12.949-3.3516-18.75 0-5.8008 3.3477-9.375 9.5391-9.375 16.238v134.43c-0.003906 4.9727 1.9727 9.7461 5.4883 13.262s8.2891 5.4922 13.262 5.4883z' />
                <path d='m685.93 587.97c4.9766 0.003906 9.7461-1.9688 13.262-5.4883 3.5195-3.5156 5.4922-8.2852 5.4883-13.262v-228.86c0-6.6992-3.5703-12.887-9.375-16.238-5.8008-3.3477-12.945-3.3477-18.75 0-5.8008 3.3516-9.375 9.5391-9.375 16.238v228.86c0 4.9766 1.9727 9.7461 5.4883 13.262 3.5195 3.5195 8.2891 5.4922 13.262 5.4883z' />
                <path d='m727.82 652.11h-469.85c-6.6094 0.13281-12.66 3.7344-15.926 9.4805-3.2695 5.7461-3.2695 12.789 0 18.535 3.2656 5.75 9.3164 9.3516 15.93 9.4844h469.84c6.6094-0.13281 12.664-3.7344 15.93-9.4844 3.2656-5.7461 3.2656-12.789 0-18.535s-9.3203-9.3516-15.93-9.4805z' />
                <path d='m727.82 761.22h-469.85c-6.6094 0.13281-12.66 3.7383-15.926 9.4844-3.2695 5.7461-3.2695 12.789 0 18.535 3.2656 5.7461 9.3164 9.3516 15.93 9.4805h469.84c6.6094-0.12891 12.664-3.7344 15.93-9.4805 3.2656-5.7461 3.2656-12.789 0-18.535-3.2656-5.75-9.3203-9.3516-15.93-9.4844z' />
                <path d='m727.82 870.34h-469.85c-6.6094 0.13281-12.66 3.7383-15.926 9.4844-3.2695 5.7461-3.2695 12.789 0 18.535 3.2656 5.7461 9.3164 9.3516 15.93 9.4805h469.84c6.6094-0.12891 12.664-3.7344 15.93-9.4805 3.2656-5.7461 3.2656-12.789 0-18.535-3.2656-5.75-9.3203-9.3516-15.93-9.4844z' />
                <path d='m727.82 979.45h-469.85c-6.6094 0.13281-12.66 3.7344-15.926 9.4805-3.2695 5.75-3.2695 12.793 0 18.539 3.2656 5.7461 9.3164 9.3477 15.93 9.4805h469.84c6.6094-0.13281 12.664-3.7344 15.93-9.4805s3.2656-12.789 0-18.539c-3.2656-5.7461-9.3203-9.3477-15.93-9.4805z' />
            </g>
        </SvgIcon>
    )
}
