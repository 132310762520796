import {API_FETCH_ENTITY, API_ENTITIES_UPDATED, API_ENTITY_NOT_FOUND} from '../actions/entity'
import {DELETED_ENTITY} from '../actions/api'
import {COMMIT_LIST_ORDER} from '../actions/changes'

const reducer = (state = {}, action) => {
    // @ts-expect-error
    const {payload, type, meta: {url} = {}} = action
    switch (type) {
        case API_FETCH_ENTITY:
            return {
                ...state,
                [url ? url : payload['@id']]: {'@status': 'FOUND', ...payload},
            }
        case COMMIT_LIST_ORDER:
            action.payload.items.forEach((iri, index) => {
                state[iri] = {
                    ...state[iri],
                    order: index * 10,
                }
            })

            return {...state}
        case API_ENTITIES_UPDATED:
            Object.keys(payload).forEach(id => (payload[id]['@status'] = 'FOUND'))

            return {
                ...state,
                ...payload,
            }
        case API_ENTITY_NOT_FOUND:
            return {
                ...state,
                [payload['@id']]: {'@status': 'NOT FOUND', ...payload},
            }
        case DELETED_ENTITY:
            const newState = {...state}
            delete newState[payload.id]
            newState[payload.id] = {'@status': 'DELETED'}
            return newState
        default:
            return state
    }
}

export default reducer
