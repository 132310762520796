import {useParams} from 'react-router'
import useEntity from '../Hooks/useEntity'
import React, {useEffect} from 'react'
import useUser from '../Utilities/useUser'
import {saveChanges} from './Form/App'
import {SkilQueryResponseType} from '../Utilities/QueryClient'
import LoadingComponent from '../Components/LoadingComponent'
import {FormApp} from './FormApp'

export const PreCheckFormApp = ({isPreview}) => {
    const user = useUser()
    const {formId} = useParams()
    const [localStorageAnswers, setLocalStorageAnswers] = React.useState<any>({})
    const [localStorageChecked, setLocalStorageChecked] = React.useState<boolean>(false)
    const answerSet = useEntity<'findAnswerSet'>(
        formId ? `/api/answer_sets/find?form=${formId}&preview=${isPreview ? 'true' : 'false'}` : null
    )

    const localStorageKey = formId ? `answerset&form=${formId}&task=0&user=${user.id}&v=1` : ''

    useEffect(() => {
        if (localStorageKey) {
            const localStorageData = window.localStorage.getItem(localStorageKey)
            if (localStorageData) {
                const localStorageAnswers = JSON.parse(localStorageData)
                setLocalStorageAnswers(localStorageAnswers)
            }
            setLocalStorageChecked(true)
        }
    }, [localStorageKey])

    const StoreUnsavedAnswers = async (localStorageAnswers: any): Promise<any> => {
        type PatchAnswerSet = SkilQueryResponseType<'updateAnswerSet'>
        return await saveChanges<PatchAnswerSet>(answerSet['@id'], localStorageAnswers.answers, undefined).then(res => {
            if (Object.keys(res.answers).length === 0) {
                return true
            }
            const filteredAnswers = Object.keys(localStorageAnswers.answers).filter((key, value) => {
                return !Object.values(res.answers).some(iri => key === iri['indicator'])
            })
            // hack to fix the issue with the localStorage not being cleared when all answers are deleted
            // this happens when an indicator is deleted from the form while the user is working on it
            if (JSON.stringify(filteredAnswers) === '["0"]') {
                window.localStorage.setItem(localStorageKey, JSON.stringify({newLocation: undefined, answers: {}}))
            } else {
                window.localStorage.setItem(localStorageKey, JSON.stringify({newLocation: undefined, answers: {...filteredAnswers}}))
            }
            return true
        })
    }

    if (
        !isPreview &&
        formId &&
        answerSet['@id'] &&
        answerSet.id !== 0 &&
        localStorageChecked &&
        localStorageAnswers?.answers &&
        Object.keys(localStorageAnswers?.answers).length > 0
    ) {
        // if the localStorage is checked and there were unsaved answers then we need to save them
        // before we can render the form.
        StoreUnsavedAnswers(localStorageAnswers).then(() => {
            // In order for the Form to have a fresh version of the answerSet we need to reload the window
            window.location.reload()
        })
        return <LoadingComponent msg={'Lagrer ulagrede svar...'} />
    }

    if (
        !isPreview &&
        formId &&
        localStorageChecked &&
        (!localStorageAnswers?.answers || Object.keys(localStorageAnswers?.answers).length === 0 || answerSet.id === 0)
    ) {
        // if the localStorage is checked and there were not any unsaved answers then we can render the form
        return <FormApp formId={formId} isPreview={isPreview} />
    }

    if (isPreview) {
        return <FormApp formId={formId} isPreview={isPreview} />
    }

    return <LoadingComponent msg={'Laster inn spørsmål...'} />
}
