import React, {ReactNode, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import Button from '../Button/Button'
import axios from 'axios'
import useEntity from '../../Hooks/useEntity'
import {SkilQueryResponseType} from '../../Utilities/QueryClient'
import Box from '@mui/material/Box'

type FileType = SkilQueryResponseType<'getFileItem'>

type Props = {
    label?: ReactNode
    value?: string | FileType
    accept?: string
    onChange: Function
    feedback?: ReactNode
    className?: string
}

/** @deprecated*/
const FileField = ({label, value, accept, onChange, feedback, className}: Props) => {
    const file = useEntity<'getFileItem'>(typeof value === 'string' ? value : null)
    // @ts-expect-error
    let filename = file?.filename || value?.name || value

    const onDrop = useCallback(acceptedFiles => {
        onChange(acceptedFiles[0])
    }, [])

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, accept})

    const onRemove = e => {
        onChange(null)
        e.stopPropagation()
        return false
    }

    return (
        <Box mb={1} className={`${className}`}>
            <div style={{border: isDragActive ? 'dashed 2px #333333' : 'inherit'}} {...getRootProps()}>
                {label && <label>{label}</label>}
                <p style={{marginBottom: 1}}>Filnavn: </p>
                <p style={{height: 'auto', overflowY: 'auto'}}>
                    {filename ? (
                        <Button
                            style={{whiteSpace: 'normal', overflowWrap: 'anywhere', textAlign: 'left', paddingLeft: '0'}}
                            target={'_blank'}
                            href={file ? `${file['@id']}/download` : undefined}
                            variant={'link'}
                        >
                            {filename}
                        </Button>
                    ) : (
                        <em>Ikke valgt</em>
                    )}
                </p>

                <input {...getInputProps()} />
                <Button onClick={() => open}>Velg fil</Button>
                {file['@id'] && (
                    <>
                        <Button variant={'link'} onClick={onRemove} confirm={'Er du sikker?'}>
                            Fjern
                        </Button>
                    </>
                )}
                {feedback}
            </div>
        </Box>
    )
}

FileField.UploadFile = async (selectedFile, filetype, url = '/api/files', dryRun = false, seminar = '') => {
    if (!filetype) throw new Error('Argument `filetype` is required. See File object for available filetypes')
    const fd = new FormData()
    if (dryRun) fd.append('dryRun', 'true')

    fd.append('file', selectedFile)
    fd.append('type', filetype)
    fd.append('seminar', seminar)

    const response = await axios.post(url, fd, {headers: {'Content-Type': 'multipart/form-data'}})
    return response.data
}
export default FileField
