import * as React from 'react'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {GridColumns, GridSelectionModel} from '@mui/x-data-grid-premium'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import {useSkilQuery} from '../../Utilities/QueryClient'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import Button from '../../Components/Button/Button'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {StudyCreateDialog} from './StudyCreateDialog'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {SendMessageDialog} from '../components/SendMessageDialog'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {components} from '../../Generated/eportal'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'

type StudyType = components['schemas']['Praksisnett.Study-PraksisNettStudy.details_PraksisNettStudyAttachment.details_File.view']

const renderCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return (
        <Button variant='text' to={`/dashboard/praksisnett/studies/${params.row.id}`}>
            {params.value}
        </Button>
    )
}

let lastStatusValueGetter = params => params.row.status[params.row.status.latestStatus]
const columnVisibilityModel = {
    id: false,
    fundingSource: false,
    'researcher.title': false,
    'norce.projectNumber': false,
    'norce.clientNumber': false,
    'norce.invoiceNumber': false,
    'status.contractSignedOn': false,
    'status.wp2ConversationOn': false,
    'status.discussedWithLeadersOn': false,
    'status.interestFormReceivedOn': false,
    'status.finalApplicationReceivedOn': false,
    'status.approvedForPraksisNettUseOn': false,
    'status.invoiceSentOn': false,
    'status.startedOn': false,
    'status.endedOn': false,
    'status.cancelledOn': false,
    'status.cancelledReason': false,
    'payment.lastReceivedOn': false,
    'payment.total': false,
    'payment.paid': false,
    notes: false,
    createdAt: false,
}

export const LATEST_STATUS_OPTIONS = [
    {label: 'Kontrakt signert', value: 'contractSignedOn'},
    {label: 'WP2 Samtale', value: 'wp2ConversationOn'},
    {label: 'Diskutert med ledelse', value: 'discussedWithLeadersOn'},
    {label: 'Kontaktskjema mottatt', value: 'interestFormReceivedOn'},
    {label: 'Søknad mottatt', value: 'finalApplicationReceivedOn'},
    {label: 'Godkjent for PraksisNett', value: 'approvedForPraksisNettUseOn'},
    {label: 'Faktura sendt', value: 'invoiceSentOn'},
    {label: 'Startet', value: 'startedOn'},
    {label: 'Avsluttet', value: 'endedOn'},
    {label: 'Avlyst', value: 'cancelledOn'},
]

export const Studies = () => {
    const studiesResponse = useSkilQuery<'getPraksisnettStudyCollection'>('/api/praksisnett/studies', {pagination: false})
    const [openAddStudy, setOpenAddStudy] = React.useState(false)
    const [showEmailDialog, setShowEmailDialog] = React.useState(false)
    const [showTextDialog, setShowTextDialog] = React.useState(false)
    const [selectedStudies, setSelectedStudies] = React.useState<GridSelectionModel>([])
    const {data: selectedDoctorsData, isLoading: isLoadingSelectedDoctors} = useSkilQuery<'getPraksisNettInvitationCollection'>(
        '/api/praksisnett/invitations',
        {'study[]': selectedStudies},
        {enabled: selectedStudies.length > 0}
    )

    const studyActions: SpecialActionProps[] = [
        {
            label: 'Opprett ny studie',
            action: () => setOpenAddStudy(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: 'Send SMS',
            action: () => setShowTextDialog(true),
            disabled: selectedStudies.length === 0,
            icon: {
                position: 'start',
                icon: <PhoneIphoneOutlinedIcon />,
            },
        },
        {
            label: 'Send e-post',
            action: () => setShowEmailDialog(true),
            disabled: selectedStudies.length === 0,
            icon: {
                position: 'start',
                icon: <EmailOutlinedIcon />,
            },
        },
    ]

    const studies = studiesResponse.data?.['hydra:member'] ?? []

    const onChangeSelection = (event, value) => {
        setSelectedStudies(value.map(v => v.id))
    }
    const currentSelection: StudyType[] = studies.filter(i => selectedStudies.includes(Number(i.id)))

    /*************/
    /*  columns  */
    /*************/

    const columns: GridColumns = [
        columnDef({field: 'id', headerName: 'System-ID', type: 'number', renderCell}),
        columnDef({field: 'name', headerName: 'Navn', flex: 3, renderCell}),
        columnDef({field: 'region', headerName: 'Ansvarlig region'}),
        columnDef({field: 'responsibleInstitution', headerName: 'Ansvarlig institusjon'}),
        columnDef({field: 'fundingSource', headerName: 'Finansieringskilde'}),

        columnDef({field: 'researcher.name', headerName: 'Prosjektleder'}),
        columnDef({field: 'researcher.title', headerName: 'Prosjektleder stillingstittel'}),
        columnDef({field: 'researcher.email', headerName: 'Prosjektleder epost'}),
        columnDef({field: 'researcher.mobile', headerName: 'Prosjektleder telefon', type: 'number'}),

        columnDef({field: 'norce.projectNumber', headerName: 'NORCE prosjektnr.', flex: 2}),
        columnDef({field: 'norce.clientNumber', headerName: 'NORCE kundenr.', flex: 2}),
        columnDef({field: 'norce.invoiceNumber', headerName: 'NORCE fakturanr.', flex: 2}),

        selectColumnDef({field: 'status.latestStatus', headerName: 'Siste status', valueOptions: LATEST_STATUS_OPTIONS}),
        dateColumnDef({
            field: 'status.lastStatusAt',
            headerName: 'Siste status (tidspunkt)',
            type: 'dateTime',
            valueGetter: lastStatusValueGetter,
        }),
        dateColumnDef({field: 'status.contractSignedOn', headerName: 'Kontrakt signert', type: 'dateTime'}),
        dateColumnDef({field: 'status.wp2ConversationOn', headerName: 'WP2 møte', type: 'dateTime'}),
        dateColumnDef({field: 'status.discussedWithLeadersOn', headerName: 'Ledergruppe diskusjon', type: 'dateTime'}),
        dateColumnDef({field: 'status.interestFormReceivedOn', headerName: 'Kontaktskjema mottatt', type: 'dateTime'}),
        dateColumnDef({field: 'status.finalApplicationReceivedOn', headerName: 'Søknad mottatt', type: 'dateTime'}),
        dateColumnDef({field: 'status.approvedForPraksisNettUseOn', headerName: 'Søknad godkjent', type: 'dateTime'}),
        dateColumnDef({field: 'status.invoiceSentOn', headerName: 'Faktura sent', type: 'dateTime'}),
        dateColumnDef({field: 'status.startedOn', headerName: 'Startet', type: 'dateTime'}),
        dateColumnDef({field: 'status.endedOn', headerName: 'Sluttet', type: 'dateTime'}),
        dateColumnDef({field: 'status.cancelledOn', headerName: 'Avlyst', type: 'dateTime'}),
        columnDef({field: 'status.cancelledReason', headerName: 'Avlyst (årsak)'}),

        dateColumnDef({field: 'payment.lastReceivedOn', headerName: 'Sist innbetaling'}),
        columnDef({field: 'payment.total', headerName: 'Betaling totalt'}),
        columnDef({field: 'payment.paid', headerName: 'Betalt'}),

        columnDef({field: 'notes', headerName: 'Notater'}),
        dateColumnDef({field: 'createdAt', headerName: 'Opprettet', type: 'dateTime'}),
    ]

    const formattedColumns: GridColumns = columns.map(column => ({headerAlign: 'left', ...column}))
    // @ts-expect-error
    const selectedDoctorIris: string[] = selectedStudies.length > 0 ? (selectedDoctorsData?.['hydra:member'] ?? []).map(i => i.doctor) : []

    return (
        <>
            <DataGridWrapper rows={studies.length} addHeight={8.5}>
                <DataGrid
                    id={'studies'}
                    checkboxSelection
                    onSelectionModelChange={newSelectedDoctors => {
                        setSelectedStudies(newSelectedDoctors)
                    }}
                    selectionModel={selectedStudies}
                    loading={studiesResponse.isLoading && studies.length === 0}
                    rows={studies}
                    columns={formattedColumns}
                    initModel={{columnVisibilityModel}}
                    toolbarButtons={<SpecialActions actions={studyActions} />}
                />
            </DataGridWrapper>
            <StudyCreateDialog refetch={studiesResponse.refetch} setOpen={setOpenAddStudy} isOpen={openAddStudy} />
            <SendMessageDialog
                isOpen={showEmailDialog}
                onClose={() => setShowEmailDialog(false)}
                doctorIris={isLoadingSelectedDoctors ? [] : selectedDoctorIris}
                isEmail
            >
                <Autocomplete
                    multiple
                    id='recipients'
                    options={studies}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelection}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label='Mottakere'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isLoadingSelectedDoctors ? (
                                    <InputAdornment position='end'>
                                        <CircularProgress size={16} aria-label='Laster leger' />
                                    </InputAdornment>
                                ) : (
                                    params.InputProps.endAdornment
                                ),
                            }}
                        />
                    )}
                />
            </SendMessageDialog>
            <SendMessageDialog
                isOpen={showTextDialog}
                onClose={() => setShowTextDialog(false)}
                doctorIris={isLoadingSelectedDoctors ? [] : selectedDoctorIris}
                isText
            >
                <Autocomplete
                    multiple
                    id='recipients'
                    options={studies}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelection}
                    getOptionLabel={option => String(option.name)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label='Mottakere'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isLoadingSelectedDoctors ? (
                                    <InputAdornment position='end'>
                                        <CircularProgress size={16} aria-label='Laster leger' />
                                    </InputAdornment>
                                ) : (
                                    params.InputProps.endAdornment
                                ),
                            }}
                        />
                    )}
                />
            </SendMessageDialog>
        </>
    )
}
