import TextField from '@mui/material/TextField'
import React from 'react'
import Grid from '@mui/material/Grid'
import DateTimeField from '../Components/Fields/DateTimeField'
import Moment from 'moment'
import TextareaField from '../Components/Fields/TextareaField'
import Divider from '@mui/material/Divider'
import {Box} from '@mui/material'
import SelectField from '../Components/Fields/SelectField'

const PartnershipSignupComponent = ({partnershipSignup, setPartnershipSignup, seminarTemplates}) => {
    function updateField(field, value) {
        setPartnershipSignup(prev => {
            // Clone the previous state to avoid direct mutation
            const updatedSignup = {...prev}
            // Update the object in the cloned state
            updatedSignup[field] = value
            return updatedSignup
        })
    }

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <SelectField
                    label={'Seminar tema'}
                    id={'template'}
                    value={partnershipSignup.seminarTheme}
                    entities={seminarTemplates}
                    onChange={seminar => {
                        updateField('seminarTheme', seminar['@id'])
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    id='plannedSeminarAt'
                    label='Planlagt seminar'
                    value={partnershipSignup.plannedSeminarAt}
                    required={false}
                    onChange={(value, e) => {
                        let date = Moment(value)
                        date.format()
                        updateField('plannedSeminarAt', date)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    id='educationMeetingAt'
                    label='Opplæringsmøte'
                    value={partnershipSignup.educationMeetingAt}
                    required={false}
                    onChange={(value, e) => {
                        let date = Moment(value)
                        date.format()
                        updateField('educationMeetingAt', date)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    id='informationMeetingAt'
                    label='Informasjonsmøte'
                    value={partnershipSignup.informationMeetingAt}
                    required={false}
                    onChange={(value, e) => {
                        let date = Moment(value)
                        date.format()
                        updateField('informationMeetingAt', date)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    id='debriefMeetingAt'
                    label='Oppsummeringsmøte'
                    value={partnershipSignup.debriefMeetingAt}
                    required={false}
                    onChange={(value, e) => {
                        let date = Moment(value)
                        date.format()
                        updateField('debriefMeetingAt', date)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='ContactPersonRole'
                    label='Kontaktperson stilling'
                    placeholder='Kommuneoverlege'
                    value={partnershipSignup?.contactPersonRole}
                    fullWidth
                    required={true}
                    onChange={event => {
                        updateField('contactPersonRole', event.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='ContactPersonName'
                    label='Kontaktperson navn'
                    placeholder='Ola Normann'
                    value={partnershipSignup?.contactPersonName}
                    fullWidth
                    required={true}
                    onChange={event => {
                        updateField('contactPersonName', event.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='ContactPersonEmail'
                    label='Kontaktperson e-post'
                    placeholder='ola@normann.no'
                    value={partnershipSignup?.contactPersonEmail}
                    fullWidth
                    required={true}
                    onChange={event => {
                        updateField('contactPersonEmail', event.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='ContactPersonPhone'
                    label='Kontaktperson telefon'
                    placeholder='927701057'
                    type={'number'}
                    value={partnershipSignup?.contactPersonPhone}
                    fullWidth
                    required={true}
                    onChange={event => {
                        updateField('contactPersonPhone', parseInt(event.target.value))
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextareaField
                    id='signupOtherInfo'
                    label='Andre opplysninger'
                    value={partnershipSignup.signupOtherInfo ?? ''}
                    required={false}
                    onChange={value => {
                        updateField('signupOtherInfo', value)
                    }}
                />
            </Grid>
            <hr />
            <Grid item xs={12}>
                <TextareaField
                    id='description'
                    label='SKILs interne notater om påmeldingen'
                    value={partnershipSignup.description ?? ''}
                    required={false}
                    onChange={value => {
                        updateField('description', value)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='jiraLink'
                    label='Jira lenke til påmeldingsopplysninger'
                    placeholder='Lenke til Jira oppgave'
                    value={partnershipSignup?.signupJiraLink}
                    fullWidth
                    required={false}
                    onChange={event => {
                        updateField('signupJiraLink', event.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {partnershipSignup?.signupJiraLink && (
                    <div className={'col-sm-6 col-lg-6'}>
                        <a href={partnershipSignup?.signupJiraLink} target='_blank'>
                            Jira oppgave
                        </a>
                    </div>
                )}
            </Grid>
            <Grid item xs={6} sx={{mb: 2}}>
                <TextField
                    id='invoiceOrgNumber'
                    label='Organisasjonsnummer til EHF utsending:'
                    type={'number'}
                    placeholder='123456789'
                    value={partnershipSignup.orgNumber}
                    required={true}
                    fullWidth
                    onChange={event => updateField('orgNumber', parseInt(event.target.value))}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider orientation='horizontal' flexItem />
                <Box sx={{mt: 4, mb: 4}}>Faktura detaljer</Box>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='invoiceEmailReceiver'
                    label='E-posttil fakturamottak'
                    placeholder='epost@fakturamottak.no'
                    value={partnershipSignup.emailForInvoiceReceipt}
                    required
                    fullWidth
                    onChange={event => updateField('emailForInvoiceReceipt', event.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='invoiceResourceNumber'
                    label='Ressursnummer / PO nr::'
                    placeholder='Ressursnummer / PO nr:'
                    value={partnershipSignup.resourceNumber}
                    required
                    fullWidth
                    onChange={event => updateField('resourceNumber', event.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='invoiceReference'
                    label='Fakturareferanse:'
                    placeholder='Referanse'
                    value={partnershipSignup.invoiceReference}
                    fullWidth
                    onChange={event => updateField('invoiceReference', event.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='invoiceOtherInfo'
                    label='Andre opplysninger:'
                    multiline={true}
                    placeholder='Annet viktig...'
                    value={partnershipSignup.otherInfo}
                    fullWidth
                    onChange={event => updateField('otherInfo', event.target.value)}
                />
                <hr />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='invoiceNotes'
                    label='SKILs interne notater om fakturadetaljer'
                    multiline={true}
                    placeholder='SKILs interne notater...'
                    value={partnershipSignup.internalNotes}
                    fullWidth
                    onChange={event => updateField('internalNotes', event.target.value)}
                />
            </Grid>
        </Grid>
    )
}
export default PartnershipSignupComponent
