import {useDispatch} from 'react-redux'
import {showToast} from '../State/actions/ui'

const useSendText = consultation => {
    const dispatch = useDispatch()

    return (content, customIri = undefined) => {
        return fetch((customIri ? customIri : consultation['@id']) + '/send_sms', {
            method: 'POST',
            body: JSON.stringify({content}),
            headers: {'Content-Type': 'application/json'},
        }).then(
            res => {
                if (res.status < 400) dispatch(showToast('Meldingen er sendt!'))
                else dispatch(showToast(`Meldingen ble ikke sendt. Feilmelding: ${res.status} ${res.statusText}`, 'error'))
            },
            err => dispatch(showToast(`Meldingen ble ikke sendt. Feilmelding: ${err.message}`, 'error'))
        )
    }
}
export default useSendText
