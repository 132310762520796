import React, {ReactNode} from 'react'
import MuiTextField from '@mui/material/TextField'

type Props = {
    id: string
    label?: ReactNode
    placeholder?: string
    value?: string
    required?: boolean
    rows?: number
    onChange: (value: string, e) => any
    disabled?: boolean
}
/** @deprecated*/
const TextareaField = ({id, label, placeholder, value, onChange, disabled = false, ...props}: Props) => {
    const onLocalChange = e => onChange(e.target.value, e)

    return (
        <MuiTextField
            sx={{mb: 2}}
            id={id}
            value={value}
            label={label}
            placeholder={placeholder}
            onChange={onLocalChange}
            variant='outlined'
            multiline={true}
            disabled={disabled}
            fullWidth
            {...props}
        />
    )
}
export default TextareaField
