import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import PurePage from '../../../Components/PurePage'
import {BackdropComponent} from '../../../Components/BackdropComponent'
import {Checkbox, FormControlLabel, FormGroup, Grid, Skeleton, useMediaQuery} from '@mui/material'
import Box from '@mui/system/Box'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {Button} from '../../../Components/Button/MuiButton'
import useUser from '../../../Utilities/useUser'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import styled from 'styled-components'
import {SkeletonTitleContainer} from './GroupDetailsPage'
import InvoiceDetails from './InvoiceDetails'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import FormHelperText from '@mui/material/FormHelperText'
import {toast} from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import LoadingComponent from '../../../Components/LoadingComponent'

const TosFormControlLabel = styled(FormControlLabel)`
    span:nth-of-type(2) {
        margin-bottom: 0;
    }
`
const StyledGrid = styled(Grid)`
    .MuiCard-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`
interface StyledJumperProps {
    animate: boolean
}

const StyledJumper = styled.div<StyledJumperProps>`
    .hide-span {
        display: ${props => (props.animate ? 'flex' : 'none')};
    }

    .rotating-text-wrapper {
        height: inherit;
        width: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.7rem;
    }
    .rotating-text-wrapper span {
        font-size: 2.5em;
        margin: 0;
        padding: 0.3em;
        color: #fff;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        animation-duration: 6s;
        animation-iteration-count: ${props => (props.animate ? '1' : '0')};
        opacity: 0;
    }
    .rotating-text-wrapper span:nth-child(1) {
        background-color: lightseagreen;
        animation-name: rotating-text-1;
    }
    @keyframes rotating-text-1 {
        0% {
            transform: translateY(0%) translateX(-85%);
            opacity: 1;
        }
        33% {
            transform: translateY(-100%) translateX(-85%);
            opacity: 0;
        }
        34% {
            transform: translateY(-200%) translateX(-85%);
            opacity: 0;
        }
    }
`

const validationSchema = z.object({
    tosAccepted: z
        .boolean({
            required_error: 'Du må godkjenne vilkår for å fortsette',
        })
        .refine(tosAccepted => tosAccepted, {
            message: 'Du må godkjenne vilkår for å fortsette',
        }),

    invoice: z.optional(
        z.object({
            private: z.optional(
                z.object({
                    name: z.string().nonempty({message: 'Navn er påkrevd'}),
                    address: z.string().nonempty({message: 'Adresse er påkrevd'}),
                    postalcode: z
                        .string({
                            required_error: 'Postnummer er påkrevd',
                            invalid_type_error: 'Postnummeret er ugyldig',
                        })
                        .refine(
                            code => {
                                return /^\d{4}$/.test(code)
                            },
                            {
                                message: 'Postnummer må være 4 siffer',
                            }
                        ),
                    postalarea: z.string().nonempty({message: 'Poststed er påkrevd'}),
                })
            ),
            company: z.optional(
                z
                    .object({
                        orgnr: z.string().refine(
                            orgnr => {
                                return /^\d{9}$/.test(orgnr)
                            },
                            {message: 'Organisasjonsnummeret må være 9 siffer'}
                        ),
                        orgForm: z.string().nonempty({message: 'Organisasjonsform er påkrevd'}),
                        name: z.string().nonempty({message: 'Organisasjonsnavn er påkrevd'}),
                        email: z
                            .string({
                                required_error: 'E-post er påkrevd',
                                invalid_type_error: 'E-post er ugyldig',
                            })
                            .email({message: 'Ugyldig e-post'}),
                        reference: z.string().optional(),
                        resource: z.string(),
                    })
                    .refine(
                        ({orgForm, resource}) => {
                            if (orgForm === 'KOMM') {
                                return resource !== '' && resource !== undefined
                            } else {
                                return true
                            }
                        },
                        {
                            path: ['resource'],
                            message: 'Påkrevd når kommune faktureres',
                        }
                    )
            ),
            sole_proprietorship: z.optional(
                z.object({
                    orgnr: z.string().refine(orgnr => /^\d{9}$/.test(orgnr), {message: 'Organisasjonsnummeret må være 9 siffer'}),
                    orgForm: z.string().nonempty({message: 'Organisasjonsform er påkrevd'}),
                    name: z.string().nonempty({message: 'Organisasjonsnavn er påkrevd'}),
                    address: z.string().nonempty({message: 'Addresse er påkrevd'}),
                    postalcode: z
                        .string({
                            required_error: 'Postnummer er påkrevd',
                            invalid_type_error: 'Postnummeret er ugyldig',
                        })
                        .refine(
                            code => {
                                return /^\d{4}$/.test(code)
                            },
                            {
                                message: 'Postnummer må være 4 siffer',
                            }
                        ),
                    postalarea: z.string().nonempty({message: 'Poststed er påkrevd'}),
                })
            ),
        })
    ),
})

type ValidationSchema = z.infer<typeof validationSchema>

export default function NefleSignupConfirm() {
    const {
        register,
        control,
        handleSubmit,
        formState: {isValid, errors, isDirty},
        unregister,
        trigger,
        setValue,
        clearErrors,
        setError,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
        mode: 'all',
        defaultValues: {
            tosAccepted: false,
            invoice: {
                private: {
                    name: '',
                    address: '',
                    postalcode: '',
                    postalarea: '',
                },
                company: {
                    orgnr: '',
                    orgForm: '',
                    email: '',
                    reference: '',
                    resource: '',
                },
                sole_proprietorship: {
                    orgnr: '',
                    orgForm: '',
                    name: '',
                    address: '',
                    postalcode: '',
                    postalarea: '',
                },
            },
        },
    })

    const formRef = React.useRef<HTMLFormElement>(null)
    const user = useUser()
    const navigate = useNavigate()
    const [hasRefreshedUser, setHasRefreshedUser] = useState<boolean>(false)
    const [isSearchingOrgNr, setIsSearchingOrgNr] = useState<boolean>(false)
    const [tosAccepted, setTosAccepted] = React.useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [readyToShow, setReadyToShow] = React.useState<boolean>(false)
    const [discountCode, setDiscountCode] = useState('')
    const [validatingDiscountCode, setValidatingDiscountCode] = useState<boolean>(false)
    const [hasValidDiscountCode, setHasValidDiscountCode] = useState<boolean>(false)
    const [timer, setTimer] = useState(1)
    const [startTimer, setStartTimer] = useState<boolean>(false)
    const [validatingDiscountCodeFinished, setValidatingDiscountCodeFinished] = useState<boolean>(false)
    const signupOfficeNefle = useSkilMutation<'signupOfficeNefle'>('POST', `/api/offices/${null}/nefle/signup`)
    const bigScreen = useMediaQuery('(min-width:600px)')
    const officesClaim = user?.authenticated ? user.claims.offices[`/api/offices/${user.claims.office}`] : null

    // if the user is authenticated and
    // - the user is not associated with an office
    // - the user is not approved by an office admin
    // - the office has a nefle subscription that has been generated and the user is approved by an office admin
    // - the office has a nefle subscription that has been cancelled
    // then the user needs to be redirected to the authorize route
    const needsToAuthorize = user?.authenticated
        ? !user.claims.office ||
          // @ts-expect-error
          !officesClaim?.approved_by?.id ||
          // @ts-expect-error
          (officesClaim.nefle?.generated && officesClaim.approved_by?.id) ||
          officesClaim.nefle?.cancelled !== null
        : false

    const {
        data: discountCodeValidation,
        refetch: refetchDiscountCodeValidation,
        isFetching: isFetchingDiscountCodeValidation,
        isRefetching: isRefetchingDiscountCodeValidation,
    } = useSkilQuery<any>(
        `/api/nefle_discount_codes/validate/${discountCode}`,
        {},
        {
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: data => {
                if (validatingDiscountCode) {
                    setValidatingDiscountCode(false)
                    if (data.valid) {
                        toast('Rabattkoden er gyldig', {type: 'success'})
                        setHasValidDiscountCode(true)
                        setValidatingDiscountCodeFinished(true)
                    } else {
                        toast('Rabattkoden er ugyldig', {type: 'error'})
                        setHasValidDiscountCode(false)
                        setValidatingDiscountCodeFinished(true)
                    }
                }
            },
        }
    )

    const startupCost = 7000 - discountCodeValidation?.discount ?? 0

    useEffect(() => {
        if (!user.authenticated && !hasRefreshedUser) {
            user.refresh()
            setHasRefreshedUser(true)
        }
    }, [])

    useEffect(() => {
        // if user is not authenticated then navigate to login page with redirect to this page
        if (!user.authenticated) {
            // we need to check if we have tried to refresh the user, if we have then we know that the user is not authenticated
            // and we should redirect to login page
            if (hasRefreshedUser) {
                navigate(`/signup/nefle/${encodeURIComponent(window.location.pathname)}`)
            }
        }

        if (user?.authenticated) {
            if (needsToAuthorize) {
                window.location.replace(`/signup/nefle/authorize`)
            } else {
                setReadyToShow(true)
            }
        }
    }, [user])

    useEffect(() => {
        if (hasValidDiscountCode) {
            setStartTimer(true)
        }
    }, [hasValidDiscountCode])

    // Timer countdown logic that controls when css
    // animation stops after successful validation of discount code
    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null

        if (startTimer && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1)
            }, 1000)
        } else if (timer === 0) {
            setStartTimer(false) // Stop the timer when it reaches 0
            // Correctly manage the new interval
            const resetInterval = setInterval(() => {
                setTimer(1) // Assuming you want to reset timer to 1 every 6 seconds after it reaches 0
            }, 6000)
            // Store the resetInterval so it can be cleared on cleanup
            interval = resetInterval
        }

        // Cleanup interval on component unmount or on effect re-run
        return () => {
            if (interval !== null) {
                clearInterval(interval)
            }
        }
    }, [startTimer, timer])

    useEffect(() => {
        if (isFetchingDiscountCodeValidation && validatingDiscountCodeFinished) {
            setValidatingDiscountCodeFinished(false)
        }
    }, [isFetchingDiscountCodeValidation])

    const onHandleValidateDiscountCode = async () => {
        if (!validatingDiscountCode) {
            setValidatingDiscountCode(true)
            await refetchDiscountCodeValidation()
        }
    }

    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        if (isSubmitting) {
            return
        }
        setIsSubmitting(true)
        try {
            await signupOfficeNefle
                .mutateAsync({
                    '@overridePath': `/api/offices/${user?.claims?.office}/nefle/signup`,
                    nefleDiscountCode: discountCodeValidation?.['@id'] ?? null,
                    tosAccepted: tosAccepted,
                    // @ts-expect-error
                    invoice: data.invoice,
                })
                .then(nefle => {
                    if (nefle['@id']) {
                        const officeId = nefle?.office?.replace('/api/offices/', '')
                        if (officeId) {
                            toast(`Kjøp av Nefle er vellykket`, {type: 'success'})
                            window.location.replace(
                                `${window.location.protocol}//${window.location.hostname}/dashboard/offices/${officeId}/nefle/setup/`
                            )
                        } else {
                            toast('Det skjedde en feil ved bestilling. Prøv igjen.', {type: 'error'})
                            setIsSubmitting(false)
                        }
                    } else {
                        toast('Det skjedde en feil ved bestilling. Prøv igjen.', {type: 'error'})
                        setIsSubmitting(false)
                    }
                })
        } catch (e) {
            handleErrorWithToast(e + ' Det skjedde en feil ved bestilling. Prøv igjen.')
            setIsSubmitting(false)
        }
    }
    const isSubmittable = isDirty && isValid && tosAccepted

    return (
        <PurePage variant={'md'}>
            {readyToShow && (
                <form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{m: 'auto', width: bigScreen ? '50%' : '100%', mt: 5}} id={'nefle.signup.confirm'}>
                        <h2 className='text-left'>
                            <strong>Nefle : </strong>Nettside for Legekontor
                        </h2>
                    </Box>
                    <InvoiceDetails
                        register={register}
                        errors={errors}
                        control={control}
                        unregister={unregister}
                        trigger={trigger}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        setError={setError}
                        // tosAccepted here is not the same as the useState tosAccepted
                        tosAccepted={true}
                        invoice={null}
                        isSearchingOrgNr={isSearchingOrgNr}
                        setIsSearchingOrgNr={setIsSearchingOrgNr}
                        user={user}
                        isNefleSignup={true}
                    />
                    <Box sx={{mt: 4}}>
                        <FormGroup
                            sx={{
                                mt: 2,
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: bigScreen ? 'unset' : 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'baseline',
                                    width: bigScreen ? '50%' : '100%',
                                }}
                            >
                                <TextField
                                    id={`discountCode`}
                                    name={`discountCode`}
                                    type={'text'}
                                    value={discountCode}
                                    disabled={hasValidDiscountCode}
                                    sx={{
                                        minWidth: '75%',
                                        marginBottom: 0,
                                        marginTop: 0,
                                        '& > div': {
                                            marginBottom: 0,
                                            paddingRight: 0,
                                            outline: hasValidDiscountCode
                                                ? '3px solid #3e8a82'
                                                : validatingDiscountCodeFinished && !hasValidDiscountCode
                                                ? '3px solid #A92513FF'
                                                : 'unset',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: validatingDiscountCodeFinished && !hasValidDiscountCode ? '#f7f7f7' : 'unset',
                                            },
                                        },
                                    }}
                                    placeholder={'Rabattkode'}
                                    onChange={e => setDiscountCode(e.target.value)}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            onHandleValidateDiscountCode()
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                {!hasValidDiscountCode && !validatingDiscountCodeFinished && (
                                                    <div style={{minWidth: '2em'}} />
                                                )}
                                                {hasValidDiscountCode && <CheckIcon style={{fontSize: '2em', color: '#3e8a82'}} />}
                                                {validatingDiscountCodeFinished && !hasValidDiscountCode && (
                                                    <CloseIcon style={{fontSize: '2em', color: '#A92513FF'}} />
                                                )}
                                                {timer === 0 && (
                                                    <StyledJumper animate={timer === 0}>
                                                        <div className='rotating-text-wrapper'>
                                                            <span style={{padding: 0}} className={'hide-span'}>
                                                                Godkjent
                                                            </span>
                                                        </div>
                                                    </StyledJumper>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                                <Button
                                    type={'button'}
                                    size={'medium'}
                                    sx={{ml: 1, backgroundColor: '#3e8a82', width: 'inherit'}}
                                    disabled={hasValidDiscountCode || validatingDiscountCode}
                                    onClick={() => onHandleValidateDiscountCode()}
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            maxWidth: '4em',
                                            height: 'inherit',
                                        }}
                                    >
                                        {hasValidDiscountCode ? 'Validert' : validatingDiscountCode ? '' : 'Valider'}
                                        {validatingDiscountCode && <CircularProgress color='inherit' size={'1.5em'} />}
                                    </span>
                                </Button>
                            </Box>
                        </FormGroup>
                    </Box>
                    <Box sx={{mt: 4}}>
                        <FormGroup
                            sx={{display: 'flex', alignItems: 'center'}}
                            title={'Pris'}
                            aria-label={'Pris'}
                            id={'nefle.signup.confirm'}
                        >
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: bigScreen ? '50%' : '100%'}}>
                                <p className='text-left'>Etableringskostnad</p>
                                <Box>
                                    <p className='text-right'>
                                        {hasValidDiscountCode &&
                                        discountCodeValidation?.valid === true &&
                                        discountCodeValidation?.discount >= 7000
                                            ? 'GRATIS'
                                            : hasValidDiscountCode &&
                                              discountCodeValidation?.valid === true &&
                                              discountCodeValidation?.discount > 0 &&
                                              discountCodeValidation?.discount < 7000
                                            ? `kr ${startupCost} (inkl. mva.)`
                                            : 'kr 7000 (inkl. mva.)'}
                                    </p>
                                    {hasValidDiscountCode &&
                                        discountCodeValidation?.valid === true &&
                                        discountCodeValidation?.discount > 0 && (
                                            <p
                                                className='text-right'
                                                style={{
                                                    textDecoration: 'line-through',
                                                }}
                                            >
                                                kr 7000 (inkl. mva.)
                                            </p>
                                        )}
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: bigScreen ? '50%' : '100%'}}>
                                <p className='text-left'>Årlig driftskostnad</p>
                                <p className='text-left'>kr 5500 (inkl. mva.)</p>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: bigScreen ? '50%' : '100%'}}>
                                <p className='text-left'>Totalsum</p>
                                <p className='text-left'>
                                    {hasValidDiscountCode &&
                                    discountCodeValidation?.valid === true &&
                                    discountCodeValidation?.discount > 0 ? (
                                        <strong>kr {5500 + startupCost} (inkl.mva.)</strong>
                                    ) : (
                                        <strong>kr 12500 (inkl.mva.)</strong>
                                    )}
                                </p>
                            </Box>
                        </FormGroup>
                    </Box>
                    <Box
                        sx={{
                            display: bigScreen ? 'flex' : 'block',
                            alignItems: 'center',
                            justifyContent: 'end',
                            width: bigScreen ? '50%' : '100%',
                            m: 'auto',
                        }}
                    >
                        <Box sx={{mt: 2, mb: 2}}>
                            <Controller
                                name={'tosAccepted'}
                                control={control}
                                render={({field: {onChange}, fieldState: {error}}) => (
                                    <TosFormControlLabel
                                        sx={{
                                            mb: '0em',
                                            ml: '0em',
                                            mr: '0em',
                                            border: errors?.tosAccepted ? 'red solid 1px' : 'none',
                                            borderRadius: '0.25em',
                                            padding: '0.5em 1em 0.5em 0',
                                        }}
                                        control={
                                            <Checkbox
                                                required
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                {...register('tosAccepted')}
                                                onChange={(e, checked) => {
                                                    onChange(checked)
                                                    setTosAccepted(checked)
                                                    if (!checked) {
                                                        setError('tosAccepted', {
                                                            type: 'boolean',
                                                            message: 'Vilkårene må godtas for å bestille Nefle nettside.',
                                                        })
                                                    } else {
                                                        clearErrors('tosAccepted')
                                                    }
                                                }}
                                            />
                                        }
                                        defaultChecked={false}
                                        label={
                                            <>
                                                <span>
                                                    Jeg har lest og godkjenner{' '}
                                                    <a href={'/nefle-kontrakt.pdf'} target='_blank'>
                                                        vilkår og betingelser
                                                    </a>{' '}
                                                </span>
                                            </>
                                        }
                                    />
                                )}
                            />
                            {errors?.tosAccepted && (
                                <FormHelperText style={{color: '#ab3e36'}}>{errors.tosAccepted.message ?? ''}</FormHelperText>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <Button
                            type={'submit'}
                            size={'large'}
                            fullWidth
                            disabled={!isSubmittable || !tosAccepted || isSubmitting}
                            style={{width: bigScreen ? '50%' : '100%'}}
                        >
                            Bekreft kjøp&nbsp;&nbsp;
                            {isSubmitting && <CircularProgress color='inherit' size={'1.5em'} />}
                        </Button>
                    </Box>
                    {isSubmitting && <BackdropComponent isOpen={isSubmitting} status={'Behandler...'} />}
                </form>
            )}
            {!readyToShow && (
                <>
                    <SkeletonTitleContainer>
                        <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '50%', mt: 1}} />
                    </SkeletonTitleContainer>
                    <Grid width={'100%'} sx={{mt: 1}}>
                        <StyledGrid
                            display={'flex'}
                            width={'100%'}
                            xs={12}
                            sm={12}
                            md={12}
                            item
                            sx={{justifyContent: {xs: 'center', sm: 'initial'}}}
                        >
                            <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%', mr: 2}} />
                            <Skeleton variant='rectangular' animation='wave' sx={{height: '20em', width: '50%'}} />
                        </StyledGrid>
                    </Grid>
                    <Skeleton variant='rectangular' animation='wave' sx={{height: '2em', width: '100%', mt: 1}} />
                </>
            )}
        </PurePage>
    )
}
