import * as React from 'react'
import {Card, LinearProgress, SxProps, Theme, Typography} from '@mui/material'

interface LinearProgressWithPercentLabelProps {
    percentage: number
    cardSx?: SxProps<Theme>
}

const LinearProgressWithPercentLabel = ({percentage, cardSx = []}: LinearProgressWithPercentLabelProps) => {
    const sx: SxProps<Theme> = [{display: 'flex', alignItems: 'center', px: 2, py: 1}, ...(Array.isArray(cardSx) ? cardSx : [cardSx])]

    return (
        <Card variant='outlined' sx={sx}>
            <LinearProgress sx={{flexGrow: 1, mr: 2}} variant='determinate' value={percentage} />
            <Typography flexGrow={0} variant='body2'>
                {percentage}%
            </Typography>
        </Card>
    )
}

export default LinearProgressWithPercentLabel
