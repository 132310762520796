import React, {ReactNode} from 'react'
import {OptionType as SelectOptionType} from '../Select'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export type OptionType = SelectOptionType

type Props = {
    id: string
    label?: ReactNode
    help?: ReactNode
    size?: string
    value?: any
    onChange: (value: any, option: {disabled?: boolean; label: ReactNode; value: any}) => any
    options: OptionType[]
    required?: boolean
    placeholder?: string
    disabled?: boolean
    multiple?: 'multiple'
    style?: React.CSSProperties
}

/** @deprecated*/
export default function SimpleSelectField({
    id,
    label,
    help,
    value,
    onChange,
    options,
    placeholder = 'Velg...',
    disabled = false,
    required = true,
}: Props) {
    const selected = (options.length > 0 && options.find(o => o.value === value)) || null

    const onLocalChange = (e, val) => {
        onChange(val ? val.value : null, val)
    }

    return (
        <Autocomplete
            sx={{mb: 2}}
            id={id}
            fullWidth
            value={selected}
            disabled={disabled}
            options={options}
            onChange={onLocalChange}
            placeholder={placeholder}
            disableClearable={required}
            renderInput={params => <TextField required={required} {...params} label={label} helperText={help} />}
        />
    )
}
