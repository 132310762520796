import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid, {InitModel} from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridCellEditCommitParams, GridColDef, GridActionsColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import courseColumnDef from '../../Components/DataGrid/columnDefs/courseColumnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import userColumnDef from '../../Components/DataGrid/columnDefs/userColumnDef'
import {Button} from '../../Components/Button/MuiButton'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {COURSE_TYPES} from './Courses'
import {useState} from 'react'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import {FilterList as FilterListIcon} from '@mui/icons-material'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'

const COURSE_ROLE = [
    {value: 0, label: 'Deltaker'},
    {value: 1, label: 'Veileder'},
    {value: 2, label: 'Administrator'},
]

const INVOICE_RECEIVER_OPTIONS = [
    {value: 1, label: 'Privatperson'},
    {value: 2, label: 'Enkeltpersonforetak'},
    {value: 3, label: 'Bedriften/arbeidsplassen betaler'},
]

const columnVisibilityModel = {
    id: false,
    invitedBy: false,
    'course.type': false,
    'invoiceDetails.invoiceReceiver': false,
    'invoiceDetails.privateOrgNr': false,
    'invoiceDetails.companyOrgNr': false,
    'invoiceDetails.mailingAddress': false,
    'invoiceDetails.zipCode': false,
    'invoiceDetails.postal': false,
    'invoiceDetails.emailForInvoiceReceipt': false,
    'invoiceDetails.invoiceReference': false,
    'invoiceDetails.resourceNumber': false,
}

const InvoiceFilterModel: InitModel = {
    columnVisibilityModel: {
        id: false,
        invitedBy: false,
        createdAt: false,
        county: false,
        region: false,
        'user.officeName': false,
        'certificate.createdAt': false,
        statusDescription: false,
    },
    sort: [{field: 'acceptedAt', sort: 'asc'}],
    filter: [
        {
            columnField: 'invoiceDetails.invoiceDetailsConfirmedAt',
            operatorValue: 'isNotEmpty',
            id: 1,
        },
        {
            columnField: 'price.price',
            id: 2,
            operatorValue: '!=',
            value: '0',
        },
        {
            columnField: 'price.price',
            operatorValue: 'isNotEmpty',
            id: 3,
        },
        {
            columnField: 'invoiceSendtAt',
            operatorValue: 'isEmpty',
            id: 4,
        },
    ],
}

const renderOfficeCell = params => {
    if (!params.value) return ''

    const officeId = params.row.user.office.substring('/api/offices/'.length)

    return (
        <Button variant='text' href={`/dashboard/offices/${officeId}/settings`}>
            {params.value}
        </Button>
    )
}

const Participants = () => {
    const {data, isFetching, refetch} = useSkilQuery<'getUserCourseStatistics'>('/api/user_courses/statistics', {
        pagination: false,
    })
    const participants = data?.['hydra:member'] ?? []
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel})

    const updateParticipationMutation = useSkilMutation<'patchUserCourseItem'>('PATCH', `/api/user_courses/{id}`, [
        '/api/user_courses/statistics',
        '/api/user_courses',
    ])

    const onEditInvoiceSent = async (params: GridCellEditCommitParams) => {
        try {
            await updateParticipationMutation.mutateAsync({
                '@overridePath': `/api/user_courses/${params.id}`,
                [params.field]: params.value,
            })
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const columns: (GridColDef | GridActionsColDef)[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        courseColumnDef({field: 'course.shortTitle'}),
        selectColumnDef({field: 'course.type', headerName: 'Type', valueOptions: COURSE_TYPES}),
        userColumnDef({field: 'user', headerName: 'Bruker'}),
        columnDef({field: 'user.email', headerName: 'Epost'}),
        columnDef({field: 'county', headerName: 'Kommune'}),
        columnDef({field: 'region', headerName: 'Fylke'}),
        columnDef({field: 'user.officeName', headerName: 'Legekontor', renderCell: renderOfficeCell}),
        columnDef({field: 'price', type: 'number', headerName: 'Pris'}),
        userColumnDef({field: 'invitedBy', headerName: 'Invitert av'}),
        dateColumnDef({field: 'createdAt', headerName: 'Invitert'}),
        dateColumnDef({field: 'acceptedAt', headerName: 'Påmeldt'}),
        dateColumnDef({field: 'completedAt', headerName: 'Fullført'}),
        selectColumnDef({field: 'invoiceDetails.invoiceReceiver', headerName: 'Faktura mottaker', valueOptions: INVOICE_RECEIVER_OPTIONS}),
        columnDef({field: 'invoiceDetails.privateOrgNr', headerName: 'Faktura Privat org.nr.'}),
        columnDef({field: 'invoiceDetails.companyOrgNr', headerName: 'Faktura Firma org.nr'}),
        columnDef({field: 'invoiceDetails.mailingAddress', headerName: 'Faktura Post mottak'}),
        columnDef({field: 'invoiceDetails.zipCode', headerName: 'Faktura Postnr.'}),
        columnDef({field: 'invoiceDetails.postal', headerName: 'Faktura Sted'}),
        columnDef({field: 'invoiceDetails.emailForInvoiceReceipt', headerName: 'Faktura epost-mottak'}),
        columnDef({field: 'invoiceDetails.invoiceReference', headerName: 'Faktura referanse'}),
        columnDef({field: 'invoiceDetails.resourceNumber', headerName: 'Faktura ressursnr.'}),
        dateColumnDef({field: 'invoiceDetails.invoiceDetailsConfirmedAt', headerName: 'Fakturadetaljer bekreftet', type: 'dateTime'}),
        dateColumnDef({field: 'invoiceSendtAt', headerName: 'Faktura sendt'}, {onCellEditCommit: onEditInvoiceSent}),
        dateColumnDef({field: 'certificate.createdAt', headerName: 'Kursbevis'}),
        columnDef({
            field: 'progress',
            headerName: 'Fremdrift',
            type: 'number',
            renderCell: ({value}) => Math.round(value * 100) + '%',
            hide: true,
        }),
        columnDef({field: 'statusDescription', headerName: 'Status'}),
        selectColumnDef({field: 'role', headerName: 'Rolle', valueOptions: COURSE_ROLE}),
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Handlinger',
            getActions: params => [
                <Button variant={'text'} key={params.id} href={`/dashboard/course/${params.row.course.shortTitle}/users/${params.id}`}>
                    Se mer
                </Button>,
            ],
        },
    ]

    const actions: SpecialActionProps[] = [
        {
            label: 'Fakturadetaljer',
            variant: 'outlined',
            action: () => setInitModel(InvoiceFilterModel),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
    ]

    return (
        <Box>
            <DataGridWrapper rows={participants.length}>
                <DataGrid
                    id={'participants'}
                    loading={isFetching}
                    initModel={initModel}
                    rows={participants}
                    columns={columns}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={actions} />}
                />
            </DataGridWrapper>
        </Box>
    )
}

export default Participants
