import * as React from 'react'
import {Box} from '../../Components/Panel'
import MovieIcon from '@mui/icons-material/Movie'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CheckIcon from '@mui/icons-material/Check'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import WashOutlinedIcon from '@mui/icons-material/WashOutlined'
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import SickOutlinedIcon from '@mui/icons-material/SickOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import MedicationLiquidSharpIcon from '@mui/icons-material/MedicationLiquidSharp'
import GroupsIcon from '@mui/icons-material/Groups'
import ElderlyIcon from '@mui/icons-material/Elderly'
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined'
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined'

export const CourseDevelopment = () => {
    return (
        <Box>
            <h4 style={{marginLeft: 10}}>Kursutvikling</h4>
            <a className='btn btn-app' href='/dashboard/SKIL/forms'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <CheckIcon />
                    <p>Skjema</p>
                </div>
            </a>
            <a className='btn btn-app' href='https://live.easygenerator.com/' target={'_blank'}>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <GroupWorkIcon />
                    <p>EasyGenerator</p>
                </div>
            </a>
            <a className='btn btn-app' href='https://www.shutterstock.com/nb/' target={'_blank'}>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <MovieIcon />
                    <p>Shutterstock</p>
                </div>
            </a>
            <a className='btn btn-app'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <ThumbUpIcon />
                    <p>Evalueringer</p>
                </div>
            </a>

            <h4 style={{marginLeft: 10}}>Kvalitetspakker</h4>

            <a className='btn btn-app' href='/dashboard/course/LMG/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <MedicationLiquidSharpIcon />
                    <p>LMG</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/RAK2021/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <BugReportOutlinedIcon />
                    <p>Antibiotika</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/KLOK/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <CheckCircleOutlineOutlinedIcon />
                    <p>Kloke valg</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/SFL/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <SickOutlinedIcon />
                    <p>Sykemelding</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/VLM/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <AssignmentOutlinedIcon />
                    <p>VLM</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/SHL/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <FavoriteBorderOutlinedIcon />
                    <p>Sexhelse 1</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/SHL2/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <FavoriteBorderOutlinedIcon />
                    <p>Sexhelse 2</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/DIA/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <WashOutlinedIcon />
                    <p>Diabetes</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/EKONS/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <CoPresentOutlinedIcon />
                    <p>E-kons</p>
                </div>
            </a>

            <h4 style={{marginLeft: 10}}>Kvalitetsseminar</h4>

            <a className='btn btn-app' href='/dashboard/course/KS.MAL/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <GroupsIcon />
                    <p>Basis</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/SEL/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <ElderlyIcon />
                    <p>Skr. eldre</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/GLEDE/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <SentimentSatisfiedOutlinedIcon />
                    <p>Glede</p>
                </div>
            </a>
            <a className='btn btn-app' href='/dashboard/course/KS.SMV/settings'>
                <div style={{display: 'block', justifyContent: 'center'}}>
                    <VaccinesOutlinedIcon />
                    <p>Smittevern</p>
                </div>
            </a>
        </Box>
    )
}
