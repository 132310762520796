import * as React from 'react'
import Button from '../../Components/Button/Button'
import Collapse from '../../Components/Collapse'
import FindUserModal, {FindUserModalDTO} from '../../Components/FindUserModal'
import LoadingComponent from '../../Components/LoadingComponent'
import ErrorComponent from '../../Components/ErrorComponent'
import {useSkilQuery} from '../../Utilities/QueryClient'
import {SetState} from '../../Utilities/TypeHelpers'
import {toast} from 'react-toastify'
import {DistrictsExport} from '../../Components/DistrictsExport'
import OfficeComponent from './OfficeComponent'
import {components} from '../../Generated/eportal'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'

const StyledPanel = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5em;
    margin-bottom: 2em;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`

export interface Participation {
    courseInvitation: number
    courseAcknowledged: number
    seminarInvited: number
    seminarAcknowledged: number
}

export interface Employee {
    id: number
    '@id': string
    name: string
    email: string
    isRegistered: boolean
    hasValidEmail: boolean
    hasHelfoConnection: boolean
    isTemp: boolean
    isMale: boolean
    helfoRole: string
    skilRole: string
    participation: Participation
}

export type UserType = {id: number; checked: boolean}
type PartnershipType = components['schemas']['Partnership.jsonld']
export type ParticipantsType = {users: string[]; extraUsers: FindUserModalDTO[]; init: boolean}
//export type HelfoUserType = {id: number; checked: boolean; email: string}
//export type HelfoUsersListType = {[key: number]: HelfoUserType}
//export type PartsType = {users: UserType[]; helfoUsers: HelfoUsersListType; extraUsers: FindUserModalDTO[]; init: boolean}
type ParticipantsProps = {
    partnership: PartnershipType
    participantId?: number | string
    participants: ParticipantsType
    setParticipants: SetState<ParticipantsType>
    bigScreen: boolean
}

const validateEmail = email => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)

export default function Participants({partnership, participantId, participants, setParticipants, bigScreen}: ParticipantsProps) {
    const [expanded, setExpanded] = React.useState<number[]>([])
    const [showAddMember, setShowAddMember] = React.useState(false)
    const allDistricts = DistrictsExport(!!participantId)
    const userRoles = [
        {'@id': '/api/user_roles/1', name: 'Fastlege', '@type': 'UserRole', id: 1},
        {'@id': '/api/user_roles/10', name: 'Fastlegevikar', '@type': 'UserRole', id: 10},
    ]
    const {data: helfoOffices} = useSkilQuery<'listPartnershipDoctors'>(
        partnership?.id !== undefined ? `/api/partnerships/${partnership.id}/list_doctors` : '',
        {enabled: !!partnership?.id}
    )
    const {data, isLoading, isError} = useSkilQuery<'getPartnershipStatsItem'>(
        `/api/partnerships/statistics/${participantId}`,
        {},
        {
            enabled: !!participantId,
            onSuccess: data => {
                if (!participants.init) {
                    let users: string[] = []
                    data.offices.forEach(office => {
                        // @ts-ignore
                        const officeUsers: string[] = office.users.map(dto => dto.user['@id'])
                        if (officeUsers.length > 0) {
                            users.concat(officeUsers)
                        }
                    })

                    setParticipants({users, extraUsers: [], init: true})
                }
                return data
            },
        }
    )

    const offices = data?.offices ?? []

    //@ts-expect-error
    const helfoOfficesWithDistricts = helfoOffices ? helfoOffices.filter(ho => ho.district) : []
    //@ts-expect-error
    const helfoOfficesWithoutDistricts = helfoOffices ? helfoOffices.filter(o => !o.district) : []
    const county = helfoOffices?.[0]?.county
    const districts = allDistricts.filter(d => '/api/counties/' + d.county === county)
    const newExtraUsers = participants.extraUsers.filter(u => u.type === 'new')
    const helfoExtraUsers = participants.extraUsers.filter(u => u.type === 'helfo')
    const skilExtraUsers = participants.extraUsers.filter(u => u.type === 'skil')

    const toggleUser = (user, checked) => {
        const userIri = user['@id']
        setParticipants(({users, ...participants}) => {
            if (checked) {
                // only include the user if it's not already in the list
                if (!users.includes(userIri)) {
                    return {users: [...users, userIri], ...participants}
                }
                return {users: [...users], ...participants}
            } else {
                return {users: users.filter(oldId => oldId !== userIri), ...participants}
            }
        })
    }

    const toggleHelfoUser = (extraUser, office, checked) => {
        setParticipants(participants => {
            // Check if the user already exists in the extraUsers list
            const existingUserIndex = participants.extraUsers.findIndex(u => u.id === extraUser.id)

            if (existingUserIndex === -1 && checked) {
                // Add the user if they do not exist
                participants.extraUsers.push({
                    id: extraUser.id,
                    email: '',
                    role: '',
                    name: extraUser.name,
                    type: 'helfo',
                    office: {officeType: 'external', externalOffice: office.helfoOffice, officeName: office.name},
                })
            }

            if (!checked) {
                // If unchecked and user exists, remove the user
                participants.extraUsers.splice(existingUserIndex, 1)
            }

            return {...participants}
        })
    }

    const toggleOffice = (office, checked) => {
        office.employees.forEach(d => {
            if (d['@id']) {
                if (d['@id'].includes('/api/users/')) {
                    toggleUser(d, checked)
                }
                if (d['@id'].includes('/api/helfo_doctors/')) {
                    toggleHelfoUser(d, office, checked)
                }
            }
        })
    }

    const onAddMember = addUser => {
        setParticipants(participants => {
            if (
                (!participants.extraUsers.some(u => u.id === addUser.id) && (addUser.type === 'skil' || addUser.type === 'helfo')) ||
                (addUser.type === 'new' && !participants.extraUsers.some(u => u.email === addUser.email))
            ) {
                participants.extraUsers.push(addUser)
            }
            //else if (addUser.type === 'skil') {
            //    const userIri = `/api/users/${addUser.id}`
            //    setParticipants(({users, ...participants}) => {
            //        // only include the user if it's not already in the list
            //        if (!users.includes(userIri)) {
            //            return {users: [...users, userIri], ...participants}
            //        }
            //    })
            //}
            else {
                toast('Deltaker er allerede lagt til', {type: 'warning'})
            }
            return {...participants}
        })
        setShowAddMember(false)
    }

    const removeExtraUser = index => {
        setParticipants(participants => {
            const newList = [...participants.extraUsers]
            newList.splice(index, 1)
            participants.extraUsers = newList
            return {...participants}
        })
    }

    if (isLoading) return <LoadingComponent msg={'Laster inn legekontor...'} absolute={false} />

    if (isError) return <ErrorComponent>Kunne ikke laste inn legene!</ErrorComponent>

    return (
        <>
            <Collapse expanded={(Array.isArray(helfoOffices) && (helfoOffices?.length ?? 0)) > 0}>
                {districts &&
                    districts.length > 0 &&
                    districts.map(district => {
                        const districtHasOffices = helfoOfficesWithDistricts.some(o => o.district === district?.['@id'])
                        if (!districtHasOffices) {
                            return null
                        }

                        return (
                            <StyledPanel key={district.id}>
                                <h3 style={{marginBottom: '1em', marginTop: '3em', textAlign: 'center'}}>
                                    <strong>{district.label}</strong>
                                </h3>
                                {!districtHasOffices && (
                                    <div>
                                        <p>Ingen leger registrert</p>
                                        <br />
                                    </div>
                                )}
                                {Array.isArray(helfoOfficesWithDistricts) &&
                                    helfoOfficesWithDistricts.map(o => {
                                        let selectedUsers = participants.users
                                            ? o.employees.filter(d => participants.users?.includes(d['@id'])).length
                                            : 0
                                        let selectedExtraUsers = o.employees.filter(d =>
                                            participants.extraUsers.some(u => u.id === d.id)
                                        ).length
                                        const selected = selectedUsers + selectedExtraUsers
                                        if (o.district !== '/api/districts/' + district.id) {
                                            return null
                                        }
                                        return (
                                            <OfficeComponent
                                                key={o.id}
                                                participants={participants}
                                                setParticipants={setParticipants}
                                                toggleUser={toggleUser}
                                                toggleOffice={toggleOffice}
                                                o={o}
                                                expanded={expanded}
                                                setExpanded={setExpanded}
                                                selected={selected}
                                                userRoles={userRoles}
                                                bigScreen={bigScreen}
                                            />
                                        )
                                    })}
                            </StyledPanel>
                        )
                    })}
                {districts && helfoOfficesWithoutDistricts && districts.length > 0 && helfoOfficesWithoutDistricts.length > 0 && (
                    <StyledPanel>
                        <h3 style={{marginBottom: '1em', marginTop: '3em', textAlign: 'center'}}>
                            <strong>Ingen bydel registrert</strong>
                        </h3>
                        {helfoOfficesWithoutDistricts &&
                            (helfoOfficesWithoutDistricts ?? []).map(o => {
                                let selectedUsers = participants.users
                                    ? o.employees.filter(d => participants.users?.includes(d['@id'])).length
                                    : 0
                                let selectedExtraUsers = o.employees.filter(d => participants.extraUsers.some(u => u.id === d.id)).length
                                const selected = selectedUsers + selectedExtraUsers

                                return (
                                    <OfficeComponent
                                        key={o.id}
                                        participants={participants}
                                        setParticipants={setParticipants}
                                        toggleUser={toggleUser}
                                        toggleOffice={toggleOffice}
                                        o={o}
                                        expanded={expanded}
                                        setExpanded={setExpanded}
                                        selected={selected}
                                        userRoles={userRoles}
                                        bigScreen={bigScreen}
                                    />
                                )
                            })}
                    </StyledPanel>
                )}
                {districts &&
                    districts.length === 0 &&
                    Array.isArray(helfoOffices) &&
                    (helfoOffices ?? []).map(o => {
                        let selectedUsers = participants.users ? o.employees.filter(d => participants.users?.includes(d['@id'])).length : 0
                        let selectedExtraUsers = o.employees.filter(d => participants.extraUsers.some(u => u.id === d.id)).length
                        const selected = selectedUsers + selectedExtraUsers

                        return (
                            <OfficeComponent
                                key={o.id}
                                participants={participants}
                                setParticipants={setParticipants}
                                toggleUser={toggleUser}
                                toggleOffice={toggleOffice}
                                o={o}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                selected={selected}
                                userRoles={userRoles}
                                bigScreen={bigScreen}
                            />
                        )
                    })}
                {newExtraUsers.length > 0 && (
                    <div style={{marginTop: '4em'}}>
                        <div>
                            <label>Nye brukere</label>
                        </div>
                        <ul className={'list-group'}>
                            <Grid container xs={12} spacing={1} mt={0} mb={0} sx={{justifyContent: 'end', alignItems: 'center'}}>
                                {newExtraUsers.map((u, index) => (
                                    <Grid container xs={12} key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}>
                                        <li
                                            className={'list-group-item'}
                                            key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                backgroundColor: validateEmail(u.email) ? 'initial' : '#ff4500b5',
                                                color: validateEmail(u.email) ? 'initial' : '#fff',
                                                fontWeight: validateEmail(u.email) ? 'initial' : 'bold',
                                            }}>
                                            <Grid
                                                item
                                                xs={3}
                                                mt={0}
                                                mb={0}
                                                mr={1}
                                                sx={{justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
                                                <span>{u.name}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                mt={0}
                                                mb={0}
                                                mr={1}
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    overflowWrap: 'anywhere',
                                                }}>
                                                <span>{validateEmail(u.email) ? u.email : <small>MANGLER E-POST</small>}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                mt={0}
                                                mb={0}
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}>
                                                <span>{u.office?.officeName ? u.office?.officeName : 'Ukjent arbeidssted'}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={1}
                                                mt={0}
                                                mb={0}
                                                ml={1}
                                                sx={{justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
                                                <Button
                                                    variant={'link'}
                                                    inline
                                                    onClick={() => removeExtraUser(index)}
                                                    style={{
                                                        color: u.email || u.type === 'skil' ? 'initial' : '#fff',
                                                        fontWeight: u.email || u.type === 'skil' ? 'initial' : 'bold',
                                                    }}>
                                                    Fjern
                                                </Button>
                                            </Grid>
                                        </li>
                                    </Grid>
                                ))}
                            </Grid>
                        </ul>
                    </div>
                )}
                {helfoExtraUsers.length > 0 && (
                    <div style={{marginTop: '1em'}}>
                        <div>
                            <label>Nye brukere fra Helfo register</label>
                        </div>
                        <ul className={'list-group'}>
                            <Grid container xs={12} spacing={1} mt={0} mb={0} sx={{justifyContent: 'end', alignItems: 'center'}}>
                                {helfoExtraUsers.map((u, index) => (
                                    <Grid container xs={12} key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}>
                                        <li
                                            className={'list-group-item'}
                                            key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                backgroundColor: validateEmail(u.email) ? 'initial' : '#ff4500b5',
                                                color: validateEmail(u.email) ? 'initial' : '#fff',
                                                fontWeight: validateEmail(u.email) ? 'initial' : 'bold',
                                            }}>
                                            <Grid
                                                item
                                                xs={3}
                                                mt={0}
                                                mb={0}
                                                mr={1}
                                                sx={{justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
                                                <span>{u.name}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                mt={0}
                                                mb={0}
                                                mr={1}
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    overflowWrap: 'anywhere',
                                                }}>
                                                <span>{validateEmail(u.email) ? u.email : <small>MANGLER E-POST</small>}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                mt={0}
                                                mb={0}
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}>
                                                <span>{u.office?.officeName ? u.office?.officeName : 'Ukjent arbeidssted'}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={1}
                                                mt={0}
                                                mb={0}
                                                ml={1}
                                                sx={{justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
                                                <Button
                                                    variant={'link'}
                                                    inline
                                                    onClick={() => removeExtraUser(index)}
                                                    style={{
                                                        color: u.email || u.type === 'skil' ? 'initial' : '#fff',
                                                        fontWeight: u.email || u.type === 'skil' ? 'initial' : 'bold',
                                                    }}>
                                                    Fjern
                                                </Button>
                                            </Grid>
                                        </li>
                                    </Grid>
                                ))}
                            </Grid>
                        </ul>
                    </div>
                )}
                {skilExtraUsers.length > 0 && (
                    <div style={{marginTop: '1em'}}>
                        <div>
                            <label>Andre SKIL brukere</label>
                        </div>
                        <ul className={'list-group'}>
                            <Grid container xs={12} spacing={1} mt={0} mb={0} sx={{justifyContent: 'end', alignItems: 'center'}}>
                                {skilExtraUsers.map((u, index) => (
                                    <Grid container xs={12} key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}>
                                        <li
                                            className={'list-group-item'}
                                            key={u.type + (u.type === 'new' ? u.name + u.email : u.id)}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                            }}>
                                            <Grid
                                                item
                                                xs={3}
                                                mt={0}
                                                mb={0}
                                                mr={1}
                                                sx={{justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
                                                <span>{u.name}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                mt={0}
                                                mb={0}
                                                mr={1}
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    overflowWrap: 'anywhere',
                                                }}>
                                                <span>***skjult***</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                mt={0}
                                                mb={0}
                                                sx={{
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}>
                                                <span>{u.office?.officeName ? u.office?.officeName : 'Ukjent arbeidssted'}</span>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={1}
                                                mt={0}
                                                mb={0}
                                                ml={1}
                                                sx={{justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>
                                                <Button
                                                    variant={'link'}
                                                    inline
                                                    onClick={() => removeExtraUser(index)}
                                                    style={{
                                                        color: u.email || u.type === 'skil' ? 'initial' : '#fff',
                                                        fontWeight: u.email || u.type === 'skil' ? 'initial' : 'bold',
                                                    }}>
                                                    Fjern
                                                </Button>
                                            </Grid>
                                        </li>
                                    </Grid>
                                ))}
                            </Grid>
                        </ul>
                    </div>
                )}
                <div>
                    <Button variant={'link'} onClick={() => setShowAddMember(true)}>
                        Legg til deltaker
                    </Button>
                    {showAddMember && <FindUserModal onClose={() => setShowAddMember(false)} onSave={onAddMember} />}
                </div>
            </Collapse>
        </>
    )
}
