import * as React from 'react'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/material/Typography'
import {useTheme} from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

export const EditableCell = (displayValue, noValueMessage = '', align: 'center' | 'left' | 'right') => {
    const theme = useTheme()

    return (
        <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems={'center'}
            sx={{
                '&:hover': {
                    '& svg.MuiSvgIcon-root': {
                        color: theme.palette.primary.main,
                    },
                },
            }}
        >
            <Box display={'flex'} flexGrow={1} alignItems={'center'} justifyContent={align}>
                {displayValue ? displayValue : <Typography variant='quiet'>{noValueMessage}</Typography>}
            </Box>
            <EditIcon fontSize='small' sx={{display: 'block', marginLeft: theme.spacing(1), color: theme.palette.grey[400]}} />
        </Box>
    )
}
