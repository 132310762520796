import React, {useEffect, useState} from 'react'
import Button from '../../Components/Button/Button'
import ProgressBar from '../../Components/ProgressBar'
import {AnimatePresence} from 'framer-motion'
import styled from 'styled-components'
import useGetSize from '../../Hooks/useGetSize'
import IndicatorGroup from './IndicatorGroup'
import AnimatedItem from '../../Components/AnimatedItem'
import Indicator from './Indicator'
import {AppAnswerSetType, IndicatorType, QuestionsType, UnknownIndicatorType} from '../Types'

const StyledContainer = styled.div`
    position: relative;
    overflow: hidden;
`
const StyledHr = styled.hr`
    clear: both;
`

type Props = {
    questions: QuestionsType
    onSubmit: () => void
    onChange: (indicator: IndicatorType, answer: any, save: boolean) => Promise<void>
    answerSet: AppAnswerSetType
    fresh: boolean
}
const GroupSlides = ({questions, onSubmit, onChange, answerSet, fresh = true}: Props) => {
    const [[current, direction], setCurrent] = useState([0, 1])
    const groups = Object.values(questions)
    groups.sort((a, b) => a.group.order - b.group.order)
    const {setRef, height} = useGetSize()
    const isFirst = current === 0
    const isLast = current === groups.length - 1
    const {group, indicators} = groups[current]

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'})
    }, [current])

    const onNext = () => {
        let next = current + 1
        if (next >= groups.length) next = groups.length - 1

        setCurrent([next, 1])
    }

    const onPrevious = () => {
        let next = current - 1
        if (next < 0) next = 0

        setCurrent([next, -1])
    }

    const onChangeAnswer =
        (question: IndicatorType) =>
        (value: any, save = true) => {
            onChange(question, value, save)
                .then(() => {})
                .catch(() => {})
        }

    return (
        <>
            <StyledContainer style={{height: height + 25}}>
                <AnimatePresence initial={true} custom={direction}>
                    <AnimatedItem id={group['@id']} direction={direction} style={{position: 'absolute', top: '0', width: '100%'}}>
                        <IndicatorGroup group={group} ref={setRef} answerSet={answerSet}>
                            {indicators.map((indicator, index) => {
                                const answer = answerSet.answers.find(a => a.indicator === indicator['@id'])
                                let defaultAnswer = {
                                    valid: false,
                                    value: null,
                                    fresh,
                                    ...answer,
                                }

                                return (
                                    <AnimatedItem direction={direction} key={indicator['@id']} id={indicator['@id']}>
                                        <Indicator
                                            // @ts-expect-error // Don't know how to fix this
                                            answer={defaultAnswer}
                                            answerSet={answerSet}
                                            disabled={answerSet.approved}
                                            onChange={(value, save) => onChangeAnswer(indicator)(value, save)}
                                            indicator={indicator as UnknownIndicatorType}
                                        />
                                        <StyledHr hidden={index === indicators.length - 1} />
                                    </AnimatedItem>
                                )
                            })}
                        </IndicatorGroup>
                    </AnimatedItem>
                </AnimatePresence>
            </StyledContainer>

            <br />
            <br />

            <div className='row'>
                <div className='col-xs-12'>
                    {!isLast && (
                        <Button variant={'primary'} className={'pull-right'} onClick={onNext} hidden={isLast}>
                            Neste
                        </Button>
                    )}
                    {isLast && !answerSet['approved'] && (
                        <Button variant={'primary'} className={'pull-right'} onClick={onSubmit} disabled={answerSet['approved']}>
                            Ferdig
                        </Button>
                    )}
                    {!isFirst && (
                        <Button onClick={onPrevious} hidden={isFirst}>
                            Tilbake
                        </Button>
                    )}
                    <br />
                    <br />
                </div>
                {groups.length > 1 && (
                    <div className='col-xs-12'>
                        <ProgressBar current={current + 1} max={groups.length} />
                    </div>
                )}
            </div>
        </>
    )
}
export default GroupSlides
