export const GLOBAL_TRIGGER_OPTIONS = [
    {value: 'App\\Messages\\Events\\ePortal\\UserRegistered', label: 'Bruker registrert i SKIL ePortal'},
    {value: 'App\\Messages\\Events\\ePortal\\UserOrderedNefle', label: 'Bruker bestilt Nefle'},
    {value: 'App\\Messages\\Events\\ePortal\\UserAddedToNefle', label: 'Bruker lagt til Nefle'},
    {value: 'App\\Messages\\Events\\ePortal\\CounselorAddedToGroup', label: 'Veileder tildelt gruppe'},
    {value: 'App\\Messages\\Events\\ePortal\\UserAcknowledgeReminder', label: 'Første påminnelse kursinvitasjon (14 dager igjen)'},
    {value: 'App\\Messages\\Events\\ePortal\\UserAcknowledgeSecondReminder', label: 'Andre påminnelse kursinvitasjon (4 dager igjen)'},
    {
        value: 'App\\Messages\\Events\\ePortal\\UserAcknowledgeSpecialReminder',
        label: 'Spesiell påminnelse kursinvitasjon (14 dager igjen) (engangsbruk)',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\CourseAdminApproveQualitySeminarReminder',
        label: 'Påminnelse til kursadmin om å godkjenne deltakerlisten for kvalitetsseminar',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekUntilCourseUnapprovedReminder',
        label: 'Påminnelse 1 uke før kursgodkjenning går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\ThreeMonthsUntilCourseUnapprovedReminder',
        label: 'Påminnelse 3 måneder før kursgodkjenning går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\FifteenMonthsAfterCourseSignupReminder',
        label: 'Påminnelse 3 måneder før frist for å fullføre kurset går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekBeforeCourseSignupExpiresReminder',
        label: 'Påminnelse 1 uke før frist for å fullføre kurset går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekBeforeFinalCourseSignupDeadlineExpiresReminder',
        label: 'Påminnelse 1 uke før siste frist for å fullføre kurset går ut (utvidelse innvilget)',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\TwoDaysBeforeConferenceSignupExpiresReminder',
        label: 'Påminnelse 2 dager før frist for å fullføre konferansen går ut',
    },
    {
        value: 'App\\Messages\\Events\\ePortal\\OneWeekBeforeSeminarSignupExpiresReminder',
        label: 'Påminnelse 1 uke før frist for å fullføre seminaret går ut',
    },
]

export const COURSE_TRIGGER_OPTIONS = [
    {value: 'App\\Messages\\Events\\Course\\MeetingPlanned', label: 'Møte er planlagt'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserAcknowledgedCourse', label: 'Bruker påmeldt kurs (godkjent vilkår)'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserCompletedCourse', label: 'Bruker har Fullført kurs'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserCompletedCourse100DaysAgo', label: 'Bruker har fullført kurset for 100 dager siden'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserCompletedCourse365DaysAgo', label: 'Bruker har fullført kurset for 365 dager siden'},
]

export const TASK_TRIGGER_OPTIONS = [
    {value: 'App\\Messages\\Events\\Course\\User\\UserCompletedTask', label: 'Bruker har fullført oppgave'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserHadMeeting10DaysAgo', label: 'Bruker hadde møte for 10 dager siden'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserHadMeeting14DaysAgo', label: 'Bruker hadde møte for 14 dager siden'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserHaveMeetingIn10Days', label: 'Bruker har møte om 10 dager'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserHaveMeetingIn24Hours', label: 'Bruker har møte om 24 timer'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserInvitedToCourse', label: 'Bruker invitert til kurs'},
    {value: 'App\\Messages\\Events\\Course\\User\\UserStartedTask', label: 'Bruker har startet på oppgave'},
]

export const TRIGGER_OPTIONS = [...GLOBAL_TRIGGER_OPTIONS, ...COURSE_TRIGGER_OPTIONS, ...TASK_TRIGGER_OPTIONS]
