import Dialog from '@mui/material/Dialog'
import {DialogTitle} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import * as React from 'react'

type Props = {
    isOpen: boolean
    handleClose: () => any
    handleSubmit: (newGroup: {name: string}) => any
}

export const CreatePartnershipDialog = ({isOpen, handleClose, handleSubmit}: Props) => {
    const [name, setName] = React.useState<string>('')

    const handleDialogClose = () => {
        setName('')
        return handleClose()
    }

    const handleDialogSubmit = async () => {
        await handleSubmit({
            name,
        })
        handleDialogClose()
    }

    return (
        <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogTitle>Opprett nytt samarbeid</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            id='name'
                            label='Navn'
                            fullWidth
                            value={name}
                            onChange={event => {
                                setName(event.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                <DialogActions sx={{padding: 0}}>
                    <Button variant='outlined' onClick={handleDialogClose}>
                        Avbryt
                    </Button>
                    <Button disabled={!name} variant='contained' onClick={handleDialogSubmit}>
                        Opprett samarbeid
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
