import * as React from 'react'
import {Button} from '../../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import {DatePicker} from '@mui/x-date-pickers-pro'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import Typography from '@mui/material/Typography'
import {components} from '../../../Generated/eportal'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import useFieldChange from '../../utils/useFieldChange'
import {SetState} from '../../../Utilities/TypeHelpers'

interface DoctorInviteDialogProps {
    office: components['schemas']['Praksisnett.Office.jsonld-PraksisNettOffice.details_PraksisNettDoctor.contact']
    refresh: Function
    setOpen: SetState<boolean>
    isOpen: boolean
}
type NewCompensationType = components['schemas']['Praksisnett.Office.Compensation.jsonld']

//TODO: This type is actually wrong, id, createdAt and changelogs should not be required.. WIP
const initData = (): NewCompensationType => ({
    id: 0,
    createdAt: '',
    year: new Date().getFullYear(),
    invoiceReceivedAt: new Date().toISOString(),
    invoiceReminderAt: null,
    invoicePaidAt: null,
    invoiceNumber: '',
    amountPaid: 0,
    changelogs: [],
})

export const OfficeAddCompensationDialog = ({office, refresh, setOpen, isOpen = false}: DoctorInviteDialogProps) => {
    const addMutation = useSkilMutation<'createPraksisNettOfficeCompensation'>('POST', `/api/praksisnett/office/compensations`, [
        `/api/praksisnett/offices/${office.id}`,
        `/api/praksisnett/offices`,
    ])
    const [updateFields, onChange, setFields, onChangeDate] = useFieldChange<NewCompensationType>(initData)

    const handleDialogClose = () => {
        setOpen(false)
        setFields(initData())
    }

    const handleSubmitInvite = async () => {
        try {
            await addMutation.mutateAsync({
                office: office['@id'],
                ...updateFields,
            })
            toast(`Kompensasjon lagt til`, {type: 'success'})
            refresh()
            handleDialogClose()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    return (
        <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogContent>
                <Typography>Legg til kompensasjon</Typography>

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            id='year'
                            fullWidth
                            value={updateFields.year}
                            onChange={onChange}
                            label='År'
                            type={'number'}
                            inputProps={{min: 2015, max: 2035}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='invoiceNumber'
                            fullWidth
                            value={updateFields.invoiceNumber}
                            onChange={onChange}
                            label='Fakturanummer'
                            type={'string'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            label='Faktura mottatt'
                            inputFormat='dd.MM.yyyy'
                            mask='__.__.____'
                            value={updateFields.invoiceReceivedAt}
                            renderInput={params => <TextField fullWidth id='invoiceReceivedAt' {...params} />}
                            onChange={onChangeDate('invoiceReceivedAt')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            label='Faktura betalt'
                            inputFormat='dd.MM.yyyy'
                            mask='__.__.____'
                            value={updateFields.invoicePaidAt ?? null}
                            renderInput={params => <TextField fullWidth id='invoicePaidAt' {...params} />}
                            onChange={onChangeDate('invoicePaidAt')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='amountPaid'
                            fullWidth
                            value={updateFields.amountPaid ?? ''}
                            onChange={onChange}
                            label='Betalt'
                            type={'number'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            label='Påminnelse'
                            inputFormat='dd.MM.yyyy'
                            mask='__.__.____'
                            value={updateFields.invoiceReminderAt ?? null}
                            renderInput={params => <TextField fullWidth id='invoiceReminderAt' {...params} />}
                            onChange={onChangeDate('invoiceReminderAt')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='invoiceNote'
                            fullWidth
                            value={updateFields.invoiceNote}
                            onChange={onChange}
                            label='Faktura merknad'
                            type={'string'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={handleDialogClose}>
                    Avbryt
                </Button>
                <Button disabled={!updateFields.invoiceReceivedAt || !updateFields.year} variant='contained' onClick={handleSubmitInvite}>
                    Legg til
                </Button>
            </DialogActions>
        </Dialog>
    )
}
