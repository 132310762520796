import * as React from 'react'
import {Page} from './Page'
import {Box} from './Panel'

export const NotFoundPage = () => {
    return (
        <Page variant='full'>
            <Box>
                <h1 style={{textAlign: "center"}}>Siden ble ikke funnet</h1>
            </Box>
        </Page>
    )
}
