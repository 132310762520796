import React, {Suspense} from 'react'
import {Route} from 'react-router-dom'
import {Routes} from 'react-router'
import LoadingComponent from './Components/LoadingComponent'
import Job from './EditJob/Job'
import Waitingroom from './WaitingRoom/Waitingroom'
import Meeting from './Groups/Meeting'
import Form from './FormEditor/Form/Form'
import Group from './Groups/Group'
import {PraksisNettApp} from './PraksisNett/App'
import {SkilApp} from './Skil/App'
import {PageNotFound} from './PageNotFound'
import {GlobalRelatedFormApp} from './FormTask/GlobalRelatedFormApp'
import {KommunedashboardApp} from './Kommunedashboard/KommunedashboardApp'
import {SignupApp} from './Signup/SignupApp'
import {PreCheckFormTaskApp} from './FormTask/PreCheckFormTaskApp'
import {PreCheckFormApp} from './FormTask/PreCheckFormApp'
import {PreCheckRelatedTaskApp} from './FormTask/PreCheckRelatedTaskApp'
import {NefleApp} from './Nefle/App'
import {ConfirmUserDetails} from './User/ConfirmUserDetails'

const App = () => {
    return (
        <Suspense fallback={<LoadingComponent>Loading...</LoadingComponent>}>
            <Routes>
                <Route path={`/public/task/:taskId/`} element={<PreCheckFormTaskApp />} />
                <Route path={`/public/form/:formId/preview`} element={<PreCheckFormApp isPreview />} />
                <Route path={`/public/form/:formId/`} element={<PreCheckFormApp isPreview={false} />} />
                <Route path={`/dashboard/task/form/:taskId`} element={<PreCheckFormTaskApp />} />
                <Route path={`/public/related_task/:taskId/`} element={<PreCheckRelatedTaskApp />} />
                <Route path={`/dashboard/related_task/:taskId`} element={<PreCheckRelatedTaskApp />} />
                <Route path={`/dashboard/related_task/:taskId/:answerSetId`} element={<PreCheckRelatedTaskApp />} />
                <Route path={`/public/related_form/:formId/related-task/:relatedTaskUniqueId`} element={<GlobalRelatedFormApp />} />
                <Route path={`/dashboard/course/:shortTitle/settings/jobs/:jobId/`} element={<Job />} />
                <Route path={`/dashboard/offices/:officeId/waitingroom`} element={<Waitingroom />} />
                <Route path={'/dashboard/offices/:officeId/nefle/setup/*'} element={<NefleApp />} />
                <Route path={'/dashboard/groups/:groupId/meetings/:meetingId'} element={<Meeting />} />
                <Route path={'/dashboard/groups/:id'} element={<Group isKommuneDashboard={false} />} />
                <Route path={'/dashboard/meetings/:meetingId'} element={<Meeting />} />
                <Route path={'/dashboard/course/:shortTitle/settings/forms/:formId'} element={<Form />} />
                <Route path={'/dashboard/course/:shortTitle/settings/forms/:formId/:groupId'} element={<Form />} />
                <Route path={'/dashboard/kommuner/*'} element={<KommunedashboardApp />} />
                <Route path={'/dashboard/SKIL/*'} element={<SkilApp />} />
                <Route path={'/dashboard/praksisnett/*'} element={<PraksisNettApp />} />
                <Route path={'/dashboard/confirm/user/:redirect'} element={<ConfirmUserDetails />} />
                <Route path={'/*'} element={<SignupApp />} />
                <Route path={'*'} element={<PageNotFound />} />
            </Routes>
        </Suspense>
    )
}

export default App
