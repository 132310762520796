import PurePage from '../../../Components/PurePage'
import React from 'react'
import useUser from '../../../Utilities/useUser'
import {
    Grid,
    Paper,
    Box,
    Typography,
    Divider,
    Stack,
    Chip,
    Card,
    CardContent,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    InputAdornment,
    DialogTitle,
} from '@mui/material'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import 'dayjs/locale/nb'
import DateTimeField from '../../../Components/Fields/DateTimeField'
import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker'
import DialogActions from '@mui/material/DialogActions'
import Item from '@mui/material/Unstable_Grid2'
import {Button} from '../../../Components/Button/MuiButton'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import styled from 'styled-components'
import {array, z} from 'zod'
import {useLocation} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import TextField from '@mui/material/TextField'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

type Props = {
    isOpen: boolean
    onClose: () => any
    learningGoal: string
}

export default function LearningGoalsDialog({isOpen, onClose, learningGoal}: Props) {
    const learningGoals = learningGoal ? {__html: learningGoal} : undefined
    return (
        <PurePage variant={'md'}>
            <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={() => onClose} aria-describedby='alert-dialog-slide-description'>
                <DialogTitle>Læringsmål</DialogTitle>
                <DialogContent>
                    <Box>{learningGoal && <div dangerouslySetInnerHTML={learningGoals} />}</Box>
                </DialogContent>
                <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                    <DialogActions sx={{padding: 0}}>
                        <Button variant='outlined' onClick={() => onClose()}>
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </PurePage>
    )
}
