import * as React from 'react'
import Button from '../../Button/Button'
import {GridCellParams, GridColDef} from '@mui/x-data-grid-premium'
import {customValueGetter, sortComparator} from './columnDef'

const renderCell = params => {
    if (!params.value) return <span>ingen gruppe</span>

    const group = params.row[params.field]

    return (
        <Button variant='text' to={`/dashboard/groups/${group.id}`}>
            {params.value}
        </Button>
    )
}

const getApplyGroupQuickFilterFn = (value: string) => {
    if (!value) {
        return null
    }
    return (params: GridCellParams): boolean => (params.value ? params.value.includes(value) : false)
}

type Column = GridColDef & {
    type?: 'group_type'
}
export default function groupColumnDef(col: Column): GridColDef {
    let valueGetter = customValueGetter(col.valueGetter, 'title')

    return {
        flex: 3,
        headerName: 'Gruppe',
        type: 'string',
        sortComparator,
        renderCell,
        getApplyQuickFilterFn: getApplyGroupQuickFilterFn,
        ...col,
        valueGetter,
    }
}
