import {UI_SHOW_TOAST} from '../actions/ui'
import {toast} from 'react-toastify'

export const toastify = () => next => async action => {
    next(action)

    if (action.type !== UI_SHOW_TOAST) return

    const {message, type, config} = action.payload
    await toast[type](message, config)
}
