import * as React from 'react'
import {BreadcrumbsItem} from '../../Components/Breadcrumbs'
import {ROUTES} from '../constants/routes'
import logo from '../img/logo.png'
import {useTheme} from '@mui/material'
interface BreadcrumbsMap {
    [breadcrumb: string]: BreadcrumbsItem
}

const HomeBreadcrumb = () => {
    const theme = useTheme()
    return (
        <img
            title='Hjem'
            style={{width: theme.spacing(22.5), paddingBottom: theme.spacing(0.5), background: theme.palette.background.default}}
            src={logo}
            alt='PraksisNett CRM'
            loading='lazy'
        />
    )
}

export const breadcrumbsMap: BreadcrumbsMap = {
    home: {title: 'PraksisNett CRM', image: <HomeBreadcrumb />, to: ROUTES.HOME},
    doctors: {title: 'Leger', to: ROUTES.DOCTORS},
    offices: {title: 'Legekontor', to: ROUTES.OFFICES},
    studies: {title: 'Studier', to: ROUTES.STUDIES},
    compensations: {title: 'Kompensasjon', to: ROUTES.COMPENSATIONS},
    invitations: {title: 'Invitasjon', to: ROUTES.INVITATIONS},
    praksisnett_users: {title: 'PraksisNett brukere', to: ROUTES.PRAKSISNETT_USERS},
}
