import * as React from 'react'
import Button from '../../Button/Button'
import {GridCellParams, GridColDef, GridRenderCellParams} from '@mui/x-data-grid-premium'
import {sortComparator, customValueGetter} from './columnDef'

const renderCell = (params: GridRenderCellParams) => {
    if (!params.value) return <span>ingen oppgave</span>

    const task = params.row[params.field]
    const course = task?.course

    if (!task || !course) {
        return params.value
    }

    return (
        <Button variant='text' href={`/dashboard/course/${course.shortTitle}/settings/task/${task.id}`}>
            {params.value}
        </Button>
    )
}

type Column = GridColDef & {
    type?: 'task_type'
}

const getApplyTaskQuickFilterFn = (value: string) => {
    if (!value) {
        return null
    }
    return (params: GridCellParams): boolean => (params.value ? params.value.includes(value) : false)
}

export default function taskColumnDef(col: Column): GridColDef {
    let valueGetter = customValueGetter(col.valueGetter, 'title')

    return {
        flex: 3,
        headerName: 'Oppgave',
        type: 'string',
        sortComparator,
        renderCell,
        ...col,
        valueGetter,
        getApplyQuickFilterFn: getApplyTaskQuickFilterFn,
    }
}
