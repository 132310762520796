import * as React from 'react'
import {useState} from 'react'
import {Button} from '../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import TextEditorField from '../../Components/Fields/TextEditorField'
import {styled} from '@mui/material/styles'
import {useSkilMutation} from '../../Utilities/QueryClient'
import Box from '@mui/material/Box'
import {DialogTitle} from '@mui/material'

type EmailProp = {isEmail: true; isText?: undefined}
type TextProp = {isText: true; isEmail?: undefined}
type BaseProps = {
    onClose: Function
    isOpen: boolean
    children?: React.ReactNode
    doctorIris: string[]
    submitLabel?: string
}
type DialogProps = BaseProps & (EmailProp | TextProp)

// TODO: Create a new component for this?
//  It is a custom fancy separator with label for the rich text field to match the label from Mui
const LabelDivider = styled(Divider)`
    ::before {
        width: 10px;
    }
    ::before,
    ::after {
        border-top-color: #c4c4c4;
    }
    ::after {
        width: 100%;
    }

    span {
        color: #021012;
        font-size: 11px;
        padding-left: 4px;
        padding-right: 4px;
    }
`

export const SendMessageDialog = ({isEmail, isText, isOpen, onClose, doctorIris, children, submitLabel}: DialogProps) => {
    const sendTextMutation = useSkilMutation<'sendPraksisnettDoctorSms'>('post', '/api/praksisnett/doctors/send_sms')
    const sendEmailMutation = useSkilMutation<'sendPraksisnettDoctorEmail'>('post', '/api/praksisnett/doctors/send_email')
    const [subject, setSubject] = useState<string>('')
    const [content, setContent] = useState<string>('')

    const handleDialogClose = () => {
        setSubject('')
        setContent('')
        return onClose()
    }

    const handleSubmit = async () => {
        if (isEmail) {
            // @ts-expect-error
            await sendEmailMutation.mutateAsync({content, subject, doctors: doctorIris})
        }
        if (isText) {
            // @ts-expect-error
            await sendTextMutation.mutateAsync({content, doctors: doctorIris})
        }

        handleDialogClose()
        return 'Melding sendt til ' + doctorIris.length + ' brukere'
    }

    return (
        <Dialog maxWidth='md' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogTitle>Send melding</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                    {isEmail && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    id='content'
                                    label='Emne'
                                    fullWidth
                                    value={subject}
                                    onChange={event => {
                                        setSubject(event.target.value)
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <LabelDivider textAlign='left'>Innhold</LabelDivider>
                                <TextEditorField rows={10} id='content' value={content} onChange={setContent} />
                            </Grid>
                        </>
                    )}
                    {isText && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    minRows={6}
                                    id='content'
                                    label='Melding'
                                    multiline
                                    fullWidth
                                    value={content}
                                    onChange={event => {
                                        setContent(event.target.value)
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                <DialogActions sx={{padding: 0}}>
                    <Button variant='outlined' onClick={handleDialogClose}>
                        Avbryt
                    </Button>
                    <Button disabled={doctorIris.length === 0} variant='contained' onClick={handleSubmit}>
                        {submitLabel ?? `Send melding til ${doctorIris.length} bruker(e)`}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
