import TextEditor from '../Components/TextEditor/TextEditor'
import React, {useState} from 'react'
import SimpleSelectField from '../Components/Fields/SimpleSelectField'
import TextField from '../Components/Fields/TextField'
import {jsonFetch} from '../Components/jsonFetch'
import Button from '../Components/Button/Button'
import {Page} from '../Components/Page'
import CheckboxField from '../Components/Fields/CheckboxField'
import {useSkilQuery} from '../Utilities/QueryClient'
import RadioGroupField from '../Components/Fields/RadioGroupField'

export default function MassMailing() {
    const [subject, setSubject] = useState('')
    const [receivers, setReceivers] = useState('')
    const [content, setContent] = useState('')
    const [dontWantGeneralInfo, setDontWantGeneralInfo] = useState(true)
    const [outreachType, setOutreachType] = useState<string>('generalInfo')
    const {data: courses} = useSkilQuery<'api_coursesavailable_get_collection'>('/api/courses/available', {itemsPerPage: 1000})

    const options = [
        {value: 'test_support', label: 'Test til Support'},
        {value: 'test_morten', label: 'Test til Morten'},
        {value: 'all_eportal_users', label: 'Alle brukere'},
        {value: 'all_active_counselors', label: 'Alle aktive veiledere'},
        {value: 'all_nefle_homepage_users', label: 'Alle nefle hjemmeside brukere'},
        {value: 'all_nefle_video_users', label: 'Alle nefle video brukere'},
        {value: 'all_quality_seminar_users', label: 'Alle kvalitetsseminar brukere'},
        {value: 'all_2024_skil_conference_users', label: 'Alle SKIL Forum 2024 brukere'},
        {value: 'all_rogaland_and_vestland_users', label: 'Alle Rogaland og Vestland brukere'},
    ]
    // add available courses (signup allowed) to options
    courses?.['hydra:member'].forEach(course => options.push({value: course.shortTitle, label: course.title}))

    function changeReceivers(e) {
        setReceivers(e)
    }
    function changeSubject(e) {
        setSubject(e)
    }
    function changeContent(e) {
        setContent(e)
    }
    function changeDontWantEmails(e) {
        setDontWantGeneralInfo(e)
    }

    const sendMail = () => {
        return jsonFetch(`/dashboard/massmailing/send_message`, {
            method: 'POST',
            json: {content: content, title: subject, recipients: receivers, dontWantGeneralInfo: dontWantGeneralInfo, outreachType},
        })
            .then(res => {
                // @ts-expect-error API is not typed
                let count = res.count
                if (count) {
                    setReceivers('')
                    setSubject('')
                    setContent('')
                    return 'Meldingen er sendt til ' + count + ' brukere'
                }
            })
            .catch(e => {
                return 'Det skjedde en feil: ' + e
            })
    }

    const breadcrumbs = [
        {title: 'SKIL', to: '/dashboard/SKIL'},
        {
            title: 'Verktøy',
            to: '/dashboard/SKIL/tools',
        },
        {title: 'Masseutsendelse'},
    ]

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <div className={'box'}>
                <div className={'box-header'}>
                    <h3 className={'box-title'}>Skriv ny e-post</h3>
                    <br />
                    <br />
                    <p>
                        Instruksjoner: Ved lim inn fra Word eller Pages velg kun 'Insert only Text'. Teksten må deretter formatteres på nytt
                        i denne teksteditoren.
                    </p>
                </div>
                <div className={'box-body'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <div className={'form-group'}>
                                <SimpleSelectField
                                    id={'receivers'}
                                    label={'Mottakere'}
                                    multiple={'multiple'}
                                    data-placeholder={'Velg mottakere'}
                                    style={{width: '100%'}}
                                    value={receivers}
                                    options={options}
                                    onChange={changeReceivers}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <div className={'form-group'}>
                                <TextField
                                    id={'title'}
                                    label='title'
                                    className={'form-control'}
                                    placeholder={'Emne'}
                                    style={{width: '100%'}}
                                    value={subject}
                                    onChange={changeSubject}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'row'} style={{marginTop: '1em', marginBottom: '1em'}}>
                        <div className={'col-md-12'}>
                            <div className={'form-group'}>
                                <RadioGroupField
                                    id='generalInfoOrNewsletter'
                                    label='Generell info eller nyhetsbrev?'
                                    value={outreachType}
                                    onChange={event => {
                                        setOutreachType(event)
                                    }}
                                    dontWantGeneralInfo={dontWantGeneralInfo}
                                />
                            </div>
                        </div>
                    </div>
                    {outreachType === 'generalInfo' && (
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'form-group'}>
                                    <CheckboxField
                                        id={'dontWantGeneralInfo'}
                                        label='Hopp over de som ikke ønsker generell info'
                                        className={'form-control'}
                                        value={dontWantGeneralInfo}
                                        defaultChecked={true}
                                        onChange={changeDontWantEmails}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <TextEditor id={'content'} placeholder={'Innhold'} value={content} onChange={changeContent} />
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Button onClick={sendMail} className={'btn btn-primary btn-flat pull-right submitButton'}>
                                Send
                            </Button>
                        </div>
                    </div>
                    <div className={'row'} />
                </div>
            </div>
        </Page>
    )
}
