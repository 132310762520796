import React from 'react'
import {motion} from 'framer-motion'

const AnimatedItem = ({id, children, direction = 1, ...props}) => {
    return (
        <motion.div
            transition={{duration: 0.15, delay: 0.1}}
            key={id}
            initial={{x: 300, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            exit={{x: -300, opacity: 0}}
            {...props}
        >
            {children}
        </motion.div>
    )
}
export default AnimatedItem
