import React, {useLayoutEffect, useState} from 'react'
import Panel from '../../../Components/Panel'
import {useOutletContext} from 'react-router-dom'
import Button from '../../../Components/Button/Button'
import {useParams} from 'react-router'
import styled from 'styled-components'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import useDnsService from '../hooks/useDnsService'

const assertNefle = domain => {
    return /nefle\.no$/.test(domain)
}

const Status = () => {
    const {officeId} = useParams()
    const {nefle, isGenerated, setQueryEnabled}: any = useOutletContext()
    const generateOfficeNefleSite = useSkilMutation<'generateOfficeNefleSite'>('POST', `/api/office_nefles/${nefle.id}/generate`)
    const [seemsLegit, setSeemsLegit] = useState(false)

    const onGenerate = async () => {
        try {
            await generateOfficeNefleSite.mutateAsync({})

            setQueryEnabled(true)
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const [formData, setFormData] = useState({
        ...nefle.siteConfig,
    })

    const isNefle = assertNefle(formData.domain)
    const {forwarded} = useDnsService(formData.domain)
    const demoDomain = `${officeId}.demo.nefle.no`

    if (!isGenerated) return <Finished onGenerate={onGenerate} />

    return (
        <Panel variant={'flat'}>
            <Panel.Body>
                {!isNefle && (!seemsLegit || !forwarded) && <RegistrarCard domain={formData.domain} />}
                {seemsLegit && forwarded && <NefleCard domain={formData.domain} />}

                <DomainCard
                    domain={forwarded ? formData.domain : demoDomain}
                    isDemo={!forwarded}
                    seemsLegit={seemsLegit && forwarded}
                    setSeemsLegit={setSeemsLegit}
                />

                {!isNefle && <DnsHelpCard domain={formData.domain} />}
            </Panel.Body>
        </Panel>
    )
}

export default Status

const StyledPill = styled.div`
    display: inline-block;
    min-width: 150px;
    padding: 0.5rem;
    margin: 0.5rem;

    background: #f8f9fa;
    border-radius: 14px;
`

const Pill = ({children, className, ...props}) => (
    <StyledPill {...props} className={className + ' border'}>
        {children}
    </StyledPill>
)

const NefleCard = ({domain}) => {
    return (
        <Panel variant={'flat'}>
            <Panel.Body className='text-center'>
                <strong>Se over og gjør endringer på dine side nå</strong>
                <br />
                via din unike{' '}
                <a className={'text-dark'} target={'_blank'} href={`https://${domain}`}>
                    Nefle-side
                </a>
                <br />
                <br />
                <br />
            </Panel.Body>
        </Panel>
    )
}

const RegistrarCard = ({domain}) => {
    const tmpDomain = domain.replace(/^www./, '')
    const {nameserver} = useDnsService(tmpDomain)
    const nefleIp = process.env.REACT_APP_NEFLE_IP || '35.228.75.104'
    return (
        <Panel variant={'flat'}>
            <Panel.Body className='text-center'>
                <strong>For at nettsiden skal fungere gjør følgende</strong>
                <br />
                via din domeneforhandler
                {
                    //TODO nameserver // service
                }
                <br />
                <br />
                <p className='small'>
                    1. Opprett en A-post for “{tmpDomain}” som peker mot vår IP-adresse {nefleIp}
                    <br />
                    2. Opprett en A-post for “www.{tmpDomain}” som peker mot vår IP-adresse {nefleIp}
                </p>
            </Panel.Body>
        </Panel>
    )
}

const DnsHelpCard = ({domain}) => {
    const {forwarded} = useDnsService(domain)
    return (
        <Panel variant={'flat'} hidden={forwarded}>
            <Panel.Body className='text-center'>
                <strong>Trenger du hjelp med DNS?</strong>
                <br />
                Kontakt din domeneforhandler eller oss på e-post <a href='mailto:teknisk@skilnet.no'>teknisk@skilnet.no</a>
            </Panel.Body>
        </Panel>
    )
}

const DomainCard = ({domain, isDemo = false, seemsLegit, setSeemsLegit}) => {
    const {forwarded, ingress, hasSsl, testNow} = useDnsService(domain)
    const [testAgainAt, updateTestAgain] = useState(0)

    useLayoutEffect(() => {
        if (!forwarded || isDemo || !ingress || !hasSsl) {
            setSeemsLegit(false)

            const interval = setInterval(() => {
                if (testAgainAt <= 0) {
                    updateTestAgain(60)
                    testNow().then()
                } else {
                    updateTestAgain(testAgainAt - 1)
                }
            }, 1000)

            return () => clearInterval(interval)
        } else {
            setSeemsLegit(true)
        }
    }, [forwarded, ingress, hasSsl, testAgainAt])

    return (
        <Panel variant={'flat'} className='text-center'>
            <Panel.Body className='p-2'>
                {!seemsLegit || !forwarded ? (
                    <div className='small'>
                        <strong>STATUSLINJE | </strong>
                        {testAgainAt > 0 ? (
                            <span>Domenet blir kontrollert igjen om {testAgainAt} sekunder</span>
                        ) : (
                            <span>
                                Kontrollerer domenet <i className={'fa fa-spinner fa-spin'} />
                            </span>
                        )}
                        <br />
                        <small>HTTPs / SSL tar ca. 2 timer.</small>
                    </div>
                ) : (
                    <div className='small'>
                        <strong>STATUSLINJE | </strong>
                        <span>Alt ser ut til å være i orden 🎉 </span>
                    </div>
                )}
            </Panel.Body>
            <Panel.Body className='p-2'>
                <Pill className={forwarded ? 'border-success' : ''}>
                    <i className={forwarded && seemsLegit ? 'fa fa-check' : 'fa fa-circle-notch fa-spin'}></i>
                    &nbsp;Domene
                </Pill>
                <Pill className={ingress ? 'border-success' : ''}>
                    <i className={ingress ? 'fa fa-check' : 'fa fa-circle-notch fa-spin'}></i>
                    &nbsp;Server
                </Pill>
                <Pill className={hasSsl ? 'border-success' : ''}>
                    <i className={hasSsl ? 'fa fa-check' : 'fa fa-circle-notch fa-spin'}></i>
                    &nbsp;HTTPs
                </Pill>
            </Panel.Body>
            <Panel.Body>
                <div className={'small'} style={{display: 'grid'}}>
                    {isDemo && <span>Inntil nettsiden er aktivert kan du redigere nettsiden via din demo nettside.</span>}
                    <Button variant='dark' href={`https://${domain}`} target='_blank' rel='noopener noreferrer'>
                        {isDemo ? `Åpne min demo nettside: ${domain}` : domain}
                    </Button>
                </div>
            </Panel.Body>
            {!seemsLegit && (
                <Panel.Body>
                    <Button
                        variant={'link'}
                        hidden={testAgainAt < 5}
                        onClick={() => {
                            updateTestAgain(2)
                            testNow().then()
                        }}>
                        {' '}
                        Sjekk på ny nå
                    </Button>
                </Panel.Body>
            )}
        </Panel>
    )
}

const Finished = ({onGenerate}) => {
    return (
        <Panel variant={'flat'}>
            <Panel.Body className='text-center'>
                <br />
                <strong>Du er nå klar til å opprette nettsiden til legekontoret!</strong>
                <p>Det tar ca. 5 minutter å opprette siden.</p>
                <StyledButton variant={'primary'} onClick={onGenerate}>
                    Opprett nettsiden
                </StyledButton>
                <br />
                <br />
                <p>
                    Dette gjør nettsiden tilgjengelig på vår server, men hvis du har eget domene må dette fortsatt konfigureres før
                    pasienter ser nettsiden din. Mer informasjon om dette får du etter at du har opprettet nettsiden på Nefle.
                </p>
                <br />
                <br />
            </Panel.Body>
        </Panel>
    )
}

const StyledButton = styled(Button)`
    &:focus {
        border: 4px solid blue;
    }
`
