import * as React from 'react'
import {Routes, Route} from 'react-router-dom'
import {Doctor} from './DetailsViews/Doctor'
import {Doctors} from './ListViews/Doctors'
import {Office} from './DetailsViews/Office'
import {Offices} from './ListViews/Offices'
import {Study} from './DetailsViews/Study'
import {Studies} from './ListViews/Studies'
import Overview from './Overview'
import {NotFoundPage} from '../Components/NotFoundPage'
import {APP_ROUTES} from './constants/routes'
import praksisNettTheme from './theme'
import ThemeProvider from '../Utilities/ThemeProvider'
import {Compensations} from './ListViews/Compensations'
import {Invitations} from './ListViews/Invitations'
import {PraksisnettUsers} from './ListViews/PraksisnettUsers'

export const PraksisNettApp = () => {
    return (
        <ThemeProvider theme={praksisNettTheme}>
            <Routes>
                <Route path={APP_ROUTES.HOME} element={<Overview />}>
                    <Route path={APP_ROUTES.STUDIES} element={<Studies />} />
                    <Route path={APP_ROUTES.OFFICES} element={<Offices />} />
                    <Route path={APP_ROUTES.DOCTORS} element={<Doctors />} />
                    <Route path={APP_ROUTES.COMPENSATIONS} element={<Compensations />} />
                    <Route path={APP_ROUTES.INVITATIONS} element={<Invitations />} />
                    <Route path={APP_ROUTES.PRAKSISNETT_USERS} element={<PraksisnettUsers />} />
                </Route>
                <Route path={`${APP_ROUTES.STUDIES}/:studyId`} element={<Study />} />
                <Route path={`${APP_ROUTES.OFFICES}/:officeId`} element={<Office />} />
                <Route path={`${APP_ROUTES.DOCTORS}/:doctorId`} element={<Doctor />} />

                <Route path={'/*'} element={<NotFoundPage />} />
            </Routes>
        </ThemeProvider>
    )
}
