import * as React from 'react'
import LoadingComponent from '../Components/LoadingComponent'
import {useParams} from 'react-router-dom'
import {Page} from '../Components/Page'
import Meetings from './Components/Meetings'
import Settings from './Components/Settings'
import Details from './Components/Details'
import useUser from '../Utilities/useUser'
import {SkilQueryResponseType, useSkilQuery} from '../Utilities/QueryClient'
import {AtMostOneOf} from '../Utilities/TypeHelpers'
import GroupMembers from './Components/GroupMembers'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import he from 'he'

type BreadcrumbType = {title: string} & AtMostOneOf<{href: string; to: string}>
type MemberWithPriceType = SkilQueryResponseType<'getUserGroupsFilterToOnePerUserWithPrice'>['hydra:member'][number]
type MemberWithoutPriceType = SkilQueryResponseType<'getUserGroupsFilterToOnePerUser'>['hydra:member'][number]
export type MemberType = MemberWithoutPriceType | MemberWithPriceType
export type MembersType = Array<MemberType>
export type GroupType = SkilQueryResponseType<'getGroupItem'>

export default function Group({isKommuneDashboard = false}) {
    const {partnershipId, id} = useParams()
    const {voters} = useUser()
    const groupIri = `/api/groups/${id}`
    const {data: group, isLoading: isLoadingGroups} = useSkilQuery<'getGroupItem'>(groupIri)
    const {isLoading: isLoadingMeetings} = useSkilQuery(`${groupIri}/meetings`)

    const isAdminOrCounselor = voters.isAdminOrCounselor(group?.course?.['@id'])
    const isSkilUser = voters.isSkilUser()
    const userHasActiveCourse = voters.hasCourse(group?.course?.['@id'])
    const isGroupCounselor = voters.isGroupCounselor(group?.['@id'])
    const isCourseAdmin = voters.isCourseAdmin(group?.course?.['@id'])

    const userGroupPath = isCourseAdmin || isSkilUser ? `/filterToOnePerUser/price` : `/filterToOnePerUser`
    const userGroupQuery = useSkilQuery(groupIri + userGroupPath)
    const members = (userGroupQuery.data?.['hydra:member'] ?? []) as MembersType
    let userGroupsDeactivated = members.filter(ug => ug.removed)
    let userGroupsActive = members.filter(ug => !ug.removed)

    let breadcrumbs: BreadcrumbType[] = [
        {title: 'Dashboard', to: '/dashboard/kommuner'},
        {title: 'Gruppe'},
        {title: group?.title ? he.decode(group?.title) : ''},
    ]
    let baseLinkUrl = `/dashboard/groups/${id}/meetings`

    if (isKommuneDashboard) {
        breadcrumbs = [
            {title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId},
            {title: 'Grupper', to: `/dashboard/kommuner/${partnershipId}/groups`},
            {title: group?.title ? he.decode(group?.title) : ''},
        ]
        baseLinkUrl = `/dashboard/kommuner/${partnershipId}/groups/${id}/meetings`
    }

    const showSettings = isCourseAdmin || isSkilUser
    return (
        <Page breadcrumbs={breadcrumbs} variant={'big'}>
            {isLoadingGroups || !group || userGroupQuery.isLoading || isLoadingMeetings ? (
                <LoadingComponent />
            ) : (
                <>
                    {!userHasActiveCourse && group.course?.signupLink && group.course.signupAllowed && (
                        <Alert variant={'outlined'} severity={'info'} style={{marginBottom: '1rem'}}>
                            <AlertTitle>Du er ikke påmeldt kurset.</AlertTitle>
                            <a href={group.course.signupLink} target={'_blank'}>
                                Meld deg på for å delta med gruppen.
                            </a>
                        </Alert>
                    )}
                    <div className={'row'}>
                        <div className={'col-lg-8 col-md-6'}>
                            <Details
                                group={group}
                                isKommuneDashboard={isKommuneDashboard}
                                isSkilUser={isSkilUser}
                                isCourseAdmin={isCourseAdmin}
                            />
                        </div>
                        <div className={'col-md-6 col-lg-4'}>{showSettings && <Settings group={group} />}</div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-xs-12'}>
                            <Meetings
                                group={group}
                                baseLinkUrl={baseLinkUrl}
                                isAdminOrCounselor={isAdminOrCounselor}
                                isKommuneDashboard={isKommuneDashboard}
                                isSkilUser={isSkilUser}
                            />
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-xs-12'}>
                            <GroupMembers
                                group={group}
                                userGroupsActive={userGroupsActive}
                                isKommuneDashboard={isKommuneDashboard}
                                isAdminOrCounselor={isAdminOrCounselor}
                                isGroupCounselor={isGroupCounselor}
                                isSkilUser={isSkilUser}
                                userGroupsDeactivated={userGroupsDeactivated}
                            />
                        </div>
                    </div>
                </>
            )}
        </Page>
    )
}
