import React, {useEffect, useRef} from 'react'
import {func, number, string} from 'prop-types'
import {Jodit} from 'jodit'

const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    enableDragAndDropFileToEditor: true,
    uploader: {
        insertImageAsBase64URI: true,
    },
    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg'],
    i18n: 'no',
    toolbar: true,
    defaultActionOnPaste: 'insert_only_text',
}

const JoditEditor = ({id, name, onBlur, onChange, tabIndex, value}) => {
    const textArea = useRef(null)

    useEffect(() => {
        const element = textArea.current
        // @ts-expect-error
        textArea.current = Jodit.make(element, config)
        // @ts-expect-error
        textArea.current.workplace.tabIndex = tabIndex || -1
        // adding event handlers
        // @ts-expect-error
        textArea.current.events.on('blur', value => onBlur && onBlur(value))
        // @ts-expect-error
        textArea.current.events.on('change', value => onChange && onChange(value))
        if (id) {
            // @ts-expect-error
            element.id = id
        }
        if (name) {
            // @ts-expect-error
            element.name = name
        }
        return () => {
            // @ts-expect-error
            textArea.current?.destruct()
            textArea.current = element
        }
    }, [config])

    useEffect(() => {
        // @ts-expect-error
        if (textArea?.current?.value !== value) {
            // @ts-expect-error
            textArea.current.value = value
        }
    }, [value])

    return <textarea ref={textArea} />
}

JoditEditor.propTypes = {
    id: string,
    name: string,
    onBlur: func,
    onChange: func,
    tabIndex: number,
    value: string,
}
export default JoditEditor
