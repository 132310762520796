import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import areaColumnDef from '../../Components/DataGrid/columnDefs/areaColumnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import {Button} from '../../Components/Button/MuiButton'
import AddIcon from '@mui/icons-material/Add'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import CreateOfficeDialog from '../Components/CreateOfficeDialog'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import {CountiesExportToAreaColumnDef} from '../../Components/CountiesExportToAreaColumnDef'
import {DistrictsExport} from '../../Components/DistrictsExport'

const renderCell = params => {
    if (!params.value) return ''

    return (
        <Button variant='text' href={`/dashboard/offices/${params.row.id}/settings`}>
            {params.value}
        </Button>
    )
}

const columnVisibilityModel = {
    id: false,
    postCode: false,
    address: false,
    createdAt: false,
    invoiceEmail: false,
    confirmedOfficeDetailsPreviouslyAt: false,
    'nefle.id': false,
    'nefle.createdAt': false,
    'nefle.generatedAt': false,
    'nefle.domain': false,
    'nefle.cancelledAt': false,
    'nefle.cancelledReason': false,
    'nefle.partnership': false,
}

const Offices = () => {
    const {data, isFetching, refetch} = useSkilQuery<'getOfficesDetailsCollection'>('/api/offices/details', {pagination: false})
    const createOfficeMutation = useSkilMutation<'addOffice'>('POST', '/api/offices', ['/api/offices'])
    const offices = data?.['hydra:member'] ?? []
    const [showOfficeDialog, setShowOfficeDialog] = useState(false)
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const counties = CountiesExportToAreaColumnDef(hasLoaded)
    const districts = DistrictsExport(hasLoaded)

    const columns: GridColDef[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'name', headerName: 'Navn', type: 'string', renderCell}),
        columnDef({field: 'orgNr', headerName: 'Org. nr', type: 'string'}),
        columnDef({field: 'phoneNumber', headerName: 'Telefon', type: 'number'}),
        columnDef({field: 'postCode', headerName: 'Postnummer', type: 'number'}),
        columnDef({field: 'address', headerName: 'Adresse', type: 'string'}),
        areaColumnDef({
            field: 'county',
            headerName: 'Kommune',
            type: 'county',
            counties: counties.map(county => ({label: county.label, value: county.id})),
        }),
        areaColumnDef({
            field: 'district',
            headerName: 'Bydel',
            type: 'district',
            counties: districts.map(district => ({label: district.label, value: district.id})),
        }),
        areaColumnDef({
            field: 'county',
            headerName: 'Fylke',
            type: 'region',
            counties: counties.map(county => ({label: county.region, value: county.regionId})),
        }),
        columnDef({field: 'createdAt', headerName: 'Opprettet', type: 'string'}),
        columnDef({field: 'hospital', headerName: 'Sykehus', type: 'boolean'}),
        columnDef({field: 'invoiceEmail', headerName: 'Faktura epost', type: 'string'}),
        dateColumnDef({field: 'confirmedOfficeDetailsPreviouslyAt', headerName: 'Detaljer bekreftet', type: 'date'}),

        columnDef({field: 'nefle', headerName: 'Nefle', type: 'boolean'}),
        columnDef({field: 'nefle.id', headerName: 'Nefle ID'}),
        dateColumnDef({field: 'nefle.createdAt', headerName: 'Nefle opprettet', type: 'date'}),
        dateColumnDef({field: 'nefle.generatedAt', headerName: 'Nefle Generert på'}),
        columnDef({field: 'nefle.domain', headerName: 'Nefle Domene'}),
        columnDef({field: 'nefle.cancelledAt', headerName: 'Nefle Avlyst på'}),
        columnDef({field: 'nefle.cancelledReason', headerName: 'Nefle Avlyst årsak'}),
        columnDef({field: 'nefle.partnership', headerName: 'Nefle kommunesamarbeid', type: 'boolean'}),
    ]

    const addOfficeActions: SpecialActionProps[] = [
        {
            label: 'Legg til legekontor',
            action: () => setShowOfficeDialog(true),
            disabled: false,
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
    ]

    const onCreateOffice = async newOffice => {
        try {
            await createOfficeMutation.mutateAsync(newOffice)
            toast('Legekontoret ble opprettet', {type: 'success'})
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && counties?.length > 0) {
            setHasLoaded(true)
        }
    }, [counties])

    return (
        <Box>
            <DataGridWrapper rows={offices.length}>
                <DataGrid
                    id={'offices'}
                    loading={isFetching}
                    initModel={{columnVisibilityModel}}
                    rows={offices}
                    columns={columns}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={addOfficeActions} />}
                />
            </DataGridWrapper>
            <CreateOfficeDialog isOpen={showOfficeDialog} handleSubmit={onCreateOffice} handleClose={() => setShowOfficeDialog(false)} />
        </Box>
    )
}

export default Offices
