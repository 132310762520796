import {Route, Routes, useNavigate} from 'react-router-dom'
import Groups from './Groups'
import * as React from 'react'
import KommuneDashboard from './KommuneDashboard'
import CreateSeminar from './CreateSeminar'
import Communication from './Communication'
import Feedback from './Feedback'
import Group from '../Groups/Group'
import News from './News'
import NewsNew from './NewsNew'
import NewsItem from './NewsItem'
import Report from './Report'
import Doctors from './Doctors'
import Meeting from '../Groups/Meeting'
import useUser from '../Utilities/useUser'
import Button from '../Components/Button/Button'
import {useEffect} from 'react'
import {PageNotFound} from '../PageNotFound'

export const KommunedashboardApp = () => {
    return (
        <Routes>
            <Route path={'/'} element={<RedirectOrFail />} />

            <Route path={':partnershipId'} element={<KommuneDashboard />} />
            <Route path={':partnershipId/create_seminar'} element={<CreateSeminar />} />
            <Route path={':partnershipId/communication'} element={<Communication />} />
            <Route path={':partnershipId/feedback'} element={<Feedback />} />
            <Route path={':partnershipId/groups'} element={<Groups />} />
            <Route path={':partnershipId/groups/:id'} element={<Group isKommuneDashboard={true} />} />
            <Route path={':partnershipId/nyheter/'} element={<News />} />
            <Route path={':partnershipId/nyheter'} element={<News />} />
            <Route path={':partnershipId/nyheter/new'} element={<NewsNew />} />
            <Route path={':partnershipId/nyheter/:id'} element={<NewsItem />} />
            <Route path={':partnershipId/report'} element={<Report />} />
            <Route path={':partnershipId/doctors'} element={<Doctors />} />
            <Route path={':partnershipId/groups/:groupId/meetings/:meetingId'} element={<Meeting />} />
            <Route path={':partnershipId/seminars/:meetingId'} element={<Meeting />} />

            <Route path={'*'} element={<PageNotFound />} />
        </Routes>
    )
}

export const RedirectOrFail = () => {
    const user = useUser()
    const navigate = useNavigate()

    useEffect(() => {
        if (user.authenticated) {
            const partnerships = Object.keys(user.claims.partnerships)
            if (partnerships.length > 0) {
                const id = partnerships[0].substring('/api/partnerships/'.length)
                navigate(id, {replace: true})
            }
        }
    }, [user])

    return (
        <div className='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 text-center'>
            <p>
                Du har ingen samarbeid! <br />
                <br />
                <p>Hvis du burde hatt tilgang her, send en epost til kontakt@skilnet.no eller ring 94 20 70 70 så ordner vi det!</p>
                <Button variant={'primary'} href='https://www.skilnet.no/kommunesamarbeid/'>
                    Les mer her
                </Button>
            </p>
        </div>
    )
}
