import {useParams} from 'react-router'
import useEntity from '../Hooks/useEntity'
import useEntities from '../Hooks/useEntities'
import LoadingComponent from '../Components/LoadingComponent'
import {jsonFetch} from '../Components/jsonFetch'
import {AnswerValueType, AppAnswerSetType, NewLocationType} from './Types'
import React from 'react'
import {SkilQueryResponseType} from '../Utilities/QueryClient'
import {App} from './Form/App'

export const GlobalRelatedFormApp = () => {
    const {formId} = useParams()
    const {relatedTaskUniqueId} = useParams()
    let iri = `/api/forms/${formId}`
    const form = useEntity<'getFormItem'>(iri)
    const dependencies = useEntities<'getIndicator-IndicatorDependencyCollection'>(`${iri}/indicator_dependencies?itemsPerPage=1000`)
    const indicators = useEntities<'getFormIndicatorCollection'>(`${iri}/indicators?disabled=0&itemsPerPage=1000`)
    const groups = useEntities<'getFormIndicatorGroupCollection'>(`${iri}/indicator_groups?disabled=0&itemsPerPage=1000`)
    const answerSet = useEntity<'findAnswerSet'>(`/api/answer_sets/find?form=${formId}&slaveRelatedTask=${relatedTaskUniqueId}`)

    if (!form['@loaded'] || !answerSet['@loaded'] || !dependencies['@loaded'] || !indicators['@loaded'] || !groups['@loaded']) {
        return <LoadingComponent msg={'Laster inn spørsmål...'} />
    }

    const onSubmit = async (
        answers: {[indicatorIri: string]: AnswerValueType},
        newLocation?: NewLocationType
    ): Promise<AppAnswerSetType> => {
        const isApproved = answerSet && answerSet['approved']
        if (isApproved) {
            return {
                answers: answerSet.answers ?? [],
                newLocation: newLocation ?? {
                    id: 0,
                    region: undefined,
                    county: '',
                    externalOffice: '',
                    office: '',
                    district: '',
                },
                approved: true,
            }
        }

        const submitted = await jsonFetch<SkilQueryResponseType<'slaveRelatedSubmitAnswerSet', '201'>>(
            `/api/slave_related_answer_sets?form=${form.id}&masterlink=${relatedTaskUniqueId}`,
            {
                json: {
                    indicators: answers,
                    location: newLocation,
                },
            }
        )

        return {
            newLocation: submitted.newLocation,
            approved: submitted.approved,
            answers: submitted.answers,
        }
    }

    return (
        <App
            onSubmit={onSubmit}
            answerSet={answerSet}
            dependencies={dependencies}
            // @ts-expect-error
            indicators={indicators}
            groups={groups}
            form={form}
            title={form.name}
            isPreview={false}
        />
    )
}
