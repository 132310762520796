import * as React from 'react'
import {useState} from 'react'
import {Box} from '../../../Components/Panel'
import CircularProgress from '@mui/material/CircularProgress'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import DataGrid from '../../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../../Components/DataGrid/Wrappers'
import columnDef, {EditableGridColDef} from '../../../Components/DataGrid/columnDefs/columnDef'
import SpecialActions from '../../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import {GridCellEditCommitParams, GridEnrichedColDef} from '@mui/x-data-grid-premium'
import dateColumnDef from '../../../Components/DataGrid/columnDefs/dateColumnDef'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {DoctorInviteDialog} from './DoctorInviteDialog'
import {set} from 'lodash'
import Button from '../../../Components/Button/Button'
import {DeleteIconButton} from '../../../Components/Button/DeleteIconButton'
import {toast} from 'react-toastify'
import {DoctorType} from './Doctor'
interface DoctorStudiesPanelProps {
    doctor: DoctorType
}

export const DoctorStudiesPanel = ({doctor}: DoctorStudiesPanelProps) => {
    const {data, isLoading, refetch, isFetching, isError} = useSkilQuery<'getPraksisNettDoctorInvitationCollection'>(
        `/api/praksisnett/doctors/${doctor.id}/invitations`,
        null
    )
    const updateInvitationMutation = useSkilMutation<'updatePraksisNettInvitationItem'>('PATCH', `/api/praksisnett/invitations/${null}`, [
        `/api/praksisnett/doctors/${doctor.id}/invitations`,
    ])

    const deleteInvitationMutation = useSkilMutation<'deletePraksisNettInvitationItem'>('DELETE', `/api/praksisnett/invitations/${null}`, [
        `/api/praksisnett/doctors/${doctor.id}/invitations`,
    ])

    const handleDeleteInvitation = async id => {
        try {
            await deleteInvitationMutation.mutateAsync({
                '@overridePath': `/api/praksisnett/invitations/${id}`,
            })
            toast('Fjernet fra studien', {
                type: 'success',
            })
            // refetch: no need to catch, handled by useSkilQuery 😄
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const [openAddStudyDialog, setOpenAddStudyDialog] = useState<boolean>(false)

    const invitations = data ? data['hydra:member'] : []

    const onCellEditCommit = async (params: GridCellEditCommitParams) => {
        const compensation = set({}, params.field, params.value)
        try {
            await updateInvitationMutation.mutateAsync({
                '@overridePath': `/api/praksisnett/invitations/${params.id}`,
                ...compensation,
            })
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const renderStudyTitleCell = params => {
        if (!params.value) return <span>ingen navn</span>

        return (
            <Button variant='text' to={`/dashboard/praksisnett/studies/${params.row.study.id}`}>
                {params.value}
            </Button>
        )
    }

    const dateWidths = {minWidth: 120, maxWidth: 140}

    const invitationColumns: (EditableGridColDef | GridEnrichedColDef)[] = React.useMemo(
        () => [
            columnDef({field: 'id', headerName: 'System-ID', type: 'string'}),
            columnDef({field: 'study.name', headerName: 'Studie', type: 'string', renderCell: renderStudyTitleCell}),
            dateColumnDef({field: 'acceptedAt', headerName: 'Invitasjon godtatt på', type: 'date', ...dateWidths}, {onCellEditCommit}),
            columnDef({field: 'compensation.invoiceNumber', headerName: 'Fakturanummer', type: 'string'}, {onCellEditCommit}),
            dateColumnDef(
                {field: 'compensation.invoiceReceivedAt', headerName: 'Faktura mottatt på', type: 'date', ...dateWidths},
                {onCellEditCommit}
            ),
            columnDef({field: 'compensation.amountPaid', headerName: 'Betalt', maxWidth: 200, type: 'number'}, {onCellEditCommit}),
            dateColumnDef({field: 'compensation.invoicePaidAt', headerName: 'Betalt på', type: 'date', ...dateWidths}, {onCellEditCommit}),
            dateColumnDef(
                {field: 'compensation.invoiceReminderAt', headerName: 'Påminnelse på', type: 'date', ...dateWidths},
                {onCellEditCommit}
            ),
            columnDef({field: 'compensation.invoiceNote', headerName: 'Faktura merknad', type: 'string'}, {onCellEditCommit}),
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Handlinger',

                getActions: ({id}) => {
                    return [
                        <DeleteIconButton
                            key={`del${id}`}
                            deleteMenuItemLabel='Fjern fra studie'
                            tooltipText='Fjern'
                            menuId={`del${id}`}
                            deleteAction={() => handleDeleteInvitation(id)}
                        />,
                    ]
                },
            },
        ],
        []
    )

    const invitationActions: SpecialActionProps[] = [
        {
            label: 'Legg til studie',
            action: () => setOpenAddStudyDialog(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
    ]

    return (
        <Box mb={2} title={`${doctor.name}s studier`}>
            {isError ? (
                <Box>Noe gikk galt</Box>
            ) : isLoading ? (
                <CircularProgress />
            ) : (
                <DataGridWrapper rows={invitations.length} addHeight={9}>
                    <DataGrid
                        id={'studies'}
                        loading={isFetching}
                        disableSelectionOnClick
                        rows={invitations}
                        columns={invitationColumns}
                        initModel={{columnVisibilityModel: {id: false}}}
                        toolbarButtons={<SpecialActions actions={invitationActions} />}
                    />
                </DataGridWrapper>
            )}
            <DoctorInviteDialog refetchInvitations={refetch} doctor={doctor} setOpen={setOpenAddStudyDialog} isOpen={openAddStudyDialog} />
        </Box>
    )
}
