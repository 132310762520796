import React, {ReactNode} from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

type Props = {
    id: string
    label?: ReactNode
    help?: ReactNode
    value?: boolean
    onChange: (value: boolean, event: React.ChangeEvent<HTMLInputElement>) => any
    feedback?: ReactNode
    className?: string
    required?: boolean
    props?: {[key: string]: any}
    defaultChecked?: boolean
    disabled?: boolean
}

/** @deprecated*/
const CheckboxField = ({id, label, help, value, onChange, feedback, className, defaultChecked = false, disabled, ...props}: Props) => {
    return (
        <div className='form-group'>
            <FormControlLabel
                sx={{'& .MuiTypography-root': {overflowWrap: 'anywhere'}}}
                control={
                    <Checkbox
                        defaultChecked={defaultChecked}
                        disabled={disabled}
                        onChange={event => onChange(event.target.checked, event)}
                        sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                    />
                }
                label={label}
            />
            {feedback}
            {help && <p>{help}</p>}
        </div>
    )
}
export default CheckboxField
