import * as React from 'react'
import useFieldChange from '../../utils/useFieldChange'
import {useClickHandler} from '../../../Components/Button/utils'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import {Box} from '../../../Components/Panel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {DatePicker} from '@mui/x-date-pickers-pro'
import LoadingButton from '@mui/lab/LoadingButton'
import {components} from '../../../Generated/eportal'

type StudyType = components['schemas']['Praksisnett.Study.jsonld-PraksisNettStudy.details_PraksisNettStudyAttachment.details_File.view']
type PatchStudyType = components['schemas']['Praksisnett.Study-PraksisNettStudy.update']

interface StudyProps {
    study: StudyType
    refetch: Function
}

export const StudyStatusPanel = ({study, refetch}: StudyProps) => {
    const [updateFields, onFieldChange, setUpdateFields, onChangeDate] = useFieldChange<PatchStudyType>({})
    const updateProfile = useSkilMutation<'patchPraksisnettStudy'>('patch', `/api/praksisnett/studies/${study?.id}`, [
        `/api/praksisnett/studies/${study.id}`,
        `/api/praksisnett/studies`,
    ])

    const saveProfile = async () => {
        await updateProfile.mutateAsync(updateFields)
        refetch()
    }
    const {handleClick: handleSaveProfileClick, working: savingProfile} = useClickHandler(saveProfile)

    return (
        <Box title='Status'>
            <Grid container spacing={2} mb={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <DatePicker
                        label='Kontaktskjema mottatt'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.interestFormReceivedOn ?? study.status?.interestFormReceivedOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.interestFormReceivedOn' {...params} />}
                        onChange={onChangeDate('status.interestFormReceivedOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='WP2 møte'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.wp2ConversationOn ?? study.status?.wp2ConversationOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.wp2ConversationOn' {...params} />}
                        onChange={onChangeDate('status.wp2ConversationOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Diskutert i ledergruppemøte'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.discussedWithLeadersOn ?? study.status?.discussedWithLeadersOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.discussedWithLeadersOn' {...params} />}
                        onChange={onChangeDate('status.discussedWithLeadersOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Søknad mottatt'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.finalApplicationReceivedOn ?? study.status?.finalApplicationReceivedOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.finalApplicationReceivedOn' {...params} />}
                        onChange={onChangeDate('status.finalApplicationReceivedOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Søknad godkjent'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.approvedForPraksisNettUseOn ?? study.status?.approvedForPraksisNettUseOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.approvedForPraksisNettUseOn' {...params} />}
                        onChange={onChangeDate('status.approvedForPraksisNettUseOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='På kontrakt'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.contractSignedOn ?? study.status?.contractSignedOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.contractSignedOn' {...params} />}
                        onChange={onChangeDate('status.contractSignedOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Faktura sendt'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.invoiceSentOn ?? study.status?.invoiceSentOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.invoiceSentOn' {...params} />}
                        onChange={onChangeDate('status.invoiceSentOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Startet'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.startedOn ?? study.status?.startedOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.startedOn' {...params} />}
                        onChange={onChangeDate('status.startedOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Avsluttet'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.endedOn ?? study.status?.endedOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.endedOn' {...params} />}
                        onChange={onChangeDate('status.endedOn')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label='Avlyst'
                        inputFormat='dd.MM.yyyy'
                        mask='__.__.____'
                        value={updateFields.status?.cancelledOn ?? study.status?.cancelledOn ?? null}
                        renderInput={params => <TextField fullWidth id='status.cancelledOn' {...params} />}
                        onChange={onChangeDate('status.cancelledOn')}
                    />
                </Grid>
                <Grid hidden={!updateFields.status?.cancelledOn} item xs={12}>
                    <TextField
                        id='status.cancelledReason'
                        fullWidth
                        label='Avlyst grunn'
                        defaultValue={study.status?.cancelledReason}
                        onChange={onFieldChange}
                    />
                </Grid>
            </Grid>

            <LoadingButton
                sx={{marginLeft: 'auto', display: 'block'}}
                loading={savingProfile}
                variant='contained'
                onClick={handleSaveProfileClick}
            >
                Lagre
            </LoadingButton>
        </Box>
    )
}
