import React, {useState} from 'react'
import {Box} from '../Components/Panel'
import LoadingComponent from '../Components/LoadingComponent'
import {Page} from '../Components/Page'
import {useQueryInvalidate, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../Components/DataGrid/columnDefs/dateColumnDef'
import {DataGridWrapper} from '../Components/DataGrid/Wrappers'
import DataGrid, {InitModel} from '../Components/DataGrid/DataGrid'
import SpecialActions from '../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import {Radio} from '@mui/material'
import CreateNewsPopupDialog from './Components/CreateNewsPopupDialog'
import EditNewsPopupDialog from './Components/EditNewsPopupDialog'

const columnVisibilityModel = {
    message: false,
    createdAt: false,
    createdBy: false,
    updatedAt: false,
    updatedBy: false,
}

export default function NewsPopup() {
    const invalidate = useQueryInvalidate()
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel})
    const [selected, setSelected] = React.useState('')
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [editing, setEditing] = React.useState(false)
    const [isDeleting, setIsDeleting] = React.useState(false)
    const updatePopupNewsMutation = useSkilMutation<'patchNewsPopupItem'>('patch', `/api/news_popups/${selected}`, ['/api/news_popup/all'])
    const deletePopupNewsMutation = useSkilMutation<'deleteNewsPopupItem'>('delete', `/api/news_popups/${null}`, ['/api/news_popup/all'])

    const generatePopupNews = useSkilMutation<'postNewsPopupItem'>('post', `/api/news_popups`)
    const {
        data: newsPopupsRaw,
        isFetching,
        isSuccess,
        refetch: refetchAllPopupNewsItems,
    } = useSkilQuery<'getNewsPopupCollection'>(`/api/news_popup/all`)
    const allNewsPopups = newsPopupsRaw?.['hydra:member'] ?? []
    const {
        data: newsPopupItem,
        isFetching: isFetchingNewsPopupItem,
        isSuccess: isSuccessNewsPopupItem,
        refetch: refetchNewsPopupItem,
    } = useSkilQuery<'getNewsPopupItem'>(`/api/news_popups/${selected}`, {}, {enabled: !!selected && editing})

    const handleSelect = value => {
        setSelected(value)
    }

    const handleCloseEditDialog = () => {
        setSelected('')
        setEditing(false)
    }

    const handleEditDiscount = async () => {
        invalidate(['/api/news_popups/' + selected]).then(() => {
            setEditing(true)
        })
    }

    const handleDeleteDiscount = async () => {
        await onDelete()
    }

    const columns: GridColDef[] = [
        // Add a custom column for radio buttons
        {
            field: 'radio',
            headerName: '',
            width: 50,
            renderCell: params => {
                // Custom cell renderer for the radio button
                return <Radio checked={selected === params.id.toString()} onChange={() => handleSelect(params.id.toString())} />
            },
        },
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'title', headerName: 'Tittel', type: 'string'}),
        columnDef({field: 'message', headerName: 'Melding', type: 'string'}),
        dateColumnDef({field: 'validFrom', headerName: 'Gyldig fra', type: 'dateTime'}),
        dateColumnDef({field: 'validTo', headerName: 'Gyldig til', type: 'dateTime'}),
        dateColumnDef({field: 'createdAt', headerName: 'Opprettet dato', type: 'dateTime'}),
        columnDef({field: 'createdBy', headerName: 'Opprettet av', type: 'string'}),
        dateColumnDef({field: 'updatedAt', headerName: 'Oppdatert dato', type: 'dateTime'}),
        columnDef({field: 'updatedBy', headerName: 'Oppdatert av', type: 'string'}),
    ]

    const validateForm = async e => {
        if (!e.title) {
            toast('Du må skrive en tittel', {type: 'error'})
            return false
        }
        if (!e.message) {
            toast('Du må skrive en melding', {type: 'error'})
            return false
        }
        if (!e.validFrom) {
            toast('Du må fylle inn gyldig fra dato', {type: 'error'})
            return false
        }
        if (!e.validTo) {
            toast('Du må fylle inn gyldig til dato', {type: 'error'})
            return false
        }
        if (new Date(e.validTo) < new Date(e.validFrom)) {
            toast('Til dato kan ikke være før fra dato', {type: 'error'})
            return false
        }
        return true
    }

    const onSave = async e => {
        const valid = await validateForm(e)
        if (!valid) {
            setEditing(false)
            return false
        }

        try {
            await generatePopupNews
                .mutateAsync({
                    title: e.title,
                    message: e.message,
                    validFrom: e.validFrom,
                    validTo: e.validTo,
                })
                .then(() => {
                    refetchAllPopupNewsItems().then(() => {
                        toast('Popup nyheten er opprettet', {type: 'success'})
                        setEditing(false)
                        return true
                    })
                })
        } catch (e) {
            handleErrorWithToast(e)
            setEditing(false)
            return false
        }
    }

    const onUpdate = async e => {
        const valid = await validateForm(e)
        if (!valid) {
            setEditing(false)
            return false
        }

        try {
            await updatePopupNewsMutation
                .mutateAsync({
                    // @ts-expect-error
                    newsPopup: '/api/news_popups/' + selected,
                    title: e.title,
                    message: e.message,
                    validFrom: e.validFrom,
                    validTo: e.validTo,
                })
                .then(() => {
                    invalidate(['/api/news_popup/all', '/api/news_popups/' + selected])
                    refetchAllPopupNewsItems()
                        .then(() => {
                            toast('Popup nyheten ble oppdatert', {type: 'success'})
                            setEditing(false)
                            return true
                        })
                        .catch(() => {})
                })
        } catch (e) {
            handleErrorWithToast(e)
            setEditing(false)
            return false
        }
    }

    const onDelete = async () => {
        try {
            await deletePopupNewsMutation.mutateAsync({
                '@overridePath': '/api/news_popups/' + selected,
            })
            toast('Popup nyheten ble slettet', {type: 'success'})
            invalidate(['/api/news_popup/all', '/api/news_popups/' + selected]).then(() => {})
            refetchAllPopupNewsItems()
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const breadcrumbs = [
        {
            title: 'SKIL',
            to: '/dashboard/SKIL',
        },
        {
            title: 'Verktøy',
            to: '/dashboard/SKIL/tools',
        },
        {
            title: 'Popup nyheter',
        },
    ]

    const actions: SpecialActionProps[] = [
        {
            label: 'Legg til',
            action: () => setShowCreateDialog(true),
            disabled: false,
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: editing && !isSuccessNewsPopupItem && isFetchingNewsPopupItem ? 'henter data...' : 'Rediger',
            action: () => handleEditDiscount(),
            disabled: !selected,
            icon: {
                position: 'start',
                icon:
                    editing && !isSuccessNewsPopupItem && isFetchingNewsPopupItem ? (
                        <i className={'fa fa-spin fa-spinner'} />
                    ) : (
                        <EditIcon />
                    ),
            },
        },
        {
            label: isDeleting ? 'sletter...' : 'Slett',
            action: () => handleDeleteDiscount(),
            disabled: !selected,
            icon: {
                position: 'start',
                icon: isDeleting ? <i className={'fa fa-spin fa-spinner'} /> : <RemoveIcon />,
            },
        },
    ]

    if (!isSuccess) return <LoadingComponent size={'h4'}>Laster inn..</LoadingComponent>

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <Box>
                <DataGridWrapper rows={allNewsPopups.length} addHeight={12} sx={{height: '100vh'}}>
                    <DataGrid
                        id={'newsPopupDataGrid'}
                        sx={{height: 'auto', '& .MuiDataGrid-main': {height: '50vh'}}}
                        initModel={initModel}
                        loading={isFetching}
                        rows={allNewsPopups}
                        columns={columns}
                        disableSelectionOnClick
                        toolbarButtons={<SpecialActions actions={actions} />}
                    />
                </DataGridWrapper>
                <CreateNewsPopupDialog isOpen={showCreateDialog} onSave={e => onSave(e)} onClose={() => setShowCreateDialog(false)} />
                {newsPopupItem && isSuccessNewsPopupItem && !isFetchingNewsPopupItem && (
                    <EditNewsPopupDialog
                        newsPopupItem={newsPopupItem}
                        isOpen={editing && isSuccessNewsPopupItem && !isFetchingNewsPopupItem}
                        onSave={e => onUpdate(e)}
                        onClose={() => handleCloseEditDialog()}
                    />
                )}
            </Box>
        </Page>
    )
}
