export const REQUEST = '[API] Request'
export const FETCH = '[API] Fetch request'
export const PATCH_ENTITY = '[API] Patch entity'
export const CREATE_ENTITY = '[API] Create entity'
export const CREATE_INDICATOR_DEPENDENCY = '[API] Create indicator dependency'

export const api = (
    url: string,
    type: string,
    method: string = 'GET',
    forceReload: boolean = false,
    body: any = undefined,
    ttl: number | boolean = 60
) => ({
    type: REQUEST,
    payload: {url, type, method, body, ttl},
    meta: {forceReload},
})

export const GET_FORM_INDICATOR_GROUPS = '[API] Get form indicator-groups'
export const GET_FORM_INDICATOR = '[API] Get form indicators'
export const GET_FORM_INDICATOR_DEPENDENCIES = '[API] Get form indicator-dependencies'
export const GET_FORM = '[API] Get form'
export const GET_ANSWER_SET = '[API] Get answer set'
export const DELETED_ENTITY = '[API] Deleted entity'
export const getFormIndicatorGroups = (id, disabled = false) =>
    api(`${id}/indicator_groups?disabled=${disabled}&itemsPerPage=1000`, GET_FORM_INDICATOR_GROUPS)
export const getFormIndicators = (id, disabled = false) =>
    api(`${id}/indicators?disabled=${disabled}&itemsPerPage=1000`, GET_FORM_INDICATOR)
export const getFormIndicatorDependencies = id => api(`${id}/indicator_dependencies?itemsPerPage=1000`, GET_FORM_INDICATOR_DEPENDENCIES)
export const getForm = id => api(`${id}`, GET_FORM)
export const getAnswerSet = (form, task) => api(`/api/answer_sets/find?form=${form}&task=${task}`, GET_ANSWER_SET, 'GET', true)
export const deleteEntity = id => api(id, DELETED_ENTITY, 'DELETE', true)

export const patchEntity = (id, changes) => api(id, PATCH_ENTITY, 'PATCH', true, changes)
export const createEntity = (iri, data) => api(iri, CREATE_ENTITY, 'POST', true, data)
