import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import courseColumnDef from '../../Components/DataGrid/columnDefs/courseColumnDef'
import groupColumnDef from '../../Components/DataGrid/columnDefs/groupColumnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import Button from '../../Components/Button/Button'
import userColumnDef from '../../Components/DataGrid/columnDefs/userColumnDef'
import areaColumnDef from '../../Components/DataGrid/columnDefs/areaColumnDef'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import {CreateGroupDialog} from '../Components/CreateGroupDialog'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import GroupIcon from '@mui/icons-material/Group'
import {toast} from 'react-toastify'
import {CountiesExportToAreaColumnDef} from '../../Components/CountiesExportToAreaColumnDef'
import {useEffect} from 'react'

let renderCell = (params: GridRenderCellParams) => (
    <Button variant='text' to={`/dashboard/groups/${params.id}`}>
        {params.value || <em>mangler navn</em>}
    </Button>
)

const Groups = () => {
    const [showCreateGroup, setShowCreateGroup] = React.useState<boolean>(false)
    const [enableStatistics, setEnableStatistics] = React.useState<boolean>(false)
    const {data, isFetching, refetch, isLoading} = useSkilQuery<'getGroupStatistics'>(
        '/api/groups/statistics',
        {},
        {enabled: enableStatistics, onSuccess: () => setEnableStatistics(false)}
    )
    const createGroupMutation = useSkilMutation<'createGroup'>('POST', '/api/groups', ['/api/groups', '/api/groups/statistics?'])
    const groups = data?.['hydra:member'] ?? []
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const counties = CountiesExportToAreaColumnDef(hasLoaded)

    const columns: GridColDef[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        courseColumnDef({field: 'course.shortTitle'}),
        groupColumnDef({field: 'title', renderCell, headerName: 'Navn'}),
        userColumnDef({field: 'leader', headerName: 'Gruppeleder'}),
        userColumnDef({field: 'counselor', headerName: 'Veileder'}),
        columnDef({field: 'wantCounselor', headerName: 'Ønsker veileder', type: 'boolean'}),
        userColumnDef({field: 'registeredBy', headerName: 'Registrert av'}),
        dateColumnDef({field: 'signupAt', headerName: 'Påmeldt', type: 'date'}),
        dateColumnDef({field: 'createdAt', headerName: 'Opprettet', type: 'date'}),
        columnDef({field: 'description', headerName: 'Beskrivelse'}),
        areaColumnDef({
            field: 'county',
            type: 'county',
            headerName: 'Kommnue',
            counties: counties.map(county => ({label: county.label, value: county.id})),
        }),
        areaColumnDef({
            field: 'county',
            type: 'region',
            headerName: 'Fylke',
            counties: counties.map(county => ({label: county.region, value: county.regionId})),
        }),
        dateColumnDef({field: 'nextMeeting.time', headerName: 'Neste møtetid', type: 'dateTime'}),
        columnDef({field: 'nextMeeting.location', headerName: 'Neste møtested'}),
        columnDef({field: 'participantsCount', headerName: 'Antall deltakere', type: 'number'}),
    ]

    const groupsActions: SpecialActionProps[] = [
        {
            label: 'Opprett gruppe',
            action: () => setShowCreateGroup(true),
            icon: {
                position: 'start',
                icon: <GroupIcon />,
            },
        },
    ]

    const onCreateGroup = async newGroup => {
        try {
            await createGroupMutation.mutateAsync(newGroup).then(() => {
                toast('Gruppen ble opprettet', {type: 'success'})
                refetch()
                    .then(() => {})
                    .catch(() => {})
            })
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && counties?.length > 0) {
            setHasLoaded(true)
        }
    }, [counties])

    useEffect(() => {
        // we only want to get the statistics once
        if (!enableStatistics) {
            setEnableStatistics(true)
        }
    }, [])

    return (
        <Box>
            <DataGridWrapper rows={20}>
                <DataGrid
                    id={'groups'}
                    loading={isFetching}
                    rows={groups}
                    columns={columns}
                    initModel={{
                        columnVisibilityModel: {
                            id: false,
                            registeredBy: false,
                            signupAt: false,
                            createdAt: false,
                            description: false,
                        },
                    }}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={groupsActions} />}
                />
            </DataGridWrapper>
            <CreateGroupDialog isOpen={showCreateGroup} handleSubmit={onCreateGroup} handleClose={() => setShowCreateGroup(false)} />
        </Box>
    )
}

export default Groups
