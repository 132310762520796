import React, {useState, useEffect} from 'react'
import Button from '../Components/Button/Button'
import Panel from '../Components/Panel'
import useEntities from '../Hooks/useEntities'
import LoadingComponent from '../Components/LoadingComponent'
import styled from 'styled-components'
import format from 'date-fns/format'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import {Page} from '../Components/Page'
import useEntity from '../Hooks/useEntity'
import useUser from '../Utilities/useUser'
import SimpleSelectField from '../Components/Fields/SimpleSelectField'

const StyledNewsItem = styled.div`
    margin-bottom: 1.8rem;
    padding: 1.4rem 2rem;
    border-radius: 4px;
    background: white;
    animation: display_set 1.5s linear;
    width: 100%;
    position: relative;
    overflow: hidden;
`
const StyledParagraph = styled.p`
    color: grey;
    margin-top: 1em;
`
const StyledEditIcon = styled.div`
    display: flex;
    justify-content: flex-end;
`

export function getTitleFromContent(value) {
    let heading
    const paragraphs = value.split('</p>')
    let firstParagraph = paragraphs[0] + '</p>'
    let filter9 = firstParagraph?.match(`<p>(.*?)<\/p>`)
    let filter8 = firstParagraph?.match(`<p>(.*?)&nbsp;<\/p>`)
    let filter7 = firstParagraph?.match(`<em>(.*?)<\/em>`)
    let filter6 = firstParagraph?.match(`<em>(.*?)&nbsp;<\/em>`)
    let filter5 = firstParagraph?.match(`<strong>(.*?)<\/strong>`)
    let filter4 = firstParagraph?.match(`<strong>(.*?)&nbsp;<\/strong>`)
    let filter3 = firstParagraph?.match(`">(.*?)<\/em>`)
    let filter2 = firstParagraph?.match(`">(.*?)&nbsp;<\/em>`)
    let filter1 = firstParagraph?.match(`px;">(.*?)<\/`)
    let filter0 = firstParagraph?.match(`px;">(.*?)&nbsp;<\/`)

    if (filter5 && !filter0 && !filter1) {
        heading = filter5[1]
    }
    if (filter5 && filter4 && !filter0 && !filter1) {
        heading = filter4[1]
    }
    if (filter0 && filter1 && !filter6) {
        heading = filter0[1]
    }
    if (!filter0 && filter1 && !filter6) {
        heading = filter1[1]
    }
    if (filter0 && filter1 && filter2 && filter3 && filter5 && filter6 && filter7 && filter9) {
        heading = filter6[1]
    }
    if (!filter0 && !filter1 && !filter2 && !filter3 && !filter4 && !filter5 && !filter6 && !filter7 && !filter8 && filter9) {
        heading = filter9[1]
    }
    if (!filter0 && !filter1 && !filter2 && !filter3 && !filter4 && !filter5 && !filter6 && !filter7 && filter8 && filter9) {
        heading = filter8[1]
    }
    if (filter1 && filter3 && filter5 && !filter7 && filter9) {
        heading = filter5[1]
    }
    if (!filter0 && filter1 && filter3 && filter5 && filter7 && filter9) {
        heading = filter1[1]
    }
    if (filter0 && filter1 && filter3 && filter5 && filter7 && filter9) {
        heading = filter0[1]
    }
    if (filter1 && filter3 && !filter5 && filter7 && filter9) {
        heading = filter7[1]
    }
    if (filter4 && filter5 && filter7 && filter9) {
        heading = filter4[1]
    }
    if (filter6 && filter7 && filter9) {
        heading = filter6[1]
    }
    if (!filter4 && !filter5 && !filter6 && filter7 && filter9) {
        heading = filter7[1]
    }
    if (filter0 && filter1 && filter3 && filter4 && filter5 && filter9) {
        heading = filter4[1]
    }
    if (heading === '<br>') {
        heading = heading.replace('<br>', '')
    }
    return heading
}

export default function News() {
    const {partnershipId} = useParams()
    const news = useEntities<'getPartnershipNewsCollection'>(partnershipId ? `/api/partnerships/${partnershipId}/news` : undefined)

    const showSpinner = !news['@loaded']

    let formatDateTime = date => {
        if (!date) return null

        return format(new Date(date), 'dd/MM/yyyy')
    }

    const breadcrumbs = [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId}, {title: 'Nyheter'}]

    return (
        <Page title={'Nefle nyhetssaker'} breadcrumbs={breadcrumbs} variant={'full'}>
            <Panel variant={'flat'}>
                {partnershipId && (
                    <Panel.Footer>
                        <Button to={`/dashboard/kommuner/${partnershipId}/nyheter/new`} aria-label={'Legg til nyhet'}>
                            <i className={'fa fa-pen'} /> Legg til nyhet
                        </Button>
                    </Panel.Footer>
                )}
            </Panel>
            {showSpinner && <LoadingComponent />}
            {news.map((item, index) => (
                <Panel variant={'flat'} key={item.id}>
                    <Panel.Body>
                        <StyledNewsItem key={index}>
                            <StyledEditIcon>
                                <Button confirm={'Er du sikker?'} onClick={() => news.remove(item['@id']!)} aria-label={'Slett nyhet'}>
                                    <i className={'fa fa-trash'} /> Slett
                                </Button>
                                <Button to={`/dashboard/kommuner/${partnershipId}/nyheter/${item.id}`} aria-label={'Rediger nyhet'}>
                                    <i className={'fa fa-pen'} /> Rediger
                                </Button>
                            </StyledEditIcon>
                            <div dangerouslySetInnerHTML={{__html: item?.article}} />
                            <StyledParagraph>{formatDateTime(item?.createdAt)}</StyledParagraph>
                        </StyledNewsItem>
                    </Panel.Body>
                </Panel>
            ))}
        </Page>
    )
}
