import React from 'react'

const ProgressBar = ({current, max, text = '', size = 'lg', color = 'primary'}) => {
    const width = (current / max) * 100

    return (
        <div className='progress-group'>
            {text && <span className='progress-text'>{text}</span>}
            <span className='progress-number'>
                &nbsp;<b>{current}</b>&nbsp;/&nbsp;<small>{max}</small>
            </span>

            <div className={`progress ${size}`}>
                <div className={`progress-bar progress-bar-${color}`} style={{width: `${width}%`}} />
            </div>
        </div>
    )
}
export default ProgressBar
