import * as React from 'react'
import {Link} from 'react-router-dom'
import {Box} from '../../Components/Panel'
import {ROUTES} from '../constants/routes'
import Button from '../../Components/Button/Button'
import Stack from '@mui/system/Stack'

const Tools = () => {
    return (
        <Box>
            <h4 style={{marginLeft: 10}}>SKIL</h4>
            <a className='btn btn-app' target='_blank' href='https://oppslag.visma.no/handboker/no/personalhandbok/Pages/default.aspx'>
                <i className='fa fa-book'></i>
                Personalhåndbok
            </a>
            <a
                className='btn btn-app'
                target='_blank'
                href='https://skil-my.sharepoint.com/:o:/g/personal/camilla_skilnet_no/EiFwiZaE4ehDsCirF-9DKvMBp-e2ebRAeYBVAggB-z5UjA?e=BQIR1V'
            >
                <i className='fas fa-calendar-alt'></i> Fredagsmøte
            </a>
            <a className='btn btn-app' target='_blank' href='https://skilnet.atlassian.net/jira'>
                <i className='fab fa-jira'></i>Jira
            </a>
            <h4 style={{marginLeft: 10}}>ePortal</h4>
            <a className='btn btn-app' href='/dashboard/SKIL/forms'>
                <i className='fa fa-check'></i> Skjema
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/massmailing'>
                <i className='fa fa-envelope'></i> E-post masseutsendelse
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/news_popups'>
                <i className='fa fa-newspaper'></i> Popup nyheter
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/nefle/discount_codes'>
                <i className='fa fa-tag'></i> Nefle rabattkoder
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/userroles'>
                <i className='fa fa-user-tag'></i> Roller
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/indicators'>
                <i className='fa fa-question'></i> Indikatorer
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/users/map'>
                <i className='fa fa-map'></i> Kart
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/files'>
                <i className='fa fa-file'></i> Filer
            </a>
            <a className='btn btn-app' href='/dashboard/helfo'>
                <i className='far fa-hospital'></i> Helfo
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/zingtree'>
                <i className='fas fa-align-justify'></i> Zingtree
            </a>
            <Link className='btn btn-app' to={ROUTES.REPORTS_NEFLE_USAGE}>
                <i className='fas fa-browser'></i> Nefle-bruk
            </Link>
            <a className='btn btn-app' href='/dashboard/SKIL/duplicate_offices'>
                <i className='fas fa-clinic-medical'></i> Duplikate legekontor
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/duplicate_helfo_offices'>
                <i className='fas fa-clinic-medical'></i> Duplikate helfokontor
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/duplicate_groups'>
                <i className='fas fa-clinic-medical'></i> Duplikate grupper
            </a>

            <h4 style={{marginLeft: 10}}>Programmer</h4>
            <a className='btn btn-app' href='https://portal.office.com/OLS/MySoftware.aspx'>
                <i className='fa fa-cloud-download-alt'></i>Office
            </a>
            <a
                className='btn btn-app'
                href='https://c2rsetup.officeapps.live.com/c2r/download.aspx?productReleaseID=SkypeforBusinessEntryRetail&platform={X86}&language={en-us}&source=O16O365&version=O16GA'
            >
                <i className='fas fa-cloud-download-alt'></i> Skype
            </a>
            <a className='btn btn-app' href='http://teams.microsoft.com'>
                <i className='fab fa-microsoft'></i> Teams
            </a>

            <h4 style={{marginLeft: 10}}>Teknisk</h4>
            <a className='btn btn-app' href='/dashboard/SKIL/oidc'>
                <i className='fab fa-openid'></i> OpenID
            </a>
            <a className='btn btn-app' href='https://portal.office.com/adminportal/home'>
                <i className='fab fa-microsoft'></i> Admin
            </a>
            <a className='btn btn-app' href='/dashboard/SKIL/easygenerator'>
                <i className='far fa-file-video'></i> EasyGenerator
            </a>
            <a className='btn btn-app' href='https://grafana.skil-sky.no/login'>
                <i className='far fa-monitor-heart-rate'></i> Grafana
            </a>
            <a className='btn btn-app' href='https://dashboard.skil-sky.no'>
                <i className='far fa-dharmachakra'></i> Kubernetes
            </a>
            <Stack my={2} ml={1.25} direction='row' spacing={2}>
                <Button variant={'primary'} to={'/dashboard/course/LMG/gfredgesraer'}>
                    Test 404 React Router
                </Button>
                <Button variant={'primary'} to={'/dashboard/offices/79/settings'}>
                    Test Office settings React Router
                </Button>
                <Button variant={'primary'} to={'/signup/complete/welcome'}>
                    Test unsupported route
                </Button>
            </Stack>
        </Box>
    )
}

export default Tools
