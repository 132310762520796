import React, {useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useQuery} from 'react-query'
import LoadingComponent from './Components/LoadingComponent'
import {NotFoundPage} from './Components/NotFoundPage'
import {Page} from './Components/Page'
import {Box} from './Components/Panel'
import PurePage from './Components/PurePage'

const fetchSymfonyContent = async path => {
    const response = await fetch(path, {
        method: 'GET',
        headers: {Accept: 'text/html'},
    })
    const text = await response.text()

    let type: null | string | RegExpMatchArray = text.match(/id="html-twig" content="(.+)"/)
    if (type) {
        type = type[1] ?? null
    }

    const statusCode = response.status
    const el = document.createElement('body')
    el.innerHTML = text
    let content = el.querySelector('section.content')?.outerHTML
    if (!content) {
        content = el.querySelector('section.container-fluid')?.outerHTML
    }

    if (statusCode >= 400) {
        throw new Error('Unsupported status code')
    }

    // check if the only supported base twig file is found
    if (type !== 'base' && type !== 'react' && type !== 'pure-react') {
        throw new Error('No supported twig file found')
    }
    const javascripts = el.querySelectorAll("div[id='page-included-javascript'] script")
    //TODO: Check if page contain legacy javascripts, and we have not loaded it yet (then remove javascript from pure_react.html.twig)

    return {content, javascripts, type}
}

export const PageNotFound = () => {
    const location = useLocation()
    const path = location.pathname
    const contentRef = useRef<HTMLDivElement>(null)

    const {data, isLoading} = useQuery(path, () => fetchSymfonyContent(path), {
        // Config is extremely important to ensure contentRef is available before onSuccess

        retry: false,
        refetchOnMount: 'always',
        cacheTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false,

        onError: err => {
            window.location.reload()
        },
        onSuccess: ({javascripts, content, type}) => {
            if (type === 'react' || type === 'pure-react') {
                return {content: undefined, javascripts: undefined, type}
            }

            //TODO: Replace all <a /> elements with <Link /> elements

            // @ts-expect-error
            contentRef.current.innerHTML = content ?? ''

            javascripts.forEach(script => {
                // add page specific javascript
                let scriptEl = document.createElement('script')
                scriptEl.type = 'text/javascript'
                scriptEl.innerHTML = script.innerHTML
                document.body.appendChild(scriptEl)
            })

            // initialize initComponent
            let initScript = document.createElement('script')
            initScript.type = 'text/javascript'
            initScript.innerHTML = '$(document).ready(function () {\n' + 'initComponents()\n' + '    })'
            document.body.appendChild(initScript)
        },
    })

    return (
        <>
            {isLoading && <LoadingComponent>Dette kan ta litt tid...</LoadingComponent>}
            <div ref={contentRef} />

            {!isLoading && data?.content == undefined && data?.type !== 'pure-react' && <NotFoundPage />}
            {!isLoading && data?.type === 'pure-react' && (
                <PurePage breadcrumbs={[{title: 'Siden ble ikke funnet'}]}>
                    <NotFoundPage />
                </PurePage>
            )}
        </>
    )
}
