import * as React from 'react'
import {useState} from 'react'
import {Button} from '../../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {SetState} from '../../../Utilities/TypeHelpers'
import {DoctorType} from './Doctor'
import {DatePicker} from '@mui/x-date-pickers-pro'

interface DoctorInviteDialogProps {
    doctor: DoctorType
    refetchInvitations: any
    setOpen: SetState<boolean>
    isOpen: boolean
}

export const DoctorInviteDialog = ({doctor, refetchInvitations, setOpen, isOpen = false}: DoctorInviteDialogProps) => {
    const {isLoading, data} = useSkilQuery<'getPraksisnettStudyCollection'>('/api/praksisnett/studies')
    const createInvitation = useSkilMutation<'createPraksisNettInvitationItem'>('post', '/api/praksisnett/invitations', [
        '/api/praksisnett/invitations',
    ])
    const [acceptedAt, setAcceptedAt] = useState<string | null>(null)
    const [invoicePaidAt, setInvoicePaidAt] = useState<string | null>(null)
    const [invoiceReceivedAt, setInvoiceReceivedAt] = useState<string | null>(null)
    const [amountPaid, setAmountPaid] = useState<string>('')
    const [selectedStudyId, setSelectedStudyId] = useState<string>('')
    const [invoiceNumber, setInvoiceNumber] = useState<string | null>(null)
    const [invoiceReminderAt, setInvoiceReminderAt] = useState<string | null>(null)
    const [invoiceNote, setInvoiceNote] = useState<string | null>(null)

    const studies = data?.['hydra:member'].map(study => ({...study, label: study.name})) ?? []

    const clearState = () => {
        // TODO: there must be a nicer way...
        setAcceptedAt(null)
        setInvoicePaidAt(null)
        setInvoicePaidAt(null)
        setAmountPaid('')
        setSelectedStudyId('')
        setInvoiceNumber(null)
        setInvoiceReminderAt(null)
        setInvoiceNote(null)
    }

    const handleDialogClose = () => {
        setOpen(false)
    }

    const handleSubmitInvite = async () => {
        try {
            await createInvitation.mutateAsync({
                // @ts-expect-error
                id: undefined,
                doctor: doctor['@id'],
                study: selectedStudyId,
                changelogs: [],
                acceptedAt,
                compensation: {
                    invoiceReceivedAt,
                    invoicePaidAt,
                    amountPaid: parseFloat(amountPaid),
                    invoiceNumber,
                    invoiceReminderAt,
                    invoiceNote,
                },
            })
            toast(`${doctor.name} er lagt til studien.`, {
                type: 'success',
            })
            refetchInvitations()
            handleDialogClose()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    return (
        <Dialog maxWidth='md' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogContent>
                <Typography>Legg {doctor.name} til i en studie. Du kan også velge dato, fakturering og betalingsstatus.</Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            id='study'
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            value={studies.find(study => study['@id'] === selectedStudyId)}
                            onChange={(event: any, newValue) => setSelectedStudyId(newValue['@id']!)}
                            disableClearable
                            renderInput={params => (
                                <TextField
                                    required
                                    {...params}
                                    label='Studie'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <MenuItem component='li' {...props}>
                                    {option.name}
                                </MenuItem>
                            )}
                            options={studies}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            id='invoiceNumber'
                            fullWidth
                            value={invoiceNumber}
                            label='Fakturanummer'
                            type={'string'}
                            onChange={event => {
                                setInvoiceNumber(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DatePicker
                            label='Godtatt på'
                            value={acceptedAt}
                            onChange={value => setAcceptedAt(value)}
                            renderInput={params => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DatePicker
                            label='Faktura mottatt på'
                            value={invoiceReceivedAt}
                            onChange={value => setInvoiceReceivedAt(value)}
                            renderInput={params => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DatePicker
                            label='Faktura betalt på'
                            value={invoicePaidAt}
                            onChange={value => setInvoicePaidAt(value)}
                            renderInput={params => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            id='amountPaid'
                            label='Betalt'
                            value={amountPaid}
                            fullWidth
                            defaultValue={amountPaid}
                            onChange={event => {
                                setAmountPaid(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DatePicker
                            label='Påminnelse på'
                            value={invoiceReminderAt}
                            onChange={value => setInvoiceReminderAt(value)}
                            renderInput={params => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            id='invoiceNote'
                            fullWidth
                            value={invoiceNote}
                            onChange={event => {
                                setInvoiceNote(event.target.value)
                            }}
                            label='Faktura merknad'
                            type={'string'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={handleDialogClose}>
                    Avbryt
                </Button>
                <Button disabled={!selectedStudyId} onClick={handleSubmitInvite}>
                    Legg til studie
                </Button>
            </DialogActions>
        </Dialog>
    )
}
