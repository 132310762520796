import React, {useState} from 'react'
import Modal from '../Components/Modal'
import SelectField from '../Components/Fields/SelectField'
import useEntities from '../Hooks/useEntities'
import useUser from '../Utilities/useUser'
import {SkilQueryResponseType} from '../Utilities/QueryClient'
import DateTimeField from '../Components/Fields/DateTimeField'
import TextField from '../Components/Fields/TextField'
import PhoneField from '../Components/Fields/PhoneField'

type Employee = SkilQueryResponseType<'getOfficeEmployees'>['hydra:member'][number]

const NewConsultationForm = ({onSubmit, onClose, officeIri}) => {
    const user = useUser()
    const [phonenumber, setPhonenumber] = useState('')
    const [name, setName] = useState('')
    const [meetingAt, setMeetingAt] = useState<Date | null>(null)
    const [message, setMessage] = useState('Vennligst vent, legen kommer snart')
    const employees = useEntities<'getOfficeEmployees'>(officeIri + '/employees')
    const [doctor, setDoctor] = useState<Employee | undefined>(() => employees.find(e => e.user!['@id'] === user.iri))

    const onHide = () => {
        setPhonenumber('')
        setMeetingAt(new Date())
        setName('')
        setMessage('Vennligst vent, legen kommer snart')
        setDoctor(employees.find(e => e.user === user.iri))
        onClose()
    }
    const onSave = () => {
        onSubmit({phonenumber, meetingAt, name, doctor: doctor?.user?.['@id'], message})
    }

    return (
        <Modal title={'Ny konsultasjon'} onClose={onHide} onSave={onSave}>
            <form>
                <DateTimeField id='meeting' label='Tidspunkt' value={meetingAt} onChange={setMeetingAt} required />
                <TextField
                    id='name'
                    type='text'
                    label='Pasientens navn'
                    placeholder='Ola Jensen'
                    value={name}
                    onChange={setName}
                    required={false}
                />
                <PhoneField
                    id='phonenumber'
                    label='Pasientens mobilnummer'
                    placeholder='123 45 123'
                    required
                    value={phonenumber}
                    onChange={setPhonenumber}
                />
                <TextField
                    id='message'
                    type='text'
                    label='Melding til pasient'
                    value={message}
                    onChange={setMessage}
                    help={'Vises mens pasient venter på lege på Nefle Video'}
                />
                <SelectField<Employee>
                    id='doctor'
                    label='Lege'
                    // @ts-expect-error
                    entities={employees.map(e => e.user)}
                    value={doctor?.user?.['@id']}
                    required={false}
                    onChange={doctor => setDoctor(employees.find(e => e.user!['@id'] === doctor['@id']))}
                    help={'Valgfritt'}
                />
            </form>
        </Modal>
    )
}

export default NewConsultationForm
