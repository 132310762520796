import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import courseColumnDef from '../../Components/DataGrid/columnDefs/courseColumnDef'
import taskColumnDef from '../../Components/DataGrid/columnDefs/taskColumnDef'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import Button from '../../Components/Button/Button'
import {TRIGGER_OPTIONS} from '../constants/jobTriggers'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import GroupIcon from '@mui/icons-material/Group'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {CreateJobDialog} from '../Components/CreateJobDialog'
import {useNavigate} from 'react-router-dom'

const renderCell = params => {
    let value = params.formattedValue ?? params.value ?? 'Ingen navn'

    return (
        <Button variant='text' to={`/dashboard/SKIL/jobs/${params.row.id}`}>
            {value}
        </Button>
    )
}
const renderDescriptionCell = params => {
    let value = params.formattedValue ?? params.value ?? 'Ingen navn'

    return <span dangerouslySetInnerHTML={{__html: value}}></span>
}

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'ID', type: 'number'}),
    selectColumnDef({field: 'trigger', headerName: 'Hendelse', valueOptions: TRIGGER_OPTIONS, renderCell}),
    taskColumnDef({field: 'task'}),
    courseColumnDef({field: 'course.shortTitle'}),
    columnDef({field: 'description', headerName: 'Beskrivelse', renderCell: renderDescriptionCell}),
    columnDef({field: 'title', headerName: 'Tittel'}),
    columnDef({field: 'content', headerName: 'Innhold'}),
]

const Jobs = () => {
    const [showCreateJob, setShowCreateJob] = React.useState<boolean>(false)
    const {data, isFetching, refetch} = useSkilQuery<'getJobCollection'>('/api/jobs', {itemsPerPage: 1000})
    const createJobMutation = useSkilMutation<'createJob'>('POST', '/api/jobs', ['/api/jobs'])
    const jobs = data?.['hydra:member'] ?? []
    const navigate = useNavigate()

    const onCreateJob = async newJob => {
        try {
            const job = await createJobMutation.mutateAsync(newJob)
            toast('Jobben ble opprettet', {type: 'success'})
            navigate(`/dashboard/SKIL/jobs/${job.id}`)
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }
    const jobsAction: SpecialActionProps[] = [
        {
            label: 'Opprett automatisk epost',
            action: () => setShowCreateJob(true),
            icon: {
                position: 'start',
                icon: <GroupIcon />,
            },
        },
    ]

    return (
        <Box>
            <DataGridWrapper rows={jobs.length}>
                <DataGrid
                    id={'jobs'}
                    loading={isFetching}
                    rows={jobs}
                    columns={columns}
                    initModel={{columnVisibilityModel: {id: false, content: false, description: false}}}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={jobsAction} />}
                />
            </DataGridWrapper>
            <CreateJobDialog isOpen={showCreateJob} handleClose={() => setShowCreateJob(false)} handleSubmit={onCreateJob} />
        </Box>
    )
}

export default Jobs
