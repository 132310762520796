import * as React from 'react'
import Button from '../../Button/Button'
import {GridCellParams, GridColDef} from '@mui/x-data-grid-premium'
import {sortComparator, customValueGetter} from './columnDef'

const renderCell = params => {
    if (!params.value) return <span>ingen kurs</span>

    return (
        <Button variant='text' href={`/dashboard/course/${params.value}/settings`}>
            {params.value}
        </Button>
    )
}

const getApplyCourseQuickFilterFn = (value: string) => {
    if (!value) {
        return null
    }
    return (params: GridCellParams): boolean => {
        // the params value will be missing when the row is regarding a trigger which is course independent
        if (!params.value) return false
        let lowerCaseParamValue = params.value.toLowerCase()
        let lowerCaseSearchValue = value.toLowerCase()
        return params.value ? lowerCaseParamValue.includes(lowerCaseSearchValue) : false
    }
}

type Column = GridColDef & {
    type?: 'course_type'
}
export default function courseColumnDef(col: Column): GridColDef {
    let valueGetter = customValueGetter(col.valueGetter)

    return {
        flex: 1,
        type: 'string',
        headerName: 'Kurs',
        sortComparator,
        renderCell,
        getApplyQuickFilterFn: getApplyCourseQuickFilterFn,
        ...col,
        valueGetter,
    }
}
