import * as React from 'react'
import {Box} from '@mui/material'
import QualitySeminarGoal24 from './QualitySeminarGoal24'
import NewParticipantsGoal24 from './NewParticipantsGoal24'
import NewUserCoursesGoal24 from './NewUserCoursesGoal24'
import QualitySeminarsGoal24 from './QualitySeminarsGoal24'
import NefleStats from './NefleStats'
import Type0CourseCompletions from './Type0CourseCompletions'
import Grid from '@mui/material/Grid'
import Type1CourseCompletions from './Type1CourseCompletions'
import Type2CourseCompletions from './Type2CourseCompletions'

export const Goals24 = () => {
    return (
        <>
            <Grid container spacing={1} mb={3} mt={5}>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <QualitySeminarGoal24 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <NewParticipantsGoal24 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <NewUserCoursesGoal24 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <QualitySeminarsGoal24 />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <NefleStats />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <Type0CourseCompletions />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <Type1CourseCompletions />
                </Grid>
                <Grid display={'flex'} width={'100%'} maxWidth={'100%'} xs={12} sm={4} md={3} item>
                    <Type2CourseCompletions />
                </Grid>
            </Grid>
        </>
    )
}
