import React, {useState} from 'react'
import Panel from '../Components/Panel'
import useEntities from '../Hooks/useEntities'
import Button from '../Components/Button/Button'
import NewConsultationForm from './NewConsultationForm'
import useEntity from '../Hooks/useEntity'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import addSeconds from 'date-fns/addSeconds'
import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import EditConsultationForm from './EditConsultationForm'
import {nb} from 'date-fns/locale'
import EventLogModal from './EventLogModal'
import {isSupported} from 'twilio-video'
import Alert from '../Components/Alert'
import TextModal from './TextModal'
import DeleteConsultationModal from './DeleteConsultationModal'
import usePhoneFormatter from '../Components/usePhoneFormatter'
import PhoneField from '../Components/Fields/PhoneField'
import ParticipantsModal from './ParticipantsModal'
import usePushNotifications from './usePushNotifications'
import {useParams} from 'react-router'
import {Page} from '../Components/Page'
import useUser from '../Utilities/useUser'
import {SkilQueryResponseType, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {Box, Grid, TextField} from '@mui/material'
import {DateRange, DesktopDateRangePicker} from '@mui/x-date-pickers-pro'
import MenuItem from '@mui/material/MenuItem'

type Employee = SkilQueryResponseType<'getOfficeEmployees'>['hydra:member'][number]
type Consultation = SkilQueryResponseType<'getConsultationItem'>

const initDateRange = (): [Date, Date] => {
    const from = new Date()
    from.setHours(0, 0, 0, 0)
    let to = new Date()
    to.setHours(23, 59, 59, 0)
    to = addDays(to, 1)
    return [from, to]
}

export default function Waitingroom() {
    const {officeId} = useParams()
    const [[from, to], setDateRange] = useState<DateRange<Date>>(initDateRange)
    const user = useUser()
    const office = useEntity<'getOfficeItem'>(`/api/offices/${officeId}`)
    const employees: Array<Employee> = useEntities<'getOfficeEmployees'>(`/api/offices/${officeId}/employees`)
    const [showNewConsultationModal, setShowNewConsultationModal] = useState(false)
    const [{defaultContent, showSendText, newIri}, setShowSendTextModal] = useState({defaultContent: '', showSendText: false, newIri: ''})
    const [selectedDoctor, setSelectedDoctor] = useState<Employee | undefined>(() => employees.find(e => e.user!.id === user.id))
    const [searchPhone, setSearchPhone] = useState('')
    const hideWebSupport = !!window.localStorage.getItem('hide-web-support')
    const {requestPermission, isRegistered, isSupported: isPushNotificationsSupported, deleteDevice, isLoading} = usePushNotifications()
    const consultationsQuery = useSkilQuery<'getOfficeConsultations'>(
        selectedDoctor ? `${selectedDoctor.user!['@id']}/consultations` : `/api/offices/${officeId}/consultations`,
        {'meetingAt[after]': from, 'meetingAt[before]': to, phonenumber: searchPhone},
        {refetchInterval: 15000, refetchOnWindowFocus: 'always', cacheTime: 15000}
    )
    const createConsultationMutation = useSkilMutation<'createConsultation'>('POST', '/api/consultations', [
        selectedDoctor ? `${selectedDoctor.user!['@id']}/consultations` : null,
        `/api/offices/${officeId}/consultations`,
    ])
    const consultations: Array<Consultation> = consultationsQuery.data?.['hydra:member'] ?? []

    const onChangeDateRange = ([from, to]) => {
        to = addHours(to, 23)
        to = addMinutes(to, 59)
        to = addSeconds(to, 59)
        setDateRange([from, to])
    }

    const onChangeDoctor = e => {
        const iri = e.target.value
        setSelectedDoctor(employees.find(e => e['@id'] === iri))
    }

    const onCreatePartnership = async form => {
        setShowNewConsultationModal(false)
        form.office = office['@id']

        const result = await createConsultationMutation.mutateAsync(form)
        const meetingAt = new Date(result.meetingAt!)
        const date = format(meetingAt, 'dd.MM')
        const time = format(meetingAt, 'HH:mm')

        let content = `Du har fått videokonsultasjon hos oss ${date} kl ${time}.\n\nGå til https://v.nefle.no/c/${result.entryCode} litt før med PC/mac/telefon og oppdatert nettleser`
        if (result.doctorLastName)
            content = `Tildelt videokonsultasjon hos Dr. ${result.doctorLastName} ${date} kl ${time}.\n\nGå til https://v.nefle.no/c/${result.entryCode} litt før med PC/mac/mobil.`

        setShowSendTextModal({defaultContent: content, showSendText: true, newIri: result['@id']!})
        return consultationsQuery.refetch()
    }

    const onHideAlert = key => () => {
        window.localStorage.setItem(key, 'true')
    }

    const breadcrumbs = [
        {title: office?.name ?? '', path: `/dashboard/offices/${officeId}`},
        {title: 'Venterom', path: ''},
    ]

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            {!hideWebSupport && !isSupported && (
                <Alert variant={'danger'} onDismiss={onHideAlert('hide-web-support')}>
                    Nettleseren din har ikke støtte for videkonsultasjoner! Vennligst bruk Firefox eller Chrome på PC/Mac, Chrome på Android
                    eller Safari på iOS.
                </Alert>
            )}
            {!isRegistered && !isLoading && isPushNotificationsSupported && (
                <Panel variant={'primary'} title={'Notifikasjoner'}>
                    <Panel.Body>
                        <p>Jobb smartere, motta notifikasjon når pasienten er på venteromet.</p>

                        <Button variant={'primary'} onClick={requestPermission}>
                            Aktiver notifikasjoner for dine videokonsultasjoner på denne enheten.
                        </Button>
                    </Panel.Body>
                </Panel>
            )}
            <Panel variant={'primary'} title={'Digitalt Venterom'}>
                <Panel.Footer className={'form-inline'}>
                    <Grid container spacing={2} justifyContent={'space-between'}>
                        <Grid item>
                            <Button onClick={() => setShowNewConsultationModal(true)}>Ny konsultasjon</Button>
                        </Grid>
                        <Grid item flexGrow={1} />
                        <Grid item flexGrow={0.5}>
                            <TextField
                                select
                                fullWidth
                                label={'Lege'}
                                value={selectedDoctor?.['@id'] ?? ''}
                                onChange={onChangeDoctor}
                                required={false}
                            >
                                <MenuItem value={''}>Alle</MenuItem>
                                {employees.map(e => (
                                    <MenuItem key={e['@id']} value={e['@id']}>
                                        {e.user?.name!}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <DesktopDateRangePicker
                                value={[from, to]}
                                onChange={value => {
                                    onChangeDateRange(value)
                                }}
                                renderInput={(startProps, endProps) => (
                                    <>
                                        <TextField {...startProps} />
                                        <Box sx={{mx: 2}}> to </Box>
                                        <TextField {...endProps} />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                    {showSendText && (
                        <TextModal
                            defaultContent={defaultContent}
                            iri={newIri}
                            onClose={() => setShowSendTextModal({newIri: '', defaultContent: '', showSendText: false})}
                        />
                    )}
                </Panel.Footer>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>Tidspunkt</th>
                            <th className={'hidden-xs'}>Lege</th>
                            <th>Pasient</th>
                            <th className={'hidden-xs'}>Telefon</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {consultations && consultations.map((e: object) => <ConsultationRow key={e['@id']} iri={e['@id']} />)}
                        {!consultations ||
                            (consultations.length === 0 && (
                                <tr>
                                    <td colSpan={6} className={'text-center text-muted'}>
                                        Ingen konsultasjoner
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <Panel.Footer>
                    <Grid container spacing={2} justifyContent={'space-between'}>
                        <Grid item>
                            <Button onClick={() => setShowNewConsultationModal(true)}>Ny konsultasjon</Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <PhoneField id='doctor' value={searchPhone} onChange={setSearchPhone} label={'Søk telefon:'} />
                        </Grid>
                    </Grid>
                </Panel.Footer>
            </Panel>
            {showNewConsultationModal && (
                <NewConsultationForm
                    officeIri={office['@id']}
                    onClose={() => setShowNewConsultationModal(false)}
                    onSubmit={onCreatePartnership}
                />
            )}
            {isRegistered && (
                <h5 className={'text-center'}>
                    <i className={'fa fa-bell'} /> Notifikasjoner er aktivert for dine videokonsultasjoner på denne enheten.{' '}
                    <Button variant={'text'} inline confirm={'Er du sikker'} onClick={deleteDevice}>
                        Deaktiver
                    </Button>
                </h5>
            )}

            {!isPushNotificationsSupported && (
                <h5 className={'text-danger text-center'}>
                    <i className={'fa fa-exclamation-triangle'} /> Nettleseren din har ikke støtte for notifikasjoner, vennligst bruk Chrome
                    eller Firefox
                </h5>
            )}
        </Page>
    )
}

const ConsultationRow = React.memo(function ConsultationRow({iri}: {iri: string}) {
    const [showEdit, setShowEdit] = useState(false)
    const [showParticipants, setShowParticipants] = useState(false)
    const [showEvents, setShowEvents] = useState(false)
    const [defaultContent, setDefaultContent] = useState('')
    const [showSendText, setShowSendText] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const e = useEntity<'getConsultationItem'>(iri)
    const doctor = useEntity<'getUserItem'>(e.doctor)
    const formatPhone = usePhoneFormatter()
    const {formatted, valid} = formatPhone(e.phonenumber)
    const participants = e.participants ?? []
    if (e && e['@status'] === 'DELETED') return null

    const meetingAt = e.meetingAt ? new Date(e.meetingAt) : null
    const meeting = meetingAt ? formatRelative(meetingAt, new Date(), {locale: nb}) : ''

    const onSendSms = () => {
        const dato = format(meetingAt!, 'dd.MM')
        const time = format(meetingAt!, 'HH:mm')
        const content = `Din videokonsultasjon er ${dato} kl ${time}.\n\nGå til https://v.nefle.no/c/${e.entryCode} litt før med PC/mac/telefon og oppdatert nettleser`

        setDefaultContent(content)
        setShowSendText(true)
    }

    const onCloseTextModal = () => {
        setDefaultContent('')
        setShowSendText(false)
    }

    const onlineParticipantsCount = participants.filter(p => ParticipantState(p) === 'connected').length

    const freshParticipants = participants.filter((p: Consultation['participants'][number]) => {
        const now = Date.now()
        const timeoutAt = now - 1000 * 60 * 5 // 5 minutes ago
        const state = ParticipantState(p)

        const isRecent = p.lastPingAt ? new Date(p.lastPingAt).getTime() > timeoutAt : false
        const isConnected = state === 'connected'
        const isDisconnected = state === 'disconnected'
        const isError = state === 'error'

        return isConnected || (!isDisconnected && isRecent && isError)
    })

    const roomHref = `https://v.nefle.no/r/${e.room}`

    let trClassName = 'text-muted'
    if (onlineParticipantsCount === 1) trClassName = 'warning'
    if (onlineParticipantsCount >= 2) trClassName = 'success'

    return (
        <>
            <tr className={trClassName}>
                <td>
                    {meeting}
                    {freshParticipants.length > 0 && (
                        <button
                            type='button'
                            className='btn btn-link  btn-inline dropdown-toggle'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            onClick={() => setShowParticipants(!showParticipants)}
                        >
                            {freshParticipants.map(p => (
                                <UserIcon iri={p['@id']} key={p['@id']} />
                            ))}
                            <span className='caret' />
                        </button>
                    )}
                    <span className={'visible-xs-block'}>{doctor.name}</span>
                </td>
                <td className={'hidden-xs'}>{doctor.name}</td>
                <td>
                    {e.name}
                    <span className={'visible-xs-block'}>
                        {!valid && <i title={'Telefonummeret er ugylding'} className={'fa fa-exclamation-triangle'} />} {formatted}
                    </span>
                </td>
                <td className={'hidden-xs'}>
                    {!valid && <i title={'Telefonummeret er ugylding'} className={'fa fa-exclamation-triangle'} />} {formatted}
                </td>
                <td className={'text-right'}>
                    <div className='btn-group'>
                        {e.active ? (
                            <a href={roomHref} className='btn btn-link  btn-inline' target='_blank'>
                                Åpne rom
                            </a>
                        ) : (
                            <Button inline variant='link' onClick={() => setShowEdit(true)}>
                                Endre
                            </Button>
                        )}

                        <button
                            type='button'
                            className='btn btn-link  btn-inline dropdown-toggle'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                        >
                            Mer <span className='caret' />
                        </button>

                        <ul className='dropdown-menu'>
                            {e.active && (
                                <li>
                                    <Button href={roomHref} variant='link' target='_blank'>
                                        Åpne rom
                                    </Button>
                                </li>
                            )}
                            <li>
                                <Button variant={'link'} onClick={() => setShowEdit(true)}>
                                    Endre
                                </Button>
                            </li>
                            <li>
                                <Button variant={'link'} onClick={() => setShowDeleteModal(true)}>
                                    Slett
                                </Button>
                            </li>
                            <li>
                                <Button variant={'link'} onClick={() => setShowEvents(true)}>
                                    Hendelser
                                </Button>
                            </li>
                            <li>
                                <Button variant={'link'} onClick={() => setShowParticipants(true)}>
                                    Deltakere
                                </Button>
                            </li>
                            <li role='separator' className='divider' />
                            <li>
                                <Button variant={'link'} onClick={onSendSms}>
                                    Send sms
                                </Button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>

            {showEvents && <EventLogModal iri={e['@id']} onClose={() => setShowEvents(false)} />}
            {showSendText && <TextModal onClose={onCloseTextModal} defaultContent={defaultContent} iri={e['@id']} />}
            {showDeleteModal && <DeleteConsultationModal onClose={() => setShowDeleteModal(false)} iri={e['@id']} />}
            {showEdit && <EditConsultationForm iri={e['@id']} onClose={() => setShowEdit(false)} />}
            {showParticipants && <ParticipantsModal iri={e['@id']} onClose={() => setShowParticipants(false)} />}
        </>
    )
})

export const ParticipantState = p => {
    if (!p) return null

    const now = Date.now()
    const lastPingAt = p.lastPingAt ? new Date(p.lastPingAt) : null
    const timeoutAt = now - 1000 * 30 // 30sec
    const isTimedOut = lastPingAt ? lastPingAt.getTime() < timeoutAt : false

    if (p.disconnectedAt || p.state === 'disconnected') return 'disconnected'

    if (p.error || (isTimedOut && p.state === 'connected')) return 'error'

    if (p.state === 'connected') return 'connected'

    return 'unknown'
}

const UserIcon = ({iri}) => {
    const p = useEntity<'getConsultationParticipantItem'>(iri)
    const state = ParticipantState(p)

    let className = 'text-muted'
    if (state === 'disconnected') className = 'text-muted'
    if (state === 'error') className = 'text-danger'
    if (state === 'connected') className = 'text-success'

    const icon = p.patient ? 'fa-user' : 'fa-user-md'
    return <i className={`fa ${icon} ${className}`} title={p.name} />
}

// @ts-expect-error
const CustomDateInput = React.forwardRef(({value, onClick}, ref) => (
    // @ts-expect-error
    <Button ref={ref} variant={'link'} onClick={onClick}>
        {value}
    </Button>
))
