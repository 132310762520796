import React, {ChangeEventHandler} from 'react'
import styled from 'styled-components'

const StyledCheckbox = styled.div`
    &&& input {
        margin-left: 0;
        position: inherit;
        width: 1.5em;
        height: 1.5em;
    }
`

type Props = {
    checked: boolean
    disabled: boolean
    onChange: ChangeEventHandler<HTMLInputElement>
    id?: string
    children: React.ReactNode
}
export default function CheckboxControl({checked, onChange, disabled, id, children}: Props) {
    return (
        <StyledCheckbox>
            <label>
                <input
                    id={id}
                    type='checkbox'
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    style={{marginRight: '0.3em', width: '1.5em !important', height: '1.5em !important'}}
                />
                {children}
            </label>
        </StyledCheckbox>
    )
}
