import * as React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../Components/DataGrid/columnDefs/dateColumnDef'
import Button from '../../Components/Button/Button'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {SNOWBOX_EXTRACTION_OPTIONS, SNOWBOX_SKY_OPTIONS, SNOWBOX_TEST_OPTIONS} from './Offices'
import {DeleteIconButton} from '../../Components/Button/DeleteIconButton'
import {Link} from 'react-router-dom'

const renderDoctorCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return <Link to={`/dashboard/praksisnett/doctors/${params.row.doctor.id}`}>{params.value}</Link>
}

const renderOfficeCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return <Link to={`/dashboard/praksisnett/offices/${params.row.doctor.office.id}`}>{params.value}</Link>
}
const renderStudyTitleCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return (
        <Button variant='text' to={`/dashboard/praksisnett/studies/${params.row.study.id}`}>
            {params.value}
        </Button>
    )
}

const columnVisibilityModel = {
    id: false,
    'doctor.id': false,
    'doctor.external': false,
    'doctor.mobile': false,
    'doctor.email': false,
    'doctor.region': false,
    'doctor.hpr': false,
    'doctor.signedDigitally': false,
    'doctor.accountNr': false,
    'doctor.comment': false,
    'doctor.helfoDoctor.positionSize': false,
    'doctor.helfoDoctor.authorizedAt': false,
    'doctor.helfoDoctor.age': false,
    'doctor.helfoDoctor.male': false,
    'doctor.helfoDoctor.listSize': false,
    'doctor.helfoDoctor.listFree': false,
    'doctor.verifiedEmail': false,
    'doctor.mismatchedOffice': false,
    'doctor.acknowledgeOfficeMismatch': false,
    'doctor.office.id': false,
    'doctor.office.region': false,
    'doctor.office.orgNr': false,
    'doctor.office.address': false,
    'doctor.office.county.name': false,
    'doctor.office.county.id': false,
    'doctor.office.signedContract': false,
    'doctor.office.createdAt': false,
    'doctor.office.snowbox.journal': false,
    'doctor.office.snowbox.deployedAt': false,
    'doctor.office.snowbox.drcInstalled': false,
    'snowbox.extraction': false,
    'snowbox.test': false,
    'snowbox.sky': false,
    'doctor.office.snowbox.cancelledAt': false,
    'doctor.office.snowbox.cancelledReason': false,
    'doctor.office.snowbox.location': false,
    'doctor.office.snowbox.invitationAvailable': false,
    'doctor.office.snowbox.notes': false,
}

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'Invitasjon System-ID', type: 'string'}),
    columnDef({field: 'doctor.id', headerName: 'Lege ID', type: 'string'}),
    columnDef({field: 'doctor.name', headerName: 'Lege', type: 'string', renderCell: renderDoctorCell}),
    dateColumnDef({field: 'acceptedAt', headerName: 'Invitasjon godtatt på', type: 'date'}),
    dateColumnDef({field: 'compensation.invoiceReceivedAt', headerName: 'Faktura mottatt på', type: 'date'}),
    dateColumnDef({field: 'compensation.invoicePaidAt', headerName: 'Betalt på', type: 'date'}),
    columnDef({field: 'compensation.amountPaid', headerName: 'Beløp', type: 'number'}),
    columnDef({field: 'study.name', headerName: 'Studie', type: 'string', renderCell: renderStudyTitleCell}),
    columnDef({field: 'doctor.external', headerName: 'Sluttet', type: 'boolean'}),
    columnDef({field: 'doctor.mobile', headerName: 'Telefon', type: 'string'}),
    columnDef({field: 'doctor.email', headerName: 'Epost', type: 'string'}),
    columnDef({field: 'doctor.region', headerName: 'Lege Region', type: 'string'}),
    columnDef({field: 'doctor.hpr', headerName: 'HPR', type: 'string'}),
    columnDef({field: 'doctor.signedDigitally', headerName: 'Spesialist i allmennmedisin', type: 'boolean'}),
    columnDef({field: 'doctor.accountNr', headerName: 'Kontonr', type: 'number'}),
    columnDef({field: 'doctor.comment', headerName: 'Notat', type: 'string'}),
    columnDef({field: 'doctor.helfoDoctor.positionSize', headerName: 'Helfo Posisjonsstørrelse', type: 'number'}),
    columnDef({field: 'doctor.helfoDoctor.authorizedAt', headerName: 'Helfo Autorisert', type: 'string'}),
    columnDef({field: 'doctor.helfoDoctor.age', headerName: 'Helfo Alder', type: 'number'}),
    columnDef({field: 'doctor.helfoDoctor.male', headerName: 'Helfo Kjønn', type: 'boolean'}),
    columnDef({field: 'doctor.helfoDoctor.listSize', headerName: 'Helfo Listestørrelse', type: 'number'}),
    columnDef({field: 'doctor.helfoDoctor.listFree', headerName: 'Helfo Ledige plasser', type: 'number'}),
    columnDef({field: 'doctor.verifiedEmail', headerName: 'Godkjent epost', type: 'boolean'}),
    columnDef({field: 'doctor.mismatchedOffice', headerName: 'Feil legekontor', type: 'boolean'}),
    columnDef({field: 'doctor.acknowledgeOfficeMismatch', headerName: 'Godkjent feil legekontor', type: 'boolean'}),

    columnDef({field: 'doctor.office.id', headerName: 'Legekontor ID', type: 'number'}),
    columnDef({field: 'doctor.office.name', headerName: 'Legekontor', type: 'string', renderCell: renderOfficeCell}),
    columnDef({field: 'doctor.office.region', headerName: 'Legekontor Region', type: 'string'}),
    columnDef({field: 'doctor.office.orgNr', headerName: 'Orgnr.', type: 'number'}),
    columnDef({field: 'doctor.office.address', headerName: 'Adresse', type: 'string'}),
    columnDef({field: 'doctor.office.county.name', headerName: 'Kommune', type: 'string'}),
    columnDef({field: 'doctor.office.county.id', headerName: 'Kommunenr', type: 'number'}),
    columnDef({field: 'doctor.office.signedContract', headerName: 'På kontrakt', type: 'boolean'}),
    dateColumnDef({field: 'doctor.office.createdAt', headerName: 'Opprettet', type: 'dateTime'}),
    columnDef({field: 'doctor.office.snowbox.online', headerName: 'Snowbox Online', type: 'boolean'}),
    columnDef({field: 'doctor.office.snowbox.status', headerName: 'Snowbox Status', type: 'string'}),
    columnDef({field: 'doctor.office.snowbox.journal', headerName: 'Snowbox Journal', type: 'string'}),
    dateColumnDef({field: 'doctor.office.snowbox.deployedAt', headerName: 'Snowbox Utplassert', type: 'dateTime'}),
    columnDef({field: 'doctor.office.snowbox.drcInstalled', headerName: 'Snowbox DRC Innstallert', type: 'boolean'}),
    selectColumnDef({
        field: 'snowbox.extraction',
        headerName: 'Snowbox Uttrekk',
        valueOptions: SNOWBOX_EXTRACTION_OPTIONS,
    }),
    selectColumnDef({field: 'snowbox.test', headerName: 'Snowbox Test', valueOptions: SNOWBOX_TEST_OPTIONS}),
    selectColumnDef({field: 'snowbox.sky', headerName: 'Snowbox Sky', valueOptions: SNOWBOX_SKY_OPTIONS}),
    dateColumnDef({field: 'doctor.office.snowbox.cancelledAt', headerName: 'Snowbox Avlyst', type: 'dateTime'}),
    columnDef({field: 'doctor.office.snowbox.cancelledReason', headerName: 'Snowbox Avlyst grunn', type: 'string'}),
    columnDef({field: 'doctor.office.snowbox.location', headerName: 'Snowbox Plassering', type: 'string'}),
    columnDef({
        field: 'doctor.office.snowbox.invitationAvailable',
        headerName: 'Snowbox Tilgjengelig Invitasjon',
        type: 'boolean',
    }),
    columnDef({field: 'doctor.office.snowbox.notes', headerName: 'Snowbox Notater', type: 'string'}),
]
export const Invitations = () => {
    const invitationsResponse = useSkilQuery<'getPraksisNettInvitationCollection'>('/api/praksisnett/invitations', {
        pagination: true,
    })

    let invitations = invitationsResponse.data?.['hydra:member'] ?? []

    return (
        <DataGridWrapper rows={invitations.length}>
            <DataGrid
                id='invitations'
                initModel={{columnVisibilityModel}}
                loading={invitationsResponse.isLoading || invitationsResponse.isFetching}
                rows={invitations}
                columns={columns}
            />
        </DataGridWrapper>
    )
}
