import {patchEntity} from './api'

export const CANCEL_EDIT_INDICATOR = '[CHANGES] Cancel edit indicator'
export const CANCEL_CHANGE_FIELD = '[CHANGES] Cancel edit field'
export const CLEAR_CHANGES = '[CHANGES] Clear entity changes'
export const CHANGE_FIELD = '[CHANGES] Change field'
export const SAVE_FIELDS = '[CHANGES] Save changes'
export const CHANGE_LIST_ORDER = '[CHANGES] Change list order'
export const COMMIT_LIST_ORDER = '[CHANGES] Commit list order'
export const RESET_LIST_ORDER = '[CHANGES] Reset list order'

export const changeListOrder = (items: string[]) => ({type: CHANGE_LIST_ORDER, payload: {items}})
export const commitListOrder = (items: string[]) => ({type: COMMIT_LIST_ORDER, payload: {items}})
export const resetListOrder = (items: string[]) => ({type: RESET_LIST_ORDER, payload: {items}})

export const cancelIndicatorChanges = id => ({type: CANCEL_EDIT_INDICATOR, payload: {id}})
export const changeIndicatorField = (id, field, content, ownerId = undefined) => ({
    type: CHANGE_FIELD,
    payload: {id, field, content, ownerId},
})
export const cancelIndicatorFieldChange = (id, field, ownerId = undefined) => ({type: CANCEL_CHANGE_FIELD, payload: {id, field, ownerId}})

export const saveChanges = (id, changes = {}) => ({type: SAVE_FIELDS, payload: {id, changes}})
export const clearChanges = id => ({type: CLEAR_CHANGES, payload: {id}})
