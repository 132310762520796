import {useCallback} from 'react'
import {PhoneNumberUtil, PhoneNumberFormat as PNF} from 'google-libphonenumber'

const usePhoneFormatter = (country = 'NO') => {
    return useCallback(
        number => {
            try {
                const phoneUtil = PhoneNumberUtil.getInstance()
                const numberObj = phoneUtil.parseAndKeepRawInput(number, country)
                const formatted = phoneUtil.format(numberObj, PNF.NATIONAL)
                const valid = phoneUtil.isValidNumber(numberObj)
                const raw = phoneUtil.format(numberObj, PNF.E164)
                return {formatted, valid, raw: valid ? raw : number}
            } catch (e) {
                return {formatted: number, valid: false, raw: number}
            }
        },
        [country]
    )
}
export default usePhoneFormatter
