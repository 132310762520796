import {useParams} from 'react-router'
import useEntity from '../Hooks/useEntity'
import React, {useEffect} from 'react'
import useUser from '../Utilities/useUser'
import {saveChanges} from './Form/App'
import {SkilQueryResponseType, useSkilQuery} from '../Utilities/QueryClient'
import LoadingComponent from '../Components/LoadingComponent'
import {RelatedTasksApp} from './RelatedTaskApp'

export const PreCheckRelatedTaskApp = () => {
    const user = useUser()
    const {taskId} = useParams()
    const {answerSetId} = useParams()
    // the answerSetID is set either from the params or from the localStorage
    const [answerSetID, setAnswerSetID] = React.useState<number | null>(answerSetId ? parseInt(answerSetId) : null)
    const task = useEntity<'getFormRelatedTaskItem'>(`/api/form_related_tasks/${taskId}`)
    const formData = useSkilQuery<'getFormItem'>(task?.form ?? '', {pagination: false}, {enabled: !!taskId})
    const [localStorageAnswers, setLocalStorageAnswers] = React.useState<any>({})
    const [localStorageChecked, setLocalStorageChecked] = React.useState<boolean>(false)
    const form = formData.data
    const answerSet = useEntity<'findAnswerSet'>(answerSetID ? `/api/answer_sets/${answerSetID}` : null)

    const localStorageKey = form?.id && taskId ? `answerset&form=${form?.id}&task=${taskId}&user=${user.id}&v=1` : ''

    useEffect(() => {
        if (answerSetID) {
            return
        }
        // in the preCheck we need to check if there is a localStorage id for current answerSet,
        // since it is not always given by the params
        const id = window.localStorage.getItem('current-answerSetId')
        if (!id) {
            return
        }
        setAnswerSetID(parseInt(id))
    }, [])

    useEffect(() => {
        if (localStorageKey) {
            const localStorageData = window.localStorage.getItem(localStorageKey)
            if (localStorageData) {
                const localStorageAnswers = JSON.parse(localStorageData)
                setLocalStorageAnswers(localStorageAnswers)
            }
            setLocalStorageChecked(true)
        }
    }, [localStorageKey])

    const StoreUnsavedAnswers = async (localStorageAnswers: any) => {
        type PatchAnswerSet = SkilQueryResponseType<'updateAnswerSet'>
        return await saveChanges<PatchAnswerSet>(answerSet['@id'], localStorageAnswers.answers, undefined).then(res => {
            if (res.answers.length === 0) {
                return true
            }
            const filteredAnswers = Object.keys(localStorageAnswers.answers).filter((key, value) => {
                return !Object.values(res.answers).some(iri => key === iri['indicator'])
            })
            // hack to fix the issue with the localStorage not being cleared when all answers are deleted
            // this happens when an indicator is deleted from the form while the user is working on it
            if (JSON.stringify(filteredAnswers) === '["0"]') {
                window.localStorage.setItem(localStorageKey, JSON.stringify({newLocation: undefined, answers: {}}))
            } else {
                window.localStorage.setItem(localStorageKey, JSON.stringify({newLocation: undefined, answers: {...filteredAnswers}}))
            }
            return true
        })
    }

    if (
        taskId &&
        answerSet['@id'] &&
        localStorageChecked &&
        localStorageAnswers?.answers &&
        Object.keys(localStorageAnswers?.answers).length > 0
    ) {
        // if the localStorage is checked and there were unsaved answers then we need to save them
        // before we can render the form
        // In order for the Form to have a fresh version of the answerSet we need to reload the window
        StoreUnsavedAnswers(localStorageAnswers).then(() => {
            window.location.reload()
        })
        return <LoadingComponent msg={'Lagrer ulagrede svar...'} />
    }

    if (taskId && localStorageChecked && (!localStorageAnswers?.answers || Object.keys(localStorageAnswers?.answers).length === 0)) {
        // if the localStorage is checked and there were not any unsaved answers then we can render the form
        return <RelatedTasksApp taskId={taskId} answerSetId={answerSetId} />
    }

    return <LoadingComponent msg={'Laster inn spørsmål...'} />
}
