import * as React from 'react'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import columnDef, {EditableGridColDef} from '../../../Components/DataGrid/columnDefs/columnDef'
import dateColumnDef from '../../../Components/DataGrid/columnDefs/dateColumnDef'
import selectColumnDef from '../../../Components/DataGrid/columnDefs/selectColumnDef'
import {SNOWBOX_EXTRACTION_OPTIONS, SNOWBOX_SKY_OPTIONS, SNOWBOX_TEST_OPTIONS} from '../../ListViews/Offices'
import {Box} from '../../../Components/Panel'
import {DataGridWrapper} from '../../../Components/DataGrid/Wrappers'
import DataGrid from '../../../Components/DataGrid/DataGrid'
import {GridCellEditCommitParams, GridEnrichedColDef, GridSelectionModel} from '@mui/x-data-grid-premium'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SpecialActions from '../../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../../Components/DataGrid/SpecialAction'
import {StudyAddDoctorModal} from './StudyAddDoctorModal'
import {set} from 'lodash'
import {toast} from 'react-toastify'
import {DeleteIconButton} from '../../../Components/Button/DeleteIconButton'
import {Link} from 'react-router-dom'
import {SendMessageDialog} from '../../components/SendMessageDialog'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const columnVisibilityModel = {
    id: false,
    'doctor.id': false,
    'doctor.external': false,
    'doctor.mobile': false,
    'doctor.email': false,
    'doctor.region': false,
    'doctor.hpr': false,
    'doctor.signedDigitally': false,
    'doctor.accountNr': false,
    'doctor.comment': false,
    'doctor.helfoDoctor.authorizedAt': false,
    'doctor.helfoDoctor.age': false,
    'doctor.helfoDoctor.male': false,
    'doctor.helfoDoctor.listSize': false,
    'doctor.helfoDoctor.listFree': false,
    'doctor.verifiedEmail': false,
    'doctor.mismatchedOffice': false,
    'doctor.acknowledgeOfficeMismatch': false,
    'doctor.office.id': false,
    'doctor.office.region': false,
    'doctor.office.orgNr': false,
    'doctor.office.address': false,
    'doctor.office.county.name': false,
    'doctor.office.signedContract': false,
    'doctor.office.createdAt': false,
    'doctor.office.snowbox.journal': false,
    'doctor.office.snowbox.deployedAt': false,
    'doctor.office.snowbox.drcInstalled': false,
    'snowbox.extraction': false,
    'snowbox.test': false,
    'snowbox.sky': false,
    'doctor.office.snowbox.cancelledAt': false,
    'doctor.office.snowbox.cancelledReason': false,
    'doctor.office.snowbox.location': false,
    'doctor.office.snowbox.invitationAvailable': false,
    'doctor.office.snowbox.notes': false,
}

const renderDoctorCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return <Link to={`/dashboard/praksisnett/doctors/${params.row.doctor.id}`}>{params.value}</Link>
}

const renderOfficeCell = params => {
    if (!params.value) return <span>ingen navn</span>

    return <Link to={`/dashboard/praksisnett/offices/${params.row.doctor.office.id}`}>{params.value}</Link>
}

export const StudyDoctorsPanel = ({studyId}) => {
    const [showFindUser, setShowFindUser] = React.useState(false)
    const [showSendText, setShowText] = React.useState(false)
    const [showSendEmail, setShowEmail] = React.useState(false)
    const {data, isLoading, refetch, isFetching} = useSkilQuery<'getPraksisNettStudyInvitationCollection'>(
        `/api/praksisnett/studies/${studyId}/invitations`,
        {pagination: false}
    )
    const [selectedInvitations, setSelectedInvitations] = React.useState<GridSelectionModel>([])
    const invitations = data ? data['hydra:member'] : []

    const updateInvitationMutation = useSkilMutation<'updatePraksisNettInvitationItem'>('PATCH', `/api/praksisnett/invitations/${null}`, [
        `/api/praksisnett/studies/${studyId}/invitations`,
    ])

    const deleteInvitationMutation = useSkilMutation<'deletePraksisNettInvitationItem'>('DELETE', `/api/praksisnett/invitations/${null}`, [
        `/api/praksisnett/studies/${studyId}/invitations`,
    ])

    const handleDeleteInvitation = async id => {
        try {
            await deleteInvitationMutation.mutateAsync({
                '@overridePath': `/api/praksisnett/invitations/${id}`,
            })
            toast('Fjernet fra studien', {
                type: 'success',
            })
            refetch().catch(() => {})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const invitationActions: SpecialActionProps[] = [
        {
            label: 'Legg til lege',
            action: () => setShowFindUser(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
        {
            label: 'Send SMS',
            action: () => setShowText(true),
            disabled: selectedInvitations.length === 0,
            icon: {
                position: 'start',
                icon: <PhoneIphoneOutlinedIcon />,
            },
        },
        {
            label: 'Send e-post',
            disabled: selectedInvitations.length === 0,
            action: () => setShowEmail(true),
            icon: {
                position: 'start',
                icon: <EmailOutlinedIcon />,
            },
        },
    ]

    const onCellEditCommit = async (params: GridCellEditCommitParams) => {
        const compensation = set({}, params.field, params.value)

        try {
            await updateInvitationMutation.mutateAsync({
                '@overridePath': `/api/praksisnett/invitations/${params.id}`,
                ...compensation,
            })
            // refetch: no need to catch, handled by useSkilQuery 😄
            refetch()
                .then(() => {})
                .catch(() => {})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const invitationColumns: (EditableGridColDef | GridEnrichedColDef)[] = React.useMemo(
        () => [
            columnDef({field: 'id', headerName: 'Invitasjon System-ID', type: 'string'}),
            columnDef({field: 'doctor.id', headerName: 'Lege ID', type: 'string'}),
            columnDef({field: 'doctor.name', headerName: 'Lege', type: 'string', renderCell: renderDoctorCell}),
            dateColumnDef({field: 'acceptedAt', headerName: 'Invitasjon godtatt på', type: 'date'}, {onCellEditCommit}),
            dateColumnDef({field: 'compensation.invoiceReceivedAt', headerName: 'Faktura mottatt på', type: 'date'}, {onCellEditCommit}),
            dateColumnDef({field: 'compensation.invoicePaidAt', headerName: 'Betalt på', type: 'date'}, {onCellEditCommit}),
            columnDef({field: 'compensation.amountPaid', headerName: 'Beløp', type: 'number'}, {onCellEditCommit}),
            columnDef({field: 'doctor.external', headerName: 'Sluttet', type: 'boolean'}),
            columnDef({field: 'doctor.mobile', headerName: 'Telefon', type: 'string'}),
            columnDef({field: 'doctor.email', headerName: 'Epost', type: 'string'}),
            columnDef({field: 'doctor.region', headerName: 'Lege Region', type: 'string'}),
            columnDef({field: 'doctor.hpr', headerName: 'HPR', type: 'string'}),
            columnDef({field: 'doctor.signedDigitally', headerName: 'Spesialist i allmennmedisin', type: 'boolean'}),
            columnDef({field: 'doctor.accountNr', headerName: 'Kontonr', type: 'number'}),
            columnDef({field: 'doctor.comment', headerName: 'Notat', type: 'string'}),
            columnDef({field: 'doctor.helfoDoctor.positionSize', headerName: 'Helfo Posisjonsstørrelse', type: 'number'}),
            columnDef({field: 'doctor.helfoDoctor.authorizedAt', headerName: 'Helfo Autorisert', type: 'string'}),
            columnDef({field: 'doctor.helfoDoctor.age', headerName: 'Helfo Alder', type: 'number'}),
            columnDef({field: 'doctor.helfoDoctor.male', headerName: 'Helfo Kjønn', type: 'boolean'}),
            columnDef({field: 'doctor.helfoDoctor.listSize', headerName: 'Helfo Listestørrelse', type: 'number'}),
            columnDef({field: 'doctor.helfoDoctor.listFree', headerName: 'Helfo Ledige plasser', type: 'number'}),
            columnDef({field: 'doctor.verifiedEmail', headerName: 'Godkjent epost', type: 'boolean'}),
            columnDef({field: 'doctor.mismatchedOffice', headerName: 'Feil legekontor', type: 'boolean'}),
            columnDef({field: 'doctor.acknowledgeOfficeMismatch', headerName: 'Godkjent feil legekontor', type: 'boolean'}),

            columnDef({field: 'doctor.office.id', headerName: 'Legekontor ID', type: 'number'}),
            columnDef({field: 'doctor.office.name', headerName: 'Legekontor', type: 'string', renderCell: renderOfficeCell}),
            columnDef({field: 'doctor.office.region', headerName: 'Legekontor Region', type: 'string'}),
            columnDef({field: 'doctor.office.orgNr', headerName: 'Orgnr.', type: 'number'}),
            columnDef({field: 'doctor.office.address', headerName: 'Adresse', type: 'string'}),
            columnDef({field: 'doctor.office.county.name', headerName: 'Kommune', type: 'string'}),
            columnDef({field: 'doctor.office.county.id', headerName: 'Kommunenr', type: 'number'}),
            columnDef({field: 'doctor.office.signedContract', headerName: 'På kontrakt', type: 'boolean'}),
            dateColumnDef({field: 'doctor.office.createdAt', headerName: 'Opprettet', type: 'dateTime'}),
            columnDef({field: 'doctor.office.snowbox.online', headerName: 'Snowbox Online', type: 'boolean'}),
            columnDef({field: 'doctor.office.snowbox.status', headerName: 'Snowbox Status', type: 'string'}),
            columnDef({field: 'doctor.office.snowbox.journal', headerName: 'Snowbox Journal', type: 'string'}),
            dateColumnDef({field: 'doctor.office.snowbox.deployedAt', headerName: 'Snowbox Utplassert', type: 'dateTime'}),
            columnDef({field: 'doctor.office.snowbox.drcInstalled', headerName: 'Snowbox DRC Innstallert', type: 'boolean'}),
            selectColumnDef({
                field: 'snowbox.extraction',
                headerName: 'Snowbox Uttrekk',
                valueOptions: SNOWBOX_EXTRACTION_OPTIONS,
            }),
            selectColumnDef({field: 'snowbox.test', headerName: 'Snowbox Test', valueOptions: SNOWBOX_TEST_OPTIONS}),
            selectColumnDef({field: 'snowbox.sky', headerName: 'Snowbox Sky', valueOptions: SNOWBOX_SKY_OPTIONS}),
            dateColumnDef({field: 'doctor.office.snowbox.cancelledAt', headerName: 'Snowbox Avlyst', type: 'dateTime'}),
            columnDef({field: 'doctor.office.snowbox.cancelledReason', headerName: 'Snowbox Avlyst grunn', type: 'string'}),
            columnDef({field: 'doctor.office.snowbox.location', headerName: 'Snowbox Plassering', type: 'string'}),
            columnDef({
                field: 'doctor.office.snowbox.invitationAvailable',
                headerName: 'Snowbox Tilgjengelig Invitasjon',
                type: 'boolean',
            }),
            columnDef({field: 'doctor.office.snowbox.notes', headerName: 'Snowbox Notater', type: 'string'}),
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Handlinger',

                getActions: ({id}) => {
                    return [
                        <DeleteIconButton
                            key={`del${id}`}
                            deleteMenuItemLabel='Fjern fra studie'
                            tooltipText='Fjern'
                            menuId={`del${id}`}
                            deleteAction={() => handleDeleteInvitation(id)}
                        />,
                    ]
                },
            },
        ],
        []
    )

    const onChangeSelectedDoctors = (event, value) => {
        setSelectedInvitations(value.map(v => v.id))
    }
    const currentSelection = invitations.filter(i => selectedInvitations.includes(Number(i.id)))
    const doctorIris = invitations.filter(i => selectedInvitations.includes(Number(i.id))).map(i => i.doctor!['@id'] as string)

    return (
        <Box title='Leger'>
            {isLoading && !invitations ? (
                <CircularProgress />
            ) : (
                <DataGridWrapper rows={invitations.length} addHeight={8.5}>
                    <DataGrid
                        id={'doctors'}
                        checkboxSelection
                        loading={isFetching}
                        rows={invitations}
                        initModel={{columnVisibilityModel}}
                        columns={invitationColumns}
                        onSelectionModelChange={newSelectedInvitations => {
                            setSelectedInvitations(newSelectedInvitations)
                        }}
                        selectionModel={selectedInvitations}
                        toolbarButtons={<SpecialActions actions={invitationActions} />}
                    />
                </DataGridWrapper>
            )}
            {showFindUser && <StudyAddDoctorModal refetch={refetch} studyId={studyId} onClose={() => setShowFindUser(false)} />}
            <SendMessageDialog isOpen={showSendEmail} onClose={() => setShowEmail(false)} doctorIris={doctorIris} isEmail>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={invitations}
                    value={currentSelection}
                    limitTags={5}
                    onChange={onChangeSelectedDoctors}
                    getOptionLabel={option => String(option.doctor?.name)}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>

            <SendMessageDialog isOpen={showSendText} onClose={() => setShowText(false)} doctorIris={doctorIris} isText>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={invitations}
                    limitTags={5}
                    value={currentSelection}
                    onChange={onChangeSelectedDoctors}
                    getOptionLabel={option => String(option.doctor?.name)}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>
        </Box>
    )
}
