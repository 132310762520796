import React, {useState, useRef, useEffect, useContext} from 'react'
import Button from '../../Components/Button/Button'
import EditShade from './EditShade'
import styled from 'styled-components'
import {EditLockContext} from '../Form/Form'

const StyledContainer = styled.div`
    position: relative;
    width: ${props => (props.inline ? 'auto' : '100%')};
    display: ${props => (props.inline ? 'inline-block' : 'block')};
    width: ${props => (props.width ? props.width : 'inherit')};
    text-align: ${props => (props.width ? 'center' : 'left')};
    height: auto;
    min-width: 5em;
    &:hover {
        text-decoration: underline;
    }
    &&& .toolbox {
        position: ${props => (props.toolboxStyling ? 'relative' : 'absolute')};
        ${props => (props.toolboxStyling ? 'display:flex;' : null)};
        ${props => (props.toolboxStyling ? 'justify-content: flex-end;' : null)};
        ${props => (props.toolboxStyling ? 'width: fit-content;' : null)};
        ${props => (props.toolboxStyling ? 'float: right;' : null)};
        right: 0;
        top: auto;
        box-shadow: 2px 2px #88888824;
        background: #fff;
    }
`

const StyledInput = styled.input`
    padding: ${props => (props.styling ? '10px 15px' : 'null')};
    font-size: ${props => (props.styling ? '14px' : '12px')};
`

const StyledTextarea = styled.textarea`
    padding: ${props => (props.styling ? '10px 15px' : 'null')};
    font-size: ${props => (props.styling ? '14px' : '12px')};
`

type Props = {
    onSave?: Function
    onCancel?: Function
    text?: string
    placeholder?: string
    onRemove?: Function
    inline?: boolean
    type?: string
    width?: string
    className?: string
    externalEditTrigger?: boolean
    styling?: boolean
    toolboxStyling?: boolean
}

const EditText = ({
    onSave,
    onCancel,
    text,
    placeholder,
    onRemove,
    inline = false,
    type = 'text',
    width = undefined,
    className = '',
    externalEditTrigger = false,
    styling = false,
    toolboxStyling = false,
}: Props) => {
    const [lock, setLock] = useContext<any>(EditLockContext)
    const [editing, setEditing] = useState(false)
    const [currentLabel, setLabel] = useState('' || text)
    const isEditingOther = lock && !editing
    const inputRef = useRef<any>()
    const [externalStyling, setExternalStyling] = useState(false)

    useEffect(() => {
        if (externalEditTrigger) {
            setEditing(true)
        }
    }, [externalEditTrigger])

    useEffect(() => {
        if (styling) {
            setExternalStyling(true)
        }
    }, [styling])

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus()
        }
    }, [editing])

    useEffect(() => {
        if (!editing) {
            setLabel(text || '')
        }
    }, [text])

    const onEdit = e => {
        if (!lock) {
            setEditing(true)
            setLock(true)
        }

        e.preventDefault()
        return false
    }
    const onLocalCancel = () => {
        setEditing(false)
        setLock(false)

        if (typeof onCancel === 'function') onCancel(currentLabel)
    }

    const onLocalSave = () => {
        setLock(false)
        setEditing(false)

        if (typeof onSave === 'function') onSave(currentLabel)
    }

    const onLocalRemove = () => {
        setEditing(false)
        setLock(false)

        if (onRemove) onRemove()
    }

    const onKeypress = e => {
        if (e.key === 'Escape') {
            onLocalCancel()
            e.preventDefault()
            return false
        }

        if (e.key === 'Enter') {
            onLocalSave()
            e.preventDefault()
            return false
        }
    }

    return (
        <StyledContainer onKeyDown={onKeypress} width={width} inline={inline} toolboxStyling={toolboxStyling}>
            {editing ? (
                <div>
                    {inline || type !== 'text' ? (
                        <StyledInput
                            ref={inputRef}
                            type={type}
                            width={width}
                            className={'form-control input-sm'}
                            value={currentLabel}
                            onChange={e => setLabel(e.target.value)}
                            styling={externalStyling}
                        />
                    ) : (
                        <StyledTextarea
                            ref={inputRef}
                            className={'form-control input-sm'}
                            value={currentLabel}
                            onChange={e => setLabel(e.target.value)}
                            styling={externalStyling}
                        />
                    )}

                    <div className={'toolbox'}>
                        <Button onClick={onLocalSave} variant={'link'} size={'sm'}>
                            <i className={'fa fa-check'} />
                        </Button>
                        <Button onClick={onLocalCancel} variant={'link'} size={'sm'}>
                            <i className={'fa fa-times'} />
                        </Button>
                        {onRemove && (
                            <Button
                                onClick={onLocalRemove}
                                confirm={
                                    <>
                                        <i className={'fa fa-check'} /> Er du sikker?
                                    </>
                                }
                                variant={'link'}
                                size={'sm'}
                            >
                                <i className={'fa fa-trash'} />
                            </Button>
                        )}
                    </div>
                </div>
            ) : (
                <div style={{minHeight: '2em'}} className={className} hidden={editing}>
                    <a>{text ?? placeholder ?? ' '}</a>
                </div>
            )}

            <EditShade type='button' hidden={editing || isEditingOther} onEdit={onEdit} toolboxStyling={editing} />
        </StyledContainer>
    )
}
export default EditText
