export const APP_ROUTES = {
    HOME: '/',
    DOCTORS: 'doctors',
    OFFICES: 'offices',
    STUDIES: 'studies',
    COMPENSATIONS: 'compensations',
    INVITATIONS: 'invitations',
    PRAKSISNETT_USERS: 'praksisnett_users',
}
export const ROUTES = {
    HOME: `/dashboard/praksisnett/${APP_ROUTES.STUDIES}`,
    DOCTORS: `/dashboard/praksisnett/${APP_ROUTES.DOCTORS}`,
    OFFICES: `/dashboard/praksisnett/${APP_ROUTES.OFFICES}`,
    STUDIES: `/dashboard/praksisnett/${APP_ROUTES.STUDIES}`,
    COMPENSATIONS: `/dashboard/praksisnett/${APP_ROUTES.COMPENSATIONS}`,
    INVITATIONS: `/dashboard/praksisnett/${APP_ROUTES.INVITATIONS}`,
    PRAKSISNETT_USERS: `/dashboard/praksisnett/${APP_ROUTES.PRAKSISNETT_USERS}`,
}
