// @ts-nocheck
import React, {useState} from 'react'
import Button from '../Components/Button/Button'
import useEntity from '../Hooks/useEntity'
import useEntities from '../Hooks/useEntities'
import {useParams, useNavigate} from 'react-router'
import Panel from '../Components/Panel'
import {useQuery} from 'react-query'
import format from 'date-fns/format'
import styled from 'styled-components'
import Collapse from '../Components/Collapse'
import FindUserModal from '../Components/FindUserModal'
import {jsonFetch} from '../Components/jsonFetch'
import {Page} from '../Components/Page'

const StyledTable = styled.table`
    td.selected,
    th.selected {
        &,
        * {
            color: green !important;
            font-weight: bold !important;
        }
    }
`

const formatDate = dateStr => (dateStr ? format(new Date(dateStr), 'dd.MM.yyyy HH:mm') : '~')
const formatRole = role => {
    switch (role) {
        case 0:
            return 'Student'
        case 1:
            return 'Veileder'
        case 2:
            return 'Admin'
        default:
            return '~'
    }
}

const getPreferredUc = (uc1, uc2) => {
    if (!uc1) return uc2

    if (!uc2) return uc1

    if (uc1.completedAt) return uc1

    if (uc2.completedAt) return uc2

    if (uc1.progress && uc1.progress > uc2.progress) return uc1

    if (uc2.progress && uc2.progress > uc1.progress) return uc2

    if (uc1.acceptedAt && !uc2.acceptedAt) return uc1

    if (uc2.acceptedAt && !uc1.acceptedAt) return uc2

    if (uc1.role !== uc2.role) return uc1.role > uc2.role ? uc1 : uc2

    return uc1
}

const createDefaultMessage = (user1, user2, preferredEmail, oldEmail) => {
    return `Hei ${user1.name}!

Vi oppdaget at du har 2 brukere i SKIL ePortal, og har derfor slått sammen brukeren med epost ${preferredEmail} og ${oldEmail}.

${oldEmail} er blitt slettet. Neste gang du logger på, vennligst bruk ${preferredEmail}.


Med vennlig hilsen SKIL
    
    `
}

export default function MergeUsers() {
    const {idUser1, idUser2} = useParams()
    const [showSelectUser, setShowSelectUser] = useState(null)
    const [selection, setSelection] = useState({})
    const [expandedTasks, setExpandedTasks] = useState([])
    const navigate = useNavigate()

    const {data: user1} = useQuery(`/api/users/${idUser1}/merge_user`, {enabled: !!idUser1})
    const {data: user2} = useQuery(`/api/users/${idUser2}/merge_user`, {enabled: !!idUser2})

    React.useEffect(() => {
        if (!user1 || !user2) return

        const lastSeenUser = new Date(user1.lastSeenAt) > new Date(user2.lastSeenAt) ? user1 : user2
        const theOtherUser = user1 === lastSeenUser ? user2 : user1
        const user1courses = user1.courses ?? []
        setSelection({
            user1: user1['@id'],
            user2: user2['@id'],
            message: createDefaultMessage(user1, user2, lastSeenUser.email, theOtherUser?.email ?? null),
            name: lastSeenUser.name,
            email: lastSeenUser.email,
            mobile: lastSeenUser.mobile ?? theOtherUser.mobile,
            office: lastSeenUser.office ?? theOtherUser.office,
            officeRoles: lastSeenUser.office ? lastSeenUser.officeRoles : theOtherUser.officeRoles,
            location: lastSeenUser.location ?? theOtherUser.location,
            district: lastSeenUser.district ?? theOtherUser.district,
            courses: user1courses
                .map(uc1 => {
                    const uc2 = (user2.courses ?? []).find(uc2 => uc2.course === uc1.course)

                    let preferredUc = getPreferredUc(uc1, uc2)
                    const otherUc = uc1 === preferredUc ? uc2 : uc1
                    return {
                        id: preferredUc.id,
                        course: uc1.course,
                        tasks: Object.values(preferredUc.tasks).map(preferredUt => {
                            const otherUt = Object.values(otherUc?.tasks ?? {}).find(ut2 => ut2.task === preferredUt.task) ?? null

                            if (!otherUt) {
                                return preferredUt
                            }

                            const bestUt = preferredUt.progress >= otherUt.progress ? preferredUt : otherUt
                            if (bestUt === otherUt && !expandedTasks.includes(uc1.course))
                                setExpandedTasks(courses => [...courses, uc1.course])

                            return bestUt
                        }),
                    }
                })
                .concat(
                    (user2.courses ?? [])
                        .filter(uc2 => !user1courses.find(uc1 => uc1.course === uc2.course))
                        .map(uc2 => {
                            return {
                                id: uc2.id,
                                course: uc2.course,
                                tasks: Object.values(uc2.tasks).map(ut => ({id: ut.id, task: ut.task})),
                            }
                        })
                ),
        })
    }, [user1, user2])

    const counties = useEntities<'getCountyCollection'>('/api/counties?itemsPerPage=500')
    const location1 = user1?.location ? counties.find(c => c['@id'] === user1?.location) : null
    const location2 = user2?.location ? counties.find(c => c['@id'] === user2?.location) : null
    const districts = useEntities<'getDistrictCollection'>('/api/districts')
    const district1 = user1?.district ? districts.find(c => c['@id'] === user1?.district) : null
    const district2 = user2?.district ? districts.find(c => c['@id'] === user2?.district) : null
    const office1 = useEntity<'getOfficeItem'>(user1?.office ?? null)
    const office2 = useEntity<'getOfficeItem'>(user2?.office ?? null)

    const onSelectUser = user => {
        setShowSelectUser(null)

        if (showSelectUser === 1) {
            navigate(`/dashboard/SKIL/merge_users/${user.id}/${idUser2 ? idUser2 : ''}`)
        } else if (showSelectUser === 2) {
            navigate(`/dashboard/SKIL/merge_users/${idUser1}/${user.id}`)
        }
    }

    const toggleExpandedCourse = course => {
        if (expandedTasks.includes(course)) setExpandedTasks(courses => courses.filter(c => c !== course))
        else setExpandedTasks(courses => [...courses, course])
    }
    const onSelect = (key, value) => {
        setSelection(selection => ({...selection, [key]: value}))

        if (key === 'email') {
            setSelection(selection => {
                const oldEmail = user1.email === selection.email ? user2.email : user1.email
                const message = createDefaultMessage(user1, user2, selection.email, oldEmail)

                return {...selection, message}
            })
        }
    }

    const onSelectOfficeRole = (key, value) => {
        setSelection(selection => ({...selection, officeRoles: {...selection.officeRoles, [key]: value}}))
    }

    const onSelectCourse = selectedUc => {
        setSelection(selection => ({
            ...selection,
            courses: selection.courses.map(c => (c.course === selectedUc.course ? {...c, id: selectedUc.id} : c)),
        }))
    }

    const onSelectTask = (course, selectedTask) => {
        setSelection(selection => ({
            ...selection,
            courses: selection.courses.map(c =>
                c.course !== course
                    ? c
                    : {
                          ...c,
                          tasks: Object.values(c.tasks).map(ut => (ut.task !== selectedTask.task ? ut : selectedTask)),
                      }
            ),
        }))
    }

    const user1Courses = user1?.courses ?? []
    const user2Courses = user2?.courses ?? []
    const filteredUser2Courses = user2Courses.filter(uc2 => !user1Courses.find(uc1 => uc1.course === uc2.course))

    const onSubmit = () => {
        return jsonFetch('/api/skil/users/merge_users', {method: 'POST', json: selection}).then(() => {
            const selectedId = user1.email === selection.email ? user1.id : user2.id
            window.location.href = `/dashboard/users/${selectedId}`
        })
    }

    const breadcrumbs = [
        {title: 'SKIL', path: '/dashboard/SKIL'},
        {title: 'Slå sammen brukere', path: ''},
    ]

    return (
        <Page breadcrumbs={breadcrumbs} variant={'default'}>
            <Panel title={'Flett sammen brukere'}>
                {showSelectUser && (
                    <FindUserModal disableHelfoDoctor disableNewUser onSave={onSelectUser} onClose={() => setShowSelectUser(null)} />
                )}
                <StyledTable className={'table'}>
                    <thead>
                        <tr>
                            <th />
                            <th>
                                {user1?.name ?? 'Ikke valgt enda'}
                                <Button inline variant={'link'} onClick={() => setShowSelectUser(1)}>
                                    Bytte
                                </Button>
                            </th>
                            <th>
                                {user2?.name ?? 'Ikke valgt enda'}
                                <Button hidden={!idUser1} inline variant={'link'} onClick={() => setShowSelectUser(2)}>
                                    Bytte
                                </Button>
                            </th>
                        </tr>
                        <tr>
                            <th />
                            <td>
                                Sist sett:
                                <br />
                                {formatDate(user1?.lastSeenAt)}
                            </td>
                            <td>
                                Sist sett:
                                <br />
                                {formatDate(user2?.lastSeenAt)}
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th>Navn:</th>
                            <td className={selection?.name === user1?.name ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('name', user1?.name)}>
                                    {user1?.name}
                                </Button>
                            </td>
                            <td className={selection?.name === user2?.name ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('name', user2?.name)}>
                                    {user2?.name}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <th>Epost:</th>
                            <td className={selection?.email === user1?.email ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('email', user1?.email)}>
                                    {user1?.email}
                                    {user1?.hasInvalidEmail && <i title={'Ugyldig epost'} className={'fa fa-exclamation-triangle'} />}
                                </Button>
                            </td>
                            <td className={selection?.email === user2?.email ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('email', user2?.email)}>
                                    {user2?.email}
                                    {user2?.hasInvalidEmail && <i title={'Ugyldig epost'} className={'fa fa-exclamation-triangle'} />}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <th>Mobil:</th>
                            <td className={selection?.mobile === user1?.mobile ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('mobile', user1?.mobile)}>
                                    {user1?.mobile}
                                </Button>
                            </td>
                            <td className={selection?.mobile === user2?.mobile ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('mobile', user2?.mobile)}>
                                    {user2?.mobile}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <th>Kommune:</th>
                            <td className={selection?.location === location1?.['@id'] ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => {
                                        onSelect('location', location1?.['@id'])
                                        onSelect('district', district1?.['@id'])
                                    }}>
                                    {location1 ? `${location1.name}, ${location1.region}` : ''}
                                </Button>
                            </td>
                            <td className={selection?.location === location2?.['@id'] ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => {
                                        onSelect('location', location2?.['@id'])
                                        onSelect('district', district2?.['@id'])
                                    }}>
                                    {location2 ? `${location2.name}, ${location2.region}` : ''}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <th>Bydel:</th>
                            <td className={selection?.district === district1?.['@id'] ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => {
                                        onSelect('district', district1?.['@id'])
                                        onSelect('location', location1?.['@id'])
                                    }}>
                                    {district1 ? `${district1.name}` : ''}
                                </Button>
                            </td>
                            <td className={selection?.district === district2?.['@id'] ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => {
                                        onSelect('district', district2?.['@id'])
                                        onSelect('location', location2?.['@id'])
                                    }}>
                                    {district2 ? `${district2.name}` : ''}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <th>Legekontor:</th>
                            <td className={selection?.office === office1?.['@id'] ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('office', office1?.['@id'])}>
                                    {office1.name}
                                </Button>
                            </td>
                            <td className={selection?.office === office2?.['@id'] ? 'selected' : ''}>
                                <Button inline variant={'link'} onClick={() => onSelect('office', office2?.['@id'])}>
                                    {office2.name}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Har nefle?</td>
                            <td className={user1?.hasNefle ? 'selected' : ''}>
                                {user1?.hasNefle ? (
                                    <>
                                        <i className={'fa fa-exclamation-triangle'} /> Ja
                                    </>
                                ) : (
                                    'Nei'
                                )}
                            </td>
                            <td className={user2?.hasNefle ? 'selected' : ''}>
                                {user2?.hasNefle ? (
                                    <>
                                        <i className={'fa fa-exclamation-triangle'} /> Ja
                                    </>
                                ) : (
                                    'Nei'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Kontakt:</td>
                            <td className={selection?.officeRoles?.isContact === user1?.officeRoles?.isContact ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => onSelectOfficeRole('isContact', user1?.officeRoles?.isContact)}>
                                    {user1?.officeRoles?.isContact ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                            <td className={selection?.officeRoles?.isContact === user2?.officeRoles?.isContact ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => onSelectOfficeRole('isContact', user2?.officeRoles?.isContact)}>
                                    {user2?.officeRoles?.isContact ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>IT-Kontakt:</td>
                            <td className={selection?.officeRoles?.isItContact === user1?.officeRoles?.isItContact ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => onSelectOfficeRole('isItContact', user1?.officeRoles?.isItContact)}>
                                    {user1?.officeRoles?.isItContact ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                            <td className={selection?.officeRoles?.isItContact === user2?.officeRoles?.isItContact ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => onSelectOfficeRole('isItContact', user2?.officeRoles?.isItContact)}>
                                    {user2?.officeRoles?.isItContact ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Nefle Admin</td>
                            <td className={selection?.officeRoles?.nefleAdmin === user1?.officeRoles?.nefleAdmin ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => onSelectOfficeRole('nefleAdmin', user1?.officeRoles?.nefleAdmin)}>
                                    {user1?.officeRoles?.nefleAdmin ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                            <td className={selection?.officeRoles?.nefleAdmin === user2?.officeRoles?.nefleAdmin ? 'selected' : ''}>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() => onSelectOfficeRole('nefleAdmin', user2?.officeRoles?.nefleAdmin)}>
                                    {user2?.officeRoles?.nefleAdmin ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Video Admin</td>
                            <td
                                className={
                                    selection?.officeRoles?.officeConsultationsAdmin === user1?.officeRoles?.officeConsultationsAdmin
                                        ? 'selected'
                                        : ''
                                }>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() =>
                                        onSelectOfficeRole('officeConsultationsAdmin', user1?.officeRoles?.officeConsultationsAdmin)
                                    }>
                                    {user1?.officeRoles?.officeConsultationsAdmin ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                            <td
                                className={
                                    selection?.officeRoles?.officeConsultationsAdmin === user2?.officeRoles?.officeConsultationsAdmin
                                        ? 'selected'
                                        : ''
                                }>
                                <Button
                                    inline
                                    variant={'link'}
                                    onClick={() =>
                                        onSelectOfficeRole('officeConsultationsAdmin', user2?.officeRoles?.officeConsultationsAdmin)
                                    }>
                                    {user2?.officeRoles?.officeConsultationsAdmin ? 'Ja' : 'Nei'}
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                    {user1Courses.map(uc1 => {
                        const uc2 = user2Courses.find(uc2 => uc2.course === uc1.course) || {}
                        const uc1tasks = Object.values(uc1.tasks ?? [])
                        const selectedCourse = Object.values(selection?.courses ?? {}).find(sc => sc.course === uc1.course)
                        const expanded = expandedTasks.includes(uc1.course)

                        return (
                            <React.Fragment key={uc1.course}>
                                <tbody>
                                    <tr>
                                        <th colSpan={3}>&nbsp;</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>{uc1.course}:</th>
                                    </tr>
                                    <tr>
                                        <td>Rolle:</td>
                                        <td className={selectedCourse?.id === uc1.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc1)}>
                                                {formatRole(uc1.role)}
                                            </Button>
                                        </td>
                                        <td className={selectedCourse?.id === uc2.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc2)}>
                                                {formatRole(uc2.role)}
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td className={selectedCourse?.id === uc1.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc1)}>
                                                {uc1.status}
                                            </Button>
                                        </td>
                                        <td className={selectedCourse?.id === uc2.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc2)}>
                                                {uc2.status ?? '~'}
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Opprettet:</td>
                                        <td className={selectedCourse?.id === uc1.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc1)}>
                                                {formatDate(uc1.createdAt)}
                                            </Button>
                                        </td>
                                        <td className={selectedCourse?.id === uc2.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc2)}>
                                                {formatDate(uc2.createdAt)}
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Vilkår akseptert:</td>
                                        <td className={selectedCourse?.id === uc1.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc1)}>
                                                {formatDate(uc1.acceptedAt)}
                                            </Button>
                                        </td>
                                        <td className={selectedCourse?.id === uc2.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc2)}>
                                                {formatDate(uc2.acceptedAt)}
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Fullført:</td>
                                        <td className={selectedCourse?.id === uc1.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc1)}>
                                                {formatDate(uc1.completedAt)}
                                            </Button>
                                        </td>
                                        <td className={selectedCourse?.id === uc2.id ? 'selected' : ''}>
                                            <Button inline variant={'link'} onClick={() => onSelectCourse(uc2)}>
                                                {formatDate(uc2.completedAt)}
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Oppgaver:</th>
                                        <td colSpan={2}>
                                            <Button inline variant={'link'} onClick={() => toggleExpandedCourse(uc1.course)}>
                                                {expanded ? 'Skjul alle' : 'Vis alle'}
                                            </Button>
                                        </td>
                                    </tr>
                                    {uc1tasks.map(ut1 => {
                                        const ut2 = Object.values(uc2.tasks ?? []).find(ut2 => ut2.task === ut1.task) || {}
                                        const selectedTask = (selectedCourse?.tasks ?? []).find(c => c.task === ut1.task)

                                        return (
                                            <Collapse expanded={expanded} as={'tr'} key={ut1.task}>
                                                <td>{ut1.taskName}</td>
                                                <td className={selectedTask?.id === ut1.id ? 'selected' : ''}>
                                                    <Button inline variant={'link'} onClick={() => onSelectTask(uc1.course, ut1)}>
                                                        {ut1.id ? `${ut1.progress}%` : '~'}
                                                    </Button>
                                                </td>
                                                <td className={selectedTask?.id === ut2.id ? 'selected' : ''}>
                                                    <Button inline variant={'link'} onClick={() => onSelectTask(uc1.course, ut2)}>
                                                        {ut2.id ? `${ut2.progress}%` : '~'}
                                                    </Button>
                                                </td>
                                            </Collapse>
                                        )
                                    })}
                                </tbody>
                            </React.Fragment>
                        )
                    })}
                    {filteredUser2Courses.map(uc2 => {
                        const uc2tasks = Object.values(uc2.tasks ?? [])
                        const expanded = expandedTasks.includes(uc2.course)

                        return (
                            <React.Fragment key={uc2.course}>
                                <tbody>
                                    <tr>
                                        <th colSpan={3}>&nbsp;</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>{uc2.course}:</th>
                                    </tr>
                                    <tr>
                                        <td>Rolle:</td>
                                        <td>~</td>
                                        <td className={'selected'}>{uc2.role}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>~</td>
                                        <td className={'selected'}>{uc2.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Opprettet:</td>
                                        <td>~</td>
                                        <td className={'selected'}>{formatDate(uc2.createdAt)}</td>
                                    </tr>
                                    <tr>
                                        <td>Vilkår akseptert:</td>
                                        <td>~</td>
                                        <td className={'selected'}>{formatDate(uc2.acceptedAt)}</td>
                                    </tr>
                                    <tr>
                                        <td>Fullført:</td>
                                        <td>~</td>
                                        <td className={'selected'}>{formatDate(uc2.completedAt)}</td>
                                    </tr>
                                    <tr>
                                        <th>Oppgaver:</th>
                                        <td colSpan={2}>
                                            <Button inline variant={'link'} onClick={() => toggleExpandedCourse(uc2.course)}>
                                                {expanded ? 'Skjul alle' : 'Vis alle'}
                                            </Button>
                                        </td>
                                    </tr>
                                    {uc2tasks.map(ut2 => {
                                        return (
                                            <Collapse expanded={expanded} as={'tr'} key={ut2.task}>
                                                <td>{ut2.taskName}</td>
                                                <td>~</td>
                                                <td className={'selected'}>{ut2.id ? `${ut2.progress}%` : '~'}</td>
                                            </Collapse>
                                        )
                                    })}
                                </tbody>
                            </React.Fragment>
                        )
                    })}
                </StyledTable>
                <Panel.Body>
                    <textarea
                        rows={10}
                        value={selection.message}
                        onChange={e => onSelect('message', e.target.value)}
                        className={'form-control'}
                    />
                    <span className={'help-block'}>La stå tomt for ikke sende epost automatisk.</span>
                    {!selection.message && <span className={'strong text-warning'}>Ingen melding vil bli sent!</span>}
                </Panel.Body>
                <Panel.Footer>
                    <Button onClick={onSubmit} variant={'danger'} confirm={'Er du helt sikker?'}>
                        Flett sammen brukere. Obs, dette kan ikke angres!
                    </Button>
                </Panel.Footer>
            </Panel>
        </Page>
    )
}
