import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Panel from '../Components/Panel'
import useEntity from '../Hooks/useEntity'
import Button from '../Components/Button/Button'
import useEntities from '../Hooks/useEntities'
import {useNavigate} from 'react-router'
import NewLocationForm from './NewLocationForm'
import FindUserModal from '../Components/FindUserModal'
import {jsonFetch} from '../Components/jsonFetch'
import LoadingComponent from '../Components/LoadingComponent'
import {Page} from '../Components/Page'
import TextField from '../Components/Fields/TextField'
import TextareaField from '../Components/Fields/TextareaField'
import {Box} from '@mui/material'
import DateTimeField from '../Components/Fields/DateTimeField'
import {SkilQueryResponseType, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../Utilities/errorHandlers'
import useUser from '../Utilities/useUser'
import PartnershipSignupModal from './PartnershipSignupModal'
import InvoiceComponent from './InvoiceComponent'
import PartnershipSignupInvoiceModal from './PartnershipSignupInvoiceModal'
import Moment from 'moment'
import SelectField from '../Components/Fields/SelectField'
import styled from 'styled-components'
import Divider from '@mui/material/Divider'
import LoadingButton from '@mui/lab/LoadingButton'
import FileField from '../Components/Fields/FileField'
import {useClickHandler} from '../Components/Button/utils'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {DeleteIconButton} from '../Components/Button/DeleteIconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Collapse from '@mui/material/Collapse'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import {RegionsExport} from '../Components/RegionsExport'

const StyledFileField = styled(FileField)`
    margin-bottom: 0;
`

const StyledPanel = styled(Panel)`
    display: inline-block;
    width: 100%;
`
const StyledItem = styled(Panel.Body)`
    padding: 0;
`

const StyledItemHeader = styled(({expanded, children, ...props}) => <Panel children={children} {...props} />)(({expanded}) => ({
    marginBottom: expanded ? '4em' : '0',
    borderColor: expanded ? '#ddd' : '#fff',
    boxShadow: expanded ? '1px 1px 5px 5px #ddd' : 'none',
    ...{
        '.panel-heading': {
            backgroundColor: '#fff',
            borderBottom: '0',
        },
        '.panel-body': {
            paddingTop: '0',
        },
    },
}))

type PartnershipSignupstype = SkilQueryResponseType<'getPartnershipSignupsCollection'>['hydra:member'][number]
type PartnershipSignupInvoiceDetailsType = SkilQueryResponseType<'getPartnershipSignupInvoiceDetailsItem'>
type SeminarType = SkilQueryResponseType<'getCourseItem'>

const PartnershipDetails = ({}) => {
    const user = useUser()
    const {id} = useParams()
    const partnership = useEntity<'getPartnershipItem'>(`/api/partnerships/${id}`)
    const regions = RegionsExport(true)
    const updatePartnershipMutation = useSkilMutation<'patchPartnershipItem'>('PATCH', `${partnership['@id']}`)

    const {data: partnershipSignupsData, refetch: refreshPartnershipSignups} = useSkilQuery<'getPartnershipSignupsCollection'>(
        `/api/partnerships/${id}/signups`,
        null,
        {
            enabled: !!id,
            onSuccess: data => {
                setSignups(data?.['hydra:member'])
            },
        }
    )
    const {
        data: qualityReportCollection,
        refetch: refreshQualityReports,
        isLoading: isQualityReportsLoading,
    } = useSkilQuery<'getPartnershipQualityReportCollection'>(`/api/partnerships/${id}/quality_reports`, null, {
        enabled: !!id && user.voters.isSkilUser(),
    })

    const {data: completedSeminarsCollection} = useSkilQuery<'getPartnershipCompletedSeminarCollection'>(
        `/api/partnerships/${id}/completed_seminars`,
        null,
        {
            enabled: !!id && user.voters.isSkilUser(),
        }
    )
    const completedSeminars = completedSeminarsCollection?.['hydra:member'] ?? []
    const qualityReports = qualityReportCollection?.['hydra:member'] ?? []
    const partnershipSignups: PartnershipSignupstype[] = partnershipSignupsData?.['hydra:member'] ?? []
    const members = useEntities<'getPartnershipMembers'>(`/api/partnerships/${id}/members`)
    const isMember = members.some(m => m.user === user['@id'])
    const createPartnershipSignupMutation = useSkilMutation<'createPartnershipSignupDetailsItem'>('POST', ``)
    const createInvoiceDetailsMutation = useSkilMutation<'createPartnershipSignupInvoiceDetailsItem'>('POST', ``)
    const updateInvoiceDetailsMutation = useSkilMutation<'patchPartnershipSignupInvoiceDetailsItem'>(
        'PATCH',
        `/api/partnership_signup_invoice_details/${null}`
    )

    const deleteInvoiceDetailsMutation = useSkilMutation<'deletePartnershipSignupInvoiceDetailsItem'>(
        'DELETE',
        `/api/partnership_signup_invoice_details/${null}`
    )
    const deletePartnershipSignupMutation = useSkilMutation<'deletePartnershipSignupItem'>('DELETE', `/api/partnership_signups/${null}`)
    const updatePartnershipSignupMutation = useSkilMutation<'patchPartnershipSignupItem'>('PATCH', `/api/partnership_signups/${null}`)
    const deleteAttachmentMutation = useSkilMutation<'deletePartnershipAttachment'>('DELETE', `/api/partnership_attachments/${null}`)
    const {data: templates} = useSkilQuery<'getCourseItem'>(`/api/seminar/templates`)
    const seminarTemplates = templates?.['hydra:member'] ?? []
    const [showMemberModal, setShowMemberModal] = useState(false)
    const [showLocationModal, setShowLocationModal] = useState(false)
    const [showUploadReport, setShowUploadReport] = useState(false)
    const [file, setFile] = React.useState()
    const [fileSeminar, setFileSeminar] = React.useState<string>('')
    const [signups, setSignups] = useState<PartnershipSignupstype[]>([])
    const [showCreatePartnershipSignupModal, setShowCreatePartnershipSignupModal] = useState(false)
    const [showCreatePartnershipSignupInvoiceModal, setShowCreatePartnershipSignupInvoiceModal] = useState<boolean[]>([])
    // Necessary for targeted rerendering of the InvoiceComponent component when the reset button is clicked
    const [renderKey, setRenderKey] = useState('')
    const [expandedSignupFields, setExpandedSignupFields] = useState<boolean[]>([])
    const [isDeletingSignupFields, setIsDeletingSignupFields] = useState<boolean>(false)
    let navigate = useNavigate()

    useEffect(() => {
        if (partnershipSignups.length > 0) {
            setRenderKey(Math.random().toString(36))
            setSignups(partnershipSignups)
            if (isDeletingSignupFields) {
                setExpandedSignupFields(partnershipSignups.map((_value, index: number) => false))
                setIsDeletingSignupFields(false)
            } else {
                setExpandedSignupFields(partnershipSignups.map((_value, index: number) => expandedSignupFields[index] ?? false))
            }
            setShowCreatePartnershipSignupInvoiceModal(partnershipSignups.map((_value, index: number) => false))
        }
    }, [partnershipSignups])

    const onRemoveLocation = area => {
        return jsonFetch(`${partnership['@id']}/remove_area`, {
            method: 'POST',
            json: area,
        }).then(() => {
            partnership.refresh()
        })
    }

    const onAddLocation = async newArea => {
        setShowLocationModal(false)
        await jsonFetch(`${partnership['@id']}/add_area`, {
            method: 'POST',
            json: newArea,
        })
        return partnership.refresh()
    }

    const onDelete = async () => {
        await partnership.remove()
        navigate('/dashboard/SKIL/samarbeid/')
    }

    const onAddMember = user => {
        setShowMemberModal(false)
        return members.create(user, `/api/partnerships/${id}/add_member`)
    }

    const onCreatePartnershipSignup = async signup => {
        if (!partnership['@id']) return
        try {
            await createPartnershipSignupMutation.mutateAsync({
                '@overridePath': `${partnership['@id']}/add_signup`,
                name: partnership?.name,
                partnership: partnership['@id'],
                description: signup?.description ?? null,
                seminarTheme: signup?.seminarTheme ?? null,
                plannedSeminarAt: signup?.plannedSeminarAt ?? null,
                educationMeetingAt: signup?.educationMeetingAt ?? null,
                informationMeetingAt: signup?.informationMeetingAt ?? null,
                debriefMeetingAt: signup?.debriefMeetingAt ?? null,
                contactPersonName: signup?.contactPersonName,
                contactPersonEmail: signup?.contactPersonEmail,
                contactPersonPhone: signup?.contactPersonPhone,
                contactPersonRole: signup?.contactPersonRole,
                signupOtherInfo: signup?.signupOtherInfo ?? null,
                signupJiraLink: signup?.signupJiraLink ?? null,

                orgNumber: signup.orgNumber,
                emailForInvoiceReceipt: signup.emailForInvoiceReceipt,
                resourceNumber: signup.resourceNumber,
                invoiceReference: signup.invoiceReference,
                invoiceOtherInfo: signup.invoiceOtherInfo,
                internalNotes: signup.internalNotes,
            })
            await refreshPartnershipSignups()
            toast('Faktura opplysningene ble lagret', {type: 'success'})
            setShowCreatePartnershipSignupModal(false)
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onCreateInvoiceDetails = async (invoice, signupIndex) => {
        if (!invoice?.partnershipSignup) return
        try {
            await createInvoiceDetailsMutation.mutateAsync({
                '@overridePath': `${invoice?.partnershipSignup}/add_invoice_details`,
                partnershipSignup: invoice?.partnershipSignup,
                name: invoice.name,
                orgNumber: invoice.orgNumber,
                emailForInvoiceReceipt: invoice.emailForInvoiceReceipt,
                resourceNumber: invoice.resourceNumber,
                invoiceReference: invoice.invoiceReference,
                invoiceOtherInfo: invoice.invoiceOtherInfo,
                internalNotes: invoice.internalNotes,
            })
            await refreshPartnershipSignups()
            toast('Faktura opplysningene ble lagret', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const onUpdateInvoiceDetails = async (invoice, signupIndex) => {
        if (!invoice['@id']) return
        if (
            !invoice.name ||
            !invoice.orgNumber ||
            invoice.orgNumber.toString().length !== 9 ||
            !invoice.emailForInvoiceReceipt ||
            !invoice.resourceNumber
        ) {
            toast('Obligatoriske fakturaopplysninger er ikke riktig fylt ut', {type: 'error'})
            return
        }
        try {
            await updateInvoiceDetailsMutation.mutateAsync({
                '@overridePath': `${invoice['@id']}`,
                // @ts-expect-error
                invoiceDetailsUpdatedAt: new Date(),
                invoiceDetailsUpdatedBy: user['@id'],
                name: invoice.name,
                orgNumber: invoice.orgNumber ?? null,
                emailForInvoiceReceipt: invoice.emailForInvoiceReceipt ?? null,
                resourceNumber: invoice.resourceNumber ?? null,
                invoiceReference: invoice.invoiceReference ?? null,
                invoiceOtherInfo: invoice.invoiceOtherInfo ?? null,
                internalNotes: invoice.internalNotes ?? null,
            })
            await refreshPartnershipSignups()
            toast('Endring av fakturadetaljene ble lagret', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    async function onDeleteInvoiceDetails(invoice, signupIndex) {
        if (!invoice['@id']) return
        try {
            await deleteInvoiceDetailsMutation.mutateAsync({
                '@overridePath': `${invoice['@id']}`,
            })
            await refreshPartnershipSignups()
            toast('Fakturadetaljene ble slettet', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    function resetInvoiceDetailsFields(signupIndex, invoiceIndex) {
        setSignups(prev => {
            // Clone the previous state to avoid direct mutation
            const resetSignups = [...prev]
            // Clone the nested array to which the update needs to be applied
            const resetPartnershipSignupInvoiceDetails: any[] = [...resetSignups[signupIndex].partnershipSignupInvoiceDetails]
            const originalPartnershipSignupInvoiceDetails: any[] = [...partnershipSignups[signupIndex].partnershipSignupInvoiceDetails]
            // Update the specific element within the nested array
            resetPartnershipSignupInvoiceDetails[invoiceIndex] = {
                ...originalPartnershipSignupInvoiceDetails[invoiceIndex],
            }
            // Update the nested array in the cloned state
            resetSignups[signupIndex] = {
                ...resetSignups[signupIndex],
                partnershipSignupInvoiceDetails: resetPartnershipSignupInvoiceDetails,
            }
            // Return the updated state
            return resetSignups
        })
    }

    const onUpdatePartnership = async partnership => {
        if (!partnership) {
            toast('Navnet til samarbeidet mangler', {type: 'error'})
            return
        }
        try {
            await updatePartnershipMutation.mutateAsync({
                // @ts-expect-error
                partnership: partnership['@id'],
                name: partnership.name,
                description: partnership.description,
            })
            await partnership.refresh().then(() => {
                partnership.reset()
                toast('Endringer i samarbeidet ble lagret', {type: 'success'})
            })
        } catch (e) {
            handleErrorWithToast(e)
            const description = partnership.description
            partnership.reset()
            partnership.changeField('description', description)
        }
    }

    const onUpdatePartnershipSignup = async signup => {
        if (!signup.partnershipSignup) return
        if (!signup.contactPersonName || !signup.contactPersonEmail || !signup.contactPersonPhone || !signup.contactPersonRole) {
            toast('Obligatoriske påmeldingsopplysninger er ikke riktig fylt ut', {type: 'error'})
            return
        }
        try {
            await updatePartnershipSignupMutation.mutateAsync({
                '@overridePath': `${signup.partnershipSignup}`,
                // @ts-expect-error
                signupUpdatedAt: new Date(),
                signupUpdatedBy: user['@id'],
                plannedSeminarAt: signup.plannedSeminarAt ?? null,
                educationMeetingAt: signup.educationMeetingAt ?? null,
                informationMeetingAt: signup.informationMeetingAt ?? null,
                debriefMeetingAt: signup.debriefMeetingAt ?? null,
                description: signup.description ?? null,
                seminarTheme: signup.seminarTheme ?? null,
                contactPersonName: signup.contactPersonName,
                contactPersonEmail: signup.contactPersonEmail,
                contactPersonPhone: signup.contactPersonPhone,
                contactPersonRole: signup.contactPersonRole,
                signupOtherInfo: signup.signupOtherInfo ?? null,
                signupJiraLink: signup.signupJiraLink ?? null,
            })
            await refreshPartnershipSignups()
            toast('Endring av påmeldingsopplysninger ble lagret', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    function updateSignupField(signupIndex, field, value) {
        setSignups(prev => {
            // Clone the previous state to avoid direct mutation
            const updatedSignups = [...prev]
            // Update the array in the cloned state
            updatedSignups[signupIndex] = {
                ...updatedSignups[signupIndex],
                [field]: value,
            }
            // Return the updated state
            return updatedSignups
        })
    }

    function resetSignupFields(signupIndex) {
        setSignups(prev => {
            // Clone the previous state to avoid direct mutation
            const resetSignup = [...prev]
            // Reset the array in the cloned state
            resetSignup[signupIndex] = {
                ...partnershipSignups[signupIndex],
            }
            // Return the reset state
            return resetSignup
        })
    }

    async function onDeletePartnershipSignup(signup) {
        if (!signup.partnershipSignup) return
        setIsDeletingSignupFields(true)
        try {
            await deletePartnershipSignupMutation.mutateAsync({
                '@overridePath': `${signup.partnershipSignup}`,
            })
            await refreshPartnershipSignups()
            toast('Påmeldingen ble slettet', {type: 'success'})
        } catch (e) {
            handleErrorWithToast(e)
            setIsDeletingSignupFields(false)
        }
    }

    const onAddAttachment = async () => {
        // fileType if overriden in UploadQualityReport.php

        if (!fileSeminar) {
            toast('Velg seminar for å laste opp fil', {type: 'error'})
            return
        }
        await FileField.UploadFile(file, 13, `${partnership['@id']}/upload_attachment`, false, fileSeminar)
        setFile(undefined)
        setShowUploadReport(false)
        await refreshQualityReports()
    }
    const onDeleteAttachment = async iri => {
        await deleteAttachmentMutation.mutateAsync({
            '@overridePath': iri,
        })
        await refreshQualityReports()
    }
    const clickHandler = useClickHandler(onAddAttachment)

    if (!partnership['@loaded'] || !regions)
        return (
            <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <LoadingComponent />
            </div>
        )

    const breadcrumbs = [
        {title: 'SKIL', to: '/dashboard/SKIL'},
        {title: 'Samarbeid', to: '/dashboard/SKIL/samarbeid'},
        {title: partnership.name, path: ''},
    ]

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <div className={'col-sm-8 col-md-6'}>
                <Panel title={<span>{partnership['name']}</span>} variant={'primary'}>
                    <Panel.Body>
                        <TextField
                            id='name'
                            label='Samarbeid navn'
                            placeholder='Bergen'
                            required
                            value={partnership.name}
                            onChange={partnership.changeField('name')}
                        />
                        <TextareaField
                            id='description'
                            label='SKILs interne notater'
                            value={partnership.description}
                            onChange={partnership.changeField('description')}
                        />
                        {isMember && partnership['@loaded'] && (
                            <a href={`/dashboard/kommuner/${partnership.id}`}>Gå til Kommunedashboard</a>
                        )}
                    </Panel.Body>
                    <Panel.Footer>
                        {partnership['@changes'] && (
                            <>
                                <Button variant={'primary'} onClick={() => onUpdatePartnership(partnership)}>
                                    Lagre endringer
                                </Button>
                                <Button onClick={() => partnership.reset()}>Tilbakestill</Button>
                            </>
                        )}
                        <Button
                            variant={'danger'}
                            confirm={signups.length > 0 ? 'Er du sikker? Dette vil også slette alle påmeldinger' : 'Er du sikker?'}
                            onClick={onDelete}>
                            Slett
                        </Button>
                        <Button variant={'link'} to={'/dashboard/SKIL/samarbeid'}>
                            Tilbake
                        </Button>
                    </Panel.Footer>
                </Panel>
            </div>
            <div className={'col-sm-6 col-md-6'}>
                <Panel variant={'default'} title={'Brukere med tilgang til kommunedashboard'}>
                    {!members['@loaded'] && (
                        <Panel.Body>
                            <LoadingComponent msg={'Laster inn medlemmer'} />
                        </Panel.Body>
                    )}
                    <table className={'table'}>
                        <thead>
                            <tr>
                                <th>Navn</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {members.map(m => {
                                const userId = m.user!.substring(m.user!.lastIndexOf('/') + 1)

                                return (
                                    <tr key={m.id}>
                                        <td>
                                            <Button inline variant={'link'} href={`/dashboard/users/${userId}`}>
                                                {m.userName}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                inline
                                                variant={'link'}
                                                onClick={() => members.remove(m['@id']!)}
                                                confirm={'Bekreft fjerning'}>
                                                Fjern
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Panel.Footer>
                        <Button variant={'primary'} onClick={() => setShowMemberModal(true)}>
                            Nytt medlem
                        </Button>
                    </Panel.Footer>
                    {showMemberModal && <FindUserModal onClose={() => setShowMemberModal(false)} onSave={onAddMember} />}
                </Panel>
            </div>
            <div className={'col-sm-6 col-md-6'}>
                <Panel variant={'default'} title={'Områder'}>
                    {!partnership['@loaded'] && (
                        <Panel.Body>
                            <LoadingComponent msg={'Laster inn områder'} />
                        </Panel.Body>
                    )}
                    <div className={'table-responsive'}>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>Fylke</th>
                                    <th>Kommune</th>
                                    <th>Bydel</th>
                                    <th>Legekontor</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {(partnership.areas ?? []).map(l => (
                                    <PartnershipLocation key={l.id} location={l} onRemove={() => onRemoveLocation(l)} regions={regions} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Panel.Footer>
                        <Button variant={'primary'} onClick={() => setShowLocationModal(true)}>
                            Nytt område
                        </Button>
                    </Panel.Footer>
                    {showLocationModal && (
                        <NewLocationForm onClose={() => setShowLocationModal(false)} onSubmit={onAddLocation} regions={regions} />
                    )}
                </Panel>
            </div>
            <div className={'col-sm-8 col-md-6'}>
                <StyledPanel variant={'default'} title={'Påmeldinger til kvalitetsseminar'}>
                    {signups.map((signup, signupIndex) => {
                        const partnershipSignup = partnershipSignups[signupIndex]

                        const partnershipSignupHasChanges =
                            partnershipSignup &&
                            signup &&
                            (partnershipSignup?.contactPersonName !== signup?.contactPersonName ||
                                partnershipSignup?.contactPersonEmail !== signup?.contactPersonEmail ||
                                partnershipSignup?.contactPersonPhone !== signup?.contactPersonPhone ||
                                partnershipSignup?.contactPersonRole !== signup?.contactPersonRole ||
                                partnershipSignup?.signupOtherInfo !== signup?.signupOtherInfo ||
                                partnershipSignup?.seminarTheme !== signup?.seminarTheme ||
                                partnershipSignup?.plannedSeminarAt !== signup?.plannedSeminarAt ||
                                partnershipSignup?.educationMeetingAt !== signup?.educationMeetingAt ||
                                partnershipSignup?.informationMeetingAt !== signup?.informationMeetingAt ||
                                partnershipSignup?.debriefMeetingAt !== signup?.debriefMeetingAt ||
                                partnershipSignup?.description !== signup?.description ||
                                partnershipSignup?.signupJiraLink !== signup?.signupJiraLink)

                        const invoiceDetails = signup?.partnershipSignupInvoiceDetails as unknown as PartnershipSignupInvoiceDetailsType[]
                        let chosenSeminarTemplate: SeminarType | null = null
                        if (signup.seminarTheme) {
                            chosenSeminarTemplate = seminarTemplates.find(seminarTemplate => seminarTemplate['@id'] === signup.seminarTheme)
                        }
                        const expanded = expandedSignupFields[signupIndex]
                        const isLastItem = signups.indexOf(signup) === signups.length - 1
                        return (
                            <div key={signup.partnershipSignup + signupIndex}>
                                <StyledItem>
                                    <StyledItemHeader
                                        expanded={expanded}
                                        title={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: expanded ? 'flex-start' : 'baseline',
                                                    marginTop: expanded ? '1em' : 'initial',
                                                }}>
                                                {expanded ? (
                                                    <SelectField
                                                        label={'Seminar tema'}
                                                        id={'template'}
                                                        value={signup.seminarTheme}
                                                        entities={seminarTemplates}
                                                        onChange={seminar => {
                                                            if (seminar) {
                                                                updateSignupField(signupIndex, 'seminarTheme', seminar?.['@id'])
                                                            } else {
                                                                toast('Seminar tema mangler', {type: 'error'})
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <span>
                                                        {chosenSeminarTemplate ? chosenSeminarTemplate.name : 'Seminar tema mangler'}
                                                    </span>
                                                )}
                                                <Button
                                                    onClick={() =>
                                                        setExpandedSignupFields(prev => {
                                                            // Clone the previous state to avoid direct mutation
                                                            const expendedSignups = [...prev]
                                                            const isExpanded = expendedSignups[signupIndex]
                                                            // Reset the array in the cloned state
                                                            expendedSignups[signupIndex] = !isExpanded
                                                            // Return the reset state
                                                            return expendedSignups
                                                        })
                                                    }
                                                    style={{marginLeft: '1em'}}
                                                    variant={'link'}>
                                                    {expanded ? 'Lukk ' : 'Åpne '}
                                                    <i className={expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
                                                </Button>
                                            </div>
                                        }
                                        variant={'default'}>
                                        <Panel.Body className={expanded ? '' : 'hidden'}>
                                            <Box mb={4} style={{display: 'inline-grid'}}>
                                                <label>Påmeldingsskjema</label>{' '}
                                                {signup.updatedAt ? (
                                                    <span style={{fontSize: 'small'}}>
                                                        Sist oppdatert: {new Date(signup.updatedAt).toLocaleDateString()} av{' '}
                                                        {signup.updatedBy}
                                                        {!signup.updatedBy ? 'ePortalen' : ''}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </Box>
                                            <div className={'row'}>
                                                <div className={'col-sm-6 col-lg-6'}>
                                                    <DateTimeField
                                                        id='plannedSeminarAt'
                                                        label='Planlagt seminar'
                                                        value={signup.plannedSeminarAt}
                                                        required={false}
                                                        onChange={(value, e) => {
                                                            let date = Moment(value)
                                                            date.format()
                                                            updateSignupField(signupIndex, 'plannedSeminarAt', date.format())
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-sm-6 col-lg-6'}>
                                                    <DateTimeField
                                                        id='educationMeetingAt'
                                                        label='Opplæringsmøte'
                                                        value={signup.educationMeetingAt}
                                                        required={false}
                                                        onChange={(value, e) => {
                                                            let date = Moment(value)
                                                            date.format()
                                                            updateSignupField(signupIndex, 'educationMeetingAt', date.format())
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-sm-6 col-lg-6'}>
                                                    <DateTimeField
                                                        id='informationMeetingAt'
                                                        label='Informasjonsmøte'
                                                        value={signup.informationMeetingAt}
                                                        required={false}
                                                        onChange={(value, e) => {
                                                            let date = Moment(value)
                                                            date.format()
                                                            updateSignupField(signupIndex, 'informationMeetingAt', date.format())
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-sm-6 col-lg-6'}>
                                                    <DateTimeField
                                                        id='debriefMeetingAt'
                                                        label='Oppsummeringsmøte'
                                                        value={signup.debriefMeetingAt}
                                                        required={false}
                                                        onChange={(value, e) => {
                                                            let date = Moment(value)
                                                            date.format()
                                                            updateSignupField(signupIndex, 'debriefMeetingAt', date.format())
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <TextField
                                                id='ContactPersonRole'
                                                label='Kontaktperson stilling'
                                                placeholder='Kommuneoverlege'
                                                value={signup?.contactPersonRole}
                                                required={true}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'contactPersonRole', value)
                                                }}
                                            />
                                            <TextField
                                                id='ContactPersonName'
                                                label='Kontaktperson navn'
                                                placeholder='Ola Normann'
                                                value={signup?.contactPersonName}
                                                required={true}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'contactPersonName', value)
                                                }}
                                            />
                                            <TextField
                                                id='ContactPersonEmail'
                                                label='Kontaktperson e-post'
                                                placeholder='ola@normann.no'
                                                value={signup?.contactPersonEmail}
                                                required={true}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'contactPersonEmail', value)
                                                }}
                                            />
                                            <TextField
                                                id='ContactPersonPhone'
                                                label='Kontaktperson telefon'
                                                placeholder='927701057'
                                                type={'number'}
                                                value={signup?.contactPersonPhone}
                                                required={true}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'contactPersonPhone', value)
                                                }}
                                            />
                                            <TextareaField
                                                id='signupOtherInfo'
                                                label='Andre opplysninger'
                                                value={signup.signupOtherInfo ?? ''}
                                                required={false}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'signupOtherInfo', value)
                                                }}
                                            />
                                            <hr />
                                            <TextareaField
                                                id='description'
                                                label='SKILs interne notater'
                                                value={signup.description ?? ''}
                                                required={false}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'description', value)
                                                }}
                                            />
                                            <TextField
                                                id='jiraLink'
                                                label='Jira lenke til påmeldingsopplysninger'
                                                placeholder='Lenke til Jira oppgave'
                                                value={signup?.signupJiraLink}
                                                required={false}
                                                onChange={(value, e) => {
                                                    updateSignupField(signupIndex, 'signupJiraLink', value)
                                                }}
                                            />
                                            {signup?.signupJiraLink && (
                                                <div className={'col-sm-6 col-lg-6'}>
                                                    <a href={signup?.signupJiraLink} target='_blank'>
                                                        Jira oppgave
                                                    </a>
                                                </div>
                                            )}
                                            <hr />
                                            {invoiceDetails.map((invoice, invoiceIndex: number) => {
                                                const partnershipSignupsItem =
                                                    partnershipSignups[signupIndex]['partnershipSignupInvoiceDetails'][invoiceIndex]
                                                const invoiceDetailsHasChanges =
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.name !== invoice?.name ||
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.orgNumber !== invoice?.orgNumber ||
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.emailForInvoiceReceipt !== invoice?.emailForInvoiceReceipt ||
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.resourceNumber !== invoice?.resourceNumber ||
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.invoiceReference !== invoice?.invoiceReference ||
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.invoiceOtherInfo !== invoice?.invoiceOtherInfo ||
                                                    //@ts-expect-error
                                                    partnershipSignupsItem?.internalNotes !== invoice?.internalNotes
                                                return (
                                                    <Box
                                                        key={signup.partnershipSignup + invoiceIndex + renderKey}
                                                        className={'col-sm-6 col-md-6'}
                                                        sx={{
                                                            paddingLeft: {xs: '0em', sm: '0.5em'},
                                                            paddingRight: {xs: '0em', sm: '0.5em'},
                                                        }}>
                                                        <Panel title={<span>Fakturadetaljer</span>} variant={'default'}>
                                                            <Panel.Body>
                                                                <InvoiceComponent
                                                                    signupIndex={signupIndex}
                                                                    invoiceIndex={invoiceIndex}
                                                                    invoice={invoice}
                                                                    setSignups={setSignups}
                                                                />
                                                            </Panel.Body>
                                                            <Panel.Footer>
                                                                {invoiceDetailsHasChanges && (
                                                                    <Button
                                                                        variant={'primary'}
                                                                        onClick={() => onUpdateInvoiceDetails(invoice, signupIndex)}>
                                                                        Lagre endringer
                                                                    </Button>
                                                                )}
                                                                {invoiceDetailsHasChanges && (
                                                                    <Button
                                                                        variant={'link'}
                                                                        onClick={() => {
                                                                            setRenderKey(Math.random().toString(36))
                                                                            resetInvoiceDetailsFields(signupIndex, invoiceIndex)
                                                                        }}>
                                                                        Tilbakestill
                                                                    </Button>
                                                                )}
                                                                <Button
                                                                    variant={'danger'}
                                                                    confirm={'Er du sikker?'}
                                                                    onClick={() =>
                                                                        onDeleteInvoiceDetails(invoice, signupIndex).then(() => {})
                                                                    }>
                                                                    Slett
                                                                </Button>
                                                            </Panel.Footer>
                                                        </Panel>
                                                    </Box>
                                                )
                                            })}
                                        </Panel.Body>
                                        <Panel.Footer className={expanded ? '' : 'hidden'}>
                                            <>
                                                {partnershipSignupHasChanges && (
                                                    <>
                                                        <Button variant={'primary'} onClick={() => onUpdatePartnershipSignup(signup)}>
                                                            Lagre endringer
                                                        </Button>
                                                        <Button onClick={() => resetSignupFields(signupIndex)}>Tilbakestill</Button>
                                                    </>
                                                )}
                                            </>
                                            <Button
                                                variant={'danger'}
                                                confirm={'Er du sikker?'}
                                                onClick={() => onDeletePartnershipSignup(signup)}>
                                                Slett
                                            </Button>
                                            <Button
                                                variant={'primary'}
                                                onClick={() => {
                                                    setShowCreatePartnershipSignupInvoiceModal(prev => {
                                                        // Clone the previous state to avoid direct mutation
                                                        const expendedSignups = [...prev]
                                                        const isExpanded = expendedSignups[signupIndex]
                                                        // Reset the array in the cloned state
                                                        expendedSignups[signupIndex] = !isExpanded
                                                        // Return the reset state
                                                        return expendedSignups
                                                    })
                                                }}>
                                                Opprett nye fakturadetaljer
                                            </Button>
                                        </Panel.Footer>
                                    </StyledItemHeader>
                                    {showCreatePartnershipSignupInvoiceModal[signupIndex] && (
                                        <PartnershipSignupInvoiceModal
                                            signup={signup}
                                            signupIndex={signupIndex}
                                            onSubmit={onCreateInvoiceDetails}
                                            onClose={() => {
                                                setShowCreatePartnershipSignupInvoiceModal(prev => {
                                                    // Clone the previous state to avoid direct mutation
                                                    const expendedSignups = [...prev]
                                                    const isExpanded = expendedSignups[signupIndex]
                                                    // Reset the array in the cloned state
                                                    expendedSignups[signupIndex] = !isExpanded
                                                    // Return the reset state
                                                    return expendedSignups
                                                })
                                            }}
                                        />
                                    )}
                                </StyledItem>
                                {!isLastItem && <Divider orientation='horizontal' flexItem style={{border: '1px solid #f4f4f4'}} />}
                            </div>
                        )
                    })}
                    <Panel.Footer>
                        <Button variant={'primary'} onClick={() => setShowCreatePartnershipSignupModal(true)}>
                            Opprett ny påmelding
                        </Button>
                    </Panel.Footer>
                </StyledPanel>
            </div>
            {user.voters.isSkilUser() && (
                <div className={'col-sm-6 col-md-6'}>
                    <StyledPanel variant={'default'} title={'Kvalitetsrapporter'}>
                        {isQualityReportsLoading && (
                            <Panel.Body>
                                <LoadingComponent msg={'Laster inn rapporter'} />
                            </Panel.Body>
                        )}
                        {!isQualityReportsLoading && qualityReports.length > 0 && (
                            <>
                                <StyledItem>
                                    {qualityReports?.map(attachment => {
                                        const isLastItem = qualityReports.indexOf(attachment) === qualityReports.length - 1
                                        return (
                                            <StyledItemHeader
                                                key={attachment.id}
                                                expanded={false}
                                                title={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginTop: 'initial',
                                                        }}>
                                                        <a
                                                            target={'_blank'}
                                                            href={attachment.file + '/download'}
                                                            style={{display: 'flex', alignItems: 'center'}}>
                                                            <AttachFileIcon fontSize='small' style={{marginRight: '0.5em'}} />
                                                            {attachment.filename}
                                                        </a>
                                                        <Button
                                                            onClick={() => onDeleteAttachment(attachment.qualityReport)}
                                                            confirm={'Bekreft sletting'}
                                                            variant={'link'}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                marginLeft: '1em',
                                                                paddingRight: '0',
                                                            }}>
                                                            <span>Slett</span>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                }
                                                variant={'default'}>
                                                {!isLastItem && (
                                                    <Divider orientation='horizontal' flexItem style={{border: '1px solid #f4f4f4'}} />
                                                )}
                                            </StyledItemHeader>
                                        )
                                    })}
                                </StyledItem>
                            </>
                        )}
                        {completedSeminars.length > 0 && (
                            <Panel.Footer>
                                <Box title='Vedlegg'>
                                    <List sx={{padding: '0'}}>
                                        {/* add attachment button */}
                                        <ListItem sx={{padding: '0'}}>
                                            <ListItemButton onClick={() => setShowUploadReport(open => !open)}>
                                                <ListItemIcon>{showUploadReport ? <RemoveIcon /> : <AddIcon />}</ListItemIcon>
                                                <ListItemText primary={'Legg til kvalitetsrapport'} />
                                            </ListItemButton>
                                        </ListItem>
                                        {/* upload accordion */}
                                        <ListItem sx={{padding: '0', marginTop: showUploadReport ? '2em' : '0'}}>
                                            <Collapse
                                                sx={{width: '100%', position: 'relative'}}
                                                orientation='vertical'
                                                in={showUploadReport}
                                                timeout='auto'
                                                unmountOnExit>
                                                <SelectField
                                                    label={'Utførte seminar'}
                                                    id={'template'}
                                                    value={fileSeminar}
                                                    //@ts-expect-error
                                                    entities={completedSeminars}
                                                    labelFn={(seminar: {'@id': string; '@type': string; id: number; title: string}) => {
                                                        return seminar?.title ?? 'Ukjent'
                                                    }}
                                                    onChange={seminar => {
                                                        if (seminar) {
                                                            setFileSeminar(seminar?.['@id'])
                                                        } else {
                                                            toast('Utført seminar mangler', {type: 'error'})
                                                        }
                                                    }}
                                                />
                                                <StyledFileField label={'Last opp'} value={file} onChange={file => setFile(file)} />
                                                <LoadingButton
                                                    disabled={!file || !fileSeminar}
                                                    loading={clickHandler.working}
                                                    onClick={clickHandler.handleClick}
                                                    sx={{position: 'absolute', right: 0, bottom: 0}}
                                                    variant='contained'>
                                                    Lagre
                                                </LoadingButton>
                                            </Collapse>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Panel.Footer>
                        )}
                        {completedSeminars.length === 0 && (
                            <Panel.Footer>
                                <Box title='Vedlegg'>
                                    <small>Ingen kvalitetsseminar er utført, ikke mulig å laste opp kvalitetsrapport</small>
                                </Box>
                            </Panel.Footer>
                        )}
                    </StyledPanel>
                </div>
            )}
            {showCreatePartnershipSignupModal && (
                <PartnershipSignupModal
                    onSubmit={onCreatePartnershipSignup}
                    onClose={() => setShowCreatePartnershipSignupModal(false)}
                    seminarTemplates={seminarTemplates}
                />
            )}
        </Page>
    )
}
export default PartnershipDetails

const PartnershipLocation = ({location, onRemove, regions}) => {
    const office = useEntity<'getOfficeItem'>(location.office)
    const region = regions?.find(region => region.value === location.region)
    return (
        <tr>
            <td>{region?.label ?? <i>Alle</i>}</td>
            <td>{location.county?.name ?? <i>Alle</i>}</td>
            <td>{location.district?.name ?? <i>Alle</i>}</td>
            <td>{location.office ? office['@loaded'] ? office.name : <LoadingComponent size={'small'} /> : <i>Alle</i>}</td>
            <td>
                <Button inline variant={'link'} onClick={onRemove}>
                    Fjern
                </Button>
            </td>
        </tr>
    )
}
