import React, {useState} from 'react'
import {Button} from '../../Components/Button/MuiButton'

const GdprItems = [
    {
        icon: 'fa fa-file-alt',
        title: 'Informasjonskapsler',
        description: 'Vi bruker kun informasjonskapsler (cookies) for å kunne logge brukeren din på ePortalen.',
    },
    {
        icon: 'fa fa-user',
        title: 'Kontaktdetaljer',
        description:
            'Vi lagrer offentlig informasjon om legekontoret, epostadresse, navn og mobilnummer for å organisere gruppemøter effektivt.',
    },
    {
        icon: 'fa fa-hospital',
        title: 'Helfo',
        description: 'Vi kobler brukeren din til Helfo-profilen din, for å lettere organisere gruppemøter.',
    },
    {
        icon: 'fa fa-shield-check',
        title: 'Sikkerhetslogg',
        description: 'Vi logger viktige hendelser om din bruker for å ivareta sikkerheten (hendelse, tidspunkt, ip-addresse og hvem).',
    },
    {
        icon: 'fa fa-exclamation-circle',
        title: 'Arbeidsark',
        description:
            'Noen av kursoppgavene vil inkludere utfylling av arbeidsark og vil bli lagret tilknyttet til brukeren din. Disse vil kun bli brukt til å lage aggregerte rapporter.',
    },
]

export default function GdprList() {
    const [showGDPR, setShowGDPR] = useState<string | null>(null)
    return (
        <ul className='media-list'>
            {GdprItems.map((item, index) => (
                <li className='media' key={item.title}>
                    <div className='media-body'>
                        <Button
                            variant={'text'}
                            onClick={() => setShowGDPR(title => (title === item.title ? null : item.title))}
                            data-toggle='collapse'
                            size={'large'}
                            aria-expanded={showGDPR === item.title}
                            aria-controls={'gdpr_content_' + {index}}
                        >
                            <i className={item.icon}></i> {item.title} <small>(klikk for mer info)</small>
                        </Button>
                        <p style={{paddingLeft: 12}} hidden={showGDPR !== item.title}>
                            {item.description}
                        </p>
                    </div>
                </li>
            ))}
        </ul>
    )
}
