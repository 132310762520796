import React, {useState, useEffect} from 'react'
import useEntity from '../Hooks/useEntity'
import useSendText from './useSendText'
import Modal from '../Components/Modal'
import TextareaField from '../Components/Fields/TextareaField'

const TextModal = ({iri, defaultContent, onClose}) => {
    const [content, setContent] = useState('')
    const consultation = useEntity<'getConsultationItem'>(iri)
    const sendText = useSendText(consultation)

    useEffect(() => {
        setContent(defaultContent)
    }, [defaultContent])

    const onSave = () => {
        sendText(content)
        onClose()
    }
    if (!consultation['@id']) return null
    return (
        <Modal title={'Send melding'} onClose={onClose} onSave={onSave} cancelText={'Ikke send'} saveText={'Send'}>
            <form>
                <TextareaField id='content' label='Innhold' rows={7} required value={content} onChange={setContent} />
            </form>
        </Modal>
    )
}
export default TextModal
