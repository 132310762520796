export const APP_ROUTES = {
    HOME: '/',
    TOOLS: 'tools',
    GOALS23: 'goals23',
    GOALS24: 'goals24',
    OFFICES: 'offices',
    COURSES: 'courses',
    PARTICIPANTS: 'participants',
    GROUPS: 'groups',
    MEETINGS: 'meetings',
    USERS: 'users',
    COUNSELORS: 'counselors',
    PARTNERSHIPSIGNUPS: 'samarbeid/signups',
    PARTNERSHIPS: 'samarbeid',
    JOBS: 'jobs',
    MASS_MAILING: 'massmailing',
    NEWS_POPUPS: 'news_popups',
    USER_ROLES: 'userroles',
    REPORTS_NEFLE_USAGE: 'reports/nefle-usage',
    MERGE_USERS: 'merge_users',
    FORMS: 'forms',
    PARTNERSHIPS_STATISTICS: 'samarbeid_stats',
    STATISTICS_COURSE_COMPLETIONS: 'statistics/course_completions',
    NEFLE_DISCOUNT_CODES: 'nefle/discount_codes',
}

export const ROUTES = {
    HOME: `/dashboard/SKIL/`,
    TOOLS: `/dashboard/SKIL/${APP_ROUTES.TOOLS}`,
    GOALS23: `/dashboard/SKIL/${APP_ROUTES.GOALS23}`,
    GOALS24: `/dashboard/SKIL/${APP_ROUTES.GOALS24}`,
    OFFICES: `/dashboard/SKIL/${APP_ROUTES.OFFICES}`,
    COURSES: `/dashboard/SKIL/${APP_ROUTES.COURSES}`,
    PARTICIPANTS: `/dashboard/SKIL/${APP_ROUTES.PARTICIPANTS}`,
    GROUPS: `/dashboard/SKIL/${APP_ROUTES.GROUPS}`,
    MEETINGS: `/dashboard/SKIL/${APP_ROUTES.MEETINGS}`,
    USERS: `/dashboard/SKIL/${APP_ROUTES.USERS}`,
    COUNSELORS: `/dashboard/SKIL/${APP_ROUTES.COUNSELORS}`,
    PARTNERSHIPSIGNUPS: `/dashboard/SKIL/${APP_ROUTES.PARTNERSHIPSIGNUPS}`,
    PARTNERSHIPS: `/dashboard/SKIL/${APP_ROUTES.PARTNERSHIPS}`,
    PARTNERSHIPS_STATISTICS: `/dashboard/SKIL/${APP_ROUTES.PARTNERSHIPS_STATISTICS}`,
    JOBS: `/dashboard/SKIL/${APP_ROUTES.JOBS}`,
    MASS_MAILING: `/dashboard/SKIL/${APP_ROUTES.MASS_MAILING}`,
    NEWS_POPUPS: `/dashboard/SKIL/${APP_ROUTES.NEWS_POPUPS}`,
    USER_ROLES: `/dashboard/SKIL/${APP_ROUTES.USER_ROLES}`,
    REPORTS_NEFLE_USAGE: `/dashboard/SKIL/${APP_ROUTES.REPORTS_NEFLE_USAGE}`,
    MERGE_USERS: `/dashboard/SKIL/${APP_ROUTES.MERGE_USERS}`,
    FORMS: `/dashboard/SKIL/${APP_ROUTES.FORMS}`,
    STATISTICS_COURSE_COMPLETIONS: `/dashboard/SKIL/${APP_ROUTES.STATISTICS_COURSE_COMPLETIONS}`,
    NEFLE_DISCOUNT_CODES: `/dashboard/SKIL/${APP_ROUTES.NEFLE_DISCOUNT_CODES}`,
}
