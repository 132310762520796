import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import LoadingComponent from '../../Components/LoadingComponent'
import {format, subMonths} from 'date-fns'
import GoalCardAllCoursesCompletions from './GoalCardAllCoursesCompletions'

const Type0CourseCompletions = () => {
    const {data: stats, isFetching} = useSkilQuery<'getAllCoursesCompletionsStatistics'>('/api/courses/statistics/all_courses_completions')

    const date27MonthsAgo = subMonths(new Date(), 27)
    const dateFrom = format(date27MonthsAgo, 'dd.MM.yy')
    const date9MonthsAgo = subMonths(new Date(), 9)
    const dateUntil = format(date9MonthsAgo, 'dd.MM.yy')
    const dateFromUntil = dateFrom + ' til ' + dateUntil
    if (!stats || isFetching) {
        return <LoadingComponent />
    }

    return (
        <GoalCardAllCoursesCompletions
            title='Seminar fullførelse'
            header={`Viser påmeldinger i dato intervallet: ${dateFromUntil}`}
            description={''}
            description1Type2={'% fullført innen 9 mnd'}
            // @ts-expect-error
            percentage1Type2={stats.percentageCompletedWithin9MonthsType2}
            description2Type2={'% fullført innen 18 mnd'}
            // @ts-expect-error
            percentage2Type2={stats.percentageCompletedWithin18MonthsType2}
            // @ts-expect-error
            description3Type2={`Fullført innen 9 mnd: ${stats.completedWithin9MonthsType2}`}
            // @ts-expect-error
            description4Type2={`Fullført innen 18 mnd: ${stats.completedWithin18MonthsType2}`}
            // @ts-expect-error
            description5Type2={`Fullført etter 18 mnd: ${stats.completedAfter18MonthsType2}`}
            // @ts-expect-error
            description6Type2={`Påmeldinger: ${stats.acceptedType2}`}
            // @ts-expect-error
            description7Type2={`Aldri fullført: ${stats.neverCompletedType2}`}
        />
    )
}

export default Type0CourseCompletions
