import {api} from './api'

export const API_ENTITIES_UPDATED = '[API] Entities updated'
export const API_FETCH_ENTITY = '[API] Get entity'
export const API_ENTITY_NOT_FOUND = '[API] Entity not found'

export const entitiesUpdated = entities => ({type: API_ENTITIES_UPDATED, payload: entities})
export const entityNotFound = id => ({type: API_ENTITY_NOT_FOUND, payload: {id}})
export const fetchEntity = (id, forceReload = false, ttl: number | boolean | undefined = 60) =>
    api(id, API_FETCH_ENTITY, 'GET', forceReload, undefined, ttl)
