import * as React from 'react'
import {useState} from 'react'
import {Button} from '../../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {SetState} from '../../../Utilities/TypeHelpers'
import {DoctorType} from './Doctor'
import {GridSelectionModel} from '@mui/x-data-grid-premium'

interface DoctorsAddToStudyDialogProps {
    onClose: Function
    isOpen: boolean
    doctorIris: string[]
    setSelected: SetState<GridSelectionModel>
}

export const DoctorsAddToStudyDialog = ({onClose, isOpen = false, doctorIris, setSelected}: DoctorsAddToStudyDialogProps) => {
    const {isLoading, data} = useSkilQuery<'getPraksisnettStudyCollection'>('/api/praksisnett/studies')
    const [selectedStudyId, setSelectedStudyId] = useState<string>('')
    const addDoctorsToStudy = useSkilMutation<'addPraksisnettStudyDoctors'>('post', `${selectedStudyId}/add_doctors`)

    const studies = data?.['hydra:member'].map(study => ({...study, label: study.name})) ?? []

    const handleDialogClose = () => {
        setSelectedStudyId('')
        onClose()
    }

    const handleSubmitInvite = async () => {
        try {
            await addDoctorsToStudy
                .mutateAsync({
                    // @ts-expect-error
                    doctors: doctorIris,
                })
                .then(() => {
                    setSelected([])
                })
            toast(`Legene er lagt til studien.`, {
                type: 'success',
            })
            handleDialogClose()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    return (
        <Dialog maxWidth='md' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogContent>
                <Typography>Legg til legen(e) i en studie.</Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            autoHighlight
                            id='study'
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            value={studies.find(study => study['@id'] === selectedStudyId)}
                            onChange={(event: any, newValue) => setSelectedStudyId(newValue['@id']!)}
                            disableClearable
                            renderInput={params => (
                                <TextField
                                    required
                                    {...params}
                                    label='Studie'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <MenuItem component='li' {...props}>
                                    {option.name}
                                </MenuItem>
                            )}
                            options={studies}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={handleDialogClose}>
                    Avbryt
                </Button>
                <Button disabled={!selectedStudyId} onClick={handleSubmitInvite}>
                    Legg til i studie
                </Button>
            </DialogActions>
        </Dialog>
    )
}
