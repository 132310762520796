import * as React from 'react'
import {useState} from 'react'
import {Button, ButtonProps} from '../../../Components/Button/MuiButton'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import {Page} from '../../../Components/Page'
import {Box} from '../../../Components/Panel'
import {useLocation} from 'react-router-dom'
import {breadcrumbsMap} from '../../utils/breadcrumbsMap'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {components} from '../../../Generated/eportal'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import {useParams} from 'react-router'
import ScrollToTop from '../../../Utilities/ScrollToTop'
import {OfficeEmployeesPanel} from './OfficeEmployeesPanel'
import {SnowboxDetailsPanel} from './SnowboxDetailsPanel'
import {OfficeCompensations} from './OfficeCompensations'
import {OfficeProfilePanel} from './OfficeProfilePanel'
import {ActionButtonGroup} from '../../components/DetailsViewActionButtonGroup'
import {OfficeChangelogDialog} from '../../components/Changelogs/OfficeChangelogDialog'

export type OfficeType = components['schemas']['Praksisnett.Office.jsonld-PraksisNettOffice.details_PraksisNettDoctor.contact']
export interface OfficeProps {
    office: OfficeType
    refetch: Function
}

export const Office = () => {
    const location = useLocation()
    const {officeId} = useParams()
    const [showChangelog, setShowChangelog] = useState(false)

    const {
        data: office,
        isLoading,
        isError,
        refetch,
        isFetching,
    } = useSkilQuery<'getPraksisNettOffice'>(`/api/praksisnett/offices/${officeId}`)

    const officeActions: ButtonProps[] = [
        {
            children: 'Vis endringslogg',
            onClick: () => setShowChangelog(true),
            startIcon: <ListAltOutlinedIcon />,
        },
    ]

    const breadcrumbs = [breadcrumbsMap.home, breadcrumbsMap.offices, {title: office?.name ?? '', to: location.pathname}]

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs}>
            <ScrollToTop />
            {isError && <Box>Noe gikk galt</Box>}

            {isLoading || !office ? (
                <CircularProgress />
            ) : (
                <>
                    <ActionButtonGroup>
                        {officeActions.map((props, index) => (
                            <Button {...props} key={index} />
                        ))}
                    </ActionButtonGroup>
                    <Grid container spacing={2}>
                        <Grid container item md={6}>
                            <OfficeProfilePanel office={office} refetch={refetch} />
                        </Grid>
                        <Grid item md={6}>
                            <SnowboxDetailsPanel refetch={refetch} office={office} />
                        </Grid>
                        <Grid xs={12} item>
                            <OfficeCompensations refetch={refetch} office={office} isFetching={isFetching} />
                        </Grid>
                        <Grid xs={12} item>
                            <OfficeEmployeesPanel office={office} refetch={refetch} />
                        </Grid>
                    </Grid>
                    <OfficeChangelogDialog office={office} setOpen={setShowChangelog} isOpen={showChangelog} />
                </>
            )}
        </Page>
    )
}
