import {combineReducers, createStore} from 'redux'
import reducer from './State/reducers'
import middleware from './State/middlewares'
import {addMiddleware, removeMiddleware} from 'redux-dynamic-middlewares'
import regeneratorRuntime from 'regenerator-runtime'
import './Utilities/ConfigureLocalization'
import {composeWithDevTools} from 'redux-devtools-extension'
import {toast} from 'react-toastify'
import React, {StrictMode} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter, Route} from 'react-router-dom'
import App from './App'
import ReloadFrontend from './Components/Dialogs/ReloadFrontend'
import react from 'react'

// Define the Reducers that will always be present in the application
const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 25})

import {LicenseInfo} from '@mui/x-data-grid-premium'

LicenseInfo.setLicenseKey('95de6b061740a76a7f2d0f28407010bdTz04MTAzNCxFPTE3MzQ4ODYwNTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

// Configure the store
export function configureStore() {
    const store = createStore(combineReducers(reducer), {}, composeEnhancers(middleware))
    // @ts-expect-error
    store.asyncReducers = {}
    // @ts-expect-error
    store.addMiddleware = addMiddleware
    // @ts-expect-error
    store.removeMiddleware = removeMiddleware
    return store
}

toast.configure({
    autoClose: 5000,
    position: 'bottom-right',
})

import './Utilities/Firebase'
import Search from './Search/Search'
import {QueryProvider} from './Utilities/QueryClient'
import ThemeProvider from './Utilities/ThemeProvider'
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns'
import {UserProvider} from './Utilities/useUser'

const SearchApp = () => {
    const searchContainer = document.querySelector('#omnisearch')
    if (!searchContainer) return null
    return ReactDOM.createPortal(<Search />, searchContainer)
}

const PortalApp = () => {
    const container = document.querySelector('#react-app')
    if (!container) return null

    //TODO: Load user data before rendering

    return ReactDOM.createPortal(<App />, container)
}

ReactDOM.render(
    <QueryProvider>
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <UserProvider>
                    <Provider store={configureStore()}>
                        <BrowserRouter basename={`/`}>
                            <SearchApp />
                            <PortalApp />
                            <ReloadFrontend />
                        </BrowserRouter>
                    </Provider>
                </UserProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </QueryProvider>,
    document.querySelector('#state-app')
)
