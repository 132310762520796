import {deepmerge} from '@mui/utils'
import {createTheme} from '@mui/material/styles'
import {skilThemeConfig} from '../skilTheme'
import {nbNO} from '@mui/material/locale'
import {nbNO as dataGridNO} from '@mui/x-data-grid-premium'

const praksisNettBlue = 'hsl(214.6,57.7%,41.8%)'

const praksisNettThemeConfig = {
    palette: {
        primary: {
            main: praksisNettBlue,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:focus, &.MuiButtonGroup-grouped:focus': {
                        // see skilTheme.ts for details.
                        outline: `3px solid ${praksisNettBlue}`,
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    // light version of PraksisNett blue. Overrides SKIL theme custom colour.
                    backgroundColor: 'hsl(214.6,57.7%,78%)',
                },
            },
        },
    },
}

const praksisNettTheme = createTheme(deepmerge(skilThemeConfig, praksisNettThemeConfig), nbNO, dataGridNO)

export default praksisNettTheme
