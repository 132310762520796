import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'

const hasAllMeetingsThisYear = (meetings: string[]) => {
    if (meetings.length === 0) return false

    const now = new Date()
    return meetings.every(meeting => new Date(meeting).getFullYear() === 2023 && new Date(meeting) < now)
}

const QualitySeminarGoal23 = () => {
    const {data} = useSkilQuery<'getPartnershipsSeminarsCollection'>('/api/partnerships/seminars')
    const seminars = data?.['hydra:member'] ?? []

    /**
     * A quality seminar happened in 2023 if all of its meetings are in 2023.
     * Some notes:
     * - Although quality seminars are supposed to have only one meeting, we check
     *   all of them just in case.
     * - Some seminars (e.g. SKIL fora) have no partnership, so we filter them out.
     * - We use a Set to count unique partnerships, since some partnerships may have
     *   multiple quality seminars.
     */
    const seminarsThisYear = (seminars ?? []).filter(seminar => {
        const meetings = seminar?.meetings ?? []
        return hasAllMeetingsThisYear(meetings)
    })

    const uniquePartnershipsWithSeminarsThisYear = new Set(seminarsThisYear?.map(seminar => seminar.partnership).filter(id => !!id))

    return (
        <GoalCard
            title='Kvalitetsseminar'
            subheader='Mål: minst 35 kommuner har hatt minst ett kvalitetsseminar hittil i 2023.'
            description={`${uniquePartnershipsWithSeminarsThisYear.size} kommunesamarbeid med minst ett kvalitetsseminar hittil i 2023.`}
            percentage={Math.ceil((uniquePartnershipsWithSeminarsThisYear.size * 100) / 35)}
        />
    )
}

export default QualitySeminarGoal23
