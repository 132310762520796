import React, {useContext, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {motion, Reorder, useDragControls} from 'framer-motion'
import useEntity from '../../Hooks/useEntity'
import {EditLockContext} from './Form'
import styled from 'styled-components'
import useEntities from '../../Hooks/useEntities'
import PreviousIndicatorGroupsModal from './PreviousIndicatorGroupsModal'
import Button from '../../Components/Button/Button'
import {useNavigate} from 'react-router'
import EditText from '../Components/EditText'
import firebase from 'firebase'
import functions = firebase.functions

const StyledReorderItem = styled(Reorder.Item)`
    display: flex;
    padding: 0;
    justify-content: space-between;
    background: ${props => (props.active ? '#021012' : 'transparent')};
    border-bottom: ${props => (props.active ? '1px solid #021012' : '1px solid #dddddd')};
    align-items: baseline;

    :hover {
        background-color: ${props => (props.active ? '#021012' : '#f5f5f5')};
    }
    a {
        flex-grow: 1;
    }
    a,
    button {
        padding: 10px;
        color: ${props => (props.active ? '#fff' : '#021012')};
        :hover {
            color: ${props => (props.active ? '#fff' : '#021012')};
        }
        :focus {
            color: ${props => (props.active ? '#fff' : '#021012')};
        }
    }
`

const StyledDiv = styled.div`
    position: relative;
    height: 40px;
    :hover {
        cursor: pointer;
    }

    hr {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0;
    }

    .icon {
        z-index: 0;
        position: absolute;
        background: white;
        text-align: center;
        left: 0;
        right: 0;
        top: calc(20px - 0.7em);
        width: 2em;
        margin: 0 auto;
    }
`

const plusMotion = {
    hover: {
        opacity: 1,
        scale: 1.5,
        transition: {
            duration: 0.15,
            type: 'tween',
            ease: 'easeInOut',
        },
    },
    tap: {
        scale: 1.2,
    },
}

const StyledPlusButton = styled.button`
    background: white;
    border: none;
`

const StyledHref = styled.a`
    display: flex;
    justify-content: space-between;
`

const StyledLabel = styled.div`
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
`
const StyledLabelWithoutCursor = styled.div`
    padding: 10px 15px;
`

const StyledPanelDefault = styled.div``

const WrapperDiv = styled.div`
    padding: 10px 15px;
    div {
        display: inline-flex;
    }
`

const StyledIcon = styled.i`
    float: right;
`

const StyledEditTextFormName = styled(EditText)`
    :first-child {
        padding: 0 0 0 0.5em;
    }
`
const StyledEditTextTitle = styled(EditText)`
    :first-child {
        padding: 0 0 0 0.5em;
    }
`
const StyledLabelAndText = styled.div`
    width: 90%;
`

type IndicatorType = {
    number: string | {number: string}
}

interface NavigationProps {
    children: React.ReactNode
    groups: any
    form: any
    shortTitle: string | undefined
    selectedGroup: any
    indicatorsWithMissingDependencies: IndicatorType[] | null
    setIndicatorsWithMissingDependencies: Function
    setUpdatingIndicatorGroups: Function
}

export const Navigation = ({
    children,
    groups,
    form,
    shortTitle,
    selectedGroup,
    indicatorsWithMissingDependencies,
    setIndicatorsWithMissingDependencies,
    setUpdatingIndicatorGroups,
}: NavigationProps) => {
    const [lock] = useContext(EditLockContext)
    const [showPreviousIndicatorGroupModal, setShowPreviousIndicatorGroupModal] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState('global_forms')
    const forms = useEntities<'getCourseForms'>(
        selectedCourse !== 'global_forms' ? `${selectedCourse}/forms` : `/api/global_forms?exists[course]=false`
    )
    const [selectedForm, setSelectedForm] = useState(0)
    const indicatorGroups = useEntities<'getFormIndicatorGroupCollection'>(
        selectedForm !== 0 ? `${selectedForm}/indicator_groups?disabled=false` : undefined
    )
    let navigate = useNavigate()
    const tasks = useEntity<'getTaskItem'>(form ? form['tasks']?.[0] : null)
    const anonymousTask = tasks ? tasks['anonymous'] : null
    const selectedGroupTemp = useEntity<'getGroupItem'>(selectedGroup ? selectedGroup['@id'] : null)

    const createGroup = () => {
        groups.create({form: form['@id'], order: 10000, title: 'Ny gruppe'}, `/api/indicator_groups`).then(async response => {
            const newGroup = await response
            await groups.refresh()
            setUpdatingIndicatorGroups(true)
            navigate(`/dashboard/course/${shortTitle}/settings/forms/${form['id']}/${newGroup.id}`)
        })
    }

    const addPreviousIndicatorGroup = e => {
        fetch(`/api/indicator_groups/${e.indicatorGroup}/copy_indicatorgroup_to_form`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {'Content-Type': 'application/json', Accept: 'application/json'},
            body: JSON.stringify(e), // body data type must match "Content-Type" header
        }).then(async response => {
            const group = await response.json()
            setIndicatorsWithMissingDependencies(prev => {
                window.localStorage.setItem('missingDependencies-' + form.id, JSON.stringify({...prev, ...group.missingDependencies}))
                return {...prev, ...group.missingDependencies}
            })
            await groups.refresh()
            setUpdatingIndicatorGroups(true)
            navigate(`/dashboard/course/${shortTitle}/settings/forms/${form['id']}/${group.newIndicatorGroupId}`)
        })
    }

    const onSelectCourse = e => {
        setSelectedCourse(e)
    }

    const onSelectForm = e => {
        setSelectedForm(e)
    }

    const onGroupTitleSelectedSave = value => {
        selectedGroupTemp.changeField('title', value)
        selectedGroupTemp.save()
    }

    const onFormNameSave = value => {
        form.changeField('name', value)
        form.save()
    }

    return (
        <>
            <div className='panel panel-default'>
                <div className='panel-heading'>Endre navn</div>
                <ul className={'list-group'}>
                    <WrapperDiv>
                        <StyledLabelAndText>
                            Arbeidsark:
                            <StyledEditTextFormName toolboxStyling={true} text={form.name} onSave={onFormNameSave} big />
                        </StyledLabelAndText>
                        <StyledIcon className={'fa fa-file-signature'} />
                    </WrapperDiv>
                </ul>
                <ul className={'list-group'}>
                    <WrapperDiv>
                        <StyledLabelAndText>
                            Indikatorgruppe:
                            {selectedGroup && (
                                <StyledEditTextTitle
                                    toolboxStyling={true}
                                    text={selectedGroupTemp.title}
                                    onSave={onGroupTitleSelectedSave}
                                    big
                                />
                            )}
                        </StyledLabelAndText>
                        <StyledIcon className={'fa fa-file-signature'} />
                    </WrapperDiv>
                </ul>
            </div>
            <hr />
            <StyledPanelDefault className='panel panel-default'>
                <div className='panel-heading'>Verktøy</div>
                <ul className={'list-group'}>
                    <StyledHref href={`/public/form/${form['id']}/preview`} target={'_blank'} className={`list-group-item`}>
                        Forhåndsvisning
                        <i className={'fa fa-eye'} />
                    </StyledHref>
                </ul>
                <ul className={'list-group'}>
                    <StyledLabel onClick={() => setShowPreviousIndicatorGroupModal(true)}>
                        Kopier tidligere indikatorgruppe
                        <i className={'fa fa-copy'} />
                    </StyledLabel>
                </ul>
                <ul className={'list-group'}>
                    <StyledLabel
                        onClick={() => {
                            setSelectedCourse('/api/courses/36')
                            setShowPreviousIndicatorGroupModal(true)
                        }}
                        className={`list-group-item`}
                    >
                        Kopier maler
                        <i className={'fa fa-copy'} />
                    </StyledLabel>
                </ul>
            </StyledPanelDefault>
            <div className='panel panel-default' style={{position: 'relative'}}>
                <div className='panel-heading'>Grupper</div>
                {children}
                <StyledDiv onClick={createGroup} initial='rest' whileHover='hover' whileTap='tap'>
                    <motion.div hidden={lock} variants={plusMotion} className={'icon'}>
                        <StyledPlusButton>
                            <i className={'fa fa-plus fa-lg'} />
                        </StyledPlusButton>
                    </motion.div>
                </StyledDiv>
            </div>
            {indicatorsWithMissingDependencies && Object.keys(indicatorsWithMissingDependencies).length > 0 && (
                <StyledPanelDefault className='panel panel-default' style={{border: 'solid 2px red'}}>
                    <div className='panel-heading'>Mangler avhengighet etter kopiering</div>
                    {Object.entries(indicatorsWithMissingDependencies)?.map((indicator, index) => {
                        const missingIndicators = Object.entries(indicator?.[1])
                        return (
                            <ul key={indicator[0] + index} className={'list-group'} style={{padding: '10px 15px'}}>
                                {missingIndicators.map((missingIndicator: any, missingIndicatorsIndex) => {
                                    return (
                                        <div
                                            key={indicator[0] + index + missingIndicatorsIndex}
                                            style={{display: 'flex', justifyContent: 'space-between'}}
                                        >
                                            <p>
                                                {' '}
                                                Indikator # {indicator[0]} mangler indikator # {missingIndicator[0]}
                                                {' - ' + missingIndicator[1] + ' '}
                                            </p>
                                            <div
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    setIndicatorsWithMissingDependencies(prev => {
                                                        const tempPrev = {...prev}
                                                        delete tempPrev[indicator[0]][missingIndicator[0]]
                                                        if (Object.keys(tempPrev[indicator[0]]).length === 0) {
                                                            delete tempPrev[indicator[0]]
                                                        }
                                                        window.localStorage.setItem(
                                                            'missingDependencies-' + form.id,
                                                            JSON.stringify(tempPrev)
                                                        )
                                                        return tempPrev
                                                    })
                                                }}
                                            >
                                                Ferdig <i className={'fa fa-eraser'} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                        )
                    })}
                </StyledPanelDefault>
            )}
            {showPreviousIndicatorGroupModal && (
                <PreviousIndicatorGroupsModal
                    onClose={() => {
                        setShowPreviousIndicatorGroupModal(false)
                        setSelectedCourse('global_forms')
                    }}
                    selectedCourse={selectedCourse}
                    onSelectCourse={onSelectCourse}
                    forms={forms}
                    form={form}
                    selectedForm={onSelectForm}
                    indicatorGroups={indicatorGroups}
                    addPreviousIndicatorGroup={addPreviousIndicatorGroup}
                />
            )}
        </>
    )
}

Navigation.Item = ({iri, to, onRemove, value}) => {
    const {pathname} = useLocation()
    const controls = useDragControls()

    if (!value || value['disabled'] === true) return null

    return (
        <StyledReorderItem
            value={value}
            id={iri}
            active={to === pathname}
            dragListener={false}
            dragControls={controls}
            className={`list-group-item`}
        >
            <NavLink to={to}>{value['title']}</NavLink>
            <Button
                onClick={onRemove}
                confirm={
                    <>
                        <i className={'fa fa-check'} /> Er du sikker?
                    </>
                }
                variant={'link'}
            >
                <i className={'fa fa-trash'} />
            </Button>
            <button className={'btn btn-link'} onPointerDown={e => controls.start(e)}>
                <i className={'fa fa-arrows-alt'} />
            </button>
        </StyledReorderItem>
    )
}
