import * as React from 'react'
import {Route, Routes, useLocation, useMatch, useRoutes, useInRouterContext} from 'react-router-dom'
import Overview from './Setup/Overview'
import {PageNotFound} from '../PageNotFound'
import Office from './Setup/Pages/Office'
import Booking from './Setup/Pages/Booking'
import Employees from './Setup/Pages/Employees'

import Accessibility from './Setup/Pages/Accessibility'
import Status from './Setup/Pages/Status'
import OpeningHours from './Setup/Pages/Openinghours'

export const NefleApp = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Overview />}>
                <Route path={'legekontoret'} element={<Office />} />
                <Route path={'timebestilling'} element={<Booking />} />
                <Route path={'ansatte'} element={<Employees />} />
                <Route path={'opningstider'} element={<OpeningHours />} />
                <Route path={'tilgjengelighet'} element={<Accessibility />} />
                <Route path={'status'} element={<Status />} />
                <Route path={'*'} element={<PageNotFound />} />
            </Route>
            <Route path={'*'} element={<PageNotFound />} />
        </Routes>
    )
}
