import TextField from '@mui/material/TextField'
import React from 'react'
import Grid from '@mui/material/Grid'

const InvoiceComponent = ({signupIndex, invoiceIndex, invoice, setSignups}) => {
    function updateField(field, value) {
        setSignups(prev => {
            // Clone the previous state to avoid direct mutation
            const updatedSignups = [...prev]
            // Clone the nested array to which the update needs to be applied
            const updatedPartnershipSignupInvoiceDetails = [...updatedSignups[signupIndex].partnershipSignupInvoiceDetails]
            // Update the specific element within the nested array
            updatedPartnershipSignupInvoiceDetails[invoiceIndex] = {
                ...updatedPartnershipSignupInvoiceDetails[invoiceIndex],
                [field]: value,
            }
            // Update the nested array in the cloned state
            updatedSignups[signupIndex] = {
                ...updatedSignups[signupIndex],
                partnershipSignupInvoiceDetails: updatedPartnershipSignupInvoiceDetails,
            }
            // Return the updated state
            return updatedSignups
        })
    }
    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <TextField
                    id='invoiceName'
                    label='Kommune / Bydel'
                    placeholder='Bergen'
                    value={invoice.name}
                    required
                    fullWidth
                    onChange={event => {
                        updateField('name', event.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='invoiceOrgNumber'
                    label='Organisasjonsnummer til EHF utsending:'
                    type={'number'}
                    placeholder='123456789'
                    value={invoice.orgNumber}
                    required={true}
                    fullWidth
                    onChange={event => updateField('orgNumber', parseInt(event.target.value))}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='invoiceEmailReceiver'
                    label='E-post til fakturamottak'
                    placeholder='epost@fakturamottak.no'
                    value={invoice.emailForInvoiceReceipt}
                    required
                    fullWidth
                    onChange={event => updateField('emailForInvoiceReceipt', event.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='invoiceResourceNumber'
                    label='Ressursnummer / PO nr:'
                    placeholder='Ressursnummer / PO nr'
                    value={invoice.resourceNumber}
                    required
                    fullWidth
                    onChange={event => updateField('resourceNumber', event.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id='invoiceReference'
                    label='Referanse:'
                    placeholder='Referanse'
                    value={invoice.invoiceReference}
                    fullWidth
                    onChange={event => updateField('invoiceReference', event.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='invoiceOtherInfo'
                    label='Andre opplysninger:'
                    multiline={true}
                    placeholder='Annet viktig...'
                    value={invoice.invoiceOtherInfo}
                    fullWidth
                    onChange={event => updateField('invoiceOtherInfo', event.target.value)}
                />
                <hr />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='invoiceNotes'
                    label='SKILs interne notater'
                    multiline={true}
                    placeholder='SKILs interne notater...'
                    value={invoice.internalNotes}
                    fullWidth
                    onChange={event => updateField('internalNotes', event.target.value)}
                />
            </Grid>
        </Grid>
    )
}
export default InvoiceComponent
