import * as React from 'react'
import {Box, Card, CardContent, LinearProgress, Typography} from '@mui/material'
import MeetingIcon from '../../Components/Icons/MeetingIcon'
import {SkilQueryResponseType} from '../../Utilities/QueryClient'
import {format} from 'date-fns'
import {DATE_FORMAT} from '../../State/utils/constants'
import {CardActionArea} from '@mui/material'
import LinearProgressWithPercentLabel from '../../Components/LinearProgressWithPercentLabel'

interface SeminarButtonProps {
    seminar: SkilQueryResponseType<'getUserSeminars'>['hydra:member'][0]
    partnershipId?: string
    isPast: boolean
}

const SeminarButton = ({seminar, partnershipId, isPast}: SeminarButtonProps) => {
    const {meeting, title, meetingAt, attended, signups, invited} = seminar
    const meetingDate = format(new Date(meetingAt), DATE_FORMAT)
    const signupPercentage = Math.round((signups / invited) * 100)

    return (
        <Card sx={{mb: 3}}>
            <CardActionArea href={`${partnershipId}/seminars/${meeting}`}>
                <Box display='flex'>
                    <MeetingIcon sx={{fontSize: 136}} />
                    <Box flexGrow={1} flexShrink={0} py={1}>
                        <CardContent>
                            <Typography fontWeight={500} variant='body1'>{`${title} (${meetingDate})`}</Typography>
                            <Typography variant='body2' mb={2}>{`${
                                isPast ? attended + ` deltagere` : signups + ` påmeldte`
                            } av ${invited} inviterte (${signupPercentage}%)`}</Typography>
                            <LinearProgressWithPercentLabel percentage={signupPercentage} />
                        </CardContent>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    )
}

export default SeminarButton
