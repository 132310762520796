import {GridCellParams, GridColDef} from '@mui/x-data-grid-premium'
import {customValueGetter} from './columnDef'

declare type ValueOptions = {
    value: any
    label: string
}

type Column = GridColDef & {
    type?: 'singleSelect'
    valueOptions: Array<ValueOptions>
}

export default function selectColumnDef(col: Column): GridColDef {
    const valueGetter = customValueGetter(col.valueGetter)
    const valueFormatter = params => {
        const opt = col.valueOptions.find(o => (typeof o === 'object' ? o.value : o) === params.value)

        if (typeof opt === 'object') return opt.label

        return opt
    }

    const getApplySelectQuickFilterFn = (value: string) => {
        if (!value) {
            return null
        }
        return (params: GridCellParams): boolean => {
            if (params?.row?.title && params?.formattedValue) {
                // either row title or formattedValue contains filter value
                return params.row.title.includes(value) || params.formattedValue.includes(value)
            }
            return false
        }
    }

    return {
        type: 'singleSelect',
        flex: col.field.endsWith('id') ? 0.5 : 1,
        ...col,
        valueGetter,
        valueFormatter,
        getApplyQuickFilterFn: getApplySelectQuickFilterFn,
    }
}
