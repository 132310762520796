import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import styled from 'styled-components'
import EditText from '../../Components/EditText'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`
const StyledCheckbox = styled.input`
    cursor: pointer;
`

const LocationIndicator = ({indicator, answer, onChange, disabled = false, showSaveAndResetButtons}) => {
    const {fresh, value, valid} = answer

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onOptionalChange = value => {
        indicator.changeField('optional', value)
        showSaveAndResetButtons(false)
        indicator.save()
    }

    const onSelectChange = value => {
        indicator.changeField('locationLevel', parseInt(value))
        indicator.save()
    }

    return (
        <FormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>
            <select className='form-control' value={indicator.locationLevel} onChange={e => onSelectChange(e.target.value)}>
                {indicator && !indicator.locationLevel && (
                    <option selected disabled value='0'>
                        Ikke valgt
                    </option>
                )}
                <option value='1'>Fylke</option>
                <option value='2'>Kommune</option>
                <option value='3'>Office</option>
            </select>
            <span className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </span>
            <label className='help-block'>
                <StyledCheckbox type='checkbox' checked={indicator.optional} onChange={e => onOptionalChange(e.target.checked)} /> Dette
                feltet er valgfritt
            </label>
        </FormGroup>
    )
}
export default LocationIndicator
