import React, {useContext, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import ImageDropZoneModal from '../../../Components/ImageDropZoneModal'
import LoadingComponent from '../../../Components/LoadingComponent'

const StyledImage = styled.img`
    width: 100%;
    height: auto;

    ${props => props.isLoading && `display: none`}
`
const StyledDiv = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`
const StyledButton = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    &:before {
        content: ' ';
        display: block;
        /* adjust 'height' to position overlay content vertically */
        height: 25%;
    }
`

const Image = ({officeId, onChange, alt, editing, link = null}) => {
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
    }, [link])

    const onSave = e => {
        setShowModal(false)
        onChange(e)
    }

    const onCancel = () => {
        setShowModal(false)
    }

    const onShow = () => {
        setShowModal(true)
    }

    if (editing)
        return (
            <StyledDiv>
                {isLoading && <LoadingComponent />}
                <StyledImage
                    className='img-thumbnail'
                    alt={alt}
                    src={link}
                    isLoading={isLoading}
                    onLoad={() => {
                        setIsLoading(false)
                    }}
                />
                {!isLoading && (
                    <StyledButton>
                        <button
                            className={'btn btn-lg'}
                            onClick={e => {
                                e.preventDefault()
                                onShow()
                            }}
                        >
                            <i className={'fa fa2x fa-cloud-upload-alt'} />
                            <br />
                            Last opp bilde
                        </button>
                    </StyledButton>
                )}
                {showModal && (
                    <ImageDropZoneModal
                        //TODO !!!
                        uploadUrl={`/api/office_nefles/${officeId}/upload`}
                        maxAspectRatio={1}
                        minAspectRatio={1}
                        onCancel={onCancel}
                        onSave={onSave}
                    />
                )}
            </StyledDiv>
        )

    return (
        <StyledDiv>
            <StyledImage className='img-thumbnail' alt={alt} src={link} />
        </StyledDiv>
    )
}
export default Image
