import React, {ReactNode} from 'react'
import usePhoneFormatter from '../usePhoneFormatter'
import MuiTextField from '@mui/material/TextField'

type Props = {
    value?: string | null
    onChange: (value: string, e) => any
    id: string
    label?: ReactNode
    placeholder?: string
    disabled?: boolean
    readonly?: boolean
    required?: boolean
}
/** @deprecated*/
const PhoneField = ({id, label, value, onChange, ...props}: Props) => {
    const formatPhone = usePhoneFormatter()
    const onLocalChange = e => {
        const {raw} = formatPhone(e.target.value)
        onChange(raw, e)
    }

    const {formatted, valid} = formatPhone(value)

    return (
        <MuiTextField
            sx={{marginBottom: 3}}
            id={id}
            label={label}
            type='tel'
            onChange={onLocalChange}
            variant='outlined'
            value={formatted}
            fullWidth={true}
            error={value && !valid ? true : undefined}
            {...props}
        />
    )
}
export default PhoneField
