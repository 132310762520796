import React, {useState, useEffect} from 'react'
import useEntity, {UseEntityReturnType} from '../../Hooks/useEntity'
import Button from '../../Components/Button/Button'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import {format, parse} from 'date-fns'
import Select from '../../Components/Select'
import InlineDropdown from '../Components/InlineDropdown'
import EditText from '../Components/EditText'
import useEntities, {UseEntitiesType} from '../../Hooks/useEntities'
import {fetchEntity} from '../../State/actions/entity'
import {SkilQueryResponseType} from '../../Utilities/QueryClient'

const StyledLabel = styled.label`
    display: block;
`

const StyledEditText = styled(EditText)`
    padding-right: 12px;
    padding-left: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .toolbox {
        position: relative;
    }
`
const StyledRemoveDiv = styled.div`
    display: flex;
    justify-content: flex-end;
`

const TYPES = [
    'MultichoiceIndicator',
    'ChoiceIndicator',
    'TextIndicator',
    'CheckboxIndicator',
    'CounselorEmailIndicator',
    'NumberIndicator',
    'DateTimeIndicator',
    'LocationIndicator',
    'PreviousAnswerIndicator',
    'SeminarThemeIndicator',
]

type DependenciesType = UseEntitiesType<SkilQueryResponseType<'getIndicator-IndicatorDependencyCollection'>['hydra:member'][number]>

const Dependencies = ({iri, updatingIndicatorGroups, setUpdatingIndicatorGroups}) => {
    const indicator = useEntity<'getIndicatorItem'>(iri)
    const [chosen, setChosen] = useState<any>()
    const dependencies: DependenciesType = useEntities<'getIndicator-IndicatorDependencyCollection'>(
        `/api/abstractindicator/${indicator['id']}/indicator_dependencies`
    )
    let indicators = useEntities<'getFormIndicatorCollection'>(
        indicator['@loaded'] ? indicator.form + `/indicators?disabled=false&itemsPerPage=1000` : undefined
    )
    let groups = useEntities<'getFormIndicatorGroupCollection'>(
        indicator['@loaded'] ? indicator.form + `/indicator_groups?disabled=false&itemsPerPage=1000` : undefined
    )
    let filteredIndicators = indicators.filter(i => TYPES.includes(i.subType))

    const groupedIndicators = Object.values(
        filteredIndicators.reduce((carry, item) => {
            const groupId = item.group!
            const group = groups.find(g => g['@id'] === groupId)

            if (!carry[groupId]) carry[groupId] = {order: group ? group['order'] : null, label: group ? group['title'] : null, options: []}

            carry[groupId].options.push({
                value: item['@id'],
                label: item['title'],
                isDisabled: indicator['@id'] === item['@id'],
            })

            return carry
        }, {})
    )
    groupedIndicators.sort((a: any, b: any) => a.order - b.order)

    const onAdd = () => {
        dependencies.create({indicator: iri, dependent: chosen.value}, '/api/indicator_dependencies')
        setChosen(false)
    }

    useEffect(() => {
        if (updatingIndicatorGroups) {
            groups.refresh().then(() => {
                indicators.refresh().then(() => {
                    setUpdatingIndicatorGroups(false)
                })
            })
        }
    }, [updatingIndicatorGroups])

    return (
        <div>
            <StyledLabel>Avhenger av:</StyledLabel>
            {dependencies.map(item => (
                <Dependency id={item.id} dependencies={dependencies} dependent={item['dependent']} key={item['id']} />
            ))}
            <div className={'row'}>
                <div className='col-sm-9'>
                    {/* @ts-expect-error */}
                    <Select className={'dependencies'} onChange={setChosen} options={groupedIndicators} value={chosen}></Select>
                </div>
                <div className='col-sm-3'>
                    <Button onClick={onAdd} disabled={!chosen} block>
                        Legg til
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default Dependencies

const Dependency = ({id, dependencies, dependent}) => {
    const dependency = useEntity<'getIndicatorDependencyItem'>(id ? `/api/indicator_dependencies/${id}` : null)
    const dependentLocal = useEntity<'getIndicatorItem'>(dependency ? dependency['dependent'] : null)
    const deps = Object.entries(dependent ? dependent.dependencyMethods : null)
    const dependencyMethods = deps.map(([value, label]) => ({value: parseInt(value), label: label}))

    const onMethodSave = val => {
        dependency.changeField('method', val)
        dependency.save()
        dependency.refresh()
    }

    const onValueSave = val => {
        dependency.changeField('value', val)
        dependency.save()
        dependency.refresh()
    }

    const onRemoveDependency = () => {
        dependency.remove()
    }

    return (
        <>
            {dependency['@status'] !== 'DELETED' && (
                <div className={'row'} style={{marginBottom: '1em'}}>
                    <div className='col-sm-5'>
                        <input
                            value={'Indikator #' + dependentLocal.id + ' - ' + dependentLocal?.title ?? ''}
                            className='form-control'
                            name='dependency[]'
                            readOnly
                            title={'Indikator #' + dependentLocal.id + ' - ' + dependentLocal?.title ?? ''}
                        />
                    </div>
                    <div className='col-sm-2'>
                        {dependentLocal && (
                            <InlineDropdown
                                id={id}
                                value={dependency['method']}
                                // @ts-expect-error
                                options={dependencyMethods}
                                onChange={onMethodSave}
                                alternative={false}
                            />
                        )}
                    </div>
                    <div className='col-sm-3'>
                        <Alternative
                            // @ts-expect-error
                            className='form-control'
                            indicator={dependent}
                            value={dependency['value']}
                            onChange={onValueSave}
                            alternative={true}
                        />
                    </div>
                    <StyledRemoveDiv className='col-sm-2'>
                        <Button onClick={onRemoveDependency}>Fjern</Button>
                    </StyledRemoveDiv>
                </div>
            )}
        </>
    )
}

const Alternative = ({indicator, onChange, value, alternative}) => {
    if (!indicator || !indicator['@type']) return null

    switch (indicator['subType']) {
        case 'CheckboxIndicator':
            return (
                <InlineDropdown
                    // @ts-expect-error
                    className='form-control'
                    value={value}
                    options={[
                        {value: 'yes', label: 'Ja'},
                        {value: 'no', label: 'Nei'},
                    ]}
                    onChange={onChange}
                    alternative={alternative}
                />
            )
        case 'NumberIndicator':
            return (
                <StyledEditText
                    className='form-control'
                    text={value}
                    onSave={onChange}
                    type={'number'}
                    alternative={alternative}
                    toolboxStyling={true}
                />
            )
        case 'TextIndicator':
            return (
                <StyledEditText className='form-control' text={value} onSave={onChange} alternative={alternative} toolboxStyling={true} />
            )
        case 'ChoiceIndicator':
            return (
                <InlineDropdown
                    // @ts-expect-error
                    className='form-control'
                    value={value}
                    optionsRaw={indicator['alternatives']}
                    options={indicator['alternatives']}
                    valueKey={'key'}
                    labelKey={'text'}
                    onChange={onChange}
                    alternative={alternative}
                />
            )
        case 'MultichoiceIndicator':
            return (
                <InlineDropdown
                    // @ts-expect-error
                    className='form-control'
                    value={value}
                    optionsRaw={indicator['alternatives']}
                    options={indicator['alternatives']}
                    valueKey={'key'}
                    labelKey={'text'}
                    onChange={onChange}
                    alternative={alternative}
                />
            )
        case 'LocationIndicator':
            return (
                <InlineDropdown
                    className='form-control'
                    value={value}
                    optionsRaw={indicator['alternatives']}
                    // @ts-expect-error
                    options={Object.entries(indicator['alternatives'])}
                    // @ts-expect-error
                    valueKey={0}
                    // @ts-expect-error
                    labelKey={1}
                    onChange={onChange}
                    alternative={alternative}
                />
            )
        case 'DateTimeIndicator':
            let formatStr = 'dd.MM.yyyy HH:mm'
            if (!indicator['time']) formatStr = 'dd.MM.yyyy'
            else if (!indicator['date']) formatStr = 'HH:mm'

            const onChangeDate = date => {
                onChange(format(date, formatStr))
            }

            const selected = parseDate(value)

            return (
                <DatePicker
                    methods={indicator['dependencyMethods']}
                    key={formatStr}
                    onChange={onChangeDate}
                    className={`form-control`}
                    selected={selected}
                    showTimeSelect={indicator['time']}
                    showTimeSelectOnly={!indicator['date']}
                    timeFormat='HH:mm'
                    timeIntervals={15}
                    timeCaption='Tid'
                    dateFormat={formatStr}
                    // @ts-expect-error
                    customInput={<CustomInput />}
                />
            )
        default:
            return null
    }
}
const CustomInput = ({value, onClick}) => <Button variant='link' onClick={onClick} children={value ? value : '...'} />
const parseDate = dateStr => {
    const formats = ['dd.MM.yyyy HH:mm', 'dd.MM.yyyy', 'HH:mm']
    if (!dateStr) return null

    try {
        const format = formats.find(fmt => parse(dateStr, fmt, new Date()).toString() !== 'Invalid Date')
        if (!format) return null

        return parse(dateStr, format, new Date())
    } catch (e) {
        return null
    }
}
