import React, {useState} from 'react'
import useEntities from '../Hooks/useEntities'
import Panel from '../Components/Panel'
import Button from '../Components/Button/Button'
import styled from 'styled-components'
import LoadingComponent from '../Components/LoadingComponent'
import {Page} from '../Components/Page'
import TextField from '../Components/Fields/TextField'

const StyledAddRole = styled.div`
    div {
        display: inline-block;
    }
    button {
        display: inline-block;
    }
`
const StyledRole = styled.div`
    display: flex;
    justify-content: space-between;
    &:hover {
        background-color: #f7f7f7;
    }
`

export default function UserRoles() {
    const userRoles = useEntities<'getUserRoleCollection'>(`/api/user_roles`)
    let filteredRoles = userRoles.filter(i => i.name)
    const [role, setRole] = useState<string | null>(null)

    function changeRole(e) {
        setRole(e)
    }

    const createRole = () => {
        userRoles.create({name: role}, `/api/user_roles`)
        setRole('')
    }

    const breadcrumbs = [
        {
            title: 'SKIL',
            to: '/dashboard/SKIL',
        },
        {
            title: 'Verktøy',
            to: '/dashboard/SKIL/tools',
        },
        {
            title: 'Brukerroller',
        },
    ]

    if (!userRoles['@loaded']) return <LoadingComponent size={'h4'}>Laster inn..</LoadingComponent>

    return (
        <Page breadcrumbs={breadcrumbs} variant={'default'}>
            <div className={'box'}>
                <div className={'box-header'}>
                    <br />
                    <p>Her kan du legge til nye roller som brukes ved påmelding</p>
                </div>
                <div className={'box-body'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <div className={'list-group'}>
                                <Panel variant={'flat'}>
                                    <Panel.Body>
                                        <StyledAddRole>
                                            <TextField label={'Ny rolle'} id={'rolle'} value={role} onChange={changeRole} />
                                            <Button onClick={createRole}>Legg til</Button>
                                        </StyledAddRole>
                                    </Panel.Body>
                                </Panel>
                                <Panel variant={'flat'}>
                                    <Panel.Body>
                                        {filteredRoles.map(item => (
                                            <StyledRole key={item['id']}>
                                                <div>{item['name']}</div>
                                                <Button
                                                    variant={'link'}
                                                    confirm={
                                                        'Er du sikker? (Dette vil fjerne rollen for alle brukere som har den definert!)'
                                                    }
                                                    onClick={() => userRoles.remove(item['@id']!)}
                                                >
                                                    Fjern
                                                </Button>
                                            </StyledRole>
                                        ))}
                                    </Panel.Body>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}
