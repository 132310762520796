import React from 'react'
import Button from '../Components/Button/Button'
import Panel from '../Components/Panel'
import useEntity from '../Hooks/useEntity'
import LoadingComponent from '../Components/LoadingComponent'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import {Page} from '../Components/Page'
import {getTitleFromContent} from './News'
import useEntities from '../Hooks/useEntities'
import TextEditorField from '../Components/Fields/TextEditorField'
import DateField from '../Components/Fields/DateField'
import CheckboxField from '../Components/Fields/CheckboxField'
import TextField from '../Components/Fields/TextField'

export default function NewsItem() {
    const {id, partnershipId} = useParams()
    const navigate = useNavigate()
    const newsToEdit = useEntity<'getPartnershipNewsItem'>('/api/partnership_news/' + id)
    const news = useEntities<'getPartnershipNewsCollection'>(`/api/partnerships/${partnershipId}/news`)

    const breadcrumbs = [
        {title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId},
        {title: 'Nyheter', to: `/dashboard/kommuner/${partnershipId}/nyheter`},
        {title: 'Nyhetsartikkel'},
    ]

    if (!newsToEdit['@loaded']) return <LoadingComponent />

    const changeArticle = value => {
        newsToEdit.changeField('article', value)
    }
    const changeValidFrom = value => {
        newsToEdit.changeField('validFrom', value)
    }
    const changeValidUntil = value => {
        newsToEdit.changeField('validUntil', value)
    }
    const changeImportant = value => {
        newsToEdit.changeField('important', value)
    }
    const changeUrlSlug = value => {
        newsToEdit.changeField('urlSlug', value)
    }

    const onSave = async () => {
        const title = getTitleFromContent(newsToEdit.article)
        newsToEdit.changeField('title', title)
        await newsToEdit.save()
        await news.refresh()
        navigate(`/dashboard/kommuner/${partnershipId}/nyheter`)
    }

    return (
        <Page breadcrumbs={breadcrumbs} variant={'full'}>
            <Panel variant={'flat'}>
                <Panel.Body>
                    <TextEditorField
                        id={'article-content'}
                        label='Artikkel'
                        placeholder='Nyhetsartikkelen'
                        required
                        value={newsToEdit?.article}
                        onChange={e => changeArticle(e)}
                    />
                    <DateField
                        id={'validFrom'}
                        autocomplete={'off'}
                        label='Aktiv fra'
                        help='La stå blankt for å være deaktivert'
                        value={newsToEdit?.validFrom}
                        onChange={e => changeValidFrom(e)}
                    />
                    <DateField
                        id={'validUntil'}
                        autocomplete={'off'}
                        label='Aktiv til'
                        help='La stå blankt for å ikke ha tidsbegrensning'
                        value={newsToEdit?.validUntil}
                        onChange={e => changeValidUntil(e)}
                    />
                    <CheckboxField
                        id='important'
                        label='Angi viktig nyhet'
                        value={newsToEdit?.important}
                        onChange={val => changeImportant(val)}
                    />
                    <TextField
                        id='urlSlug'
                        type='text'
                        label='URL slug'
                        placeholder=''
                        required
                        value={newsToEdit?.urlSlug}
                        onChange={val => changeUrlSlug(val)}
                        help='Angir slutten på URL lenken'
                    />
                </Panel.Body>
                <Panel.Footer>
                    <div className={'row'}>
                        <div className={'col-xs-4'}>
                            <Button variant={'link'} to={`/dashboard/kommuner/nyheter/${partnershipId}`} aria-label={'Tilbake'}>
                                Tilbake
                            </Button>
                        </div>
                        <div className={'col-xs-4'} />
                        <div className={'col-xs-4 text-right'}>
                            <Button onClick={onSave} aria-label={'Lagre'}>
                                Lagre
                            </Button>
                        </div>
                    </div>
                </Panel.Footer>
            </Panel>
        </Page>
    )
}
