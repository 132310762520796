import React from 'react'
import useEntities from '../Hooks/useEntities'
import Modal from '../Components/Modal'
import useEntity from '../Hooks/useEntity'
import {format} from 'date-fns'
import {ParticipantState} from './Waitingroom'

const ParticipantsModal = ({iri, onClose}) => {
    const consultation = useEntity<'getConsultationItem'>(iri)
    const participants = useEntities<'getConsultationParticipants'>(iri + '/participants', 120)

    return (
        <Modal cancelText={'Skjul'} onClose={onClose} title={'Deltakere til ' + consultation.name}>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Navn</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {participants.map(p => (
                        <Participant iri={p['@id']!} key={p['@id']} />
                    ))}
                </tbody>
            </table>
        </Modal>
    )
}
export default ParticipantsModal

const Participant = React.memo(function Participant({iri}: {iri: string}) {
    const p = useEntity<'getConsultationParticipantItem'>(iri)

    if (!p['@loaded']) return null

    const state = ParticipantState(p)
    let className = 'text-muted'
    if (state === 'disconnected') className = 'text-muted'
    if (state === 'error') className = 'danger'
    if (state === 'connected') className = 'success'

    const disconnectedAt = p.disconnectedAt ? new Date(p.disconnectedAt) : null
    const connectedAt = p.connectedAt ? new Date(p.connectedAt) : null
    const connectedAtStr = connectedAt ? format(connectedAt, 'do LLL HH:mm') : null
    const disconnectedAtStr = disconnectedAt ? format(disconnectedAt, 'do LLL HH:mm') : null

    return (
        <tr className={className}>
            <td>
                {state === 'error' && <i className={'fa fa-exclamation-triangle'} />}
                <i className={`fa ${p.patient ? 'fa-user' : 'fa-user-md'}`} />
            </td>
            <td>{p.name}</td>
            <td>
                {state === 'error' ? p.message ?? 'Mistet kontakt' : ''}
                {state === 'connected' ? 'Tilkoblet' + (connectedAtStr ? ' (' + connectedAtStr + ')' : '') : null}
                {state === 'disconnected' ? 'Frakoblet (' + (connectedAtStr ? connectedAtStr + ' - ' : '') + disconnectedAtStr + ')' : null}
                {state === 'unknown' && <>Ukjent</>}
            </td>
        </tr>
    )
})
