import React from 'react'
import FormGroup from '../../../Components/FormGroup'
import EditText from '../../Components/EditText'
import styled from 'styled-components'

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 2.5em 0;
`

const StyledDescription = styled.div`
    margin: 2.5em 0 1em 0;
`

const CounselorEmailIndicator = ({indicator, answer, onChange, disabled}) => {
    const {fresh, value, valid} = answer

    const onTitleSave = value => {
        indicator.changeField('title', value)
        indicator.save()
    }

    const onDescriptionSave = value => {
        indicator.changeField('description', value)
        indicator.save()
    }

    const onRowsSave = value => {
        indicator.changeField('rows', parseInt(value, 10))
        indicator.save()
    }

    return (
        <FormGroup hasError={!fresh && !valid && !indicator['optional']} hasSuccess={!fresh && valid}>
            <StyledLabel>
                <EditText text={indicator.title} onSave={onTitleSave} />
            </StyledLabel>
            {indicator.rows <= 1 && (
                <input
                    disabled={disabled}
                    name={indicator['@id']}
                    onBlur={e => onChange(e.target.value, true)}
                    onChange={e => onChange(e.target.value)}
                    value={value ? value : ''}
                    className='form-control'
                    type='text'
                />
            )}
            {indicator.rows > 1 && (
                <textarea
                    disabled={disabled}
                    name={indicator['@id']}
                    onBlur={e => onChange(e.target.value, true)}
                    value={value ? value : ''}
                    onChange={e => onChange(e.target.value)}
                    style={{resize: 'vertical'}}
                    className='form-control'
                />
            )}

            <span className='help-block'>
                Antall rader:{' '}
                <EditText type='number' width='6em' inline placeholder='Antall rader' text={indicator.rows} onSave={onRowsSave} />
            </span>
            <StyledDescription className='help-block'>
                <EditText placeholder='Legg til beskrivelse' text={indicator.description} onSave={onDescriptionSave} />
            </StyledDescription>
            <label className='help-block'>Dette feltet er valgfritt</label>
            <span className='text-warning'>
                Alle svar blir sent til veileder(e), eventuelt kurs-administrator eller kontakt@skilnet.no. Svarene blir slettet etter at de
                er sendt.
            </span>
        </FormGroup>
    )
}
export default CounselorEmailIndicator
