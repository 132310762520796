import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import LoadingComponent from '../../Components/LoadingComponent'
import {format, subMonths} from 'date-fns'
import GoalCardAllCoursesCompletions from './GoalCardAllCoursesCompletions'

const Type0CourseCompletions = () => {
    const {data: stats, isFetching} = useSkilQuery<'getAllCoursesCompletionsStatistics'>('/api/courses/statistics/all_courses_completions')

    const date27MonthsAgo = subMonths(new Date(), 27)
    const dateFrom = format(date27MonthsAgo, 'dd.MM.yy')
    const date9MonthsAgo = subMonths(new Date(), 9)
    const dateUntil = format(date9MonthsAgo, 'dd.MM.yy')
    const dateFromUntil = dateFrom + ' til ' + dateUntil
    if (!stats || isFetching) {
        return <LoadingComponent />
    }

    return (
        <GoalCardAllCoursesCompletions
            title='E-læringskurs fullførelse'
            header={`Viser påmeldinger i dato intervallet: ${dateFromUntil}`}
            description={''}
            description1Type0={'% fullført innen 9 mnd'}
            // @ts-expect-error
            percentage1Type0={stats.percentageCompletedWithin9MonthsType0}
            description2Type0={'% fullført innen 18 mnd'}
            // @ts-expect-error
            percentage2Type0={stats.percentageCompletedWithin18MonthsType0}
            // @ts-expect-error
            description3Type0={`Fullført innen 9 mnd: ${stats.completedWithin9MonthsType0}`}
            // @ts-expect-error
            description4Type0={`Fullført innen 18 mnd: ${stats.completedWithin18MonthsType0}`}
            // @ts-expect-error
            description5Type0={`Fullført etter 18 mnd: ${stats.completedAfter18MonthsType0}`}
            // @ts-expect-error
            description6Type0={`Påmeldinger: ${stats.acceptedType0}`}
            // @ts-expect-error
            description7Type0={`Aldri fullført: ${stats.neverCompletedType0}`}
        />
    )
}

export default Type0CourseCompletions
