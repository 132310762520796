import * as React from 'react'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import {IconButtonWithDropdownMenu} from './IconButtonWithDropdownMenu'
import {DropdownMenuItem} from './IconButtonWithDropdownMenu'

export const DeleteIconButton = ({deleteAction, deleteMenuItemLabel = 'Slett', tooltipText = 'Slett', menuId}) => {
    const menuItems: DropdownMenuItem[] = [
        {
            label: deleteMenuItemLabel,
            action: deleteAction,
        },
    ]

    return <IconButtonWithDropdownMenu tooltipText={tooltipText} buttonIcon={<DeleteIcon />} menuId={menuId} menuItems={menuItems} />
}
