import * as React from 'react'
import {useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {Box} from '../../Components/Panel'
import DataGrid from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {GridColDef, GridSelectionModel} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import areaColumnDef from '../../Components/DataGrid/columnDefs/areaColumnDef'
import {Button} from '../../Components/Button/MuiButton'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import AddIcon from '@mui/icons-material/Add'
import {toast} from 'react-toastify'
import FindUserModal from '../../Components/FindUserModal'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import MapIcon from '@mui/icons-material/Map'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {SendMessageDialog} from '../Components/SendMessageDialog'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import {CountiesExportToAreaColumnDef} from '../../Components/CountiesExportToAreaColumnDef'
import {useEffect} from 'react'

const renderCell = params => {
    if (!params.value) return ''

    return (
        <Button variant='text' href={`/dashboard/users/${params.row.id}`}>
            {params.value}
        </Button>
    )
}
const renderOfficeCell = params => {
    if (!params.value) return ''

    const officeId = params.row.office.substring('/api/offices/'.length)

    return (
        <Button variant='text' href={`/dashboard/offices/${officeId}/settings`}>
            {params.value}
        </Button>
    )
}

const Users = () => {
    const [showAddUser, setShowAddUser] = React.useState(false)
    const [showSendText, setShowText] = React.useState(false)
    const [showSendEmail, setShowEmail] = React.useState(false)
    const [selectedUsers, setSelectedUsers] = React.useState<GridSelectionModel>([])
    const {data, isFetching, refetch} = useSkilQuery<'getUserStatistics'>('/api/users/statistics', {pagination: false})
    const users = data?.['hydra:member'] ?? []
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const counties = CountiesExportToAreaColumnDef(hasLoaded)

    const addUserMutation = useSkilMutation<'addUser'>('POST', `/api/users`, [`/api/users`, '/api/users/statistics'])

    const columns: GridColDef[] = [
        columnDef({field: 'id', headerName: 'ID', type: 'number'}),
        columnDef({field: 'name', headerName: 'Navn', type: 'string', renderCell}),
        columnDef({field: 'email', headerName: 'Epost', type: 'string'}),
        areaColumnDef({
            field: 'location',
            headerName: 'Kommune',
            type: 'county',
            counties: counties.map(county => ({label: county.label, value: county.id})),
        }),
        areaColumnDef({
            field: 'location',
            headerName: 'Fylke',
            type: 'region',
            counties: counties.map(county => ({label: county.region, value: county.regionId})),
        }),
        columnDef({field: 'officeName', headerName: 'Legekontor', type: 'string', renderCell: renderOfficeCell}),
    ]

    const onAddUser = async addUser => {
        try {
            await addUserMutation.mutateAsync(addUser)
            setShowAddUser(false)
            refetch()
                .then(() => {})
                .catch(() => {})
            toast('Brukeren ble opprettet', {type: 'success'})
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const onChangeSelectedUsers = (event, value) => {
        setSelectedUsers(value.map(v => v.id))
    }

    const userActions: SpecialActionProps[] = [
        {
            label: 'Send SMS',
            action: () => setShowText(true),
            disabled: selectedUsers.length === 0,
            icon: {
                position: 'start',
                icon: <PhoneIphoneOutlinedIcon />,
            },
        },
        {
            label: 'Send Epost',
            action: () => setShowEmail(true),
            disabled: selectedUsers.length === 0,
            icon: {
                position: 'start',
                icon: <EmailOutlinedIcon />,
            },
        },
        {
            label: 'Kart',
            href: '/dashboard/SKIL/users/map',
            action: () => {},
            icon: {
                position: 'start',
                icon: <MapIcon />,
            },
        },
        {
            label: 'Legg til bruker',
            action: () => setShowAddUser(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
    ]

    const currentSelection = users.filter(i => selectedUsers.includes(Number(i.id)))
    const userIris = users.filter(i => selectedUsers.includes(Number(i.id))).map(d => d['@id'] as string)

    useEffect(() => {
        // we only want to get the counties once
        if (!hasLoaded && counties?.length > 0) {
            setHasLoaded(true)
        }
    }, [counties])

    return (
        <Box>
            <DataGridWrapper rows={users.length}>
                <DataGrid
                    id={'users'}
                    checkboxSelection
                    loading={isFetching}
                    rows={users}
                    columns={columns}
                    initModel={{columnVisibilityModel: {id: false}}}
                    disableSelectionOnClick
                    onSelectionModelChange={newSelectedUsers => {
                        setSelectedUsers(newSelectedUsers)
                    }}
                    selectionModel={selectedUsers}
                    toolbarButtons={<SpecialActions actions={userActions} />}
                />
            </DataGridWrapper>
            {showAddUser && <FindUserModal onSave={onAddUser} onClose={() => setShowAddUser(false)} disableSkilUser={true} />}
            <SendMessageDialog isOpen={showSendText} isText onClose={() => setShowText(false)} userIris={userIris}>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={users}
                    value={currentSelection}
                    onChange={onChangeSelectedUsers}
                    getOptionLabel={option => String(option.name)}
                    limitTags={5}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>
            <SendMessageDialog isOpen={showSendEmail} isEmail onClose={() => setShowEmail(false)} userIris={userIris}>
                <Autocomplete
                    multiple
                    id='recipients'
                    options={users}
                    value={currentSelection}
                    onChange={onChangeSelectedUsers}
                    getOptionLabel={option => String(option.name)}
                    limitTags={5}
                    renderInput={params => <TextField {...params} label='Mottakere' />}
                />
            </SendMessageDialog>
        </Box>
    )
}

export default Users
