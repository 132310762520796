import * as React from 'react'
import DialogContent from '@mui/material/DialogContent'
import Button from '../../Components/Button/Button'
import Grid from '@mui/material/Grid'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import {DialogTitle} from '@mui/material'

type Props = {
    userName: string
    shortTitle?: string
    userCourseId?: string
    isOpen: boolean
    handleClose: () => any
    handleSubmit: () => any
}

export default function RemoveUserFromSeminarDialog({
    handleSubmit,
    handleClose,
    isOpen = false,
    userName,
    shortTitle,
    userCourseId,
}: Props) {
    const onSubmit = async () => {
        handleSubmit()
        onClose()
    }

    const onClose = () => {
        handleClose()
    }

    return (
        <Dialog maxWidth='sm' open={isOpen} onClose={onClose}>
            <DialogContent>
                <DialogTitle sx={{pl: 0}}>
                    <strong>Bekreft sletting</strong>
                </DialogTitle>
                <Grid container spacing={2} mt={2} pl={2} pr={2}>
                    <div>
                        Vær oppmerksom på at sletting av <strong>{userName}</strong> fra seminaret innebærer at eventuell kurs fremdrift
                        også slettes. Dette kan ikke reverseres.
                    </div>
                    {shortTitle && userCourseId && (
                        <div style={{marginTop: '1em'}}>
                            <a target={'_blank'} href={`/dashboard/course/${shortTitle}/users/${userCourseId}/`}>
                                Se kursfremdriften
                            </a>
                        </div>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='default' onClick={onClose}>
                    Avbryt
                </Button>
                <Button variant={'danger'} onClick={onSubmit}>
                    Slett
                </Button>
            </DialogActions>
        </Dialog>
    )
}
