import React, {useState, useEffect} from 'react'
import Button from '../Components/Button/Button'
import useEntity from '../Hooks/useEntity'
import styled from 'styled-components'
import useEntities from '../Hooks/useEntities'
import Collapse from '../Components/Collapse'
import LoadingComponent from '../Components/LoadingComponent'
import {Page} from '../Components/Page'
import {useParams, useNavigate} from 'react-router'
import useUser from '../Utilities/useUser'
import {SkilQueryResponseType, useSkilQuery} from '../Utilities/QueryClient'
import {components} from '../Generated/eportal'
import SimpleSelectField from '../Components/Fields/SimpleSelectField'

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    caption {
        caption-side: bottom;
        text-align: center;
    }

    .kvalitetsseminar {
        border: 1px solid #062a30 !important;
        box-sizing: border-box;
    }

    .kvalitetspakke {
        border: 1px solid #062a30 !important;
        box-sizing: border-box;
        border-radius: 4px;
    }

    th.grouped {
        background: #062a30;
        color: #ffffff;
        font-weight: 600;
    }

    thead {
        color: #062a30;
    }

    tfoot th,
    thead th,
    tbody td {
        text-align: center;
    }

    thead th {
        font-weight: normal;
    }

    td,
    th {
        padding: 0.5em;

        span {
            padding: 0.5em;
            display: block;
            border-radius: 4px;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:first-child {
            padding-left: 0;
        }
    }

    thead tr.subheader span {
        background: inherit;
    }

    tbody {
        tr {
            td:first-child span {
                padding-left: 11px;
            }

            td {
                padding-top: 0;
                padding-bottom: 0;

                span {
                    border-radius: 0;
                }
            }
        }

        tr:first-child td {
            padding-top: 0.5em;

            span {
                padding-top: 0.6em;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        tr.lastRow td {
            padding-bottom: 0.6em;

            span {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        tr {
            transition: all 0.2s ease;
        }
    }

    tbody:nth-child(2n + 1) tr td span {
        background: #e5e5e5;

        &.success {
            background: #b2df8f;
        }
    }

    tbody:nth-child(2n + 2) tr td span {
        background: #f2f2f2;

        &.success {
            background: #b2df8f;
        }
    }
`

export default function Doctors() {
    const {partnershipId} = useParams()
    const [expanded, setExpanded] = useState<number>()
    const {data, isLoading} = useSkilQuery<'getPartnershipStatsItem'>(
        `/api/partnerships/statistics/${partnershipId}`,
        {},
        {enabled: !!partnershipId}
    )
    const offices = data?.offices ?? []

    const breadcrumbs = [{title: 'Kommunedashboard', to: '/dashboard/kommuner/' + partnershipId}, {title: 'Leger'}]

    return (
        <Page breadcrumbs={breadcrumbs}>
            <div className={'col-xs-12'}>
                {isLoading && <LoadingComponent />}
                <Collapse expanded={offices.length > 0}>
                    <StyledTable cellspacing={10}>
                        <caption>
                            Oversikt over leger som er invitert eller godtatt en invitasjon til kvalitetskurs eller -seminar siste 12
                            måneder
                        </caption>
                        <colgroup span={1} className={'details'} />
                        <colgroup span={2} className={'kvalitetsseminar'} />
                        <colgroup className={'space'} />
                        <colgroup span={2} className={'kvalitetspakke'} />
                        <thead>
                            <tr>
                                <th colSpan={1} />
                                <th colSpan={2} className={'grouped'}>
                                    <span>Kvalitetsseminar</span>
                                </th>
                                <th />
                                <th colSpan={2} className={'grouped'}>
                                    <span>Kvalitetspakke</span>
                                </th>
                            </tr>
                            <tr className={'subheader'}>
                                <th style={{textAlign: 'left'}}>
                                    <span>Legekontor</span>
                                </th>
                                <th>
                                    <span>Invitert</span>
                                </th>
                                <th>
                                    <span>Påmeldt</span>
                                </th>
                                <th />
                                <th>
                                    <span>Invitert</span>
                                </th>
                                <th>
                                    <span>Påmeldt</span>
                                </th>
                            </tr>
                        </thead>
                        {offices.map(o => {
                            // @ts-expect-error
                            const officeId: number = o.office.id
                            return (
                                <OfficeDetails
                                    expanded={expanded === officeId}
                                    onToggle={() => setExpanded(officeId === expanded ? undefined : officeId)}
                                    office={o}
                                    key={o['@id']}
                                />
                            )
                        })}
                        <tfoot>
                            <tr>
                                <th>
                                    <span>Helfo: {data?.doctors} fastlegehjemler</span>
                                </th>
                                <th>Fullført</th>
                                <th>{data?.doctorsFinishedAtleastOneSeminars}</th>
                                <th />
                                <th>Fullført</th>
                                <th>{data?.doctorsFinishedAtleastOneQualityCourse}</th>
                            </tr>
                        </tfoot>
                    </StyledTable>
                </Collapse>
            </div>
        </Page>
    )
}

const NavButton = styled(Button)`
    text-decoration: none;
    font-weight: 600;
`

type OfficeDetailsProps = {
    office: SkilQueryResponseType<'getPartnershipStatsItem'>['offices'][number]
    onToggle: Function
    expanded: boolean
}

const OfficeDetails = ({office, onToggle, expanded}: OfficeDetailsProps) => {
    // @ts-expect-error
    const hasInvalidEmail = office.users.some(d => d.user.invalidEmail)

    const seminarInvited = office.users.reduce((carry, dto) => carry + (dto.seminarInvitations || dto.seminars ? 1 : 0), 0)
    const seminarAcknowledged = office.users.reduce((carry, dto) => carry + (dto.seminars ? 1 : 0), 0)
    const courseInvitation = office.users.reduce((carry, dto) => carry + (dto.courseInvitations || dto.courses ? 1 : 0), 0)
    const courseAcknowledged = office.users.reduce((carry, dto) => carry + (dto.courses ? 1 : 0), 0)
    return (
        <tbody>
            <tr className={expanded ? '' : 'lastRow'}>
                <td style={{textAlign: 'left', fontWeight: 'bold'}}>
                    <span style={{paddingLeft: '7px'}}>
                        <NavButton inline variant={'link'} onClick={onToggle}>
                            {hasInvalidEmail && (
                                <>
                                    <i className={'far fa-exclamation-triangle'} title={'Bruker med ugyldig epost'} />
                                    &nbsp;
                                </>
                            )}
                            {office.office?.name} <i className={expanded ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
                        </NavButton>
                    </span>
                </td>
                <td>
                    <span className={seminarInvited > 0 ? 'success' : ''}>{seminarInvited}</span>
                </td>
                <td>
                    <span className={seminarAcknowledged > 0 ? 'success' : ''}>{seminarAcknowledged}</span>
                </td>
                <td />
                <td>
                    <span className={courseInvitation > 0 ? 'success' : ''}>{courseInvitation}</span>
                </td>
                <td>
                    <span className={courseAcknowledged > 0 ? 'success' : ''}>{courseAcknowledged}</span>
                </td>
            </tr>
            {office.users.map((d, i) => (
                <Collapse expanded={expanded} as={'tr'} key={d.user?.id} className={office.users.length - 1 === i ? 'lastRow' : ''}>
                    <td style={{textAlign: 'left'}}>
                        <span>
                            {d.user?.invalidEmail && (
                                <>
                                    <i title={'Ugyldig epost adresse'} className={'far fa-fw fa-exclamation-triangle'} />
                                    &nbsp;
                                </>
                            )}
                            {d.user?.name}
                            {d.user?.role?.name === 'Fastlege' && <i title={d.user.role.name} className={'fa fa-fw fa-user-md'} />}
                        </span>
                    </td>
                    <td>
                        <span className={d.seminarInvitations || d.seminars ? 'success' : ''}>
                            {d.seminarInvitations || d.seminars ? 'Ja' : 'Nei'}
                        </span>
                    </td>
                    <td>
                        <span className={d.seminars ? 'success' : ''}>{d.seminars ? 'Ja' : 'Nei'}</span>
                    </td>
                    <td />
                    <td>
                        <span className={d.courseInvitations || d.courses ? 'success' : ''}>
                            {d.courseInvitations || d.courses ? 'Ja' : 'Nei'}
                        </span>
                    </td>
                    <td>
                        <span className={d.courses ? 'success' : ''}>{d.courses ? 'Ja' : 'Nei'}</span>
                    </td>
                </Collapse>
            ))}
        </tbody>
    )
}
