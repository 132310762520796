import * as React from 'react'
import {Route, Routes, useLocation, useMatch, useRoutes, useInRouterContext} from 'react-router-dom'
import Meetings from './ListViews/Meetings'
import MassMailing from './MassMailing'
import UserRoles from './UserRoles'
import NefleUsage from './NefleUsage'
import HelfoSync from './HelfoSync'
import Partnerships from './ListViews/Partnerships'
import PartnershipDetails from '../Partnerships/PartnershipDetails'
import MergeUsers from './MergeUsers'
import Form from '../FormEditor/Form/Form'
import Job from '../EditJob/Job'
import Overview from './Overview'
import Tools from './ListViews/Tools'
import Courses from './ListViews/Courses'
import Participants from './ListViews/Participants'
import Groups from './ListViews/Groups'
import Offices from './ListViews/Offices'
import Users from './ListViews/Users'
import Jobs from './ListViews/Jobs'
import Counselors from './ListViews/Counselors'
import {APP_ROUTES} from './constants/routes'
import PartnershipsStats from './ListViews/PartnershipsStats'
import {Goals23} from './Dashboard/Goals23'
import {PageNotFound} from '../PageNotFound'
import {CourseDevelopment} from './ListViews/CourseDevelopment'
import CourseCompletions from './ListViews/CourseCompletions'
import NefleDiscountCodes from './NefleDiscountCodes'
import {Goals24} from './Dashboard/Goals24'
import PartnershipSignups from './ListViews/PartnershipsSignups'
import NewsPopup from './NewsPopup'
import CustomNewsPopupDialog from './Components/CustomNewsPopupDialog'
import {useSkilQuery} from '../Utilities/QueryClient'
import {BackdropComponent} from '../Components/BackdropComponent'
import styled from 'styled-components'

interface NewsPopup {
    key: string
    open: boolean
}

const StyledBackdropComponent = styled(BackdropComponent)`
    max-height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
    }
`

export const SkilApp = () => {
    const [isNewsPopupOpen, setIsNewsPopupOpen] = React.useState<NewsPopup[]>([])
    const {data: currentNews, isFetching} = useSkilQuery<'getCurrentNewsCollection'>('/api/news_popup/current/news', {}, {enabled: true})
    const isNewsToShow = isNewsPopupOpen.some(newsItem => newsItem.open)

    React.useEffect(() => {
        // @ts-expect-error
        if (currentNews?.length > 0) {
            // @ts-expect-error
            for (let i = 0; i < currentNews?.length; i++) {
                // @ts-expect-error
                const newsPopupKey = 'newsPopup_' + currentNews[i].id
                const item = isNewsPopupOpen.find(newsItem => newsItem.key === newsPopupKey)
                if (!localStorage.getItem(newsPopupKey) && !sessionStorage.getItem(newsPopupKey) && !item) {
                    setIsNewsPopupOpen(prevState => [...prevState, {key: newsPopupKey, open: true}])
                }
            }
        }
    }, [currentNews])
    const handleNewsPopupRemove = newsItem => {
        const newsPopupKey = 'newsPopup_' + newsItem.id
        const index = isNewsPopupOpen.findIndex(newsItem => newsItem.key === newsPopupKey)
        const item = isNewsPopupOpen[index]

        if (item && index > -1) {
            setIsNewsPopupOpen(prevState => {
                return prevState.map((item, idx) => {
                    if (idx === index) {
                        return {key: newsPopupKey, open: false}
                    }
                    return item
                })
            })
        } else {
            setIsNewsPopupOpen(prevState => [...prevState, {key: newsPopupKey, open: false}])
        }
        localStorage.setItem(newsPopupKey, 'true') // Store in local storage for removed popups
    }
    const handleNewsPopupHide = newsItem => {
        const newsPopupKey = 'newsPopup_' + newsItem.id
        const index = isNewsPopupOpen.findIndex(newsItem => newsItem.key === newsPopupKey)
        const item = isNewsPopupOpen[index]

        if (item && index > -1) {
            setIsNewsPopupOpen(prevState => {
                return prevState.map((item, idx) => {
                    if (idx === index) {
                        return {key: newsPopupKey, open: false}
                    }
                    return item
                })
            })
        } else {
            setIsNewsPopupOpen(prevState => [...prevState, {key: newsPopupKey, open: false}])
        }
        sessionStorage.setItem(newsPopupKey, 'true') // Store in session storage for hidden popups
    }

    return (
        <>
            <Routes>
                <Route path={'/'} element={<Overview />}>
                    <Route path={APP_ROUTES.HOME} element={<CourseDevelopment />} />
                    <Route path={APP_ROUTES.GOALS23} element={<Goals23 />} />
                    <Route path={APP_ROUTES.GOALS24} element={<Goals24 />} />
                    <Route path={APP_ROUTES.COURSES} element={<Courses />} />
                    <Route path={APP_ROUTES.PARTICIPANTS} element={<Participants />} />
                    <Route path={APP_ROUTES.GROUPS} element={<Groups />} />
                    <Route path={APP_ROUTES.MEETINGS} element={<Meetings />} />
                    <Route path={APP_ROUTES.OFFICES} element={<Offices />} />
                    <Route path={APP_ROUTES.USERS} element={<Users />} />
                    <Route path={APP_ROUTES.COUNSELORS} element={<Counselors />} />
                    <Route path={APP_ROUTES.PARTNERSHIPSIGNUPS} element={<PartnershipSignups />} />
                    <Route path={APP_ROUTES.PARTNERSHIPS} element={<Partnerships />} />
                    <Route path={APP_ROUTES.PARTNERSHIPS_STATISTICS} element={<PartnershipsStats />} />
                    <Route path={APP_ROUTES.JOBS} element={<Jobs />} />
                    <Route path={APP_ROUTES.TOOLS} element={<Tools />} />
                    <Route path={APP_ROUTES.STATISTICS_COURSE_COMPLETIONS} element={<CourseCompletions />} />
                </Route>
                <Route path={APP_ROUTES.FORMS + '/:formId'} element={<Form />} />
                <Route path={APP_ROUTES.FORMS + '/:formId/:groupId'} element={<Form />} />
                <Route path={APP_ROUTES.MASS_MAILING} element={<MassMailing />} />
                <Route path={APP_ROUTES.NEWS_POPUPS} element={<NewsPopup />} />
                <Route path={APP_ROUTES.USER_ROLES} element={<UserRoles />} />
                <Route path={APP_ROUTES.REPORTS_NEFLE_USAGE} element={<NefleUsage />} />
                <Route path={APP_ROUTES.MERGE_USERS} element={<MergeUsers />} />
                <Route path={APP_ROUTES.MERGE_USERS + '/:idUser1'} element={<MergeUsers />} />
                <Route path={APP_ROUTES.MERGE_USERS + '/:idUser1/:idUser2'} element={<MergeUsers />} />
                <Route path={APP_ROUTES.PARTNERSHIPS + '/:id'} element={<PartnershipDetails />} />
                <Route path={APP_ROUTES.JOBS + `/:jobId/`} element={<Job />} />
                <Route path={APP_ROUTES.NEFLE_DISCOUNT_CODES} element={<NefleDiscountCodes />} />
                <Route path={'*'} element={<PageNotFound />} />
            </Routes>
            <StyledBackdropComponent isOpen={isNewsToShow} status={'Nyheter'} showSpinner={false}>
                {(() => {
                    // @ts-expect-error
                    return currentNews?.map((item, i) => {
                        const newsPopupKey = 'newsPopup_' + item.id

                        // find the news item that is open
                        const isOpen = isNewsPopupOpen.find(newsItem => newsItem.key === newsPopupKey)?.open

                        if (!isOpen) {
                            return null
                        }

                        return (
                            <CustomNewsPopupDialog
                                key={'currentNews_' + i}
                                title={item.title}
                                message={item.message}
                                onClose={() => handleNewsPopupHide(item)}
                                onRemove={() => handleNewsPopupRemove(item)}
                                isOpen={isOpen}
                            />
                        )
                    })
                })()}
            </StyledBackdropComponent>
        </>
    )
}
