import React, {useState} from 'react'
import LoadingComponent from './LoadingComponent'
import TextField from './Fields/TextField'
import MultiSelectField from './Fields/MultiSelectField'
import {useSkilQuery} from '../Utilities/QueryClient'
import employees from '../Nefle/Setup/Pages/Employees'
import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import {Button} from './Button/MuiButton'

export default function FindOfficeDialog({onSave, isOpen, onClose}) {
    const [search, setSearch] = useState('')
    const [showSearchResults, setShowSearchResults] = useState<boolean>(false)
    const [office, setOffice] = useState(null)
    const [multipleEmployees, setMultipleEmployees] = useState<Array<string>>([])
    const [selected, setSelected] = useState<{type: string | null; id: number | null; name: string | null}>({
        type: null,
        id: null,
        name: null,
    })
    const {data, isSuccess, refetch} = useSkilQuery<'getOfficeEmployees'>(
        office?.['@id'] + `/employees`,
        {},
        {
            enabled: !!office,
        }
    )
    const employees = data?.['hydra:member'].map(e => ({'@id': e['@id']!, name: e.user!.name}))
    const officesResponse = useSkilQuery<'findOffices'>(`/api/offices/find`, {name: search, itemsPerPage: 5}, {enabled: search.length > 3})
    const offices = officesResponse?.data?.['hydra:member'] ?? []

    const onChangeOffice = async office => {
        setOffice(office)
        setSearch(office.name)
        setSelected({...selected, type: 'skil', id: office['@id']})

        const {data} = await refetch()
        setMultipleEmployees(data!['hydra:member'].map(e => e['@id']!))
        setShowSearchResults(false)
    }

    const onChangeSearch = string => {
        setShowSearchResults(true)
        setMultipleEmployees([])
        setOffice(null)
        setSearch(string)
    }

    const onSubmit = () => {
        onSave(multipleEmployees)
        setSearch('')
        setMultipleEmployees([])
    }

    const onLocalClose = () => {
        setMultipleEmployees([])
        setSearch('')
        setOffice(null)

        return onClose()
    }

    const onSaveEnabled = selected.type !== null && selected.type === 'skil' && multipleEmployees.length > 0

    return (
        <Dialog open={isOpen} maxWidth='sm' fullWidth onClose={onLocalClose}>
            <DialogTitle sx={{pb: 2}}>Legg til brukere fra legekontor</DialogTitle>
            <DialogContent style={{paddingTop: '5px'}}>
                <TextField
                    id={'office'}
                    label={'Legekontor'}
                    placeholder={'Søk etter legekontor'}
                    value={search}
                    onChange={onChangeSearch}
                />
                <div className={'list-group'}>
                    {officesResponse.isLoading && showSearchResults && <LoadingComponent />}
                    {showSearchResults &&
                        !officesResponse.isLoading &&
                        offices.map(o => (
                            <button type={'button'} key={o.id} onClick={() => onChangeOffice(o)} className={'list-group-item'}>
                                {selected.type === 'skil' && selected.id === o.id && <i className={'fa fa-circle'} />} {o.name}{' '}
                                <em className='label label-primary pull-right' />
                            </button>
                        ))}
                </div>
                {office && !isSuccess && <LoadingComponent />}
                {office && isSuccess && (
                    <MultiSelectField
                        id={'employees'}
                        label={'Ansatte'}
                        value={multipleEmployees}
                        entities={employees ?? []}
                        onChange={setMultipleEmployees}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{px: 3, pb: 2}}>
                <Button variant='outlined' onClick={onLocalClose}>
                    Lukk
                </Button>
                <Button disabled={!onSaveEnabled} variant='contained' onClick={onSubmit}>
                    Lagre
                </Button>
            </DialogActions>
        </Dialog>
    )
}
