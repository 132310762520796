import React, {useState, useContext, useEffect} from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import {EditLockContext} from './Form'

const StyledDiv = styled(motion.div)`
    position: relative;
    height: 2.5em;
    :hover {
        cursor: pointer;
    }

    hr {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0;
    }

    .icon {
        z-index: 0;
        position: absolute;
        background: white;
        text-align: center;
        left: 0;
        right: 0;
        top: calc(0.5em);
        width: 2em;
        margin: 0 auto;
    }
`

const plusMotion = {
    rest: {
        opacity: 0,
        transition: {
            duration: 0.25,
            type: 'tween',
            ease: 'easeIn',
        },
    },
    hover: {
        opacity: 1,
        scale: 1.8,
        transition: {
            duration: 0.15,
            type: 'tween',
            ease: 'easeInOut',
        },
    },
    tap: {
        scale: 1.2,
    },
}

const AddItem = ({toolbar}) => {
    const [show, setShow] = useState(false)
    const [lock] = useContext(EditLockContext)

    useEffect(() => {
        let id: ReturnType<typeof setTimeout> | undefined = undefined
        if (show) id = setTimeout(() => setShow(false), 2500)

        return () => {
            clearTimeout(id)
        }
    })

    return (
        <>
            <StyledDiv onClick={() => setShow(e => !e)} initial='rest' whileHover='hover' whileTap='tap' hidden={show}>
                <motion.div hidden={lock} variants={plusMotion} className={'icon'}>
                    <i className={'fa fa-plus'} />
                </motion.div>
            </StyledDiv>
            {show && toolbar}
        </>
    )
}
export default AddItem
