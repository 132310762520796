import {toast} from 'react-toastify'
import React, {useEffect} from 'react'
import {SkilQueryResponseType, useSkilMutation, useSkilQuery} from '../Utilities/QueryClient'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {SxProps, Theme, useTheme} from '@mui/material/styles'
import {useNavigate} from 'react-router-dom'
import {LoadedUseEntityType} from '../Hooks/useEntity'

type Props = {
    task: LoadedUseEntityType<SkilQueryResponseType<'getFormRelatedTaskItem'>>
    createAnswerset: Function
    relatedTaskUniqueId: string
    gettingNewAnswerSet: boolean
    approved: boolean | undefined
}
export const Toolbar = ({task, createAnswerset, relatedTaskUniqueId, gettingNewAnswerSet, approved}: Props) => {
    const relatedFormId = task.relatedForm?.replace('/api/forms/', '')
    const navigate = useNavigate()
    const [mobileNumber, setMobileNumber] = React.useState<string>('')
    const [linkAndContent, setLinkAndContent] = React.useState<string>(
        `Takk for konsultasjonen vi nettopp hadde. Som ledd i egen kvalitetssikring og forskning håper jeg du kan svare på denne korte spørreundersøkelsen: https://eportal.skilnet.no/public/related_form/${relatedFormId}/related-task/${relatedTaskUniqueId}`
    )
    const {data: countSlaveAnswerSets, refetch: refetchCountSlave} = useSkilQuery<'CountSlaveRelatedAnswerSets'>(
        `/api/form_related_tasks/${task.id}/countSlaveRelatedAnswerSets`
    )
    const {data: countMasterAnswerSets, refetch: refetchCountMaster} = useSkilQuery<'CountMasterRelatedAnswerSets'>(
        `/api/form_related_tasks/${task.id}/countMasterRelatedAnswerSets`
    )

    // refetch when first loaded
    useEffect(() => {
        refetchCountSlave().then(() => {})
        refetchCountMaster().then(() => {})
    }, [])

    // refetch after new submission by doctor
    useEffect(() => {
        refetchCountSlave().then(() => {})
        refetchCountMaster().then(() => {})
    }, [approved])

    const phoneCons = countMasterAnswerSets?.[`hydra:member`][0] ?? 0
    const eCons = countMasterAnswerSets?.[`hydra:member`][1] ?? 0
    const videoCons = countMasterAnswerSets?.[`hydra:member`][2] ?? 0

    const sendLinkToPatientMutation = useSkilMutation('post', '/api/sms/link/toPatient')
    const theme = useTheme()
    const sendSMS = async () => {
        if (!mobileNumber) {
            return toast('Mobilnummer mangler', {type: 'error'})
        }
        if (!task.relatedForm || !mobileNumber) {
            toast('Lenken til arbeidsarket mangler en ID. SMS ble ikke sendt til pasienten!', {type: 'error'})
            return
        }
        try {
            toast('Sender SMS med lenke til ' + mobileNumber)
            // @ts-expect-error
            await sendLinkToPatientMutation.mutateAsync({mobileNumber, linkAndContent, slaveRelatedTask: relatedTaskUniqueId})
            toast('SMS sending vellykket')
        } catch (e: any) {
            toast('Noe skjedde feil under lagring. SMS ble ikke sendt til pasienten! ' + e.message, {type: 'error'})
        }
    }

    const goToReport = () => {
        navigate(`/course/EKONS/reports/task/${task.id}`)
    }

    const defaultActionButtonStyleProps: SxProps<Theme> = {
        borderRadius: 0,
        marginLeft: 1,
        marginBottom: 0.5,
        padding: theme.spacing(2.5, 2.5),
        lineHeight: 'initial',
        textTransform: 'initial',
        color: `inherit`,
        backgroundColor: theme.palette.grey[300],
        minHeight: theme.spacing(3.75),
        border: `none`,
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
            border: 'none',
        },
    }

    return (
        <>
            <div className='col-xs-12 col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1'>
                <div style={{padding: '1rem', backgroundColor: 'white'}}>
                    <Box>
                        <Grid item xs={12} sx={{marginBottom: 2, display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <Button
                                    disabled={gettingNewAnswerSet}
                                    color={'primary'}
                                    sx={{...defaultActionButtonStyleProps}}
                                    onClick={() => createAnswerset()}
                                >
                                    Ny pasient
                                    {gettingNewAnswerSet && <i className={'fa fa-spin fa-spinner'} />}
                                </Button>
                                <Button sx={{...defaultActionButtonStyleProps}} onClick={() => goToReport()}>
                                    Se pasientsvar ({countSlaveAnswerSets} av min 10 svar)
                                </Button>
                                {countSlaveAnswerSets && countSlaveAnswerSets < 10 ? (
                                    <i
                                        className={'fa fa-info-circle'}
                                        style={{paddingLeft: '5px'}}
                                        title={
                                            'Du må ha minst 10 svar for å se pasientens svar, dette for at pasientenes svar skal være tilstrekkelig anonymisert. Hvis du har færre enn 10 svar, vil du kunne se svar fra kommunen eller landsbasis.'
                                        }
                                        onClick={() =>
                                            toast(
                                                'Du må ha minst 10 svar for å se pasientens svar, dette for at pasientenes svar skal være tilstrekkelig anonymisert. Hvis du har færre enn 10 svar, vil du kunne se svar fra kommunen eller landsbasis.',
                                                {type: 'info'}
                                            )
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div>
                                <p>Videokonsultasjoner: {videoCons}</p>
                                <p>Telefonkonsultasjoner: {phoneCons}</p>
                                <p>E-konsultasjoner: {eCons}</p>
                                <p>Totalt: {phoneCons + eCons + videoCons} av 10</p>
                            </div>
                        </Grid>
                        <Grid hidden={!relatedTaskUniqueId} item xs={12} sx={{marginBottom: 2}}>
                            <TextField
                                minRows={1}
                                maxRows={1}
                                id='to'
                                label='Mobilnummer'
                                value={mobileNumber}
                                onChange={event => {
                                    setMobileNumber(event.target.value)
                                }}
                            />
                            <Button disabled={!relatedTaskUniqueId} sx={{...defaultActionButtonStyleProps}} onClick={() => sendSMS()}>
                                Send SMS
                            </Button>
                        </Grid>
                        <Grid hidden={!relatedTaskUniqueId} item xs={12} sx={{marginBottom: 2, display: `flex`}}>
                            <TextField
                                sx={{width: '100%'}}
                                multiline={true}
                                minRows={1}
                                maxRows={5}
                                disabled={true}
                                id='linkAndMessage'
                                label='Melding'
                                value={linkAndContent}
                            />
                            <Button
                                sx={{...defaultActionButtonStyleProps}}
                                onClick={() => {
                                    navigator.clipboard
                                        .writeText(linkAndContent)
                                        .then(() => toast('Kopiert til utklippstavlen', {type: 'info'}))
                                }}
                            >
                                <i className={'fa fa-copy fa-2x'} style={{paddingLeft: '5px'}} />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{marginBottom: 2, display: `flex`}}>
                            <p>Merk at denne lenken kun skal sendes til én pasient, du får ny lenke for hver pasient</p>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    )
}
