import {LoadingButton} from '@mui/lab'
import {Box, Button, styled} from '@mui/material'
import React, {useEffect, useState} from 'react'

/*
    Native form submit, make sure to wrap SubmitBar in the form
    Main logic for handling save/next button swapping and feedback from zod submit.
    Some input shared between pages that are passed from context
 */
const SubmitBar = ({shouldSpin, isDirty, onDiscard, hasError, isCompleted, next}) => {
    const [shouldNext, setShouldNext] = useState(false)

    useEffect(() => {
        if (shouldNext && !isDirty && !hasError && isCompleted && !shouldSpin) {
            setShouldNext(false)
            next()
        } else if (hasError) {
            setShouldNext(false)
        }
    }, [shouldSpin])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: {xs: '1em', sm: '2em'},
                marginLeft: '10px',
                marginRight: '10px',
            }}
        >
            <Button variant={'contained'} disabled={!isDirty} onClick={() => onDiscard()}>
                Angre
            </Button>
            {isDirty && (
                <SaveButton
                    loading={shouldSpin}
                    variant={'contained'}
                    type='submit'
                    disabled={!isDirty && isCompleted}
                    $hasError={!!hasError}
                >
                    Lagre
                </SaveButton>
            )}
            {!isCompleted || (shouldSpin && !isDirty) ? (
                <LoadingButton
                    loading={shouldSpin && !isDirty}
                    disabled={isDirty || hasError}
                    variant={'contained'}
                    type='submit'
                    onClick={() => {
                        setShouldNext(true)
                    }}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            setShouldNext(true)
                        }
                    }}
                >
                    Neste
                </LoadingButton>
            ) : (
                <Button
                    disabled={isDirty}
                    variant={'contained'}
                    onClick={() => {
                        next()
                    }}
                >
                    Neste
                </Button>
            )}
        </Box>
    )
}

export default SubmitBar

const SaveButton = styled(LoadingButton)`
    @keyframes panic {
        0% {
            left: 0px;
        }
        1% {
            left: 5px;
        }
        2% {
            left: -5px;
        }
        3% {
            left: 5px;
        }
        4% {
            left: -5px;
        }
        5% {
            left: 0px;
        }
    }
    40% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-90deg);
        top: 0px;
    }
    75% {
        transform: rotate(-270deg);
        top: 150px;
    }
    100% {
        transform: rotate(-1080deg);
        top: 400px;
    }
    margin-left: auto;
    margin-right: 8px;

    ${props =>
        props.$hasError &&
        `
            animation: 6s panic linear forwards;
    `}
`
