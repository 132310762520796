import * as React from 'react'
import {Box} from '../../../Components/Panel'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import columnDef, {EditableGridColDef} from '../../../Components/DataGrid/columnDefs/columnDef'
import SpecialActions from '../../../Components/DataGrid/SpecialActions'
import {SpecialActionProps} from '../../../Components/DataGrid/SpecialAction'
import {DataGridWrapper} from '../../../Components/DataGrid/Wrappers'
import DataGrid from '../../../Components/DataGrid/DataGrid'
import {GridCellEditCommitParams, GridEnrichedColDef} from '@mui/x-data-grid-premium'
import dateColumnDef from '../../../Components/DataGrid/columnDefs/dateColumnDef'
import AddIcon from '@mui/icons-material/Add'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {OfficeProps} from './Office'
import {OfficeAddCompensationDialog} from './OfficeAddCompensationDialog'
import {DeleteIconButton} from '../../../Components/Button/DeleteIconButton'
import {toast} from 'react-toastify'

export const OfficeCompensations = ({office, refetch, isFetching}: OfficeProps & {isFetching: boolean}) => {
    const [showAddDialog, setShowAddDialog] = React.useState(false)
    const updateCompensationMutation = useSkilMutation<'patchPraksisnettOfficeCompensationItem'>(
        'PATCH',
        `/api/praksisnett/office/compensations/{id}`,
        [`/api/praksisnett/offices/${office.id}`, `/api/praksisnett/offices`]
    )

    const deleteCompensationMutation = useSkilMutation<'deletePraksisnettOfficeCompensationItem'>(
        'DELETE',
        `/api/praksisnett/office/compensations/${null}`,
        [`/api/praksisnett/offices/${office.id}`, `/api/praksisnett/offices`, `/api/praksisnett/office/compensations`]
    )

    const handleDeleteCompensation = async id => {
        try {
            await deleteCompensationMutation.mutateAsync({
                '@overridePath': `/api/praksisnett/office/compensations/${id}`,
            })
            toast('Kompensasjon slettet', {
                type: 'success',
            })
            refetch()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const onCellEditCommit = async (params: GridCellEditCommitParams) => {
        try {
            await updateCompensationMutation.mutateAsync({
                '@overridePath': `/api/praksisnett/office/compensations/${params.id}`,
                [params.field]: params.value,
            })
            refetch()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    const compensationColumns: (EditableGridColDef | GridEnrichedColDef)[] = React.useMemo(
        () => [
            columnDef({field: 'id', headerName: 'System-ID', type: 'string'}),
            columnDef({field: 'year', headerName: 'År', type: 'number'}, {onCellEditCommit}),
            columnDef({field: 'invoiceNumber', headerName: 'Fakturanummer', type: 'string'}, {onCellEditCommit}),
            dateColumnDef({field: 'invoiceReceivedAt', headerName: 'Faktura mottatt', type: 'date'}, {onCellEditCommit}),
            dateColumnDef({field: 'invoicePaidAt', headerName: 'Faktura betalt', type: 'date'}, {onCellEditCommit}),
            columnDef({field: 'amountPaid', headerName: 'Beløp', type: 'number'}, {onCellEditCommit}),
            dateColumnDef({field: 'invoiceReminderAt', headerName: 'Påminnelse på', type: 'date'}, {onCellEditCommit}),
            columnDef({field: 'invoiceNote', headerName: 'Faktura merknad', type: 'string'}, {onCellEditCommit}),
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Handlinger',

                getActions: ({id}) => {
                    return [
                        <DeleteIconButton
                            key={`del${id}`}
                            deleteMenuItemLabel='Slett kompensasjon'
                            tooltipText='Slett'
                            menuId={`del${id}`}
                            deleteAction={() => handleDeleteCompensation(id)}
                        />,
                    ]
                },
            },
        ],
        []
    )

    const compensationActions: SpecialActionProps[] = [
        {
            label: 'Ny kompensasjon',
            action: () => setShowAddDialog(true),
            icon: {
                position: 'start',
                icon: <AddIcon />,
            },
        },
    ]

    const compensations = office.compensations ?? []

    return (
        <Box title='Kompensasjon'>
            <DataGridWrapper rows={compensations.length} addHeight={9}>
                <DataGrid
                    id={'compensations'}
                    loading={isFetching}
                    disableSelectionOnClick
                    rows={compensations}
                    columns={compensationColumns}
                    initModel={{columnVisibilityModel: {id: false}}}
                    toolbarButtons={<SpecialActions actions={compensationActions} />}
                />
            </DataGridWrapper>
            <OfficeAddCompensationDialog office={office} refresh={refetch} setOpen={setShowAddDialog} isOpen={showAddDialog} />
        </Box>
    )
}
