import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'
import LoadingComponent from '../../Components/LoadingComponent'

const NefleStats = () => {
    const {data: nefleStatistics} = useSkilQuery<'getNefleStatistics'>(`/api/nefles/statistics/nefle_statistics`)

    if (!nefleStatistics) {
        return <LoadingComponent />
    }

    // @ts-expect-error
    const percentage = nefleStatistics ? ((nefleStatistics.totalListSize / 5500000) * 100).toFixed(2) : 0

    return (
        <GoalCard
            title='Nefle statistikk'
            subheader='Inkluderer tall for total listestørrelse, antall leger og legekontor basert på Helfo data.'
            // @ts-expect-error
            description={`Nefle leveres til ${nefleStatistics?.totalListSize} personer og dekker ${percentage}% av befolkningen i Norge`}
            percentage={null}
            // @ts-expect-error
            secondDescription={`${nefleStatistics?.officeCount} antall legekontor`}
            // @ts-expect-error
            thirdDescription={`${nefleStatistics?.doctorCount} antall leger`}
        />
    )
}

export default NefleStats
