import React, {useState} from 'react'
import Modal from '../Components/Modal'
import {toast} from 'react-toastify'
import PartnershipSignupComponent from './PartnershipSignupComponent'

export default function PartnershipSignupModal({onSubmit, onClose, seminarTemplates}) {
    const [spinnerWhileSaving, setSpinnerWhileSaving] = useState(false)
    const [disableWhileSaving, setDisableWhileSaving] = useState(false)
    const onSave = () => {
        //if (
        //    !partnershipSignup.orgNumber ||
        //    partnershipSignup.orgNumber.toString().length !== 9 ||
        //    !partnershipSignup.emailForInvoiceReceipt ||
        //    !partnershipSignup.resourceNumber
        //) {
        //    toast('Obligatoriske påmeldings opplysninger er ikke riktig fylt ut', {type: 'error'})
        //    return
        //}
        setSpinnerWhileSaving(true)
        setDisableWhileSaving(true)
        onSubmit(partnershipSignup)
    }
    const [partnershipSignup, setPartnershipSignup] = useState({
        seminarTheme: null,
        plannedSeminarAt: null,
        educationMeetingAt: null,
        informationMeetingAt: null,
        debriefMeetingAt: null,
        contactPersonRole: '',
        contactPersonName: '',
        contactPersonEmail: '',
        contactPersonPhone: '',
        signupOtherInfo: '',
        description: '',
        signupJiraLink: '',

        name: '',
        orgNumber: 0,
        emailForInvoiceReceipt: '',
        resourceNumber: '',
        invoiceReference: '',
        otherInfo: '',
        internalNotes: '',
        jiraLink: '',
    })

    const onHide = () => {
        setPartnershipSignup({
            seminarTheme: null,
            plannedSeminarAt: null,
            educationMeetingAt: null,
            informationMeetingAt: null,
            debriefMeetingAt: null,
            contactPersonRole: '',
            contactPersonName: '',
            contactPersonEmail: '',
            contactPersonPhone: '',
            signupOtherInfo: '',
            description: '',
            signupJiraLink: '',

            name: '',
            orgNumber: 0,
            emailForInvoiceReceipt: '',
            resourceNumber: '',
            invoiceReference: '',
            otherInfo: '',
            internalNotes: '',
            jiraLink: '',
        })
        setSpinnerWhileSaving(false)
        setDisableWhileSaving(false)
        onClose()
    }

    return (
        <Modal
            title={'Påmelding til kvalitetsseminar'}
            onClose={onHide}
            onSave={onSave}
            spinnerWhileSaving={spinnerWhileSaving}
            disableWhileSaving={disableWhileSaving}>
            <PartnershipSignupComponent
                partnershipSignup={partnershipSignup}
                setPartnershipSignup={setPartnershipSignup}
                seminarTemplates={seminarTemplates}
            />
        </Modal>
    )
}
