import {useQuery} from 'react-query'

type Results = {
    available: boolean
    valid: boolean
    ingress: boolean
    nameserver: string
    error: string
    forwarded: boolean
    hasSsl: boolean
}

const useDnsService = (domain: string, office?: string) => {
    const {data, refetch, isLoading} = useQuery<Results>(`/api/office_nefles/validate_domain/${domain}?office=${office ?? ''}`, {
        enabled: Boolean(domain && domain.length > 2),
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
    })
    const {available, valid, ingress, nameserver, error, forwarded, hasSsl} = data || {}

    return {available, valid, ingress, nameserver, error, forwarded, hasSsl, loading: isLoading, testNow: refetch}
}
export default useDnsService
