import React, {useEffect, useState} from 'react'
import {useOutletContext} from 'react-router-dom'
import {FormControlLabel, TextField, RadioGroup, Radio, IconButton, ClickAwayListener} from '@mui/material'
import Box from '@mui/system/Box'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {useSkilMutation} from '../../../Utilities/QueryClient'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import {SubmitHandler, useForm} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import _ from 'lodash'
import SubmitBar from '../Components/SubmitBar'
import OfficeSkeleton from '../Skeletons/OfficeSkeleton'
import InputAdornment from '@mui/material/InputAdornment'
import {styled} from '@mui/material/styles'

const StyledTextField = styled(TextField)`
    margin-top: 10px;
`

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
const domainRegex = /^(?!www\.)[A-Za-z0-9-\ÆæØøÅå_]+(\.[A-Za-z0-9-\ÆæØøÅå_]+)*\.[A-Za-z]{2,}$/
const validationSchema = z.object({
    domain: z
        .string()
        .trim()
        .nonempty('Kan ikke være blank')
        .regex(domainRegex, 'Ugyldig domene')
        .refine(domain => domain.length <= 253, 'Domene må være under 254 tegn')
        .refine(domain => {
            // Split domain into parts and check each part's length
            const parts = domain.split('.')
            return parts.every(part => part.length >= 1 && part.length <= 63)
        }, 'Hver del av domenet må være mellom 1 og 63 tegn')
        .refine(domain => !domain.startsWith('-') && !domain.endsWith('-'), 'Domenet kan ikke starte eller slutte med en bindestrek'),
    siteTags: z.object({
        title: z.string().trim().nonempty('Kan ikke være blank'),
        phone: z.string().min(8, 'Ugyldig nummer').regex(phoneRegex, 'Ugyldig nummer'),
        location: z.object({
            address: z.string().trim().nonempty('Kan ikke være blank'),
            postnumber: z
                .string()
                .transform(number => parseInt(number))
                .pipe(z.number().min(1000).max(9999))
                .transform(year => year.toString()),
            place: z.string().trim().nonempty('Kan ikke være blank'),
        }),
    }),
})

type ValidationSchema = z.infer<typeof validationSchema>

const Office = () => {
    const {nefle, setProgress, isGenerated, next}: any = useOutletContext()
    const nefleSetupMutation = useSkilMutation<'patchOfficeNefleItem'>('PATCH', `/api/office_nefles/${nefle.id}`)
    const [domainGroup, setDomainGroup] = useState('own')
    const [openTooltip, setOpenTooltipTooltip] = useState(false)

    const handleTooltipToggle = () => {
        setOpenTooltipTooltip(!openTooltip)
    }

    const handleTooltipClose = () => {
        setOpenTooltipTooltip(false)
    }

    const {
        reset,
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: {errors, isDirty, isSubmitting},
    } = useForm<ValidationSchema>({
        resolver: zodResolver(validationSchema),
    })

    useEffect(() => {
        reset({
            ...nefle.siteConfig,
        })
    }, [nefle])

    useEffect(() => {
        setDomainGroup(getValues('domain').endsWith('.nefle.no') ? 'nefle' : 'own')
    }, [getValues('domain')])

    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        let siteConfig = nefle.siteConfig
        if (!siteConfig.progress) siteConfig.progress = {}

        siteConfig.progress['/legekontoret'] = true

        const merged = _.merge(siteConfig, data)

        try {
            await nefleSetupMutation.mutateAsync({
                // @ts-expect-error
                siteConfig: {...merged},
            })
        } catch (e) {
            handleErrorWithToast(e)
        } finally {
            reset({...merged})
            setProgress(merged.progress)
        }
    }

    const siteTags = getValues()

    if (Object.keys(siteTags).length <= 0) return <OfficeSkeleton />

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{backgroundColor: 'white', borderRadius: '0.25em', padding: {xs: '1em', sm: '2em'}}}>
                <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: {xs: '1em', sm: '2em'}}}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-around'}
                        alignItems={'stretch'}
                        flexDirection={'column'}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <StyledTextField
                            disabled={isGenerated}
                            type={'text'}
                            autoComplete={'office'}
                            placeholder='Ditt legekontor'
                            label={'Ditt legekontor'}
                            error={!!errors?.siteTags?.title}
                            helperText={errors?.siteTags?.title?.message ?? ''}
                            {...register('siteTags.title')}
                        />
                        <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                            <RadioGroup
                                aria-labelledby='domain'
                                value={domainGroup}
                                name='radio-nefle-domain'
                                row
                                onChange={event => {
                                    if (!isGenerated) {
                                        setDomainGroup(event.target.value)
                                    }
                                }}
                            >
                                <FormControlLabel
                                    disabled={isGenerated}
                                    value='own'
                                    tabIndex={0}
                                    control={<Radio tabIndex={-1} />}
                                    label='Eget'
                                    onKeyPress={e => {
                                        if (e.key === 'Enter' || e.key === 'Space') {
                                            if (!isGenerated) {
                                                setValue('domain', getValues('domain').replace('.nefle.no', ''), {shouldDirty: true})
                                            }
                                        }
                                    }}
                                    onClick={() => {
                                        if (!isGenerated) {
                                            setValue('domain', getValues('domain').replace('.nefle.no', ''), {shouldDirty: true})
                                        }
                                    }}
                                    sx={{
                                        paddingRight: '10px',
                                        '&:focus-visible': {
                                            outline: '2px solid black!important',
                                            borderRadius: '2px',
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    disabled={isGenerated}
                                    value='nefle'
                                    tabIndex={0}
                                    control={<Radio tabIndex={-1} />}
                                    label='Subdomene av Nefle.no'
                                    onKeyPress={e => {
                                        if (e.key === 'Enter' || e.key === 'Space') {
                                            if (!isGenerated && !getValues('domain')?.endsWith('.nefle.no')) {
                                                setValue('domain', getValues('domain') + '.nefle.no', {shouldDirty: true})
                                            }
                                        }
                                    }}
                                    onClick={() => {
                                        if (!isGenerated && !getValues('domain')?.endsWith('.nefle.no')) {
                                            setValue('domain', getValues('domain') + '.nefle.no', {shouldDirty: true})
                                        }
                                    }}
                                    sx={{
                                        paddingRight: '10px',
                                        '&:focus-visible': {
                                            outline: '2px solid black!important',
                                            borderRadius: '2px',
                                        },
                                    }}
                                />
                            </RadioGroup>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={openTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={`Eget domene for nettside kjøper du f.eks hos domeneforhandleren www.domeneshop.no og koster vanligvis under kr 150 i året.
                                    Ved kjøp at eget domene for nettside kan det også være lurt å kjøpe en e-postadresse pakke slik at e-postene får likt domene som nettsiden.
                                    Ved bruk av eget domene for nettside må du selv sørge for å sette opp DNS innstillinger for domenet hos din domeneforhandler. 
                                    Du vil få instruksjoner for dette i siste steg av oppsettet av nettsiden.
                                    \n
                                    Subdomene av Nefle.no betyr at du kan få et gratis subdomene av oss, f.eks legekontoret.nefle.no. Vi kan ikke levere e-postadresser. 
                                    Dette valget krever ingen oppsett av DNS innstillinger.`
                                        .split('\n')
                                        .map((line, index, array) => (
                                            <span key={index}>
                                                {line}
                                                {index < array.length - 1 && <br />}
                                            </span>
                                        ))}
                                >
                                    <IconButton onClick={handleTooltipToggle}>
                                        <InfoIcon style={{fill: '#11ade0'}} />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        </Box>
                        <StyledTextField
                            disabled={isGenerated}
                            type={'text'}
                            placeholder='Domene | Nettsideadresse'
                            label={'Domene | Nettsideadresse'}
                            error={!!errors?.domain?.type}
                            helperText={errors?.domain?.message ?? ''}
                            {...register('domain')}
                            onChange={event => {
                                setDomainGroup(event.target.value.endsWith('.nefle.no') ? 'nefle' : 'own')
                                setValue('domain', event.target.value, {shouldDirty: true})
                            }}
                        />
                        <StyledTextField
                            disabled={isGenerated}
                            type={'phone'}
                            placeholder='Telefonnummer'
                            label={'Telefonnummer'}
                            error={!!errors?.siteTags?.phone?.type}
                            helperText={errors?.siteTags?.phone?.message ?? ''}
                            {...register('siteTags.phone')}
                        />
                        <StyledTextField
                            disabled={isGenerated}
                            type={'text'}
                            placeholder='Gateadresse'
                            label={'Gateadresse'}
                            error={!!errors?.siteTags?.location?.address?.type}
                            helperText={errors?.siteTags?.location?.address?.message ?? ''}
                            {...register('siteTags.location.address')}
                        />
                        <Box display={'flex'} justifyContent={'space-around'} alignItems={'stretch'} flexDirection={'row'}>
                            <StyledTextField
                                disabled={isGenerated}
                                placeholder='Postnummer'
                                label={'Postnummer'}
                                error={!!errors?.siteTags?.location?.postnumber?.type}
                                helperText={errors?.siteTags?.location?.postnumber?.message ? 'Ugyldig postnummer' : ''}
                                {...register('siteTags.location.postnumber')}
                                sx={{
                                    flexGrow: '0',
                                    marginRight: '10px',
                                }}
                            />
                            <StyledTextField
                                disabled={isGenerated}
                                type={'text'}
                                placeholder='Sted'
                                label={'Sted'}
                                error={!!errors?.siteTags?.location?.place?.type}
                                helperText={errors?.siteTags?.location?.place?.message ?? ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start' sx={{cursor: 'not-allowed', pointerEvents: 'none'}} tabIndex={-1}>
                                            <IconButton sx={{p: '10px'}} aria-label='marker' tabIndex={-1}>
                                                <i className='fa fa-map-marker-alt' tabIndex={-1}></i>{' '}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register('siteTags.location.place')}
                                sx={{
                                    flexGrow: '1',
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/*
                    One for every page, ties to context
                */}
            <SubmitBar
                shouldSpin={isSubmitting}
                isDirty={isDirty}
                onDiscard={() => {
                    reset()
                    setDomainGroup(getValues('domain')?.endsWith('.nefle.no') ? 'nefle' : 'own')
                }}
                isCompleted={nefle?.siteConfig?.progress && nefle.siteConfig?.progress['/legekontoret']}
                next={() => {
                    next()
                }}
                hasError={Object.keys(errors).length > 0}
            />
        </form>
    )
}

export default Office
