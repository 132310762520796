import {Card, CardContent, CardHeader, Typography} from '@mui/material'
import LinearProgressWithPercentLabel from '../../Components/LinearProgressWithPercentLabel'
import React from 'react'

const GoalCardAllCoursesCompletions = ({
    title,
    header,
    description,
    description1Type0 = '',
    percentage1Type0 = null,
    description2Type0 = '',
    percentage2Type0 = null,
    description3Type0 = '',
    percentage3Type0 = null,
    description4Type0 = '',
    percentage4Type0 = null,
    description5Type0 = '',
    percentage5Type0 = null,
    description6Type0 = '',
    percentage6Type0 = null,
    description7Type0 = '',
    percentage7Type0 = null,

    description1Type1 = '',
    percentage1Type1 = null,
    description2Type1 = '',
    percentage2Type1 = null,
    description3Type1 = '',
    percentage3Type1 = null,
    description4Type1 = '',
    percentage4Type1 = null,
    description5Type1 = '',
    percentage5Type1 = null,
    description6Type1 = '',
    percentage6Type1 = null,
    description7Type1 = '',
    percentage7Type1 = null,

    description1Type2 = '',
    percentage1Type2 = null,
    description2Type2 = '',
    percentage2Type2 = null,
    description3Type2 = '',
    percentage3Type2 = null,
    description4Type2 = '',
    percentage4Type2 = null,
    description5Type2 = '',
    percentage5Type2 = null,
    description6Type2 = '',
    percentage6Type2 = null,
    description7Type2 = '',
    percentage7Type2 = null,
}) => {
    return (
        <Card elevation={3}>
            <CardHeader
                titleTypographyProps={{variant: 'h3'}}
                title={title}
                subheaderTypographyProps={{variant: 'body1'}}
                subheader={header}
            />
            {description1Type0 && description3Type0 && (
                <CardContent>
                    <Typography variant='body2'>{description1Type0}</Typography>
                    {percentage1Type0 && <LinearProgressWithPercentLabel percentage={percentage1Type0} cardSx={{mt: 2}} />}
                    <Typography variant='body2'>{description3Type0} stk</Typography>
                </CardContent>
            )}
            {description2Type0 && percentage2Type0 && description4Type0 && description5Type0 && (
                <CardContent>
                    <Typography variant='body2'>{description2Type0}</Typography>
                    {percentage1Type0 && <LinearProgressWithPercentLabel percentage={percentage2Type0} cardSx={{mt: 2}} />}
                    <Typography variant='body2'>{description4Type0} stk</Typography>
                    <Typography variant='body2'>{description5Type0} stk</Typography>
                </CardContent>
            )}
            {description6Type0 && (
                <CardContent>
                    <Typography variant='body2'>{description6Type0} stk</Typography>
                </CardContent>
            )}
            {description7Type0 && (
                <CardContent>
                    <Typography variant='body2'>{description7Type0} stk</Typography>
                </CardContent>
            )}

            {description1Type1 && description3Type1 && (
                <CardContent>
                    <Typography variant='body2'>{description1Type1}</Typography>
                    {percentage1Type1 && <LinearProgressWithPercentLabel percentage={percentage1Type1} cardSx={{mt: 2}} />}
                    <Typography variant='body2'>{description3Type1} stk</Typography>
                </CardContent>
            )}
            {description2Type1 && percentage2Type1 && description4Type1 && description5Type1 && (
                <CardContent>
                    <Typography variant='body2'>{description2Type1}</Typography>
                    {percentage1Type1 && <LinearProgressWithPercentLabel percentage={percentage2Type1} cardSx={{mt: 2}} />}
                    <Typography variant='body2'>{description4Type1} stk</Typography>
                    <Typography variant='body2'>{description5Type1} stk</Typography>
                </CardContent>
            )}
            {description6Type1 && (
                <CardContent>
                    <Typography variant='body2'>{description6Type1} stk</Typography>
                </CardContent>
            )}
            {description7Type1 && (
                <CardContent>
                    <Typography variant='body2'>{description7Type1} stk</Typography>
                </CardContent>
            )}

            {description1Type2 && description3Type2 && (
                <CardContent>
                    <Typography variant='body2'>{description1Type2}</Typography>
                    {percentage1Type2 && <LinearProgressWithPercentLabel percentage={percentage1Type2} cardSx={{mt: 2}} />}
                    <Typography variant='body2'>{description3Type2} stk</Typography>
                </CardContent>
            )}
            {description2Type2 && percentage2Type2 && description4Type2 && description5Type2 && (
                <CardContent>
                    <Typography variant='body2'>{description2Type2}</Typography>
                    {percentage1Type2 && <LinearProgressWithPercentLabel percentage={percentage2Type2} cardSx={{mt: 2}} />}
                    <Typography variant='body2'>{description4Type2} stk</Typography>
                    <Typography variant='body2'>{description5Type2} stk</Typography>
                </CardContent>
            )}
            {description6Type2 && (
                <CardContent>
                    <Typography variant='body2'>{description6Type2} stk</Typography>
                </CardContent>
            )}
            {description7Type2 && (
                <CardContent>
                    <Typography variant='body2'>{description7Type2} stk</Typography>
                </CardContent>
            )}
        </Card>
    )
}

export default GoalCardAllCoursesCompletions
