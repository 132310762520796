import * as React from 'react'
import {Link} from 'react-router-dom'
import {computeDisabled, useClickHandler, useConfirmClickHandler} from './utils'

type Props = {
    onClick?: Function
    to?: string
    href?: string
    target?: '_blank'
    rel?: 'noopener noreferrer'
    variant?: 'default' | 'link' | 'text' | 'primary' | 'secondary' | 'warning' | 'dark' | 'light' | 'danger' | 'outline-primary'
    hidden?: boolean
    className?: string
    size?: string
    inline?: boolean
    disabled?: boolean
    confirm?: React.ReactNode
    block?: boolean
    title?: string
    children?: React.ReactNode
    style?: React.CSSProperties
}

const Button = React.forwardRef<any, Props>(
    (
        {
            variant = 'default',
            onClick,
            href,
            to,
            hidden,
            className = '',
            size = '',
            inline = false,
            disabled = false,
            confirm = undefined,
            block = false,
            title = '',
            children,
            ...props
        },
        ref
    ) => {
        const clickHandler = useClickHandler(onClick)
        const confirmClick = useConfirmClickHandler(clickHandler.handleClick, children, confirm)
        const isDisabled = computeDisabled(onClick, href, to, disabled, clickHandler.working)

        /*************/
        /*  styling  */
        /*************/

        const sizeClass = size ? 'btn-' + size : ''
        const blockClass = block ? 'btn-block' : ''

        let realVariant = `${className} ${sizeClass} ${blockClass}`
        if (variant !== 'text') realVariant = `btn btn-${variant} ${realVariant}`

        if (inline) realVariant += ' btn-inline'

        /*************************/
        /*  returned components  */
        /*************************/

        if (hidden) return null

        if (to) {
            return (
                <Link
                    ref={ref}
                    to={to}
                    onClick={confirmClick.handleClick}
                    className={realVariant + (isDisabled ? ' disabled' : '')}
                    title={title}
                    {...props}
                >
                    {confirmClick.children}
                    {clickHandler.working ? <i className={'fa fa-spin fa-spinner'} /> : ''}
                </Link>
            )
        }

        if (href)
            return (
                <a
                    ref={ref}
                    aria-disabled={isDisabled}
                    href={href}
                    onClick={confirmClick.handleClick}
                    className={realVariant}
                    title={title}
                    {...props}
                >
                    {confirmClick.children}
                    {clickHandler.working ? <i className={'fa fa-spin fa-spinner'} /> : ''}
                </a>
            )

        return (
            <button ref={ref} disabled={isDisabled} onClick={confirmClick.handleClick} className={realVariant} title={title} {...props}>
                {confirmClick.children}
                {clickHandler.working ? <i className={'fa fa-spin fa-spinner'} /> : ''}
            </button>
        )
    }
)
export default Button
