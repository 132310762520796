import * as React from 'react'
import {useState} from 'react'
import {useParams} from 'react-router'
import {Page} from '../../../Components/Page'
import {Box} from '../../../Components/Panel'
import {useLocation, useNavigate} from 'react-router-dom'
import {breadcrumbsMap} from '../../utils/breadcrumbsMap'
import {BreadcrumbsItem} from '../../../Components/Breadcrumbs'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import {SkilQueryResponseType, useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {DoctorProfilePanel} from './DoctorProfilePanel'
import {DoctorOfficePanel} from './DoctorOfficePanel'
import {DoctorStudiesPanel} from './DoctorStudiesPanel'
import {Button, ButtonProps} from '../../../Components/Button/MuiButton'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ScrollToTop from '../../../Utilities/ScrollToTop'
import {ROUTES} from '../../constants/routes'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {ActionButtonGroup} from '../../components/DetailsViewActionButtonGroup'
import EmailIcon from '@mui/icons-material/Email'
import {DeleteDoctorDialog} from './DeleteDoctorDialog'
import {SendMessageDialog} from '../../components/SendMessageDialog'
import {DoctorMessagesDialog} from './DoctorMessagesDialog'
import {DoctorChangelogDialog} from '../../components/Changelogs/DoctorChangelogDialog'

export type DoctorType = SkilQueryResponseType<'getPraksisnettDoctorItem'>

export const Doctor = () => {
    const location = useLocation()
    const {doctorId} = useParams()
    const navigate = useNavigate()
    const [showSendText, setShowText] = useState(false)
    const [showSendEmail, setShowEmail] = useState(false)
    const [showMessages, setShowMessages] = useState(false)
    const [showChangelog, setShowChangelog] = useState(false)
    const [openDeleteDoctorDialog, setOpenDeleteDoctorDialog] = useState(false)

    const {data: doctor, isLoading, isError, refetch} = useSkilQuery<'getPraksisnettDoctorItem'>(`/api/praksisnett/doctors/${doctorId}`)

    const removeDoctor = useSkilMutation<`deletePraksisnettDoctor`>('delete', `/api/praksisnett/doctors/${doctor?.id}`, [
        `/api/praksisnett/doctors/${doctor?.id}`,
        `/api/praksisnett/doctors`,
    ])

    const handleRemoveDoctor = async () => {
        try {
            await removeDoctor.mutateAsync({})
            toast('Legen er fjernet', {type: 'success'})
            navigate(ROUTES.DOCTORS)

            // Using the `void` operator to silence eslint, since the actual error is handled by react-query elsewhere
            refetch().catch(() => {})
        } catch (e) {
            handleErrorWithToast(e)
        }
    }

    const doctorActions: ButtonProps[] = [
        {
            children: 'Send SMS',
            onClick: () => setShowText(true),
            startIcon: <PhoneIphoneOutlinedIcon />,
        },
        {
            children: 'Send e-post',
            onClick: () => setShowEmail(true),
            startIcon: <EmailOutlinedIcon />,
        },
        {
            children: 'Vis meldinger',
            onClick: () => setShowMessages(true),
            startIcon: <EmailIcon />,
        },
        {
            children: 'Vis endringslogg',
            onClick: () => setShowChangelog(true),
            startIcon: <ListAltOutlinedIcon />,
        },
    ]

    /***************
     * breadcrumbs *
     ***************/
    const breadcrumbs: BreadcrumbsItem[] = [
        breadcrumbsMap.home,
        breadcrumbsMap.offices,
        {title: doctor?.office?.name ?? `Legekontor`, to: ROUTES.OFFICES + '/' + doctor?.office?.id},
        {title: doctor?.name ?? `Lege ${doctorId}`, to: location.pathname},
    ]

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs}>
            <ScrollToTop />
            {isError && <Box>Noe gikk galt</Box>}
            {isLoading || !doctor ? (
                <CircularProgress />
            ) : (
                <>
                    <ActionButtonGroup>
                        {doctorActions.map((props, index) => (
                            <Button {...props} key={index} />
                        ))}
                    </ActionButtonGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DoctorProfilePanel doctor={doctor} refetch={refetch} />
                        </Grid>
                        <Grid item xs={12}>
                            <DoctorOfficePanel doctor={doctor} refetch={refetch} />
                        </Grid>
                        <Grid item xs={12}>
                            <DoctorStudiesPanel doctor={doctor} />
                        </Grid>
                    </Grid>

                    <DoctorChangelogDialog doctorId={doctorId!} setOpen={setShowChangelog} isOpen={showChangelog} />

                    <SendMessageDialog
                        isOpen={showSendEmail}
                        submitLabel={'Send epost'}
                        onClose={() => setShowEmail(false)}
                        doctorIris={[doctor['@id'] as string]}
                        isEmail
                    />
                    <SendMessageDialog
                        isOpen={showSendText}
                        submitLabel={'Send sms'}
                        onClose={() => setShowText(false)}
                        doctorIris={[doctor['@id'] as string]}
                        isText
                    />
                    <DoctorMessagesDialog setOpen={setShowMessages} isOpen={showMessages} doctor={doctor} />

                    <ActionButtonGroup align='right'>
                        <Button onClick={() => setOpenDeleteDoctorDialog(true)} startIcon={<DeleteIcon />}>
                            Fjern lege fra PraksisNett
                        </Button>
                    </ActionButtonGroup>
                    <DeleteDoctorDialog
                        doctorName={doctor?.name}
                        setOpen={setOpenDeleteDoctorDialog}
                        isOpen={openDeleteDoctorDialog}
                        deleteHandler={handleRemoveDoctor}
                    />
                </>
            )}
        </Page>
    )
}
