import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'
import {useQuery} from 'react-query'

const hasAllMeetingsThisYear = (meetings: string[]) => {
    if (meetings.length === 0) return false

    //const thisYear = new Date().getFullYear()
    const now = new Date()
    return meetings.every(meeting => new Date(meeting).getFullYear() === 2024 && new Date(meeting) < now)
}

type SearchItem = {
    text: string
    url: string
    icon: string
    id: number
    '@id': string
    eid: string
    type: string
}

type ApiResponse = {
    results: SearchItem[]
}

const QualitySeminarGoal24 = () => {
    const {data} = useSkilQuery<'getPartnershipsSeminarsCollection'>('/api/partnerships/seminars')
    const seminars = data?.['hydra:member'] ?? []

    const seminarsThisYear = new Set(
        (seminars ?? []).filter(seminar => {
            let meetings = seminar?.meetings ?? []
            // we do not want to count the test partnership
            if (seminar.partnership === '/api/partnerships/21') {
                meetings = []
            }
            return hasAllMeetingsThisYear(meetings)
        })
    )

    return (
        <GoalCard
            title='Kvalitetsseminar'
            subheader='Mål: minst 15 gjennomførte kvalitetsseminar i 2024.'
            description={`${seminarsThisYear.size} kvalitetsseminar hittil i 2024.`}
            percentage={Math.ceil((seminarsThisYear.size * 100) / 15)}
        />
    )
}

export default QualitySeminarGoal24
