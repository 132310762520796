import * as React from 'react'
import Breadcrumbs, {BreadcrumbsItem} from './Breadcrumbs'
import Logo from './skil_topplogo.svg'
import styled from 'styled-components'
import {useEffect} from 'react'
import {Breakpoint, Container, Grid} from '@mui/material'

const StyledLogo = styled.img`
    width: 5em;
`
const StyledFooter = styled(Grid)`
    display: grid;
    justify-content: center;
    align-items: baseline;
`

interface PageProps {
    backLink?: string
    breadcrumbs?: BreadcrumbsItem[]
    children?: React.ReactNode
    className?: string
    variant?: Breakpoint
    showMenuAndHeader?: boolean
}

export default function PurePage({
    children,
    backLink = undefined,
    variant = 'lg',
    className = '',
    breadcrumbs = undefined,
    showMenuAndHeader = false,
    ...props
}: PageProps) {
    const title = breadcrumbs ? breadcrumbs[breadcrumbs.length - 1]?.title ?? '' : ''
    const currentYear = new Date().getFullYear()

    useEffect(() => {
        document.title = title + ' | SKIL ePortal'
    }, [title])

    // TODO: If showMenuAndHeader is true, render the menu and header

    return (
        <Grid sx={{flexGrow: 1}} style={{minHeight: '100vh'}} container alignItems={'center'} spacing={0} direction={'column'}>
            <StyledLogo src={Logo} title='SKIL Logo' id='skil-logo' />
            <Container maxWidth={variant} sx={{flexGrow: 1}}>
                {breadcrumbs ? <Breadcrumbs pages={breadcrumbs} /> : null}
                {children}
            </Container>
            <StyledFooter item>
                <a href='https://www.skilnet.no' className='btn btn-link'>
                    www.skilnet.no
                </a>
                <strong style={{fontSize: '14px'}}>Copyright &copy; 2015 - {currentYear}</strong>
            </StyledFooter>
        </Grid>
    )
}
