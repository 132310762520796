import React, {useState, useEffect} from 'react'
import useEntity from '../Hooks/useEntity'
import useSendText from './useSendText'
import Modal from '../Components/Modal'
import Button from '../Components/Button/Button'
import {format} from 'date-fns'
import TextareaField from '../Components/Fields/TextareaField'

const DeleteConsultationModal = ({iri, onClose}) => {
    const consultation = useEntity<'getConsultationItem'>(iri)
    const [content, setContent] = useState<string | null>(null)
    const sendText = useSendText(consultation)

    useEffect(() => {
        if (consultation.id && content === null) {
            const meetingAt = new Date(consultation.meetingAt)
            const dato = format(meetingAt, 'dd.MM')
            const time = format(meetingAt, 'HH:mm')
            const content = `Din videokonsultasjon ${dato} kl ${time} er kansellert`

            setContent(content)
        }
    }, [consultation, content])

    const onDeleteWithMessage = async () => {
        await sendText(content)
        consultation.remove()
        onClose()
    }

    const onDeleteWithoutMessage = () => {
        consultation.remove()
        onClose()
    }

    return (
        <Modal
            title={`Slett konsultasjon til ${consultation.name}`}
            onClose={onClose}
            onSave={onDeleteWithMessage}
            saveText={'Slett og send melding'}
            footerComponents={<Button onClick={onDeleteWithoutMessage}>Slett uten melding</Button>}
        >
            <form>
                <TextareaField id='content' label='Innhold' rows={7} required value={content ? content : ''} onChange={setContent} />
            </form>
        </Modal>
    )
}
export default DeleteConsultationModal
