import firebase from 'firebase/app'
import 'firebase/messaging'
import {toast} from 'react-toastify'
import Messaging = firebase.messaging.Messaging
const FIREBASE_CM_PUBLIC_VAPID_KEY = 'BBm7xUOSk3SmcEnP0pdekaSaN4zlWksyUo6lYAYnkgcWLjSI2op__EW4sZaiE-Gop4qxgnkqVmssxA3knwbwhNM'
const SERVICE_WORKER_REFRESH = 10000 // 3600000
const initializedApp = firebase.initializeApp({
    apiKey: 'AIzaSyBsI6sVrmPDLdaxkPRsdDGJrCPKWSbIjtI',
    authDomain: 'eportal-d265a.firebaseapp.com',
    databaseURL: 'https://eportal-d265a.firebaseio.com',
    projectId: 'eportal-d265a',
    storageBucket: 'eportal-d265a.appspot.com',
    messagingSenderId: '959056724334',
    appId: '1:959056724334:web:3ec41aa56ee709a935017f',
    measurementId: 'G-QMJ14V93XG',
})
export default initializedApp

let tmpMessaging: Messaging | null = null
if (firebase.messaging.isSupported()) {
    tmpMessaging = initializedApp.messaging()
    tmpMessaging.usePublicVapidKey(FIREBASE_CM_PUBLIC_VAPID_KEY)

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register(new URL('/firebase-messaging-sw.js', window.location.href), {scope: '/firebase-cloud-messaging-push-scope'})
            .then(registration => {
                const lastUpdatedAt = Number(window.localStorage.getItem('firebase_sw_installed_at'))
                if (lastUpdatedAt && Date.now() - lastUpdatedAt > SERVICE_WORKER_REFRESH) {
                    //Check for updates every hour
                    registration.update()
                }

                window.localStorage.setItem('firebase_sw_installed_at', Date.now().toString())
                tmpMessaging?.useServiceWorker(registration)
            })
            .catch(err => console.log('Service Worker Error', err))
    }

    // Callback fired if Instance ID token is updated.
    tmpMessaging.onTokenRefresh(() => {
        tmpMessaging
            ?.getToken()
            .then(newToken => {
                const previousToken = window.localStorage.getItem('last_notification_token') || ''
                window.localStorage.setItem('last_notification_token', newToken)
                fetch('/api/push_notification_targets', {
                    method: 'POST',
                    body: JSON.stringify({newToken, previousToken, bindingType: 'fcm'}),
                    credentials: 'same-origin',
                    headers: {'Content-Type': 'application/ld+json'},
                })
            })
            .catch(err => {
                console.log('Unable to retrieve refreshed token ', err)
            })
    })

    tmpMessaging.onMessage(payload => {
        const body = payload.data.body ?? payload.data.twi_body ?? ''
        const icon = payload.data.icon ?? 'favicon_256.png'
        const title = payload.data.title ?? payload.data.tw_body ?? 'SKIL ePortal'

        const notificationOptions = {body, icon, data: payload.data}
        const notification = new Notification(title, notificationOptions)

        /*
        {
              "data": {
                "twi_message_id": "RU91e6c2ffe6f4696a6b26cdc9dd4b60a8",
                "twi_title": "Notification title",
                "icon": "https://eportal.skilnet.no/nefle_video_logo512.png",
                "twi_body": "Notification body",
                "primary_href": "https://eportal.skilnet.no",
                "primary_title": "Åpne"
              },
              "from": "959056724334",
              "priority": "high",
              "collapse_key": "do_not_collapse"
            }
         */

        notification.addEventListener('click', event => {
            // @ts-expect-error
            if (event.action) {
                // @ts-expect-error
                window.open(event.action, '_blank')
            } else {
                window.open(payload.data.primary_href, '_blank')
            }
        })
        toast['info'](body, {autoClose: false, onClose: () => window.open(payload.data.primary_href, '_blank')})
    })
} else {
    console.warn('Firebase Messaging is not supported')
}

export const messaging = tmpMessaging
