import * as React from 'react'
import {useState} from 'react'
import {Page} from '../../../Components/Page'
import {Box} from '../../../Components/Panel'
import {useLocation} from 'react-router-dom'
import {breadcrumbsMap} from '../../utils/breadcrumbsMap'
import {useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {useNavigate, useParams} from 'react-router'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import {components} from '../../../Generated/eportal'
import {StudyDoctorsPanel} from './StudyDoctorsPanel'
import {StudyAttachmentsPanel} from './StudyAttachmentsPanel'
import {StudyPublicationsPanel} from './StudyPublicationsPanel'
import {StudyStatusPanel} from './StudyStatusPanel'
import {StudyDetailsPanel} from './StudyDetailsPanel'
import ScrollToTop from '../../../Utilities/ScrollToTop'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, ButtonProps} from '../../../Components/Button/MuiButton'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import {toast} from 'react-toastify'
import {ROUTES} from '../../constants/routes'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {ActionButtonGroup} from '../../components/DetailsViewActionButtonGroup'
import {DeleteStudyDialog} from './DeleteStudyDialog'
import {StudyChangelogDialog} from '../../components/Changelogs/StudyChangelogDialog'

export type StudyType =
    components['schemas']['Praksisnett.Study.jsonld-PraksisNettStudy.details_PraksisNettStudyAttachment.details_File.view']

export interface StudyProps {
    study: StudyType
    refetch: Function
}
export const Study = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {studyId} = useParams()
    const [openDeleteStudyDialog, setOpenDeleteStudyDialog] = useState(false)
    const [showChangelog, setShowChangelog] = useState(false)
    const studyActions: ButtonProps[] = [
        {
            children: 'Vis endringslogg',
            onClick: () => setShowChangelog(true),
            startIcon: <ListAltOutlinedIcon />,
        },
    ]

    const {data: study, isLoading, isError, refetch} = useSkilQuery<'getPraksisnettStudyItem'>(`/api/praksisnett/studies/${studyId}`)
    const breadcrumbs = [breadcrumbsMap.home, breadcrumbsMap.studies, {title: study?.name ?? '', to: location.pathname}]

    const deleteStudyMutation = useSkilMutation<'deletePraksisnettStudy'>('delete', `/api/praksisnett/studies/${study?.id}`, [
        `/api/praksisnett/studies/${study?.id}`,
        `/api/praksisnett/studies`,
    ])
    const handleDeleteStudy = async () => {
        try {
            await deleteStudyMutation.mutateAsync({})
            toast('Studie slettet', {
                type: 'success',
            })
            return navigate(ROUTES.STUDIES)
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    return (
        <Page variant={'full'} breadcrumbs={breadcrumbs}>
            <ScrollToTop />
            {isError ? (
                <Box>Noe gikk galt</Box>
            ) : isLoading || !study ? (
                <CircularProgress />
            ) : (
                <>
                    <ActionButtonGroup>
                        {studyActions.map((props, index) => (
                            <Button {...props} key={index} />
                        ))}
                    </ActionButtonGroup>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={8}>
                            <StudyDetailsPanel study={study} refetch={refetch} />
                        </Grid>
                        <Grid display={`flex`} item xs={12} md={4}>
                            <StudyStatusPanel study={study} refetch={refetch} />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <StudyPublicationsPanel study={study} refetch={refetch} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <StudyAttachmentsPanel study={study} refetch={refetch} />
                            </Grid>
                        </Grid>
                        <Grid className='column-main' item xs={12}>
                            <StudyDoctorsPanel studyId={studyId} />
                        </Grid>
                    </Grid>
                    <ActionButtonGroup align='right'>
                        <Button onClick={() => setOpenDeleteStudyDialog(true)} startIcon={<DeleteIcon />}>
                            Slett studie
                        </Button>
                    </ActionButtonGroup>
                    <StudyChangelogDialog study={study} setOpen={setShowChangelog} isOpen={showChangelog} />
                    <DeleteStudyDialog
                        studyName={study?.name}
                        setOpen={setOpenDeleteStudyDialog}
                        isOpen={openDeleteStudyDialog}
                        deleteHandler={handleDeleteStudy}
                    />
                </>
            )}
        </Page>
    )
}
