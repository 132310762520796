import * as React from 'react'
import {SetState} from '../../../Utilities/TypeHelpers'
import {useSkilQuery} from '../../../Utilities/QueryClient'
import {ChangelogDialog} from './ChangelogDialog'
import {OfficeType} from '../../DetailsViews/Office/Office'

interface OfficeChangelogDialogProps {
    setOpen: SetState<boolean>
    isOpen: boolean
    office: OfficeType
}

export const OfficeChangelogDialog = ({setOpen, office, isOpen = false}: OfficeChangelogDialogProps) => {
    const {data, isLoading} = useSkilQuery<'getPraksisNettOffice-ChangelogCollection'>(`/api/praksisnett/offices/${office.id}/changelogs`, {
        pagination: false,
    })
    return <ChangelogDialog setOpen={setOpen} isOpen={isOpen} data={data} isLoading={isLoading} />
}
