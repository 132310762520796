import Dialog from '@mui/material/Dialog'
import {DialogTitle} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import {Button} from '../../Components/Button/MuiButton'
import * as React from 'react'
import {CountyAutocomplete} from '../../Components/CountyAutocomplete'
import {CourseAutocomplete, CourseType} from '../../Components/CourseAutocomplete'
import {toast} from 'react-toastify'

type Props = {
    isOpen: boolean
    handleClose: () => any
    handleSubmit: (newGroup: {
        name: string
        county: string
        district?: string | null
        signupAt: string
        counselor: string | null
        course: string | null
    }) => any
}

export const CreateGroupDialog = ({isOpen, handleClose, handleSubmit}: Props) => {
    const [name, setName] = React.useState<string>('')
    const [county, setCounty] = React.useState<string | null>(null)
    const [district, setDistrict] = React.useState<string | undefined>(undefined)
    const [countiesRaw, setCountiesRaw] = React.useState<any>(undefined)
    const [signupAt, setSignupAt] = React.useState<Date | null>(new Date())
    const [course, setCourse] = React.useState<CourseType | null>(null)

    const countyDistricts = county
        ? countiesRaw
              ?.find(c => c.id === county)
              ?.districts?.map(district => {
                  let iri = district['@id'] as string
                  return {label: district.name, id: iri}
              })
              .sort(function (a, b) {
                  if (a.label < b.label) {
                      return -1
                  }
                  if (a.label > b.label) {
                      return 1
                  }
                  return 0
              })
        : []

    const handleDialogClose = () => {
        setName('')
        setCounty(null)
        setSignupAt(new Date())
        setCourse(null)
        return handleClose()
    }

    const handleDialogSubmit = async () => {
        // if the county has districts, we need to make sure one is selected
        if (countyDistricts?.length > 0 && !district) {
            toast('Du må velge en bydel', {type: 'warning'})
            return
        }
        await handleSubmit({
            name,
            county: county!, //enforced by submit-button disabled prop
            district: district,
            signupAt: signupAt!.toDateString(), //enforced by submit-button disabled prop
            counselor: null,
            course: course?.['@id'] ?? null,
        })
        handleDialogClose()
    }

    return (
        <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={handleDialogClose}>
            <DialogTitle>Opprett ny gruppe</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            id='name'
                            label='Navn'
                            fullWidth
                            required
                            value={name}
                            onChange={event => {
                                setName(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CourseAutocomplete required value={course?.['@id'] ?? null} onChange={(e, course) => setCourse(course)} />
                    </Grid>
                    <Grid item xs={12}>
                        <CountyAutocomplete
                            value={county}
                            required
                            onChange={(event, county, iri, district, countiesRaw) => {
                                setCounty(iri)
                                setDistrict(district?.id)
                                setCountiesRaw(countiesRaw)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Box display={'flex'} gap={1} mb={2} padding={1} mx={2} justifyContent={'end'} alignItems={'baseline'}>
                <DialogActions sx={{padding: 0}}>
                    <Button variant='outlined' onClick={handleDialogClose}>
                        Avbryt
                    </Button>
                    <Button
                        disabled={!signupAt || !name || !course || !county || (countyDistricts?.length > 0 && !district)}
                        variant='contained'
                        onClick={handleDialogSubmit}>
                        Opprett gruppe
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
