import * as React from 'react'
import {QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient, UseQueryOptions} from 'react-query'
import {jsonFetch} from '../Components/jsonFetch'
import {operations} from '../Generated/eportal'
import {persistQueryClient} from 'react-query/persistQueryClient-experimental'
import {createWebStoragePersistor} from 'react-query/createWebStoragePersistor-experimental'
import {toast} from 'react-toastify'
import {notEmpty} from './TypeHelpers'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: Infinity,
            staleTime: process.env.NODE_ENV === 'development' ? 1000 * 60 : undefined,
            queryFn: ({queryKey}) => {
                const uri = typeof queryKey[0] === 'string' && queryKey.length === 1 ? queryKey[0] : null
                if (!uri) {
                    throw new Error('URI is missing, or queryFn!')
                }

                const controller = new AbortController()
                const signal = controller.signal

                const promise = jsonFetch(uri, {signal})
                // @ts-expect-error
                promise.cancel = () => {
                    controller.abort()
                }
                return promise
            },
            onError: (err: unknown) => {
                if (err instanceof Error) {
                    toast(err.message, {type: 'error'})
                }
            },
        },
    },
})

let buster = process.env.API_HASH // How to get git commit sha in here?
let persistor = createWebStoragePersistor({storage: window.localStorage, key: 'api_cache', throttleTime: 500})
persistQueryClient({queryClient, persistor, buster})
    .then(() => {})
    .catch(e => console.error(e))

export const QueryProvider = ({children}) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

type JsonContent = {content: {'application/ld+json': object}}
export type SkilQueryResponseTypes = operations & {
    [op in keyof operations]: {
        parameters: {
            query: object
        }
        responses: {'200': JsonContent}
    }
}

type TMutationOperations = operations & {
    [op in keyof operations]: {
        requestBody: JsonContent
        responses: {200: JsonContent} & {201: JsonContent} & {202: JsonContent} & {203: JsonContent} & {204: never}
    }
}

export type SkilQueryResponseType<Top extends keyof SkilQueryResponseTypes, TStatus = '200'> = Readonly<
    // @ts-expect-error
    SkilQueryResponseTypes[Top]['responses'][TStatus]['content']['application/ld+json']
>
type TQueryParameters<Top extends keyof SkilQueryResponseTypes> = SkilQueryResponseTypes[Top]['parameters']['query']
export function useSkilQuery<TOp extends keyof operations>(
    url: string | URL,
    searchParameters?: TQueryParameters<TOp> | URLSearchParams | null | {[key: string]: any},
    options?: UseQueryOptions<SkilQueryResponseType<TOp>>
) {
    const tmp = typeof url === 'string' ? new URL(url, window.location.href) : url
    let searchParams = tmp.searchParams
    if (searchParameters && !(searchParameters instanceof URLSearchParams)) {
        searchParams = new URLSearchParams() // Object.entries(searchParameters)
        Object.entries(searchParameters).forEach(([key, value]) => {
            if (value instanceof Array) {
                value.forEach(val => {
                    searchParams.append(key, val)
                })
            } else if (value instanceof Date) {
                searchParams.append(key, value.toISOString())
            } else {
                searchParams.append(key, value)
            }
        })
    }

    const targetUrl = tmp.origin + tmp.pathname + '?' + searchParams.toString()
    return useQuery<SkilQueryResponseType<TOp>>(targetUrl, options)
}

export type SkilRequestBodyType<Top extends keyof TMutationOperations> =
    TMutationOperations[Top]['requestBody']['content']['application/ld+json']

type Only<T, U> = {
    [P in keyof T]: T[P]
} & {
    [P in keyof U]?: never
}

type Either<T, U> = Only<T, U> | Only<U, T>

type TMutationResponseData<Top extends keyof TMutationOperations> = Either<
    TMutationOperations[Top]['responses'][200]['content']['application/ld+json'],
    Either<
        TMutationOperations[Top]['responses'][201]['content']['application/ld+json'],
        Either<
            TMutationOperations[Top]['responses'][202]['content']['application/ld+json'],
            TMutationOperations[Top]['responses'][203]['content']['application/ld+json']
        >
    >
>

export function useSkilMutation<TOp extends keyof TMutationOperations>(
    method: string,
    url: string,
    invalidateUrls: Array<string | null> = []
) {
    type TVar = SkilRequestBodyType<TOp> & {'@overridePath'?: string}
    type TData = TMutationResponseData<TOp>
    const invalidate = useQueryInvalidate()

    return useMutation<TData, unknown, TVar, unknown>({
        mutationFn: json => {
            const path = json['@overridePath'] ?? url
            delete json['@overridePath']
            return jsonFetch(path, {method, json})
        },
        onSuccess: () => {
            return invalidate(invalidateUrls)
        },
        onError: (err, variables, context) => {
            // @ts-expect-error
            toast(err.message, {type: 'error'})
        },
    })
}

export function useQueryInvalidate() {
    const client = useQueryClient()

    return async (urls: Array<string | null> = []) => {
        const actual: Array<string> = urls.filter(notEmpty)

        // Hack to make sure we refetch queries that matches our url
        const cache = client.getQueryCache()
        const cachedQueries = cache.findAll().filter(item => {
            return actual.some(url => item.queryKey?.includes(url))
        })

        const keys = cachedQueries.map(query => query?.queryKey)

        return Promise.all(
            keys.map(key =>
                client.invalidateQueries(
                    key,
                    {
                        exact: true,
                        refetchActive: true,
                        refetchInactive: false,
                    },
                    {throwOnError: false, cancelRefetch: true}
                )
            )
        )
    }
}
