import * as React from 'react'
import {useState} from 'react'
import {Button} from '../../Components/Button/MuiButton'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import {useSkilMutation} from '../../Utilities/QueryClient'
import Typography from '@mui/material/Typography'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from '../constants/routes'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import {SetState} from '../../Utilities/TypeHelpers'

interface StudyCreateDialogProps {
    refetch: () => Promise<any>
    setOpen: SetState<boolean>
    isOpen: boolean
}

export const StudyCreateDialog = ({refetch, setOpen, isOpen = false}: StudyCreateDialogProps) => {
    const createStudyMutation = useSkilMutation<'createPraksisnettStudy'>('post', '/api/praksisnett/studies', ['/api/praksisnett/studies'])
    const [name, setName] = useState<string>('')
    const [region, setRegion] = useState<string>('')
    const navigate = useNavigate()

    const handleDialogClose = () => {
        setName('')
        setRegion('')
        setOpen(false)
    }

    const handleSubmitStudy = async () => {
        try {
            const study = await createStudyMutation.mutateAsync({name, region})
            toast('Ny studie opprettet', {
                type: 'success',
            })
            handleDialogClose()
            navigate(ROUTES.STUDIES + '/' + study.id)
            await refetch()
        } catch (error) {
            handleErrorWithToast(error)
        }
    }

    return (
        <Dialog maxWidth='sm' open={isOpen} onClose={handleDialogClose}>
            <DialogContent>
                <Typography>Opprett en ny studie</Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField id='name' label='Navn' value={name} fullWidth onChange={e => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id='region-label'>Ansvarlig region</InputLabel>
                            <Select
                                labelId='region-label'
                                label='Ansvarlig region'
                                name='region'
                                value={region}
                                onChange={e => setRegion(e.target.value)}>
                                {['Midt', 'Nord', 'Vest', 'Øst'].map(region => (
                                    <MenuItem key={region} value={region}>
                                        {region}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{paddingX: 3, paddingBottom: 2}}>
                <Button variant='outlined' onClick={handleDialogClose}>
                    Avbryt
                </Button>
                <Button disabled={!region || !name} variant='contained' onClick={handleSubmitStudy}>
                    Opprett studie
                </Button>
            </DialogActions>
        </Dialog>
    )
}
