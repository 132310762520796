import {Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Paper, TextField, Typography, LinearProgress} from '@mui/material'
import Box from '@mui/system/Box'
import CircularProgress from '@mui/material/CircularProgress'
import {Button} from '../../../Components/Button/MuiButton'
import PurePage from '../../../Components/PurePage'
import {BackdropComponent} from '../../../Components/BackdropComponent'
import React from 'react'
import useUser from '../../../Utilities/useUser'
import {useNavigate, useParams} from 'react-router'
import {SkilQueryResponseType, useSkilMutation, useSkilQuery} from '../../../Utilities/QueryClient'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../../Utilities/errorHandlers'
import {CountyAutocomplete} from '../../../Components/CountyAutocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import {Unboxed} from '../../../Utilities/TypeHelpers'
import Divider from '@mui/material/Divider'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z, ZodIssueCode} from 'zod'
import TermsOfServiceDialog from './TermsOfServiceDialog'

type OfficeType = Unboxed<SkilQueryResponseType<'getCountyOfficeCollection'>['hydra:member']>

const validationSchema = z
    .object({
        profile: z
            .object({
                firstname: z.string({required_error: 'Dette feltet er påkrevd'}).min(2, {message: 'Må inneholde minst 2 tegn'}),
                lastname: z.string({required_error: 'Dette feltet er påkrevd'}).min(2, {message: 'Må inneholde minst 2 tegn'}),
                mobile: z.string({required_error: 'Dette feltet er påkrevd'}).min(8, {message: 'Må inneholde minst 8 tall'}),
                email: z.string({required_error: 'Dette feltet er påkrevd'}).email({message: 'Ugyldig e-post adresse'}),
                repeatEmail: z.string({required_error: 'Dette feltet er påkrevd'}).email({message: 'Ugyldig e-post adresse'}),
            })
            .refine(({email, repeatEmail}) => email === repeatEmail, {
                path: ['repeatEmail'],
                message: 'E-postene er ikke like',
            }),

        password: z
            .object({
                password: z.string({required_error: 'Dette feltet er påkrevd'}).min(8, {message: 'Må inneholde minst 8 tegn'}),
                repeatPassword: z.string({required_error: 'Dette feltet er påkrevd'}).min(8, {message: 'Må inneholde minst 8 tegn'}),
            })
            .refine(({password, repeatPassword}) => password === repeatPassword, {
                path: ['repeatPassword'],
                message: 'Passordene er ikke like',
            }),

        role: z.object(
            {
                id: z.number(),
                name: z.string(),
                ['@id']: z.string(),
            },
            {errorMap: issue => ({message: 'Dette feltet er påkrevd'})}
        ),
        county: z.string({required_error: 'Dette feltet er påkrevd'}).startsWith('/api/counties/'),
        district: z.string().startsWith('/api/districts/').optional(),
        office: z
            .object({id: z.number(), name: z.string({required_error: 'Dette feltet er påkrevd'}), ['@id']: z.string()})
            .nullable()
            .optional(),
        newOfficeName: z.string().optional().nullable(),
        tosAccepted: z.literal(true, {errorMap: issue => ({message: ' - Du må godkjenne vilkår for å fortsette'})}),
    })
    .refine(({newOfficeName, office}) => newOfficeName || office?.id, {
        path: ['newOfficeName'],
        message: 'Du må skrive navnet på det nye legekontoret eller velge ett eksisterende legekontor over',
    })
    .superRefine(({county, district}, ctx) => {
        const requiredCounties = ['/api/counties/5001', '/api/counties/301', '/api/counties/4601', '/api/counties/1103']
        if (requiredCounties.includes(county) && !district) {
            ctx.addIssue({
                code: ZodIssueCode.custom,
                path: ['district'],
                message: 'Du må velge en bydel for den valgte kommunen',
            })
        }
    })

//TODO: Move office and newOfficeName and refine newOfficeName/Office to its own sub schema

type ValidationSchema = z.infer<typeof validationSchema>

export default function RegisterPage() {
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        resetField,
        setValue,
    } = useForm<ValidationSchema>({resolver: zodResolver(validationSchema)})

    const user = useUser()
    const county = getValues('county')
    const {shortTitle, redirect} = useParams<{shortTitle: string; redirect: string}>()
    const registerUserMutation = useSkilMutation<'registerUser'>('POST', `/api/users/register`)
    const navigate = useNavigate()
    const officesRequest = useSkilQuery<'getCountyOfficeCollection'>(`${county}/offices`, {itemsPerPage: 5000}, {enabled: !!county})
    const offices: Array<OfficeType> = officesRequest.data?.['hydra:member'] ?? []
    const rolesRequest = useSkilQuery<'getUserRoleCollection'>(`/api/user_roles`)
    const userRoles = rolesRequest?.data?.['hydra:member'] ?? []
    const formRef = React.useRef<HTMLFormElement>(null)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [termsOfServiceDialogOpen, setTermsOfServiceDialogOpen] = React.useState(false)

    const onSubmit: SubmitHandler<ValidationSchema> = async data => {
        setIsSubmitting(true)
        try {
            await registerUserMutation.mutateAsync({
                name: data.profile.firstname + ' ' + data.profile.lastname,
                email: data.profile.email,
                password: data.password.password,
                mobile: data.profile.mobile,
                role: data.role['@id'] ?? '',
                tosAccepted: data.tosAccepted,
                office: data.newOfficeName
                    ? {officeName: data.newOfficeName!, county: data.county, district: data.district, officeType: 'new'}
                    : {office: data.office!['@id'], county: data.county, district: data.district, officeType: 'skil'},
            })
            user.refresh()
            toast('Bruker registrert', {type: 'success'})
            if (shortTitle === 'nefle') {
                // first we need to check the users authorization, if the user is authorized backend we can redirect to the confirm page
                window.location.replace(`/signup/nefle/authorize`)
            }
            if (redirect) {
                window.location.replace(redirect)
            } else {
                navigate(`/signup/${shortTitle}/details`)
            }
        } catch (e) {
            handleErrorWithToast(e)
            setIsSubmitting(false)
        }
    }

    return (
        <PurePage variant={'md'}>
            <form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Typography textAlign={'center'} mb={4} mt={2} variant={'h2'}>
                    {'Registrer deg i SKIL ePortal'}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={0} sx={{width: '100%', height: '100%'}}>
                            <Box
                                minHeight={'350px'}
                                overflow={'hidden'}
                                padding={4}
                                display={'flex'}
                                justifyContent={'space-around'}
                                alignItems={'center'}
                                flexDirection={'column'}
                                flexGrow={1}>
                                <Typography variant={'h2'}>Din bruker</Typography>
                                <TextField
                                    type={'text'}
                                    required
                                    autoComplete={'first-name'}
                                    placeholder={'Ditt fornavn'}
                                    label={'Fornavn'}
                                    fullWidth
                                    error={!!errors?.profile?.firstname}
                                    helperText={errors?.profile?.firstname?.message ?? ''}
                                    {...register('profile.firstname')}
                                />
                                <TextField
                                    type={'text'}
                                    required
                                    autoComplete={'last-name'}
                                    placeholder={'Ditt etternavn'}
                                    label={'Etternavn'}
                                    fullWidth
                                    error={!!errors?.profile?.lastname}
                                    helperText={errors?.profile?.lastname?.message ?? ''}
                                    {...register('profile.lastname')}
                                />
                                <TextField
                                    type={'tel'}
                                    required
                                    autoComplete={'tel'}
                                    placeholder={'00000000'}
                                    label={'Mobilnummer'}
                                    fullWidth
                                    error={!!errors?.profile?.mobile}
                                    helperText={errors?.profile?.mobile?.message ?? ''}
                                    {...register('profile.mobile')}
                                />
                                <TextField
                                    type={'email'}
                                    required
                                    autoComplete={'email'}
                                    placeholder={'e-postaddressen din'}
                                    label={'E-post'}
                                    fullWidth
                                    error={!!errors?.profile?.email}
                                    helperText={errors?.profile?.email?.message ?? ''}
                                    {...register('profile.email')}
                                />
                                <TextField
                                    type={'email'}
                                    required
                                    autoComplete={'email'}
                                    placeholder={'e-postaddressen din'}
                                    label={'Gjenta E-posten din'}
                                    fullWidth
                                    error={!!errors.profile?.repeatEmail}
                                    helperText={errors.profile?.repeatEmail?.message ?? ''}
                                    {...register('profile.repeatEmail')}
                                />
                                <TextField
                                    type={'password'}
                                    required
                                    autoComplete={'new-password'}
                                    label={'Passord'}
                                    placeholder={'Ditt passord'}
                                    fullWidth
                                    error={!!errors?.password?.password}
                                    helperText={errors?.password?.password?.message ?? ''}
                                    {...register('password.password')}
                                />
                                <TextField
                                    type={'password'}
                                    required
                                    autoComplete={'new-password'}
                                    label={'Gjenta Passord'}
                                    placeholder={'Gjenta passord'}
                                    error={!!errors?.password?.repeatPassword}
                                    helperText={errors?.password?.repeatPassword?.message ?? ''}
                                    fullWidth
                                    {...register('password.repeatPassword')}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={0} sx={{width: '100%', height: '100%'}}>
                            <Box
                                minHeight={'350px'}
                                overflow={'hidden'}
                                padding={4}
                                display={'flex'}
                                justifyContent={'space-around'}
                                alignItems={'center'}
                                flexDirection={'column'}
                                flexGrow={1}>
                                <Typography variant={'h2'}>Ditt arbeidssted</Typography>
                                <Controller
                                    name={'role'}
                                    control={control}
                                    render={({field: {onChange, value}, fieldState: {error}}) => (
                                        <Autocomplete
                                            id={'choose-role'}
                                            fullWidth
                                            options={userRoles}
                                            disableClearable
                                            getOptionLabel={option => option.name}
                                            value={value}
                                            onChange={(_, newValue) => onChange(newValue)}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    error={!!error}
                                                    helperText={error?.message ?? ''}
                                                    label={'Din rolle'}
                                                    placeholder={'Velg...'}
                                                />
                                            )}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name={'county'}
                                    render={({field: {onChange, value}, fieldState: {error}, formState}) => (
                                        <CountyAutocomplete
                                            required
                                            value={value}
                                            onChange={(event, county, iri, district) => {
                                                onChange(iri)
                                                if (district) {
                                                    setValue('district', district.id)
                                                } else {
                                                    setValue('district', undefined)
                                                    resetField('district')
                                                    resetField('office')
                                                }
                                            }}
                                            error={!!errors['county']}
                                            helperText={errors?.['county']?.message ?? ''}
                                            errorDistrict={!!errors['district']}
                                            helperTextDistrict={errors?.['district']?.message ?? ''}
                                        />
                                    )}
                                />
                                {officesRequest.isLoading && <LinearProgress />}

                                <Controller
                                    control={control}
                                    name={'office'}
                                    render={({field: {onChange, value}}) => (
                                        <Autocomplete<OfficeType | null>
                                            sx={{mb: 2}}
                                            id={'choose-office'}
                                            fullWidth
                                            loading={officesRequest.isLoading}
                                            // @ts-expect-error a simplified zod type instead of OfficeType
                                            value={value ?? null}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            disabled={!county || officesRequest.isLoading}
                                            options={offices}
                                            onChange={(_, newValue) => {
                                                onChange(newValue)
                                                resetField('newOfficeName')
                                            }}
                                            getOptionLabel={option => option?.name ?? ''}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={'Arbeidssted'}
                                                    placeholder={officesRequest.isLoading ? 'Laster inn...' : 'Velg...'}
                                                />
                                            )}
                                        />
                                    )}
                                />
                                <Divider flexItem>Eller registrer nytt arbeidssted</Divider>
                                <TextField
                                    disabled={getValues('office') != null}
                                    fullWidth
                                    id={'office-name'}
                                    label={'Arbeidssted navn'}
                                    error={!!errors['newOfficeName']}
                                    helperText={errors?.['newOfficeName']?.message ?? ''}
                                    {...register('newOfficeName')}
                                />
                                <FormHelperText>Fyll ut hvis du ikke fant ditt arbeidssted i listen over</FormHelperText>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Controller
                    name={'tosAccepted'}
                    control={control}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <>
                            <FormGroup sx={{marginTop: '1em'}} row>
                                <FormControlLabel
                                    sx={{
                                        mb: '1em',
                                        ml: '0em',
                                        border: errors?.tosAccepted ? 'red solid 1px' : 'none',
                                        borderRadius: '0.25em',
                                        padding: '0.5em 1em 0.5em 0.5em',
                                    }}
                                    control={<Checkbox name={'tosAccepted'} required />}
                                    onChange={(e, checked) => onChange(checked)}
                                    value={value}
                                    label={
                                        <>
                                            Lest og godtatt{' '}
                                            <a href={'#'} onClick={() => setTermsOfServiceDialogOpen(true)}>
                                                vilkår
                                            </a>{' '}
                                        </>
                                    }
                                />
                            </FormGroup>
                            <FormHelperText style={{color: '#ab3e36'}}>{error?.message ?? ''}</FormHelperText>
                        </>
                    )}
                />
                {termsOfServiceDialogOpen && (
                    <TermsOfServiceDialog
                        isOpen={termsOfServiceDialogOpen}
                        onClose={() => setTermsOfServiceDialogOpen(false)}
                        isNefle={shortTitle === 'nefle' ?? false}
                    />
                )}
                <Button type={'submit'} size={'large'} fullWidth>
                    Registrer ny bruker og fortsett til {shortTitle} påmeldingen&nbsp;&nbsp;
                    {isSubmitting && <CircularProgress color='inherit' size={'1.5em'} />}
                </Button>
                <BackdropComponent isOpen={isSubmitting} status={'Behandler...'} />
            </form>
        </PurePage>
    )
}
