import * as React from 'react'
import {Box} from '../../Components/Panel'
import DataGrid, {InitModel} from '../../Components/DataGrid/DataGrid'
import {DataGridWrapper} from '../../Components/DataGrid/Wrappers'
import {useSkilQuery} from '../../Utilities/QueryClient'
import {GridColDef} from '@mui/x-data-grid-premium'
import columnDef from '../../Components/DataGrid/columnDefs/columnDef'
import courseColumnDef from '../../Components/DataGrid/columnDefs/courseColumnDef'
import {SpecialActionProps} from '../../Components/DataGrid/SpecialAction'
import SpecialActions from '../../Components/DataGrid/SpecialActions'
import {useState} from 'react'
import selectColumnDef from '../../Components/DataGrid/columnDefs/selectColumnDef'
import {FilterList as FilterListIcon} from '@mui/icons-material'
import {subMonths, format} from 'date-fns'

export const COURSE_TYPES = [
    {value: 0, label: 'E-læringskurs'},
    {value: 1, label: 'Kvalitetspakke'},
    {value: 2, label: 'Seminar'},
    {value: 3, label: 'Konferanse'},
]

export const COURSE_STATUS = [
    {value: 0, label: 'Under utvikling'},
    {value: 1, label: 'Aktivt'},
    {value: 2, label: 'Arkivert'},
]

const columns: GridColDef[] = [
    columnDef({field: 'id', headerName: 'ID', type: 'number'}),
    courseColumnDef({field: 'course.shortTitle'}),
    selectColumnDef({field: 'course.type', headerName: 'Type', valueOptions: COURSE_TYPES}),
    selectColumnDef({field: 'courseStatus', headerName: 'Status', valueOptions: COURSE_STATUS}),
    columnDef({field: 'course.title', headerName: 'Tittel'}),
    columnDef({field: 'accepted', headerName: 'Påmeldt', type: 'number'}),
    columnDef({field: 'neverCompleted', headerName: 'Ikke fullført', type: 'number'}),
    columnDef({field: 'completedWithin9Months', headerName: 'Fullført innen 9 mnd', type: 'number'}),
    columnDef({field: 'completedWithin18Months', headerName: 'Fullført innen 18 mnd', type: 'number'}),
    columnDef({field: 'completedAfter18Months', headerName: 'Fullført etter 18 mnd', type: 'number'}),
    columnDef({
        field: 'percentageCompletedWithin9Months',
        headerName: 'Prosent fullført innen 9 mnd',
        type: 'number',
    }),
    columnDef({
        field: 'percentageCompletedWithin18Months',
        headerName: 'Prosent fullført innen 18 mnd',
        type: 'number',
    }),
]

const columnVisibilityModel = {
    invited: false,
    completed: false,
    meetings: false,
}

const date27MonthsAgo = subMonths(new Date(), 27)
const dateFrom = format(date27MonthsAgo, 'dd.MM.yy')

const date9MonthsAgo = subMonths(new Date(), 9)
const dateUntil = format(date9MonthsAgo, 'dd.MM.yy')

const CourseCompletions = () => {
    const {data, isFetching} = useSkilQuery<'getCourseCompletionsStatistics'>('/api/courses/statistics/course_completions')
    const courses = data?.['hydra:member'] ?? []
    const [initModel, setInitModel] = useState<InitModel>({columnVisibilityModel})

    const actions: SpecialActionProps[] = [
        {
            label: 'Kvalitetspakker',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false},
                    sort: [{field: 'course', sort: 'asc'}],
                    filter: [{columnField: 'course.type', operatorValue: 'is', value: 1}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
        {
            label: 'E-Læringskurs',
            variant: 'outlined',
            action: () =>
                setInitModel({
                    columnVisibilityModel: {id: false},
                    sort: [{field: 'course', sort: 'asc'}],
                    filter: [{columnField: 'course.type', operatorValue: 'is', value: 0}],
                }),
            icon: {
                icon: <FilterListIcon />,
                position: 'start',
            },
        },
    ]

    const explainerText = 'Datointervall: ' + dateFrom + ' - ' + dateUntil

    return (
        <Box>
            <DataGridWrapper rows={courses.length}>
                <DataGrid
                    id={'courses'}
                    initModel={initModel}
                    loading={isFetching}
                    rows={courses}
                    columns={columns}
                    disableSelectionOnClick
                    toolbarButtons={<SpecialActions actions={actions} explainerText={explainerText} />}
                />
            </DataGridWrapper>
        </Box>
    )
}

export default CourseCompletions
