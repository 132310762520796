import Panel from '../../Components/Panel'
import format from 'date-fns/format'
import * as React from 'react'
import SimpleSelectField from '../../Components/Fields/SimpleSelectField'
import TextField from '../../Components/Fields/TextField'
import TextareaField from '../../Components/Fields/TextareaField'
import Button from '../../Components/Button/Button'
import {toast} from 'react-toastify'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {jsonFetch} from '../../Components/jsonFetch'
import {useQueryInvalidate, useSkilMutation, useSkilQuery} from '../../Utilities/QueryClient'
import {GroupType} from '../Group'

type Props = {
    group: GroupType
}
export default function Settings({group: initialGroup}: Props) {
    const [description, setDescription] = React.useState<string>()
    const [counselor, setCounselor] = React.useState<string | null | undefined>()
    const activeCourse = initialGroup.course
    const {data: settings} = useSkilQuery<'getGroupSettings'>(`/api/groups/${initialGroup.id}/settings`)
    const deleteGroupMutation = useSkilMutation<'deleteGroup'>('DELETE', initialGroup['@id']!, [initialGroup['@id']!, `/api/groups`])
    const currentDescription = description ?? settings?.description ?? ''
    const selectedCounselor = counselor !== undefined ? counselor : initialGroup.counselor?.['@id'] ?? null
    const invalidate = useQueryInvalidate()

    const counselorCollectionQuery = useSkilQuery<'getCourseCounselors'>(`${activeCourse?.['@id']}/get_counselors`, null, {
        enabled: !!initialGroup?.course,
    })
    const counselors = (counselorCollectionQuery.data?.['hydra:member'] ?? []).map(c => ({value: c['@id'], label: c.name}))

    const saveSettings = async () => {
        try {
            await jsonFetch(`/api/groups/${settings?.id}/settings`, {
                json: {
                    group: settings?.['@id'],
                    counselor: selectedCounselor,
                    description: currentDescription,
                },
            })
            await invalidate([initialGroup['@id']!, `/api/groups/${initialGroup.id}/settings`])
            toast('Innstillingene ble lagret', {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    const deleteGroup = async () => {
        try {
            const groupDeleted = await deleteGroupMutation.mutateAsync({})
            if (!groupDeleted) {
                toast('Gruppen kan ikke slettes. Minst ett av møtene er godkjent', {type: 'warning'})
            } else {
                toast('Gruppen ble slettet', {type: 'success'})
                window.location.href = `/dashboard/course/${activeCourse?.shortTitle}/groups`
            }
        } catch (err) {
            handleErrorWithToast(err)
        }
    }

    return (
        <Panel variant={'flat'} title={'Innstillinger'}>
            <Panel.Body>
                <div className={'form-group'}>
                    <SimpleSelectField
                        id={'counselor'}
                        label={'Veileder'}
                        value={selectedCounselor}
                        options={counselors}
                        onChange={e => setCounselor(e)}
                        required={false}
                    />
                </div>
                <div className={'form-group'}>
                    <TextField
                        id={'signupAt'}
                        disabled
                        label={'Påmeldt'}
                        value={
                            settings?.signupAt
                                ? format(new Date(settings.signupAt), 'dd.MM.yyyy') + ' kl. ' + format(new Date(settings.signupAt), 'HH:ss')
                                : 'Ikke satt'
                        }
                        onChange={() => {}}
                    />
                </div>
                <div className={'form-group'}>
                    <TextareaField id={'description'} value={currentDescription} onChange={e => setDescription(e)} label={'Beskrivelse'} />
                </div>
            </Panel.Body>
            <Panel.Footer>
                <div style={{textAlign: 'end'}}>
                    <Button onClick={deleteGroup} disabled={!settings?.deletable} confirm={'Bekreft sletting'} variant={'warning'}>
                        Slett gruppen
                    </Button>
                    <Button onClick={saveSettings} variant={'primary'}>
                        Lagre
                    </Button>
                </div>
            </Panel.Footer>
        </Panel>
    )
}
