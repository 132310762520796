import Panel from '../../Components/Panel'
import * as React from 'react'
import SelectField from '../../Components/Fields/SelectField'
import TextField from '../../Components/Fields/TextField'
import SimpleSelectField from '../../Components/Fields/SimpleSelectField'
import {toast} from 'react-toastify'
import {jsonFetch} from '../../Components/jsonFetch'
import {handleErrorWithToast} from '../../Utilities/errorHandlers'
import {SkilQueryResponseType, useQueryInvalidate, useSkilQuery} from '../../Utilities/QueryClient'
import {GroupType} from '../Group'
import useUser from '../../Utilities/useUser'
import Button from '../../Components/Button/Button'
import {components} from '../../Generated/eportal'
import he from 'he'

type CountyTpe = SkilQueryResponseType<'getCountyItem'>
type CourseType = SkilQueryResponseType<'getCourseCollection'>

type Props = {
    group: GroupType
    isKommuneDashboard: boolean
    isSkilUser: boolean
    isCourseAdmin: boolean
}
type SaveDetailsType = components['schemas']['Group.SaveGroupDetailsDTO.jsonld']

export default function Details({group, isKommuneDashboard, isSkilUser, isCourseAdmin}: Props) {
    const user = useUser()
    const [name, setName] = React.useState<string>()
    const [leader, setLeader] = React.useState<string | null>()
    const [contact, setContact] = React.useState<string | null>()
    const [activeCourse, setActiveCourse] = React.useState<GroupType['course']>()
    const [county, setCounty] = React.useState<GroupType['county']>()
    const [district, setDistrict] = React.useState<GroupType['district']>(group?.district ?? null)
    const coursesQuery = useSkilQuery<'getCourseCollection'>(isSkilUser ? `/api/courses` : `${user.iri}/courses`, {usesGroups: true})
    const courses = (coursesQuery?.data?.['hydra:member'] ?? []).filter(course => user.voters.hasCourse(course['@id'], true))
    const invalidate = useQueryInvalidate()

    if (group.course && !courses.some(course => course.id === group.course!.id)) {
        // @ts-expect-error We only use courses in Select, so we don't really care about all the other properties
        courses.push(group.course)
    }

    // TODO: use "defaultValue" when our fields support it
    const selectedName = typeof name !== 'undefined' ? name : group?.title ? he.decode(group?.title) : undefined
    const selectedCounty = typeof county !== 'undefined' ? county : group?.county ?? null
    const selectedDistrict = typeof district !== 'undefined' ? district : null
    const selectedLeader = typeof leader !== 'undefined' ? leader : group.leader?.['@id'] ?? null
    const selectedContact = typeof contact !== 'undefined' ? contact : group.contact?.['@id'] ?? null
    const selectedCourse = typeof activeCourse !== 'undefined' ? activeCourse : group?.course?.['@id'] ?? null

    // Avoid rerender simpleSelect
    const groupMembers = React.useMemo(
        () => Object.values(group.members ?? []).map(member => ({value: member.user!['@id'], label: member.user!.name})),
        [group.members]
    )
    const day = new Date(group ? group.createdAt : '')?.getDate()
    // getMonth() returns 0-11, so we add 1 to get the correct month
    const month = new Date(group ? group.createdAt : '')?.getMonth() + 1
    const year = new Date(group ? group.createdAt : '')?.getFullYear()
    const countiesQueryResult = useSkilQuery<'getCountyCollection'>(`/api/counties`, {pagination: false})
    const districtsQueryResult = useSkilQuery<'getDistrictCollection'>(`/api/districts`, {pagination: false})
    const counties = countiesQueryResult?.data?.['hydra:member']
    const allDistricts = districtsQueryResult?.data?.['hydra:member']
    let districts = allDistricts?.filter(district => district.county?.['@id'] === selectedCounty)

    const saveDetails = async () => {
        try {
            const json: SaveDetailsType = {
                contact: selectedContact,
                leader: selectedLeader,
                name: selectedName,
                county: selectedCounty as string,
                course: selectedCourse as string,
                district: selectedDistrict as string,
            }

            await jsonFetch(`/api/groups/${group?.id}/details`, {json})
            await invalidate([group['@id']!, `${group['@id']}/filterToOnePerUser/price`, `${group['@id']}/filterToOnePerUser`])
            setName(undefined)
            setLeader(undefined)
            setContact(undefined)
            setActiveCourse(undefined)
            setCounty(undefined)
            setDistrict(undefined)
            toast('Endringene ble lagret', {type: 'success'})
        } catch (err) {
            handleErrorWithToast(err)
        }
    }
    return (
        <>
            <Panel variant={'flat'} title={'Detaljer'}>
                <Panel.Body>
                    <div className={'row'}>
                        <div className={'col-md-12 col-lg-6'}>
                            {coursesQuery.isLoading ? (
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <i className='fa fa-spinner fa-spin' />
                                </div>
                            ) : (
                                <SelectField<CourseType>
                                    id={'course'}
                                    type='select'
                                    disabled={isCourseAdmin}
                                    // @ts-expect-error
                                    value={selectedCourse}
                                    // @ts-expect-error
                                    entities={courses}
                                    label={'Aktivt kurs'}
                                    onChange={e => setActiveCourse(e['@id'])}
                                />
                            )}
                            <TextField id={'name'} value={selectedName} onChange={name => setName(name)} label={'Gruppenavn'} />
                            <SelectField<CountyTpe>
                                id={'county'}
                                type='select'
                                value={selectedCounty}
                                // @ts-expect-error
                                entities={counties}
                                label={'Kommune'}
                                disabled={isKommuneDashboard ?? false}
                                onChange={e => {
                                    setCounty(e['@id'])
                                    setDistrict(undefined)
                                }}
                            />
                            {districts && districts.length > 0 && (
                                <SelectField<CountyTpe>
                                    id={'district'}
                                    type='select'
                                    value={selectedDistrict}
                                    // @ts-expect-error
                                    entities={districts}
                                    label={'Bydel'}
                                    disabled={isKommuneDashboard ?? false}
                                    onChange={e => setDistrict(e['@id'])}
                                />
                            )}
                        </div>
                        <div className={'col-md-12 col-lg-6'}>
                            <SimpleSelectField
                                id={'leader'}
                                value={selectedLeader}
                                options={groupMembers}
                                label={'Gruppeleder'}
                                onChange={e => setLeader(e)}
                            />
                            <SimpleSelectField
                                id={'contact'}
                                value={selectedContact}
                                options={groupMembers}
                                label={'Gruppekontakt'}
                                onChange={e => setContact(e)}
                            />
                            <div hidden={!group.counselor}>
                                <TextField
                                    id={'counselor'}
                                    disabled={true}
                                    value={group.counselor?.name ?? ''}
                                    onChange={() => {}}
                                    label={'Veileder'}
                                />
                            </div>
                        </div>
                    </div>
                </Panel.Body>
                <Panel.Footer>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {group.registeredBy?.name && (
                            <span>
                                Gruppen er registrert av{' '}
                                {isSkilUser && <a href={`/dashboard/users/${group.registeredBy.id}`}>{group.registeredBy.name}</a>}
                                {!isSkilUser && <span>{group.registeredBy.name}</span>} den {day}.{month}.{year}{' '}
                            </span>
                        )}
                        <Button disabled={!selectedCourse || !selectedCounty || !selectedName} onClick={saveDetails} variant={'primary'}>
                            Lagre
                        </Button>
                    </div>
                </Panel.Footer>
            </Panel>
        </>
    )
}
